import React, { useState, useEffect } from 'react'
import BreadCrumb from '../../components/shared/BreadCrumb'
import ModalCard from '../../components/execution/ModalCard'
import CustomCard from '../../components/shared/component/Card'
import ChangeRoute from '../../components/execution/ChangeRoute'
import Unionicon from '../../components/shared/UnionIcon'
import { PageHeader, Button, Tag, Statistic, Row, Card } from 'antd'
import { UserSwitchOutlined, PhoneOutlined, UserOutlined } from '@ant-design/icons'
import { useParams, useNavigate, useLocation, } from 'react-router-dom'
import moment from 'moment'
import { GetExecutionHistory, GetExecutionInfo } from '../../api/Execution'
import HistoryRecord from '../../components/shared/component/HistoryRecord'
import LoadingBlock from '../../components/shared/LoadingBlock'
import { useSelector } from 'react-redux'
import { GetCaseInfo } from '../../api/Case'
import { FilterConty } from '../../components/shared/util/FilterFunc'
import { RouteName } from '../../util/route'
import TimeLine from './timeline'
const Execute = ({ ContainerWidth }) => {
    const county = useSelector(state => state.county)
    let { executeId } = useParams()
    const navigate = useNavigate()
    const { state, pathname } = useLocation()
    const routeId = RouteName(pathname)
    let UserInfo = useSelector(state => state.UserInfo)
    let UserPermission = UserInfo ? UserInfo.permission.route : null
    const [abnormalCondition, setAbnormalCondition] = useState(null)
    const [fetchData, setFetchData] = useState(null)
    const [modalStatus, setModalStatus] = useState(null)
    const [executeLists, setExecuteLists] = useState([])
    const [historyRecord, setHistoryRecord] = useState(null)
    const [executedCars, setExecutedCars] = useState(null)
    const [CountyList, setCountyList] = useState(null)
    // const modalContent = [
    //     { key: 1, title: "案件改派", buttonText: "派遣" },
    //     { key: 2, title: "案件取消", buttonText: "確定" },
    //     { key: 3, title: "迄運點變更", buttonText: "確定" }
    // ]
    useEffect(() => {
        if (county) GetHistory()
    }, [county])
    useEffect(()=>{
        if(state && state.isChange){
            setModalStatus(4)
        }
    },[state])
    const GetHistory = async () => {
        let all_town = []
        if (!CountyList) {
            county[0]["children"].forEach(t => {
                let name = t.label
                t["children"].forEach(town => {
                    all_town.push({ ...town, parentName: name })
                })
            })
            setCountyList(all_town)
        } else {
            all_town = [...CountyList]
        }
        let detail = await GetExecutionInfo(executeId)
        if (!detail.Error) {
            let Detail = detail.Result
            setAbnormalCondition(Detail.message_cards.map(card=>{
                card.Content = card.content.split("|")
                return card
            }))
            let carInfo = Detail.trailer.indexOf(" / ") ? Detail.trailer.split(" / ") : [Detail.trailer]
            Detail.DriverInfo = {
                driver_name: Detail.driver,
                service_point_name: Detail.station,
                plate_number: carInfo[0],
                trailer_type_name: carInfo.length === 2 ? carInfo[1] : '',
                tel: Detail.driver_mobile_phone_number,
                status: ''
            }
            Detail.driver = {
                serviceCentre: Detail.station,
                technician: { type: "tag", content: [Detail.driver], color: "", icon: <UserOutlined /> },
                carBelonging: { type: "tag", content: [`${Detail.trailer}`], icon: (<Unionicon />), color: "" },
                executingTime: Detail.status === "完成" ? `${moment(Detail.time).format("YYYY/MM/DD HH:mm")}` : `${moment(Detail.time).format("YYYY/MM/DD HH:mm")} ;  預計 ${moment(Detail.time).format("YYYY/MM/DD HH:mm")}`,
                executingStatus: {
                    type: "status", color: Detail.status === '異況確認' ? "#EB2F96" : "rgba(82, 196, 26, 1)", status: 1, content: [Detail.status]
                }
            }
            if (Detail.action_status && Detail.category) {
                //qian前往
                let done = ''
                Detail.route.forEach((r, i) => {
                    if (Detail.category && Detail.action_status) {
                        if (Detail.category === r.category) {
                            if (Detail.action_status === "前往") {
                                done = i === 0 ? '' : i - 1
                            } else if (Detail.action_status === "抵達") {
                                done = i
                            }
                        }
                    }
                })
                Detail.route = Detail.route.map((r, i) => {
                    if (done === '') {
                        r.status = "待執行"
                    } else if (done === 0) {
                        if (done === i) {
                            r.status = "執行中"
                        } else if (done < i) {
                            r.status = "待執行"
                        }
                    } else {
                        if (done === i) {
                            r.status = "執行中"
                        } else if (i > done) {
                            r.status = "待執行"
                        } else {
                            r.status = "完成"
                        }
                    }
                    return r
                })
            } else {
                Detail.route = Detail.route.map(r => {
                    r.status = Detail.status
                    return r
                })
            }
            let vehicle = []
            Detail.route.forEach(route => {
                route.vehicles.map(v => {
                    let filter = vehicle.filter(data => data.row_id === v.row_id)
                    if (filter.length === 0) {
                        vehicle.push(v)
                    }
                })
            })
            for (let i = 0; i < vehicle.length; i++) {
                let res = await GetCaseInfo(vehicle[i]["case_id"], vehicle[i]["vehicle_sn"])
                if (res && !res.Error) {
                    vehicle[i]["case_status"] = res.Result.case_status
                    vehicle[i]["execution_status"] = res.Result.execution_status
                    let origin = all_town.filter(t => t.Code === res.Result.origin_town)
                    let destination = all_town.filter(t => t.Code === res.Result.destination_town)
                    let Origin = '', Destination = ''
                    if (origin.length === 1) Origin = `${origin[0]["parentName"]} / ${origin[0]["Name"]}`
                    if (destination.length === 1) Destination = `${destination[0]["parentName"]} / ${destination[0]["Name"]}`
                    FilterConty(res.Result.destination_town, true)
                    vehicle[i]["Origin"] = Origin
                    vehicle[i]["Destination"] = Destination
                    vehicle[i]["case_row_id"] = res.Result.case_info_row_id
                }
            }
            Detail.vehicle_data = vehicle
            setFetchData(Detail)
            let split_block = document.querySelector(".split_block > .content_block") ? document.querySelector(".split_block > .content_block").clientHeight : 0
            if (document.querySelector(".split_block > .history_block")) {
                document.querySelector(".split_block > .history_block").style.maxHeight = `${split_block}px`
            }
            let res = await GetExecutionHistory(Detail.id)
            let data = res.Result
            data = data.map(d => {
                d.time = moment(d.time).format("YYYY.MM.DD HH:mm")
                return d
            })
            setHistoryRecord(data)
        }
    }
    return (
        <div className="container" style={ContainerWidth}>
            {
                fetchData ? (<>
                    <PageHeader
                        style={{ background: "white", width: "100%" }}
                        title={fetchData.id}
                        onBack={() => navigate('/execution', { state: state })}
                        className="site-page-header"
                        tags={<Tag color={fetchData.status === "完成" ? "green" : fetchData.status === "異況確認" ? "magenta" : "default"}>{fetchData.status}</Tag>}
                        extra={UserPermission && UserPermission[routeId] === 2 && fetchData.status !== "完成" && [
                            <Button icon={<UserSwitchOutlined />}
                                onClick={() => setModalStatus(4)}
                            >
                                內容變更
                            </Button>,
                            <a href={`tel:${fetchData.driver_mobile_phone_number}`}>
                                <Button type='primary' icon={<PhoneOutlined />} >
                                    聯絡技師
                                </Button>
                            </a>

                        ]}
                        breadcrumb={<BreadCrumb Info={fetchData !== null && { [executeId]: fetchData.id }} />}
                    >
                    </PageHeader>
                    <div className='split_block' style={{ display: 'grid', gridTemplateColumns: "repeat(2, 1fr)", }}>
                        <div className='content_block' style={{ padding: 24 }}>
                            {/* 技師提出異況 */}
                            <ModalCard
                                executeId={executeId}
                                abnormalCondition={abnormalCondition}
                                detail={fetchData}
                                permission={UserPermission[routeId]}
                                setVisible={(val) => setModalStatus(val)}
                                onSuccess={(val) => {
                                    if (val) {
                                        setFetchData(null)
                                        GetHistory()
                                    }
                                }}
                            />
                            <Card title="執行內容小計" bordered={false} >
                                <Row style={{ paddingTop: 6, display: "flex", justifyContent: "space-around" }}>
                                    <Statistic title="車輛台數" value={fetchData.vehicle_count} />
                                    <Statistic
                                        title="停靠點（含起迄）"
                                        value={fetchData.route.length}
                                        style={{
                                            paddingLeft: 24,
                                            borderLeft: '1px solid rgba(0, 0, 0, 0.03)'
                                        }}
                                    />
                                    <Statistic title="預計耗時" value={fetchData.duration} style={{ borderLeft: '1px solid rgba(0, 0, 0, 0.03)', paddingLeft: 24 }} />
                                </Row>
                            </Card>
                            <Card
                                style={{ width: "100%", margin: "24px 0" }}
                                title="技師資訊"
                            // extra={
                            //     status === 5 ? "" :
                            //         <Button
                            //             type="text"
                            //             style={{ paddingRight: 0 }}
                            //         // onClick={()=> setVisible(3)}
                            //         > 更多
                            //             <MoreOutlined style={{ color: "rgba(24, 144, 255, 1)" }} />
                            //         </Button>
                            // }
                            >
                                <CustomCard
                                    header={[
                                        { key: "serviceCentre", name: "服務據點" },
                                        { key: "technician", name: "服務技師" },
                                        { key: "carBelonging", name: "服務車輛" },
                                        { key: "executingTime", name: "開始執行時間" },
                                        { key: "executingStatus", name: "執行狀態" }
                                    ]} data={fetchData.driver} />
                            </Card>
                            <TimeLine fetchData={fetchData} />
                        </div>
                        <HistoryRecord History={historyRecord} detail={fetchData} />
                        {
                            modalStatus && (<ChangeRoute
                                executeId={executeId}
                                driverInfo={fetchData.DriverInfo}
                                carData={fetchData.vehicle_data}
                                onClose={(val) => setModalStatus(val)}
                                onSuccess={(val) => {
                                    if (val) {
                                        setFetchData(null)
                                        GetHistory()
                                        setModalStatus(null)
                                    }
                                }}
                            />)
                        }


                    </div>
                </>) : (<div style={{ margin: 24, background: "white" }}>
                    <LoadingBlock />
                </div>)
            }

        </div>
    );
}


export default Execute;

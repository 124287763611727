import React, { useState, useEffect, useRef } from 'react'
import BreadCrumb from '../../components/shared/BreadCrumb'
import CustomCard from '../../components/shared/component/Card'
import LoadingBlock from '../../components/shared/LoadingBlock'
import HistoryRecord from '../../components/shared/component/HistoryRecord'
import { PageHeader, Card, Button, Select, Radio, Drawer, Input, Form, Modal } from 'antd'
import { EditOutlined, EnterOutlined, DeleteOutlined, CloseOutlined } from '@ant-design/icons'
import { PrivilegeTagDetail, CheckPrivilegeTagName, UpdatePrivilegeTag, GetPrivilegeTagHistory, GetPrivilegeTagUserList, AllPrivilegeTag, DeletePrivilegeTag, EditPrivilegeTagStatus } from '../../api/Member'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RouteName } from '../../util/route'
const { TextArea } = Input
const { confirm } = Modal
const TagDetail = ({ ContainerWidth, pageTitle }) => {
    let { tagId } = useParams()
    const navigate = useNavigate()
    const { state, pathname } = useLocation()
    const routeId = RouteName(pathname)
    let UserInfo = useSelector(state => state.UserInfo)
    let UserPermission = UserInfo ? UserInfo.permission.route : null
    const [data, setData] = useState(null)
    const [history, setHistory] = useState(null)
    //0: 基本資料 、 1:權限設定 、 2:通知設定
    const [editDrawer, setEditDrawer] = useState(false)
    const submitRef = useRef(), deleteTagForm = useRef()
    const [tagArr, setTagArr] = useState(null)
    const [nameValidation, setNameValidation] = useState(null)
    const [tagUserList, setTagUserList] = useState(null)
    const [tagOption, setTagOption] = useState([])
    const [deleteModal, setDeleteModal] = useState(false)
    const [editBtnDisabled, setEditBtnDisabled] = useState(true)
    const onFinish = async (val) => {
        if (editDrawer === "0") {
            val.tag_id = data.tag_id
        }
        let res
        if (editDrawer === "0") {
            res = await UpdatePrivilegeTag(val)
        } else {
            let reform = Object.keys(val).map(d => {
                let obj = {}
                obj.id = d
                obj.p_status = val[d]
                return obj
            })
            res = await EditPrivilegeTagStatus(reform)
        }
        if (res && !res.Error) {
            FetchApi()
            setEditDrawer(false)
        }
    }
    const FetchApi = () => {
        PrivilegeTagDetail(tagId).then(res => {
            if (res && !res.Error) {
                let result = res.Result
                let obj = { 1: [], 2: [] }
                result.permission_info.map(p => {
                    p.p_status = p.p_status.toString()
                    if (p.p_type === 1) {
                        if(p.p_name === '加油管理'){
                            p.p_status  = p.p_status === "2"? "1":"3"
                        }
                        p.status_text = p.p_status === "1" ? "檢視" : p.p_status === "2" ? "編輯" : "隱藏"
                        
                    } else {
                        p.status_text = p.p_status === "2" ? "允許" : "拒絕"
                    }
                    obj[p.p_type].push(p)
                })
                setTagArr(obj)
                setData(result)
            }
        })
        GetPrivilegeTagHistory(tagId).then(res => {
            setHistory(res.Result)
        })
    }
    useEffect(() => {
        GetPrivilegeTagUserList(tagId).then(res => {
            if (!res.Error) setTagUserList(res.Result)
        })
        AllPrivilegeTag().then(res => {
            if (res && !res.Error) {
                let result = res.Result
                setTagOption(result)
            }
        })
        FetchApi()
    }, [])
    return (
        <div className='container' style={ContainerWidth}>
            <div style={{ height: "calc(100%)" }}>
                {
                    data ? (<>
                        <PageHeader
                            style={{ background: "white", width: "100%" }}
                            title={data.tag_name}
                            onBack={() => navigate(-1)}
                            className="site-page-header"
                            breadcrumb={<BreadCrumb Info={{ [tagId]: data.tag_name }} />}
                            extra={UserPermission && UserPermission[routeId] !== 2 ? [] : [<Button key="刪除標籤" icon={<DeleteOutlined />} onClick={() => {
                                if (tagUserList) {
                                    if (tagUserList.length > 0) {
                                        setDeleteModal(true)

                                    } else {
                                        confirm({
                                            title: '確定刪除標籤嗎？',
                                            content: '刪除後將無法復原',
                                            okText: '確定刪除',
                                            cancelText: '先不要',
                                            onOk() {
                                                DeletePrivilegeTag({ tag_id: tagId, replace_tag_list: null }).then(res => {
                                                    if (!res.Error) {
                                                        navigate('/privilege/tags/')
                                                    }
                                                })
                                            }
                                        });
                                    }
                                }
                            }}> 刪除標籤 </Button>]}
                        >
                        </PageHeader>
                        <div className='split_block' style={{ display: 'grid', gridTemplateColumns: "repeat(2, 1fr)", height: "calc(100% - 102px)" }}>
                            <div className='content_block' style={{ padding: 24 }}>
                                <Card
                                    style={{ width: "100%", marginBottom: 24 }}
                                    title="基本資訊"
                                    extra={
                                        UserPermission && UserPermission[routeId] !== 2 ? false :
                                            <Button
                                                type="text"
                                                style={{ paddingRight: 0 }}
                                                onClick={() => {
                                                    setEditDrawer("0")
                                                    setTimeout(() => submitRef.current.setFieldsValue(data), 200)
                                                }}
                                            >
                                                修改
                                                <EditOutlined style={{ color: "rgba(24, 144, 255, 1)" }} />
                                            </Button>
                                    }
                                >
                                    {
                                        data !== null && (<CustomCard
                                            header={[
                                                { key: "tag_name", name: "名稱" },
                                                { key: "tag_note", name: "備註" },
                                            ]} data={data} />)
                                    }
                                </Card>
                                {
                                    tagArr && Object.keys(tagArr).map(o => (
                                        <Form layout="vertical" >
                                            <Card
                                                style={{ width: "100%", marginBottom: 24 }}
                                                title={o === "1" ? "權限設定" : "通知設定"}
                                                key={o === "1" ? "權限設定" : "通知設定"}
                                                extra={
                                                    UserPermission && UserPermission[routeId] !== 2 ? false :
                                                        <Button
                                                            type="text"
                                                            style={{ paddingRight: 0 }}
                                                            onClick={() => {
                                                                setEditDrawer(o)
                                                                let obj = {}
                                                                tagArr[o].map(x => obj[x.id] = x.p_status)
                                                                setTimeout(() => submitRef.current.setFieldsValue(obj), 100)
                                                            }}
                                                        >
                                                            修改
                                                            <EditOutlined style={{ color: "rgba(24, 144, 255, 1)" }} />
                                                        </Button>
                                                }
                                            >
                                                <ul>
                                                    {
                                                        tagArr[o].map(a => (
                                                            <li style={{ display: "flex", borderBottom: "1px solid rgba(240, 240, 240, 1)" }} key={a.id}>
                                                                <div style={{ width: "160px", background: "#FAFAFA", padding: 8 }}>{a.p_name}</div>
                                                                <div style={{ padding: 8, width: "calc(100% - 160px)" }}>
                                                                    {a.status_text}
                                                                </div>
                                                            </li>
                                                        ))
                                                    }
                                                </ul>
                                            </Card>
                                        </Form>
                                    ))
                                }
                            </div>
                            <HistoryRecord detail={data} History={history} />
                        </div>
                    </>) : (<div style={{ padding: 24, height: '100%' }}>
                        <LoadingBlock />
                    </div>)
                }
            </div>
            <Drawer
                title={`編輯 - ${editDrawer === "0" ? "基本資訊" : editDrawer === "1" ? "權限設定" : "通知設定"}`}
                placement="right"
                closable={false}
                width={400}
                extra={<CloseOutlined
                    style={{ cursor: "pointer", color: "rgba(0, 0, 0, 0.45)" }}
                    onClick={() => setEditDrawer(false)}
                />}
                visible={editDrawer}
                footer={
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button type="text" style={{ marginRight: 16 }} onClick={() => {
                            setEditDrawer(false)
                            setEditBtnDisabled(true)
                        }
                        }>取消</Button>
                        <Button type="primary"
                            disabled={editBtnDisabled}
                            onClick={() => submitRef.current.submit()}
                        >編輯</Button>
                    </div>
                }
            >
                {
                    data && (<Form
                        ref={submitRef}
                        name="basic"
                        layout='vertical'
                        initialValues={data}
                        onFinish={onFinish}
                        autoComplete="off"
                    >
                        {
                            editDrawer === "0" && (
                                <>
                                    <Form.Item
                                        label="標籤名稱"
                                        name="tag_name"
                                        hasFeedback
                                        validateStatus={nameValidation == null ? "" : nameValidation ? "success" : "error"}
                                        help={nameValidation == null || nameValidation ? '' : '此標籤名稱已存在'}
                                        rules={[{ required: true, message: '請輸入標籤名稱!' }]}
                                    >
                                        <Input
                                            onKeyUp={() => {
                                                setNameValidation(null)
                                                setEditBtnDisabled(false)
                                            }}
                                            placeholder='請輸入標籤名稱' addonAfter={<EnterOutlined style={{ cursor: "pointer" }} onClick={() => {
                                                let val = submitRef.current.getFieldValue().tag_name
                                                CheckPrivilegeTagName(val).then(res => {
                                                    if (res.Result === 'true') {
                                                        setNameValidation(false)
                                                    } else {
                                                        setNameValidation(true)
                                                    }
                                                })
                                            }} />} />
                                    </Form.Item>
                                    <Form.Item
                                        label="備註"
                                        name="tag_note"
                                    >
                                        <TextArea
                                            onKeyUp={() => setEditBtnDisabled(false)}
                                            autoSize={{ minRows: 3, maxRows: 6 }} placeholder="" />
                                    </Form.Item>
                                </>
                            )
                        }
                        {
                            editDrawer && editDrawer !== "0" && tagArr[editDrawer].map(o => (
                                <Form.Item
                                    key={o.id}
                                    label={o.p_name}
                                    name={o.id}
                                >
                                    <Radio.Group defaultValue={o.p_status}
                                        onChange={(e) => {
                                            setEditBtnDisabled(false)
                                            submitRef.current.setFieldsValue({ [o.id]: e.target.value })
                                        }}
                                    >
                                        {
                                            editDrawer === "1" ? (<>
                                                <Radio.Button value="1">檢視</Radio.Button>
                                                {
                                                    o.p_name !== "加油管理" && (
                                                        <Radio.Button value="2">編輯</Radio.Button>
                                                    )
                                                }

                                                <Radio.Button value="3">隱藏</Radio.Button>
                                            </>) : (<>
                                                <Radio.Button value="2">允許</Radio.Button>
                                                <Radio.Button value="3">拒絕</Radio.Button>
                                            </>)
                                        }
                                    </Radio.Group>
                                </Form.Item>
                            ))
                        }
                    </Form>)
                }
            </Drawer>
            <Modal title="標籤尚未被刪除"
                visible={deleteModal}
                width={416}
                okText='刪除標籤'
                cancelText='關閉'
                onOk={() => deleteTagForm.current.submit()}
                onCancel={() => {
                    setDeleteModal(false)
                    deleteTagForm.current.resetFields()
                }}
            >
                <Form
                    ref={deleteTagForm}
                    onFinish={(val) => {
                        let replace_tag_list = Object.keys(val).map(v => {
                            return { user_id: v, re_tag_id: val[v] }
                        })
                        DeletePrivilegeTag({ tag_id: tagId, replace_tag_list }).then(res => {
                            if (!res.Error) {
                                navigate('/privilege/tags/')
                            }
                        })
                    }}
                >
                    {
                        tagUserList && tagUserList.map(user => (
                            <Form.Item
                                className='tag_user'
                                key={user.id}
                                label={user.user_name}
                                name={user.id}
                                rules={[{ required: true, message: `請選擇 ${user.user_name} 權限標籤!` }]}
                            >
                                <Select
                                    allowClear
                                    placeholder="請指定權限標籤"
                                >
                                    {
                                        tagOption.map(tag => (<option key={tag.tag_id} value=
                                            {tag.tag_id}>{tag.tag_name}</option>))
                                    }
                                </Select>
                            </Form.Item>
                        ))
                    }
                </Form>
            </Modal>
        </div>
    );
}

export default TagDetail;
import React, { useState } from "react";
import { Button } from "antd";
import { PhoneOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { ContinueExecution } from "../../api/Execution";
import { Continue } from "../../api/Case";
const Modalcard = ({ abnormalCondition, setVisible, detail,  permission, executeId, onSuccess}) => {
  const navigate = useNavigate();
  return (
    <>
      {abnormalCondition.length > 0 &&
        abnormalCondition.map((condition) => (
          <div style={{ background: "white", marginBottom: 24 }}>
            <div
              style={{
                padding: "16px 24px",
                borderBottom: "1px solid rgba(240, 240, 240, 1)",
                fontSize: 16,
                fontWeight: 500,
              }}
            >
              {condition["title"]}
            </div>
            <div style={{ padding: 24 }}>
              {condition["Content"].map((c, i) => (
                <>
                  {i % 2 !== 0 ? (
                    <span style={{ color: "rgba(24, 144, 255, 1)" }}>{c}</span>
                  ) : (
                    <span>{c}</span>
                  )}
                </>
              ))}
            </div>
            {permission === 2 && (
              <div
                style={{
                  borderTop: "1px solid rgba(240, 240, 240, 1)",
                  padding: "10px 24px",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                {condition["is_case"] && (
                  <Button
                    style={{ marginRight: 8 }}
                    onClick={() => {
                      navigate(`/cases/${condition["case_id"]}`, {
                        state: {
                          vehicle_sn: condition["vehicle_sn"],
                        },
                      });
                    }}
                  >
                    案件內容
                  </Button>
                )}
                <Button
                  onClick={() => {
                    setVisible(4);
                  }}
                >
                  內容變更
                </Button>
                <Button
                  style={{ margin: "0 8px" }}
                  onClick={() => {
                    if(condition.is_case){
                        Continue(condition.case_info_row_id).then(res=>{
                            if (res && !res.Error) {
                                onSuccess(true);
                            }
                        })
                    }else{
                        ContinueExecution(executeId).then((res) => {
                        if (res && !res.Error) {
                            onSuccess(true);
                        }
                        });
                    }
                  }}
                >
                  繼續執行
                </Button>
                <a href={`tel:${detail.driver_mobile_phone_number}`}>
                  <Button type="primary" icon={<PhoneOutlined />}>
                    聯絡技師
                  </Button>
                </a>
              </div>
            )}
          </div>
        ))}
    </>
  );
};

export default Modalcard;

const intialData = [
    {
      key: 1,
      no: 1,
      mobility: "1",
      
    }
  ];

const Cases = (state = intialData, action) => {
    switch (action.type) {
      case 'ADD_CASE':
        return {
        //   ...state,
        //   likeCount: (state.likeCount += action.num),
          cases: action.cases,
          customerInfo : action.customer
        }
  
      default:
        return state
    }
  }
  export default Cases
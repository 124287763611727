import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Route, Routes, BrowserRouter as Router, Navigate, useNavigate, Redirect } from 'react-router-dom'
import Login from './pages/member/login'
import ForgetPW from './pages/member/forget_pw'
import ResetPW from './pages/member/reset_pw'
import { createStore, applyMiddleware, compose } from 'redux'
import allReducers from './reducers'
import { Provider } from 'react-redux'
import thunk from 'redux-thunk'
// import { BrowserRouter as Router} from 'react-router-dom'
import { createBrowserHistory } from 'history'
import { routerMiddleware } from 'connected-react-router'
import { ConfigProvider } from 'antd'
import zhTW from 'antd/lib/locale/zh_TW'
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
let history = createBrowserHistory();

const mystore = createStore(
  allReducers(history),
  compose(
    applyMiddleware(
      routerMiddleware(history)
    ),
  composeEnhancers(applyMiddleware(thunk))

  )
)

let user = localStorage.getItem("User")? JSON.parse(localStorage.getItem("User")) : null;
ReactDOM.render(
  <Provider store={mystore}>

    <ConfigProvider locale={zhTW}>
    <App history={history} />
    </ConfigProvider>
  </Provider>,
  document.getElementById('root')
)


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
           





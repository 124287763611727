import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import BreadCrumb from "../../components/shared/BreadCrumb";
import {
  PageHeader, Tabs, Button, Row, Statistic, DatePicker, Modal, Form,
  Radio, Input
} from "antd";
import { DownloadOutlined, PlusOutlined } from "@ant-design/icons";
import { GetDriverHealth, GetDriverHealthException, UpdatePhysiologicalNote } from "../../api/ServicePoint";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend} from "chart.js";
import { Line } from "react-chartjs-2";
import moment from "moment";
import LoadingBlock from "../../components/shared/LoadingBlock";
import HistoryRecord from "../../components/shared/component/HistoryRecord";
import { RouteName } from "../../util/route";
import { useSelector } from "react-redux";
const { TabPane } = Tabs;

const TechnicianHealth = ({ ContainerWidth }) => {
  let { technicianId, pointId } = useParams();
  const { state, pathname } = useLocation();
  const routeId = RouteName(pathname);
  let UserInfo = useSelector((state) => state.UserInfo);
  let UserPermission = UserInfo ? UserInfo.permission.route : null;
  const navigate = useNavigate(),
    editForm = useRef();
  const [modalStatus, setModalStatus] = useState(false);
  const [currentTab, setCurrentTab] = useState("heart_rate");
  const [allData, setAllData] = useState(null);
  const [selectedData, setSelectedData] = useState(null);
  const [selectedDate, setSelectedDate] = useState(moment());
  const [timeRange, setTimeRange] = useState(null);
  const [record, setRecord] = useState([]);
  const fetchApi = async () => {
    let today = selectedDate.format("YYYY-MM-DD");
    let res = await GetDriverHealth(technicianId, today);
    if (res && !res.Error) {
      let Data = res.Result;
      let arr = ["min", "max", "avg"];
      let time = Data.Items.map((d) => {
        return moment(d.date).format("HH:mm:ss");
      });
      let obj = {
        blood_oxygen: {
          data: Data.Items.map((d) => {
            return {
              value: d.blood_oxygen ? d.blood_oxygen : 0,
              status: d.blood_oxygen_status,
              note: `${d.blood_oxygen_level}${d.blood_oxygen_note?`（${d.blood_oxygen_note}）`:""}`,
              physiological_row_id: d.physiological_row_id
            };
          }),
        },
        fatigue: {
          data: Data.Items.map((d) => ({
            value: d.fatigue ? d.fatigue : 0,
            status: d.fatigue_status,
            note: `${d.fatigue_level}${d.fatigue_note?`（${d.fatigue_note}）`:""}`,
            physiological_row_id: d.physiological_row_id

          })),
        },
        heart_rate: {
          data: Data.Items.map((d) => ({
            value: d.heart_rate ? d.heart_rate : 0,
            status: d.heart_rate_status,
            note: `${d.heart_rate_level}${d.heart_rate_note?`（${d.heart_rate_note}）`:""}`,
            physiological_row_id: d.physiological_row_id
          })),
        },
      };
      Object.keys(obj).map((t) => {
        arr.map((a) => {
          let key = `${t}_${a}`;
          obj[t][a] = Data[key] ? Data[key] : "-";
        });
      });
      setAllData(obj);
      setTimeRange(time);
      setSelectedData(obj[currentTab])
      FetchRecord();
    }
  };
  const FetchRecord = async () => {
    let res = await GetDriverHealthException(technicianId, -1, 0);
    if (res && !res.Error) {
      let Result = res.Result.Data.map((r) => {
        let unit = r.item === "heart_rate" ? "次/分" : "ms";
        r.content = `${r.item === "fatigue"
            ? "疲勞值"
            : r.item === "heart_rate"
              ? "心律"
              : "血氧"
          }異常：${r.value} ${unit}`;
        return r;
      });
      setRecord(Result);
    }
  };
  useEffect(() => {
    if (allData) {
      setSelectedData(allData[currentTab]);
      ChartJS.register( CategoryScale, LinearScale, PointElement, LineElement, Title,Tooltip, Legend )
      ChartJS.register(
        {
          id: 'uniqueid5', //typescript crashes without id
          afterDraw: function (chart, easing) {
            if (chart.tooltip._active && chart.tooltip._active.length) {
              const activePoint = chart.tooltip._active[0];
              const ctx = chart.ctx;
              const x = activePoint.element.x;
      
              const topY = chart.scales.yAxis.top;
              const bottomY = chart.scales.yAxis.bottom;
              ctx.save();
              ctx.beginPath();
              ctx.moveTo(x, topY);
              ctx.lineTo(x, bottomY);
              ctx.lineWidth = 2;
              ctx.strokeStyle = '#d5d5d5';
              ctx.stroke();
              ctx.restore();
            }
          },
        }
      );
    }
  }, [currentTab, allData]);
  useEffect(() => {
    fetchApi();
  }, [selectedDate]);
  useEffect(() => {
    FetchRecord();
  }, []);
  return (
    <div className="container" style={ContainerWidth}>
      <PageHeader
        style={{ background: "white", width: "100%" }}
        title="生理監控"
        onBack={() => navigate(-1)}
        className="site-page-header"
        extra={
          UserPermission && UserPermission[routeId] !== 2
            ? []
            : selectedDate
              ? [
                <a
                  href={`${process.env.REACT_APP__API_HOST
                    }/ServicePoint/Driver/DownloadPhysiological?driver_row_id=${technicianId}&start=${selectedDate.format(
                      "YYYY/MM/DD"
                    )}&end=${selectedDate.format("YYYY/MM/DD")}`}
                  download="生理監控"
                >
                  <Button type="primary" key="匯出" icon={<DownloadOutlined />}>
                    匯出
                  </Button>
                </a>,
              ]
              : []
        }
        breadcrumb={
          <BreadCrumb
            Info={
              state
                ? {
                  [pointId]: state.point,
                  [technicianId]: state.driver,
                  health: "生理監控",
                }
                : {}
            }
          />
        }
      ></PageHeader>
      {selectedData && timeRange ? (
        <>
          <div
            className="split_block"
            style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)" }}
          >
            <div className="content_block" style={{ margin: 24 }}>
              <DatePicker
                allowClear={false}
                defaultValue={selectedDate}
                style={{ width: 256, marginBottom: 24 }}
                onChange={(val) => setSelectedDate(val)}
              />
              <div style={{ background: "white", padding: 24 }}>
                <div style={{ position: "relative" }}>
                  <div style={{ fontSize: 18, fontWeight: 500 }}>當日數值</div>
                  <div style={{ position: "absolute", right: 0, top: 0 }}>
                    。{" "}
                    <span style={{ color: "rgba(0, 0, 0, 0.45)" }}>次/分</span>
                  </div>
                  <Tabs
                    style={{ margin: "8px 0 16px" }}
                    defaultActiveKey={currentTab}
                    onTabClick={(value) => setCurrentTab(value)}
                  >
                    <TabPane tab="心律" key="heart_rate" />
                    <TabPane tab="血氧" key="blood_oxygen" />
                    <TabPane tab="疲勞度" key="fatigue" />
                  </Tabs>
                </div>
                <Row
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(3,1fr)",
                  }}
                >
                  <Statistic
                    title="平均"
                    value={allData[currentTab]["avg"]}
                    style={{
                      borderRight: "1px solid rgba(0, 0, 0, 0.03)",
                      paddingRight: 24,
                      margin: "0 auto",
                    }}
                  />
                  <Statistic
                    title="最高"
                    value={allData[currentTab]["max"]}
                    style={{
                      borderRight: "1px solid rgba(0, 0, 0, 0.03)",
                      paddingRight: 24,
                      margin: "0 auto",
                    }}
                  />
                  <Statistic
                    title="最低"
                    value={allData[currentTab]["min"]}
                    style={{
                      borderRight: "",
                      paddingRight: 24,
                      margin: "0 auto",
                    }}
                  />
                </Row>

                <Line
                  style={{ marginTop: 16 }}
                  height={200}
                  options={{
                    scales: { yAxis: { min: 0} , xAxis:{ grid:{display:false} }},
                    plugins: {
                      legend: false,
                      title: { display: false },
                      tooltip: {
                        callbacks: {
                          label:(c)=>(""),
                          footer: function (tooltipItem) {
                            return `${tooltipItem[0]["raw"]}（${!selectedData.data[tooltipItem[0]['dataIndex']].status?'正常':'異常'}）`;
                          },
                        },
                      },
                    },
                    interaction: {
                        mode: 'index',
                        intersect:false
                    }
                  }}
                  data={{
                    labels: timeRange,
                    datasets: [
                      {
                        data: selectedData.data.map(d=>d.value),
                        borderColor: "#1890FF",
                        backgroundColor: "white",
                        tension: 0.3,
                        pointStyle: (val)=>{
                          if(selectedData.data.length> 0 && selectedData.data[val.dataIndex].status){
                            return 'rectRot'
                          }else{
                            return ''
                          }
                        },
                        pointRadius: (val)=>{
                          if(selectedData.data.length> 0 && selectedData.data[val.dataIndex].status){
                            return 6
                          }else{
                            return 0
                          }
                        },
                        pointBorderColor: 'transparent',
                        pointBackgroundColor:  (val)=>{
                          if(selectedData.data.length> 0 && selectedData.data[val.dataIndex].status){
                            return '#F5222D'
                          }else{
                            return '#1890FF'
                          }
                        },
                      },
                    ],
                  }}
                />
                <ul className="grid_double_block">
                  <li>
                    <div>時間</div>
                    <div>數值</div>
                    <div>備註</div>
                  </li>
                  {timeRange.map((time, i) => (
                    <li key={`${time}_${i}`}>
                      <div>{time}</div>
                      <div
                        style={{
                          color: selectedData["data"][i]["status"]
                            ? "rgba(245, 34, 45, 1)"
                            : "",
                        }}
                      >
                        {selectedData["data"][i]["value"]}
                      </div>
                      <div>
                        {selectedData["data"][i]["status"] && selectedData["data"][i]["note"]? (selectedData["data"][i]["note"]) : selectedData["data"][i]["status"]  ?(
                          <Button
                            type='link'
                            onClick={() => {
                              if(!selectedData["data"][i]["note"]){
                                  setModalStatus(selectedData["data"][i])
                              }
                            }}
                          >
                             新增備註 <PlusOutlined />
                          </Button>
                        ):""}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <HistoryRecord detail={allData} History={record} name="異況紀錄" />
          </div>
          <Modal
            title="新增備註"
            visible={modalStatus}
            width={416}
            onCancel={() => setModalStatus(null)}
            onOk={() => editForm.current.submit()}
          >
            <Form
              name="basic"
              layout="vertical"
              ref={editForm}
              initialValues={{}}
              onFinish={(val) => {
                let param=`row_id=${modalStatus.physiological_row_id}&type=${currentTab}&note_level=${val.type}&note=${val.note?val.note:""}`
                UpdatePhysiologicalNote(param).then(res=>{
                  if(res && !res.Error){
                    editForm.current.resetFields()
                    setModalStatus(null)
                    fetchApi()
                  }
                })

              }}
              // onFinishFailed={onFinishFailed}
              autoComplete="off"
              style={{ width: "100%" }}
            >
              <Form.Item
                label=""
                name="type"
                rules={[{ required: true, message: "請選擇!" }]}
              >
                <Radio.Group buttonStyle="solid">
                  <Radio.Button value="很好">很好</Radio.Button>
                  <Radio.Button value="不錯">不錯</Radio.Button>
                  <Radio.Button value="普通">普通</Radio.Button>
                  <Radio.Button value="勞累">勞累</Radio.Button>
                  <Radio.Button value="非常勞累">非常勞累</Radio.Button>
                </Radio.Group>
              </Form.Item>
              <Form.Item label="備註" name="note">
                <Input placeholder="example" />
              </Form.Item>
            </Form>
          </Modal>
        </>
      ) : (
        <div style={{ padding: 24, height: "100%" }}>
          <LoadingBlock />
        </div>
      )}
    </div>
  );
};

export default TechnicianHealth;

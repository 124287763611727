import axios from 'axios';

//引入qs模組，用來序列化post型別的資料
// import QS from 'qs';
const user = JSON.parse(window.localStorage.getItem('User'))
//設定axios基礎路徑
const service = axios.create({
    baseURL: process.env.REACT_APP__API_HOST,
    headers:{
      'Authorization': user?  `Bearer ${user.Token}`:''
    }
  })
  // 請求攔截器
  // service.interceptors.request.use(config => { 
    // 每次傳送請求之前本地儲存中是否存在token，也可以通過Redux這裡只演示通過本地拿到token
    // 如果存在，則統一在http請求的header都加上token，這樣後臺根據token判斷你的登入情況
    // 即使本地存在token，也有可能token是過期的，所以在響應攔截器中要對返回狀態進行判斷 
   
    // console.log(token)
    //在每次的請求中新增token
    // if(user){
    //   config.data = Object.assign({}, config.data, {
    //     token:  user.Token
    //   })
    // }
   
    //設定請求頭
    // config.headers = {
    //   'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8'
    // }
    // config.data = QS.stringify(config.data)
  //   return config
  // }, error => { 
  //     return error;
  // })
  
  // 響應攔截器
service.interceptors.response.use(response => {
    //根據返回不同的狀態碼做不同的事情

    if (response.status) {
      if(response.status === 200){
        return response.data;
      }
    } else { 

      return response;
    }
  }, error=>{
    if(error.response){
      //token過期處理方法
      if(error.response.status === 403){
        localStorage.removeItem("User")
        window.location.reload()
      }else{
        return error.response.data
      }
      // switch (error.response.status) {
      //   case 400:
      //     return error.response.data;
      //     //未登入處理方法
      //     break;
      //   case 403:
      //     //token過期處理方法
      //     break;
      //   default:
      // }
    }
  })
  //最後把封裝好的axios匯出
  export default service
import React, { useState, useEffect, useRef } from 'react';
import { Form, Input, Radio, Upload, Button, Cascader, Select } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { clone } from 'lodash'
import Slider from '../../shared/component/Slider';
// import { useSelector } from 'react-redux'
const { Option } = Select;

const Carexpandrow = ({ data, setData, submitForm, CarBrand }) => {
    const form = useRef()
    // let DispatchCarBrand = useSelector(state => state.CarBrand) 
    const [carData, setCarData] = useState(clone(data))
    const [iniCarData, setIniCarData] = useState(clone(data))
    const [mobility, setMobility] = useState(clone(data.mobility))
    const [CarModel, setCarModel] = useState([])
    useEffect(() => {
        setCarData(data)
        let model = CarBrand.filter(c => c.Name === data.CarBrand)
        if (model.length > 0) setCarModel(model[0]['children'])
    }, [data])
    useEffect(() => {
    }, [carData])
    useEffect(() => {
        if (submitForm !== undefined) {
            form.current.submit()
        }
    }, [submitForm])

    const onFinish = (value) => {
        let filter = CarModel.filter(c=> c.Code === value.CarType[0])
        carData.CarBrand = filter[0]["parentName"]
        carData.CarType = value.CarType[0]
        carData.VIN = value.CarPlate ? value.CarPlate : ""
        carData.CarColor = value.CarColor ? value.CarColor : ""
        carData.mobility = value.CarMobility
        carData.vehicle_model_id = value.CarType[0]
        let report = carData.unpower
        if (report) {
            if (report[0]) {
                report[0]["description"] = value.description
            } else {
                report.description = value.description

            }
            carData.unpower = report
        }
        setData({ carData, type: 1 })
    }
    const prevFill = () => {
        let prevMobility = iniCarData.mobility
        setMobility(prevMobility)
        form.current.setFieldsValue({
            CarType: [iniCarData.CarType],
            CarPlate: iniCarData.VIN,
            CarColor: iniCarData.color,
            CarMobility: prevMobility,
            description: iniCarData.unpower && iniCarData.unpower["description"] ? iniCarData.unpower["description"] : ""
        })
        if (prevMobility === "0") {
            let new_data = iniCarData
            // console.log(new_data)
            // if(new_data.unpower["uploadFile"]){
            // }
        }
    }
    let fileList = iniCarData.unpower && iniCarData.unpower["uploadFile"] ? iniCarData.unpower["uploadFile"] : [];

    return (
        <div>

            {
                carData.client_case_photos && carData.client_case_photos.length > 0 ? (
                    <Slider data={carData.client_case_photos} />
                ) : (<></>)
            }
            <Form
                ref={form}
                name="basic"
                layout="vertical"
                initialValues={{
                    CarType: carData.CarType === "" ? [] : [carData.CarType],
                    CarPlate: carData.VIN,
                    CarColor: carData.color,
                    CarMobility: carData.mobility,
                    CarBrand:carData.CarBrand,
                    description: data.unpower && data.unpower["description"] ? data.unpower["description"] : ""
                }}
                onFinish={onFinish}
                autoComplete="off"
            >
                <div style={{ display: "flex", margin: "8px 0 16px" }}>
                    <Form.Item
                        label="廠牌"
                        name="CarBrand"
                        rules={[{ required: true, message: '請選擇或輸入廠牌!' }]}
                        style={{ marginRight: '16px' }}
                    >
                        <Select
                            style={{width:200}}
                            placeholder="點擊選擇"
                            showSearch
                            onChange={(val) => {
                                let model = CarBrand.filter(c => c.Name === val)
                                if (model.length > 0) {
                                    setCarModel(model[0]["children"])
                                } else {
                                    setCarModel([])
                                }
                                form.current.setFieldsValue({CarType:[]})
                            }}>

                            {
                                CarBrand.map(c => (<Option value={c.Name}>{c.Name}</Option>))
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="車型"
                        name="CarType"
                        rules={[{ required: true, message: '請選擇或輸入車型!' }]}
                        style={{ marginRight: '16px' }}
                    >
                        <Cascader
                            options={CarModel}
                            allowClear
                            // onChange={(val) => (val[0])}
                            style={{ width: "200px" }}
                            placeholder="點擊選擇"
                            showSearch={(inputValue, path) => {
                                return path.some(
                                    (option) =>
                                        option.label
                                            .toLowerCase()
                                            .indexOf(inputValue.toLowerCase()) > -1
                                );
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        label="車牌 / 車身 / 引擎號碼"
                        name="CarPlate"
                    >
                        <Input style={{ width: "304px" }} placeholder='example' />
                    </Form.Item>
                </div>
                <div style={{ display: "flex" }}>
                    <Form.Item
                        label="車身顏色"
                        name="CarColor"
                        style={{ marginRight: '16px' }}
                    >
                        <Input style={{ width: "304px" }} placeholder='example' />
                    </Form.Item>
                    <Form.Item
                        label="動力狀況"
                        name="CarMobility"
                    >
                        <Radio.Group buttonStyle="solid"
                            onChange={
                                (e) => {
                                    let new_data = carData;
                                    new_data.unpower = [{
                                        uploadFile: [],
                                        description: ""
                                    }]
                                    setMobility(e.target.value)
                                    // form.setFieldsValue({ mobility: e.target.value });
                                }}
                        >
                            <Radio.Button value="1">正常</Radio.Button>
                            <Radio.Button value="0">無動力</Radio.Button>
                        </Radio.Group>
                    </Form.Item>
                </div>
                {
                    mobility === "0" ? (
                        <div style={{ paddingTop: 16 }}>
                            <div>
                                <span style={{ fontWeight: 500 }}>不可行駛原因</span>
                                <span style={{ color: "rgba(0, 0, 0, 0.45)", paddingLeft: 4 }}>請提供車輛照片或文字簡述車輛問題，以利調度和金額預估</span>
                            </div>
                            <div>
                                <p style={{ padding: "8px 0" }}>車輛照片</p>
                                <Upload
                                    listType="picture"
                                    defaultFileList={[...fileList]}
                                    className="upload-list-inline"
                                    accept="image/*"
                                    maxCount="4"
                                    customRequest={({ onSuccess }) => onSuccess("ok")}
                                    onChange={({ file, fileList }) => {
                                        let new_data = carData;
                                        // console.log(new_data.unpower.length)
                                        // console.log(new_data.unpower[0]["uploadFile"])
                                        if(new_data.unpower[0]){
                                            new_data.unpower[0]["uploadFile"]=fileList
                                        }else{
                                            new_data.unpower["uploadFile"] = fileList
                                        }
                                        setCarData(new_data)
                                    }}
                                >
                                    <Button icon={<UploadOutlined />}>上傳</Button>
                                </Upload>
                                <p style={{ color: "rgba(0, 0, 0, 0.45)", padding: "4px 0" }}>前後左右照片共 4 張</p>
                            </div>
                            <Form.Item
                                label="文字簡述"
                                name="description"
                                style={{ padding: "8px 0 0", marginBottom: 0 }}
                            >
                                <Input.TextArea rows={4}
                                    style={{ width: "400px" }}
                                    maxLength={100}
                                    showCount
                                />
                            </Form.Item>
                        </div>
                    ) : ''
                }
                <div style={{ marginTop: mobility === "0" ? 32 : 0 }}>
                    <Button type="primary" style={{ marginRight: 8 }} htmlType="submit">保存</Button>
                    <Button onClick={() => {
                        prevFill()
                        setData({ key: carData.key, type: 2, iniCarData }
                        )
                    }}>回復至修改前</Button>
                </div>
            </Form>
        </div>
    );
}

export default Carexpandrow;

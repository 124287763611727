import React, { useState, useEffect } from "react";
import { Radio, Button, Card, Tag, Empty,Pagination } from "antd";
import { CheckOutlined, StarOutlined, StarFilled} from "@ant-design/icons";
// import { ConfirmDispatch } from '../../api/Case'
import { useSelector,useDispatch } from "react-redux";
import { notifyChanges,unreadNotification } from "../../actions/notification";
import { GetNotificationStatus,Read,ReadAll,FavoriteNotify,UnfavoriteNotify,GetNotificationList } from "../../api/Notification";
import Match from "./component/DispatchCase";
import Status from "./component/Status";
import ButtonType from "./ButtonType"
const Index = ({ pageTitle, ContainerWidth }) => {
  const dispatch = useDispatch();
  // const notification = useSelector(state => state.Notification)
  const [currentTab, setCurrentTab] = useState("1");
  const [selectedData, setSelectedData] = useState(null);
  const [Notification, setNotification] = useState(null);
    // const [DispatchChange, setDispatchChange] = useState(false)
    const [filterObj, setFilterObj] = useState({pageSize:10,pageIndex:0, status:"0"})

  useEffect(() => {
    fetchNotify()
  }, [filterObj]);
  useEffect(()=>{
    let container = document.querySelector(".container");
    container.scrollTo(0, 0);
  },[currentTab])
  const fetchNotify = ()=>{
    let container = document.querySelector(".container");
    container.scrollTo(0, 0);
    let param = ''
    Object.keys(filterObj).forEach((key,i)=>{
      param+= i=== 0? `${key}=${filterObj[key]}` : `&${key}=${filterObj[key]}`
    })
    GetNotificationList(param).then(NotifyRes => {
      if (NotifyRes && !NotifyRes.Error) {
        setNotification(NotifyRes.Result)
      
      }
    })
  }
 
  const changeStatus = async (type,obj) => {
    let res 
    if(type === "readAll" ){
      res = await ReadAll ()
    }else if(type === "read"){
      res = await Read(obj.row_id)
    }else if(type === "star"){
      res = await FavoriteNotify(obj.row_id)
    }else{
      res = await UnfavoriteNotify(obj.row_id)
    }
    if(res &&!res.Error){
      GetNotificationList(`pageSize=10&pageIndex=0`).then(NotifyRes => {
        if (!NotifyRes.Error) {
          dispatch(unreadNotification(NotifyRes.Result.RowsCount))
        }
      })
      fetchNotify()
    } 
  };
  const getDetail = async (value, tab, test) => {
    if(tab === "2a"){
    let success = value.success
    let success_arr = []
    if (success.length > 0) {
      for (let i = 0; i < success.length; i++) {
        let id = success[i]["execution_info_row_id"]
        let fetch = await GetNotificationStatus(id)
        if (fetch && !fetch.Error) {
          success[i]["status"] = fetch.Result === 8 ? 0 : 1
        }
        if(test){
          success[i]["status"] = 1
        }
        success_arr.push(success[i])
      }
    }
    value.success = success_arr
    }
    setSelectedData(value)
    setCurrentTab(tab)
  }
  return (
    <div className="container" style={ContainerWidth}>
      <div style={{ width: 684, margin: "24px auto" }}>
        {currentTab === "1" && (
          <>
            <p style={{ fontSize: 38, fontWeight: "bold" }}>通知</p>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "16px 0 24px",
              }}
            >
              <Radio.Group
                defaultValue={filterObj.status.toString()}
                buttonStyle="solid"
                onChange={(e) => setFilterObj({...filterObj, status:e.target.value})}
              >
                <Radio.Button key="unread" value="0">
                  未讀
                </Radio.Button>
                <Radio.Button key="read" value="1">
                  已讀
                </Radio.Button>
                <Radio.Button key="important" value="2">
                  重要訊息
                </Radio.Button>
              </Radio.Group>
              {filterObj.status === "0" && Notification && Notification.RowsCount > 0 && (
                <Button onClick={() => changeStatus("readAll")}>全部已讀</Button>
              )}
            </div>
            {Notification && Notification.RowsCount > 0 ? (
              Notification.Data.map((n) => {
                let content_arr = n.content.split("|");
                return (
                  <div key={n.id}>
                    <div style={{ position: "relative", marginBottom: 8 }}>
                      <Card
                        title={
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <strong>{n.title}</strong>
                            {
                              !n.is_read &&(<Tag
                              color="processing"
                              style={{ borderRadius: 100, marginLeft: 4 }}
                            >
                              未讀
                            </Tag>)
                            }  
                          </div>
                        }
                        extra={
                          n.is_star ? (
                            <StarFilled
                              style={{
                                fontSize: 20,
                                color: "rgb(24, 144, 255)",
                                cursor:"pointer"
                              }}
                              onClick={() => changeStatus("unstar", n)}
                            />
                          ) : (
                            <StarOutlined
                              style={{
                                fontSize: 20,
                                color: "rgba(0,0,0,0.45)",
                                cursor: "pointer",
                              }}
                              onClick={() => changeStatus("star", n)}
                            />
                          )
                        }
                      >
                        <div style={{ display: "flex" }}>
                          {/* <div
                            style={{
                              width: 32,
                              height: 32,
                              background: "rgba(0,0,0,0.45)",
                              borderRadius: "50%",
                            }}
                          ></div> */}
                          <div style={{ marginLeft: 8 }}>
                            <p style={{ color: "rgba(0,0,0,0.45)" }}>
                              {n.user}
                              <span style={{ color: "#BFBFBF", fontSize: 12 }}>
                                系統通知 {n.time}
                              </span>
                            </p>
                            <p>
                              {content_arr.map((d, i) => (
                                <>
                                  {i % 2 === 0 ? (
                                    d
                                  ) : (
                                    <span style={{ color: "#1890FF" }}>
                                      {d}
                                    </span>
                                  )}
                                </>
                              ))}
                            </p>
                          </div>
                        </div>
                        <ButtonType
                          type={n.title}
                          data={n}
                          changeCurrenTab={(tab, val) =>
                            getDetail({...val.data,type:val.type}, tab)
                          }
                        />
                      </Card>
                      {!n.is_read && (
                        <div
                          style={{
                            position: "absolute",
                            right: -40,
                            top: "50%",
                            transform: "translateY(-50%)",
                          }}
                          onClick={() => changeStatus("read",n)}
                        >
                          <div
                            style={{ height: 32, width: 32, borderRadius: "50%", background: "#1890FF", cursor: "pointer", display: "flex", alignItems: "center", justifyContent: "center"}}
                          >
                            <CheckOutlined
                              style={{ display: "block", color: "white", fontSize: 16}}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })
            ) : (
              <div
                style={{ width: "100%",  height: 400, position: "relative", background: "white"}}
              >
                <Empty
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                />
              </div>
            )}
            {
            Notification&& Notification.PageCount>1 &&(
              <div style={{margin:"24px 0 48px", display:'flex', justifyContent:"center"}}>
              <Pagination 
              defaultCurrent={filterObj.pageIndex+1} 
              total={ Notification.RowsCount} 
                onChange={(val)=>{
                  setFilterObj({...filterObj, pageIndex:val-1})
                }}
              />
              </div>
            )
          }
          </>
        )}
        {currentTab === "2a" && selectedData && (
          <Match
            selectedData={selectedData}
            SetCurrentTab={(val)=>setCurrentTab(val)}
            dispatchData={(s) => {}}
          />
        )}
        {currentTab === "2b" && selectedData && (
          <Status
            selectedData={selectedData}
            SetCurrentTab={(val)=>setCurrentTab(val)}
          />
        )}
         
      </div>
    </div>
  );
};

export default Index;

import React,{useState,useEffect} from 'react'
import BreadCrumb from '../../../components/shared/BreadCrumb'
import { PageHeader,Button,Table} from 'antd' 
import { PlusOutlined} from '@ant-design/icons'
import {  useNavigate,useLocation } from 'react-router-dom'
import {GetAdvertisementList} from '../../../api/Advertisement'
import { RouteName } from '../../../util/route'
import { useSelector } from 'react-redux'


const Index =({ContainerWidth}) => {
  const [fetchData, setFetchData] = useState(null)
  const { state,pathname } = useLocation()
  const routeId = RouteName(pathname)
  let UserInfo = useSelector(state => state.UserInfo) 
  let UserPermission = UserInfo? UserInfo.permission.route: null
  let navigate = useNavigate()
  useEffect(() => {
    GetAdvertisementList(0,-1).then(res=>{

      if(!res.Error){
        let Data = res.Result.Data 
        res.Result.Data = res.Result.Data.map(d=>{
          let arr=["start_date","end_date"]
          arr.forEach(a=>{
            let date_arr = d[a].split("T")
            d[a] = `${date_arr[0].replaceAll("-",".")} ${date_arr[1]}`
          })
      //     d.status_color = d.status === 0 ? "#F5222D" :  d.status === 1 ? "#52C41A":  "#D9D9D9"
      //     d.tag_color = d.status === 0 ? "red" :  d.status === 1 ? "green":  "default"
      //     if(!d.note){
      //       d.note="-"
      //     }
          return d
        })
        setFetchData(Data)
      }
    })
  }, []);
  const columns = [
    {
      title: '狀態',
      dataIndex: 'Status',
      width: '10%',
      render : (status,_) => {
        return (<div style={{ display: "flex", alignItems: "center" }}>
      <div style={{ width: 6, height: 6, borderRadius: "50%", background: status === "1"?"#52C41A": status ==="2" ?"#D9D9D9":"rgb(245, 34, 45)", marginRight: 8 }}></div>
      <p>{status ==="1" ?"上架中":status ==="2" ? "未啟用" :"已結束"}</p>
    </div>)
      }

  },
    {
      title: '上架時間',
      dataIndex: 'start_date',
      // render : (data,_) => (<span>{moment(data).format("YYYY.MM.DD HH:MM")}</span>)
      // width: '100px',
    },
    {
      title: '下架時間',
      dataIndex: 'end_date',
      // render : (data,_) => (<span>{moment(data).format("YYYY.MM.DD HH:MM")}</span>)
    //   sorter: true,
    },
    {
      title: '廣告說明',
      dataIndex: 'dm_desc',
      width:"35%",
      render:(data)=> (<span>{data===""?"-":data}</span>)
    },
    {
      title: '廣告連結',
      dataIndex: 'dm_link',
      render : (data,_) => (<Button type='link' onClick={(e)=>{
        e.stopPropagation()
        let url = data.indexOf("http")>-1? data : `https://${data}`
        window.open(url,'_blank')
      }}>點擊前往</Button>)

    }
 ]
  return (
    <div className='container' style={ContainerWidth}>
    <PageHeader
            style={{background: "white", width: "100%"}}
            title="廣告版位"
            className="site-page-header"
            extra={UserPermission && UserPermission[routeId] ===2?[
            <Button type="primary" icon={<PlusOutlined />}
              onClick={()=> navigate('/customer/advertisement/add')}
            >
                新增廣告
            </Button>
            ]:[]}
            breadcrumb={<BreadCrumb/>}
        >
        </PageHeader>
        <div className='table_block'>
        {
          fetchData &&( <Table
                columns={columns}
                rowKey={record => record.row_id}
                dataSource={fetchData}
                // pagination={data.length > 10 ? pagination : false}
                pagination={fetchData.length > 10 ? {
                        total: fetchData.length,
                        showTotal: (total, range) =>
                            `${range[0]}-${range[1]} 列（共${total}  列）`,
                    } : false}
                // loading={loading}
                style={{padding: "12px 0", minHeight: 400}}
                onRow={(record, _) => {
                    return {
                    onClick: event => { navigate(`/customer/advertisement/${record.row_id}`)},
                    };
                }}

            />)
        }
           
        </div>
    </div>
  )
}


export default Index;
import React, { useState, useEffect } from 'react';
import { PageHeader, Steps } from 'antd'
import BreadCrumb from '../../components/shared/BreadCrumb'
import Step2 from '../../components/cases/add/step2'
import Step3 from '../../components/cases/add/step3'
import { useNavigate, useLocation } from 'react-router-dom'
import { GetCheckCaseInfo } from '../../api/Case'
import { GetCaseType } from '../../api/Code'
import { useSelector, useDispatch } from 'react-redux'
import { addCase } from '../../actions/cases'
import { GetCustomerDetail } from '../../api/Customer';
import moment from 'moment'
import LoadingBlock from '../../components/shared/LoadingBlock'
const Verify = ({ pageTitle, ContainerWidth }) => {
    const [currentStep, setCurrentStep] = useState(0)
    const [caseId, setCaseId] = useState(null)
    const [caseData, setCaseData] = useState(null)
    const navigate = useNavigate()
    const { state } = useLocation()
    const dispatch = useDispatch()
    let store_case = useSelector(state => state.Cases).customerInfo
  let DispatchCarBrand = useSelector(state => state.CarBrand)
  const [AllCarBrand, setAllCarBrand] = useState(null)

    const FetchAPI = async () => {
        dispatch(addCase([{ key: 1, mobility: "1", no: 1 }]))
        if (state) {
            if (state.caseId) {
                setCaseId(state.caseId)
                let data = await GetCheckCaseInfo(state.caseId)
                let case_type = await GetCaseType()
                case_type = case_type.Result
                if (!data.Error) {
                    let Result = data.Result
                    let Data = []
                    for (let i = 0; i < Result.length; i++) {
                        let d = Result[i]
                        d.key = d.vehicle_sn
                        d.no = d.vehicle_sn
                        if(d.case_type_id === "014" || d.case_type_id === "015"){
                            d.components_note = d.case_content
                        }
                        d.caseId = state.caseId
                        let filter_customer = case_type.filter(c => c.Code === d.case_type_id)
                        if (filter_customer.length > 0) {
                            d.case_type = `${d.case_type_id} - ${filter_customer[0]["Name"]}運送`
                        } else {
                            d.case_type = ''
                        }
                        if(d.case_type_id !=="014" && d.case_type_id !=="015" ){
                            d.mobility = (d.power_status).toString()
                            d.CarPlate = d.VIN
                            if(d.vehicle_model_id){
                                let carBrand = AllCarBrand.filter(a=>a.Code ===d.vehicle_model_id.substring(0,3))
                                d.CarBrand =carBrand.length>0?carBrand[0]["Name"]:''
                            }
                          
                            d.CarType = d.vehicle_model_id
                            d.color = d.vehicle_color
                            d.mobility = d.power_status.toString()
                            d.unpower = {
                                recordMethod: d.report_way,
                                description: d.report_content,
                                uploadFile: d.report_picture,
                            }
                        }
                        d.calling_date = moment(d.calling_date)
                        d.calling_time = moment(d.calling_time, 'HH:mm')
                        let calling_arr = []
                        if (d.calling_source === "LINE") {
                            calling_arr.push(d.calling_source)
                            if (d.line_group_name) calling_arr.push(d.line_group_name)
                            if (d.line_id) calling_arr.push(d.line_id)
                        } else {
                            if (d.calling_person_name) calling_arr.push(d.calling_person_name)
                            if (d.calling_person_phone) calling_arr.push(d.calling_person_phone)
                        }
                        // let customer = await GetCustomerDetail(d.order_customer)
                        // if (customer && !customer.Error)
                        //  console.log(customer.Result)
                        //     d.calling_arr = [d.calling_source, customer.Result.name]
                        //     d.order_customer_name = customer.Result.name
                        //     d.order_customer_name = customer.Result.name
                        let service = ["auction_no","car_hood_note","car_hood_type","case_content"]
                        service.forEach(s=> {
                            if(!d[s]) delete d[s]
                        })
                        Data.push(d)
                    }
                    // console.log(Data)
                    dispatch(addCase({ cases: Data }, Result[0]))
                }
            }
        }
    }
    useEffect(() => {
        if (DispatchCarBrand !== null) setAllCarBrand(DispatchCarBrand)
        if ( AllCarBrand !== null) {
            FetchAPI()
        }
      }, [DispatchCarBrand, AllCarBrand])
    // useEffect(() => {
    //     document.title = pageTitle
    //     FetchAPI()
    // }, []);
    return (
        <div className='container' style={ContainerWidth}>
            <PageHeader
                title="案件審核"
                onBack={() => navigate(-1)}
                className="site-page-header"
                breadcrumb={<BreadCrumb />}
            >
            </PageHeader>
            {
                currentStep === 0 && store_case ? (<Step2 setCurrentStep={(data) => {
                    setCaseId(data.caseId)
                    setCurrentStep(1)
                    setCaseData(data.caseData)

                }} />) : currentStep === 1 && caseData !== null ? (<Step3
                    caseId={caseId}
                    caseData={caseData}
                    setCurrentStep={(step) => setCurrentStep(step)}
                />) : (<div style={{ background: 'white', margin: 24 }}>
                    <LoadingBlock />
                </div>)
            }
        </div>
    )
}

export default Verify
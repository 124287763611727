import React from 'react'
import { Skeleton } from 'antd';
const LoadingBlock = () => {
  return (
    <div style={{background: 'white', display:'flex', flexDirection:'column', padding:24 }}>
        <Skeleton active style={{marginBottom: 16, }} />
        <Skeleton active style={{marginBottom: 16, }} />
        <Skeleton active style={{marginBottom: 16, }} />
        <Skeleton active style={{}} />
    </div>
  )
}

export default LoadingBlock
import Employee from '../../../components/customers/Add/employee'
import DeliveryCenter from '../../../components/customers/Add/deliveryCenter'
import PricingModule from '../../../components/customers/Add/pricingModule'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
const Details = ({ ContainerWidth, pageTitle }) => {
    const { state } = useLocation()
    let currentTab = state.subTab? state.subTab : state.tab
    return (<>
      <div className='container' style={ContainerWidth}>
            {
                currentTab === "2" && <Employee propState={state}/>
            }
            {
                currentTab === "3" && <DeliveryCenter propState={state}/>
            }
            {
                currentTab === "4" && <PricingModule propState={state}/>
            }
        </div>
        
    </>)
}

export default Details;
import React, { useState, useEffect, useRef } from 'react'
import { Table, Tag, Button, Drawer, Space, Input } from 'antd'
import { ArrowLeftOutlined, SearchOutlined } from '@ant-design/icons'
import { GetUnmatchedCases } from '../../../api/Case'
import Highlighter from "react-highlight-words";
const CarList = ({ SetVisible, onConfirm, carList,width }) => {
    let [selectedCar, setSelectedCar] = useState(null)
    let [data, setData] = useState([])
    let [selectedRowKeys, setSelectedRowKeys] = useState([])
    let [loading, setLoading] = useState(true)
    const [searchText, setSearchText] = useState("");
    const [searchedColumn, setSearchedColumn] = useState("");
    const searchInput = useRef(null);
    const handleSearch = (setSelectedKeys,selectedKeys, confirm, dataIndex) => {
        let value = searchInput.current.input.value? searchInput.current.input.value.replace("台","臺"):''
        setSelectedKeys([value])
        setSearchText(value);
        setSearchedColumn(dataIndex);
        confirm();
    };

    const handleReset = (clearFilters, confirm) => {
        clearFilters();
        setSearchText("");
        confirm();
    };
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }} >
                <Input
                    ref={searchInput}
                    placeholder='請輸入關鍵字'
                    value={selectedKeys[0]}
                    // onChange={(e) =>
                    //     setSelectedKeys(e.target.value ? [e.target.value.replace("台", "臺")] : [])
                    // }
                    // onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: "block" }}
                />
                <div style={{ width: "100%", display: "grid", gridTemplateColumns: "repeat(2,1fr)", gridGap: 8 }}>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(setSelectedKeys,selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                    > 搜尋 </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters, confirm)}
                    >
                        清除
                    </Button>
                </div>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? "#1890ff" : undefined
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <div>
                    {
                        dataIndex === 'status' ? (<Tag color={text === '正常' ? 'green' : 'red'}>{text}</Tag>) : (<Highlighter
                            highlightStyle={{
                                backgroundColor: "#ffc069",
                                padding: 0
                            }}
                            searchWords={[searchText]}
                            autoEscape
                            textToHighlight={text ? text.toString() : ""}
                        />)
                    }
                </div>

            ) : (
                dataIndex === 'status' ? <Tag color={text === '正常' ? 'green' : 'red'}>{text}</Tag> : text
            )
    });
    const columnsTechnician = [
        {
            title: '廠牌 / 車型', dataIndex: 'model', key: 'model',
            ...getColumnSearchProps("model")

        },
        {
            title: '車牌 / 引擎 / 車身號碼', dataIndex: 'vin', key: 'vin',
            width: '20%',
            render: (record, data) => (<div>{record ? record : '-'}</div>),
            ...getColumnSearchProps("vin")
        },
        {
            title: '動力狀況', dataIndex: 'status', key: 'mobility',
            width: '12%',
            // render: (record) => record === 1 ? (<Tag color='green'>正常</Tag>) : (<Tag color='red'>不可行駛</Tag>),
            ...getColumnSearchProps("status")
        },
        {
            title: '起運點', dataIndex: 'origin', key: 'origin', ...getColumnSearchProps("origin")
        },
        { title: '迄運點', dataIndex: 'destination', key: 'destination', ...getColumnSearchProps("destination") },
        // {
        //     title: '案件狀態', dataIndex: 'case_status', key: 'status',
        //     render: (record) => (<Tag >{StatusObj(record).text}</Tag>)
        // },
    ]
    const fetchData = async () => {
        let res = await GetUnmatchedCases(0, 100)
        let cars = []
        if (res && !res.Error) {
            cars = res.Result.Data.map(c => {
                c.model = `${c.vehicle_model_name}/${c.vehicle_name}`
                c.status = c.power_status === 1 ? '正常' : '不可行駛'
                return c
            })
            let pageCount = res.Result.PageCount
            if (pageCount > 1) {
                for (let i = 1; i < pageCount; i++) {
                    let car_res = await GetUnmatchedCases(i, 100)
                    if (car_res && !car_res.Error) {
                        car_res.Result.Data.forEach(c => cars.push({ ...c, model: `${c.vehicle_model_name}/${c.vehicle_name}` }))
                    }
                }
            }
            let selected = []
            carList.forEach(car => {
                let filter = cars.filter(d => d.case_row_id === car.case_row_id)
                if (filter.length === 1) {
                    selected.push(filter[0]["case_row_id"])
                }
            })
            setData(cars)
            setSelectedRowKeys(selected)
            setLoading(false)
        }

    }
    useEffect(() => {
        fetchData()
    }, [])
    return (
        <Drawer
            title={
                <div>
                    <ArrowLeftOutlined
                        style={{ cursor: "pointer" }}
                        onClick={() => SetVisible(1)}
                    />
                    <span style={{ fontWeight: 700, paddingLeft: 16 }}>新增車輛</span>
                </div>
            }
            placement="right"
            width={width?width:"1200px"}
            closable={false}
            visible={true}
            footer={
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button type="text" style={{ marginRight: 16 }} onClick={() => {
                        SetVisible(1)
                        setSelectedCar(carList)
                    }
                    }>取消</Button>
                    <Button type="primary"
                        //  disabled={selectedRowKeys.length > 0 ? false : true} 
                        style={{ marginRight: 16 }} onClick={() => {
                            let cars = selectedCar ? selectedCar.map(c => {
                                c.vehicle_model_name = `${c.vehicle_model_name}/${c.vehicle_name}`
                                return c
                            }) : []
                            onConfirm(cars)
                        }
                        }>新增</Button>
                </div>
            }
        >
            <Table
                rowSelection={{
                    type: 'checkbox',
                    onChange: (selectedRowKeys, selectedRows) => {
                        setSelectedCar(selectedRows)
                        setSelectedRowKeys(selectedRowKeys)
                    },
                    selectedRowKeys: selectedRowKeys
                }}
                rowKey={record => record.case_row_id}
                loading={loading}
                dataSource={data}
                columns={columnsTechnician}
                pagination={false}
                style={{ margin: "8px 0 24px" }}
            />
        </Drawer>
    );
}
export default CarList;

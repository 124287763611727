import React from 'react'
import { Tag } from 'antd'
import { CloseOutlined } from '@ant-design/icons'

const FilterTag = ({ columns, filterObject, onDelete, Filter }) => {
  return (
    <div>
    
      {
        filterObject && (
          <div className='filter_tabs_block' style={{ padding: 24, paddingBottom: 0 }}>
            {
              Object.keys(Filter).map(filter => {
                if (filter !== "transport_date") {
                  let name_arr = columns.filter(c => c.dataIndex === filter)
                  if (name_arr.length === 0) return false
                  let name = name_arr[0]["title"]
                  let formFilterObject = { ...filterObject }
                  let formFilter = { ...Filter }
                  return formFilterObject[filter]?.map(f => (<p className='tab' onClick={() => {
                    let index
                    formFilterObject[filter].map((obj, i) => {
                      index = i
                      return obj
                    })
                    let filterObj = formFilter[filter] ? formFilter[filter].filter((obj, i) => i !== index) : []
                    let tagFilterObj = formFilterObject[filter] ? formFilterObject[filter].filter((obj, i) => i !== index) : []

                    if (filterObj.length === 0) {
                      delete formFilter[filter];
                      delete formFilterObject[filter]
                    } else {
                      // if (filter === 'service_type') {
                      //   let new_filterObj = filterObj.map((f, i) => (`${f},${tagFilterObj[i]}`))
                      //   formFilter[filter] = filterObj
                      //   formFilter['service'] = new_filterObj
                      // } else {
                      // }
                      // if(filterObj.length === 0 && filter === 'service'){
                      //   delete formFilter[filter];
                      //   delete formFilterObject[filter]
                      // }
                      formFilter[filter] = filterObj
                      formFilterObject[filter] = tagFilterObj
                    }
                    onDelete(formFilter, formFilterObject)
                  }}>
                    {name}：{f}
                    {/* <Tag closable ></Tag> */}
                    <CloseOutlined style={{ color: "rgba(0, 0, 0, 0.45)", fontSize: 14, paddingLeft: 4 }} />
                  </p>))
                }
              })
            }
          </div>
        )
      }
    </div>
  )
}

export default FilterTag
import React, { useState, useEffect, useRef } from 'react'
import { Table, Tag, Button, Input, Drawer, Radio, Form} from 'antd'
import { CloseOutlined, PlusOutlined} from '@ant-design/icons'
import { StatusObj } from '../../util/status'
import { CancelExecution, ManualCloseExecution } from '../../api/Execution'
import { ChangeDispatchCase, ManualChangeDispatchCase } from '../../api/Case'
import TemporaryDriver from './TemporaryDriver'
// import NonOfficialDriver from './execute/NonOfficialDriver'
import Driver from '../shared/Dispatch/Driver'
import Route from '../shared/Dispatch/Route'
import TechnicianList from '../cases/case/TechnicianList'
import CarList from '../cases/case/CarList'
import FullRoute  from '../shared/Dispatch/FullRoute'
const { TextArea } = Input

const ChangeRoute = ({ executeId, carData, onClose, onSuccess, driverInfo }) => {
  const [selectedCar, setSelectedCar] = useState(null)
  const [extraSelectedCar, setExtraSelectedCar] = useState([])
  const [manualCarList, setManualCarList]= useState(null)
  const [selectedRoute, setSelectedRoute] = useState(null)
  const [option, setOption] = useState(null)
  const [submitObj, setSubmitObj] = useState({})
  const [btnDisabled, setBtnDisabled] = useState(true)
  const [fetchLoading, setFetchLoading] = useState(false)
  const [selectedDriver, setSelectedDriver] = useState(null)
  const submitRef = useRef()
  const [visible, setVisible] = useState(1)
  // const [technicianList, setTechnicianList] = useState({})
  // const [driverExecutionList, setDriverExecutionList] = useState(null)
  // const [hoverAddBtn, setHoverAddBtn] = useState(null)
  const [reason, setReason] = useState(null)
  const cancalOption = [
    { label: '聯絡不上', value: '1' },
    { label: '車輛故障', value: '2' },
    { label: '臨時請假', value: '3' },
    { label: '客戶因素', value: '4' },
    { label: '督導調度', value: '5' },
    { label: '其他', value: '6' }
  ]
  const onSubmit = async (val) => {
    setFetchLoading(true)
    setBtnDisabled(true)
    let active = option.filter(o => o.active)[0]["title"]
    let res
    let obj = { ...val, vehicle_row_id_list: selectedCar.map(car => car.row_id) }
    let success = []
    if (active === "改派他人") {
      // let reason = val.reason.split(",")
      let value = {
        driver_row_id: selectedDriver[0]['driver_row_id'],
        ...selectedRoute[0],
        ...reason
      }
      if(manualCarList){
        let caseSorts = []
        manualCarList.forEach(route=>{
          route.car.forEach(c=>{
            caseSorts.push({
              case_info_row_id:c.case_info_row_id,
              case_action:c.case_action,
              case_sort:route.key
            })
          })
        })
        value.current_case_info_row_id =  selectedCar[0]['row_id']
        value.caseSorts = caseSorts
      }else{
        value.wait_match_case_list =  [...selectedCar,...extraSelectedCar].map(car => car.row_id || car.case_row_id)
      }
      
      let fetch = manualCarList? await ManualChangeDispatchCase(value) : await ChangeDispatchCase(value)
      if (fetch && !fetch.Error) {
        success.push(1)
      } else {
        success.push(0)
      }
    } else {
      if (active === '取消運送') {
        if(obj.reason)obj.reason = obj.reason.split(",")[0]
        res = await CancelExecution(executeId, obj)
      } else if (active === '紙本結案') {
        obj.service_point = selectedDriver[0]["service_point_name"]
        obj.driver = selectedDriver[0]["driver_name"]
        if (!obj.reason) {
          obj.reason = ''
        }
        res = await ManualCloseExecution(executeId, obj)

      }
    
      if (res && !res.Error) {
        success.push(1)
      } else {
        success.push(0)
      }
    }
    let filter = success.filter(s=>s===0)
    if(filter.length === 0){
      onSuccess(true)
    }
  }
  useEffect(() => {
    if (selectedCar) {
      let filter = selectedCar
      .filter(car => 
        ((car.case_status < 7  || (car.case_status ===6 && (car.execution_status ==="前往起運點" || car.execution_status ==="抵達起運點" ))) || car.case_status === 11 )
       )
      let closeCaseFilter = selectedCar.filter(car => car.case_status !== 11 && car.case_status > 6)
      if (selectedCar.length > 0) {
        setOption([
          { key: 1, title: "改派他人", desc: "被改派之車輛將由其他技師執行", status: filter.length > 0 ? 1 : 0, active: false },
          { key: 2, title: "取消運送", desc: "被取消運送之車輛狀態將改為取消", status: filter.length > 0 ? 1 : 0, active: false },
          { key: 3, title: "紙本結案", desc: "紙本結案之車輛視為完成運送，請於結案時確認金額", status: closeCaseFilter.length > 0 ? 0 : 1, active: false }
        ])
      } else {
        setOption(null)
      }
    } else {
      setOption(null)
    }
  }, [selectedCar])
  useEffect(() => {
    if (option) {
      let active = option.filter(o => o.active)[0]["title"]
      let obj = {}
      Object.keys(submitObj).forEach(key => {
        if (submitObj[key] !== '') obj[key] = submitObj[key]
      })
      if (active === '改派他人') {
        if (Object.keys(obj).length === 2) setBtnDisabled(false)
      }else if(active === '取消運送' ){
        if(obj.reason)setBtnDisabled(false)
      }
    }
  }, [submitObj])
  const columnsCar = [
    { title: '廠牌 / 車型', dataIndex: 'vehicle_model', key: 'vehicle_model' },
    { title: '車牌 / 引擎 / 車身號碼', dataIndex: 'VIN', key: 'VIN', render:val=>val?val:'-' },
    {
      title: '動力狀況', dataIndex: 'power_status', key: 'power_status',
      render: (mobility, index) => (<Tag color={mobility === 1 ? "green" : "red"}> {mobility === 1 ? "正常" : "不可行駛"}</Tag>)
    },
    { title: '起運點', dataIndex: 'Origin', key: 'Origin' },
    { title: '迄運點', dataIndex: 'Destination', key: 'Destination' },
    {
      title: '案件狀態', dataIndex: 'case_status', key: 'case_status',
      render: (status, obj) => (<Tag color={StatusObj(status) ? StatusObj(status).tagColor : ""}> { obj.execution_status? obj.execution_status: StatusObj(status) ? StatusObj(status).text : ""}</Tag>)
    },
  ]
  const columnsExtraCar = [
    { title: '廠牌 / 車型', dataIndex: 'model', key: 'model' },
    { title: '車牌 / 引擎 / 車身號碼', dataIndex: 'vin', key: 'vin', render:val=>val?val:'-' },
    {
      title: '動力狀況', dataIndex: 'power_status', key: 'power_status',
      render: (mobility, index) => (<Tag color={mobility === 1 ? "green" : "red"}> {mobility === 1 ? "正常" : "不可行駛"}</Tag>)
    },
    { title: '起運點', dataIndex: 'origin', key: 'origin' },
    { title: '迄運點', dataIndex: 'destination', key: 'destination' },
    {
      title: '', dataIndex: 'case_row_id', key: 'case_row_id',
      render: (id, obj) => (<Button 
      onClick={()=>setExtraSelectedCar([...extraSelectedCar].filter(c=>c.case_row_id !==id))}
      type='link'>刪除</Button>)
    },
  ]
  return (
    <>
      <Drawer
        title="內容變更"
        visible={true}
        closable={false}
        width={"1280px"}
        style={{ top: 0, left: "unset", margin: 0, marginLeft: 208, paddingBottom: 0 }}
        bodyStyle={{ height: "calc(100vh - 108px)", overflow: "scroll" }}
        extra={[
          <CloseOutlined
            style={{ cursor: "pointer", color: "rgba(0, 0, 0, 0.45)" }}
            onClick={() => onClose(null)}
          />]}
        footer={
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button type="text" style={{ marginRight: 16 }}
              onClick={() => onClose(null)}
            >取消</Button>
            <Button type="primary"
              disabled={btnDisabled}
              onClick={() => {
                submitRef.current.submit()
              }}>{fetchLoading ? '變更中...' : '變更'}</Button>
          </div>
        }
      >
        <div>
          <div style={{ display: "flex", justifyContent: "space-between", marginBottom: 8 }}>
            <div style={titleStyle}>車輛名單</div>
          </div>
          <Table
            rowSelection={{
              onChange: (selectedRowKeys, selectedRows) => {
                setSelectedCar(selectedRows)
              },
            }}
            rowKey={record => record.row_id}
            dataSource={carData}
            columns={columnsCar}
            pagination={false}
            style={{ margin: "8px 0 28px" }}
          />
          <div style={{ display: "grid", gridTemplateColumns: "repeat(3,1fr)", gridGap: 8 }} className='execute_detail_block'>
            {
              option && option.map(o => (
                <button
                  key={o.title}
                  className={o.active ? 'active' : o.status === 0 ? 'disabled' : ''}
                  disabled={o.status === 1 ? false : true}
                  onClick={() => {
                    let new_opt = [...option].map(opt => {
                      opt.active = false
                      if (opt.title === o.title) {
                        opt.active = !o.active
                      }
                      return opt
                    })

                    if (o.key === 3) {
                      setSelectedDriver([driverInfo])
                      setBtnDisabled(false)
                    } else if (o.key === 1) {
                      // setSelectedDriver([])
                      setBtnDisabled(true)
                    } else {
                      // setBtnDisabled(false)
                    }
                    setExtraSelectedCar([])
                    setManualCarList(null)
                    setOption(new_opt)
                    submitRef.current.setFieldsValue({reason:null})
                  }}
                >
                  <p style={{ fontWeight: 700 }}>{o.title}</p>
                  <p style={{ color: 'rgba(0, 0, 0, 0.45)', marginTop: 16, fontSize: 14 }}>{o.desc}</p>
                </button>)
              )
            }
          </div>
          
          {
            option && (option[0]["active"]) ? (
              <div>
                <Button 
                  style={{marginBottom: 16}} 
                  type="dashed" 
                  block
                  onClick={()=>setVisible('car')}
                >
                  <PlusOutlined /> 額外加入更多車輛
                </Button>
                {
                  extraSelectedCar.length>0 &&(
                    <Table
                      rowKey={record => record.row_id}
                      dataSource={extraSelectedCar}
                      columns={columnsExtraCar}
                      pagination={false}
                      style={{ margin: "8px 0 28px" }}
                    />
                  )
                }
                
                <Driver
                  type={'official'}
                  active={option[0]["active"]?0:2}
                  SetVisible={val => {
                    setVisible(val)
                  }}
                  visible={7}
                  selectedCar={[...selectedCar,...extraSelectedCar]}
                  selectedTechnician={selectedDriver}
                  SetSelectedTechnician={(val,type) => {
                    let driver = null
                    if(val){
                      driver = val[0]? val:[val]
                    }
                    setManualCarList(type)
                    setSelectedDriver(driver)
                  }} />
                {
                  selectedDriver && option[0]['active'] && (
                    <>
                      {
                        manualCarList?(
                          <FullRoute
                              type="official"
                              visible={7}
                              reasonOption={cancalOption}
                              selectedCar={selectedCar}
                              selectedTechnician={selectedDriver}
                              SetselectedTechnician={(val)=> {
                                setSelectedDriver(val)
                              }}
                              setBtnDisabled={(val) => {
                                setBtnDisabled(val)
                              }}
                              setReason={(val) => setReason(val)}
                              manualCarList={manualCarList}
                              selectedRoute={selectedRoute}
                              setSelectedRoute={(val)=> {
                                setSelectedRoute(val)
                              }}
                            />
                        ):(
                          <Route
                            type="official"
                            visible={7}
                            reasonOption={cancalOption}
                            selectedCar={selectedCar}
                            selectedTechnician={selectedDriver}
                            SetselectedTechnician={(val)=> setSelectedDriver(val)}
                            //selectedDriver={(val) => setSelectedDriver(val)}
                            setBtnDisabled={(val) => {
                              setBtnDisabled(val)
                            }}
                            selectedRoute={selectedRoute}
                            setSelectedRoute={(val) => setSelectedRoute(val)}
                            setReason={(val) => setReason(val)}
                          />
                        )
                      }
                    </>
                    
                  )
                }
                {/* {
                  selectedDriver && !selectedDriver.valid &&(
                    <div className='disabled_info_block'>
                    <CloseCircleTwoTone twoToneColor="#F5222D" ClassName='close_icon'/>
                    <p>此車種無法配合運送，請選擇其他技師</p>
                    <Button type='primary'
                      onClick={()=> setSelectedDriver(null)}
                    >重新選擇</Button>
                    </div>
                  )
                } */}
              </div>) : (<div></div>)
          }

          
          <Form
            ref={submitRef}
            name="basic"
            layout="vertical"
            onFinish={onSubmit}
          >
            {
              option && (option[1]["active"]) && (
                <>
                  <Form.Item
                    label={option[1]["active"] ? "取消原因" : "改派原因"}
                    name="reason"
                    style={{ marginBottom: 8 }}
                  >
                    <Radio.Group
                      onChange={(e) => {
                        setSubmitObj({ ...submitObj, reason: e.target.value })
                        setBtnDisabled(true)
                      }}
                      buttonStyle="solid" >
                      {
                        cancalOption.map(o => (<Radio.Button value={`${o.value},${o.label}`} key={o.value}>{o.label}</Radio.Button>))
                      }
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item
                    label=""
                    name="reason_note"
                  >
                    <TextArea
                      onKeyUp={(e) => {
                        setSubmitObj({ ...submitObj, reason_note: e.target.value})
                        setBtnDisabled(submitObj.reason && e.target.value?false: true)
                      }}
                      placeholder="請在此輸入補充說明..."
                      autoSize={{ minRows: 2, maxRows: 3 }}
                    />
                  </Form.Item>
                </>
              )
            }
            {
              option && option[2]["active"] &&  selectedDriver && (
                <>
                  <Form.Item
                    label="紙本結案原因"
                    name="reason"
                  >
                    <TextArea
                      onKeyUp={(e) => setSubmitObj({ ...submitObj, reason_note: e.target.value })}
                      placeholder="如果可以，請簡述原因"
                      autoSize={{ minRows: 1, maxRows: 2 }}
                    />
                  </Form.Item>
                </>
              )
            }
          </Form>
        </div>
      </Drawer>
     
      {
        visible === "5b" && (
          <TemporaryDriver carData={selectedCar}
            executeId={executeId}
            active={option.filter(o => o.active)[0]["title"]}
            reasonOption={cancalOption}
            SetVisible={(val) => {
              if (val === null) {
                onSuccess(true)
              }else{
                setVisible(val)
              }
              
            }}
          />
        )
      }
      {
        visible === "4b" && (
          <TechnicianList
            reasonOption={cancalOption}
            carData={selectedCar}
            visible={visible}
            onSuccess={(val) => {
              // if (val) {
              //   setVisible(null)
              //   fetchAPI()
              // }
            }}
            SetVisible={(status) => setVisible(status)}
          />
        )

      }
      {
        visible === 'car' && (
          <CarList
            width="1280px"
            carList={extraSelectedCar?extraSelectedCar:[]}
            SetVisible={(status) => setVisible(status)}
            onConfirm={(val) => {
              let data = val
              if(val){
                // vehicle_model,Origin,Destination
                data = data.map(v=>{
                  v.vehicle_model = v.model
                  v.VIN = v.vin
                  v.Origin = v.origin
                  v.Destination = v.destination
                  return v
                })
              }
              setExtraSelectedCar(data)
              setVisible(null)
            }}
          />
        )
      }
     
    </>
  )
}
const titleStyle = {
  fontSize: 16,
  fontWeight: 700,
}
export default ChangeRoute
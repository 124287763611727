import React, { useState, useRef } from 'react'
import { Select, Input, Form, Button, Checkbox, Popconfirm, Radio, TimePicker } from 'antd'
import { PlusOutlined, UploadOutlined, UserOutlined, PhoneOutlined, ArrowRightOutlined } from '@ant-design/icons'
import CustomCascader from '../../shared/component/CustomCascader'
import { AddStation } from '../../../api/ServicePoint'
import {  useNavigate } from 'react-router-dom'

// const { RangePicker } = DatePicker
// const { Option } = Select

const Step2 = ({ setCurrentStep,setSubmitData,data }) => {
    // const [extraTel, setExtraTel] = useState([])
    // const [extraArea, setExtraArea] = useState([])
    // const [extraTutor, setExtraTutor] = useState([])
    const serviceOptions = [
        { label: '運送車', value: '1' },
        { label: '道路救援', value: '2' },
      ];
    const form = useRef()
    const navigate =useNavigate()
    const onFinish = async (value) => {
        let obj={...data, ...value}
        obj.county_code= obj["address_county"][0]
        obj.town_code= obj["address_county"][1]
        obj.contract_period_start = obj["contract_period"][0].format("YYYY-MM-DD 00:00:00")
        obj.contract_period_end = obj["contract_period"][1].format("YYYY-MM-DD 12:59:59")
        let arr = ["social_cycle","responsible_area1","responsible_area2"]
        console.log(arr)
        arr.forEach(a=>{

            let text= ''
            if(obj[a]){
                let array = obj[a].indexOf(",")?obj[a].split(","):[obj[a]]
                array=array.map(o=> o.length === 5? o= `${o}000`: o)
                obj[a] = array.toString()

            }
        })
        const formdata = new FormData()
        for(let key in obj){
            if(obj[key]){
                if(key === 'file'){
                    formdata.append("contract_file", obj[key]["file"]["originFileObj"])
                }else{
                    formdata.append(key, obj[key])
                }
            }else{
                formdata.append(key, "")
            }
        }
        AddStation(formdata).then(res=>{
            if(!res.Error && res.Result.Success){
                let Data = {...res.Result.Data, name:obj.name, id:obj.id}
                setSubmitData(Data)
                setCurrentStep(2)

            }else{}
        })
    }
    const selectedTown = (val, key)=>{
        let text =''
        val.forEach((v,i)=>{
            text += i===0 ? v[v.length-1]:`,${v[v.length-1]}`
        })
        form.current.setFieldsValue({[key]:text})
    }
    return (
        <div >
            <Form
                ref={form}
                name="basic"
                layout="vertical"
                initialValues={{ category: "1"}}
                onFinish={onFinish}
                style={{display:"flex", flexDirection:"column", justifyContent:"space-between",minHeight:"calc(100vh - 258px)"}}
            >
                <div className='step_content'>
                    <div style={{ display: "flex" }}>
                        <Form.Item
                            label="錄用分類"
                            name="category"
                            rules={[{ required: true, message: '請選擇錄用分類!' }]}
                            style={{marginRight:16}}
                        >
                            <Radio.Group buttonStyle="solid">
                                <Radio.Button value="1">正式</Radio.Button>
                                <Radio.Button value="2">支援</Radio.Button>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item
                            label="可服務類別"
                            name="service_type"
                            rules={[{ required: true, message: '請選擇可服務類別!' }]}
                        >
                            <Checkbox.Group options={serviceOptions}
                            onChange={(val)=>{
                                let select =''
                                val.forEach((v,i)=>{
                                    select+= i===0? v : `,${v}`
                                })
                                form.current.setFieldsValue({service_type:select}) 
                            }} 
                            />
                        </Form.Item>
                    </div>
                    {/* <Form.Item
                        label="派遣時段"
                        name="contractPeiods"
                        rules={[{ required: true, message: '請選擇派遣時段！' }]}
                    >
                    <TimePicker.RangePicker style={{ width: "304px"}} />
                </Form.Item> */}
                    {/* <div style={{display: "flex", alignItems:"flex-end"}}>
                    <Form.Item
                        label="派車電話 - 1"
                        name="dispatcherTel"
                        rules={[{ required: true, message: '請輸入派車電話 - 1' }]}    
                    >
                        <Input allowClear placeholder='example' style={{ width: "304px"}} />
                    </Form.Item>
                   <Button 
                   style={{marginBottom: 24, marginLeft: 16}} 
                   type="dashed" 
                   icon={<PlusOutlined />}
                   onClick={()=> {
                       let new_extraTel = [...extraTel]
                       new_extraTel.push("");
                       setExtraTel(new_extraTel)
                   }}
                  
                   >新增電話</Button>
                    
                </div> */}
                    {/* {

                        extraTel.length > 0 && extraTel.map((e, i) => {
                            let label = `派車電話 - ${i + 2}`

                            return (<>
                                <Form.Item
                                    key={i}
                                    label={label}
                                    name=""
                                >
                                    <Input allowClear placeholder='example' style={{ width: "304px" }} />
                                </Form.Item>
                            </>)
                        })
                    } */}
                    <Form.Item
                        label="主要負責生活圈（可複選）"
                        name="social_cycle"
                        rules={[{ required: true, message: '請選擇主要負責生活圈' }]}
                    >
                        <CustomCascader
                            multiple
                            style={{ width: "304px" }}
                            OnChange={(val)=> selectedTown(val,'social_cycle')}
                        />
                    </Form.Item>
                    <Form.Item
                        label="負責區域 - 1（可複選）"
                        name="responsible_area1"
                        rules={[{ required: true, message: '請選擇負責區域 - 1' }]}
                    >
                        <CustomCascader
                            multiple
                            style={{ width: "304px" }}
                            OnChange={(val)=> selectedTown(val,'responsible_area1')}
                        />
                    </Form.Item>
                    <Form.Item
                        label="負責區域 - 2（可複選）"
                        name="responsible_area2"
                        // rules={[{ required: true, message: '請選擇主要負責生活圈' }]}
                    >
                        <CustomCascader
                            multiple
                            style={{ width: "304px" }}
                            OnChange={(val)=> selectedTown(val,'responsible_area2')}
                        />
                    </Form.Item>
                    <Form.Item
                        label="負責督導"
                        name="supervisor"
                        rules={[{ required: true, message: '請輸入名稱' }]}
                    >
                        <Input placeholder='請輸入名稱' style={{ width: "304px" }} />
                    </Form.Item>
                    {/* <div style={{display: "flex", alignItems:"flex-end"}}>
                    <Form.Item
                        label="負責區域 - 1"
                        name="inChargeArea"
                        rules={[{ required: true, message: '請輸入負責區域 - 1' }]}    
                    >
                        <Input allowClear placeholder='example' style={{ width: "304px"}} />
                    </Form.Item>
                   <Button 
                   style={{marginBottom: 24, marginLeft: 16}} 
                   type="dashed" 
                   icon={<PlusOutlined />}
                   onClick={()=> {
                       let new_extraArea = [...extraArea]
                       new_extraArea.push("");
                       setExtraArea(new_extraArea)
                   }}
                  
                   >新增區域</Button>
                </div> */}
                    {/* {
                
                    extraArea.length > 0 && extraArea.map( (e,i) =>{
                    let label = `負責區域 - ${i+2}`
                    return (<>
                        <Form.Item
                        key={i}
                        label={label}   
                    >
                            <Input allowClear placeholder='example' style={{ width: "304px"}} />
                        </Form.Item> 
                        </>)
                    })
                } */}
                    {/* <div style={{display: "flex", alignItems:"flex-end"}}>
                    <Form.Item
                        label="負責督導 - 1"
                        name="tutor"
                        rules={[{ required: true, message: '請輸入負責督導 - 1' }]}    
                    >
                        <Input allowClear placeholder='example' style={{ width: "304px"}} />
                    </Form.Item>
                   <Button 
                   style={{marginBottom: 24, marginLeft: 16}} 
                   type="dashed" 
                   icon={<PlusOutlined />}
                   onClick={()=> {
                       let new_extraTutor = [...extraTutor]
                       new_extraTutor.push("");
                       setExtraTutor(new_extraTutor)
                   }}
                   >新增督導</Button>
                </div> */}
                    {/* {
                
                extraTutor.length > 0 && extraTutor.map( (e,i) =>{
                let label = `負責督導 - ${i+2}`
                return (<>
                    <Form.Item
                    key={i}
                    label={label}
                    // name="dispatcherTel"
                    // rules={[{ required: true, message: '請輸入派車電話 - 1' }]}    
                    >
                        <Input allowClear placeholder='example' style={{ width: "304px"}} />
                    </Form.Item> 
                    </>)
                })
            } */}
                </div>
                <div style={{ background: "white", marginTop: 16, width: "100%", padding: "20px", display: "flex", justifyContent: "flex-end" }}>
                <Popconfirm
                        style={{ width: 410 }}
                        placement="top"
                        title={
                            <div>
                                <div>
                                    <strong>新增還沒完成喔</strong>
                                </div>
                                <div style={{ fontSize: 14 }}>
                                    剛剛輸入的所有資訊將不被保存。
                                </div>
                            </div>
                        }
                        onCancel={() => navigate(-1)}
                        okText="不，我要留下"
                        cancelText="對，我要離開"
                    >
                        <Button type="text" style={{ marginRight: 24 }}>
                            關閉
                        </Button>
                    </Popconfirm>
                    <Button type="primary" icon={<ArrowRightOutlined />}
                        htmlType="submit"
                    > 新增 </Button>
                </div>
            </Form>
        </div>
    );
}

export default Step2;

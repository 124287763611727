import React, { useEffect } from 'react'
import moment from 'moment'
const History = ({ data }) => {
    let now = moment().format("YYYYMMDD")
    useEffect(() => {
        if (data) {
            let split_block = document.querySelector(".split_block > .content_block") ? document.querySelector(".split_block > .content_block").clientHeight : 0
            if (document.querySelector(".split_block > .history_block")) {
                document.querySelector(".split_block > .history_block").style.maxHeight = `${split_block}px`
            }
        }
    }, []);
    return (
        <div className='history_block' >
            <div
                style={{
                    fontWeight: 500,
                    padding: "16px",
                    borderBottom: "1px solid rgba(240, 240, 240, 1)",
                }}
            > 案件歷程 </div>
            <ul style={{ padding: 32, margin: 0 }}>
            {
                data.map(d=>{
                    let addDay = moment(d.create_time).add(1,"d").format("YYYYMMDD")
                    let time= addDay === now ?`昨天 ${moment(d.create_time).format("HH:mm")}`:moment(d.create_time).format("YYYYMMDD")===now? `今天 ${moment(d.create_time).format("HH:mm")}`: moment(d.create_time).format("YYYY.MM.DD HH:mm")
                    return(
                    <li
                    key={`${d.create_user_row_id}_${d.create_time}`}
                    style={{
                        fontSize: 14,
                        display: "flex",
                        justifyContent: "space-between",
                        color: "rgba(0, 0, 0, 0.25)",
                        marginBottom: 18,
                    }}
                >
                
                    <p>
                    {
                        d.action_content_list.map(list=> (list.has_del || list.change_color) ? (<span style={{textDecoration:list.has_del ?"line-through":""}}>{list.content_txt}</span>):list.content_txt !=="" ? (
                            <span style={{ color: !list.change_color? "rgba(0, 0, 0, 0.85)":"black", padding: 4 }}>
                            {list.content_txt}
                        </span>
                        ):(<></>))
                    }
                    </p>
                    {
                        
                    }
                    <p style={{ color: "rgba(0, 0, 0, 0.25)" }}>{time}</p>
                </li>
                )
                })
            }
            </ul>
        </div>
    )
}

export default History
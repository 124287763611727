import React, { useState, useEffect,useRef } from 'react';
import { PageHeader, Steps, Form, Button, Input, Upload, DatePicker, Select, Cascader, Radio,message } from 'antd'
import { PlusOutlined, UploadOutlined, ArrowRightOutlined, RightOutlined, UnorderedListOutlined,EnterOutlined } from '@ant-design/icons'
import Checkbox from 'antd/lib/checkbox/Checkbox'
import { useParams, useNavigate } from 'react-router-dom'
import BreadCrumb from '../../components/shared/BreadCrumb'
import { GetServicePoint,UpdateServicePointCarNote,AddServicePointCar,CheckServicePointCarNo } from '../../api/ServicePoint'
import { GetCarBrandList,GetCarTypeList } from '../../api/Code';
import moment from 'moment'
const { Step } = Steps
const { TextArea } = Input
const { Option } = Input
const { RangePicker } = DatePicker

const steps = [
    { title: '基本資訊', description: '請輸入車籍的基本資訊', content: 'First-content', width: "30%" },
    { title: '保險資料', description: '確認該車輛的保險狀況', content: 'Second-content', width: "100%" },
    { title: '完成', description: '完成後即可派遣', content: 'Last-content', width: "30%" },
];
const Technicianadd = ({ ContainerWidth }) => {
    let navigate = useNavigate()
    let { pointId } = useParams()
    const [check, setCheck] = useState(false)
    const [currentStep, setCurrentStep] = useState(0)
    const [isStop, setIsStop] = useState("0")
    const [remark, setRemark] = useState("")
    const [infoData, setInfoData] = useState(null)
    const [CarBrand, setCarBrand] = useState(null)
    const [submitData, setSubmitData] = useState(null)
    const [finalData, setFinalData] = useState(null)
    const [isValid, setIsValid] = useState(null)
    const [carType, setCarType] = useState([])
    const submitForm = useRef()

    const checkCarPlate = async () => {
        let no = submitForm.current.getFieldValue().no
        let res = await CheckServicePointCarNo(pointId,no)
        setIsValid(res.Result)
        return res.Result
    }
    useEffect(() => {
        GetCarTypeList().then(res=>{
            if(res&&!res.Error){
                setCarType(res.Result)
            }
        })
        GetServicePoint(pointId).then(res => {
            if (!res.Error) setInfoData(res.Result)
        })
        GetCarBrandList().then(res => {
            if (!res.Error) {
                let Result = res.Result.map(r => {
                    r.value = r.Code
                    r.label = r.Name
                    return r
                })
                setCarBrand(Result)
            }
        })
    }, []);
    const onFinish = async (val) => {
        let check = await checkCarPlate()
        if (!submitData) {
            for(let key in val){
                if(key.indexOf("date")>-1  || key === "leave_yymm"){
                    val[key] = moment(val[key]).format("YYYY-MM-DD")
                }else if(key==='brand'){
                    val[key] = val[key][0]
                }else if(key === 'kind'){
                    let arr = val[key].split(",")
                    val[key] = arr[0]
                    val[`${key}_text`] = arr[1]
                }
            }
            setSubmitData(val)
        } else {
            let formData =  new FormData();
            let new_submit_data = { ...submitData, ...val }
            let insurance_date=["voluntary","compulsory","cargo"]
            insurance_date.forEach(i=>{
                new_submit_data[`${i}_start`] = moment(new_submit_data[`${i}_date`][0]).format("YYYY-MM-DD 00:00:00")
                new_submit_data[`${i}_end`] = moment(new_submit_data[`${i}_date`][1]).format("YYYY-MM-DD 23:59:59")
            })

            new_submit_data.service_point_row_id = pointId
            new_submit_data.update_uid ='00050'
            for(let key in new_submit_data){
                if(key.indexOf('file')>-1){
                    if(new_submit_data[key]){
                        formData.append(key,new_submit_data[key]['file']["originFileObj"])
                    }
                }else{
                    if(new_submit_data[key]){
                        formData.append(key,new_submit_data[key])
                    }
                }
            }
            if(currentStep===1){
                AddServicePointCar(formData).then(res=>{
                    if(!res.Error){
                        let final = {...res.Result.Data, no:new_submit_data.no, kind:new_submit_data.kind_text}
                        setFinalData(final)
                        setCurrentStep(currentStep+1)
                        if(submitForm.current) submitForm.current.resetFields()
                    }
                })
            }
        }
        if(currentStep===0 && check){
            setCurrentStep(currentStep+1)
        }
    }
    return (
        <div className='' style={ContainerWidth} >
            <PageHeader
                title={"新增車籍"}
                onBack={() => currentStep===0? window.history.go(-1):setCurrentStep(currentStep-1)}
                className="site-page-header"
                breadcrumb={infoData && <BreadCrumb Info={{ [pointId]: infoData.name }} />}
            >
            </PageHeader>
            <div className='step_block' >
                <Steps current={currentStep} style={{ padding: "16px 24px", borderBottom: "1px solid rgba(0, 0, 0, 0.06)", margin: "24px 24px 0 24px", background: "white", width: "calc(100% - 48px)" }}>
                    {steps.map(item => (
                        <Step key={item.title} title={item.title} description={item.description} style={{ width: item.width }} />
                    ))}
                </Steps>
                <Form
                    style={{ minHeight: "calc(100vh - 200px)", display: "flex", flexDirection: "column", justifyContent: "space-between" }}
                    name="basic"
                    layout="vertical"
                    initialValues={{
                        fleet_vehicles: isStop,
                        gas_kind: "04"
                    }}
                    ref={submitForm}
                    onFinish={onFinish}
                    autoComplete="off"
                >
                    {
                        currentStep === 0 && (
                            <div className='step_content' >
                                <div style={{ display: "flex" }}>
                                    <Form.Item
                                        label="車牌號碼"
                                        name="no"
                                        rules={[{ required: true, message: '請輸入車牌號碼!' }]}
                                        hasFeedback={isValid !== null ? true : false}
                                        validateStatus={isValid === null ? '' : isValid ? 'success' : 'error'}
                                        help={isValid !== null && !isValid? '此車牌號碼已存在!':''}
                                    >
                                        <Input  
                                        placeholder='點擊選擇' style={{ width: "304px" }} 
                                        addonAfter={<EnterOutlined style={{ cursor: "pointer" }} onClick={checkCarPlate} />} 
                                        onKeyUp={(e) => submitForm.current.setFieldsValue({ no: e.target.value.toUpperCase() })}
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        label="車種"
                                        name="kind"
                                        rules={[{ required: true, message: '請輸入車種！' }]}
                                        style={{ margin: "0 16px" }}
                                    >
                                        <Select style={{width: 304}} placeholder='點擊選擇'>
                                            {
                                                carType.map(car=>(<option value={`${car.Code},${car.Name}`} key={`${car.Code}_${car.Name}`}>{car.Name}</option>))
                                            }
                                        </Select>
                                    </Form.Item>
                                    <Form.Item
                                        label="板架號碼"
                                        name="chassis_no"
                                    >
                                        <Input allowClear placeholder='example' style={{ width: 304, marginTop:2 }} />
                                    </Form.Item>
                                </div>
                                <div style={{ display: "flex" }}>
                                    <Form.Item
                                        label="引擎號碼"
                                        name="engine_no"
                                        rules={[{ required: true, message: '請輸入引擎號碼!' }]}
                                    >
                                        <Input allowClear placeholder='example' style={{ width: "304px" }} />
                                    </Form.Item>
                                    <Form.Item
                                        label="車身號碼"
                                        name="body_no"
                                        rules={[{ required: true, message: '請輸入車身號碼！' }]}
                                        style={{ margin: '0 0 0 16px' }}
                                    >
                                        <Input allowClear placeholder='example' style={{ width: "304px" }} />
                                    </Form.Item>
                                </div>
                                <div style={{ display: "flex", alignItems: "end" }}>
                                    <Form.Item
                                        label="掛牌公司"
                                        name="company"
                                        rules={[{ required: true, message: '請輸入掛牌公司!' }]}
                                    >
                                        <Input allowClear placeholder='example' style={{ width: "304px" }} />
                                    </Form.Item>
                                    <Form.Item
                                        label="掛牌公司統一編號"
                                        name="company_no"
                                        rules={[{ required: true, message: '請輸入掛牌公司統一編號!' }]}
                                        style={{ marginLeft: 16 }}
                                    >
                                        <Input allowClear placeholder='example' style={{ width: "304px" }} />
                                    </Form.Item>

                                </div>
                                <div style={{ display: "flex", alignItems: "end" }}>
                                    <Form.Item
                                        label="廠牌"
                                        name="brand"
                                        rules={[{ required: true, message: '請輸入廠牌!' }]}
                                    >
                                        <Cascader
                                            style={{ width: 304 }}
                                            options={CarBrand}
                                            allowClear
                                            // onChange={(val) => onInputChange(val[0],"CarType",record)}
                                            // defaultValue={record.CarType}
                                            placeholder="點擊選擇"
                                            showSearch={(inputValue, path) => {
                                                return path.some(
                                                    (option) =>
                                                        option.label
                                                            .toLowerCase()
                                                            .indexOf(inputValue.toLowerCase()) > -1
                                                );
                                            }}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label="車別"
                                        name="type"
                                        rules={[{ required: true, message: '請選擇常車別！' }]}
                                        style={{ marginLeft: 16, marginRight: 16 }}
                                    >
                                        <Select
                                            allowClear
                                            style={{ width: 304 }}
                                            placeholder="點擊選擇"
                                        // onChange={handleChange}
                                        >
                                            <option value='營業大貨車'>營業大貨車</option>
                                            <option value='自用大貨車'>自用大貨車</option>
                                            <option value='自用大貨車-特種'>自用大貨車-特種</option>
                                            <option value='曳引車'>曳引車</option>
                                        </Select>
                                    </Form.Item>
                                    <Form.Item
                                        label="車色"
                                        name="color"
                                        rules={[{ required: true, message: '請輸入車色!' }]}
                                    >
                                        <Input allowClear placeholder='example' style={{ width: "304px" }} />
                                    </Form.Item>
                                </div>
                                <div style={{ display: "flex" }}>
                                    <Form.Item
                                        label="排氣量"
                                        name="engine_displacement"
                                        rules={[{ required: true, message: '請輸入排氣量!' }]}
                                        style={{ position: "relative" }}
                                    >
                                        <Input addonAfter="C.C" placeholder='example' style={{ width: "304px" }} />
                                    </Form.Item>
                                    <Form.Item
                                        label="載重"
                                        name="car_load"
                                        rules={[{ required: true, message: '請輸入排氣量!' }]}
                                        style={{ position: "relative", margin: "0 16px 24px" }}
                                    >
                                        <Input addonAfter="公噸" placeholder='example' style={{ width: "304px" }} />
                                    </Form.Item>
                                    <Form.Item
                                        label="總重"
                                        name="weight"
                                        rules={[{ required: true, message: '請輸入總重!' }]}
                                        style={{ position: "relative" }}
                                    >
                                        <Input addonAfter="公噸" placeholder='example' style={{ width: "304px" }} />
                                    </Form.Item>
                                </div>
                                <div style={{ display: "flex" }}>
                                    <Form.Item
                                        label="出廠年月"
                                        name="leave_yymm"
                                        rules={[{ required: true, message: '請選擇出廠年月!' }]}
                                        style={{ position: "relative" }}
                                    >
                                        <DatePicker style={{ width: "304px" }} />

                                    </Form.Item>
                                    <Form.Item
                                        label="行照發照日"
                                        name="license_date"
                                        rules={[{ required: true, message: '請選擇行照發照日!' }]}
                                        style={{ position: "relative", margin: "0 16px" }}
                                    >
                                        <DatePicker style={{ width: "304px", marginBottom: 24 }} />
                                    </Form.Item>
                                    <div>
                                        <div style={{ height: 32 }}></div>
                                        <Form.Item
                                            label=""
                                            name="license_file"
                                            style={{ position: "relative", margin: "0 16px 24px" }}
                                        >
                                            <Upload
                                                maxCount={1}
                                                customRequest={({ onSuccess }) => onSuccess("ok")}
                                            >
                                                <Button icon={<UploadOutlined />}>上傳電子檔</Button>
                                            </Upload>
                                        </Form.Item>

                                    </div>
                                </div>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <Form.Item
                                        label="下次驗車日"
                                        name="next_check_date"
                                        rules={[{ required: true, message: '請選擇下次驗車日!' }]}
                                    >
                                        <DatePicker style={{ width: "304px" }} />
                                    </Form.Item>
                                    <Form.Item
                                        label="行照有效日期"
                                        name="license_expiry_date"
                                        rules={[{ required: !check, message: '請行照有效日期!' }]}
                                        style={{ margin: "0 16px 24px" }}
                                    >
                                        <DatePicker style={{ width: "304px" }} disabled={check ? "disabled" : ""} />
                                    </Form.Item>
                                    <div>
                                        <div style={{ height: 32 }}></div>
                                        <Form.Item
                                            label=""
                                            name="license_no_change"
                                        >
                                            <Checkbox onChange={() =>{
                                                let current_check = !check
                                                submitForm.current.setFieldsValue({license_no_change: current_check?"1":"0"})
                                                 setCheck(!check)
                                            }}>免訂換</Checkbox>
                                        </Form.Item>
                                    </div>
                                </div>
                                <div style={{ display: "flex" }}>
                                    <Form.Item
                                        label="車機廠牌"
                                        name="machine_brand"
                                        rules={[{ required: true, message: '請選擇車機廠牌!' }]}
                                        style={{ position: "relative" }}
                                    >
                                        <Select style={{ width: 304 }} placeholder='點擊選擇' >
                                            <option value="長輝">長輝</option>
                                            <option value="衛星犬">衛星犬</option>
                                            <option value="卡好路">卡好路</option>
                                            <option value="無">無</option>
                                        </Select>
                                    </Form.Item>
                                    <Form.Item
                                        label="使用油品"
                                        name="gas_kind"
                                        rules={[{ required: true, message: '請選擇使用油品!' }]}
                                        style={{ position: "relative", marginLeft: 16 }}
                                    >
                                        <Radio.Group buttonStyle="solid">
                                            <Radio.Button value="04">超級柴油</Radio.Button>
                                            <Radio.Button value="01">92 無鉛</Radio.Button>
                                            <Radio.Button value="02">95 無鉛</Radio.Button>
                                            <Radio.Button value="03">98 無鉛</Radio.Button>
                                        </Radio.Group>
                                    </Form.Item>
                                </div>
                                <div style={{ display: "flex" }}>
                                    <Form.Item
                                        label="是否靠行"
                                        name="fleet_vehicles"
                                        rules={[{ required: true, message: '請選擇是否靠行!' }]}
                                        style={{ position: "relative", marginRight: 16 }}
                                    >
                                        <Radio.Group buttonStyle="solid" onChange={(e) => setIsStop(e.target.value)}>
                                            <Radio.Button value="0">非靠行</Radio.Button>
                                            <Radio.Button value="1">靠行</Radio.Button>
                                        </Radio.Group>
                                    </Form.Item>
                                    {/* {
                                        isStop === "0" ? (<Form.Item
                                            label="靠行單位"
                                            name="respArea-2"
                                            rules={[{ required: true, message: '請輸入靠行單位名稱!' }]}
                                            style={{ position: "relative" }}
                                        >
                                            <Input placeholder='靠行單位名稱' style={{ width: "304px" }} />
                                        </Form.Item>) : (<div></div>)
                                    } */}

                                </div>
                            </div>

                        )}
                    {
                        currentStep === 1 && (
                            <div className='step_content' >
                                <div>
                                    <div style={{ fontSize: 16, fontWeight: 500 }}>任意險</div>
                                    <div style={{ background: "rgba(250, 250, 250, 1)", padding: 16, marginTop: 8 }}>
                                        <div style={{ display: "flex" }}>
                                            <Form.Item
                                                label="保險公司"
                                                name="voluntary_company"
                                                rules={[{ required: true, message: '請輸入保險公司!' }]}
                                            >
                                                <Input placeholder='example' style={{ width: "304px" }} />
                                            </Form.Item>
                                            <Form.Item
                                                label="保單號碼"
                                                name="voluntary_no"
                                                // rules={[{ required: true, message: '請輸入保單號碼!' }]}
                                                style={{ position: "relative", margin: "0 16px 24px" }}
                                            >
                                                <Input placeholder='example' style={{ width: "304px" }} />

                                            </Form.Item>
                                            <div>
                                                <div style={{ height: 32 }}></div>
                                                <Form.Item
                                                    label=""
                                                    name="voluntary_file"
                                                    style={{ position: "relative", margin: "0 16px 24px" }}
                                                >
                                                    <Upload
                                                        maxCount={1}
                                                        customRequest={({ onSuccess }) => onSuccess("ok")}
                                                    >
                                                        <Button icon={<UploadOutlined />}>上傳電子檔</Button>
                                                    </Upload>
                                                </Form.Item>

                                            </div>
                                        </div>
                                        <div style={{ display: "flex" }}>
                                            <Form.Item
                                                label="保險期間"
                                                name="voluntary_date"
                                                rules={[{ required: true, message: '請選擇保險期間!' }]}
                                            >
                                                <RangePicker style={{ width: "304px" }} />
                                            </Form.Item>
                                            <Form.Item
                                                label="保單金額"
                                                name="voluntary_money"
                                                rules={[{ required: true, message: '請輸入保單金額!' }]}
                                                style={{ position: "relative", marginLeft: 16 }}
                                            >
                                                <Input placeholder='example' style={{ width: "304px" }} />

                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ margin: "8px 0" }}>
                                    <div style={{ fontSize: 16, fontWeight: 500 }}>強制險</div>
                                    <div style={{ background: "rgba(250, 250, 250, 1)", padding: 16, marginTop: 8 }}>
                                        <div style={{ display: "flex" }}>
                                            <Form.Item
                                                label="保險公司"
                                                name="compulsory_company"
                                                rules={[{ required: true, message: '請輸入保險公司!' }]}
                                            >
                                                <Input placeholder='example' style={{ width: "304px" }} />
                                            </Form.Item>
                                            <Form.Item
                                                label="保單號碼"
                                                name="compulsory_no"
                                                // rules={[{ required: true, message: '請輸入保單號碼!' }]}
                                                style={{ position: "relative", margin: "0 16px 24px" }}
                                            >
                                                <Input placeholder='example' style={{ width: "304px" }} />

                                            </Form.Item>
                                            <div>
                                                <div style={{ height: 32 }}></div>
                                                <Form.Item
                                                    label=""
                                                    name="compulsory_file"
                                                    style={{ position: "relative", margin: "0 16px 24px" }}
                                                >
                                                    <Upload
                                                        maxCount={1}
                                                        customRequest={({ onSuccess }) => onSuccess("ok")}
                                                    >
                                                        <Button icon={<UploadOutlined />}>上傳電子檔</Button>
                                                    </Upload>
                                                </Form.Item>

                                            </div>
                                        </div>
                                        <div style={{ display: "flex" }}>
                                            <Form.Item
                                                label="保險期間"
                                                name="compulsory_date"
                                                rules={[{ required: true, message: '請選擇保險期間!' }]}
                                            >
                                                <RangePicker style={{ width: "304px" }} />
                                            </Form.Item>
                                            <Form.Item
                                                label="保單金額"
                                                name="compulsory_money"
                                                rules={[{ required: true, message: '請輸入保單金額!' }]}
                                                style={{ position: "relative", marginLeft: 16 }}
                                            >
                                                <Input placeholder='example' style={{ width: "304px" }} />

                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ marginBottom: 72 }}>
                                    <div style={{ fontSize: 16, fontWeight: 500 }}>貨物運送險</div>
                                    <div style={{ background: "rgba(250, 250, 250, 1)", padding: 16, marginTop: 8 }}>
                                        <div style={{ display: "flex" }}>
                                            <Form.Item
                                                label="保險公司"
                                                name="cargo_company"
                                                rules={[{ required: true, message: '請輸入保險公司!' }]}
                                            >
                                                <Input placeholder='example' style={{ width: "304px" }} />
                                            </Form.Item>
                                            <Form.Item
                                                label="保單號碼"
                                                name="cargo_no"
                                                // rules={[{ required: true, message: '請輸入保單號碼!' }]}
                                                style={{ position: "relative", margin: "0 16px 24px" }}
                                            >
                                                <Input placeholder='example' style={{ width: "304px" }} />

                                            </Form.Item>
                                            <div>
                                                <div style={{ height: 32 }}></div>
                                                <Form.Item
                                                    label=""
                                                    name="cargo_file"
                                                    style={{ position: "relative", margin: "0 16px 24px" }}
                                                >
                                                    <Upload
                                                        maxCount={1}
                                                        customRequest={({ onSuccess }) => onSuccess("ok")}
                                                    >
                                                        <Button icon={<UploadOutlined />}>上傳電子檔</Button>
                                                    </Upload>
                                                </Form.Item>

                                            </div>
                                        </div>
                                        <div style={{ display: "flex" }}>
                                            <Form.Item
                                                label="保險期間"
                                                name="cargo_date"
                                                rules={[{ required: true, message: '請選擇保險期間!' }]}
                                            >
                                                <RangePicker style={{ width: "304px" }} />
                                            </Form.Item>
                                            <Form.Item
                                                label="保單金額"
                                                name="cargo_money"
                                                rules={[{ required: true, message: '請輸入保單金額!' }]}
                                                style={{ position: "relative", marginLeft: 16 }}
                                            >
                                                <Input placeholder='example' style={{ width: "304px" }} />

                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        )
                    }

                    {
                        currentStep === 2 && finalData && (<div style={{ minHeight: "calc(100vh - 200px)", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>

                            <div className='step_content'>
                                <div style={{ fontSize: 30, marginBottom: 8 }}>車籍<span style={{ color: "rgba(24, 144, 255, 1)", fontSize: 30 }}> #{finalData.no} / {finalData.kind} </span> 已新增</div>

                                <TextArea
                                    placeholder="額外的據點內容說明請寫在此......"
                                    autoSize={{ minRows: 2, maxRows: 4 }}
                                    style={{ width: 395 }}
                                    value={remark}
                                    onChange={(e) => setRemark(e.target.value)}
                                />
                                <Button
                                    style={{ display: "block", marginTop: 8 }}
                                    disabled={remark !== "" ? "" : "disabled"}
                                    onClick={() => {
                                    let updated_uid = '00001'
                                    let param = `row_id=${finalData.row_id}&note=${remark}&update_uid=${updated_uid}`
                                    UpdateServicePointCarNote(param).then(data => {
                                    if (data.Result.Success) {
                                        setRemark("")
                                        // console.log(formRef)
                                        // formRef.current.resetFields()
                                        message.success('已新增備註')
                                    }
                                    })
                                }}
                                >
                                    新增備註
                                </Button>
                                <div style={{ marginTop: 32 }}>
                                    <Button
                                        type="primary"
                                        icon={<PlusOutlined />}
                                        onClick={() => setCurrentStep(0)}
                                    >新增其他車籍</Button>
                                    <Button 
                                        icon={<RightOutlined />} style={{ margin: "0 8px" }}
                                        onClick={() => navigate(`/points/${pointId}/c${finalData.row_id}`)}
                                    >
                                    車籍內容</Button>
                                    <Button
                                        icon={<UnorderedListOutlined />}
                                        onClick={() => navigate(`/points/${pointId}`,{state:{tab:"4"}})}
                                    >回列表</Button>
                                </div>
                            </div>
                        </div>)
                    }
                    {
                        currentStep !== 2 && (<div style={{ background: "white", marginTop: 16, width: "calc(100%)", padding: "20px", display: "flex", justifyContent: "flex-end" }}>
                            <Button type="text" style={{ marginRight: 24 }} >
                                關閉
                            </Button>
                            <Button type="primary" icon={<ArrowRightOutlined />}
                                htmlType="submit"
                            >
                                下一步
                            </Button>

                        </div>)
                    }

                </Form>
            </div>
        </div>
    );
}

export default Technicianadd;

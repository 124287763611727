import React, { useState, useEffect, useRef } from 'react'
import BreadCrumb from '../../../components/shared/BreadCrumb'
import { GetCustomerList } from '../../../api/Customer'
import { PageHeader, Button, Select, Modal, Table, Form, Input,AutoComplete } from 'antd'
import { FilterOutlined, PlusOutlined, CloseOutlined } from '@ant-design/icons'
import { useNavigate, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RouteName } from '../../../util/route'
import CustomCascader from '../../../components/shared/component/CustomCascader'
// import FilterTag from '../../../components/shared/component/FilterTag'
// const { Option } = Select

const Index = ({ pageTitle, ContainerWidth }) => {
    const { state, pathname } = useLocation()
    const routeId = RouteName(pathname)
    let UserInfo = useSelector(state => state.UserInfo)
    let UserPermission = UserInfo ? UserInfo.permission.route : null
    const store = useSelector(state => state.CustomerList)
    let navigate = useNavigate(), submitRef = useRef()
    const [filterVisible, setFilterVisible] = useState(false)
    const [CustomerList, setCustomerList] = useState(false)

    const [CustomerListOption, setCustomerListOption] = useState([])
    const [allCustomerListOption, setAllCustomerListOption] = useState([])
    const [filterObjectLabel, setFilterObjectLabel] = useState(null)
    const [filterData, setFilterData] = useState(null)
    const filterName = { customer_name: "客戶別", tax_num: "公司統編", town_code_list: "公司區域" };
    const fetchList = async () => {
        let container = document.querySelector(".container")
        container.scrollTo(0, 0)
        let param = ''
        let reform = {}
        Object.keys(filterData).forEach((key, i) => {
            let value = filterData[key]
            // if (key === "customer_row_id") {
            //     let customer = CustomerListOption.filter(c => c.id === value[0])
            //     if (customer.length > 0) {
            //         value = customer[0]["row_id"]
            //     }
            // }
            if(key==='town_code_list'){
                let county = []
                let town = []
                value.map(v=>{
                    if(v.length === 1){
                        if(county.indexOf(v[0])=== -1) county.push(v[0])
                    }else{
                        town.push(v[1])
                    }
                })
                if(county.length >0)reform.county_code_list=county
                if(town.length >0)reform.town_code_list=town
            }else{
                if(value.length !== '') reform[key] = value
                
            }
        })
        Object.keys(reform).forEach((key, i) => {
            let value = reform[key]
           param += i === 0 ? `${key}=${value}` : `&${key}=${value}`
            
        })
        let res = await GetCustomerList(param)
        if (!res.Error) {
            let result = res.Result
            result.Data.map(d => {
                d.full_add = `${d.county_name ? d.county_name : ''}${d.town_name ? d.town_name : ''}${d.address}`
                return d
            })
            setCustomerList(result)
        }
    }
    const onChangeFilter = (obj) => {
        let filter = { ...filterData }
        Object.keys(obj).forEach(key => {
            filter[key] = obj[key]
        })
        setFilterData(filter)
    }
    useEffect(() => {
        if (state) {
            onChangeFilter(state)
        } else {
            onChangeFilter({ page_index: 0, page_size: 10 })
        }
    }, [state])

    useEffect(() => {
        if (filterData) fetchList()
    }, [filterData])

    useEffect(() => {
        if (store) {
            setCustomerListOption([...store])
            setAllCustomerListOption([...store])
        }
    }, [store])
    const columns = [
        { title: '客戶代號', dataIndex: 'id', width: '100px' },
        { title: '客戶名稱', dataIndex: 'name' },
        { title: '公司統編', dataIndex: 'tax_id_no' },
        { title: '公司地址', dataIndex: 'full_add' },
        {
            title: '配合狀態', dataIndex: 'status', width: '10%',
            render: status => status === 1 ? (<div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ width: 6, height: 6, borderRadius: "50%", background: "rgba(82, 196, 26, 1)", marginRight: 8 }}></div>
                <p>配合中</p>
            </div>) : (<div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ width: 6, height: 6, borderRadius: "50%", background: "rgba(217, 217, 217, 1)", marginRight: 8 }}></div>
                <p>取消</p>
            </div>)
        }
    ]
    return (
        <div className="container" style={ContainerWidth}>
            <PageHeader
                style={{ background: "white", width: "100%" }}
                title="客戶清單"
                className="site-page-header"
                extra={UserPermission && UserPermission[routeId] === 2 ? [
                    <Button
                        key="篩選客戶列表"
                        icon={<FilterOutlined />}
                        onClick={() => {
                            setFilterVisible(true)
                            setTimeout(() => {
                                submitRef.current.resetFields()
                                let filter ={...filterData}
                                // filter[]
                                if(filter.customer_row_id){
                                    filter.customer_row_id = `${filter.customer_row_id[0]},${filterObjectLabel.customer_row_id[0] }`
                                }
                                submitRef.current.setFieldsValue(filter)
                            }, 100)

                        }}
                    >篩選</Button>,
                    <Button
                        key="新增客戶"
                        type="primary"
                        icon={<PlusOutlined />}
                        onClick={() => navigate('/customer/lists/add')}
                    >
                        新增客戶
                    </Button>
                ] : [
                    <Button
                        key="篩選客戶列表"
                        icon={<FilterOutlined />}
                        onClick={() => setFilterVisible(true)}
                    >篩選</Button>
                ]}
                breadcrumb={<BreadCrumb />}
            >
            </PageHeader>
            {
                filterObjectLabel && (
                    <div className='filter_tabs_block' style={{ padding: 24, paddingBottom: 0 }}>
                        {
                            Object.keys(filterObjectLabel).map(label => (
                                <>
                                    {
                                        filterObjectLabel[label].map(l =>
                                        (<p className='tab' onClick={() => {
                                            let filter = { ...filterData }, filterLabel = { ...filterObjectLabel }
                                            let index = filterLabel[label].indexOf(l)
                                            let value = filter[label].filter((Label, i) => i !== index)
                                            filterLabel[label] = filterLabel[label].filter(Label => Label !== l)
                                            if(value.length===0){
                                                delete filter[label]
                                            }else{
                                                filter[label] = value
                                            }
                                            if(!filter.customer_name) setCustomerListOption(allCustomerListOption)
                                            setFilterData(filter)
                                            setFilterObjectLabel(filterLabel)
                                        }}>
                                            {filterName[label]}：{l}
                                            <CloseOutlined style={{ color: "rgba(0, 0, 0, 0.45)", fontSize: 14, paddingLeft: 4 }} />
                                        </p>))
                                    }
                                </>
                            ))
                        }
                    </div>
                )
            }
            <div className='table_block'>
                <Table
                    columns={columns}
                    rowKey={(record) => record.row_id}
                    dataSource={CustomerList.Data}
                    pagination={CustomerList.PageCount > 1 ? {
                        current: filterData.page_index + 1,
                        total: CustomerList.RowsCount,
                        showTotal: (total, range) =>
                            `${range[0]}-${range[1]} 列（共${total}  列）`,
                    } : false}
                    loading={CustomerList ? false : true}
                    style={{ padding: "12px 0", minHeight: 400 }}
                    onRow={(record, rowIndex) => {
                        return {
                            onClick: event => { navigate(`/customer/lists/${record.row_id}`) }
                        }
                    }}
                    onChange={(pagination) => onChangeFilter({ page_index: pagination.current - 1 })}
                />

            </div>
            {/* 篩選視窗 */}
            <Modal
                title={"篩選"}
                transitionName="ant-slide-right"
                style={{ left: "unset", top: 0, right: "-100%", paddingBottom: 0, margin: 0 }}
                bodyStyle={{ height: "calc(100vh - 108px)", overflow: "scroll" }}
                visible={filterVisible}
                onOk={() => submitRef.current.submit()}
                okText={"篩選"}
                onCancel={() => setFilterVisible(false)}
                width={284}
            >
                <Form
                    ref={submitRef}
                    name="basic"
                    layout="vertical"
                    onFinish={(value) => {
                        let dataObj = {}
                        let labelObj = {}
                        for (let key in value) {
                            if (value[key]) {
                                if (key === "address") {
                                    let address = []
                                    dataObj['town_code_list'] = value[key].map(a => {
                                        let text = ''
                                        a = a.map((item, i) => {
                                            let arr = item.split(",")
                                            text += arr[1]
                                            return arr[0]
                                        })
                                        address.push(text)
                                        return a
                                    })
                                    labelObj['town_code_list'] = address
                                } 
                                // else if (key === "customer_row_id") {
                                //     let arr = value[key].split(",")
                                //     labelObj[key] = [arr[1]]
                                //     dataObj[key] = [arr[0]]
                                // }
                                else {
                                    labelObj[key] = [value[key]]
                                    dataObj[key] = [value[key]]
                                }
                            }
                        }
                        onChangeFilter(dataObj)
                        setFilterObjectLabel(labelObj)
                        setFilterVisible(false)
                    }}
                    autoComplete="off"
                >
                    <Form.Item
                        label="客戶別"
                        name="customer_name"
                    >
                    <AutoComplete
                            options={(CustomerListOption || []).map(d => ({
                                value: d.name,
                                label: d.name,
                            }))}
                            onSearch={(searchText)=>{
                                let search = searchText? [...allCustomerListOption].filter(d=> d.name.toLowerCase().indexOf(searchText.toLowerCase())>-1):[...allCustomerListOption]
                                setCustomerListOption(search)
                            }}
                            allowClear
                            placeholder='請選擇'
                        />
                        {/* <Select
                            allowClear
                            showSearch
                            style={{ width: '100%', marginTop: 8 }}
                            placeholder="請選擇客戶別"
                            defaultValue={[]}
                        >
                            {
                                CustomerListOption.map(customer => (<Option value={`${customer.id},${customer.name}`} key={customer.label}>{customer.id} - {customer.name}</Option>))
                            }
                        </Select> */}
                    </Form.Item>
                    <Form.Item
                        label="公司統編"
                        name="tax_num"
                    >
                        <Input placeholder='公司統編' />
                    </Form.Item>
                    <Form.Item
                        label="公司區域"
                        name="address"
                    >
                        <CustomCascader
                            style={{ width: '100%' }}
                            multiple
                            NameCode
                            OnChange={(val) => {
                                submitRef.current.setFieldsValue({ address: val })
                            }}
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
}

export default Index;

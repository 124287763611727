import axios from "axios";
export const DownloadFile = async(url) => {
    let res = await axios.get(`${process.env.REACT_APP__API_HOST}/${url}`,{
        headers: { 
            "Content-Type": "application/json",
            'Authorization': `Bearer ${JSON.parse(window.localStorage.getItem('User')).Token}` },
        responseType: "blob",
    })
    // console.log(res["headers"])

    let filename = res["headers"]['content-disposition']?decodeURI(res["headers"]['content-disposition'].split("filename=")[1]):''
    const blob = new Blob([res.data], { type:  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
    const anchorElement = document.createElement('a');
    const URL = window.URL.createObjectURL(blob);
    anchorElement.href = URL;
    anchorElement.download = filename;
    anchorElement.click();
    window.URL.revokeObjectURL(URL);
    return blob
    
}
import moment from 'moment';
import React, { useState, useEffect } from 'react'
import { FilterHistory } from '../util/FilterHistory';
const HistoryRecord = ({ detail, History,name }) => {
    const [historyRecord, setHistoryRecord] = useState(null)
    useEffect(() => {
        if (detail && History ) {
            let container = document.querySelector(" .container").clientHeight - 108
            let split_block = !document.querySelector(".split_block > .content_block") ? 0:  document.querySelector(".split_block > .content_block").clientHeight <container?container:document.querySelector(".split_block > .content_block").clientHeight
            
            if (document.querySelector(".split_block > .history_block")) {
                document.querySelector(".split_block > .history_block").style.maxHeight = `${split_block}px`
            }
            if(History.length>0&&History[0]["action_content"]){
                setHistoryRecord(History)
            }else{
                FilterHistory(History).then(res => {
                    setHistoryRecord(res)

                })
            }
        }
    }, [detail, History]);
    return (

        <div className='history_block' >
            {
                historyRecord && (<>
                    <div style={{ fontWeight: 500, padding: "16px", borderBottom: "1px solid rgba(240, 240, 240, 1)" }}>{name?name: "歷程"}</div>
                    <ul style={{ padding: 32, margin: 0 }}>
                        {
                            historyRecord !== null && historyRecord.map((record,i) => {
                                {/* moment(record.time).format("YYYY.MM.DD HH:mm") */ }
                                if (record.action_content) {
                                    let date = record.time
                                    if(typeof(record.action_content)==="string"){
                                        date = record.create_time
                                        let split = record.action_content.split(" ")
                                        return (<li
                                        key={`${record.log_text}_${i}`}
                                        style={{
                                            // 
                                            color: "rgba(0, 0, 0, 0.25)",
                                            marginBottom: 18,
                                        }}
                                    >
                                        <p>
                                            {
                                                split.map((text,i) => i%2===0?(<span style={{color:"rgba(0, 0, 0, 0.85)"}}>{text}</span>):(<span style={text.style}>{text}</span>))
                                            }
                                        </p>
                                        <p style={{ color: "rgba(0, 0, 0, 0.25)" }}> {moment(date).format("YYYY.MM.DD HH:mm")}</p>
                                    </li>)
                                    }else{
                                        if (record.action_content[0] !== "") {
                                        if (record.action_content.length === 1) {
                                            return (<li 
                                            key={`${record.log_text}_${i}`}
                                            >
                                                <p>{record.action_content[0]}</p>
                                                <p style={{ color: "rgba(0, 0, 0, 0.25)" }}>{date}</p>
                                            </li>)
                                        } else {
                                            return (<li 
                                            style={{  color: "rgba(0, 0, 0, 0.25)", marginBottom: 18 }}
                                            key={`${record.log_text}_${i}`}
                                            >
                                                <p style={{ letterSpacing: "1.2px" }}>
                                                
                                                {
                                                    record.action_content.map((c,i)=>(
                                                        <span style={(i+1) % 2 !== 0 || i===5?{ color: 'rgba(0, 0, 0, 0.85)' }:{}}>{i===2?c.replaceAll("~", ""):c}</span>
                                                    ))
                                                }
                                                   
                                                    {/* {record.action_content[1]}
                                                    <span style={{ color: 'rgba(0, 0, 0, 0.85)' }}> {record.action_content[2].replaceAll("~", "")} </span>
                                                    {record.action_content[3]}<span style={{  color: 'rgba(0, 0, 0, 0.85)' }}>{record.action_content[4]}</span> */}
                                                </p>
                                                <p style={{ color: "rgba(0, 0, 0, 0.25)"}}>{date}</p>
                                            </li>)
                                        }
                                    } else {
                                        return (<li 
                                        style={{  color: "rgba(0, 0, 0, 0.25)", marginBottom: 18 }}
                                        key={`${record.log_text}_${i}`}
                                        >
                                            <p> <span style={{ color: 'rgba(0, 0, 0, 0.85)',  }}>{record.action_content[1]}</span> {record.action_content[2]}</p>
                                            <p style={{ color: "rgba(0, 0, 0, 0.25)" }}>{date}</p>
                                        </li>)
                                    }
                                    }
                                    
                                }else if(record.item){
                                    //生理監控異況紀錄
                                    return(<li 
                                    key={`${record.item}_${record.time}`}
                                    style={{  display: "flex", justifyContent: 'space-between', marginBottom: 18 }}>
                                    <p> {record.content}</p>
                                    <p style={{ color: "rgba(0, 0, 0, 0.25)" }}>{record.time}</p>
                                </li>)
                                }else if(record.items){
                                    return(<li 
                                    key={`${record.item}_${record.time}`}
                                    style={{  display: "flex", justifyContent: 'space-between', marginBottom: 18 }}>
                                    <p> {
                                        record.items.map((a,i)=> (<span style={{color:a.change_color? '':"rgba(0, 0, 0, 0.25)", textDecoration:a.has_del?" line-through":""}}>{a.content_txt}</span>))
                                    }</p>
                                    <p style={{ color: "rgba(0, 0, 0, 0.25)" }}>{record.date}</p>
                                    </li>)
                                }else {
                                    return (<li
                                        key={`${record.log_text}_${record.time}`}
                                        style={{
                                            
                                            color: "rgba(0, 0, 0, 0.25)",
                                            marginBottom: 18,
                                        }}
                                    >
                                        <p>
                                            {
                                                record.text.map(text => (<span style={text.style}>{text.title}</span>))
                                            }
                                        </p>
                                        <p style={{ color: "rgba(0, 0, 0, 0.25)" }}> {record.date}</p>
                                    </li>)
                                }

                            })
                        }
                    </ul>
                </>)
            }

        </div>
    )
}

export default HistoryRecord
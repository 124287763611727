import React, { useState, useEffect, useRef } from 'react'
import { Table, Tag, Input, Button, Drawer, Radio, Select, Form } from 'antd'
import { UserOutlined, ArrowLeftOutlined } from '@ant-design/icons'
import { GetNonOfficialDriverList, SupportDriverCar,ChangeDispatchCase,DispatchCase } from '../../../api/Case'
import { GetUnoccupiedCarList } from '../../../api/Code'
import Driver from '../../shared/Dispatch/Driver'

const { TextArea } = Input

const Manualdispatch = ({ carData, visible, SetVisible, reasonOption,onSuccess,caseData }) => {
    const [selectedTechnician, setSelectedTechnician] = useState(null)
    const [selectedCar, setSelectedCar] = useState(carData)
    const [carList, setCarList] = useState([])
    const [selectedDriverCar, setSelectedDriverCar] = useState(null)
    const [technicianList, setTechnicianList] = useState([])
    const [driverList, setDriverList] = useState([])
    const [AvailableCarType,setAvailableCarType] =useState([])
    const submitRef = useRef()
    const [reason, setReason] = useState({})
    const [btnDisabled, setBtnDisabled] = useState(true)
    const setValue = (val, key) => {
        let obj = { ...reason }
        if (val === '') {
            delete obj[key]
        } else {
            obj[key] = val
        }
        setReason(obj)
        
    }
    const onSubmit = async () => {
        // setFetchLoading(true)
        let success = []
        if(visible === "4b"){
            let value = {
                wait_match_case_list: selectedCar.map(car=>car.case_row_id),
                driver_row_id: selectedTechnician[0]['driver_row_id'],
                car_info_row_id: selectedDriverCar,
                action_type: "add",
                is_add_last: true,
                ...reason
            }
            let fetch = await ChangeDispatchCase(value)
            if (fetch && !fetch.Error) {
                success.push(1)
            } else {
                success.push(0)
            }
        }else{
            let filter = selectedCar.filter(car => car.case_row_id !== caseData.case_info_row_id)
            let obj = {
              current_case_info_row_id:caseData.case_info_row_id,
              wait_match_case_list: filter.map(d => d.case_row_id),
              driver_row_id: selectedTechnician[0]['driver_row_id'],
              action_type:"add",
              is_add_last:true,
              car_info_row_id:selectedDriverCar
            }
            let fetch = await DispatchCase(obj)
            if (fetch && !fetch.Error) {
                success.push(1)
            } else {
                success.push(0)
            }
        }
        
        let filter = success.filter(s=>s===0)
        if(filter.length === 0){
          onSuccess(true)
        }
    }
    useEffect(() => {
        let param = ''
        carData.forEach((s,i)=>{
        param+= i===0?`wait_match_case_list[${i}]=${s.case_row_id}`:`&wait_match_case_list[${i}]=${s.case_row_id}`
      })
        GetNonOfficialDriverList(param).then(res => {
            if (res && !res.Error) {
                setDriverList(res.Result)
            }
        })
    }, []);
    useEffect(()=>{
        let disabled = true
        if(selectedDriverCar){
            if(visible === "4a"){
                disabled = false
            }else{
                if(Object.keys(reason).length ===2){
                    disabled = false

                }
            }
        }
        setBtnDisabled(disabled)
        
    },[selectedDriverCar,reason])
    useEffect(()=>{
        if(selectedTechnician){
              GetUnoccupiedCarList(selectedTechnician[0]["service_point_row_id"]).then(res => {
            if (res && !res.Error) {
                let data = res.Result.Data.map(d => {
                    let filter = ["全載車","三載車","四載車","六載車","八載車"].filter(type=> type ===d.kind_name)
                    let disabled = false
                    if(filter.length>0){
                        disabled = AvailableCarType[d.kind_name]?false:true
                    }
                    return { value: d.row_id, label: `${d.no} ${d.kind_name? `/ ${d.kind_name}`: ""}${disabled? "（無法配合載運）":""}`,disabled }
                })
                setCarList(data)
            }
        })
        }
    },[selectedTechnician])
    return (

        <>
            <Drawer
                title={
                    <div>
                        <ArrowLeftOutlined
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                SetVisible(visible.indexOf("b")>-1?7:1)
                            }}
                        />
                        <span style={{ fontWeight: 700, paddingLeft: 16 }}>支援技師</span>
                    </div>
                }
                placement="right"
                width={"808px"}
                closable={false}
                visible={visible}
                footer={
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button type="text" style={{ marginRight: 16 }} onClick={() => SetVisible(1)}>取消</Button>
                        <Button type="primary" disabled={ btnDisabled} style={{ marginRight: 16 }} onClick={() => onSubmit()}>{visible==="4a"?"派遣":"改派"}</Button>
                    </div>
                }
            >
             <Driver type="nonOfficial"
                SetVisible={val=> SetVisible(val)}
                visible={visible}
                availableCarType={(val)=>setAvailableCarType(val)}
                selectedCar={selectedCar}
                selectedTechnician={selectedTechnician}
                SetSelectedTechnician={(val) => {
                    if(val&&val[0]["plate_number"]){
                        setSelectedDriverCar(val[0]["car_row_id"])
                    }else{
                        setSelectedDriverCar(null)
                    }
                    setSelectedTechnician(val)
                }} />
               
                {
                    selectedTechnician && (
                        <>
                            <div style={{ display: "flex", alignItems: "center", marginBottom: 8, marginTop: 40 }}>
                                <div>指定車輛</div>
                                <div style={{ color: "rgba(0, 0, 0, 0.45)", marginLeft: 4 }}>支援技師沒有常用車，請替他選定車輛並同時指派案件</div>
                            </div>

                            <Form
                                ref={submitRef}
                                name="basic"
                                layout="vertical"
                            >
                                <Form.Item
                                    label="車輛"
                                    name="car"
                                    style={{ marginBottom: 8 }}
                                    rules={[{ required: true }]}
                                    required
                                >
                                    <Select
                                        onChange={(e) => setSelectedDriverCar(e)}
                                        defaultValue={selectedTechnician[0]["plate_number"]}
                                        disabled={selectedTechnician[0]["plate_number"] ? true : false}
                                        options={carList}
                                    />
                                </Form.Item>

                                {
                                    visible === "4b" && (
                                        <>

                                            <Form.Item
                                                label='改派原因'
                                                name="reason"
                                                rules={[{ required: true }]}
                                                style={{ marginTop: 24, marginBottom: 8 }}
                                            >
                                                <Radio.Group
                                                    onChange={(e) => setValue(e.target.value, "change_dispatch_reason_name")}
                                                    buttonStyle="solid" >
                                                    {
                                                        reasonOption.map(o => (<Radio.Button value={`${o.label}`} key={o.value}>{o.label}</Radio.Button>))
                                                    }
                                                </Radio.Group>
                                            </Form.Item>
                                            <Form.Item
                                                label=""
                                                name="reason_note"
                                            >
                                                <TextArea
                                                    onKeyUp={(e) => {
                                                        setValue(e.target.value, "change_dispatch_reason_description")
                                                    }}
                                                    placeholder="請在此輸入補充說明..."
                                                    autoSize={{ minRows: 2, maxRows: 3 }}
                                                />
                                            </Form.Item>
                                        </>
                                    )
                                }
                            </Form>

                        </>
                    )
                }

            </Drawer>
        </>
    );
}

const titleStyle = {
    fontSize: 16,
    fontWeight: 500,
}

export default Manualdispatch;

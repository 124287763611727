import moment from 'moment'
export const FilterHistory= async (history) => {

    let now = moment().format("YYYYMMDD")
    let history_list = history.map(h=>{
        let addDay = moment(h.log_time).add(1,"d").format("YYYYMMDD")
        h.date = addDay === now ?`昨天 ${moment(h.log_time).format("HH:mm")}`:moment(h.log_time).format("YYYYMMDD")===now? `今天 ${moment(h.log_time).format("HH:mm")}`: h.log_time
        let text=''
        if(h.log_text){
            text =  
            h.log_text.split(" ")
            .map((t,i)=>{
            let underline_index 
                let obj={title:t}
                if(i % 2 ===0){
                    obj.style={color:'rgba(0,0,0,0.8)', padding: 4}
                    if(t.indexOf('變更為')>-1) {
                        underline_index = i-0
                    }
                }else{
                    obj.style= {color:'rgba(0,0,0,0.4)'}
                }
    
                return obj
            })
        }
       
        h.text = text
        return h
    })
    return history_list
}
import React, { useState, useEffect, useRef } from 'react'
import { UserOutlined, BookOutlined, PlusOutlined, CloseCircleTwoTone, QuestionCircleOutlined, Loading3QuartersOutlined, ClockCircleOutlined, DeleteOutlined } from '@ant-design/icons'
import { Table, Tag, Input, Button, Drawer, Tooltip, Form, Radio,Select } from 'antd'
import { GetDriverExecutionList, DriverExecutionListFullManual, GetTrailerType } from '../../../api/Case'
import moment from 'moment'
import { GetUnoccupiedCarList } from '../../../api/Code'
const { TextArea } = Input


const Route = ({ selectedDriver, setBtnDisabled, visible, reasonOption, selectedCar, selectedTechnician, SetselectedTechnician, selectedRoute, setSelectedRoute, setReason, }) => {
  const [driverExecutionList, setDriverExecutionList] = useState(null)
  const [changeRouteData, setChangeRouteData] = useState({})
  const [hoverAddBtn, setHoverAddBtn] = useState(null)
  const [error, setError] = useState(false)
  const [carList, setCarList] = useState([])
  const [selectedDriverCar, setSelectedDriverCar] = useState(null)
  const [selectedTrailer, setSelectedTrailer] = useState(null)
  const AllTrailer = ["全載車", "三載車", "四載車", "六載車", "八載車"]
  const columnsRoutes = [
    { title: '#', dataIndex: 'execution_id', width: '12%' },
    { title: '起運點', dataIndex: 'origin_point', width: '15%' },
    { title: '迄運點', dataIndex: 'destination_point', width: '15%' },
    { title: '中途停靠', dataIndex: 'relay_point', width: '35%' },
    { title: '預計被載運時間', dataIndex: 'estimate_start_time', width: '23%' },
  ]
  const submitRef = useRef()
  const KeyUp = (key, val) => {
    let obj = { ...changeRouteData }
    if (val[key] === "") {
      delete obj[key]
    } else {
      obj[key] = val
    }
    // let Disabled = true
    // if(Object.keys(obj).length === 2){
    //   Disabled=false
    // }
    setChangeRouteData(obj)
  }
  const fetchApi = async () => {
    let official = selectedTechnician[0]["working_type"] === 1
    if (official) {
      let param = ''
      selectedCar.forEach((s, i) => {
        param += i === 0 ? `wait_match_case_list[${i}]=${s.case_row_id}` : `&wait_match_case_list[${i}]=${s.case_row_id}`
      })
      param += `&driver_row_id=${selectedTechnician[0]["driver_row_id"]}&query_type=${visible === 1 ? 1 : 2}`
      let res
      res = await GetDriverExecutionList(param)
      if (res && !res.Error) {
        setDriverExecutionList(res.Result)
        setError(false)
      } else {
        setError(true)
      }
    } else {
      let obj = {}
      if(!selectedTrailer){
        let Trailer = await GetTrailerType({ wait_match_case_list: selectedCar.map(car => car.case_row_id ? car.case_row_id : car.row_id) })
        if (Trailer && !Trailer.Error) {
          Trailer.Result.forEach(trailer => obj[trailer.trailer_type_name] = true)
          setSelectedTrailer(obj)
        }
      }
      let res = await GetUnoccupiedCarList(selectedTechnician[0]["service_point_row_id"])
      if (res && !res.Error) {
        let data = res.Result.Data.map(d => {
          // console.log(d)
          // let filter = ["全載車", "三載車", "四載車", "六載車", "八載車"].filter(type => type === d.kind_name)
          // let disabled = false
          // if (filter.length > 0) {
          // }
          let disabled = obj[d.kind_name] ? false : true
          return { value: d.row_id, label: `${d.no} ${d.kind_name ? `/ ${d.kind_name}` : ""}${disabled ? "（無法配合載運）" : ""}`, disabled }
        })
        setCarList(data)
      }
    }

  }
  useEffect(() => {
    fetchApi()
  }, []);
  useEffect(() => {
    let disabled = true
    if (selectedRoute || selectedDriverCar) {
      if (visible === 7 || (typeof (visible) === 'string' && visible.indexOf("b") > -1)) {
        if (Object.keys(changeRouteData).length === 2) {
          disabled = false
          setReason(changeRouteData)
        }
      } else {
        if (selectedRoute || selectedDriverCar) {
          disabled = false
        }
      }
    } else {
      if (visible === 7 || (typeof (visible) === 'string' && visible.indexOf("b") > -1)) {
        setReason(null)
      }
    }
    setBtnDisabled(disabled)
  }, [selectedRoute,selectedDriverCar, changeRouteData])
  return (
    <div>
      {
        selectedTechnician[0]["working_type"] === 1 ? (<>
          {
            (driverExecutionList && !error) ? (
              <>
                <div style={{ background: "#FAFAFA", padding: 24, display: 'flex', justifyContent: "space-between" }}>
                  <div>
                    <p style={{ fontSize: "20px" }}>預計耗時 {driverExecutionList.case_time_gap} 分鐘</p>
                    <p style={{ fontSize: "14px", color: "rgba(0,0,0,0.45)", marginTop: 4 }}>此為系統預估時間，僅供時程安排參考。</p>
                  </div>
                  {
                    selectedRoute && (
                      <div style={{ borderLeft: "1px solid rgba(0, 0, 0, 0.03)", paddingLeft: 24 }}>
                        <p style={{ fontSize: "20px" }}>已新增趟次</p>
                        <p style={{ fontSize: "14px", color: "rgba(0,0,0,0.45)", marginTop: 4 }}>如需調整趟次請點擊右側清除</p>
                      </div>
                    )
                  }
                  <Button onClick={() => {
                    selectedDriver(null)
                    setSelectedRoute(null)
                  }} type='link' icon={<DeleteOutlined />}>清除重選</Button>
                </div>
                {
                  (selectedRoute && visible === 7) ? (
                    <Form
                      name="basic"
                      layout="vertical"
                      style={{ marginTop: 24 }}
                    >
                      <Form.Item
                        label={"改派原因"}
                        name="change_dispatch_reason_name"
                        style={{ marginBottom: 8 }}
                      >
                        <Radio.Group
                          onChange={(e) => KeyUp("change_dispatch_reason_name", e.target.value)}
                          buttonStyle="solid" >
                          {
                            reasonOption.map(o => (<Radio.Button value={`${o.label}`} key={o.value}>{o.label}</Radio.Button>))
                          }
                        </Radio.Group>
                      </Form.Item>
                      <Form.Item
                        label=""
                        name="change_dispatch_reason_description"
                      >
                        <TextArea
                          onKeyUp={(e) => KeyUp("change_dispatch_reason_description", e.target.value)}
                          placeholder="請在此輸入補充說明..."
                          autoSize={{ minRows: 2, maxRows: 3 }}
                        />
                      </Form.Item>
                    </Form>) : !selectedRoute ? (
                      <>
                        <div style={{ display: "flex", alignItems: "center", marginBottom: 8, marginTop: 40 }}>
                          <div >趟次安排</div>
                          <div style={{ color: "rgba(0, 0, 0, 0.45)", marginLeft: 4 }}>部分趟次可能因車輛、時間等因素，不提供選擇</div>
                        </div>
                        <div className='route_table'>
                          <div className='route_table_thead'>
                            {
                              columnsRoutes.map(c => (
                                <div style={{ padding: 16, width: c.width }}>
                                  <div style={{ width: "100%", borderRight: "1px solid #f0f0f0" }}>{c.title}</div>
                                </div>
                              ))
                            }
                          </div>
                          <ul className='route_table_tbody'>
                            {
                              driverExecutionList.execution_list.map((route, index) => (
                                <>
                                  {
                                    route.can_insert_before_execution && (
                                      <li style={{ marginBottom: 8 }} onMouseEnter={() => {
                                        setHoverAddBtn(index + 1)
                                      }}
                                        onMouseLeave={() => setHoverAddBtn(null)}
                                      >
                                        <Button icon={<PlusOutlined />} style={{ width: "100%", marginTop: 8 }} type="dashed" onClick={() => {
                                          setSelectedRoute([{ execution_info_row_id: route.execution_info_row_id, action_type: "add", is_add_last: false }])
                                        }}>新增趟次</Button>
                                      </li>
                                    )
                                  }
                                  <li
                                    onClick={() => {
                                      if (route.can_insert_before_execution) {
                                        setSelectedRoute([{ execution_info_row_id: route.execution_info_row_id, action_type: "join", is_add_last: false }])
                                      }
                                    }}
                                    className={`route_table_tr ${route.can_merge_execution ? "" : "disabled"}`}>

                                    {
                                      columnsRoutes.map(c => {
                                        let text = ''
                                        if (route[c.dataIndex]) {
                                          text = route[c.dataIndex]
                                        } else {
                                          if (c.dataIndex === 'relay_point') {
                                            let max = route.relay_point_count > 2 ? 2 : route.relay_point_count
                                            for (let i = 0; i < max; i++) {
                                              text += i === 0 ? route['relay_point_list'][i] : `、${route.relay_point_list[i]}`
                                            }
                                            if (route.relay_point_count > 2) {
                                              text += `及其他 ${route.relay_point_count - 2} 個停靠點`
                                            }
                                          }
                                        }
                                        if (c.dataIndex === 'estimate_start_time') {
                                          let delay = ""
                                          if (hoverAddBtn) {
                                            if (index + 1 >= hoverAddBtn) {
                                              let time = moment(route.estimate_start_time).add(driverExecutionList.case_time_gap, "minute")
                                              let Hour = parseInt(time.format("m")) > 30 ? parseInt(time.format("HH")) + 1 : time.format("HH")
                                              delay = `（ 延後${Hour}:${parseInt(time.format("m")) > 0 && parseInt(time.format("m")) <= 30 ? "30" : "00"} ）`
                                            }
                                          }

                                          return (
                                            <div className={route.is_specified && route.can_merge_execution ? 'specified' : ''} style={{ padding: 16, width: c.width }}>{route.is_specified ? '指定' : ''} {text}
                                              {
                                                delay !== "" ? <span style={{ color: "rgba(245, 34, 45, 1)" }}>{delay}</span> : ""
                                              }
                                              {route.is_specified && route.can_merge_execution ? (
                                                <Tooltip placement="topLeft" title={
                                                  <>
                                                    {
                                                      route.specified_time_list.map((d, i) => (<div key={`需再${d.specified_time_to}前抵達${d.specified_service_point}`}>需再{d.specified_time_to}前抵達{d.specified_service_point}{i === (route.specified_time_list.length - 1) ? "" : ","}</div>))
                                                    }
                                                  </>
                                                }>
                                                  <ClockCircleOutlined style={{ color: "rgba(24, 144, 255, 1)", marginLeft: 8 }} />
                                                </Tooltip>
                                              ) : route.is_specified && !route.can_merge_execution ? (<ClockCircleOutlined style={{ color: "rgba(0, 0, 0, 0.4)", marginLeft: 8 }} />) : ""}</div>
                                          )
                                        } else {
                                          return (
                                            <div style={{ padding: 16, width: c.width }}>{text}</div>
                                          )
                                        }
                                      })
                                    }
                                  </li>
                                </>
                              ))
                            }
                            <li style={{ marginBottom: 8 }}
                            >
                              <Button icon={<PlusOutlined />} style={{ width: "100%", marginTop: 8 }} type="dashed" onClick={() => {
                                setSelectedRoute([{
                                  action_type: "add",
                                  is_add_last: true,
                                }])
                                // setBtnDisabled(false)
                              }}>新增趟次</Button>
                            </li>
                          </ul>
                        </div>
                      </>
                    ) : (<></>)
                }
              </>
            ) : error ? (

              <div className='disabled_info_block'>
                <CloseCircleTwoTone twoToneColor="#F5222D" ClassName='close_icon' />
                <p>此車種無法配合運送，請選擇其他技師</p>
                <Button type='primary'
                  onClick={() => SetselectedTechnician(null)}
                >重新選擇</Button>
              </div>

            ) : (
              <div style={{ background: "#FAFAFA", padding: 24, display: "flex", justifyContent: "center", alignItems: "center", fontSize: "20px" }}>
                <Loading3QuartersOutlined spin style={{ color: "#1890FF", marginRight: 24 }} />
                <p>計算中，請稍候</p>
              </div>
            )
          }
        </>) : (<>
          <div >可配合載運之載車
                <Tooltip placement="topLeft" title="部分車型有限制車種，請選擇可配合載運之載車技師">
                  <QuestionCircleOutlined style={{ cursor: "pointer", paddingLeft: 4 }} />
                </Tooltip>
              </div>
              {
                AllTrailer && selectedTrailer && (
                  <ul className='tab_block'>
                    {
                      AllTrailer.map(t => (<li className={selectedTrailer[t] ? 'active' : ''}>{t}</li>))
                    }
                  </ul>
                )
              }
          <div style={{ display: "flex", alignItems: "center", marginBottom: 8, marginTop: 40 }}>
            <div>指定車輛</div>
            <div style={{ color: "rgba(0, 0, 0, 0.45)", marginLeft: 4 }}>支援技師沒有常用車，請替他選定車輛並同時指派案件</div>
          </div>

          <Form ref={submitRef} name="basic" layout="vertical" >
            <Form.Item
              label="車輛"
              name="car"
              style={{ marginBottom: 8 }}
              rules={[{ required: true }]}
              placeholder='請選擇車輛'
              required
            >
              <Select
                onChange={(e) => {
                  setSelectedDriverCar(e)
                  SetselectedTechnician([{...selectedTechnician[0],car_info_row_id:e}])
                }}
                defaultValue={selectedTechnician[0]["plate_number"]}
                disabled={selectedTechnician[0]["plate_number"] ? true : false}
                options={carList}
              />
            </Form.Item>

            {
              (visible === 7 || (typeof (visible) === 'string' && visible.indexOf("b") > -1)) && (
                <>

                  <Form.Item
                    label='改派原因'
                    name="reason"
                    rules={[{ required: true }]}
                    style={{ marginTop: 24, marginBottom: 8 }}
                  >
                    <Radio.Group
                      onChange={(e) => KeyUp("change_dispatch_reason_name",e.target.value)}
                      buttonStyle="solid" >
                      {
                        reasonOption.map(o => (<Radio.Button value={`${o.label}`} key={o.value}>{o.label}</Radio.Button>))
                      }
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item
                    label=""
                    name="reason_note"
                  >
                    <TextArea
                      onKeyUp={(e) => {
                        KeyUp("change_dispatch_reason_description",e.target.value)
                      }}
                      placeholder="請在此輸入補充說明..."
                      autoSize={{ minRows: 2, maxRows: 3 }}
                    />
                  </Form.Item>
                </>
              )
            }
          </Form>
        </>)
      }
    </div>
  )
}

export default Route
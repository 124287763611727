import React, { useState, useEffect,useRef } from 'react'
import BreadCrumb from '../../../components/shared/BreadCrumb'
import CustomTable from '../../../components/shared/component/Card'
import LoadingBlock from '../../../components/shared/LoadingBlock'
import { GetMarketingTargetList,AddMarketingTarget,DeleteMarketingTarget,UpdateMarketingStatus,GetMarketing } from '../../../api/Marketing'
import { useSelector } from 'react-redux'
import { PageHeader, Button, Tag, Statistic, Row, Card, Table, Select, Drawer, Form, DatePicker, Cascader } from 'antd'
import { PlusOutlined, CloseOutlined, CopyOutlined } from '@ant-design/icons'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { RouteName } from '../../../util/route'
import moment from 'moment'
const { RangePicker } = DatePicker
const { Option } = Select

const PromotionDetail = ({ ContainerWidth }) => {
  const { state,pathname } = useLocation()
  const routeId = RouteName(pathname)
  let UserInfo = useSelector(state => state.UserInfo) 
  let UserPermission = UserInfo? UserInfo.permission.route: null
  let { promotionId } = useParams()
  let CustomerLists = useSelector(state => state.CustomerList) //客戶別選項
  let caseType = useSelector(state => state.caseType) //客戶別選項

  // const [status, setStatus] = useState(1)
  const [editDrawer, setEditDrawer] = useState(null)
  const [promotionType, setPromotionType] = useState("1")
  const [fetchData, setFetchData] = useState(null)
  const [TargetList, setTargetList] = useState(null)
  const [customerOptions, setCutomerOptions] = useState([])
  const [caseOption, setCaseOption] = useState([])
  const submitForm = useRef()
  const navigate = useNavigate()
  const FetchApi = async () => {
    let res = await GetMarketing(promotionId)
    if(!res.Error){
      let result =res.Result
      let type = result.type
      let status = result.status
      let text ='', type_name=''
      if(type=== "001"){
        text = `${result.t001_reach_type==='001'?`當月總車台數達 ${result.t001_reach} 台，`:'全月'}總金額${result.t001_discount_type==="001"?'折':'退佣金'} ${result.t001_discount}%`;
        type_name='全月折扣'
      }else if(type==='002'){
        text=`同單 ${result.t002_car_num} 車，每車折 ${result.t002_discount} ${result.t002_discount_type==='001'?'元':'%'} `
        type_name='多車折扣（每車）'

      }else if(type==='003'){
        type_name='多車折扣（整單）'
        text=`同單 ${result.t003_car_num} 車，整單折 ${result.t003_discount} ${result.t003_discount_type==='001'?'元':'%`'} `
      }else{
        type_name=`訂 ${result.t004_car_num} 送 ${result.t004_give_num}`
        text =`以價低者做為贈送單`
      }

      result.type_name= type_name
      result.detail= text
      result.status_color = status === 0 ? "#F5222D" :  status === 1 ? "#52C41A":  "#D9D9D9"
      result.tag_color = status === 0 ? "red" :  status === 1 ? "green":  "default"
      result.status_text = status===0? '取消':status === 1 ?"啟用":status === 2 ? "未啟用":"已過期"
      // tag_color = .status === 0 ? "red" :  d.status === 1 ? "green":  "default"
      result.period = `${moment(result.start_date).format('YYYY.MM.DD')}~${moment(result.end_date).format('YYYY.MM.DD')}`
      let creator  = []
      if(result.create_uid && result.create_uid!==""){
        creator.push(result.create_uid)
      }
      if(result.create_name && result.create_name!==""){
        creator.push(result.create_name)
      }
      let creator_text =''
      creator.forEach((c,i)=>{
        creator_text += i===0? c : ` / ${c}`
      })
      result.creator = creator_text
      setFetchData(result)
    }
      let targetRes = await GetMarketingTargetList(0,-1,promotionId)
      if(!targetRes.Error){
        let Data = targetRes.Result.Data.map(d=>{
          let filter = caseType.filter(type => type.Name === d.service_name)
          let serviceType =[]
          if(filter.length > 0 ) serviceType.push(filter[0]["Code"]) 
          d.serviceType = serviceType
          return d
        })

        setTargetList(Data)
      }
  }
  const onFinish = async(val)=>{
    let service_id =''
    val.service.forEach((s,i)=>{
      service_id += i===0? s : `,${s}`
    })
    val.service_id = service_id
    val.customer_row_id =val.customer_row_id[0]
    val.marketing_row_id =fetchData.row_id 
    let param = ''
    Object.keys(val).forEach((key,index)=>{
      param+= index ===0? `${key}=${val[key]}` :`&${key}=${val[key]}`
    })
    let res = await AddMarketingTarget(param)
    if(res.Result.Success){
      FetchApi()
      setEditDrawer(null)
      submitForm.current.resetFields()
    }
  }
  useEffect(()=>{
    if(CustomerLists){
      let new_CustomerLists = CustomerLists.map(d=> {
        d.id = d.row_id
        return d
      })
      setCutomerOptions(new_CustomerLists)
    } 
  },[CustomerLists])
  useEffect(()=>{
    if(caseType) {
      FetchApi()
      setCaseOption(caseType)
    }
  },[caseType])
  useEffect(() => {
    
  }, [])
  return (
    <div className="container" style={ContainerWidth}>
      {
        fetchData ? (<>
          <PageHeader
            style={{ background: "white", width: "100%" }}
            title={fetchData.name}
            onBack={() => navigate(-1)}
            className="site-page-header"
            tags={<Tag color={fetchData.tag_color}>{fetchData.status_text}</Tag>}
            extra={UserPermission && UserPermission[routeId] !==2?[]:fetchData.status === 1? [
              <Button
                key="複製並新增方案"
                icon={<CopyOutlined />}
                onClick={() => {
                  let obj = {...fetchData, TargetList}
                  console.log(TargetList)
                  // serviceType
                  navigate(`/customer/promotions/add`, { state: {...obj} })
                }}
              > 複製並新增方案 </Button>,
              <Button
                key="提前終止方案"
                icon={<CloseOutlined />}
              onClick={()=>{
                let update_uid='00050'
                let param=`row_id=${fetchData.row_id}&status=0&update_uid=${update_uid}`
                UpdateMarketingStatus(param)
                .then(res=>{
                  if(!res.Error){
                    FetchApi()
                  }
                })
              }}
              >
                提前終止方案
              </Button>
            ]:[
              <Button
                key="複製並新增方案"
                icon={<CopyOutlined />}
                onClick={() => {
                  let obj = {...fetchData, TargetList}
                  console.log(TargetList)

                  navigate(`/customer/promotions/add`, { state: {...obj} })
                }}
              >
                複製並新增方案
              </Button>
            ]}
            breadcrumb={<BreadCrumb Info={fetchData !== null && { [fetchData.row_id]: fetchData.name }} />}
          >
            <Row style={{ paddingTop: 6 }}>
              <Statistic title="開單時間" value={fetchData.create_time} />
              <Statistic
                title="最初登打人員"
                value={fetchData.creator?fetchData.creator:"-"}
                style={{ margin: '0 32px', paddingLeft: 24, borderLeft: '1px solid rgba(0, 0, 0, 0.03)' }}
              />
            </Row>
          </PageHeader>
          <div style={{ padding: 24 }}>
            <Card
              style={{ width: "100%", marginBottom: 24 }}
              title="基本資訊"
            >
              <CustomTable
                header={[
                  { key: "name", name: "活動名稱"},
                  { key: "period", name: "活動期間"},
                  { key: "type_name", name: "活動類型"},
                  { key: "detail", name: "活動內容"},
                  { key: "note", name: "備註"}
                ]} data={fetchData} />
            </Card>
            <Card
              style={{ width: "100%", marginBottom: 24 }}
              title="適用對象"
            >
            {
              TargetList&&(<Table
                pagination={false}
                dataSource={TargetList}
                columns={UserPermission && UserPermission[routeId] !==2?[
                  { title: '客戶代號', dataIndex: 'id'},
                  { title: '客戶名稱', dataIndex: 'name'},
                  { title: '服務案型', dataIndex: 'service_name'},
                ]:[
                  { title: '客戶代號', dataIndex: 'id'},
                  { title: '客戶名稱', dataIndex: 'name'},
                  { title: '服務案型', dataIndex: 'service_name'},
                  {
                    title: '', dataIndex: '', width: '67px',
                    render: (_,record) => (
                    <Button 
                    type="link"
                    onClick={()=>{
                      DeleteMarketingTarget(record.row_id).then(res=>{
                        if(res.Result.Success){
                          FetchApi()
                        }
                      })
                    }}
                    >刪除</Button>)
                  },
                ]}
              />)
            }
            {
              UserPermission && UserPermission[routeId] ===2&&(
                <Button
                  style={{ marginTop: "16px", marginBottom: 24, width: '100%' }}
                  type="dashed"
                  onClick={() => setEditDrawer("2")}
                >
                  <PlusOutlined /> 新增適用對象
                </Button>
              )
            }
            </Card>
          </div>
        </>) : (<div style={{ padding: 24, height: '100%' }}>
          <LoadingBlock />
        </div>)
      }

      <Drawer
        width={'416px'}
        title={"新增適用對象"}
        placement="right"
        closable={false}
        extra={<CloseOutlined
          style={{ cursor: "pointer", color: "rgba(0, 0, 0, 0.45)" }}
          onClick={() => setEditDrawer(null)}
        />}
        visible={editDrawer}
        footer={
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button type="text" style={{ marginRight: 16 }} onClick={() => {
                setEditDrawer(null)
                submitForm.current.resetFields()
            }
            }>取消</Button>
            <Button type="primary"
              onClick={() => submitForm.current.submit()}
            >新增</Button>
          </div>
        }
      >

        <Form
          ref={submitForm}
          name="basic"
          initialValues={{ type: promotionType }}
          onFinish={onFinish}
          layout="vertical"
          autoComplete="off"
        >
          {
            editDrawer && (<>
              <Form.Item
              label="客戶別"
              name="customer_row_id"
              rules={[{ required: true, message: "請選擇或輸入客戶別!" }]}
              // style={{ marginRight: "16px" }}
            >
              <Cascader
                options={customerOptions}
                // onChange={(val) => console.log(val[0])}
                placeholder="點擊選擇"
                showSearch={(inputValue, path) => (
                  path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()))
                )}

              />
            </Form.Item>
              <Form.Item
                label="服務案型"
                name="service"
              rules={[{ required: true, message: '請選擇服務案型!' }]}
              >
              <Select
                allowClear
                mode='multiple'
                placeholder="點擊選擇"
                onChange={(val)=>submitForm.current.value = val}
              >
                {
                  caseOption.map(c => (<Option key={`${c.Code} - ${c.Name}運送`} value={c.Code}>{c.Name}</Option>))
                }
             
              </Select>
              </Form.Item>
            </>)
          }
        </Form>
      </Drawer>
    </div>


  )
}

export default PromotionDetail
import React, { useState, useEffect, useRef } from 'react'
import BreadCrumb from '../../../components/shared/BreadCrumb'
import { PageHeader, Button, Select, Drawer, Form, Table, Input, AutoComplete } from 'antd'
import { FilterOutlined, PlusOutlined, CloseOutlined } from '@ant-design/icons'
import { useNavigate, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RouteName } from '../../../util/route'
import { GetMarketingList } from '../../../api/Marketing'
// import FilterTag from '../../../components/shared/component/FilterTag'
const { Option } = Select

const Index = ({ ContainerWidth }) => {
  const [filterVisible, setFilterVisible] = useState(false)
  const [CustomerListOption, setCustomerListOption] = useState([])
  const [allCustomerListOption, setAllCustomerListOption] = useState([])
  const [fetchData, setFetchData] = useState(null)
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 })
  const [filterData, setFilterData] = useState({ page_size: -1, page_index: 0})
  const [filterObjectLabel, setFilterObjectLabel] = useState(null)

  const filterName = { marketing_name: "活動名稱", marketing_type: "活動類型", customer_name: "適用對象" }
  const filterRef = useRef()
  let navigate = useNavigate()
  const store = useSelector(state => state.CustomerList)
  const { state, pathname } = useLocation()
  const routeId = RouteName(pathname)
  let UserInfo = useSelector(state => state.UserInfo)
  let UserPermission = UserInfo ? UserInfo.permission.route : null
  useEffect(() => {
    if (store) {
      setCustomerListOption(store)
      setAllCustomerListOption(store)
    }
  }, [store])

  const FetchMarketList = async ()=>{
    let param=''
    Object.keys(filterData).forEach((key, i) => {
      param += i === 0 ? `${key}=${filterData[key]}` : `&${key}=${filterData[key]}`;
    });
    GetMarketingList(param).then(res => {
      if (!res.Error) {
        res.Result.Data = res.Result.Data.map(d => {
          d.status_color = d.status === 0 ? "#F5222D" : d.status === 1 ? "#52C41A" : "#D9D9D9"
          d.tag_color = d.status === 0 ? "red" : d.status === 1 ? "green" : "default"
          if (!d.note) {
            d.note = "-"
          }
          let Target = ''
          if (d.target) {
            d.target.map((t, i) => {
              Target += i === 0 ? t.customer_name : `,${t.customer_name}`
            })
          }
          d.Target = Target
          return d
        })
        setFetchData(res.Result.Data)
      }
    })
  }

  useEffect(() => {
    FetchMarketList()
}, [filterData])

  const columns = [
    { title: '活動名稱', dataIndex: 'name' },
    { title: '活動類型', dataIndex: 'type_name' },
    { title: '適用對象', dataIndex: 'target_text' },
    {
      title: '狀態', dataIndex: 'status', width: '10%',
      render: (status, _) => (<div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ width: 6, height: 6, borderRadius: "50%", background: _.status_color, marginRight: 8 }}></div>
        <p>{_.status_text}</p>
      </div>)
    }
  ]
  return (
    <div className='container' style={ContainerWidth}>
      <PageHeader
        style={{ background: "white", width: "100%" }}
        title="行銷活動"
        className="site-page-header"
        extra={UserPermission && UserPermission[routeId] === 2 ? [
          <Button icon={<FilterOutlined />}
            onClick={() => setFilterVisible(true)}
          >篩選</Button>,
          <Button type="primary" icon={<PlusOutlined />}
            onClick={() => navigate('/customer/promotions/add')}
          >  新增行銷活動 </Button>
        ] : [
          <Button icon={<FilterOutlined />}
            onClick={() => setFilterVisible(true)}
          >篩選</Button>
        ]}
        breadcrumb={<BreadCrumb />}
      >
      </PageHeader>
      
      {
        filterObjectLabel && (
          <div className='filter_tabs_block' style={{ padding: 24, paddingBottom: 0 }}>
            {
              Object.keys(filterObjectLabel).map(label => (<p className='tab' onClick={() => {
                let filter = { ...filterData }, filterLabel = { ...filterObjectLabel }
                delete filter[label]
                delete filterLabel[label]
                setFilterData(filter)
                setFilterObjectLabel(filterLabel)

              }}>
                {filterName[label]}：{filterObjectLabel[label]}
                <CloseOutlined style={{ color: "rgba(0, 0, 0, 0.45)", fontSize: 14, paddingLeft: 4 }} />
              </p>))
            }
          </div>
        )
      }
      <div className='table_block'>
        {
          fetchData && (<Table
            columns={columns}
            rowKey={record => record.row_id}
            dataSource={fetchData}
            pagination={fetchData.length > 10 ? {
              current:pagination.current,
              total: fetchData.length,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} 列（共${total}  列）`,
            } : false}
            style={{ padding: "12px 0", minHeight: 400 }}
            onChange={(pagination) => setPagination(pagination)}
            onRow={(record, _) => {
              return {
                onClick: event => { navigate(`/customer/promotions/${record.row_id}`, { state: { ...record } }) },
              };
            }}
          />)
        }
        <Drawer
          title="篩選"
          placement="right"
          closable={false}
          extra={
            (<CloseOutlined
              style={{ cursor: "pointer", color: "rgba(0, 0, 0, 0.45)" }}
              onClick={() => setFilterVisible(false)}
            />)
          }
          footer={
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button type="text" style={{ marginRight: 16 }} onClick={() => setFilterVisible(false)}>取消</Button>
              <Button type="primary"
                htmlType="submit"
                onClick={() => filterRef.current.submit()}
              >篩選</Button>
            </div>
          }
          visible={filterVisible}>

          <Form
            ref={filterRef}
            name="basic"
            layout="vertical"
            onFinish={(value) => {
              let dataObj = {}
              let labelObj = {}
              let filter = { ...filterData }
              Object.keys(value).forEach((key, i) => {
                if (value[key]) {
                  filter[key] = value[key]
                  labelObj[key] = [value[key]]
      
                }else{

                }
              })
              setPagination({...pagination, current:1})
              setFilterData(filter)
              setFilterObjectLabel(labelObj)
              setFilterVisible(false)
            }}
            autoComplete="off"

          >
            <Form.Item
              label="活動名稱"
              name="marketing_name"
            >
              <Input placeholder='活動名稱' />
            </Form.Item>
            <Form.Item label="活動類型" name="marketing_type" >
              <Select
                allowClear
                style={{ width: '100%', marginTop: 8 }}
                placeholder="請選擇活動類型"
                defaultValue={[]}
              >
                <Option value='全月折扣'>全月折扣</Option>
                <Option value='多車折扣（每車）'>多車折扣（每車）</Option>
                <Option value='多車折扣（整單）'>多車折扣（整單）</Option>
                <Option value='滿幾送幾'>滿幾送幾</Option>
              </Select>
            </Form.Item>
            <Form.Item label="適用對象" name="customer_name" >
              <AutoComplete
                options={(CustomerListOption || []).map(d => ({
                  value: d.name,
                  label: d.name,
                }))}
                onSearch={(searchText) => {
                  let search = searchText ? [...allCustomerListOption].filter(d => d.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1) : [...allCustomerListOption]
                  setCustomerListOption(search)
                }}
                allowClear
                placeholder='請選擇'
              />
              {/* <Select
                allowClear
                style={{ width: '100%', marginTop: 8 }}
                placeholder="請選擇適用對象"
                defaultValue={[]}
              >
                {
                  CustomerListOption.map(customer => (<Option value={`${customer.name}`} key={customer.label}>{customer.name}</Option>))
                }
              </Select> */}
            </Form.Item>
          </Form>
        </Drawer>
      </div>
    </div>
  )
}


export default Index;
import React, { useState, useEffect, useRef } from "react";
import { Cascader, Form, Input, TimePicker, Radio, DatePicker, Table, Button, Upload, Collapse, Drawer } from "antd"
import { UserOutlined, PhoneOutlined, DownOutlined, UploadOutlined, CloseOutlined } from "@ant-design/icons";
// import ExpandRow from '../add/ExpandRow'
import moment from "moment";
// import { clone } from "lodash";
// import { useSelector } from 'react-redux'
import { PutEditCase } from '../../../api/Case'
// const { Panel } = Collapse;
const { TextArea } = Input
const Caseedit = ({ caseData, setEditData, caseId, reportedMethod, setSubmitForm, TownOption, SetVisible, visible }) => {
  const [sourceState, setSourceState] = useState(caseData.calling_source);
  const submitRef = useRef()
  const onFinish = (value) => {
    for(let key in value){
      if(!value[key]) value[key] = ''
    }
    value.calling_date = value.calling_date.format("YYYY-MM-DD")
    value.calling_time = value.calling_time.format("HH:mm")
    value.calling_source = sourceState
    // console.log(value)
    PutEditCase(caseId, caseData.vehicle_sn, value).then(res => {
      if (res && !res.Error) {
        setSubmitForm(true);
      }
    })
  };
  const onFinishFailed = (value) => { };
  useEffect(() => {
    if (caseData) {
      caseData.calling_date = moment(caseData.calling_date);
      caseData.calling_time = moment(caseData.calling_time, "HH:mm");
      submitRef.current?.setFieldsValue(caseData)
    }
  }, [caseData]);

  return (
    <Drawer
      title={"修改"}
      placement="right"
      width={"466px"}
      closable={false}
      visible={visible}
      extra={<CloseOutlined
        style={{ cursor: "pointer", color: "rgba(0, 0, 0, 0.45)" }}
        onClick={() => SetVisible(null)} />
      }
      footer={
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button type="text" style={{ marginRight: 16 }}
            onClick={() => {
              submitRef.current.setFieldsValue(caseData)
              SetVisible(null)
            }
            }>取消</Button>
          <Button type="primary"
            htmlType="submit"
            onClick={() => submitRef.current.submit()}
          >保存</Button>
        </div>
      }
    >
      <Form
        ref={submitRef}
        name="basic"
        initialValues={caseData}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        layout='vertical'
      >
        <Form.Item label="車牌 / 引擎 / 車身號碼" name="VIN" >
          <Input
            allowClear
            placeholder="請輸入車牌 / 引擎 / 車身號碼"
          />
        </Form.Item>
        <Form.Item label="車身顏色" name="color" >
          <Input
            allowClear
            placeholder="請輸入車身顏色"
          />
        </Form.Item>
        <div style={{ display: "grid", gridTemplateColumns: 'repeat(2,1fr)', gridGap: 16 }}>
          <Form.Item
            label="交車人資訊"
            name="delivery_person"
          >
            <Input
              prefix={<UserOutlined />}
              allowClear
              placeholder="請輸入名字"
            />
          </Form.Item>
          <div>
            <div style={{ height: 34 }}></div>
            <Form.Item
              label=""
              name="delivery_person_phone"
            >
              <Input
                prefix={<PhoneOutlined />}
                allowClear
                placeholder="請輸入電話"
              />
            </Form.Item>
          </div>
        </div>
        <div style={{ display: "grid", gridTemplateColumns: 'repeat(2,1fr)', gridGap: 16 }}>
          <Form.Item
            label="接車人資訊"
            name="pick_up_person"
          >
            <Input
              prefix={<UserOutlined />}
              allowClear
              placeholder="請輸入名字"
            />
          </Form.Item>
          <div>
            <div style={{ height: 34 }}></div>
            <Form.Item
              label=""
              name="pick_up_person_phone"
            >
              <Input
                prefix={<PhoneOutlined />}
                allowClear
                placeholder="請輸入電話"
              />
            </Form.Item>
          </div>
        </div>

        <div>
          <p style={{ fontSize: "16px", fontWeight: 500, paddingTop: "8px" }}>
            來源資訊
          </p>
          <div
            style={{
              width: "100%",
              background: "#FAFAFA",
              marginTop: "8px",
              padding: "16px",
            }}
          >
            <p>叫車來源</p>
            <Radio.Group
              defaultValue={sourceState}
              buttonStyle="solid"
              style={{ margin: "8px 0 32px 0" }}
              onChange={(e) => setSourceState(e.target.value)}
            >
              <Radio.Button value="LINE">LINE</Radio.Button>
              <Radio.Button value="Email">Email</Radio.Button>
              <Radio.Button value="080">080</Radio.Button>
              <Radio.Button value="傳真">傳真</Radio.Button>
              <Radio.Button value="現場加派">現場加派</Radio.Button>
            </Radio.Group>

            {sourceState === "LINE" ? (
              <>
                <Form.Item
                  label="群組名稱"
                  name="line_group_name"
                >
                  <Input
                    allowClear
                    placeholder="example"
                  />
                </Form.Item>
                <Form.Item
                  label="LINE ID"
                  name="line_id"
                  style={{ display: "block" }}
                >
                  <Input
                    allowClear
                    placeholder="example"
                  />
                </Form.Item>
              </>
            ) : (
              <>
                {sourceState === "Email" && (
                  <Form.Item
                    label="信箱"
                    name="calling_person_email"
                  >
                    <Input
                      allowClear
                      placeholder="example"
                    />
                  </Form.Item>
                )
                }
                <Form.Item label="姓名" name="calling_person_name">
                  <Input allowClear placeholder="example" />
                </Form.Item>
                <Form.Item label="電話" name="calling_person_phone"
                  style={{ display: "block" }}
                >
                  <Input allowClear placeholder="example" />
                </Form.Item>
              </>
            )}
            {/* </div> */}
            <div style={{ display: "grid", gridTemplateColumns: "repeat(2,1fr)", gridGap: 16 }}>
              <Form.Item label="叫車日期" name="calling_date"
                rules={[{ required: true, message: "請選擇叫車日期!" }]}
              >
                <DatePicker style={{ width: '100%' }} format={"YYYY/MM/DD"} />
              </Form.Item>
              <Form.Item label="叫車時間" name="calling_time"
                rules={[{ required: true, message: "請選擇叫車日期!" }]}
              >
                <TimePicker style={{ width: '100%' }} format={"HH:mm"} />
              </Form.Item>
            </div>
          </div>
        </div>
        <Form.Item label="客服給技師的備註" name="note" >
          <TextArea />
        </Form.Item>
        <Form.Item label="客服備註" name="customer_service_self_note">
          <TextArea />
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default Caseedit;

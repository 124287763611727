import React, { useState, useEffect } from 'react'
import BreadCrumb from '../../components/shared/BreadCrumb'
// import ModalCard from '../../components/cases/case/ModalCard'
import CustomTable from '../../components/shared/component/Card'
import { PageHeader, Card } from 'antd'
import { PushpinTwoTone } from '@ant-design/icons'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
// import GoogleMapReact from 'google-map-react'
const Case = ({ ContainerWidth, pageTitle }) => {
    let { recordId } = useParams()
    const { state } = useLocation()
    const navigate = useNavigate()
    // const [mapConfig, setMapConfig] = useState({
    //     config: {
    //         apiKey: "AIzaSyDidd-7pD-ozOcsDQGO2wibmHgI7mkBTYE",
    //         language: 'tw',
    //         region: 'tw',
    //     },
    //     center: {
    //         lat: 25.0513848,
    //         lng: 121.5497414
    //     },
    //     zoom: 14
    // })
    const [data, setData] = useState(null)
    const Marker = ({ text }) => (
        // <div style={{width: 26, height: 26, borderRadius:"50%", background:"blue"}}>
        <PushpinTwoTone fill={""} style={{ fontSize: 26 }} />
        // </div>
    )
    useEffect(() => {
        let new_state = state
        new_state.quantity = `${new_state.quantity} L`
        new_state.mileage = `${new_state.mileage} km`
        new_state.old_consumption = `${new_state.old_consumption} km/L`
        setData(new_state)
    }, [])
    return (
        <div className='container' style={ContainerWidth}>
            <div style={{ height: "calc(100%)" }}>
                <PageHeader
                    style={{ background: "white", width: "100%" }}
                    title={state.company_no}
                    onBack={() => navigate(-1)}
                    className="site-page-header"
                    breadcrumb={<BreadCrumb FuelId={{ [recordId]: state.company_no }} />}
                >
                </PageHeader>
                <div className='split_block' style={{ display: 'grid', gridTemplateColumns: "repeat(2, 1fr)", height: "calc(100% - 102px)" }}>
                    <div style={{ padding: 24 }}>

                        <Card
                            style={{ width: "100%", marginBottom: 24 }}
                            title="油站明細"
                        >
                            {
                                data !== null && (<CustomTable
                                    header={[
                                        {
                                            key: "department",
                                            name: "使用單位"
                                        },
                                        {
                                            key: "date",
                                            name: "交易時間"
                                        },
                                        {
                                            key: "plate_number",
                                            name: "車號"
                                        },
                                        {
                                            key: "supplier_name",
                                            name: "平台"
                                        },
                                        {
                                            key: "gas_station",
                                            name: "油站"
                                        },
                                        {
                                            key: "fuel_type",
                                            name: "油品"
                                        },
                                        {
                                            key: "price",
                                            name: "牌價"
                                        },
                                        {
                                            key: "quantity",
                                            name: "數量",
                                        },
                                        {
                                            key: "totalPrice",
                                            name: "金額"
                                        },
                                        {
                                            key: "mileage",
                                            name: "里程數",

                                        },
                                        // {
                                        //     key: "mileage",
                                        //     name: "車機里程數",

                                        // },
                                        {
                                            key: "old_consumption",
                                            name: "油耗",

                                        },


                                    ]} data={data} />)
                            }

                        </Card>
                    </div>

                </div>

            </div>
        </div>
    );
}

export default Case;
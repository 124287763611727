const notify = {
    unread:[
        {
            id:1,
            type: 1,
            status: 1,
            date: "2021.10.14 10:00",
            matchTime: "12:00 ",
            totalMatch: 63,
            failedMatch: 6,
            matchStatus: 0,
            caseId: "Q11011291234",
            user:"系統通知",
            matchDate:"12:00",
            matchNum:60,
            matchSucceed:45,
            matchFailed:15,
            button:1,
            important:false
        },
        {
            id:2,
            type: 3,
            status: 1,
            date: "2021.10.14 10:00",
            executionId: "Q11011291234",
            technicianId: "林大錢",
            user:"系統通知",
            button:2,
            important:false


        },
        {
            id:3,
            type: 4,
            status: 1,
            date: "2021.10.14 10:00",
            caseId: "Q11011291234",
            transportDate: "16:05",
            user:"系統通知",
            button:2,
            important:false


        },
        {
            id:4,
            type: 5,
            status: 1,
            date: "2021.10.14 10:00",
            executionId: "Q11011291234",
            note: "車輛拋錨，需要支援。",
            user:"林大花",
            button:2,
            important:false


        },
        {
            id:5,
            type: 1,
            status: 2,
            date: "2022.02.14 10:00",
            executionId: "Q11011291234",
            note: "車輛拋錨，需要支援。",
            user:"系統通知",
            matchDate:"12:00",
            matchNum: 30,
            matchSucceed:15,
            matchFailed:15,
            button:1,
            important:false
        }
      ],
    read:[
        {
            id:6,
            type: 1,
            status: 2,
            date: "2021.10.14 10:00",
            matchTime: "12:00 ",
            totalMatch: 63,
            failedMatch: 6,
            matchStatus: 0,
            caseId: "Q11011291234",
            user:"系統通知",
            matchDate:"12:00",
            matchNum:60,
            matchSucceed:45,
            matchFailed:15,
            button:1,
            important:false
        },
    ],
    important:[]
};

export const Notification = (state = null, action) => {
    switch (action.type) {
      case 'CHANGE_STATUS':
        return action.notify
  
      default:
        return state
    }
  }

  export const UnreadNotification = (state = null, action) => {
    switch (action.type) {
      case 'UNREAD_NOTIFICATION':
        return action.notify
  
      default:
        return state
    }
  }

import service from './Setting'


//報表製作
export const ExportExcel = (param) => service.get(`/ServicePoint/ExcelExport?${param}`);
//取得全部運送點清單
export const GetServicePointList = (param) => service.get(`/ServicePoint/List?${param}`);
//取得運送點資料
export const GetServicePoint= (id) => service.get(`/ServicePoint/Get?row_id=${id}`);
//取得運送點車輛統計
export const GetServicePointCarStatistics= (id) => service.get(`/ServicePoint/CarStatistics?row_id=${id}`);
//取得運送點歷程
export const GetServicePointHistory= (id,page_size,page_index) => service.get(`/ServicePoint/History?row_id=${id}&page_size=${page_size}&page_index=${page_index}`);

//變更運送點派遣狀態
export const UpdateServicePointStatus = (param) => service.get(`/ServicePoint/UpdateStatus?${param}`);
//查核據點編號
export const CheckStationId = (id) => service.get(`/ServicePoint/CheckCustomerId?id=${id}`,);
//新增運送點
export const AddStation = (formData) => service.post(`/ServicePoint/Add`,formData);
//新增/更新運送點備註
export const UpdteStationNote = (param) => service.get(`/ServicePoint/UpdateNote?${param}`);
//更新運送點資訊
export const UpdateStation = (formData) => service.post(`/ServicePoint/Upadte`,formData);
//變更運送點派遣狀態
export const UpdateCarStatus = (param) => service.get(`/ServicePoint/Car/UpdateStatus?${param}`);
//取得運送點技師清單
export const GetServicePointDriverList = (page_size,page,id) => service.get(`/ServicePoint/Driver/List?page_size=${page_size}&page_index=${page}&service_point_row_id=${id}`);
//取得運送點技師資料
export const GetServicePointDriver = (id) => service.get(`/ServicePoint/Driver/Get?row_id=${id}`);
//檢核技師手機號碼是否重複
export const CheckMobileNumber= (no) => service.get(`/ServicePoint/Driver/CheckMobileNumber?mobile_number=${no}`);

//新增運送點技師
export const AddServicePointDriver = (formData) => service.post(`/ServicePoint/Driver/Add`,formData);
//新增運送點技師備註
export const UpdateServicePointDriverNote = (param) => service.get(`/ServicePoint/Driver/UpdateNote?${param}`);
//更新運送點技師
export const UpdateServicePointDriver = (formData) => service.post(`/ServicePoint/Driver/Upadte`,formData);
//更新運送點技師駕照
export const UpdateDriverLicense = (formData) => service.post(`/ServicePoint/Driver/UpadteLicense`,formData);
//設定技師離職日
export const SetDriverLeaveOfficeDate = (param) => service.get(`/ServicePoint/Driver/SetLeaveOfficeDate?${param}`);
//設定技師復職日
export const SetDriverTakeOfficeDate = (param) => service.get(`/ServicePoint/Driver/SetTakeOfficeDate?${param}`);

//取得運送點技師歷程
export const GetDriverHistory = (page_size,page,id) => service.get(`/ServicePoint/Driver/History?page_size=${page_size}&page_index=${page}&row_id=${id}`);
//取得運送點技師生理監控
export const GetDriverHealth = (id,date) => service.get(`/ServicePoint/Driver/Physiological?driver_row_id=${id}&date=${date}`);
//取得運送點技師生理監控異況紀錄
export const GetDriverHealthException = (id,page_size,page_index) => service.get(`/ServicePoint/Driver/PhysiologicalException?driver_row_id=${id}&page_size=${page_size}&page_index=${page_index}`);
//取得運送點技師生理監控異況紀錄
export const UpdatePhysiologicalNote = (param) => service.get(`/ServicePoint/Driver/UpdatePhysiologicalNote?${param}`);
//取得全部運送點清單（含共同運送點）
export const GetAllStationList = () => service.get(`/Station/AllStationList`);

//出勤管理
export const GetAttendanceList = (id,date) => service.get(`/ServicePoint/Attendance/List?service_point_row_id=${id}&date=${date}`);

//取得技師當日出勤清單
export const GetDriverExecutedList = (id,date) => service.get(`/ServicePoint/Attendance/GetDriverAttendInfo?driver_row_id=${id}&date=${date}`);


//更改技師當日出勤
export const UpdateDriverAttend = (param) => service.get(`/ServicePoint/Attendance/SetAttend?${param}`);
//設定技師當日出勤出勤狀態（可結束出勤）
export const SetDriverAttendStatus = (param) => service.get(`/ServicePoint/Attendance/SetWorkdayStatus?${param}`);
//取得運送點車輛清單
export const GetServicePointCarList = (page_size,page,id) => service.get(`/ServicePoint/Car/List?page_size=${page_size}&page_index=${page}&service_point_row_id=${id}`);


//取得運送點車輛資訊
export const GetServicePointCar = (id) => service.get(`/ServicePoint/Car/Get?row_id=${id}`);
//取得運送點車輛保險資料
export const GetServicePointCarInsurance = (id) => service.get(`/ServicePoint/Car/GetInsurance?row_id=${id}`);

//查核該據點之車牌號碼是否重複
export const CheckServicePointCarNo = (id,no) => service.get(`/ServicePoint/Car/CheckCarNo?service_point_row_id=${id}&no=${no}`);

//新增運送點車輛
export const AddServicePointCar = (formData) => service.post(`/ServicePoint/Car/Add`,formData);
//取得運送點車輛歷程
export const GetServicePointCarHistory = (id,page_size,page) => service.get(`/ServicePoint/Car/History?row_id=${id}&page_size=${page_size}&page_index=${page}`);
//更小運送點車輛
export const UpdateServicePointCar = (formData) => service.post(`/ServicePoint/Car/UpadteInfo`,formData);
//更新運送點車輛備註
export const UpdateServicePointCarNote = (param) => service.get(`/ServicePoint/Car/UpdateNote?${param}`);

//新增運送點車輛保險
export const AddServicePointCarInsurance = (formData) => service.post(`/ServicePoint/Car/AddInsurance`,formData);


//更新運送點車輛保險
export const UpdateServicePointCarInsurance = (formData) => service.post(`/ServicePoint/Car/UpadteInsurance`,formData);

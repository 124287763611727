import service from './Setting'

//檢核客戶代號
export const GetNotificationList = (param) => service.get(`/Notification/List?${param}`);
//取得指定執行趟次狀態
//執行狀態 ( 待接案: 0 / 待執行: 1 / 執行中: 2 / 完成: 3 / 取消: 4 / 改派: 5 / 部分完成: 6 / 考慮中: 7 / 媒合確認: 8 / 抵達: 9 )
export const GetNotificationStatus = (id) => service.get(`/Execution/${id}/Status`);

//設定已讀（單一通知）
export const Read = (id) => service.post(`/Notification/Read/${id}`);

//設定已讀（全部通知）
export const ReadAll = (id) => service.post(`/Notification/ReadAll`);

//設定重要資訊
export const FavoriteNotify = (id) => service.post(`/Notification/Star/${id}`);
export const UnfavoriteNotify = (id) => service.post(`/Notification/CancelStar/${id}`);



//媒合結果配對成功：查看執行趟次內容
export const NotifyGetExecutionInfo = (executionId) => service.get(`/Execution/${executionId}/Notification`);
import React, { useState, useEffect, useRef } from 'react'
import { Input, Button, Drawer, Form, Select, Radio } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'
import { GetServicePointList } from '../../../api/ServicePoint'
import { DispatchCaseByTemporaryDriver } from '../../../api/Case'
const { TextArea } = Input

const NonOfficalDriver = ({ SetVisible, carData, caseData, visible, reasonOption }) => {
    const submitRef = useRef()
    const [stationList, setStationList] = useState([])
    const [filterObj, setFilterObj] = useState({})
    useEffect(() => {
        GetServicePointList(-1, 0).then(res => {
            if (res && !res.Error) {
                let data = res.Result.Data.map(d => {
                    return { value: `${d.row_id},${d.name}`, label: d.name }
                })
                setStationList(data)
            }
        })
    }, [])
    const KeyUp = (key, val) => {
        let obj = { ...filterObj }
        if (val === "") {
            delete obj[key]
        } else {
            obj[key] = val
        }
        setFilterObj(obj)
    }
    const onFinish = async (val) => {
        // let filter = carData.filter(c => c.case_row_id !== caseData.case_info_row_id)
        // if (val.service_point) {
        //     let arr = val.service_point.split(",")
        //     val.service_point_row_id = arr[0]
        // }
        delete val.service_point
        // val.current_case_info_row_id = caseData.case_info_row_id
        val.wait_match_case_list = carData.map(f => f.case_row_id)
        let res = await DispatchCaseByTemporaryDriver(val)
        console.log(res)
        if (res && !res.Error) {
            if (res.Result.is_success) {
                SetVisible(null)
            }
        }
    }
    return (
        <Drawer
            title={<div>
                <ArrowLeftOutlined
                    style={{ cursor: "pointer" }}
                    onClick={() => SetVisible(visible.indexOf("b")>-1?7:1)}
                />
                <span style={{ fontWeight: 700, paddingLeft: 16 }}>臨時技師</span>
            </div>}
            placement="right"
            width={visible==='5b'?'808px':'284px'}
            closable={false}
            visible={true}
            footer={
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button type="text" style={{ marginRight: 16 }} onClick={() => {
                        SetVisible(null)
                    }
                    }>取消</Button>
                    <Button type="primary"
                        disabled={(visible==="5a"&&Object.keys(filterObj).length === 2)|| (visible==="5b"&&Object.keys(filterObj).length === 4) ? false : true}
                        onClick={() => {
                            submitRef.current.submit()
                        }}>派遣</Button>
                </div>
            }
        >
            <Form
                ref={submitRef}
                name="basic"
                layout='vertical'
                onFinish={onFinish}
                autoComplete="off"
            >
                <Form.Item
                    label="姓名"
                    name="driver_name"
                    rules={[{ required: true, message: "請輸入姓名!" }]}
                >
                    <Input
                        style={{width:visible==='5b'?'304px':'100%'}}
                        onKeyUp={(e) => KeyUp("driver_name", e.target.value)}
                        allowClear
                        placeholder="example"
                    />
                </Form.Item>
                <Form.Item
                    label="電話"
                    name="driver_phone"
                    rules={[{ required: true, message: "請輸入電話!" }]}
                >
                    <Input
                        style={{width:visible==='5b'?'304px':'100%'}}
                        onKeyUp={(e) => KeyUp("driver_phone", e.target.value)}
                        allowClear
                        placeholder="example"
                    />
                </Form.Item>
                <Form.Item
                    label="據點"
                    name="service_point"
                >
                    <Select
                        allowClear
                        showSearch
                        style={{width:visible==='5b'?'304px':'100%'}}
                        placeholder='點擊選擇'
                        options={stationList}
                    />
                </Form.Item>
                {
                    visible === "5b" && (
                        <>
                            <Form.Item
                                label='改派原因'
                                name="change_dispatch_reason_name"
                                rules={[{ required: true }]}
                                style={{ marginTop: 24, marginBottom: 8 }}
                            >
                                <Radio.Group
                                    onChange={(e) => KeyUp("change_dispatch_reason_name", e.target.value)}
                                    buttonStyle="solid" >
                                    {
                                        reasonOption.map(o => (<Radio.Button value={`${o.label}`} key={o.value}>{o.label}</Radio.Button>))
                                    }
                                </Radio.Group>
                            </Form.Item>
                            <Form.Item
                                label=""
                                name="change_dispatch_reason_description"
                            >
                                <TextArea
                                    onKeyUp={(e) => {
                                        KeyUp("change_dispatch_reason_description", e.target.value)
                                    }}
                                    placeholder="請在此輸入補充說明..."
                                    autoSize={{ minRows: 2, maxRows: 3 }}
                                />
                            </Form.Item>
                        </>
                    )}
            </Form>
        </Drawer>
    )
}

export default NonOfficalDriver
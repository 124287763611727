import React, { useState, useEffect, useRef } from "react";
import BreadCrumb from "../../components/shared/BreadCrumb";
import moment from "moment";
import { FilterOutlined, PlusOutlined, CloseOutlined, FileAddOutlined,} from "@ant-design/icons";
import { PageHeader, Button, DatePicker, Space, Tabs, Badge, Table, Select, Drawer, Form, Input, Modal, Cascader, Dropdown, Menu,AutoComplete} from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import "moment/locale/zh-cn";
import { useDispatch, useSelector } from "react-redux";
import { addCase } from "../../actions/cases";
import { GetAllCases,GetCaseInfo } from "../../api/Case";
import CustomCascader from "../../components/shared/component/CustomCascader";
import { RouteName } from "../../util/route";
import { StatusObj } from "../../util/status";
import { DownloadFile } from "../../util/download";
import { GetServicePointList } from "../../api/ServicePoint";
const { RangePicker } = DatePicker;
const { TabPane } = Tabs;
const { Option } = Select;

const Index = ({ pageTitle, ContainerWidth }) => {
  let navigate = useNavigate();
  const { state, pathname } = useLocation();
  const routeId = RouteName(pathname);
  let CustomerList = useSelector((state) => state.CustomerList);
  let UserInfo = useSelector((state) => state.UserInfo);
  let case_type = useSelector((state) => state.caseType);
  let UserPermission = UserInfo ? UserInfo.permission.route : null;
  const dispatch = useDispatch();
  let Town = useSelector((state) => state.county);
  let selected_date = "";
  if (state && state.dataDate) {
    let state_arr = state.dataDate.split(",");
    let date_arr = state_arr[0].split("-");
    selected_date = `${date_arr[0] * 1 + 1911}-${date_arr[1]}-01`;
  }
  const [filterVisible, setFilterVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [selectedDate, setSelectedDate] = useState(
    selected_date !== ""
      ? moment(selected_date).format("YYYY-MM-DD")
      : moment().format("YYYY-MM-DD")
  );
  const [allData, setAllData] = useState(null);
  const [customerOption, setCustomerOption] = useState([]);
  const [dates, setDates] = useState(null);
  const [modal, setModal] = useState(false);
  const [modalBtnDisabled, setModalBtnDisabled] = useState(false);
  const submitRef = useRef(),
    statementForm = useRef();
  const [filterData, setFilterData] = useState({
    page_size: 10, page_index: 0, case_query_tag: 0 });
  const [filterObjectLabel, setFilterObjectLabel] = useState(null);
  const [caseTypeList, setCaseTypeList] = useState([]);
  const [ServicePointList, setServicePointList] = useState([]);
  const [AllOption, setAllOption] = useState({})
  const menu = (
    <Menu>
      <Menu.Item onClick={() => setModal(1)}>案件明細</Menu.Item>
      <Menu.Item onClick={() => setModal(2)}>總表</Menu.Item>
    </Menu>
  );
  const filterName = {
    case_id: "案件編號",
    customer: "客戶",
    driver_name_list: "執行技師",
    vin: "車牌",
    origin_name: "運送起點",
    destination_name: "運送迄點",
  };
  const [dataDate, setDataDate] = useState(
    state && state.dataDate
      ? state.dataDate
      : `${moment().year() - 1911}-${moment().format("MM")},${moment().add(1, "month").year() - 1911
      }-${moment().add(1, "month").format("MM")}`
  );

  const customWeekStartEndFormat = (value) => {
    let start = `${moment(value).year() - 1911}/${moment(value).format("MM")}`;
    let end = `${moment(value).add(1, "month").year() - 1911}/${moment(value)
      .add(1, "month")
      .format("MM")}`;
    return `${start} ~ ${end}`;
  };
  const [tabs, setTabs] = useState([
    { id: "tab1", name: "全部", key: "case_all_count", count: null, status: "0",},
    { id: "tab2", name: "草稿", key: "draft_count", count: null, status: "99",},
    { id: "tab3", name: "待收單", key: "case_wait_review_count", count: null, status: "1",},
    { id: "tab4", name: "待媒合", key: "wait_map_count", count: null, status: "8",},
    { id: "tab5", name: "待派遣", key: "wait_dispatch_count", count: null, status: "7",},
    { id: "tab6", name: "已派遣", key: "has_dispatch_count", count: null, status: "9",},
    { id: "tab7", name: "待結案", key: "case_wait_close_count", count: null, status: "3",},
    { id: "tab8", name: "已結案", key: "case_close_count", count: null, status: "4",},
    { id: "tab9", name: "取消", key: "case_cancel_count", count: null, status: "5",},
    { id: "tab10", name: "異況案件", key: "case_error_count", count: null, status: "6",},
  ]);
  const columns = [
    {
      title: "案件編號",
      dataIndex: "id",
      width: 140,
      render: (_, data) => (<div style={{textWrap:'nowrap'}}>{`${data.id}-${data.vehicle_sn}`}</div>),
    },
    {
      title: "運送日期",
      dataIndex: "transport_date",
      width: 140,
      render: (_, data) => _?_:'-',
      sorter: (a, b) => b.transport_timeStamp - a.transport_timeStamp,
    },
    { title: "預約時間", dataIndex: "transport_time_view", width: 120, render: (data) => (data ? data : "-") },
    { title: "廠牌", dataIndex: "vehicle_brand_name", width: 120, render: (data) => (data ? data : "-") },
    { title: "車型", dataIndex: "vehicle_model_name", width: 120, render: (data) => (data ? data : "-") },
    { title: "車牌", dataIndex: "VIN", width: 140, render: (data) => (data ? data : "-") },
    { title: "客戶別", dataIndex: "customer", width: 200},
    // , ellipsis: true 
    { title: "執行技師", dataIndex: "driver", width: 140, render: (_, data) => _?_:'-',},
    { title: "運送起點", dataIndex: "origin", width: 200, },
    { title: "運送訖點", dataIndex: "destination", width: 200, },
    { title: "備註", dataIndex: "customer_service_self_note", width: 200, },
    {
      title: "案件狀態",
      dataIndex: "case_status",
      width:120,
      render: (status, obj) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              width: 6,
              height: 6,
              borderRadius: "50%",
              background: StatusObj(parseInt(status)).color,
              marginRight: 8,
            }}
          ></div>
          <p>
            {status === "6" && obj.execution_status
              ? obj.execution_status
              : StatusObj(parseInt(status)).text}
          </p>
        </div>
      ),
    },
  ];
  const disabledDate = (current, i) => {
    if (!dates) {
      return false;
    }
    let year = null,
      curMonth = null;
    if (dates[0]) {
      curMonth =
        parseInt(dates[0].format("M")) + 1 === 13
          ? 1
          : parseInt(dates[0].format("M")) + 1;
      year =
        curMonth === 1
          ? parseInt(dates[0].format("YYYY")) + 1
          : dates[0].format("YYYY");
    } else if (dates[1]) {
      curMonth =
        parseInt(dates[1].format("M")) - 1 === -2
          ? 11
          : parseInt(dates[1].format("M")) - 1 === -1
            ? 12
            : parseInt(dates[1].format("M")) - 2;
      year =
        curMonth === 12
          ? parseInt(dates[1].format("YYYY")) - 1
          : dates[1].format("YYYY");
    }
    let lastday = moment(`${year}-${curMonth}-01`).endOf("month").format("D");
    let date = `${year}-${curMonth}-${lastday}`;
    let tooLate, tooEarly;
    if (dates[0]) {
      tooLate = dates[0] && moment(date).diff(current, "day") < 0;
    } else {
      tooLate = moment(date).diff(current, "day") >= 0;
    }
    return !!tooEarly || !!tooLate;
  };
  const onOpenChange = (open) => {
    if (open) {
      setDates([null, null]);
    } else {
      setDates(null);
    }
  };
  const fetchCases = async () => {
    let container = document.querySelector(".container");
    container.scrollTo(0, 0);
    let param = "";
    setLoading(true);
    setAllData(null);
    Object.keys(filterData).forEach((key, i) => {
      // if (key === "origin" || key === "destination") {
      //   let list = filterData[key].filter(
      //     (v) => v.length === 1 && v[0].split(",")[0]
      //   );
      //   let county = filterData[key].filter((v) => v.length > 1 && v);
      //   if (list.length > 0) {
      //     param +=
      //       i === 0
      //         ? `${key}_list=${list.map((l) => l[0].split(",")[1])}`
      //         : `&${key}_list=${list.map((l) => l[0].split(",")[1])}`;
      //   }
      //   if (county.length > 0) {
      //     let county_list = [],
      //       town_list = [];
      //     county.forEach((d) => {
      //       county_list.push(d[1].split(",")[0]);
      //       if (d.length === 3) town_list.push(d[2].split(",")[0]);
      //     });
      //     param +=
      //       i === 0
      //         ? `${key}_county_list=${county_list}`
      //         : `&${key}_county_list=${county_list}`;
      //     param +=
      //       i === 0
      //         ? `${key}_town_list=${town_list}`
      //         : `&${key}_town_list=${town_list}`;
      //   }
      // } else {
      //   param +=
      //     i === 0 ? `${key}=${filterData[key]}` : `&${key}=${filterData[key]}`;
      // }
      param +=
      i === 0 ? `${key}=${filterData[key]}` : `&${key}=${filterData[key]}`;
    });
    let res = await GetAllCases(param);
    if (res && !res.Error) {
      let result = res.Result;
      result.Data.map((r, i) => {

        let year = moment(r.transport_date).year() - 1911;
        let date = r.case_status==="99"?'':`${year}${moment(r.transport_date).format("-MM-DD")}`;
        r.transport_timeStamp = r.case_status==="99"?0:parseInt(moment(r.transport_date).format("x"));
        r.transport_date = date;
      });
      let reform = tabs.map((t) => {
        t.count = result[t.key];
        return t;
      });
      setTabs(reform);
      setAllData(result);
      setLoading(false);
    }
  };
  const Report = async (val) => {
    setModalBtnDisabled(true);
    let param = `start_time=${val.date[0].format(
      "YYYY-MM-DD"
    )}&end_time=${val.date[1].format("YYYY-MM-DD")}&`;
    param += modal === 1 ? `export_type=0` : `export_type=1`
    Object.keys(val).forEach((key, i) => {
      if (val[key] && key !== "date") {
        param += `&${key}=${val[key]}`;
      }
    });
    // if(val.customer){
    //   let customer = val.customer.split("-")
    //   let filter = customerOption.filter(c=>c.id === customer[0])
    //   if(filter.length===1){
    //     param+=`&customer_row_id=${filter[0]["row_id"]}`
    //   }
    // }
    // if(val.case_type_id){
    //   param+=`&case_type_id=${val.case_type_id}`
    // }
    let res = await DownloadFile(`Case/ExcelExport?${param}`);
    if (res) {
      setModalBtnDisabled(false);
      setModal(null);
      statementForm.current.resetFields();
    }
  };
  useEffect(() => {
    if (case_type) {
      setCaseTypeList(
        [...case_type].map((c) => ({ label: c.Name, value: c.Code }))
      );
    }
  }, [case_type]);
  useEffect(() => {
    if (CustomerList) {
      setCustomerOption(CustomerList);
      setAllOption({customer:CustomerList})
    }
  }, [CustomerList]);

  useEffect(() => {
    document.title = pageTitle;
    let end = moment().add(1, "month").endOf("month").format("D");
    setFilterData({
      start_time: moment().format(`YYYY-MM-01`),
      end_time: moment().add(1, "month").format(`YYYY-MM-${end}`),
      page_size: 10,
      page_index: 0,
      case_query_tag: 0,
    });
    GetServicePointList(`page_index=0&page_size=-1`).then((res) => {
      if (res && !res.Error) {
        let data = res.Result.Data.map((d) => ({
          label: d.name,
          value: d.row_id,
        }));
        setServicePointList(data);
      }
    });
    // navigate(pathname, { replace: true }); //clear state after reloading the page
  }, []);
  useEffect(() => {
    let mounted = true;
    if (filterData) {
      if (mounted) {
        // only try to update if we are subscribed (or mounted)
        fetchCases();
      }
    }
    return () => (mounted = false);
  }, [filterData]);

  return (
    <div className="container" style={ContainerWidth}>
      <PageHeader
        style={{ background: "white", width: "100%" }}
        title="案件列表"
        className="site-page-header"
        tags={[
          <Space direction="vertical" size={12}>
            <DatePicker
              defaultValue={moment(selectedDate)}
              value={moment(selectedDate)}
              format={customWeekStartEndFormat}
              allowClear={false}
              onChange={(value) => {
                let selected = value.format("YYYY-MM-DD");
                setSelectedDate(selected);
                let obj = { ...filterData };
                let end = moment(selected)
                  .add(1, "month")
                  .endOf("month")
                  .format("D");
                obj.start_time = value.format("YYYY-MM-01");
                obj.end_time = value.add(1, "month").format(`YYYY-MM-${end}`);
                setFilterData(obj);
              }}
              picker="month"
            />
          </Space>,
        ]}
        extra={
          UserPermission && UserPermission[routeId] === 2
            ? [
              <Button
                icon={<FilterOutlined />}
                onClick={() => {
                  setFilterVisible(true);
                  setTimeout(() => {
                    submitRef.current.resetFields();
                    submitRef.current.setFieldsValue({
                      selectedDate: [
                        moment(filterData.start_time),
                        moment(filterData.end_time),
                      ],
                      ...filterData,
                    });
                    if(!filterData.customer || filterData.customer.length===0 ) setCustomerOption(AllOption.customer)
                  }, 100);
                }}
              >
                篩選
              </Button>,
              <Dropdown overlay={menu} placement="bottomLeft">
                <Button icon={<FileAddOutlined />}>報表製作</Button>
              </Dropdown>,
              <Button
                key="案件新增"
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => {
                  dispatch(addCase([{ key: 1, mobility: "1", no: 1 }]));
                  navigate("add");
                }}
              >
                案件新增
              </Button>,
            ]
            : [
              <Button
                icon={<FilterOutlined />}
                onClick={() => {
                  setFilterVisible(true);
                  setTimeout(() => {
                    submitRef.current.resetFields();
                    submitRef.current.setFieldsValue({
                      selectedDate: [
                        moment(filterData.start_time),
                        moment(filterData.end_time),
                      ],
                      ...filterData,
                    });
                  }, 100);
                  if(!filterData.customer || filterData.customer.length ===0) setCustomerOption(AllOption.customer)
                }}
              >
                篩選
              </Button>,
            ]
        }
        breadcrumb={<BreadCrumb />}
      ></PageHeader>
      
      {filterObjectLabel && (
        <div
          className="filter_tabs_block"
          style={{ padding: 24, paddingBottom: 0 }}
        >
          {Object.keys(filterObjectLabel).map((label) => (
            <>
              {filterObjectLabel[label].length>0 && filterObjectLabel[label].map((l) => (
                <p
                  className="tab"
                  onClick={() => {
                    let filter = { ...filterData },
                      filterLabel = { ...filterObjectLabel };
                    let index = filterLabel[label].indexOf(l);
                    filter[label] = filter[label].filter(
                      (Label, i) => i !== index
                    );
                    filterLabel[label] = filterLabel[label].filter(
                      (Label) => Label !== l
                    );
                    setFilterData(filter);
                    setFilterObjectLabel(filterLabel);
                  }}
                >
                  {filterName[label]}：{l}
                  <CloseOutlined
                    style={{
                      color: "rgba(0, 0, 0, 0.45)",
                      fontSize: 14,
                      paddingLeft: 4,
                    }}
                  />
                </p>
              ))}
            </>
          ))}
        </div>
      )}
      <div className="table_block">
        <div style={{ position: "relative" }}>
          <Tabs defaultActiveKey={filterData.case_query_tag.toString()}>
            {tabs.map((t) => (
              <TabPane
                key={t.name}
                tab={
                  <div
                    onClick={(e) => {
                      let filter = { ...filterData };
                      filter.case_query_tag = t.status;
                      filter.page_index = 0;
                      setFilterData(filter);
                    }}
                  >
                    <span>{t.name}</span>
                    <Badge
                      className="site-badge-count-109"
                      overflowCount={999}
                      count={t.count}
                      style={{
                        backgroundColor:
                          filterData.case_query_tag.toString() === t.id
                            ? "#E6F7FF"
                            : "#F0F0F0",
                        color:
                          filterData.case_query_tag.toString() === t.id
                            ? "#1890FF"
                            : "rgba(0,0,0,0.45)",
                        marginLeft: "4px",
                      }}
                    />
                  </div>
                }
              />
            ))}
          </Tabs>
          <div
            style={{
              position: "absolute",
              top: "50%",
              right: 0,
              transform: "translateY(-75%)",
              color: "rgba(0, 0, 0, 0.45)",
              fontSize: 14,
            }}
          >
            共 {allData ? allData.RowsCount : 0} 筆
          </div>
        </div>
        <Table
          scroll={{x:'100vw'}}
          columns={columns}
          rowKey={(record) => `${record.id}-${record.vehicle_sn}`}
          dataSource={allData ? allData.Data : []}
          pagination={
            allData && allData.PageCount > 1
              ? {
                current: filterData.page_index + 1,
                total: allData.RowsCount,
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} 列（共${total}  列）`,
              }
              : false
          }
          loading={loading}
          style={{ padding: "12px 0", minHeight: 400 }}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                if(record.case_status === '99'){
                  GetCaseInfo(record.id, record.vehicle_sn).then(res=>{
                    if(res && !res.Error){
                      navigate(`add`, { state: {...res.Result, caseId:record.id}});
                    }
                  })

                  
                }else{
                  navigate(`/cases/${record.id}`, {
                    state: {
                      caseStatus: record.case_status,
                      vehicle_sn: record.vehicle_sn,
                      dataDate,
                      ...filterData,
                      step: 1,
                      caseId: record.id,
                    },
                  });
                }
              
              }, // click row
            };
          }}
          onChange={(pagination) => {
            let filter = { ...filterData };
            filter.page_index = pagination.current - 1;
            setFilterData(filter);
          }}
        />

        {/* 篩選視窗 */}

        <Drawer
          title="篩選"
          placement="right"
          closable={false}
          extra={
            <CloseOutlined
              style={{ cursor: "pointer", color: "rgba(0, 0, 0, 0.45)" }}
              onClick={() => setFilterVisible(false)}
            />
          }
          footer={
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                type="text"
                style={{ marginRight: 16 }}
                onClick={() => {
                  setFilterVisible(false);
                  // setOnCancel(v.key)
                  // submitRef.current.setFieldsValue(carData)
                  // SetVisible(null)
                  // fetchAPI()
                  // setVisible(null)
                }}
              >
                取消
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                // disabled={btnDisabled}
                onClick={() => {
                  submitRef.current.submit();
                  setTimeout(() => {
                    submitRef.current.resetFields();
                    submitRef.current.setFieldsValue({
                      selectedDate: [
                        moment(filterData.start_time),
                        moment(filterData.end_time),
                      ],
                      ...filterData,
                    });
                  }, 100);
                  // setSubmitForm(true)
                }}
              >
                篩選
              </Button>
            </div>
          }
          visible={filterVisible}
        >
          <Form
            ref={submitRef}
            name="basic"
            initialValues={{ selectedDate: moment(selectedDate) }}
            layout="vertical"
            onFinish={(value) => {
              let filter = { ...filterData },
                labelObj = filterObjectLabel ? { ...filterObjectLabel } : {};
              Object.keys(value).forEach((key, i) => {
                if (value[key]) {
                  if (key === "selectedDate") {
                    filter.start_time = value[key][0].format("YYYY-MM-DD");
                    filter.end_time = value[key][1].format("YYYY-MM-DD");
                    setSelectedDate(value[key][0]);
                  } else {
                    filter[key] = [value[key]];
                    labelObj[key] = [value[key]];
                  }
                  // else if (key === "origin" || key === "destination") {
                  //   let town = Town ? Town[0]["children"] : [];
                  //   let label_list = [];
                  //   value[key].forEach((v) => {
                  //     let text = "";
                  //     if (v.length === 1) {
                  //       text = v[0].split(",")[1];
                  //     } else {
                  //       let county = town.filter((t) => t.value === v[1]);
                  //       if (county.length > 0) {
                  //         text += county[0]["label"];
                  //         if (v.length === 3) {
                  //           text += county[0]["children"].filter(
                  //             (c) => c.value === v[2]
                  //           )[0]["label"];
                  //         }
                  //       }
                  //     }
                  //     label_list.push(text);
                  //     labelObj[key] = label_list;
                  //     filter[key] = value[key];
                  //   });
                  // } 
                }else{
                  delete filter[key] ;
                  delete labelObj[key];
                }
              });

              setFilterObjectLabel(labelObj);
              setFilterData(filter);
              setFilterVisible(false);
            }}
            autoComplete="off"
          >
            <Form.Item label="運送日期" name="selectedDate">
              <RangePicker
                style={{ width: "100%" }}
                disabledDate={disabledDate}
                onCalendarChange={(val) => setDates(val)}
                onOpenChange={onOpenChange}
              />
            </Form.Item>
            <Form.Item label="案件編號" name="case_id">
              <Input placeholder="請輸入案件編號" />
            </Form.Item>
            <Form.Item label="客戶別" name="customer">
            <AutoComplete  notFoundContent={null}
              options={(customerOption || []).map(d => ({
                value: `${d.id}-${d.name}`,
                label: `${d.id}-${d.name}`,
              }))}
              onSearch={(searchText)=>{
                let search = searchText? [...AllOption.customer].filter(d=> d.id.indexOf(searchText)>-1 || d.name.toLowerCase().indexOf(searchText.toLowerCase())>-1):[...AllOption.customer]
                setCustomerOption(search)
              }}
              allowClears
              placeholder='請選擇客戶別'
              />
        
              {/* <Select allowClear showSearch placeholder="請選擇客戶別">
                {customerOption &&
                  customerOption.map((o) => (
                    <Option
                      key={`${o.id}-${o.name}`}
                      value={`${o.id}-${o.name}`}
                    >{`${o.id}-${o.name}`}</Option>
                  ))}
              </Select> */}
            </Form.Item>
            <Form.Item label="執行技師" name="driver_name_list">
              <Input allowClear placeholder="請選擇執行技師" />
            </Form.Item>
            <Form.Item label="車牌" name="vin">
              <Input placeholder="請輸入車牌" />
            </Form.Item>
            <Form.Item label="運送起點" name="origin_name">
            <Input placeholder="請輸入運送起點" />

              {/* <CustomCascader
                defaultValue={filterData ? filterData.origin : ""}
                multiple
                SeperatedName
                withServicePoint
                OnChange={(val) => {
                  submitRef.current.setFieldsValue({ origin: val });
                }}
              /> */}
            </Form.Item>
            <Form.Item label="運送迄點" name="destination_name">
            <Input placeholder="請輸入運送迄點" />
              {/* <CustomCascader
                defaultValue={filterData ? filterData.destination : ""}
                multiple
                SeperatedName
                withServicePoint
                OnChange={(val) => {
                  submitRef.current.setFieldsValue({ destination: val });
                }}
              /> */}
            </Form.Item>
          </Form>
        </Drawer>
        <Modal
          title={modal === 1 ? "案件明細" : "總表"}
          visible={modal}
          onOk={() => statementForm.current.submit()}
          okText={`製作${modalBtnDisabled ? "中..." : ""}`}
          okButtonProps={{ disabled: modalBtnDisabled }}
          onCancel={() => {
            statementForm.current.resetFields();
            setModal(null);
          }}
          width={416}
        >
          <Form
            name="basic"
            layout="vertical"
            ref={statementForm}
            onFinish={(val) => Report(val)}
          >
            <Form.Item
              label="運送日期"
              name="date"
              rules={[{ required: true, message: "請選擇執行日期!" }]}
            >
              <RangePicker
                style={{ width: "100%" }}
                disabledDate={(current) => {
                  let now = moment().add(1, "d").format("YYYY-MM-DD");
                  return current > moment(`${now} 00:00:00`);
                }}
              />
            </Form.Item>
            {modal === 1 && (
              <>
                <Form.Item label="客戶別" name="customer_row_id">
                  <Cascader
                    options={customerOption ? customerOption : []}
                    placeholder="選擇客戶別"
                    showSearch={(inputValue, path) => {
                      path.some(
                        (option) =>
                          option.label
                            .toLowerCase()
                            .indexOf(inputValue.toLowerCase()) > -1
                      );
                    }}
                  />
                </Form.Item>
                <Form.Item label="案件類型" name="case_type_id">
                  <Cascader
                    options={caseTypeList}
                    placeholder="選擇案件類型"
                    showSearch={(inputValue, path) => {
                      path.some(
                        (option) =>
                          option.label
                            .toLowerCase()
                            .indexOf(inputValue.toLowerCase()) > -1
                      );
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label="服務據點"
                  name="service_point_row_id"
                // rules={[{ required: true, message: "請選擇案件類型！" }]}
                >
                  <Cascader
                    placeholder="選擇服務據點"
                    options={ServicePointList}
                    showSearch={(inputValue, path) => {
                      path.some(
                        (option) =>
                          option.label
                            .toLowerCase()
                            .indexOf(inputValue.toLowerCase()) > -1
                      );
                    }}
                  />
                </Form.Item>
              </>
            )}
          </Form>
        </Modal>
      </div>
    </div>
  );
};

export default Index;

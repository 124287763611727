import React, { useState, useRef,useEffect } from 'react'
import { Input, Form, Upload, Radio, DatePicker, Button,Popconfirm } from 'antd'
import { EnterOutlined, UploadOutlined, UserOutlined, PhoneOutlined, ArrowRightOutlined } from '@ant-design/icons'
import CustomCascader from '../../shared/component/CustomCascader'
import { CheckStationId } from '../../../api/ServicePoint'
import {  useNavigate } from 'react-router-dom'

const { RangePicker } = DatePicker

const Step1 = ({ setCurrentStep, setData, data }) => {
    const [fileList, setFileList] = useState([])
    const [isValid, setIsValid] = useState(null)
    const form = useRef()
    const navigate =useNavigate()
    const onFinish = async(values) => {
        setData(values)
        let check = await checkId()
        setIsValid(check)
        if(check){
            setCurrentStep(1)
        }
    };
    const checkId = async () => {
        let id = form.current.getFieldValue().id
        let res = await CheckStationId(id)
        setIsValid(res.Result)
        return res.Result
    }
    useEffect(() => {
        form.current.setFieldsValue(data)
        if(data && data.file){
            setFileList(data.file.fileList)
        }
    }, [data]);
    return (
        <div >
            <Form
                ref={form}
                name="basic"
                layout="vertical"
                onFinish={onFinish}
                initialValues={{industry: "1"}}
            >
                <div className='step_content'>
                    <Form.Item
                        label="產業別"
                        name="industry"
                        rules={[{ required: true, message: '請選擇產業別!' }]}
                    >
                        <Radio.Group >
                            <Radio.Button value="1">物流倉儲業</Radio.Button>
                            <Radio.Button value="2">大眾運輸業</Radio.Button>
                            <Radio.Button value="3">道路救援業</Radio.Button>
                            <Radio.Button value="4">汽機車買賣業</Radio.Button>
                        </Radio.Group>
                    </Form.Item>
                    <div style={{ display: "flex" }}>
                        <Form.Item
                            label="據點編號"
                            name="id"
                            rules={[{ required: true, message: '請輸入據點編號!' }]}
                            hasFeedback={isValid !== null ? true : false}
                            validateStatus={isValid === null ? '' : isValid ? 'success' : 'error'}
                            help={isValid !== null && !isValid? '此據點編號已存在!':''}
                        >
                            <Input 
                                placeholder='請輸入 5 碼編號後點擊右側按鈕檢驗'
                                addonAfter={<EnterOutlined style={{ cursor: "pointer" }} onClick={checkId} />} style={{ width: "304px" }}
                                onKeyUp={(e) => form.current.setFieldsValue({ id: e.target.value })}
                            />
                        </Form.Item>
                        <Form.Item
                            label="據點名稱"
                            name="name"
                            rules={[{ required: true, message: '請輸入據點名稱！' }]}
                            style={{ margin: '0 16px' }}
                        >
                            <Input allowClear placeholder='example' style={{ width: "304px" }} />
                        </Form.Item>
                        <Form.Item
                            label="簽約公司"
                            name="contract_company"
                            rules={[{ required: true, message: '請輸入簽約公司!' }]}
                        >
                            <Input allowClear placeholder='請輸入代號或公司名稱' style={{ width: "304px" }} />
                        </Form.Item>
                    </div>
                    <div style={{ display: "flex" }}>
                        <Form.Item
                            label="合約名稱"
                            name="contract_name"
                        >
                            <Input allowClear placeholder='點擊選擇' style={{ width: "304px" }} />
                        </Form.Item>
                        <div >
                            <div style={{ height: 34 }}></div>
                            <Form.Item
                                name="file"
                                label=""
                                className='antd-input-upload'
                                style={{ margin: "0 16px" }}
                            // extra="僅接受 jpg, png 並且小於 5mb 之檔案格式類型"
                            // rules={[{ required: true, message: '請上傳名片！' }]}

                            >
                                <Upload
                                    name="logo"
                                    //   accept="image/png, image/jpeg, image/jpg"
                                    fileList={fileList}
                                    customRequest={({ onSuccess }) => onSuccess("ok")}
                                    onChange={({ file, fileList }) => {
                                        setFileList([file])
                                        form.current.value = file
                                    }}
                                >
                                    <Button icon={<UploadOutlined />}>上傳電子檔</Button>
                                </Upload>
                            </Form.Item>
                        </div>

                        <Form.Item
                            label="合約期間"
                            name="contract_period"
                            rules={[{ required: true, message: '請輸入合約期間！' }]}
                        >
                            <RangePicker style={{ width: "304px" }} />
                        </Form.Item>
                    </div>
                    <div style={{ display: "flex" }}>
                        <Form.Item
                            label="負責人姓名"
                            name="responsible_person"
                            rules={[{ required: true, message: '請輸入負責人姓名!' }]}

                        >
                            <Input allowClear placeholder='example' style={{ width: "304px" }} />
                        </Form.Item>

                        <Form.Item
                            label="統編"
                            name="responsible_person_id"
                            rules={[{ required: true, message: '請輸入統編！' }]}
                            style={{ margin: '0 16px' }}
                        >
                            <Input allowClear placeholder='example' style={{ width: "304px" }} />
                        </Form.Item>
                    </div>

                    <div style={{ display: "flex", alignItems: "flex-end" }}>
                        <Form.Item
                            label="聯絡人資訊"
                            name="contact_name"
                            rules={[{ required: true, message: '請輸入聯絡人資訊名字！' }]}
                        >
                            <Input prefix={<UserOutlined />} allowClear placeholder='請輸入名字' style={{ width: "304px", marginRight: 16 }} />
                        </Form.Item>
                        <Form.Item
                            label=""
                            name="tel"
                            rules={[{ required: true, message: '請輸入聯絡人電話！' }]}
                        >
                            <Input prefix={<PhoneOutlined />} allowClear placeholder='請輸入電話' style={{ width: "304px" }} />
                        </Form.Item>
                    </div>
                    <div style={{ display: "flex" }}>
                        <Form.Item
                            label="傳真號碼"
                            name="fax"
                        >
                            <Input allowClear placeholder='example' style={{ width: "304px" }} />
                        </Form.Item>

                        <Form.Item
                            label="電子郵件"
                            name="email"
                            style={{ margin: '0 16px' }}
                        >
                            <Input allowClear placeholder='example' style={{ width: "304px" }} />
                        </Form.Item>
                    </div>
                    <div style={{ display: "flex" }}>
                        <Form.Item
                            label="地址"
                            name="address_county"
                            rules={[{ required: true, message: "請選擇地址!" }]}
                            style={{ marginRight: "16px" }}
                        >
                            <CustomCascader
                                defaultValue={data?data.address_county:''}
                                style={{ width: "304px" }}
                                OnChange={(val) => form.current.setFieldsValue({ address_county: val })}
                            />
                        </Form.Item>
                        <div>
                            <div style={{ height: 34 }}></div>
                            <Form.Item
                                label=""
                                name="address"
                                rules={[{ required: true, message: "請輸入完整地址!" }]}
                                style={{ marginRight: "16px" }}
                            >
                                <Input allowClear placeholder='請輸入完整地址' style={{ width: "304px" }} />
                            </Form.Item>
                        </div>

                    </div>
                </div>
                <div style={{ background: "white", marginTop: 16, width: "100%", padding: "20px", display: "flex", justifyContent: "flex-end" }}>
                    <Popconfirm
                        style={{ width: 410 }}
                        placement="top"
                        title={
                            <div>
                                <div>
                                    <strong>新增還沒完成喔</strong>
                                </div>
                                <div style={{ fontSize: 14 }}>
                                    剛剛輸入的所有資訊將不被保存。
                                </div>
                            </div>
                        }
                        onCancel={() => navigate(-1)}
                        okText="不，我要留下"
                        cancelText="對，我要離開"
                    >
                        <Button type="text" style={{ marginRight: 24 }}>
                            關閉
                        </Button>
                    </Popconfirm>
                    <Button type="primary" icon={<ArrowRightOutlined />}
                        htmlType="submit"
                    > 下一步 </Button>
                </div>
            </Form>
        </div>
    );
}

export default Step1;

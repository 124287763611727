import React, { useEffect, useState, useRef } from 'react'
import { PageHeader, Form, Input, Button, Select, Radio, Table, DatePicker, Cascader, message,Popconfirm } from 'antd'
import BreadCrumb from '../../../components/shared/BreadCrumb'
import {  PlusOutlined, RightOutlined, UnorderedListOutlined } from '@ant-design/icons'
import { useNavigate,useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { UpdateMarketingNote, AddMarketing, AddMarketingTarget, GetMarketingList } from '../../../api/Marketing'

const { Option } = Select
const { RangePicker } = DatePicker
const { TextArea } = Input
const Add = ({ ContainerWidth }) => {
    const submitForm = useRef()
    const { state } = useLocation()
    const [currentStep, setCurrentStep] = useState(0)
    const [promotionType, setPromotionType] = useState(state ? state.type: "001")
    const [remark, setRemark] = useState("")
    let CustomerLists = useSelector(state => state.CustomerList) //客戶別選項
    let caseType = useSelector(state => state.caseType) //客戶別選項
    const [targetList, setTargetList] = useState([{ id: 1, customer_row_id: '', service_id: ''}])
    let navigate = useNavigate()
    let Town = useSelector(state => state.FetchAPI)
    const [pointOptions, setPointOptions] = useState([])
    const [customerOptions, setCutomerOptions] = useState([])
    const [caseOption, setCaseOption] = useState([])
    const [successData, setSuccessData] = useState([])
    const [monthlyDisocunt, setMonthlyDisocunt] = useState(false)
    const SetchangeValue = (index, id, val) => {
        let new_targetList = [...targetList]
        new_targetList = new_targetList.map((d, i) => {
            if (i === (index - 1)) {
                d[id] = val
            }
            return d
        })
        setTargetList(new_targetList)
    }
    const onFinish = async (val) => {
        val.start_date = moment(val["promotionDateRange"][0]).format("YYYY-MM-DD")
        val.end_date = moment(val["promotionDateRange"][1]).format("YYYY-MM-DD")
        let param = ""
        Object.keys(val).forEach((key,i)=>{
            param += i === 0 ? `${key}=${val[key]}` : `&${key}=${val[key]}`
        })
        if(val.t001_reach==="不需輸入"){
            val.t001_reach =0
        }
        let res = await AddMarketing(param)
        if (!res.Error) {
            // promotionDateRange
            let success = []
            for (let i = 0; i < targetList.length; i++) {
                let service_id = ''
                targetList[i]["serviceType"].forEach((t, i) => {
                    service_id += i === 0 ? t : `,${t}`
                })

                let target_param = `marketing_row_id=${res.Result.Data.row_id}&customer_row_id=${targetList[i]["customer_row_id"]}&service_id=${service_id}`
                let target_res = await AddMarketingTarget(target_param)
                if (!target_res.Error) {
                    success.push(1)
                } else {
                    success.push(0)
                }
            }

            let filter = success.filter(s => s === 0)
            if (filter.length === 0) {
                setSuccessData({ row_id: res.Result.Data.row_id, ...val })
                setCurrentStep(1)
                submitForm.current.resetFields()
                setTargetList([{ id: 1, customer_row_id: '', service_id: '' }])
            }
        }

    }
    const ChangeTarget = async (type, obj) => {
        let new_targetList = [...targetList]
        if (type === "add") {
            let obj = { id: targetList.length + 1, customer_row_id: '', service_id: '' }
            new_targetList.push(obj)
        } else {
            let set_obj = {}
            let old_obj = {}
            submitForm.current.setFieldsValue({ [`customer_${obj.id}`]: '', [`serviceType_${obj.id}`]: "" })
            targetList.forEach((t, i) => {
                old_obj[`customer_${i + 1}`] = []
                old_obj[`serviceType_${i + 1}`] = []
            })
            submitForm.current.setFieldsValue(old_obj)
            new_targetList = [...targetList].filter(t => t.id !== obj.id)
                .map((t, i) => {
                    t.customer_id = t.id 
                    t.id = i + 1
                    set_obj[`customer_${i + 1}`] = t.customer_row_id
                    set_obj[`serviceType_${i + 1}`] = t.serviceType
                    return t
                })
            submitForm.current.setFieldsValue(set_obj)
        }
        setTargetList(new_targetList)

    }
   
    useEffect(() => {

        if (caseType && CustomerLists) {
            let new_CustomerLists = CustomerLists.map(d => {
                d.id = d.row_id
                return d
            })
            setCutomerOptions(new_CustomerLists)
            setCaseOption(caseType)
            if(state){
                if(submitForm.current){
                    submitForm.current.setFieldsValue(state)
                    let obj={}
                    state.TargetList=state.TargetList.map((t,i)=>{
                        t.id = i+1
                        let filter = caseType.filter(c=>c.Name ===t.service_name)
                        let Filter = CustomerLists.filter(c =>c.name === t.name)
                        obj[`customer_${i+1}`] =[Filter[0]["row_id"]]
                        obj[`serviceType_${i+1}`] =  filter.map(f=>f.Code)
                        return t
                    })
                    submitForm.current.setFieldsValue(obj)

                }
                setTargetList(state.TargetList)
            }
        }
    }, [caseType,state,CustomerLists])
    useEffect(() => {
        if (Town !== null) {
            setPointOptions(Town)
        }
    }, [Town])
    return (
        <div className='container' style={ContainerWidth}>
            <PageHeader
                title="新增行銷活動"
                onBack={() => currentStep === 0 ? window.history.go(-1) : setCurrentStep(currentStep - 1)}
                className="site-page-header"
                breadcrumb={<BreadCrumb />}
            >
            </PageHeader>
            <div className='step_block'>
                <div >
                    <Form
                        ref={submitForm}
                        name="basic"
                        layout="vertical"
                        initialValues={{ type: promotionType, t001_reach_type: '001', t001_discount_type: '001', t002_discount_type: '001', t003_discount_type: '001' }}
                        onFinish={onFinish}
                    // onFinishFailed={onFinishFailed}
                    // autoComplete="off"
                    >
                        <div style={{ minHeight: "calc(100vh - 240px)" }}>
                            <div className='step_content'>
                                {
                                    currentStep === 0 ? (<>
                                        <div style={{ display: "flex" }}>
                                            <Form.Item
                                                label="活動名稱"
                                                name="name"
                                                rules={[{ required: true, message: '請輸入活動名稱！' }]}
                                            >
                                                <Input style={{ width: "304px" }} placeholder='example' />
                                            </Form.Item>
                                            <Form.Item
                                                label="活動期間"
                                                name="promotionDateRange"
                                                style={{ margin: '0 16px' }}
                                                rules={[{ required: true, message: '請選擇業時間（週末）！' }]}
                                            >
                                                <RangePicker style={{ width: "304px" }} />
                                            </Form.Item>
                                        </div>
                                        <Form.Item
                                            label='活動類型'
                                            name="type"
                                            rules={[{ required: true, message: '請選擇活動類型！' }]}
                                        >
                                            <Radio.Group
                                                buttonStyle="solid"
                                                onChange={(e) => {
                                                    setPromotionType(e.target.value)
                                                    // if (currentStep === 0) {
                                                    // setSourceState(e.target.value);
                                                    // }
                                                }}
                                            >
                                                <Radio.Button value="001">全月折扣</Radio.Button>
                                                <Radio.Button value="002">多車折扣（每車）</Radio.Button>
                                                <Radio.Button value="003">多車折扣（整單）</Radio.Button>
                                                <Radio.Button value="004">滿幾送幾</Radio.Button>
                                            </Radio.Group>

                                        </Form.Item>

                                        {
                                            promotionType === "001" && (
                                                <div style={{ display: "flex" }}>
                                                    <Form.Item
                                                        label="達標車輛數"
                                                        name="t001_reach"
                                                        rules={[{ required: true, message: '請輸入台數!' }]}
                                                    >
                                                        <Input
                                                            style={{ marginRight: "16px", width: 304 }}
                                                            allowClear
                                                            disabled={monthlyDisocunt}
                                                            placeholder="請輸入台數"
                                                            addonBefore={
                                                                <Form.Item name="t001_reach_type" noStyle>
                                                                    <Select onChange={(val)=>{
                                                                        if(val ==="002"){
                                                                            setMonthlyDisocunt(true)
                                                                            submitForm.current.setFieldsValue({t001_reach:'不需輸入'})
                                                                        }
                                                                    }
                                                                    }>
                                                                        <Option value='001'>當月車輛</Option>
                                                                        <Option value='002'>不限定</Option>
                                                                    </Select>
                                                                </Form.Item>

                                                            }
                                                        />
                                                    </Form.Item>
                                                    <Form.Item
                                                        label="總金額"
                                                        name="t001_discount"
                                                        rules={[{ required: true, message: '請輸入總金額!' }]}
                                                    >
                                                        {/* <div style={{ height: 30 }}></div> */}
                                                        <Input
                                                            allowClear
                                                            placeholder="請輸入折扣 % 數"
                                                            addonBefore={
                                                                <Form.Item name="t001_discount_type" noStyle>
                                                                    <Select>
                                                                        <Option value='001'>折扣</Option>
                                                                        <Option value='002'>退佣金</Option>
                                                                    </Select>
                                                                </Form.Item>

                                                            }
                                                            addonAfter={'%'}
                                                        />
                                                    </Form.Item>
                                                </div>
                                            )
                                        }
                                        {
                                            promotionType === "002" && (
                                                <div style={{ display: "flex" }}>
                                                    <Form.Item
                                                        label="同單車輛數"
                                                        name="t002_car_num"
                                                        rules={[{ required: true, message: '請輸入車輛數字!' }]}
                                                    >
                                                        <Input
                                                            style={{ marginRight: "16px", width: 304 }}
                                                            allowClear
                                                            placeholder="請輸入車輛數字"
                                                            addonBefore='大於等於'
                                                        />
                                                    </Form.Item>
                                                    <Form.Item
                                                        label="每車折扣"
                                                        name="t002_discount"
                                                        rules={[{ required: true, message: '請輸入數字!' }]}
                                                    >
                                                        <Input
                                                            allowClear
                                                            placeholder="請輸入數字"
                                                            style={{ width: 304 }}
                                                            addonAfter={
                                                                <Form.Item name="t002_discount_type" noStyle>
                                                                    <Select>
                                                                        <Option value='001'>元</Option>
                                                                        <Option value='002'>%</Option>
                                                                    </Select>
                                                                </Form.Item>

                                                            }
                                                        />
                                                    </Form.Item>
                                                </div>
                                            )
                                        }
                                        {
                                            promotionType === "003" && (
                                                <div style={{ display: "flex" }}>
                                                    <Form.Item
                                                        label="同單車輛數"
                                                        name="003_car_num"
                                                        rules={[{ required: true, message: '請輸入車輛數字!' }]}
                                                    >
                                                        <Input
                                                            style={{ marginRight: "16px", width: 304 }}
                                                            allowClear
                                                            placeholder="請輸入車輛數字"
                                                            addonBefore='大於等於'
                                                        />
                                                    </Form.Item>
                                                    <Form.Item
                                                        label="整單折扣"
                                                        name="t003_discount"
                                                        rules={[{ required: true, message: '請輸入數字!' }]}
                                                    >
                                                        <Input
                                                            allowClear
                                                            placeholder="請輸入數字"
                                                            style={{ marginRight: "16px", width: 304 }}
                                                            addonAfter={
                                                                <Form.Item name="t003_discount_type" noStyle>
                                                                    <Select>
                                                                        <Option value='001'>元</Option>
                                                                        <Option value='002'>%</Option>
                                                                    </Select>
                                                                </Form.Item>
                                                            }
                                                        />
                                                    </Form.Item>
                                                </div>
                                            )
                                        }
                                        {
                                            promotionType === "004" && (
                                                <div style={{ display: "flex" }}>
                                                    <Form.Item
                                                        label="同單車輛數"
                                                        name="t004_car_num"
                                                        rules={[{ required: true, message: '請輸入車輛數字!' }]}
                                                    >
                                                        <Input
                                                            style={{ marginRight: "16px", width: 304 }}
                                                            allowClear
                                                            placeholder="請輸入車輛數字"
                                                            addonAfter='台'
                                                        />
                                                    </Form.Item>
                                                    <Form.Item
                                                        label="贈送車輛數"
                                                        name="t004_give_num"
                                                        rules={[{ required: true, message: '請輸入車輛數字!' }]}
                                                    >
                                                        <Input
                                                            style={{ marginRight: "16px", width: 304 }}
                                                            allowClear
                                                            placeholder="請輸入車輛數字"
                                                            addonAfter='台'

                                                        />
                                                    </Form.Item>
                                                </div>
                                            )
                                        }
                                        <div>
                                            <div style={{ marginBottom: "8px" }}>
                                                <span style={{ color: "#ff4d4f", fontSize: "14px", marginRight: "4px" }}>*</span>
                                                <span >適用對象</span>
                                            </div>
                                            <Table
                                                pagination={false}
                                                dataSource={targetList}
                                                columns={[
                                                    {
                                                        title: '#',
                                                        dataIndex: 'id',
                                                        width: '32px',
                                                    },
                                                    {
                                                        title: <><span style={{ color: "#ff4d4f" }}>*</span><span>客戶代號</span></>,
                                                        dataIndex: 'customer_row_id',
                                                        width: '200px',
                                                        render: (data, _) => (
                                                            <Form.Item
                                                                label=""
                                                                name={`customer_${_.id}`}
                                                                rules={[{ required: true, message: '請選擇客戶!' }]}
                                                                style={{ marginBottom: 0 }}
                                                            >

                                                                <Cascader
                                                                    options={customerOptions}
                                                                    onChange={(val) => SetchangeValue(_.id, 'customer_row_id', val)}
                                                                    placeholder="點擊選擇"
                                                                    showSearch={(inputValue, path) => (
                                                                        path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()))
                                                                    )}

                                                                />
                                                            </Form.Item>)
                                                    },
                                                    {
                                                        title: '服務案型',
                                                        dataIndex: 'serviceType',
                                                        render: (data, _) => (
                                                            <Form.Item
                                                                label=""
                                                                name={`serviceType_${_.id}`}
                                                                rules={[{ required: true, message: '請選擇服務案型!' }]}
                                                                style={{ marginBottom: 0 }}
                                                                defaultValue={["001", "002"]}
                                                            >
                                                                <Select
                                                                    block
                                                                    style={{ width: '100%' }}
                                                                    allowClear
                                                                    mode='multiple'
                                                                    placeholder="點擊選擇"
                                                                    onChange={(val) => SetchangeValue(_.id, `serviceType`, val)}
                                                                >
                                                                    {
                                                                        caseOption.map(c => (<Option key={`${c.Code} - ${c.Name}運送`} value={c.Code}>{c.Name}</Option>))
                                                                    }

                                                                </Select>
                                                            </Form.Item>
                                                        )

                                                    },
                                                    {
                                                        title: '',
                                                        dataIndex: '',
                                                        width: '67px',
                                                        render: (data, _) => (<Button
                                                            onClick={() => ChangeTarget("remove", _)}
                                                            type="link">刪除</Button>)
                                                    },
                                                ]}
                                            />
                                            <Button
                                                onClick={() => ChangeTarget("add")}
                                                style={{ marginTop: "16px", marginBottom: 24, width: '100%' }}
                                                type="dashed" >
                                                <PlusOutlined /> 新增適用對象
                                            </Button>
                                        </div>

                                    </>) : (<>
                                        <div style={{ fontSize: 30 }}> 行銷活動 {successData.name} 已新增</div>
                                        <div style={{ color: "rgba(0, 0, 0, 0.45)", marginTop: 8 }}>行銷活動已建立完成，符合該活動條件之專案將會以活動價格計價</div>
                                        <TextArea
                                            placeholder="額外的活動說明請寫在此......"
                                            autoSize={{ minRows: 2, maxRows: 4 }}
                                            style={{ width: 395, marginTop: 8 }}
                                            value={remark}
                                            onChange={(e) => setRemark(e.target.value)}
                                        />
                                        <Button
                                            style={{ display: "block", marginTop: 8 }}
                                            disabled={remark !== "" ? "" : "disabled"}
                                            onClick={() => {
                                                let updated_uid = '00050'
                                                let param = `row_id=${successData.row_id}&note=${remark}&update_uid=${updated_uid}`
                                                UpdateMarketingNote(param).then(data => {
                                                    if (data.Result.Success) {
                                                        setRemark("")
                                                        submitForm.current.resetFields()
                                                        message.success('已新增備註')
                                                    }
                                                })
                                            }}
                                        >新增備註</Button>
                                        <div style={{ marginTop: 32 }}>
                                            <Button
                                                type="primary"
                                                icon={<PlusOutlined />}
                                                onClick={() => setCurrentStep(0)}
                                            >新增其他行銷活動</Button>

                                            <Button
                                                icon={<RightOutlined />}
                                                style={{ margin: "0 8px" }}
                                                onClick={() => {
                                                    GetMarketingList(0, -1, successData.row_id).then(res => {
                                                        if (!res.Error) {
                                                           let data = res.Result.Data.map(d => {
                                                                d.status_color = d.status === 0 ? "#F5222D" : d.status === 1 ? "#52C41A" : "#D9D9D9"
                                                                d.tag_color = d.status === 0 ? "red" : d.status === 1 ? "green" : "default"
                                                                if (!d.note) {
                                                                    d.note = "-"
                                                                }
                                                                return d
                                                            })
                                                            .filter(d=>d.row_id === successData.row_id)
                                                            if(data.length > 0){
                                                                navigate(`/customer/promotions/${successData.row_id}`, { state: {...data[0]} })
                                                            }
                                                        }

                                                    })
                                                   
                                                }}
                                            >行銷活動內容</Button>
                                            <Button
                                                icon={<UnorderedListOutlined />}
                                                onClick={() => navigate("/customer/promotions")}
                                            >回列表</Button>
                                        </div>
                                    </>)
                                }
                            </div>
                        </div>
                        <div style={{ background: "white", marginTop: 16, width: "100%", padding: "20px", display: "flex", justifyContent: "flex-end" }}>
                        <Popconfirm
                                style={{ width: 410 }}
                                placement="top"
                                title={
                                    <div>
                                        <div>
                                            <strong>新增還沒完成喔</strong>
                                        </div>
                                        <div style={{ fontSize: 14 }}>
                                            剛剛輸入的所有資訊將不被保存。
                                        </div>
                                    </div>
                                }
                                onCancel={() => navigate(-1)}
                                okText="不，我要留下"
                                cancelText="對，我要離開"
                            >
                                <Button type="text" style={{ marginRight: 24 }}>
                                    關閉
                                </Button>
                            </Popconfirm>
                            <Button type="primary"
                                icon={<PlusOutlined />}
                                htmlType="submit"
                            > 新增 </Button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    )
}


export default Add
import React, { useEffect, useState, useRef } from 'react'
import { PageHeader, Form, Input, Button, Radio, message, TimePicker, Checkbox, Popconfirm } from 'antd'
import BreadCrumb from '../../../components/shared/BreadCrumb'
import { PlusOutlined, RightOutlined, UnorderedListOutlined, } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import CustomCascader from '../../../components/shared/component/CustomCascader'
import { UpdateCommonStationRemark, AddCommonStation } from '../../../api/Customer'
import { CheckValidAddr } from '../../../api/Code'
import LoadingBlock from '../../../components/shared/LoadingBlock'
import moment from 'moment'
// const { Option } = Select
// const { RangePicker } = DatePicker;
const { TextArea } = Input
const ServicePointsAdd = ({ ContainerWidth }) => {
    let navigate = useNavigate()
    const format = "HH:mm"
    const [currentStep, setCurrentStep] = useState(0)
    const [remark, setRemark] = useState("")
    const [successSubmit, setSuccessSubmit] = useState(null)
    let CommonStationType = useSelector(state => state.CommonStationType)
    const [StationTypeOptions, setStationTypeOptions] = useState([])
    const [defaultStationType, setDefaultStationType] = useState(null)
    const [checkboxStatus, setCheckboxStatus] = useState({ normal: false, weekend: false, holiday: false })

    const [weekDayOperatingHours, setWeekDayOperatingHours] = useState(null)
    const [weekendOperatingHours, setWeekendOperatingHours] = useState(null)
    const [holidayOperatingHours, setHolidayOperatingHours] = useState(null)
    const [inValidAdd, setInValidAdd] = useState(null)
    useEffect(() => {
        if (CommonStationType) {
            setStationTypeOptions(CommonStationType)
            setDefaultStationType(CommonStationType[0]["Code"])
        }
    }, [CommonStationType])
    const form = useRef()
    const checkOperatingHour = (e, type) => {
        let isChecked = e
        let val = isChecked ? [moment("0"), moment("0")] : []
        let new_checkboxStatus = { ...checkboxStatus }
        new_checkboxStatus[type] = e
        setCheckboxStatus(new_checkboxStatus)
        form.current.setFieldsValue({ [type]: val })
    }
    const SubmitForm = async (value) => {

        let address = `${value.address_county[0].split(",")[1]}${value.address_county[1].split(",")[1]}${value.address}`
        value.county = value.address_county[0].split(",")[0]
        value.town = value.address_county[1].split(",")[0]
        let arr = ["normal", "weekend", "holiday"]
        arr.map(a => {
            let start = `${a}_start_time`
            let end = `${a}_end_time`
            let open_key = `${a}_open`
            value[start] = value[a][0].format(format)
            value[end] = value[a][1].format(format)
            //checkboxStatus[a]=== true 為勾選不營業
            value[open_key] = checkboxStatus[a] ? "0" : "1"
        })
        // let param = ''
        // Object.keys(value).map((key, i) => {
        //     param += i === 0 ? `${key}=${value[key]}` : `&${key}=${value[key]}`

        // })

        let invalid = await CheckValidAddr({ addr_list: [address] })
        if (invalid && !invalid.Error) {
            let valid =
                setInValidAdd(!invalid.Result[0]['is_check'])
            if (invalid.Result[0]['is_check']) {
                AddCommonStation(value).then(result => {
                    if (result.Result.Success) {
                        let data = { ...result.Result.Data, name: value.name }
                        setSuccessSubmit(data)
                        setCurrentStep(1)
                    }
                })
            }
        }


    }
    return (
        <div className='container' style={ContainerWidth}>
            <PageHeader
                title="新增共用運送點"
                onBack={() => currentStep === 0 ? window.history.go(-1) : setCurrentStep(currentStep - 1)}
                className="site-page-header"
                breadcrumb={<BreadCrumb />}
            >
            </PageHeader>
            <div className='step_block'>
                <div>
                    {
                        defaultStationType ? (
                            <Form
                                name="basic"
                                layout="vertical"
                                initialValues={{ station_type_code: defaultStationType }}
                                onFinish={SubmitForm}
                                ref={form}
                            >
                                <div style={{ minHeight: "calc(100vh - 240px)" }}>
                                    <div className='step_content'>
                                        {
                                            currentStep === 0 ? (<>
                                                <Form.Item
                                                    label={"共用運送點類型"}
                                                    name="station_type_code"
                                                    rules={[{ required: true, message: '請選擇客戶等級！' }]}
                                                >
                                                    <Radio.Group
                                                        buttonStyle="solid"
                                                        onChange={(e) => { }}
                                                    >
                                                        {
                                                            StationTypeOptions.map(station => (<Radio.Button value={station.Code}>{station.Name}</Radio.Button>))
                                                        }
                                                    </Radio.Group>
                                                </Form.Item>
                                                <Form.Item
                                                    label="名稱"
                                                    name="name"
                                                    rules={[{ required: true, message: '請輸入名稱!' }]}
                                                >
                                                    <Input allowClear placeholder='請輸入名稱' style={{ width: "304px" }} />
                                                </Form.Item>

                                                <div style={{ display: "flex" }}>
                                                    <Form.Item
                                                        label="地址"
                                                        name="address_county"
                                                        rules={[{ required: true, message: "請選擇地址!" }]}
                                                        style={{ marginRight: "16px" }}
                                                    >
                                                        <CustomCascader
                                                            NameCode
                                                            style={{ width: "304px" }}
                                                            OnChange={(val) => form.current.setFieldsValue({ address_county: val })}
                                                        />
                                                    </Form.Item>
                                                    <div>
                                                        <div style={{ height: 34 }}></div>
                                                        <Form.Item
                                                            inValidAdd
                                                            label=""
                                                            name="address"
                                                            rules={[{ required: true, message: "請輸入完整地址!" }]}
                                                            style={{ marginRight: "16px" }}
                                                            hasFeedback={inValidAdd ? false:true}
                                                            help={inValidAdd ? "查無此地址，請輸入有效地址" : ""}
                                                            validateStatus={inValidAdd === null?"" :!inValidAdd? 'success' : 'error'}
                                                        >
                                                            <Input placeholder='請輸入完整地址' style={{ width: "304px" }} />
                                                        </Form.Item>
                                                    </div>

                                                </div>
                                                <div style={{ fontSize: 16, fontWeight: 700 }}>營業時間</div>
                                                <div style={{ background: "rgba(250, 250, 250, 1)", padding: " 0 16px" }}>
                                                    <div style={{ display: "flex", paddingTop: 16, alignItems: "center" }}>
                                                        <Form.Item
                                                            label="一般日"
                                                            name="normal"
                                                            rules={[{ required: true, message: '請選擇一般日之營業時間！' }]}
                                                        >
                                                            <TimePicker.RangePicker format={"HH:mm"} disabled={checkboxStatus["normal"]} />
                                                        </Form.Item>
                                                        <Checkbox style={{ marginLeft: 8 }} onChange={(e) => checkOperatingHour(e.target.checked, "normal")} >不營業</Checkbox>
                                                    </div>
                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                        <Form.Item
                                                            label="週末"
                                                            name="weekend"
                                                            rules={[{ required: true, message: '請選擇週末之營業時間！' }]}
                                                        >
                                                            <TimePicker.RangePicker format={"HH:mm"} disabled={checkboxStatus["weekend"]} />
                                                        </Form.Item>
                                                        <Checkbox style={{ marginLeft: 8 }} onChange={(e) => checkOperatingHour(e.target.checked, "weekend")}>不營業</Checkbox>
                                                    </div>
                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                        <Form.Item
                                                            label="假日"
                                                            name="holiday"
                                                            rules={[{ required: true, message: '請選擇假日之營業時間！' }]}
                                                        >
                                                            <TimePicker.RangePicker format={"HH:mm"} disabled={checkboxStatus["holiday"]} />
                                                        </Form.Item>
                                                        <Checkbox style={{ marginLeft: 8 }} onChange={(e) => checkOperatingHour(e.target.checked, "holiday")}>不營業</Checkbox>
                                                    </div>
                                                </div>
                                                {/* <div style={{ display: "flex" }}>
                                                    <Form.Item
                                                        label="營業時間（一般）"
                                                        name="normal"
                                                        rules={[{ required: true, message: '請選擇業時間（一般）！' }]}

                                                    >
                                                        <TimePicker.RangePicker format={format} style={{ width: "304px" }} />
                                                    </Form.Item>
                                                    <Form.Item
                                                        label="營業時間（週末）"
                                                        name="weekend"
                                                        style={{ margin: '0 16px' }}
                                                        rules={[{ required: true, message: '請選擇業時間（週末）！' }]}
                                                    >
                                                        <TimePicker.RangePicker format={format} style={{ width: "304px" }} />

                                                    </Form.Item>
                                                    <Form.Item
                                                        label="營業時間（假日）"
                                                        name="holiday"
                                                        style={{ margin: '0 16px' }}
                                                        rules={[{ required: true, message: '請選擇業時間（假日）！' }]}
                                                    >
                                                        <TimePicker.RangePicker format={format} style={{ width: "304px" }} />
                                                    </Form.Item>
                                                </div> */}
                                            </>) : (
                                                <>
                                                    {
                                                        successSubmit && (<>
                                                            <div style={{ fontSize: 30 }}>
                                                                <span style={{ color: "#1890FF", fontSize: 30 }}> #{successSubmit.id} / {successSubmit.name} </span> 已新增</div>
                                                            <div style={{ color: "rgba(0, 0, 0, 0.45)", marginTop: 8 }}>資訊已建立完成，現在客戶上單時可以選擇該運送點作為起訖點</div>
                                                            <TextArea
                                                                placeholder="額外的運送點內容說明請寫在此......"
                                                                autoSize={{ minRows: 2, maxRows: 4 }}
                                                                style={{ width: 395, marginTop: 8 }}
                                                                onChange={(e) => setRemark(e.target.value)}
                                                                value={remark}
                                                            />
                                                            <Button
                                                                style={{ display: "block", marginTop: 8 }}
                                                                disabled={remark !== "" ? "" : "disabled"}
                                                                onClick={() => {
                                                                    let updated_uid = '00001'
                                                                    let param = `row_id=${successSubmit.row_id}&note=${remark}&update_uid=${updated_uid}`
                                                                    UpdateCommonStationRemark(param).then(data => {
                                                                        if (data.Result.Success) {
                                                                            setRemark("")
                                                                            form.current.resetFields()
                                                                            message.success('已新增備註')
                                                                        }
                                                                    })
                                                                }}
                                                            >新增備註</Button>
                                                            <div style={{ marginTop: 32 }}>
                                                                <Button
                                                                    type="primary"
                                                                    icon={<PlusOutlined />}
                                                                    onClick={() => setCurrentStep(0)}
                                                                >新增其他共用運送點</Button>
                                                                <Button
                                                                    icon={<RightOutlined />}
                                                                    style={{ margin: "0 8px" }}
                                                                    onClick={() => navigate(`/customer/servicePoints/${successSubmit.row_id}`)}
                                                                >
                                                                    運送點內容
                                                                </Button>
                                                                <Button
                                                                    icon={<UnorderedListOutlined />}
                                                                    onClick={() => navigate("/customer/servicePoints")}
                                                                >回列表</Button>
                                                            </div>
                                                        </>)
                                                    }
                                                </>)
                                        }
                                    </div>
                                </div>
                                <div style={{ background: "white", marginTop: 16, width: "100%", padding: "20px", display: "flex", justifyContent: "flex-end" }}>
                                    <Popconfirm
                                        style={{ width: 410 }}
                                        placement="top"
                                        title={
                                            <div>
                                                <div>
                                                    <strong>新增還沒完成喔</strong>
                                                </div>
                                                <div style={{ fontSize: 14 }}>
                                                    剛剛輸入的所有資訊將不被保存。
                                                </div>
                                            </div>
                                        }
                                        onCancel={() => navigate(-1)}
                                        okText="不，我要留下"
                                        cancelText="對，我要離開"
                                    >
                                        <Button type="text" style={{ marginRight: 24 }} >
                                            關閉
                                        </Button>
                                    </Popconfirm>

                                    <Button type="primary" icon={<PlusOutlined />}
                                        htmlType="submit"
                                    > 新增 </Button>
                                </div>
                            </Form>) : (<div style={{ margin: '0 24px' }}>
                                <LoadingBlock />
                            </div>)
                    }
                </div>
            </div>
        </div>
    )
}


export default ServicePointsAdd
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Link, useLocation } from 'react-router-dom'
import { BellOutlined, UserOutlined, DashboardFilled, CarFilled, CrownFilled, CreditCardFilled, DatabaseFilled, EyeFilled, FileAddFilled, MenuUnfoldOutlined, MenuFoldOutlined, ContactsFilled,MoneyCollectOutlined } from '@ant-design/icons';
import { Menu, Layout, Badge, Button, Dropdown } from 'antd'
import { GetNotificationList } from '../../api/Notification';
import { useDispatch, useSelector } from "react-redux";
import { notifyChanges,unreadNotification } from '../../actions/notification';
import { addCase } from '../../actions/cases';
const { Sider } = Layout

const { SubMenu } = Menu;
const HeaderNav = styled.div`
  box-sizing: border-box;
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left:  16px;
  border-bottom: 1px solid var(--border_color);
  position: fixed;
  width: 100%;
  z-index: 10;
  top: 0;
  background: white;
`;
const SideNav = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 48px;
  background: white;
  height: calc(100% - 48px);
  width: ${props => !props.open ? "208px" : "60px"};
  box-shadow: 0px 2px 6px 0px #00000026;
`;


const Header = ({ history, show, setContainer }) => {
  const { pathname } = useLocation();
  const [Notification, setNotification] = useState(null)
  let path, subPathname
  const dispatch = useDispatch()
  const [currentPage, setCurrentPage] = useState("")
  const [subCurrentPage, setSubCurrentPage] = useState(subPathname)
  const [targetBlank, setTargetBlank] = useState(null)
  const [collapsed, setCollapsed] = useState(false)
  const UserInfo = useSelector(state => state.UserInfo)
  const UserPermission = UserInfo?UserInfo.permission :null
  const unRead = useSelector(state => state.UnreadNotification)
  const [MenuItems, setMenuItems] = useState([]) 
  useEffect(()=>{
    if(UserInfo){
      let items = [
        { id: 'cases', icon: <DatabaseFilled />, title: '案件列表', link: 'cases' },
        { id: 'execution', icon: <DashboardFilled />, title: '執行列表', link: 'execution' },
        { id: 'points', icon: <CarFilled />, title: '據點管理', link: 'points' },
        {
          id: 'customer', icon: <CrownFilled />, title: '客戶管理', submenu: [
            { id: 'lists', icon: "", title: '客戶清單', link: 'customer/lists' },
            { id: 'servicePoints', icon: "", title: '共用運送點', link: 'customer/servicePoints' },
            { id: 'promotions', icon: "", title: '行銷活動', link: 'customer/promotions' },
            { id: 'advertisement', icon: "", title: '廣告版位', link: 'customer/advertisement' },
          ]
        },
        {
          id: 'fuel', icon: <CreditCardFilled />, title: '加油管理', submenu: [
            { id: 'search', icon: "", title: '通路查詢', link: 'fuel/search' },
            { id: 'evaluation', icon: "", title: '即時油價及預估', link: 'fuel/evaluation' },
            { id: 'record', icon: "", title: '加油紀錄', link: 'fuel/record' },
          ]
        },
        {
          id: 'monitor', icon: <EyeFilled />, title: '即時監控', link: 'monitor',
          submenu: [
            { id: 'dashboard', icon: "", title: ' 全台即時監控', link: `${process.env.REACT_APP__Monitor_href}dashboard`, target: '_blank' },
            { id: 'driver', icon: "", title: '生理狀態監控', link: `${process.env.REACT_APP__Monitor_href}driver`, target: '_blank' },
            { id: 'fuels', icon: "", title: '加油油耗管理', link: `${process.env.REACT_APP__Monitor_href}fuel`, target: '_blank' },
            // { id: 'cars', icon: "", title: '車輛管理績效', link: `${process.env.REACT_APP__Monitor_href}cars`, target: '_blank' },
            { id: 'delivery', icon: "", title: '運送績效管理', link: `${process.env.REACT_APP__Monitor_href}delivery`, target: '_blank' }
          ]
        },
        { id: 'statement', icon: <FileAddFilled />, title: '請款報表', link: 'statement' },
        {
          id: 'privilege', icon: <ContactsFilled />, title: '權限管理',
          submenu: [
            { id: 'account', icon: "", title: '帳號管理', link: 'privilege/account' },
            { id: 'tags', icon: "", title: '權限標籤管理', link: 'privilege/tags' }
          ]
        },
      
    
      ];
      if(UserInfo.company_id ==="aa001"){
        items.push(  { id: 'rent', icon: <MoneyCollectOutlined />, title: '租賃管理', link: 'rent' },)
        
      }
      setMenuItems(items)
      
    }
  },[UserInfo])
  useEffect(() => {
    if (pathname.indexOf("/") > -1) {
      let page_arr = pathname.split("/")
      if (page_arr[1] === "fuel" || page_arr[1] === "customer" || page_arr[1] === "privilege") {
        subPathname = [page_arr[1]]
        path = page_arr[2]
      } else if (page_arr[1] === "monitor") {

      } else {
        path = page_arr[1]
        subPathname = []
      }
    }
    if (path === "") {
      path = "cases"
    }
    setCurrentPage(path)
    if(pathname!=="/cases/add"){
      dispatch(addCase([ { key: 1, no: 1, mobility: "1"}],null))
    }
  }, [pathname])

  useEffect(() => {
    let subPathname = "";

    if (pathname.indexOf("/") > -1) {
      let page_arr = pathname.split("/")
      if (page_arr[1] === "fuel" || page_arr[1] === "customer" || page_arr[1] === "privilege") {
        subPathname = [page_arr[1]]
      } else {
        subPathname = []
      }
    }
  
    setSubCurrentPage(subPathname)
  }, [currentPage])

  useEffect(() => {
    if (targetBlank) {
      let new_targetBlank = [...targetBlank]
      setSubCurrentPage([new_targetBlank[0]])
      setCurrentPage(new_targetBlank[1])
    }
  }, [targetBlank])
  useEffect(()=>{
    setNotification(unRead)
  },[unRead])
  useEffect(() => {
    if (!Notification) {
      GetNotificationList(`pageSize=10&pageIndex=0&status=0`).then(NotifyRes => {
        if (NotifyRes&&!NotifyRes.Error) {
        // console.log(NotifyRes.Result)

          // let unread = NotifyRes.Result.Data.filter(d=>!d.is_read)
          dispatch(notifyChanges(NotifyRes.Result))
          dispatch(unreadNotification(NotifyRes.Result.RowsCount))
        }
      })
    } else {
      let GetNotification = setInterval(() => {
        GetNotificationList(`pageSize=10&pageIndex=0`).then(NotifyRes => {
          if (!NotifyRes.Error) {
            // let unread = NotifyRes.Result.filter(d=>!d.is_read)
            dispatch(notifyChanges(NotifyRes.Result))
            dispatch(unreadNotification(NotifyRes.Result.RowsCount))
          }
        })
      }, 300000)
      return () => {
        clearInterval(GetNotification);
      }
    }
  }, [])

  if (show) {
    return (
      <div>
        <Layout>
          <HeaderNav>
            <figure >
              <img src={`${process.env.PUBLIC_URL}/assets/images/logo.png`} />
            </figure>
            <ul style={{ display: "flex" }}>
              <div style={{ padding: "16px 0" }}>
                <Link style={{ color: "rgba(0,0,0,0.8)" }} to="/notifications"><BellOutlined /></Link>
                <Badge count={Notification} size="small" style={{ position: "relative", top: -8, left: -2 }}>
                </Badge>
              </div>
              <Dropdown
                overlay={(
                  <Menu style={{ right: 12 }}
                    onClick={(value, index) => { }}>
                    <Menu.Item key="管理帳號資料">
                      <Link to='/user/info'>管理帳號資料</Link>
                      {/* <div 
                    onClick={()=>{}}
                    style={{ cursor: "pointer" }}>管理帳號資料</div> */}

                    </Menu.Item>
                    <Menu.Item key="登出" onClick={() => {
                      localStorage.removeItem("User")
                      window.location.reload()
                    }}>
                      <div>登出</div>
                    </Menu.Item>
                  </Menu>
                )}
                placement="bottomLeft" arrow >
                <Button
                  type="text"
                  style={{ paddingRight: 24 }}
                >  <UserOutlined />
                  <span style={{ paddingLeft: "8px" }}>{UserInfo?UserInfo.name:''}</span>
                </Button>
              </Dropdown>
            </ul>
          </HeaderNav>
          <Layout>
            <Sider width={208} className="site-layout-background">
              <SideNav open={collapsed}>
                <Menu
                  onClick={(e) => {
                    setCurrentPage(e.key)
                  }}
                  style={{ margin: 0 }}
                  defaultSelectedKeys={[currentPage]}
                  defaultOpenKeys={subCurrentPage}
                  selectedKeys={[currentPage]}
                  mode="inline"
                  inlineCollapsed={collapsed}
                  openKeys={subCurrentPage}
                  onOpenChange={keys => setSubCurrentPage(keys)}
                // onOpenChange ={MenuProps['onOpenChange'] = keys => {
                //     console.log(keys)
                //     // const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1);
                //     // if (rootSubmenuKeys.indexOf(latestOpenKey!) === -1) {
                //     //   setOpenKeys(keys);
                //     // } else {
                //     //   setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
                //     // }
                //   }}
                >
                  {
                    MenuItems.map(item => {
                      let subMenu = item.submenu;
                      if (UserPermission && UserPermission.route[item.id] !== 3) {
                        if (subMenu) {
                          return (
                            <SubMenu key={item.id} icon={item.icon} title={item.title} >
                              {
                                subMenu.map(s => (<Menu.Item key={s.id} >
                                  {
                                    s.target ? (<a
                                      href={s.link}
                                      target={s.target}
                                      onClick={(e) => {
                                        setTargetBlank(['monitor', s.id])
                                      }}
                                    >
                                      {s.title}</a>) : (<Link to={s.link} onClick={() => setTargetBlank(null)} >{s.title}</Link>)
                                  }
                                </Menu.Item>))
                              }
                            </SubMenu>
                          )
                        } else {
                          return (

                            <Menu.Item key={item.id} icon={item.icon} style={{ margin: 0 }} >
                              <Link to={item.link}>{item.title} </Link>
                            </Menu.Item>
                          );
                        }
                      } else {
                        return (<></>)
                      }
                    })
                  }

                </Menu>
                <div
                  style={{ margin: 0, position: "absolute", left: 0, bottom: 0, zIndex: 1000000, padding: "16px 24px", borderTop: "1px solid rgba(240, 240, 240, 1)", width: !collapsed ? "208px" : "60px", cursor: "pointer" }}
                  onClick={() => {
                    setCollapsed(!collapsed)
                    setContainer(!collapsed)
                  }}
                >   {
                    !collapsed ? (<MenuFoldOutlined />) : (<MenuUnfoldOutlined />)
                  }
                </div>
              </SideNav>
            </Sider>
          </Layout>
        </Layout>
        {/* <HeaderNav>
                    <figure >
                        <img src='http://fonghs.emct.tw/tms/images/logo.png' />
                    </figure>
                    <ul style={{display: "flex"}}>
                        <ListContainer><BellOutlined /></ListContainer>
                        <ListContainer><UserOutlined /><span style={{paddingLeft: "8px"}}>林大花</span></ListContainer>
                    </ul>
                </HeaderNav>
                <SideNav open={collapsed}>
                <Menu
                  onClick={(e)=> {
                    setCurrentPage(e.key)
                    }} 
                  style={{margin: 0 }}
                  defaultSelectedKeys={[currentPage]}
                  defaultOpenKeys={subPathname !=="" ?[subCurrentPage]: []}
                  selectedKeys={[currentPage]}
                  mode="inline"
                  inlineCollapsed={collapsed}
                >
                  {
                    MenuItems.map(item => {
                      let subMenu = item.submenu;
                       if(subMenu){
                          return(
                            <SubMenu key={item.id} icon={item.icon} title={item.title} >
                            {
                              subMenu.map(s =>(<Menu.Item key={s.id} >
                                <Link to={s.link}>{s.title}</Link>
                              </Menu.Item>))
                            }
                            </SubMenu>
                          )
                        }else{
                          return (
                        
                            <Menu.Item key={item.id} icon={item.icon} style={{margin: 0 }} >
                              <Link to={item.link}>{item.title} </Link>
                            </Menu.Item>
                      );
                        }
                    })
                  }
                
                  </Menu>
                  <div
                  style={{margin: 0, position: "absolute", left: 0, bottom:0, zIndex: 1000000, padding: "16px 24px", borderTop: "1px solid rgba(240, 240, 240, 1)", width: !collapsed? "208px":"80px", cursor: "pointer" }} 
                  onClick={()=> {
                    setCollapsed(!collapsed)
                    setContainer(!collapsed)
                    }}
                  >   {
                    !collapsed ? (<MenuFoldOutlined />   ) :(<MenuUnfoldOutlined /> )
                  }
                     
                  </div>
                </SideNav> */}
      </div>
    );
  } else {
    return <div></div>
  }
}


export default Header;


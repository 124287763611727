import React, { useState, useEffect, useRef } from 'react';
import BreadCrumb from '../../components/shared/BreadCrumb'
import { FilterOutlined, CloseOutlined } from '@ant-design/icons'
import { PageHeader, Button, Tabs, Badge, Table, Drawer, Select, Form, Input, Space, DatePicker,AutoComplete } from 'antd'
import { useNavigate, useLocation } from 'react-router-dom'
import moment from 'moment'
import { GetExecutionLists } from '../../api/Execution'
import { GetServicePointList } from '../../api/ServicePoint'
import { ExecutedStatusObj } from '../../util/executeStatus';
const { TabPane } = Tabs
const { Option } = Select
const { RangePicker } = DatePicker

const customWeekStartEndFormat = value => {
    let start = `${moment(value).year() - 1911}/${moment(value).format("MM")}`
    let end = `${moment(value).add(1, "month").year() - 1911}/${moment(value).add(1, "month").format("MM")}`
    return (`${start} ~ ${end}`)
}

const Index = ({ pageTitle, ContainerWidth }) => {
    let navigate = useNavigate();
    const { state } = useLocation()

    const [filterVisible, setFilterVisible] = useState(false)
    const [FetchAllData, setFetchAllData] = useState([])
    const [loading, setLoading] = useState(true)
    const [filterObjectLabel, setFilterObjectLabel] = useState(null)
    const submitRef = useRef()
    const [servicePoint, setServicePoint] = useState([])
    const [allServicePoint, setAllServicePoint] = useState([])
    const [selectedDate, setSelectedDate] = useState(moment().format("YYYY-MM-DD"))
    const [tabs, setTabs] = useState([
        { id: 'exe_all_count', name: '全部', count: null, status: "0" },
        { id: 'exe_map_check_count', name: '媒合確認', count: null, status: "7" },
        { id: 'exe_wait_take_count', name: '待接案', count: null, status: "6" },
        { id: 'exe_wait_count', name: '待執行', count: null, status: "1" },
        { id: 'exe_run_count', name: '執行中', count: null, status: "2" },
        { id: 'exe_finish_count', name: '完成', count: null, status: "3" },
        { id: 'exe_cancel_count', name: '取消', count: null, status: "4" },
        { id: 'exe_error_count', name: '異況確認', count: null, status: "5" },
    ])
    const [dates, setDates] = useState(null);
    const [filterData, setFilterData] = useState(null)
    const filterName = {
        execution_id: "執行編號",
        service_name: "服務據點",
        driver_name: "執行技師",
        trailer_name: "載運車",
    }

    const GetExecutionList = async () => {
        setLoading(true)
        setFetchAllData(null)
        let param = ''
        Object.keys(filterData).forEach((key, i) => {
            param += i === 0 ? `${key}=${filterData[key]}` : `&${key}=${filterData[key]}`
        })
        let res = await GetExecutionLists(param)
        if (!res.Error) {
            let data = res.Result
            let reform = tabs.map(t => {
                t.count = data[t.id]
                return t
            })
            setTabs(reform)
            setFetchAllData(data)
            setLoading(false)
        }
    }
    const disabledDate = (current, i) => {
        if (!dates) {
            return false;
        }
        let year = null, curMonth = null
        if (dates[0]) {
            curMonth = parseInt(dates[0].format("M")) + 1 === 13 ? 1 : parseInt(dates[0].format("M")) + 1
            year = curMonth === 1 ? parseInt(dates[0].format("YYYY")) + 1 : dates[0].format("YYYY")
        } else if (dates[1]) {
            curMonth = parseInt(dates[1].format("M")) - 1 === -2 ? 11 : parseInt(dates[1].format("M")) - 1 === -1 ? 12 : parseInt(dates[1].format("M")) - 2
            year = curMonth === 12 ? parseInt(dates[1].format("YYYY")) - 1 : dates[1].format("YYYY")
        }
        let lastday = moment(`${year}-${curMonth}-01`).endOf('month').format("D")
        let date = `${year}-${curMonth}-${lastday}`
        let tooLate, tooEarly
        if (dates[0]) {
            tooLate = dates[0] && moment(date).diff(current, 'day') < 0;
        } else {
            tooLate = moment(date).diff(current, 'day') >= 0
        }
        return !!tooEarly || !!tooLate;
    };
    const onChangeFilter = (obj) => {
        let filter = { ...filterData }
        Object.keys(obj).forEach(key => {
            filter[key] = obj[key]

        })
        setFilterData(filter)
    }
    useEffect(() => {
        document.title = pageTitle
        GetServicePointList('page_size=-1&page_index=0').then(point => {
            if (point && !point.Error) {
                setServicePoint(point.Result.Data)
                setAllServicePoint(point.Result.Data)
            }
        })
    }, [])
    useEffect(() => {
        if (state) {
            setSelectedDate(state.start_time)
            onChangeFilter(state)
        }else{
            let end = moment().add(1, "month").endOf('month').format("D")
            onChangeFilter({
                page_index:0,
                execution_tag:"0",
                page_size:10,
                start_time: moment().format("YYYY-MM-01"),
                end_time: moment().add(1, "month").format(`YYYY-MM-${end}`)
            })
        }
    }, [state])

    useEffect(() => {
        if(filterData) GetExecutionList()
    }, [filterData])

    const columns = [
        { title: '執行編號', dataIndex: 'id', },
        { title: '服務據點', dataIndex: 'service_point' },
        { title: '執行技師', dataIndex: 'driver' },
        { title: '載運車', dataIndex: 'trailer', width: '15%', },
        { title: '運送起點', dataIndex: 'origin' },
        { title: '運送訖點', dataIndex: 'destination' },
        {
            title: '執行狀態', dataIndex: 'status',
            render: status => (<div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ width: 6, height: 6, borderRadius: "50%", background: ExecutedStatusObj(parseInt(status)).background, marginRight: 8 }}></div>
                <p>{ExecutedStatusObj(parseInt(status)).text}</p>
            </div>)
        }
    ]
    return (
        <div className="container" style={ContainerWidth}>
            {
                filterData && (
                    <PageHeader
                        style={{ background: "white", width: "100%" }}
                        title="執行列表"
                        className="site-page-header"
                        tags={[
                            <Space direction="vertical" size={12}>
                                <DatePicker
                                    defaultValue={moment(selectedDate)}
                                    value={moment(selectedDate)}
                                    format={customWeekStartEndFormat}
                                    allowClear={false}
                                    onChange={(value) => {
                                        let selected = value.format("YYYY-MM-DD")
                                        setSelectedDate(selected)
                                        let obj = { ...filterData }
                                        let end = moment(selected).add(1, "month").endOf('month').format("D")
                                        obj.start_time = value.format("YYYY-MM-01")
                                        obj.end_time = value.add(1, "month").format(`YYYY-MM-${end}`)
                                        setFilterData(obj)
                                    }}
                                    picker="month"
                                />
                            </Space>
                        ]}
                        extra={[
                            <Button
                                icon={<FilterOutlined />}
                                onClick={() => {
                                    setFilterVisible(true)
                                    setTimeout(() => {
                                        submitRef.current.resetFields()
                                        submitRef.current.setFieldsValue({ selectedDate: [moment(filterData.start_time), moment(filterData.end_time)], ...filterData })
                                        console.log(filterData)
                                        if(!filterData.service_name) setServicePoint(allServicePoint)
                                    }, 100)
                                }}
                            >篩選</Button>
                        ]}
                        breadcrumb={<BreadCrumb />}
                    >
                    </PageHeader>
                )
            }
            {
                filterObjectLabel && (
                    <div className='filter_tabs_block' style={{ padding: 24, paddingBottom: 0 }}>
                        {
                            Object.keys(filterObjectLabel).map(label => (<p className='tab' onClick={() => {
                                let filter = { ...filterData }, filterLabel = { ...filterObjectLabel }
                                delete filter[label]
                                delete filterLabel[label]
                                setFilterData(filter)
                                setFilterObjectLabel(filterLabel)
                                
                            }}>
                                {filterName[label]}：{filterObjectLabel[label]}
                                <CloseOutlined style={{ color: "rgba(0, 0, 0, 0.45)", fontSize: 14, paddingLeft: 4 }} />
                            </p>))
                        }
                    </div>
                )
            }
            <div className='table_block'>
                <Tabs>
                    {
                        filterData && tabs.map(t => (
                            <TabPane key={t.id}
                                tab={
                                    <div onClick={(e) => onChangeFilter({ execution_tag: t.status })}>
                                        <span>{t.name}</span>
                                        <Badge
                                            className="site-badge-count-109"
                                            count={t.count >= 999 ? `999+` : t.count}
                                            style={{ backgroundColor: filterData.execution_tag === t.id ? "#E6F7FF" : "#F0F0F0", color: filterData.execution_tag === t.id ? "#1890FF" : "rgba(0,0,0,0.45)", marginLeft: "4px" }}
                                        />
                                    </div>
                                } />
                        ))
                    }
                </Tabs>
                <Table
                    columns={columns}
                    rowKey={record => record.row_id}
                    dataSource={FetchAllData?.Data}
                    pagination={FetchAllData && FetchAllData.PageCount > 1 ? {
                        current: filterData.page_index + 1,
                        total: FetchAllData.RowsCount,
                        showTotal: (total, range) =>
                            `${range[0]}-${range[1]} 列（共${total}  列）`,
                    } : false}
                    loading={loading}
                    style={{ padding: "12px 0", minHeight: 400 }}
                    onRow={(record, rowIndex) => {
                        return {
                            onClick: event => { navigate(`/execution/${record.row_id}`, { state: { ...filterData } }) },
                        };
                    }}
                    onChange={(pagination) => onChangeFilter({ page_index: pagination.current - 1 })}
                />
                {/* 篩選視窗 */}
                <Drawer
                    title="篩選"
                    placement="right"
                    closable={false}
                    extra={
                        (<CloseOutlined
                            style={{ cursor: "pointer", color: "rgba(0, 0, 0, 0.45)" }}
                            onClick={() => setFilterVisible(false)}
                        />)
                    }
                    footer={
                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                            <Button type="text" style={{ marginRight: 16 }} onClick={() => setFilterVisible(false)} >取消</Button>
                            <Button type="primary"
                                htmlType="submit"
                                onClick={() => {
                                    submitRef.current.submit();
                                    // setSubmitForm(true)
                                }}
                            >篩選</Button>
                        </div>
                    }
                    visible={filterVisible} >
                    <Form
                        ref={submitRef}
                        name="basic"
                        // initialValues={{ selectedDate: moment(selectedDate) }}
                        layout="vertical"
                        onFinish={(value) => {
                            let filter = { ...filterData }
                            let labelObj = {}
                            Object.keys(value).forEach((key, i) => {
                                if (value[key]) {
                                    if (key === 'selectedDate') {
                                        filter.start_time = value[key][0].format("YYYY-MM-DD")
                                        filter.end_time = value[key][1].format("YYYY-MM-DD")
                                        setSelectedDate(value[key][0])
                                    } else {
                                        filter[key] = value[key]
                                        labelObj[key] = [value[key]]
                                    }
                                    // else if (key === 'service_point') {
                                    //     filter.service_id = value[key].split(",")[0]
                                    //     labelObj[key] = [value[key].split(",")[1]]
                                    // }
                                }
                            })
                            setFilterData(filter)
                            setFilterVisible(false)
                            setFilterObjectLabel(labelObj)
                        }}
                        autoComplete="off"
                    >
                        <Form.Item
                            label="日期"
                            name="selectedDate"
                        >
                            <RangePicker
                                style={{ width: "100%" }}
                                disabledDate={disabledDate}
                                onCalendarChange={(val) => setDates(val)}
                            // onOpenChange={onOpenChange}
                            />
                        </Form.Item>
                        <Form.Item label="執行編號" name="execution_id">
                            <Input placeholder='請輸入' />
                        </Form.Item>

                        <Form.Item
                            label="服務據點"
                            name="service_name"
                        >
                        <AutoComplete
                            options={(servicePoint || []).map(d => ({
                                value: d.name,
                                label: d.name,
                            }))}
                            onSearch={(searchText)=>{
                                let search = searchText? [...allServicePoint].filter(d=> d.name.toLowerCase().indexOf(searchText.toLowerCase())>-1):[...allServicePoint]
                                setServicePoint(search)
                            }}
                            allowClear
                            placeholder='請選擇'
                        />
                            {/* <Select
                                allowClear
                                showSearch
                                placeholder="請選擇"
                                onChange={(val) => { }}
                            >
                                {
                                    servicePoint.map(o => (<Option
                                        key={`${o.row_id}`}
                                        value={`${o.id},${o.name}`}
                                    >{`${o.name}`}</Option>))
                                }
                            </Select> */}
                        </Form.Item>

                        <Form.Item
                            label="執行技師"
                            name="driver_name"
                        >
                            <Input placeholder='請輸入' />
                        </Form.Item>
                        <Form.Item
                            label="載運車"
                            name="trailer_name"
                        >
                            <Input placeholder='請輸入' />
                        </Form.Item>
                    </Form>
                </Drawer>
            </div>
        </div>
    );
}

export default Index;

import React, { useState} from 'react';
import { Form, Input, Radio, Button,Upload } from 'antd'
import {UploadOutlined} from '@ant-design/icons'

// const { TextArea } = Input;
const Expandrow = ({record,setRecord}) => {
    // const fileInput = useRef(null);
    // const [uploadFile, setUploadFile] = useState([]);
    const [currentState, setCurrentState] = useState(record.recordMethod);
    const fileList = record.uploadFile ? record.uploadFile : [];
    let reportMethod = `reportMethod${record.key}`
    let reportPhoto = `reportPhoto${record.key}`
    let description = `description${record.key}`
    return (
        <div>
          
                <Form.Item
                    label="回報方式"
                    name={reportMethod}
                    rules={[{ required: true, message: '請選擇回報方式' }]}
                    style={{display: "block", marginRight: '16px'}}
                >
                <Radio.Group buttonStyle="solid" onChange={(e)=>{
                    setCurrentState(e.target.value)
                    record.recordMethod = e.target.value
                    setRecord(record);
                }} >
                    <Radio.Button value="0">照片&簡述</Radio.Button>
                    <Radio.Button value="1">照片</Radio.Button>
                    <Radio.Button value="2">簡述</Radio.Button>
                </Radio.Group>
                </Form.Item>
                {
                    (currentState === "0" || currentState === "1")  ? (<Form.Item
                    label="車輛照片"
                    name={reportPhoto}
                    rules={[{ required: true, message: '請選擇車輛照片' }]}
                    style={{display: "block", marginRight: '16px'}}
                    >
            
                <Upload
                // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                listType="picture"
                defaultFileList={[...fileList]}
                className="upload-list-inline"
                accept="image/*"
                maxCount="4"
                customRequest ={({onSuccess}) => onSuccess("ok") }
                
                onChange={({ file, fileList })=>{
                    let files = [...fileList]
                    let removeFile = record.removeFile?[...record.removeFile]:[]
                    if(file.row_id){   
                         if(file.status==='removed') removeFile.push(file)

                    }
                    record.uploadFile = files;
                    record.removeFile = removeFile
                    setRecord(record);
                }}
                >
                <Button icon={<UploadOutlined />}>上傳</Button>
                </Upload>
               
                </Form.Item>): "" 
                }
                {
                    (currentState === "0" || currentState === "2") ?( <Form.Item
                        label="文字簡述"
                        name={description}
                        rules={[{ required: true, message: '請輸入文字簡述！' }]} 
                        style={{display: "block", marginRight: '16px'}}
                    >        
                    <Input.TextArea rows={4} maxLength={100} showCount style={{width: "400px"}} onChange={(e)=>{
                        record.description = e.target.value
                        setRecord(record);
                    }}/>
                </Form.Item>): ""
                }
            
        </div>
    );
}

export default Expandrow;

import React, { useState, useRef, useEffect } from 'react'
import { PageHeader, Form, Input, Button, Select, Modal, Table, DatePicker, Cascader, message } from 'antd'
import { EnterOutlined, PlusOutlined, RightOutlined, UnorderedListOutlined,ExclamationCircleOutlined } from '@ant-design/icons'
import { UserCheckAccount, AllPrivilegeTag,AddAccount, AddAccountNote } from '../../api/Member'
import { useNavigate } from 'react-router-dom'
import BreadCrumb from '../../components/shared/BreadCrumb'
const { TextArea } = Input
const { confirm } = Modal
const AccountAdd = ({ pageTitle, ContainerWidth }) => {
    const [currentStep, setCurrentStep] = useState(0)
    const form = useRef(), idRef = useRef()
    let navigate = useNavigate()
    const [successSubmit, setSuccessSubmit] = useState(null)
    const [remark, setRemark] = useState("")
    const [isValid, setIsValid] = useState(null)
    const [tagOption, setTagOption] = useState([])
    const showPromiseConfirm = () => {
        confirm({
          title: '此操作會遺失當前輸入的內容',
          icon: <ExclamationCircleOutlined />,
          content: '您將會中斷帳號新增的流程，前往新增權限標籤，當前已輸入的內容將不會被保存',
          onOk() { navigate("/privilege/tag/add")},
          onCancel() {},
          okText:'前往新增標籤',
          cancelText:'再想想'
        });
      };
    const onSubmitForm = async (val)=>{
        AddAccount(val).then(res=>{
            if(res && !res.Error){
                setSuccessSubmit({...res.Result,user_id:val.user_id})
                setCurrentStep(1)
            }
        })
    }
    useEffect(() => {
        AllPrivilegeTag().then(res => {
            if (res && !res.Error) {
                let result = res.Result
                    .map(r => {
                        r.value = r.tag_id
                        r.label = r.tag_name
                        return r
                    })
                result.unshift({ value: 'add', label: "+  新增權限標籤" })
                setTagOption(result)
            }
        })
    }, []);
    return (
        <div className="container" style={ContainerWidth}>
            <PageHeader
                title="帳號新增"
                onBack={() => currentStep === 0 ? window.history.go(-1) : setCurrentStep(currentStep - 1)}
                className="site-page-header"
                breadcrumb={<BreadCrumb />}
            >
            </PageHeader>
            <div className='step_block'>
                <div >
                    <Form
                        ref={form}
                        name="basic"
                        layout="vertical"
                        // initialValues={{ is_VIP: "0" }}
                        onFinish={onSubmitForm}
                        onFinishFailed={(value) => console.log(value)}
                    >
                        <div style={{ minHeight: "calc(100vh - 240px)" }}>
                            <div className='step_content'>
                                {
                                    currentStep === 0 ? (
                                        <div>
                                            <div style={{ display: "flex" }}>
                                                <Form.Item
                                                    label="帳號"
                                                    name="user_id"
                                                    rules={[{ required: true, message: '請輸入帳號!' }]}
                                                    style={{ position: 'relative' }}
                                                    hasFeedback={isValid !== null ? true : false}
                                                    validateStatus={isValid === null ? '' : isValid ? 'success' : 'error'}
                                                    extra={isValid === null || isValid ? "輸入完帳號後請點擊右側按鈕檢核。" : ""}
                                                >
                                                    <Input
                                                        ref={idRef}
                                                        placeholder='請輸入帳號'
                                                        onChange={(e) => {
                                                            setIsValid(null)
                                                            form.current.setFieldsValue({ user_id: e.target.value })
                                                            setTimeout(() => {
                                                                idRef.current.focus()
                                                            }, 200)
                                                        }}
                                                        addonAfter={<EnterOutlined style={{ cursor: "pointer" }} onClick={(e) => {
                                                            let account = form.current.getFieldValue().user_id
                                                            UserCheckAccount(account)
                                                                .then(res => {
                                                                    if (res.Result === "false") {
                                                                        setIsValid(true)
                                                                    } else {
                                                                        setIsValid(false)
                                                                    }
                                                                })
                                                        }} />} style={{ width: "304px" }} />
                                                    {
                                                        isValid !== null && !isValid && (<div style={{ color: '#ff4d4f', position: 'absolute' }}>該帳號已被使用，請變更後再次檢核。!</div>)
                                                    }
                                                </Form.Item>
                                                <Form.Item
                                                    label="密碼"
                                                    name="password"
                                                    rules={
                                                        [
                                                            { pattern: new RegExp(/^(?=.*?[A-Za-z])(?=.*?[0-9]).{0,10}$/), message: '密碼格式錯誤!' },
                                                            { required: true, message: '請輸入密碼!' }
                                                        ]}
                                                    style={{ marginLeft: 16 }}
                                                >
                                                    <Input
                                                        placeholder='請輸入 10 碼內英數字組合之密碼'
                                                        style={{ width: "304px" }}
                                                    />

                                                </Form.Item>
                                            </div>
                                            <div style={{ display: "flex" }}>
                                                <Form.Item
                                                    label="姓名"
                                                    name="user_name"
                                                    rules={
                                                        [
                                                            { required: true, message: '請輸入姓名!' }
                                                        ]}
                                                >
                                                    <Input
                                                        placeholder='請輸入姓名'
                                                        style={{ width: "304px" }}
                                                    />

                                                </Form.Item>

                                                <Form.Item
                                                    label="E-mail"
                                                    name="email"
                                                    rules={
                                                        [
                                                            { pattern: new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g), message: 'E-mail格式錯誤!' },
                                                            { required: true, message: '請輸入電子信箱!' }
                                                        ]}
                                                    style={{ marginLeft: 16 }}
                                                >
                                                    <Input
                                                        placeholder='請輸入電子信箱'
                                                        style={{ width: "304px" }}
                                                    />

                                                </Form.Item>
                                            </div>
                                            <Form.Item
                                                label="權限標籤"
                                                name="tag_id"
                                                rules={[{ required: true, message: '請選擇權限標籤 !' }]}
                                            >
                                                <Select
                                                    showSearch
                                                    style={{ width: 304 }}
                                                    placeholder="僅可單選"
                                                    optionFilterProp="children"
                                                    onChange={(val) => {
                                                        if (val === "add") {
                                                            form.current.setFieldsValue({ tag: '' })
                                                            showPromiseConfirm()
                                                        }
                                                    }}
                                                    filterOption={(input, option) => {
                                                        if (option.value !== "add") {
                                                            return (option?.label ?? '').includes(input)
                                                        } else {
                                                            return option
                                                        }
                                                    }}
                                                    filterSort={(optionA, optionB) =>
                                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                                    }
                                                    options={tagOption}
                                                />

                                            </Form.Item>
                                        </div>) : (<>
                                            {
                                                successSubmit && (<>
                                                    <div style={{ fontSize: 30 }}>
                                                        帳號 #{successSubmit.user_id} 已成立
                                                        {/* <span style={{ color: "#1890FF", fontSize: 30 }}> {successSubmit.id} / {successSubmit.name} </span> */}
                                                    </div>
                                                    <div style={{ color: "rgba(0, 0, 0, 0.45)", marginTop: 8 }}>該帳號已成立，即刻就可以使用該帳號密碼登入</div>
                                                    <TextArea
                                                        placeholder="額外的帳號說明請寫在此......"
                                                        autoSize={{ minRows: 2, maxRows: 4 }}
                                                        style={{ width: 395, marginTop: 8 }}
                                                        onChange={(e) => setRemark(e.target.value)}
                                                        value={remark}
                                                    />
                                                    <Button
                                                        style={{ display: "block", marginTop: 8 }}
                                                        disabled={remark !== "" ? "" : "disabled"}
                                                        onClick={() => {
                                                            let obj={
                                                                id:successSubmit.id,
                                                                note:remark
                                                            }
                                                            AddAccountNote(obj).then(res=>{
                                                                if(!res.Error){
                                                                    setRemark("")
                                                                    form.current.resetFields()
                                                                    message.success('已新增備註')
                                                                }
                                                            })
                                                        }}
                                                    > 新增備註 </Button>
                                                    <div style={{ marginTop: 32 }}>
                                                        <Button
                                                            type="primary"
                                                            icon={<PlusOutlined />}
                                                            onClick={() => setCurrentStep(0)}
                                                        >新增其他帳號</Button>
                                                        <Button
                                                            icon={<RightOutlined />}
                                                            style={{ margin: "0 8px" }}
                                                            onClick={() => navigate(`/privilege/account/${successSubmit.id}`)}
                                                        >帳號內容</Button>
                                                        <Button
                                                            icon={<UnorderedListOutlined />}
                                                            onClick={() => navigate("/privilege/account/")}
                                                        >回列表</Button>
                                                    </div>
                                                </>)
                                            }
                                        </>)
                                }
                            </div>
                        </div>
                        <div style={{ background: "white", marginTop: 16, width: "100%", padding: "20px", display: "flex", justifyContent: "flex-end" }}>
                            <Button type="text" style={{ marginRight: 24 }} >
                                關閉
                            </Button>
                            <Button type="primary" icon={<PlusOutlined />}
                                htmlType="submit"
                            >
                                新增
                            </Button>
                        </div>
                    </Form>

                </div>
            </div>
        </div>)

}


export default AccountAdd
import React from 'react'
import { Button } from "antd";
import { PhoneOutlined, RightOutlined } from "@ant-design/icons"
import { useNavigate } from 'react-router-dom';
import { CustomerPricingModuleDetail } from '../../api/Customer';
const ButtonType = ({ type, data, changeCurrenTab }) => {
    let navigate = useNavigate()
    let Type = 
    type.indexOf("出勤異況")>-1?"出勤異況":
    type.indexOf("逾時")>-1 || type==='指定時間案件提醒'?"逾時"
    :type
    const button = {
        "媒合結果": [{ type: '', text: "媒合結果", icon: <RightOutlined />, action: '1' }],
        "新案件需求": [{ type: 'primary', text: "審核", icon: <RightOutlined />, action: '2', key: "case_id", path:"cases" }],

        "逾時":
            [
                { type: 'primary', text: "打給技師", icon: <PhoneOutlined />, action: '3', sub: 'call', key: "driver_mobile_phone_number" },
                { type: '', text: "詳細內容", icon: <RightOutlined />, action: '3', sub: 'detail', key: 'execution_info_row_id',path:"execution" },
            ],
        "案件異況回報":
            [
                { type: 'primary', text: "打給技師", icon: <PhoneOutlined />, action: '4', sub: 'call', key: 'driver_mobile_phone_number' },
                { type: '', text: "執行內容", icon: '', action: '4', sub: 'execution', key: "execution_info_row_id",path:"execution" },
                { type: '', text: "案件內容", icon: '', action: '4', sub: 'case', key: "case_id",path:"cases" },
            ],
        "執行異況回報":
            [
                { type: 'primary', text: "打給技師", icon: <PhoneOutlined />, action: '5', sub: 'call', key: 'driver_mobile_phone_number' },
                { type: '', text: "執行內容", icon: '', action: '5', sub: 'execution', key: "execution_info_row_id", path:"execution" },
            ],
        "生理監控異常": [
            { type: 'primary', text: "打給技師", icon: <PhoneOutlined />, action: '6', sub: 'call', key: 'driver_mobile_phone_number' },
            { type: '', text: "打給緊急聯絡人", icon: <PhoneOutlined />, action: '6', sub: 'call', key: 'emergency_contact1_tel' },
            { type: '', text: "技師資料", icon: <RightOutlined />, action: '6', sub: 'detail', key: '' },
        ],
        "計價模組到期通知": [{ type: '', text: "詳細內容", icon: <RightOutlined />, action: '7', sub: 'detail', key: 'price_row_id',path:'' }],
        "據點合約到期通知": [{ type: '', text: "詳細內容", icon: <RightOutlined />, action: '8', sub: 'detail', key: 'service_point_row_id', path:'points' }],
        "出勤異況": [{ type: '', text: "查看名單", icon: <RightOutlined />, action: '9', key:'' }],
        "請款提醒": [
            { type: 'primary', text: "請款報表", icon: '', action: '10' },
            { type: '', text: "合約內容", icon: '', action: '10',key:"price_row_id" }
        ],
        "駕照效期通知": [
            { type: 'primary', text: "打給技師", icon: <PhoneOutlined />, action: '11', sub: 'call', key: 'driver_mobile_phone_number' },
            { type: '', text: "技師資訊", icon: <RightOutlined />, action: '11', sub: 'detail', key: 'driver_row_id' , path:""},
        ],
        "效期通知": [{ type: '', text: "車輛資料", icon: <RightOutlined />, action: '12' }],
        "驗車通知": [{ type: '', text: "車輛資料", icon: <RightOutlined />, action: '13' }],
        "現場加車通知": [
            { type: '', text: "執行內容", icon: '', action: '14', sub: 'execution',path:"execution",key:'execution_info_row_id' },
            { type: '', text: "案件內容", icon: '', action: '14', sub: 'case', key:"case_id",path:"cases"  },
        ],
    }
    const buttonClick = async(b)=>{
        if(type === '媒合結果' || type.indexOf('出勤異況') > -1 ){
            changeCurrenTab(type === '媒合結果' ?"2a":"2b",{...data,type})
        }else{
            let state={}
            if(b.path ==='cases'){
                state.vehicle_sn = data['data']['vehicle_sn'] ? data['data']['vehicle_sn'] : 1
            }
            if(b.path ==='cases'){
                state.vehicle_sn = data['data']['vehicle_sn'] ? data['data']['vehicle_sn'] : 1
            }
            if (type === '計價模組到期通知' || type==='請款提醒') {

                if(data['data'][b.key]){
                    let res = await CustomerPricingModuleDetail(data['data'][b.key])
                    if (res && !res.Error) {
                        let Result = res.Result
                        state = {
                            BreadcrumbInfo: { [Result.customer_row_id]: [Result.customer_name] },
                            customerId: Result.customer_row_id,
                            customer_id: Result.customer_id,
                            customer_name: Result.customer_name,
                            id: data['data'][b.key],
                            tab:'4'
                        }
                        navigate(`/customer/lists/${Result.customer_row_id}/details`,{state})
                    }
                }else{
                    navigate(`/statement`)
                }

            }else if(type ==='駕照效期通知' || type==='生理監控異常' || type==='驗車通知' || type.indexOf('效期通知') > -1){
                state ={
                    point: type ==='駕照效期通知' || type==='生理監控異常' ?"2":"4",
                    // point:"point_name"
                }
            }else{
                navigate(`/${b.path}/${data['data'][b.key]}`,{state})
            }
            
        }
    
    }
    return (
        <div>
            <div style={{ display: "flex", alignItems: "center", gridGap: 8, marginTop: 32 }}>
                {
                    button[Type] && button[Type].map(b => (
                        <>
                        {
                            b.sub && b.sub === 'call' ? (
                                <a href={`tel:${data['data'][b.key]}`}>
                                    <Button
                                        type={b.type}
                                        icon={b.icon}
                                    >{b.text}</Button>
                                </a>
                            ) : (<Button
                                type={b.type}
                                icon={b.icon}
                                onClick={() => buttonClick(b)}
                            >{b.text}</Button>)
                        }
                        </>
                    ))
                }
            </div>
        </div>
    )
}

export default ButtonType
import React, { useEffect, useState, useRef } from 'react'
import { Button, Form, Input, Card, Drawer } from 'antd'
import { EditOutlined, CloseOutlined } from '@ant-design/icons'
import { GetUserInfo, PrivilegeTagDetail, EditUserInfo } from '../../api/Member'
import CustomCard from '../../components/shared/component/Card'
import { useDispatch } from 'react-redux'
import { fetchUserInfo } from '../../actions/iniFetch'
const Info = ({ ContainerWidth }) => {
  const [data, setData] = useState(null)
  const [editModal, setEditModal] = useState(false)
  const editForm = useRef()
  const dispatch = useDispatch()
  const fetchApi = async () => {
    let userInfo = await GetUserInfo()
    if (userInfo && !userInfo.Error) {
      let Result = userInfo.Result
      let tag = await PrivilegeTagDetail(Result.permission_tag_id)
      if (tag && !tag.Error) {
        let obj = { route: {}, notification: {} }
        let permission_list = tag.Result.permission_info.forEach(p => {
          let type = p.p_type === 1 ? 'route' : 'notification'
          obj[type][p.p_code] = p.p_status
        })
        Result.Status = {
          type: "status", color: Result.status === 1 ? "rgba(82, 196, 26, 1)" : "rgb(217, 217, 217)", status: Result.status, content: [Result.status === 1 ? '使用中' : "停用"]
        }
        dispatch(fetchUserInfo({ ...Result, permission: obj, permission_list }))
        setData(Result)
      }
    }
  }

  const submitForm = (val) => {
    let Data = {
      name: val.name,
      password: val.password_un,
      tag_id: data.permission_tag_id,
      note: data.note,
      email: val.mail,
    }
    EditUserInfo(Data).then(res=>{
      if(res && !res.Error){
        setEditModal(false)
        fetchApi()
      }
    })
  }
  useEffect(() => {
    fetchApi()
  }, []);
  return (
    <div className="container" style={ContainerWidth}>
      {
        data && (<div className='member_info_block' style={{ width: 684, margin: "24px auto" }}>
          <div className='title' style={{ fontSize: 38, marginBottom: 24 }}>帳號資料</div>
          <Card
            style={{ width: "100%", margin: "24px 0" }}
            title="個人資料"
            extra={
              <Button
                type="text"
                style={{ paddingRight: 0 }}
              onClick={()=> setEditModal(true)}
              > 修改
                <EditOutlined style={{ color: "rgba(24, 144, 255, 1)" }} />
              </Button>
            }
          >
            <CustomCard
              header={[
                { key: "account", name: "帳號" },
                { key: "password_un", name: "密碼" },
                { key: "name", name: "姓名" },
                { key: "mail", name: "E-mail" },
                { key: "permission_tag_name", name: "權限標籤" },
                { key: "Status", name: "帳號狀態" },
              ]} data={data} />
          </Card>
          {
            editModal && (
              <Drawer
                title="編輯 - 帳號資訊"
                placement="right"
                // size={"large"}
                closable={false}
                extra={
                  <CloseOutlined style={{ cursor: "pointer", color: "rgba(0, 0, 0, 0.45)" }} onClick={() => { setEditModal(false) }} />
                }
                footer={
                  (
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                      <Button type='text' onClick={() => setEditModal(false)}>取消</Button>
                      <Button
                        onClick={() => editForm.current.submit()}
                        style={{ marginLeft: 8 }} htmlType="submit">編輯</Button>
                    </div>
                  )
                }
                visible={true}>
                <Form
                  name="basic"
                  layout="vertical"
                  initialValues={data}
                  ref={editForm}
                  onFinish={submitForm}
                  // onFinishFailed={onFinishFailed}
                  autoComplete="off"
                  style={{ width: "100%" }}
                >
                  <Form.Item
                    label="姓名"
                    name="name"
                    rules={[{ required: true, message: '請輸入姓名!' }]}
                  >
                    <Input placeholder='example' style={{ width: "304px" }} />
                  </Form.Item>
                  <Form.Item
                    label="密碼"
                    name="password_un"
                    rules={[{ required: true, message: '請輸入密碼!' }]}
                  >
                    <Input placeholder='example' style={{ width: "304px" }} />
                  </Form.Item>
                  <Form.Item
                    label="E-mail"
                    name="mail"
                    rules={[{ required: true, message: '請輸入E-mail!' }]}
                  >
                    <Input placeholder='example@gmail.com' style={{ width: "304px" }} />
                  </Form.Item>
                </Form>
              </Drawer>
            )
          }
        </div>)
      }
    </div>
  )
}

export default Info
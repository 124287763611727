import React, { useState, useEffect, useRef, createRef } from 'react'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import BreadCrumb from '../../components/shared/BreadCrumb'
import { PageHeader, Tag, Button, Card, Drawer, Form, Input, Popconfirm, Radio, Modal, Table } from 'antd'
import { EditOutlined, EnterOutlined, PhoneOutlined, CloseOutlined, DownloadOutlined } from '@ant-design/icons'
import CustomCard from '../../components/shared/component/Card'
import LoadingBlock from '../../components/shared/LoadingBlock'
import CustomCascader from '../../components/shared/component/CustomCascader'
import HistoryRecord from '../../components/shared/component/HistoryRecord'
import { RouteName } from '../../util/route'
import { GetLeaseDetail, GetLeasePayHistory, CheckTax, EditLeaseDetail, EditLeasePlan, RentHistory, DisabledRent, EditPaymentReadStatus } from '../../api/Lease'
import { useSelector } from 'react-redux'
import { DownloadFile } from '../../util/download'

const Info = ({ ContainerWidth }) => {
    let { leaseId } = useParams()
    const link = createRef(), navigate=useNavigate()
    const { state, pathname } = useLocation()
    const routeId = RouteName(pathname)
    let UserInfo = useSelector(state => state.UserInfo)
    let UserPermission = UserInfo ? UserInfo.permission.route : null
    const editForm = useRef(), settingForm = useRef()
    const [modalStatus, setModalStatus] = useState(null)
    const [data, setData] = useState(null)
    const [history, setHistory] = useState(null)
    const [allPaymentRecord, setAllPaymentRecord] = useState(null)
    const [paymentRecord, setPaymentRecord] = useState(null)
    const [recordPage, setRecordPage] = useState(1)
    const [isValid, setIsValid] = useState(null)

    const settingOnSubmit = async (val) => {
        let obj = {
            company_base_row_id: data.company_base_row_id,
            pay_plan: parseInt(val.Plan),
            pay_frequency: parseInt(val.Frequency),
            pay_method: parseInt(val.Method),
        }
        let res = await EditLeasePlan(obj)
        if (res && !res.Error) {
            setModalStatus(null)
            fetchApi()
        }
    }
    const onClose = () => {
        if (modalStatus === "1") {
            // editForm.current.setFieldsValue()
        } else {
            settingForm.current.resetFields()
        }
        setModalStatus(null)
    }
    const fetchApi = async () => {
        let res = await GetLeaseDetail(leaseId)
        let record = await GetLeasePayHistory(leaseId)
        let resHistory = await RentHistory(leaseId)
        if (resHistory && !resHistory.Error) {
            let history = resHistory.Result.map(r => {
                r.items = r.action_content
                delete r.action_content
                return r
            })
            setHistory(history)
        }
        if (res && !res.Error) {
            let Result = res.Result
            Result.pay_method = Result.change_pay_method ? Result.change_pay_method : Result.pay_method
            Result.contact_person_phone_text = { type: "icons", link: 'tel', icon: <PhoneOutlined />, label: Result.contact_person_phone }
            Result.company_phone_text = { type: "icons", link: 'tel', icon: <PhoneOutlined />, label: Result.company_phone }
            Result.plan_period = `${Result.used_start_time.replaceAll("-", "/")} - ${Result.used_end_time.replaceAll("-", "/")}`
            Result.renew_date = `${Result.company_status ? `${Result.used_end_time.replaceAll("-", "/")}${Result.change_pay_plan && Result.change_pay_frequency ? `，將變更為${Result.change_pay_plan}（${Result.change_pay_frequency}）` : ''}` : `將於${Result.used_end_time.replaceAll("-", "/")}後停用`}`
            Result.plan = `${Result.pay_plan}（${Result.pay_frequency}）`
            Result.pay_plan_money = `每${Result.pay_frequency === "月付" ? "月" : "年"} ${Result.pay_plan_money}`
            Result.address_code = [Result.company_county, Result.company_town]
            Result.Method = Result.pay_method === '匯款' ? "1" : "2"
            Result.Frequency = Result.pay_frequency === '月付' ? "1" : "2"
            Result.Plan = Result.pay_plan === '標準版' ? "1" : "2"
            Result.c_industry = Result.company_industry.toString()
            if (record && !record.Error) {
                let Record = record.Result.map(r => {
                    r.plan = `${r.pay_plan} （${r.pay_frequency}）`
                    r.renew_date = r.to_renew_date.replaceAll("-", "/")
                    return r
                })
                setAllPaymentRecord(Record)
                let data_number = 10 * recordPage
                let number = Record.length > data_number ? data_number : Record.length
                // recordPage
                let PaymentRecord = Record.slice(0, number)
                setAllPaymentRecord(Record)
                setPaymentRecord(PaymentRecord)
            }
            setData(Result)
        }
    }
    const editOnFinish = async (val) => {
        let Data = {
            company_base_row_id: data.company_base_row_id,
            c_name: val.company_name,
            c_phone: val.company_phone,
            c_tax: val.company_tax_no,
            c_county: val.address_code[0],
            c_town: val.address_code[1],
            c_address: val.company_address,
            c_contact_person: val.contact_person,
            c_contact_person_phone: val.contact_person_phone,
            c_fax_num: val.company_fax_no,
            c_mail: val.company_mail,
            c_industry:val.c_industry
        }
        EditLeaseDetail(Data).then(res => {
            if (res && !res.Error) {
                fetchApi()
                setModalStatus(null)
            }
        })

    }
    useEffect(() => {
        if (allPaymentRecord) {
            let data_number = 10 * recordPage
            let number = allPaymentRecord.length > data_number ? data_number : allPaymentRecord.length
            // recordPage
            let PaymentRecord = allPaymentRecord.slice(0, number)
            setPaymentRecord(PaymentRecord)
        }
    }, [recordPage])
    useEffect(() => {
        fetchApi()

    }, []);

    return (
        <div className='container' style={ContainerWidth}>
            {
                data && history ? (<>
                    <PageHeader
                        style={{ background: "white", width: "100%" }}
                        title={data.company_name}
                        onBack={() => navigate(`/rent`, { state: state })}
                        className="site-page-header"
                        tags={<Tag color={data.company_status === 2 ? "" : "green"}>{data.company_status === 2 ? "停用" : "使用中"}</Tag>}
                        extra={UserPermission && UserPermission[routeId] !== 2 ? [] : data.company_status === 1 ? [
                            <Popconfirm
                                placement="bottom"
                                title='停用後該用戶於續訂日期結束服務使用'
                                // onCancel={ ()=> setPopStatus(false)} 
                                onConfirm={() => {
                                    DisabledRent({ company_base_row_id: data.company_base_row_id }).then(res => {
                                        if (res && !res.Error) {
                                            fetchApi()
                                        }
                                    })
                                }}
                                style={{ marginRight: 12 }}
                                okText='停用'
                                showCancel={false}
                            >
                                <Button>停用</Button>
                            </Popconfirm>
                            ,
                            <Button onClick={() => {
                                setModalStatus("2")
                            }} >方案變更</Button>
                        ] : [
                            <Button onClick={() => {
                                setModalStatus("2")
                            }} >續訂</Button>
                        ]}
                        breadcrumb={<BreadCrumb Info={{ [leaseId]: data.company_name }} />}
                    >
                    </PageHeader>
                    <div className='split_block' style={{ display: 'grid', gridTemplateColumns: "repeat(2, 1fr)", }}>
                        <div className='content_block' style={{ padding: 24 }}>
                            <Card
                                style={{ width: "100%" }}
                                title="基本資料"
                                extra={
                                    UserPermission && UserPermission[routeId] !== 2 ? false :
                                        <Button
                                            type="text"
                                            style={{ paddingRight: 0 }}
                                            onClick={() => {
                                                setModalStatus("1")
                                                setTimeout(() => {
                                                    editForm.current.setFieldsValue(data)
                                                }, 200)
                                            }}
                                        > 修改
                                            <EditOutlined style={{ color: "rgba(24, 144, 255, 1)" }} />
                                        </Button>
                                }
                            >
                                <CustomCard
                                    header={[
                                        { key: "company_name", name: "公司名稱" },
                                        { key: "company_industry_name", name: "產業別" },
                                        { key: "contact_person", name: "聯絡窗口" },
                                        { key: "contact_person_phone_text", name: "聯絡電話" },
                                        { key: "company_phone_text", name: "公司電話" },
                                        { key: "company_complete_address", name: "公司地址" },
                                        { key: "company_tax_no", name: "統編" },
                                        { key: "company_fax_no", name: "傳真號碼" },
                                        { key: "company_mail", name: "E-mail" }
                                    ]} data={data} />
                            </Card>
                            <Card
                                style={{ width: "100%", marginTop: 24 }}
                                title="訂購方案"
                            >
                                <CustomCard header={[
                                    { key: "company_id", name: "公司代號" },
                                    { key: "company_acc", name: "公司帳號" },
                                    { key: "company_un_pwd", name: "公司密碼" },
                                ]} data={data} />
                            </Card>
                            <Card
                                style={{ width: "100%", marginTop: 24 }}
                                title="訂購方案"
                            >
                                <CustomCard header={[
                                    { key: "plan", name: "使用方案" },
                                    { key: "pay_plan_money", name: "方案費用" },
                                    { key: "plan_period", name: "使用期間" },
                                    { key: "renew_date", name: "續訂日期" },
                                    { key: "pay_method", name: "付款方式" },
                                ]} data={data} />
                            </Card>
                            <Card
                                style={{ width: "100%", marginTop: 24 }}
                                title="訂購方案"
                                extra={
                                    UserPermission && UserPermission[routeId] !== 2 ? false :
                                        <Button
                                            type="text"
                                            ref={link}
                                            style={{ paddingRight: 0 }}
                                            onClick={() => {
                                                DownloadFile(`Lease/PayExcel?company_base_row_id=${leaseId}`)
                                            }}
                                        > 匯出
                                            <DownloadOutlined style={{ color: "rgba(24, 144, 255, 1)" }} />
                                        </Button>
                                }
                            >
                                <Table
                                    columns={[
                                        { title: '續訂日期', dataIndex: 'renew_date' },
                                        { title: '方案', dataIndex: 'plan' },
                                        { title: '費用', dataIndex: 'pay_plan_money', render: money => (`$${money}`) },
                                        {
                                            title: '狀態', dataIndex: 'is_read',
                                            render: (status, obj) => (<Button type="link" disabled={status}
                                                onClick={() => {
                                                    EditPaymentReadStatus({ pay_history_row_id: obj.pay_history_row_id }).then(res => {
                                                        if (res && !res.Error) {
                                                            fetchApi()
                                                        }
                                                    })
                                                }}
                                            >{status ? "已確認" : "待確認"}</Button>)
                                        },
                                    ]}
                                    pagination={false}
                                    rowKey={record => record.pay_history_row_id}
                                    dataSource={paymentRecord}
                                />
                                {
                                    allPaymentRecord && allPaymentRecord.length > 10 * recordPage && (
                                        <p style={{ textAlign: "center", padding: 12, cursor: "pointer" }} onClick={() => setRecordPage(recordPage + 1)}>載入更多</p>
                                    )
                                }

                            </Card>

                        </div>
                        <HistoryRecord detail={data} History={history} />

                    </div>
                    <Drawer
                        title="基本資料編輯"
                        placement="right"
                        size={"large"}
                        closable={false}
                        width={720}
                        extra={
                            <CloseOutlined style={{ cursor: "pointer", color: "rgba(0, 0, 0, 0.45)" }} onClick={() => onClose()} />
                        }
                        footer={
                            <div style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                                <Button type='text' onClick={() => onClose()}>取消</Button>
                                <Button style={{ marginLeft: 8 }} onClick={() => editForm.current.submit()}>更新</Button>
                            </div>
                        }
                        visible={modalStatus === "1" ? true : false}>
                        <Form
                            name="basic"
                            layout="vertical"
                            ref={editForm}
                            onFinish={editOnFinish}
                            autoComplete="off"
                            style={{ width: "100%" }}
                        >
                            <Form.Item
                                label="公司名稱"
                                name="company_name"
                                rules={[{ required: true, message: '請輸入公司名稱!' }]}
                            >
                                <Input allowClear placeholder='點擊選擇' style={{ width: "304px" }} />
                            </Form.Item>
                            <Form.Item
                                label="產業別"
                                name="c_industry"
                                rules={[{ required: true, message: '請選擇產業別!' }]}
                            >
                                <Radio.Group >
                                    <Radio.Button value="1">物流倉儲業</Radio.Button>
                                    <Radio.Button value="2">大眾運輸業</Radio.Button>
                                    <Radio.Button value="3">道路救援業</Radio.Button>
                                    <Radio.Button value="4">汽機車買賣業</Radio.Button>
                                    <Radio.Button value="5">零件運送業</Radio.Button>
                                </Radio.Group>
                            </Form.Item>
                            <div style={{ height: "100%" }}>
                                <div style={{ display: "flex" }}>
                                    <Form.Item
                                        label="公司電話"
                                        name="company_phone"
                                        rules={[{ required: true, message: '請輸入公司電話!' }]}
                                    >
                                        <Input allowClear placeholder='點擊選擇' style={{ width: "304px", marginRight: 16 }} />
                                    </Form.Item>
                                    <Form.Item
                                        label="公司統編"
                                        name="company_tax_no"
                                        rules={[{ required: true, message: '請輸入公司統編！' },
                                        {
                                            pattern: /^[\d]{8}$/,
                                            message: "請輸入8位數之公司統編",
                                        }]}
                                        hasFeedback={isValid !== null ? true : false}
                                        validateStatus={isValid === null ? '' : isValid ? 'success' : 'error'}

                                    >
                                        <Input
                                            allowClear
                                            placeholder='example'
                                            style={{ width: "304px" }}
                                            addonAfter={<EnterOutlined style={{ cursor: "pointer" }} onClick={(e) => {
                                                let val = editForm.current.getFieldValue("company_tax_no")
                                                if (val === data.company_tax_no) {
                                                    setIsValid(true)
                                                } else {
                                                    CheckTax(val)
                                                        .then(res => {
                                                            if (res && !res.Error) {
                                                                setIsValid(res.Result.is_repeat_by_tax)
                                                            }
                                                        })

                                                }
                                                // 

                                            }} />}

                                        />
                                    </Form.Item>
                                </div>

                                <div style={{ display: "flex", alignItems: "end" }}>
                                    <Form.Item
                                        label="公司地址"
                                        name="address_code"
                                        rules={[{ required: true, message: "點擊選擇!" }]}
                                        style={{ marginRight: 16 }}
                                    >
                                        <CustomCascader
                                            defaultValue={data ? data.address_code : ''}
                                            style={{ width: "304px" }}
                                            OnChange={(val) => editForm.current.setFieldsValue({ address_code: val })}
                                        />
                                    </Form.Item>
                                    <div>
                                        <div style={{ height: 30 }}></div>
                                        <Form.Item
                                            label=""
                                            name="company_address"
                                            rules={[{ required: true, message: "請輸入完整地址!" }]}
                                            style={{ marginRight: "16px" }}
                                        >
                                            <Input allowClear placeholder='請輸入完整地址' style={{ width: "304px" }} />
                                        </Form.Item>

                                    </div>


                                </div>
                                <div style={{ display: "flex" }}>
                                    <Form.Item
                                        label="聯絡窗口名稱"
                                        name="contact_person"
                                        rules={[{ required: true, message: '請輸入聯絡窗口名稱!' }]}
                                    >
                                        <Input allowClear placeholder='example' style={{ width: "304px", marginRight: 16 }} />
                                    </Form.Item>
                                    <Form.Item
                                        label="聯絡窗口電話"
                                        name="contact_person_phone"
                                        rules={[{ required: true, message: '請輸入聯絡窗口電話!' }]}
                                    >
                                        <Input allowClear placeholder='example' style={{ width: "304px" }} />
                                    </Form.Item>
                                </div>
                                <div style={{ display: "flex" }}>
                                    <Form.Item
                                        label="傳真號碼"
                                        name="company_fax_no"
                                    // rules={[{ required: true, message: '請輸入聯絡窗口名稱!' }]}
                                    >
                                        <Input allowClear placeholder='example' style={{ width: "304px", marginRight: 16 }} />
                                    </Form.Item>
                                    <Form.Item
                                        label="E-mail"
                                        name="company_mail"
                                        rules={[{ required: true, message: '請輸入E-mail!' }]}
                                    >
                                        <Input allowClear placeholder='example' style={{ width: "304px" }} />
                                    </Form.Item>
                                </div>
                            </div>
                        </Form>
                    </Drawer>
                    <Modal
                        title='方案變更'
                        visible={modalStatus !== "1" && modalStatus !== null}
                        width={416}
                        onOk={() => settingForm.current.submit()}
                        onCancel={() => onClose()}
                    >
                        <Form
                            name="basic"
                            layout="vertical"
                            ref={settingForm}
                            initialValues={data}
                            onFinish={settingOnSubmit}
                        >
                            <Form.Item
                                label="方案"
                                name="Plan"
                                rules={[{ required: true, message: '請選擇方案！' }]}
                            >
                                <Radio.Group
                                    buttonStyle="solid"
                                >
                                    <Radio.Button value="1">標準版</Radio.Button>
                                    <Radio.Button value="2">專業版</Radio.Button>
                                </Radio.Group>

                            </Form.Item>
                            <Form.Item
                                label={"付款頻率"}
                                name="Frequency"
                                rules={[{ required: true, message: '請選擇付款頻率！' }]}
                            >
                                <Radio.Group
                                    buttonStyle="solid"
                                >
                                    <Radio.Button value="1">月付</Radio.Button>
                                    <Radio.Button value="2">年付</Radio.Button>
                                </Radio.Group>

                            </Form.Item>
                            <Form.Item
                                label={"付款方式"}
                                name="Method"
                                rules={[{ required: true, message: '請選擇付款方式！' }]}
                            >
                                <Radio.Group
                                    buttonStyle="solid"
                                >
                                    <Radio.Button value="1">匯款</Radio.Button>
                                    <Radio.Button value="2">信用卡</Radio.Button>
                                </Radio.Group>

                            </Form.Item>
                        </Form>
                    </Modal>
                </>) : (<div style={{ padding: 24, height: '100%' }}>
                    <LoadingBlock />
                </div>)
            }


        </div>
    );
}

export default Info;

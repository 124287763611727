import React, { useState, useRef } from 'react'
import { PageHeader, Form, Input, Button, notification, Upload, DatePicker,Popconfirm } from 'antd'
import BreadCrumb from '../../../components/shared/BreadCrumb'
import { PictureOutlined, PlusOutlined, RightOutlined, UnorderedListOutlined, UploadOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import { AddAdvertisementDetail } from '../../../api/Advertisement'

const Add = ({ ContainerWidth }) => {
    const submitForm = useRef()
    const fileInput = useRef()
    const [currentStep, setCurrentStep] = useState(0)
    let navigate = useNavigate()
    // const [successData, setSuccessData] = useState([])
    const [imageUrl, setImageUrl] = useState(null);
    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };

    const onFinish = async (val) => {
        setCurrentStep(1)
        let today = moment().format("YYYY-MM-DD")
        val.start_date = moment(val.start).format("YYYY-MM-DD") === today ? moment(val.start).format("YYYY-MM-DD HH:mm:ss") : moment(val.start).format("YYYY-MM-DD 00:00:00")
        val.end_date = moment(val.end).format("YYYY-MM-DD 23:59:59")

        val.dm_link = `${val.dm_link}`
        val.dm_desc = val.dm_desc ? val.dm_desc : ''
        const formdata = new FormData();
        for (let key in val) {
            if (key === "dm_photo") {
                if (val[key]["file"]["originFileObj"]) {
                    formdata.append(key, val[key]["file"]["originFileObj"])
                } else {
                    formdata.append(key, val[key])
                }
            } else {
                formdata.append(key, val[key])
            }
        }
        let res = await AddAdvertisementDetail(formdata)
        if (!res.Error) {
            notification.success({
                message: '新增成功',
                closeIcon: <></>,
                duration: 1.75,
            });
            setTimeout(() => navigate(`/customer/advertisement/${res.Result.Data.row_id}`), 2000)

        }


    }

    return (
        <div className='container' style={ContainerWidth}>
            <PageHeader
                title="新增廣告版位"
                onBack={() => currentStep === 0 ? window.history.go(-1) : setCurrentStep(currentStep - 1)}
                className="site-page-header"
                breadcrumb={<BreadCrumb />}
            >
            </PageHeader>
            <div className='step_block'>
                <div >
                    <Form
                        ref={submitForm}
                        name="basic"
                        layout="vertical"
                        // initialValues={{ type: promotionType, t001_reach_type: '001', t001_discount_type: '001', t002_discount_type: '001', t003_discount_type: '001' }}
                        onFinish={onFinish}

                    >
                        <div style={{ minHeight: "calc(100vh - 240px)" }}>
                            <div className='step_content'>

                                <div style={{ display: "flex" }}>
                                    <Form.Item
                                        label="上架日期"
                                        name="start"
                                        rules={[{ required: true, message: '請選擇上架日期！' }]}
                                    >
                                        <DatePicker style={{ width: "304px" }} />
                                    </Form.Item>
                                    <Form.Item
                                        label="下架日期"
                                        name="end"
                                        style={{ margin: '0 16px' }}
                                        rules={[{ required: true, message: '請選擇業下架日期！' }]}
                                    >
                                        <DatePicker style={{ width: "304px" }} />
                                    </Form.Item>
                                </div>
                                <Form.Item
                                    label='廣告連結'
                                    name="dm_link"
                                    rules={[{ required: true, message: '請輸入廣告連結！' }]}
                                >
                                    {/* <Input addonBefore="https://" style={{ width: 624 }} /> */}
                                    <Input style={{ width: 624 }} />


                                </Form.Item>
                                <Form.Item
                                    label='廣告圖'
                                    name="dm_photo"
                                    rules={[{ required: true, message: '請選擇廣告圖！' }]}
                                >
                                    <div style={{ width: 395, height: 216, background: "#FAFAFA", border: "1px solid #D9D9D9", cursor: "pointer" }} onClick={() => {
                                        fileInput.current.click()
                                    }}>
                                        <Upload
                                            showUploadList={false}
                                            accept="image/*"
                                            customRequest={({ onSuccess }) => onSuccess("ok")}
                                            onChange={(info) => {
                                                submitForm.current.setFieldsValue({ dm_photo: info })
                                                getBase64(info.file.originFileObj, url => {
                                                    setImageUrl(url);
                                                })
                                            }}
                                        >

                                            <Button style={{ display: "none" }}
                                                ref={fileInput}
                                                icon={<UploadOutlined />}>Upload</Button>
                                        </Upload>
                                        {/* <input type="file" 
                                                    accept="image/*" style={{display:"none"}} 
                                                   
                                                    onChange={(e)=>console.log(e)}
                                                /> */}
                                        {
                                            imageUrl ? (<img src={imageUrl} alt="avatar" style={{ width: '100%' }} />) : (<>
                                                <PictureOutlined style={{ display: "block", color: "#1890FF", fontSize: 48, margin: "20px auto 0" }} />
                                                <div style={{ width: "60%", margin: "0 auto ", fontSize: 16, textAlign: "center" }}>
                                                    <p>點擊上傳廣告圖</p>
                                                    <p style={{ fontSize: 14, color: "rgba(0,0,0,0.45)", marginTop: 4 }}>請上傳橫式 4:1 比例的廣告圖，像素寬度須等於或大於720px 高度須等於或大於180px的大小並限制影像格式為：jpg, png, gif</p>
                                                </div> </>)
                                        }



                                    </div>
                                </Form.Item>
                                <Form.Item
                                    label={<><span>廣告說明</span><span style={{ paddingLeft: 4, color: "rgba(0, 0, 0, 0.45)" }}>(非必填)</span></>}
                                    name="dm_desc"
                                // rules={[{ required: true, message: '請選擇活動類型！' }]}
                                >
                                    <Input placeholder='請概述廣告內容' style={{ width: 304 }} />


                                </Form.Item>

                            </div>
                        </div>
                        <div style={{ background: "white", marginTop: 16, width: "100%", padding: "20px", display: "flex", justifyContent: "flex-end" }}>
                            <Popconfirm
                                style={{ width: 410 }}
                                placement="top"
                                title={
                                    <div>
                                        <div>
                                            <strong>新增還沒完成喔</strong>
                                        </div>
                                        <div style={{ fontSize: 14 }}>
                                            剛剛輸入的所有資訊將不被保存。
                                        </div>
                                    </div>
                                }
                                onCancel={() => navigate(-1)}
                                okText="不，我要留下"
                                cancelText="對，我要離開"
                            >
                                <Button type="text" style={{ marginRight: 24 }}>
                                    關閉
                                </Button>
                            </Popconfirm>
                            <Button type="primary"
                                icon={<PlusOutlined />}
                                htmlType="submit"
                                disabled={currentStep === 1 ? true : false}
                            > {currentStep === 1 ? '新增中...' : '新增'}</Button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    )
}

export default Add
import React, {  } from 'react';
import { Collapse, Tag} from 'antd'
import {PhoneOutlined} from '@ant-design/icons'; 
const { Panel } = Collapse;

const Table = ({header, data}) => {
     
    if(data !== null){
        for(let key in data){
            // console.log(`${key}`)
            // console.log(data[key])
            // typeof(data[key]) === "string" && console.log(data[key]["type"])
            if(!data[key]){
                data[key] = ""
            }
        }
        return (
            <div>
                <ul className='case_table'>
                {
                    header.map( (h,i) =>{
                        let key = h.key
                        // data[key]["type"] === 
                        // 1. accordian; 2. tel; 3. tag, 4. secondaryTag

                        return(
                            <li style={{display: "flex", borderBottom: "1px solid rgba(240, 240, 240, 1)"}} key={key}>
                                <div style={{width:"160px", background: "#FAFAFA", padding: 8}}>{h.name}</div>
                                {/* {
                                    typeof(data[key]) === "string"? console.log(`string:${key}`): console.log(`obj:${key}`)
                                } */}
                               
                                {
                                    typeof(data[key]) === "string" || typeof(data[key]) === "number" ? 
                                    ( <div style={{padding: 8, width:"calc(100% - 160px)"}}>{data[key] === ""  ? "-": data[key]}</div>) 
                                    : data[key]["type"] && data[key]["type"] === "accordian" ?
                                        (<div style={{width: "calc(100% - 160px)",height:"100%", padding: "8px 0"}}>
                                            {
                                                data[key]["text"] === "" || !data[key]["text"] ?(<div style={{padding:"0 8px"}}>-</div>):(
                                                <Collapse expandIconPosition={"right"}>
                                                    <Panel header={`$${data[key]["text"]}`} key={i} >
                                                    {
                                                        data[key]["subdata"].map((sub,i)=>(  
                                                            <p>{`${sub.type} / $${sub.amount}`}</p>
                                                        ))
                                                    }
                                                    </Panel>

                                                   
                                                </Collapse>
                                                )
                                            }
                                           
                                        </div>)
                                    : data[key]["type"] && data[key]["type"] === "tel" ? 
                                        (<div style={{padding: 8, display: "flex", alignItems:"center"}}>
                                            <p>
                                            {
                                                data[key]["subject"] !=="" && data[key]["tel"]!=="" ? `${data[key]["subject"]}-${data[key]["tel"]}` : "-"
                                            }
                                            </p>
                                            {
                                                data[key]["tel"]!=="" ? ( <a href={`tel:${data[key]["tel"]? data[key]["tel"]: "-"}`} style={{color: "rgba(0,0,0,0.25)", paddingLeft: 8}}><PhoneOutlined /></a>) :""
                                            }
                                           
                                        </div>)  
                                    :  data[key]["type"] && data[key]["type"] === "tag" ? 
                                        (<div style={{padding: 8, display: "flex", alignItems:"center"}} >   
                                            <Tag color= {data[key]["status"]=== 1? "green" :"red"}> 
                                                {data[key]["status"]=== 1? "正常" :"不可行駛"}
                                            </Tag>
                                        </div>) 
                                    : data[key]["type"] && data[key]["type"] === "secondaryTag"? 
                                        (<div style={{padding: 8, display: "flex", alignItems:"center"}} > 
                                        {
                                            data[key]["title"] ? (  <Tag icon={data[key]["icon"]} color="default"> {
                                                 typeof(data[key]["title"]) === "string"? (data[key]["title"]) : `${data[key]["title"][0]} / ${data[key]["title"][1]}`
                                            } </Tag>)
                                            : data[key]["data"].map(d =>(<Tag key={d.id} icon={data[key]["icon"]} color="default"> {
                                                 d.fileName
                                            } </Tag>)) 
                                        } 
                                          
                                        </div>):
                                        (<div style={{padding: 8, display: "flex", alignItems:"center"}} >  
                                            <div style={{display:"flex", alignItems: "center"}}>
                                                <div style={{width: 6, height: 6, borderRadius: "50%", background:"rgba(82, 196, 26, 1)", marginRight: 8}}></div>
                                                <p>{data[key]["status"] === 1? "執行中": "已完成"}{data[key]["place"] ? ` / ${data[key]["place"]}`:""}</p>
                                            </div> 
                                        </div>)
                                }
                            </li>
                        )
                    })
                }
                   
                </ul>  
            </div>
        );
    }else{
        return(<div></div>)
    }
   
}

export default Table;

import React, { useState, useEffect, useRef } from 'react'
import { Cascader, Form } from 'antd'
import { useSelector } from 'react-redux'
import { GetAllStationList } from '../../../api/ServicePoint'
import _ from 'lodash'
import { cloneDeep } from 'lodash'
const CustomCascader = ({ style, withServicePoint, defaultValue, OnChange, multiple, SeperatedName, selectedCustomer, ReturnName, row_id, NameCode }) => {
    //withServicePoint === true (包含運送點)
    const [pointOptions, setPointOptions] = useState()
    let town = useSelector(state => state.county)
    const cascaderRef = useRef()
    const Filter = async () => {
        let Town = cloneDeep(town)
        let selected_town = withServicePoint ? Town : [...Town[0]["children"]]
        if (withServicePoint && !selectedCustomer) {
            let town = []
            let town_init = [...Town[0]["children"]]
            town_init.forEach(county => {
                let children = county.children.map(t => {
                    t.value = `${t.Code},${t.label}`
                    return t
                })
                let obj = {
                    label: county.label,
                    value: NameCode ? `${county.value},${county.label}` : county.value,
                    children: children
                }
                town.push(obj)
            })
            // Town[0]["children"] = town
            let res = await GetAllStationList()
            let result = []
            if (res && !res.Error) {
                let Result = res.Result
                result = [];
                const map = new Map();
                for (const item of Result) {
                    if (!map.has(item.name)) {
                        map.set(item.name, true);    // set any value to Map
                        result.push({
                            id: item.code,
                            label: item.name,
                            value: row_id ? `${item.code},${item.row_id}` : ReturnName ? item.name : SeperatedName ? `${item.code},${item.name}` : item.code
                        });
                    }
                }
            }
            selected_town = [...Town].concat(result)
        } else {
            if (selectedCustomer) {
                // let town = []
                // let town_init = [...Town[0]["children"]]
                // town_init.forEach(county=>{
                //     let children = county.children.map(t=> {
                //         t.value = `${t.Code},${t.label}`   
                //         return t
                //     })
                //     let obj={
                //         label : county.label,
                //         value :NameCode?`${county.value},${county.label}`:county.value,
                //         children:children
                //     }
                //     town.push(obj)
                // })
                const user = JSON.parse(window.localStorage.getItem('User'))
                let data = await fetch(`${process.env.REACT_APP__API_HOST}/Customer/StationList?customer_row_id=${selectedCustomer}`, {
                    headers: {
                        'Authorization': user ? `Bearer ${user.Token}` : ''
                    }
                }).then(res => res.json())
                if (!data.Error) {
                    let station = data.Result.map(s => {
                        s.label = s.name
                        s.value = SeperatedName ? s.name : row_id ? `${s.code},${s.row_id}` : `${s.code}`
                        return s
                    })

                    selected_town = [...town].concat(station)
                }
            } else {
                if (ReturnName) {
                    // console.log(selected_town)
                    // selected_town = Town[0]["children"].map(t=>{
                    //     t.value = t.name
                    //     return t
                    // })

                } else {
                    selected_town = []
                    let town_init = [...Town[0]["children"]]
                    if (NameCode) {
                        town_init.forEach(county => {
                            let children = county.children.map(t => {
                                t.value = `${t.Code},${t.label}`
                                return t
                            })
                            let obj = {
                                label: county.label,
                                value: `${county.value},${county.label}`,
                                children: children
                            }
                            selected_town.push(obj)
                        })
                    } else {
                        town_init.forEach(county => {
                            let children = county.children.map(t => {
                                t.value = `${t.Code}`
                                return t
                            })
                            let obj = {
                                label: county.label,
                                value: `${county.value}`,
                                children: children
                            }
                            selected_town.push(obj)
                        })
                        selected_town = town_init
                    }
                }
            }
        }
        // selected_town = selected_town.map((t,i)=>{
        //     if(i===0){
        //         t.disabled = true
        //     }
        //     return t
        // })
        // console.log(selected_town)
        setPointOptions(selected_town)
        if (defaultValue) {
            // let reform = defaultValue.map(d => {
            //     let arr = []
            //     if (d.indexOf("縣") > -1 || d.indexOf("市") > -1) {
            //         let county = d
            //         arr.push("1")
            //         if (d.indexOf("區") > -1) {
            //             county = d.slice(0, 3)
            //             let town = d.slice(3)
            //             let filter = selected_town[0]["children"].filter(d => d.label === county)
            //             if (filter.length > 0) {
            //                 arr.push(filter[0]["value"])
            //                 let filter_town = filter[0]["children"].filter(d => d.label === town)
            //                 if (filter_town.length > 0) arr.push(filter_town[0]["value"])
            //             }
            //         } else {
            //             let filter = selected_town[0]["children"].filter(d => d.label === county)
            //             if (filter.length > 0) arr.push(filter[0]["value"])
            //         }
            //     } else {
            //         let filter = selected_town.filter(point => point.label === d)
            //         if (filter.length > 0) {
            //             arr.push(filter[0]["value"])
            //         } else {
            //             arr = d
            //         }
            //     }
            //     return arr

            // })
            setTimeout(() => {
                if (cascaderRef.current) cascaderRef.current.setFieldsValue({ point: defaultValue })

            }, 200)
        }
    }
    useEffect(() => {
        if (town !== null) {
            Filter()
        }
    }, [town, selectedCustomer, defaultValue])

    return (
        <Form ref={cascaderRef}>
            <Form.Item noStyle name='point'>
                <Cascader
                    multiple={multiple}
                    style={style}
                    options={pointOptions}
                    displayRender={(labels, selectedOptions) => {
                        let new_label = [];
                        // if (withServicePoint && selectedOptions && selectedOptions[0]) {
                        //     if (selectedOptions.length > 1) {
                        //         selectedOptions.forEach((option, i) => {
                        //             if (ReturnName) {
                        //                 new_label.push(option);
                        //             } else {
                        //                 if (i !== 0) new_label.push(option)
                        //             }
                        //         });
                        //     } else {
                        //         new_label = selectedOptions;
                        //     }

                        // } else {
                        //     new_label = selectedOptions;
                        // }
                        // console.log(selectedOptions)
                        new_label = selectedOptions;

                        let text = "";
                        new_label.map((l, i) => {
                            if (l) {
                                if (SeperatedName && !withServicePoint) {
                                    text += new_label.length === 1 ? `${l.label} / 全區` : i === 0 ? `${l.label} / ` : `${l.label}`
                                } else {
                                    if (SeperatedName) {
                                        if (new_label.length === 1) {
                                            text = l.label
                                        } else if (new_label.length === 2) {
                                            text = i === 1 && `${l.label} / 全區`
                                        } else {
                                            text += i === 1 ? `${l.label} / ` : i === 2 ? `${l.label}` : ''
                                        }
                                    } else {
                                        text += withServicePoint && new_label.length === 3 && i === 0 ? "" : l.label
                                    }
                                }
                            }else{
                                // text += ` / 全區`
                            }

                        });
                        return <span>{text} </span>;
                    }}
                    onChange={(val) => OnChange(val)}
                    placeholder="點擊選擇"
                    showSearch={(inputValue, path) => {
                        return path.some(
                            (option) =>
                                option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
                        );
                    }}
                // onSearch={(value) => console.log(value)}
                />

            </Form.Item>
        </Form>

    )
}

export default CustomCascader
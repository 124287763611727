
export const notifyChanges = (state) => {
    // console.log(state)
    // console.log(payload)
    //   if (payload) {
        return {
            type: 'CHANGE_STATUS',
            notify: state,
          }
    //   }
    }
    
    export const unreadNotification = (state) => {
            return {
                type: 'UNREAD_NOTIFICATION',
                notify: state,
              }
        //   }
        }
        

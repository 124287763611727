import React, { useState, useEffect, useRef } from 'react'
import BreadCrumb from '../../../components/shared/BreadCrumb'
import CustomCard from '../../../components/shared/component/Card'
import { PageHeader, Button, Card, Tag, DatePicker, Input, Upload, Drawer, Form } from 'antd'
import { CheckCircleOutlined, CloseCircleOutlined, EditOutlined, PlusOutlined, CloseOutlined, MinusCircleOutlined } from '@ant-design/icons'
import { useParams, useNavigate,useLocation} from 'react-router-dom'
import { GetAdvertisementDetail, GetAdvertisementHistory, UpdateAdvertisementDetail } from '../../../api/Advertisement'
import LoadingBlock from '../../../components/shared/LoadingBlock'
import { FilterHistory } from '../../../components/shared/util/FilterHistory'
import moment from 'moment'
import { RouteName } from '../../../util/route'
import { useSelector } from 'react-redux'

const AdvertisementDetail = ({ ContainerWidth }) => {
    let { advertisementId } = useParams()
    const { state,pathname } = useLocation()
    const routeId = RouteName(pathname)
    let UserInfo = useSelector(state => state.UserInfo) 
    let UserPermission = UserInfo? UserInfo.permission.route: null
    const [historyRecord, setHistoryRecord] = useState(null)
    const [fetchData, setFetchData] = useState(null)
    const navigate = useNavigate()
    const [editDrawer, setEditDrawer] = useState(null)
    const [imageUrl, setImageUrl] = useState(null);
    const [previewPhoto, setPreviewPhoto] = useState(null);
    const submitForm = useRef()
    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };
    useEffect(() => {
        FetchApi()
    }, [])
    const FetchApi = async () => {
        setImageUrl(null)
        let res = await GetAdvertisementDetail(advertisementId)
        setImageUrl(`${process.env.REACT_APP__API_HOST}/Dm/GetDmPhoto?row_id=${advertisementId}&date=${new Date().getTime()}`)
        setPreviewPhoto(`${process.env.REACT_APP__API_HOST}/Dm/GetDmPhoto?row_id=${advertisementId}&date=${new Date().getTime()}`)
        if (!res.Error) {
            let Result = { ...res.Result }
            Result["start_date"] = moment(Result["start_date"]).format("YYYY.MM.DD HH:mm:ss")
            Result["end_date"] = moment(Result["end_date"]).format("YYYY.MM.DD HH:mm:ss")
            Result.start = moment(Result["start_date"])
            Result.end = moment(Result["end_date"])
            Result.status = {
                type: "status",
                color: Result.Status === "1" ? "#52C41A" :  Result.Status === "2" ? "#D9D9D9" :"#D9D9D9",
                content: Result.Status === "1" ? ["上架中"] : Result.Status === "2"?["未啟用"]:["已結束"],
                status: Result.Status
            }
            setFetchData(Result)
        }
        let history = await GetAdvertisementHistory(advertisementId, 0, -1)
        let history_list = []
        if (!history.Error) {
            let History = (history.Result.Data).reverse()
            history_list = await FilterHistory(History)
        }
        setHistoryRecord(history_list)
    }
    const onFinish = async (val) => {
        let update_uid = "00050"
        val.row_id = advertisementId
        val.update_uid = update_uid
        let arr =["start","end"]
        arr.forEach(a=>{
            let ini_key = `${a}_date`
            if(fetchData[ini_key].indexOf((val[a]).format("YYYY.MM.DD")) > -1){
                val[ini_key]  = fetchData[ini_key].replaceAll(".","-")
            }else{
                if(a==='start'){
                    let today = moment().format("YYYY-MM-DD")
                    val[ini_key] = moment(val[a]).format("YYYY-MM-DD") === today? moment(val[a]).format("YYYY-MM-DD HH:mm:ss"):  moment(val[a]).format("YYYY-MM-DD 00:00:00")
                    val.end_date = moment(val.end).format("YYYY-MM-DD 23:59:59")
                }else{
                    val[ini_key] = val[a].format("YYYY-MM-DD 23:59:59")
                }
            }
        })
        const formdata = new FormData();
        for (var key in val) {
            if (key === "dm_photo") {
                if(val[key]["file"]){
                    formdata.append(key, val[key]["file"]["originFileObj"])
                }else{
                    formdata.append(key, val[key])
                }
            } else {
                formdata.append(key, val[key])
            }
        }
        let res = await UpdateAdvertisementDetail(formdata)
        if(!res.Error){
            FetchApi()
            setEditDrawer(null)
        }
    }
    return (
        <div className="container" style={ContainerWidth}>
            {
                fetchData ? (<>
                    <PageHeader
                        style={{ background: "white", width: "100%" }}
                        title="廣告板位"
                        onBack={() => navigate(-1)}
                        className="site-page-header"
                        breadcrumb={<BreadCrumb
                            Info={fetchData !== null && { [advertisementId]: '廣告板位' }}
                        />}
                        tags={<Tag icon={fetchData.Status === "1" ? <CheckCircleOutlined /> :fetchData.Status === "2" ? <MinusCircleOutlined/>:<CloseCircleOutlined />} color={fetchData.Status === "1" ? "success" : fetchData.Status === "2" ?"" :"error"}>{fetchData.Status === "1" ? "上架中" :fetchData.Status === "2"? "未啟用": "已結束"}</Tag>}
                        extra={UserPermission && UserPermission[routeId] ===2?[<Button icon={<EditOutlined />}
                            onClick={() => {
                                setEditDrawer(true)
                                setTimeout(() => {
                                    submitForm.current.setFieldsValue(fetchData)
                                }, 200)
                            }}
                        >編輯</Button>]:[]}
                    >
                    </PageHeader>
                    <div className='split_block' style={{ display: 'grid', gridTemplateColumns: "repeat(2, 1fr)", height: "calc(100% - 102px)" }}>
                        <div style={{ padding: 24 }}>
                            <Card
                                style={{ width: "100%", margin: "24px 0" }}
                                title="基本資訊"
                            >
                                <div
                                    style={{ width: "100%", height: "134px", background: "#D9D9D9", borderRadius: 16, marginBottom: 24, cursor: "pointer" }}
                                    onClick={() => {
                                        let url =fetchData.dm_link.indexOf("https") >-1 ? fetchData.dm_link :`https://${fetchData.dm_link}` 
                                        window.open(url, '_blank')
                                    }}
                                >
                                    <img src={imageUrl} style={{width:"100%", height:"100%", objectFit:"cover"}} /> 
                                </div>
                                <CustomCard
                                    header={[
                                        { key: "start_date", name: "上架時間" },
                                        { key: "end_date", name: "下架時間" },
                                        { key: "dm_desc", name: "廣告說明" },
                                        { key: "status", name: "狀態" },
                                    ]}
                                    data={fetchData}
                                >
                                </CustomCard>
                            </Card>
                        </div>
                        <div style={{ padding: 32, background: "rgba(250, 250, 250, 1)" }}>
                            <div
                                style={{
                                    fontWeight: 500,
                                    padding: "16px",
                                    borderBottom: "1px solid rgba(240, 240, 240, 1)",
                                }}>歷程</div>
                            <ul style={{ padding: 32, margin: 0 }}>
                                {
                                    historyRecord && historyRecord.map(h => (<li
                                        key={`${h.log_text}_${h.log_time}`}
                                        style={{
                                            fontSize: 14,
                                            display: "flex",
                                            justifyContent: "space-between",
                                            color: "rgba(0, 0, 0, 0.25)",
                                            marginBottom: 18,
                                        }}
                                    >
                                        <p style={{width:'80%'}}>
                                            {
                                                h.text.map(text => (<span style={text.style}>{text.title}</span>))
                                            }
                                        </p>
                                        <p style={{ color: "rgba(0, 0, 0, 0.25)", width:'20%', textAlign: "end" }}> {h.date}</p>
                                    </li>))
                                }
                            </ul>
                        </div>
                    </div>
                    <Drawer
                        width={672}
                        title={"編輯"}
                        placement="right"
                        closable={false}
                        extra={<CloseOutlined
                            style={{ cursor: "pointer", color: "rgba(0, 0, 0, 0.45)" }}
                            onClick={() => setEditDrawer(null)}
                        />}
                        visible={editDrawer}
                        footer={
                            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                <Button type="text" style={{ marginRight: 16 }} onClick={() => {
                                    setEditDrawer(null)
                                    setPreviewPhoto(`${process.env.REACT_APP__API_HOST}/Dm/GetDmPhoto?row_id=${advertisementId}&date=${new Date().getTime()}`)
                                    submitForm.current.resetFields()
                                }
                                }>取消</Button>
                                <Button type="primary"
                                    // disabled={btnDisabled}
                                    onClick={() => {
                                        submitForm.current.submit()

                                        // setSubmitForm(true)
                                    }}>新增</Button>
                            </div>
                        }
                    >

                        <Form
                            ref={submitForm}
                            name="basic"
                            onFinish={onFinish}
                            layout="vertical"
                            autoComplete="off"
                        >
                            {
                                editDrawer && (<>
                                    
                                    <div style={{ display: "flex" }}>
                                        <Form.Item
                                            label="上架日期"
                                            name="start"
                                            rules={[{ required: true, message: '請選擇上架日期!' }]}
                                        >
                                            <DatePicker style={{ width: 304, marginRight: 16 }} />
                                        </Form.Item>
                                        <Form.Item
                                            label="下架日期"
                                            name="end"
                                            rules={[{ required: true, message: '請選擇下架日期!' }]}
                                        >
                                            <DatePicker style={{ width: 304 }} />

                                        </Form.Item>
                                    </div>
                                    <Form.Item
                                        label="廣告連結"
                                        name="dm_link"
                                        rules={[{ required: true, message: '請選擇廣告連結!' }]}
                                    >
                                        {/* <Input addonBefore="https://" /> */}
                                        <Input />

                                    </Form.Item>
                                    <Form.Item
                                        label="廣告圖"
                                        name="dm_photo"
                                        rules={[{ required: true, message: '請選擇廣告圖!' }]}
                                    >
                                        <Upload
                                            listType="picture-card"
                                            className="avatar-uploader"
                                            showUploadList={false}
                                            accept="image/*"
                                            customRequest={({ onSuccess }) => onSuccess("ok")}
                                            onChange={(info) => {
                                                getBase64(info.file.originFileObj, url => {
                                                    setPreviewPhoto(url);
                                                })
                                            }}
                                        >
                                            {previewPhoto ? <img src={previewPhoto} alt="avatar" style={{ width: '100%' }} /> : (<div>
                                                <PlusOutlined />
                                                <div style={{ marginTop: 8 }}>上傳</div>
                                            </div>)}
                                        </Upload>
                                    </Form.Item>
                                    <Form.Item
                                        label={<><span>廣告說明</span><span style={{ paddingLeft: 4, color: "rgba(0, 0, 0, 0.45)" }}>(非必填)</span></>}
                                        name="dm_desc"
                                    // rules={[{ required: true, message: '請輸入廣告說明!' }]}
                                    >
                                        <Input style={{width:304}} />
                                    </Form.Item>
                                </>)
                            }
                        </Form>
                    </Drawer>
                </>) :
                    (<div style={{ padding: 24, height: '100%' }}>
                        <LoadingBlock />
                    </div>)
            }
        </div>
    )
}

export default AdvertisementDetail
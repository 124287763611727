import React, { useState, useRef, useEffect } from 'react';
import { PageHeader, Steps, Form, Button, Input, Upload, DatePicker, Select, TimePicker, Radio, message } from 'antd'
import { PlusOutlined, UploadOutlined, UserOutlined, PhoneOutlined, ArrowRightOutlined, RightOutlined, UnorderedListOutlined, EnterOutlined } from '@ant-design/icons'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import BreadCrumb from '../../components/shared/BreadCrumb'
import CustomCascader from '../../components/shared/component/CustomCascader'
import moment from 'moment'
import { GetServicePoint, AddServicePointDriver, UpdateServicePointDriverNote, CheckMobileNumber } from '../../api/ServicePoint'
import { GetCarTypeList, GetUnoccupiedCarList } from '../../api/Code'
// import LoadingBlock from '../../components/shared/LoadingBlock'

const { Step } = Steps
const { TextArea } = Input

const steps = [
    { title: '基本資訊', description: '請輸入技師的基本資訊', content: 'First-content', width: "30%" },
    { title: '緊急聯絡人', description: '為確保技師的工作安全請務必填寫', content: 'Second-content', width: "100%" },
    { title: '完成', sdescription: '完成後即可派遣', content: 'Last-content', width: "30%" },
];
const Technicianadd = ({ ContainerWidth }) => {
    const SubmitForm = useRef()
    let navigate = useNavigate()
    let { pointId } = useParams()
    const [currentStep, setCurrentStep] = useState(0)
    const [detail, setDetail] = useState(null)
    const [remark, setRemark] = useState("")
    const { state } = useLocation()
    const [carTypeList, setCarTypeList] = useState([])
    const [submitData, setSubmitData] = useState(null)
    const [successSubmit, setSuccessSubmit] = useState(null)
    const [IdPic, setIdPic] = useState([])
    const [LicensePic, setLicensePic] = useState([])
    const [UnoccupiedCarList, setUnoccupiedCarList] = useState([])
    const [workType, setWorkType] = useState("1")
    const [workSelected, setWorkSelected] = useState({})
    const [isValid, setIsValid] = useState(null)

    const work = [
        { title: '「星期一」出勤時段', key: 'mon', day: "一" },
        { title: '「星期二」出勤時段', key: 'tue', day: "二" },
        { title: '「星期三」出勤時段', key: 'wed', day: "三" },
        { title: '「星期四」出勤時段', key: 'thu', day: "四" },
        { title: '「星期五」出勤時段', key: 'fri', day: "五" },
        { title: '「星期六」出勤時段', key: 'sat', day: "六" },
        { title: '「星期日」出勤時段', key: 'sun', day: "日" },
    ]

    const checkMobile = async () => {
        let val = SubmitForm.current.getFieldValue().mobile_phone_number
        let valid= false
        if (val) {
            let res = await CheckMobileNumber(val)
            if(res && !res.Error){
                valid = res.Result.Data
            }else{
                valid = false
            }
        }
        setIsValid(valid)
        return valid
    }
    const onFinish = async (value) => {
        if (currentStep === 0) {
            let valid = await checkMobile()
            if(valid){
                setCurrentStep(1)
                setSubmitData(value)
            }
        }
        if(currentStep === 1 && submitData) {
            const formdata = new FormData();
            let new_submit_data = { ...submitData, ...value }
            new_submit_data.county = new_submit_data.address_county[0]
            new_submit_data.town = new_submit_data.address_county[1]
            let pic = ["id_picture", "driving_license"]
            pic.forEach(p => {
                if (new_submit_data[p]) {
                    let files = new_submit_data[p]
                    if (files.length > 0) {
                        new_submit_data[`${p}_front`] = files[0]["originFileObj"]
                        if (files.length === 2) {
                            new_submit_data[`${p}_back`] = files[1]["originFileObj"]
                        }
                    }
                }
            })
            let date_arr = ["birthday", "driving_license_expiration_date", "take_office_date"]
            date_arr.forEach(d => {
                if (new_submit_data[d]) {
                    new_submit_data[d] = new_submit_data[d].format()
                }
            })
            let skill = ''
            new_submit_data.skill.forEach((s, i) => skill += i === 0 ? s : `,${s}`)
            new_submit_data.skill = skill
            work.forEach((w) => {
                // let no_work = "Y"
                // if(workSelected[w.key]){
                //     no_work = "N"
                // }else{
                //     no_work = "Y"
                // }
                new_submit_data[`${w.key}_work`] = workSelected[w.key] ? 1 : 0

            })
            delete new_submit_data.id_picture
            new_submit_data.service_point_row_id = pointId
            new_submit_data.status = 1
            Object.keys(new_submit_data).forEach((key, i) => {
                if (!new_submit_data[key]) {
                    formdata.append(key, "")
                } else {
                    formdata.append(key, new_submit_data[key])
                }
            })
            AddServicePointDriver(formdata).then(res => {
                if (res && res.Result.Success) {
                    let Data = { ...res.Result.Data, name: new_submit_data.name }
                    SubmitForm.current.resetFields()
                    setWorkType("1")
                    setSuccessSubmit(Data)
                    setCurrentStep(2)
                }
            })
        }

        
    }

    useEffect(() => {
        GetCarTypeList().then(res => {
            if (res && !res.Error) {
                let Data = res.Result.map(r => {
                    r.value = r.Code
                    r.label = r.Name
                    return r
                })
                setCarTypeList(Data)
            }
        })
        GetServicePoint(pointId).then(res => {
            if (!res.Error) setDetail(res.Result)
        })
        GetUnoccupiedCarList(pointId).then(res => {
            if (res && !res.Error) {
                setUnoccupiedCarList(res.Result.Data)
                // console.log(res.Result.Data)

            }
        })
    }, []);
    useEffect(() => {
        // console.log(disabled)
    }, [successSubmit])


    return (
        <div className='' style={ContainerWidth} >
            <PageHeader
                title={"新增技師"}
                onBack={() => {
                    if (currentStep === 0) {
                        navigate(-1)

                    } else {
                        setCurrentStep(currentStep - 1)
                        let pic = ["id_picture", "driving_license"]
                        if (submitData) {
                            let obj = {}
                            // pic.forEach(d=>{
                            //     if(submitData[d]){
                            //         obj[d]= submitData[d]
                            //     }
                            // })
                            // console.log(obj)

                            // setTimeout(() => SubmitForm.current.setFieldsValue(obj), 100)

                        }

                        // successSubmit
                    }
                    // navigate(`/points/${pointId}/add_technician`, { state :state_variable })
                    // navigate(`/points/${pointId}/add_technician`,{state:state_variable})
                }}
                className="site-page-header"
                breadcrumb={detail ? <BreadCrumb Info={{ [pointId]: detail.name }} /> : ''}
            >
            </PageHeader>
            <div className='step_block'>
                <Steps current={currentStep} style={{ padding: "16px 24px", borderBottom: "1px solid rgba(0, 0, 0, 0.06)", margin: "24px 24px 0 24px", background: "white", width: "calc(100% - 48px)" }}>
                    {steps.map(item => (
                        <Step key={item.title} title={item.title} description={item.description} style={{ width: item.width }} />
                    ))}
                </Steps>
                <Form
                    style={{ minHeight: "calc(100vh - 200px)", display: "flex", flexDirection: "column", justifyContent: "space-between" }}
                    name="basic"
                    layout="vertical"
                    ref={SubmitForm}
                    onFinish={onFinish}
                    initialValues={{
                        task_type: "1",
                        working_type: workType
                    }}
                // onFinishFailed={onFinishFailed}
                >

                    {
                        currentStep === 0 &&
                        (

                            <div className='step_content' >
                                <div style={{ display: "flex" }}>
                                    <Form.Item
                                        label="姓名"
                                        name="name"
                                        rules={[{ required: true, message: '請輸入姓名!' }]}
                                    >
                                        <Input allowClear placeholder='點擊選擇' style={{ width: "304px" }} />
                                    </Form.Item>

                                    <Form.Item
                                        label="身分證號"
                                        name="id_no"
                                        rules={[{ required: true, message: '請輸入身分證號！' },
                                        {
                                            pattern: /^[A-Z]{1}[1-2]{1}[0-9]{8}$/,
                                            message: "身分證號格式錯誤",
                                        }]}
                                        style={{ margin: "0 16px" }}
                                    >
                                        <Input allowClear placeholder='example' style={{ width: "304px" }} />
                                    </Form.Item>
                                    <div>
                                        <div style={{ height: 34 }}></div>
                                        <Form.Item
                                            label=""
                                            name="id_picture"
                                        >
                                            <Upload
                                                fileList={IdPic}
                                                maxCount={2}
                                                multiple={true}
                                                customRequest={({ onSuccess }) => onSuccess("ok")}
                                                onChange={({ file, fileList }) => {
                                                    setIdPic(fileList)
                                                    SubmitForm.current.setFieldsValue({ id_picture: fileList })
                                                }}
                                            >
                                                <Button icon={<UploadOutlined />}>上傳身分證正反面</Button>
                                            </Upload>
                                        </Form.Item>
                                    </div>
                                </div>
                                <div style={{ display: "flex" }}>
                                    <Form.Item
                                        label="出生日期"
                                        name="birthday"
                                        rules={[{ required: true, message: '請選擇出生日期!' }]}

                                    >
                                        <DatePicker
                                            style={{ width: "304px" }}
                                        // onChange={onChange} 
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label="到職日"
                                        name="take_office_date"
                                        rules={[{ required: true, message: '請選擇到職日！' }]}
                                        style={{ margin: '0 0 0 16px' }}
                                    >
                                        <DatePicker
                                            style={{ width: "304px" }}
                                        // onChange={onChange} 
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label="駕照效期"
                                        name="driving_license_expiration_date"
                                        rules={[{ required: true, message: '請選擇駕照效期！' }]}
                                        style={{ margin: '0 16px' }}
                                    >
                                        <DatePicker
                                            style={{ width: "304px" }}
                                        // onChange={onChange} 
                                        />
                                    </Form.Item>
                                    <div>
                                        <div style={{ height: 34 }}></div>
                                        <Form.Item
                                            label=""
                                            name="driving_license"
                                        >
                                            <Upload
                                                fileList={LicensePic}
                                                multiple={true}
                                                maxCount={2}
                                                customRequest={({ onSuccess }) => onSuccess("ok")}
                                                onChange={({ file, fileList }) => {
                                                    setLicensePic(fileList)
                                                    SubmitForm.current.setFieldsValue({ driving_license: fileList })
                                                }}
                                            >
                                                <Button icon={<UploadOutlined />}>上傳駕照正反面</Button>
                                            </Upload>
                                        </Form.Item>
                                    </div>
                                </div>
                                <div style={{ display: "flex", alignItems: "end" }}>
                                    <Form.Item
                                        label="手機號碼"
                                        name="mobile_phone_number"
                                        rules={[{ required: true, message: '請輸入手機號碼!' }, {
                                            pattern: /^[\d]{10}$/,
                                            message: "請輸入10位數之手機號碼",
                                        }]}
                                        hasFeedback={isValid !== null ? true : false}
                                        validateStatus={isValid === null ? '' : isValid ? 'success' : 'error'}

                                    >
                                        <Input allowClear placeholder='example' style={{ width: "304px" }}
                                            addonAfter={<EnterOutlined style={{ cursor: "pointer" }} onClick={(e) => checkMobile()} />}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label="地址"
                                        name="address_county"
                                        rules={[{ required: true, message: "請選擇地址!" }]}
                                        style={{ marginLeft: 16, marginRight: 16 }}
                                    >
                                        <CustomCascader
                                            defaultValue={submitData ? submitData.address_county : ''}
                                            style={{ width: "304px" }}
                                            OnChange={(val) => SubmitForm.current.setFieldsValue({ address_county: val })}
                                        />
                                    </Form.Item>
                                    <div>
                                        <div style={{ height: 30 }}></div>
                                        <Form.Item
                                            label=""
                                            name="address"
                                            rules={[{ required: true, message: "請輸入完整地址!" }]}
                                            style={{ marginRight: "16px" }}
                                        >
                                            <Input allowClear placeholder='請輸入完整地址' style={{ width: "304px" }} />
                                        </Form.Item>
                                    </div>
                                   
                                </div>
                                <div style={{ display: "flex", alignItems: "end" }}>
                                <Form.Item
                                        label="服務技能（可複選）"
                                        name="skill"
                                        rules={[{ required: true, message: '請選擇服務技能（可複選）!' }]}
                                        style={{marginRight:16}}
                                    >
                                        <Select
                                            mode="multiple"
                                            placeholder='點擊選擇'
                                            style={{ width: 304, }}
                                            // onChange={(val) => console.log(val)}
                                            options={carTypeList}
                                        />

                                    </Form.Item>
                                    <Form.Item
                                        label="出勤型態"
                                        name="working_type"
                                        rules={[{ required: true, message: '請選擇出勤型態！' }]}
                                    >
                                        <Radio.Group buttonStyle="solid" onChange={(e) => setWorkType(e.target.value)}>
                                            <Radio.Button value="1">正式</Radio.Button>
                                            <Radio.Button value="2">支援</Radio.Button>
                                        </Radio.Group>
                                    </Form.Item>
                                    <Form.Item
                                        label="接案類型"
                                        name="task_type"
                                        rules={[{ required: true, message: '請選擇接案類型！' }]}
                                        style={{ margin: '0 16px 24px' }}
                                    >
                                        <Radio.Group buttonStyle="solid">
                                            <Radio.Button value="1">預約</Radio.Button>
                                            <Radio.Button value="2">即時</Radio.Button>
                                        </Radio.Group>
                                    </Form.Item>

                                </div>
                                {
                                    workType === "1" && (
                                        <>
                                            <Form.Item
                                                label="常用車"
                                                name="plate_number"
                                                rules={[{ required: true, message: '請選擇常用車！' }]}
                                            >
                                                <Select
                                                    placeholder='點擊選擇'
                                                    style={{ width: 304 }}
                                                    // onChange={(val) => console.log(val)}

                                                >
                                                    {
                                                        UnoccupiedCarList.map(car => (<option key={car.row_id} value={car.no}>{car.no}</option>))
                                                    }

                                                </Select>
                                            </Form.Item>

                                            <Form.Item
                                                label='上班日'
                                                name='work'
                                                rules={[{ required: true, message: '請選擇上班日！' }]}
                                            >
                                                <ul className='custom_radio'>
                                                    {
                                                        work.map(w => (
                                                            <li
                                                                className={workSelected[w.key] ? 'active' : ''}
                                                                onClick={() => {
                                                                    let selected = { ...workSelected }
                                                                    if (selected[w.key]) {
                                                                        delete selected[w.key]
                                                                    } else {
                                                                        selected[w.key] = true
                                                                    }
                                                                    setWorkSelected(selected)
                                                                    let val = ''
                                                                    if (Object.keys(selected).length > 0) {
                                                                        val = selected
                                                                    } else {
                                                                        val = ''
                                                                    }
                                                                    SubmitForm.current.setFieldsValue({ work: val })
                                                                }}
                                                                key={w.title}>{w.day}</li>
                                                        ))
                                                    }
                                                </ul>
                                            </Form.Item>
                                        </>
                                    )
                                }


                                {/* {
                                    work.map(w => (<div style={{ display: "flex", paddingTop: 16, alignItems: "center" }} key={w.title}>
                                        <Form.Item
                                            label={w.title}
                                            name={w.key}
                                            rules={[{ required: true, message: '請選擇出勤時段！' }]}
                                        >
                                            <TimePicker.RangePicker
                                                style={{ width: 304 }}
                                                format={"HH:mm"}
                                                disabled={disabled[w.key]}
                                            />
                                        </Form.Item>
                                        <Checkbox
                                            checked={disabled[w.key]}
                                            style={{ marginLeft: 16 }}
                                            onChange={(e) => {
                                                let checked = e.target.checked
                                                let new_disabled = { ...disabled }
                                                new_disabled[w.key] = checked
                                                let value = checked ? [moment("0"), moment("0")] : []
                                                SubmitForm.current.setFieldsValue({ [w.key]: value })
                                                setDisabled(new_disabled)
                                            }}
                                        >不出勤</Checkbox>
                                    </div>))
                                } */}

                            </div>

                        )}
                    {
                        currentStep === 1 && (
                            <>
                                <div className='step_content' >
                                    <div style={{ display: "flex", alignItems: "end" }}>
                                        <Form.Item
                                            label="緊急聯絡人  - 1"
                                            name="emergency_contact1"
                                            rules={[{ required: true, message: '請輸入緊急聯絡人名字!' }]}
                                        >
                                            <Input prefix={<UserOutlined />} allowClear placeholder='請輸入名字' style={{ width: "304px" }} />
                                        </Form.Item>
                                        <Form.Item
                                            label=""
                                            name="emergency_contact1_tel"
                                            rules={[{ required: true, message: '請輸入緊急聯絡人電話' }]}
                                            style={{ margin: '0 16px 24px' }}
                                        >
                                            <Input prefix={<PhoneOutlined />} allowClear placeholder='請輸入電話' style={{ width: "304px" }} />
                                        </Form.Item>

                                    </div>
                                    <div style={{ display: "flex", alignItems: "end" }}>
                                        <Form.Item
                                            label="緊急聯絡人  - 2"
                                            name="emergency_contact2"
                                        >
                                            <Input prefix={<UserOutlined />} allowClear placeholder='請輸入名字' style={{ width: "304px" }} />
                                        </Form.Item>
                                        <Form.Item
                                            label=""
                                            name="emergency_contact2_tel"
                                            // rules={[{ required: true, message: '請輸入緊急聯絡人電話' }]}
                                            style={{ margin: '0 16px 24px' }}
                                        >
                                            <Input prefix={<PhoneOutlined />} allowClear placeholder='請輸入電話' style={{ width: "304px" }} />
                                        </Form.Item>

                                    </div>
                                </div>
                            </>
                        )
                    }
                    {
                        currentStep === 2 && (<div style={{ minHeight: "calc(100vh - 200px)", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>

                            <div className='step_content'>
                                <div style={{ fontSize: 30 }}><span style={{ color: "rgba(24, 144, 255, 1)", fontSize: 30 }}>#{successSubmit.id} / {successSubmit.name}</span> 已新增</div>

                                <TextArea
                                    placeholder="額外的據點內容說明請寫在此......"
                                    autoSize={{ minRows: 2, maxRows: 4 }}
                                    style={{ width: 395 }}
                                    value={remark}
                                    onChange={(e) => setRemark(e.target.value)}
                                />
                                <Button
                                    style={{ display: "block", marginTop: 8 }}
                                    disabled={remark !== "" ? "" : "disabled"}
                                    onClick={() => {
                                        let param = `row_id=${successSubmit.row_id}&note=${remark}`
                                        UpdateServicePointDriverNote(param).then(data => {
                                            if(data && !data.Error){
                                                setRemark("")
                                                SubmitForm.current.resetFields()
                                                message.success('已新增備註')
                                               
                                            }
                                            
                                        })
                                    }}
                                >新增備註</Button>
                                <div style={{ marginTop: 32 }}>
                                    <Button
                                        type="primary"
                                        icon={<PlusOutlined />}
                                        onClick={() => {
                                            setCurrentStep(0)
                                        }}
                                    >新增技師</Button>
                                    <Button
                                        icon={<RightOutlined />}
                                        style={{ margin: "0 8px" }}
                                        onClick={() => navigate(`/points/${pointId}/${successSubmit.row_id}`, { state })}
                                    >技師內容</Button>
                                    <Button
                                        icon={<UnorderedListOutlined />}
                                        onClick={() => navigate(`/points/${pointId}`, { state })}
                                    >回列表</Button>
                                </div>
                            </div>
                        </div>)
                    }
                    {
                        currentStep !== 2 && (<div style={{ background: "white", marginTop: 16, width: "calc(100%)", padding: "20px", display: "flex", justifyContent: "flex-end" }}>
                            <Button type="text" style={{ marginRight: 24 }} >
                                關閉
                            </Button>
                            <Button type="primary" icon={<ArrowRightOutlined />}
                                htmlType="submit"
                            >
                                下一步
                            </Button>

                        </div>)
                    }
                </Form>
            </div>
        </div>
    );
}

export default Technicianadd;


export const fetchCounty = (state) => {
    return{
        type: 'FETCH_COUNTY_TOWN_SUCCESS',
        data:state
    }
}
export const fetchCountyCode = (state) => {
  return{
      type: 'FETCH_COUNTY_TOWN',
      data:state
  }
}
 
export const fetchCases = (state) => {
  return{
      type: 'FETCH_CASES_SUCCESS',
      data:state
  }
}  

export const fetchCertainCase = (state) => {
  return{
      type: 'FETCH_CERTAIN_CASE',
      data:state
  }
}

export const fetchCarBrand = (state) => {
  return{
      type: 'FETCH_CAR_BRAND_SUCCESS',
      data:state
  }
} 
export const fetchCaseType = (state) => {
  return{
      type: 'FETCH_CASE_TYPE',
      data:state
  }
} 
export const fetchAllCustomerList = (state) => {
  return{
      type: 'FETCH_CUSTOMER_LIST',
      data:state
  }
} 

export const fetchCommonStationType = (state) => {
  return{
      type: 'FETCH_COMMON_STATION_TYPE',
      data:state
  }
} 
export const fetchExtraPriceItem = (state) => {
  return{
      type: 'FETCH_EXTRA_PRICE_ITEM',
      data:state
  }
} 



export const fetchCarType = (state) => {
  return{
      type: 'FETCH_CAR_TYPE',
      data:state
  }
} 
export const fetchTrailerType = (state) => {
  return{
      type: 'FETCH_TRAILER_TYPE',
      data:state
  }
} 

export const fetchUserInfo = (state) => {
  return{
      type: 'FETCH_USER_INFO',
      data:state
  }
} 



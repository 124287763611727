import React, { useState, useEffect, useRef } from "react";
import BreadCrumb from "../../shared/BreadCrumb";
import PricingMethod from './pricingMethod'
import CustomCard from "../../shared/component/Card"
import CustomCascader from "../../shared/component/CustomCascader";
import { PageHeader, Button, Card, Modal, Input, InputNumber, Drawer, Popconfirm, Form, Radio, Select, DatePicker, Upload, Statistic, Row, Tag, message, } from "antd";
import { UploadOutlined, CloseOutlined, EditOutlined, CopyOutlined } from "@ant-design/icons";
import { useParams, useNavigate, useLocation } from "react-router-dom"
import { CustomerPricingModuleDetail, UpdateCustomerPricingModuleDetail, UpdateStationPriceNote, ClosePriceModule } from '../../../api/Customer'
import { useSelector } from 'react-redux'
import moment from 'moment'
import LoadingBlock from "../../shared/LoadingBlock";

const { Option } = Select
const { TextArea } = Input
const { RangePicker } = DatePicker
const Detail = ({ ContainerWidth, pageTitle, propState ,permission}) => {
  const submitForm = useRef()
  let { customerId } = useParams();
  let CaseType = useSelector(state => state.caseType)
  let PriceModule = useSelector(state => state.PriceModule)
  const { state } = useLocation();
  const navigate = useNavigate();
  const [modalStatus, setModalStatus] = useState(false);
  const finalState = propState ? propState : state;
  const [drawerStatus, setDrawerStatus] = useState(false)
  const [fetchData, setFetchData] = useState(null)
  const [editRemark, setEditRemark] = useState(null)
  const [remarkContent, setRemarkContent] = useState(null)
  const [PayType, setPayType] = useState(null)
  const [durationValidation, setDurationValidation] = useState(null)

  const FetchApi = async () => {
    let detail = await CustomerPricingModuleDetail(state.id)
    // console.log(`https://qpass.emct.tw/api/v1/Price/GetPriceDoc?row_id=af36f571-a2db-47ba-a477-57b0a186e8ff`)
    if (!detail.Error) {
      let Detail = detail.Result
      let filter = CaseType.filter(c => c.Code === Detail.case_type)
      if (filter.length > 0) {
        Detail.case_type_text = `${filter[0]["Name"]}`
      }

      Detail.tax_included = Detail.tax_included.toString()
      Detail.mode_text = `${PriceModule[Detail.mode]}計價`
      let status_text = Detail.status === 1 ? "啟用" : Detail.status === 3 ? "已過期" : Detail.status === 2 ? "尚未開始" : "停用"
      Detail.valid = { type: "status", color: Detail.status === 1 ? '#52C41A' : '#D9D9D9', content: [status_text, `${moment(Detail.start_date).format('YYYY.MM.DD')} - ${moment(Detail.end_date).format('YYYY.MM.DD')}`] }
      Detail.receive_text = `${Detail.receive_mode === '01' ? '當月' : '隔月'} ${Detail.receive_day} 號`
      Detail.paid_text = `${Detail.pay_type === 'check' ? '支票' : '匯款'}， ${Detail.pay_type === 'check'? `${Detail.ticket_month}個月`:Detail.ticket_month}`
      setPayType(Detail.pay_type)
      Detail.tax_text = `${Detail.tax_month === '01' ? '帳款發生月份' : '請款月份'} / ${Detail.tax_included === "1" ? '含稅' : '未稅'}`
      Detail.settle_text = `${Detail.settlement_day}號`
      Detail.contractPeriod = [moment(Detail.start_date), moment(Detail.end_date)]
      Detail.remark = { type: "remark", content: Detail.note ? Detail.note : '' }
      Detail.customer = `${Detail.customer_id} - ${Detail.customer_name}`
      if (Detail.doc && Detail.doc !== "") {
        Detail.Doc = { type: "files", title: Detail.name, files: [Detail.doc], download: `${process.env.REACT_APP__API_HOST}/Price/GetPriceDoc?row_id=${Detail.row_id}` }
      } else {
        Detail.Doc = "-"
      }
      Detail.contractFile = [
        {
          uid: '1',
          name: Detail.doc,
          status: 'done',
        }]
      Detail.receipt_county = Detail.receipt_address_county ? [Detail.receipt_address_county, Detail.receipt_address_town] : ''
      Detail.ReceiptAddress = Detail.receipt_address_county ? `${Detail.receipt_address_county_name}${Detail.receipt_address_town_name}${Detail.receipt_address}` : Detail.receipt_address
      setFetchData(Detail)
    }
  }
  const EditData = async (val) => {
    if (val.contractFile.file) {
      val.doc = val.contractFile.file.originFileObj
    }
    val.start_date = moment(val.contractPeriod[0]).format("YYYY-MM-DD")
    val.end_date = moment(val.contractPeriod[1]).format("YYYY-MM-DD")
    val.note = fetchData.note
    val.row_id = fetchData.row_id
    val.receipt_address_county = val.receipt_county[0]
    val.receipt_address_town = val.receipt_county[1]
    const formdata = new FormData()
    for (let key in val) {
      formdata.append(key, val[key] ? val[key] : '');
    }
    UpdateCustomerPricingModuleDetail(formdata).then(result => {
      if (result.Result.Success) {
        FetchApi()
        setDrawerStatus(false)
        // submitForm.current.resetFields()
      }else{
        setDurationValidation(result.Result.ErrorMsg)
      }
    })
  }

  useEffect(() => {
    if (CaseType && PriceModule) {
      FetchApi()
    }
  }, [CaseType, PriceModule])

  return (
    <div className="container" style={ContainerWidth}>
      {
        fetchData !== null ? (
          <>
            <PageHeader
              style={{ background: "white", width: "100%" }}
              title={`${fetchData.id}`}

              tags={
                <Tag color={fetchData.status === 1 ? "green" : fetchData.status === 2 ? "orange" : fetchData.status === 3 ? "" : "red"}>
                  {fetchData.status === 1 ? "啟用" : fetchData.status === 3 ? "已過期" : fetchData.status === 2 ? "尚未開始" : "停用"}
                </Tag>
              }
              onBack={() =>
                propState
                  ? navigate(`/customer/lists/${state.customerId}/`, {
                    state: propState,
                  })
                  : navigate(`/customer/lists/${state.customerId}/details`, {
                    state,
                  })
              }
              className="site-page-header"
              breadcrumb={<BreadCrumb Info={state.BreadcrumbInfo}
                DetailText={fetchData !== null ? fetchData.name : ""} />}
              extra={permission !==2 ? [] :fetchData.status === 1 ? [
                // <Button
                //   key={"1"}
                //   icon={<UploadOutlined />}
                //   onClick={() =>
                //     navigate(`/customer/lists/${customerId}/add`, {
                //       state: {
                //         tab: state.tab,
                //         page: pagination.current,
                //         customerId: customerId,
                //         subTab: currentTab,
                //       },
                //     })
                //   }
                // >
                //   匯出報表
                // </Button>,
                <Button
                  key="複製並新增方案"
                  icon={<CopyOutlined />}
                  onClick={() => {
                    navigate(`/customer/lists/${customerId}/add`, {
                      state: {
                        ...state, data: {
                          row_id: fetchData.row_id,
                          tax_month: fetchData.tax_month,
                          receipt_no: fetchData.receipt_no,
                          receipt_title: fetchData.receipt_title,
                          company_head: fetchData.company_head,
                          receipt_county: fetchData.receipt_county,
                          receipt_address: fetchData.receipt_address,
                          receive_mode: fetchData.receive_mode,
                          receive_day: fetchData.receive_day,
                          settlement_day: fetchData.settlement_day,
                          pay_type: fetchData.pay_type,
                          ticket_month: fetchData.ticket_month,
                          tax_included: fetchData.tax_included,
                          tax_month: fetchData.tax_month,
                          mode: fetchData.mode,
                        }
                      }
                    })
                  }}
                >
                  複製並新增方案
                </Button>,
                <Button
                  key="編輯方案"
                  icon={<EditOutlined />}
                  onClick={() => setDrawerStatus(true)}
                >
                  編輯方案
                </Button>,
                <Popconfirm
                  placement="bottom"
                  title={"終止後該客戶將無法選用這個服務案型"}
                  // onCancel={ ()=> setPopStatus(false)}
                  onConfirm={() => {
                    let param = `row_id=${fetchData.row_id}`
                    ClosePriceModule(param).then(res => {
                      if (!res.Error) {
                        FetchApi()
                        message.success("已提前終止方案")
                      }
                    })
                  }}
                  style={{ marginRight: 12 }}
                  okText="終止"
                  showCancel={false}
                >
                  <Button key="提前終止方案" icon={<CloseOutlined />}>
                    提前終止方案
                  </Button>
                </Popconfirm>,
              ] : [
                // <Button
                //     key={"1"}
                //     icon={<UploadOutlined />}
                //     onClick={() =>
                //       navigate(`/customer/lists/${customerId}/add`, {
                //         state: {
                //           tab: state.tab,
                //           page: pagination.current,
                //           customerId: customerId,
                //           subTab: currentTab,
                //         },
                //       })
                //     }
                //   >
                //     匯出報表
                //   </Button>,
                <Button
                  key="複製並新增方案"
                  icon={<CopyOutlined />}
                  onClick={() => {
                    navigate(`/customer/lists/${customerId}/add`, {
                      state: {
                        ...state, data: {
                          row_id: fetchData.row_id,
                          tax_month: fetchData.tax_month,
                          receipt_no: fetchData.receipt_no,
                          receipt_title: fetchData.receipt_title,
                          company_head: fetchData.company_head,
                          receipt_address: fetchData.receipt_address,
                          receive_mode: fetchData.receive_mode,
                          receive_day: fetchData.receive_day,
                          settlement_day: fetchData.settlement_day,
                          pay_type: fetchData.pay_type,
                          ticket_month: fetchData.ticket_month,
                          tax_included: fetchData.tax_included,
                          mode: fetchData.mode
                        }
                      }
                    })
                  }}
                >
                  複製並新增方案
                </Button>,
                <Button
                  key="編輯方案"
                  icon={<EditOutlined />}
                  onClick={() => setDrawerStatus(true)}
                >
                  編輯方案
                </Button>,]}
            >
              <Row style={{ paddingTop: 6 }}>
                <Statistic title="成立" value={fetchData.create_time ? fetchData.create_time : '-'} />
                <Statistic
                  title="最初登打人員"
                  value={!fetchData.creator || fetchData.creator === "" ? '-' : fetchData.creator}
                  style={{
                    margin: "0 32px",
                    paddingLeft: 24,
                    borderLeft: "1px solid rgba(0, 0, 0, 0.03)",
                  }}
                />
              </Row>
            </PageHeader>
            <div style={{ padding: "24px 24px 0" }}>
              <Card
                style={{ width: "100%", marginBottom: 24 }}
                title="基本資訊"
              >
                <CustomCard
                  remarkStatus={(val) => setEditRemark(val)}
                  header={[
                    { key: "customer", name: "客戶別" },
                    { key: "Doc", name: "合約/報價單名稱" },
                    { key: "case_type_text", name: "服務案型" },
                    { key: "mode_text", name: "計價模式" },
                    { key: "receipt_no", name: "發票統編" },
                    { key: "receipt_title", name: "發票抬頭" },
                    { key: "valid", name: "報價生效期間" },
                    { key: "company_head", name: "公司負責人" },
                    { key: "ReceiptAddress", name: "紙本發票收件地址" },
                    { key: "receipt_email", name: "發票收件E-mail" },
                    { key: "receive_text", name: "請款日期" },
                    { key: "paid_text", name: "付款方式" },
                    { key: "tax_text", name: "發票設定", },
                    { key: "settle_text", name: "結算日期" },
                    { key: permission!==2?"note":"remark", name: "備註" },
                  ]}
                  data={fetchData}
                />
              </Card>
            </div>

            <PricingMethod mode={fetchData.mode} ParentData={fetchData} />
            <Modal
              title="變更密碼"
              visible={modalStatus}
              width={416}
              // onOk={()=> setIsModalVisible("1a")}
              onCancel={() => setModalStatus(false)}
            >
              <Input.Password placeholder="請輸入新密碼" />
              <Input.Password
                placeholder="再次輸入新密碼"
                style={{ marginTop: 24 }}
              />
            </Modal>

            <Drawer
              width={992}
              title="編輯方案"
              placement="right"
              closable={false}
              extra={
                <CloseOutlined
                  style={{ cursor: "pointer", color: "rgba(0, 0, 0, 0.45)" }}
                  onClick={() => setDrawerStatus(false)}
                />
              }
              visible={drawerStatus}
              footer={
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    type="text"
                    style={{ marginRight: 16 }}
                    onClick={() => {
                      submitForm.current.setFieldsValue(fetchData)
                      setDrawerStatus(null);
                    }}
                  > 取消 </Button>
                  <Button
                    type="primary"
                    onClick={() => submitForm.current.submit()}
                  > 更新 </Button>
                </div>
              }
            >
              <Form
                name="basic"
                layout="vertical"
                initialValues={fetchData}
                ref={submitForm}
                onFinish={EditData}
              >
                <div style={{ display: "flex" }}>
                  <Form.Item
                    label="合約名稱"
                    name="name"
                    rules={[{ required: true, message: '請輸入合約名稱!' }]}
                  >
                    <Input allowClear placeholder='請輸入合約名稱' style={{ width: "304px" }} />
                  </Form.Item>
                  <Form.Item
                    name="contractFile"
                    label="合約電子檔"
                    style={{ marginLeft: 16, marginBottom: 0 }}
                    extra="僅接受 jpg, png 並且小於 5mb 之檔案格式類型"
                    rules={[{ required: true, message: '請上傳名片！' }]}
                  >
                    <Upload name="logo"
                      accept="image/png, image/jpeg, image/jpg"
                      listType="picture"
                      customRequest={({ onSuccess }) => onSuccess("ok")}
                      onChange={({ file, fileList }) => {
                        submitForm.current.value = file
                      }}
                    >
                      <Button icon={<UploadOutlined />}>點擊上傳合約</Button>
                    </Upload>
                  </Form.Item>
                </div>
                <div style={{ display: "flex" }}>
                  <Form.Item
                    label="合約期間"
                    name="contractPeriod"
                    rules={[{ required: true, message: '請選擇合約期間！' }]}
                    style={{ marginRight: 16 }}

                  >
                    <RangePicker
                      style={{ width: "304px" }}
                    // defaultValue={[moment('2015/01/01', dateFormat), moment('2015/01/01', dateFormat)]}
                    // format={dateFormat}
                    />
                  </Form.Item>
                  <Form.Item
                    label="服務案型"
                    name="case_type"
                    rules={[{ required: true, message: '請選擇服務案型！' }]}
                    validateStatus={durationValidation === null ? "" : durationValidation ? "error" : "success"}
                        help={durationValidation == null ? '' : durationValidation ? durationValidation: ''}
                  >
                    <Select
                      // mode="multiple"
                      allowClear
                      style={{ width: "304px" }}
                      placeholder="請選擇該計價方案適用的服務類型"
                    // defaultValue={[]}
                    // onChange={handleChange}
                    >
                      {
                        CaseType.map(c => (<Option key={`${c.Code}${c.Name}`} value={c.Code}>{c.Name}</Option>))
                      }
                    </Select>
                  </Form.Item>
                </div>
                <div style={{ display: "flex" }}>
                  <Form.Item
                    label="發票統編"
                    name="receipt_no"
                    rules={[{ required: true, message: '請輸入公司統編！' },
                    {
                      pattern: /^[\d]{8}$/,
                      message: "請輸入8位數之公司統編",
                    }]}

                  >
                    <Input allowClear placeholder='例：12345678' style={{ width: "304px" }} />
                  </Form.Item>
                  <Form.Item
                    label="發票抬頭"
                    name="receipt_title"
                    rules={[{ required: true, message: '請輸入發票抬頭！' }]}

                  >
                    <Input allowClear placeholder='例：〇〇股份有限公司' style={{ width: "304px", margin: "0 16px" }} />
                  </Form.Item>
                  <Form.Item
                    label="公司負責人姓名"
                    name="company_head"
                    rules={[{ required: true, message: '請輸入公司負責人姓名！' }]}
                  >
                    <Input allowClear placeholder='例：〇〇〇' style={{ width: "304px" }} />
                  </Form.Item>

                </div>

                <div style={{ display: 'flex' }}>
                  <Form.Item
                    label="紙本發票收件地址"
                    name="receipt_county"
                    // rules={[{ required: true, message: "請選擇紙本發票收件地址!" }]}
                  >
                    <CustomCascader
                      defaultValue={fetchData ? fetchData.receipt_county : ""}
                      style={{ width: "304px" }}
                      OnChange={(val) => {
                        submitForm.current.setFieldsValue({ receipt_county: val })
                      }}
                    />
                  </Form.Item>
                  <div>
                    <div style={{ height: 34 }}></div>
                    <Form.Item
                      label=""
                      name="receipt_address"
                      style={{ margin: "0 16px" }}
                      // rules={[{ required: true, message: '請輸入公司地址！' }]}
                    >
                      <Input allowClear placeholder='請直接輸入地址' style={{ width: "304px" }} />
                    </Form.Item>
                  </div>
                  <Form.Item
                        label="發票收件E-mail"
                        name="receipt_email"
                      >
                        <Input allowClear placeholder='example' style={{ width: "304px" }} />
                      </Form.Item>
                </div>
                <div style={{ display: "flex" }}>
                  <Form.Item
                    label="請款日期"
                    name="receive_day"
                    rules={[{ required: true, message: '請輸入發票統編!' }]}
                  >
                    <InputNumber min={1} max={31} allowClear placeholder='請輸入數字，例：15' style={{ width: "304px" }}
                      addonAfter="號"
                      addonBefore='每月'
                    // addonBefore={<Form.Item name="receive_mode" noStyle>
                    //   <Select>
                    //     <Option value="01">每月</Option>
                    //   </Select>
                    // </Form.Item>} 
                    />
                  </Form.Item>
                  <Form.Item
                    label="結算日期"
                    name="settlement_day"
                    rules={[{ required: true, message: '請輸入結算日期!' }]}
                    style={{ margin: '0 16px' }}

                  >
                    <InputNumber min={1} max={31} allowClear placeholder='請輸入數字，例：15' style={{ width: "304px" }}
                      addonAfter="號" addonBefore="每月" />
                  </Form.Item>
                </div>
                <div style={{ display: "flex" }}>
                  <Form.Item
                    label="付款方式"
                    name="ticket_month"
                    rules={[{ required: true, message: '請輸入付款方式!' }]}
                  >
                    <Input
                    allowClear 
                    placeholder={PayType === 'remit' ? '請輸入匯款備註' : '請輸入數字，例：15'}
                    style={{ width: "304px" }}
                      addonBefore={<Form.Item name="pay_type" noStyle>
                        <Select onChange={(val) => setPayType(val)}>
                          <Option value="check">支票</Option>
                          <Option value="remit">匯款</Option>
                        </Select>
                      </Form.Item>} addonAfter={PayType === "check" ? "個月支票" : false} />
                  </Form.Item>
                  <Form.Item
                    label="發票設定"
                    name="tax_month"
                    rules={[{ required: true, message: '請選擇發票設定!' }]}
                    style={{ margin: '0 16px' }}

                  >
                    <Radio.Group buttonStyle="solid" >
                      <Radio.Button value="01">帳款發生月份</Radio.Button>
                      <Radio.Button value="02">請款月份</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item
                    label=""
                    name="tax_included"
                  >
                    <Radio.Group buttonStyle="solid">
                      <div style={{ height: 34 }}></div>
                      <Radio.Button value="1">含稅</Radio.Button>
                      <Radio.Button value="0">未稅</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </div>

              </Form>
            </Drawer>
            <Modal title="請輸入備註"
              visible={editRemark === 1 ? true : false}
              width={416}
              onOk={() => {
                let param = `row_id=${fetchData.row_id}&note=${remarkContent}`
                UpdateStationPriceNote(param)
                  .then(res => {
                    if (res.Result.Success) {
                      setEditRemark(null)
                      setRemarkContent(null)
                      FetchApi()
                    }
                  })
              }}
              onCancel={() => setEditRemark(null)}
            >
              <TextArea
                defaultValue={fetchData.note}
                placeholder="額外的備註請寫在此......"
                style={{ width: 368 }}
                onKeyUp={(e) => setRemarkContent(e.target.value)}
              />
            </Modal>
          </>
        ) : (<div style={{ padding: 24, height: '100%' }}>
          <LoadingBlock />
        </div>)
      }
    </div>
  );
};

export default Detail;

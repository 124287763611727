import service from './Setting'

//取得行銷方案列表
export const GetMarketingList = (param) => service.get(`/Marketing/ListMarketing?${param}`);
//取得行銷方案資料
export const GetMarketing = (id) => service.get(`/Marketing/GetMarketing?row_id=${id}`);
//新增行銷方案
export const AddMarketing = (param) => service.get(`/Marketing/AddMarketing?${param}`);
//更新行銷方案備註
export const UpdateMarketingNote = (param) => service.get(`/Marketing/UpdateMarketingNote?${param}`);
//更新行銷方案狀態
export const UpdateMarketingStatus = (param) => service.get(`/Marketing/UpdateMarketingStatus?${param}`);

// UpdateStationPriceNote

//取得行銷方案適用對象列表
export const GetMarketingTargetList = (page,page_size,id) => service.get(`/Marketing/ListTarget?page_size=${page_size}&page_index=${page}&marketing_row_id=${id}`);
//新增行銷方案適用對象
export const AddMarketingTarget = (param) => service.get(`/Marketing/AddTarget?${param}`);

//刪除行銷方案適用對象
export const DeleteMarketingTarget = (id) => service.get(`/Marketing/DeleteTarget?row_id=${id}`);
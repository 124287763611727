import React, { useState, useEffect, useRef } from 'react'
import BreadCrumb from '../../shared/BreadCrumb'
import CustomTable from '../../shared/component/Card'
import { PageHeader, Tabs, Button, Card, Table, Tag, Modal, Input, Popconfirm, message, Drawer, Form, Select, TimePicker, Checkbox, Skeleton, InputNumber } from 'antd'
import { PlusOutlined, KeyOutlined, PauseCircleOutlined, EditOutlined, CloseOutlined, EnterOutlined, PlayCircleOutlined } from '@ant-design/icons'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import moment from "moment"
import { GetStationHistory, UpdateCustomerStationRemark, UpdateCustomerStation, UpdateStationReceiptInfo, GetStationDetail, GetStationEmployee, UpdateCustomerStationStatus, CheckStationName, GetDriver } from '../../../api/Customer'
import { FilterConty } from '../../shared/util/FilterFunc'
import { FilterHistory } from '../../shared/util/FilterHistory'
import CustomCascader from '../../shared/component/CustomCascader'
import { useSelector, useDispatch } from 'react-redux'
import { fetchCarType } from '../../../actions/iniFetch'
import { GetCarTypeList } from '../../../api/Code'
import { UpdatePassowrdByUserId } from '../../../api/Member'
const { TabPane } = Tabs
const { Option } = Select
const { TextArea } = Input
const Detail = ({ ContainerWidth, pageTitle, propState, permission }) => {
    let { customerId } = useParams()
    let CarType = useSelector(state => state.CarType), dispatch = useDispatch()
    const { state } = useLocation()
    const navigate = useNavigate()
    const editRef = useRef(), resetPasswordForm = useRef()
    const [pagination, setPagination] = useState({ current: 1, pageSize: 10 })
    const [modalStatus, setModalStatus] = useState(false)
    const [popStatus, setPopStatus] = useState(false)
    const [editDrawer, setEditDrawer] = useState(false)
    const finalState = propState ? propState : state
    const [editRemark, setEditRemark] = useState(null)
    const [remarkContent, setRemarkContent] = useState(null)
    const [coperationStatus, setCoperationStatus] = useState(null)
    const [historyRecord, setHistoryRecord] = useState(null)
    const format = "HH:mm"
    const [currentTab, setCurrentTab] = useState(finalState.subTab ? finalState.subTab : "1")
    const [fetchData, setFetchData] = useState(null)
    const [EmployeeList, setEmployeeList] = useState(null)
    const [checkboxStatus, setCheckboxStatus] = useState(null)
    const [StationValidation, setStationValidation] = useState(null)
    const [PayType, setPayType] = useState("check")
    const [passwordError, setPasswordError] = useState(null)
    const [Invalidation, setInvalidation] = useState(null)
    const [Drivers, setDrivers] = useState([])
    const CheckStation = async (name) => {
        let status = false
        if (name === fetchData.name) {
            status = true

        } else {
            let res = await CheckStationName(name, customerId)
            status = res.Result
        }
        setStationValidation(status)
        return status
    }
    const onClose = () => {
        setPayType(fetchData.pay_type)
        setEditDrawer(false)

    }
    const checkOperatingHour = (e, type) => {
        let isChecked = e
        let val = isChecked ? [moment("0"), moment("0")] : []
        let new_checkboxStatus = { ...checkboxStatus }
        new_checkboxStatus[type] = e
        setCheckboxStatus(new_checkboxStatus)
        editRef.current.setFieldsValue({ [type]: val })
    }
    const fetchApi = async () => {
        let id = state.stationId ? state.stationId : state.id
        let res = await GetStationDetail(id)
        //歷程
        let history = await GetStationHistory(`page_size=-1&page_index=0&row_id=${id}`)
        let history_list = []
        if (!history.Error) {
            let History = (history.Result.Data).reverse()
            history_list = await FilterHistory(History)
        }
        setHistoryRecord(history_list)
        let EmployeeRes = await GetStationEmployee(id, 0, -1)
        if (!EmployeeRes.Error) {
            let Employees = EmployeeRes.Result
            let Data = Employees.Data.map(e => {
                let roles = [
                    { key: "role_keyin", name: "可上單" },
                    { key: "role_payment", name: "請款窗口" },
                    { key: "role_handover", name: "交接車人" },
                ]
                let role_content = []
                roles.forEach(r => {
                    if (e[r.key] === 1) {
                        role_content.push(r.name)
                    }
                })
                e.Roles = role_content
                return e
            })
            Employees.Data = Data
            setEmployeeList(Employees)
        }
        if (!res.Error) {
            let detail = res.Result
            setCoperationStatus(detail.status)
            detail && Object.keys(detail).forEach(key => {
                if (detail[key] === null) detail[key] = ""
            })
            if (detail.receipt_address_county_name !== "") {
                detail.receipt_full_address = `${detail.receipt_address_county_name}${detail.receipt_address_town_name}${detail.receipt_address}`
                detail.receipt_address_county = [detail.receipt_address_county, detail.receipt_address_town]
            } else {
                detail.receipt_full_address = detail.receipt_address
                detail.receipt_address_county = ""
            }
            detail.remark = { type: "remark", content: detail.note }
            let townName = await FilterConty(detail.town)
            detail.full_add = `${townName}${detail.address}`
            detail.work_status = { type: "status", color: detail.status === 1 ? '#52C41A' : '#D9D9D9', content: detail.status === 1 ? ['配合中'] : ["取消"] }

            detail.no_accept_cars = !detail.no_accept_car ? [] : detail.no_accept_car.map(car => car.id)
            detail.no_accept_car_arr = { type: 'tag', content: detail.no_accept_car ? detail.no_accept_car.map(car => car.name) : [] }
            // receive_mode 請款方式 1:當月，2:隔月
            detail.receive_text = `${detail.receive_mode === "01" ? '當月' : '隔月'} ${detail.receive_day} 號`
            if (detail.pay_type === "check") {
                detail.payment_text = `支票 ，${detail.pay_day} 個月支票`
            } else {
                // detail.payment_text = `匯款 ，每月 ${detail.pay_day} 號`
                detail.payment_text = `匯款 ，${detail.pay_day}`
            }
            detail.password = detail.password ? detail.password : detail.receipt_no
            detail.address_county = [detail.county, detail.town]
            let operating_key = ["normal", 'weekend', 'holiday']
            let obj = {}
            operating_key.forEach(o => {
                let start = `${o}_start_time`
                let end = `${o}_end_time`
                let valid_start = moment(detail[start], "HH:mm", true).isValid();
                let valid_end = moment(detail[end], "HH:mm", true).isValid();
                if (valid_end && valid_start) {
                    detail[o] = [moment(detail[start] ? detail[start] : "00:00", format), moment(detail[end] ? detail[end] : "00:00", format)]
                }


                if (detail[start] === "00:00" && detail[end] === "00:00") {
                    obj[o] = true
                } else {
                    obj[o] = false
                }
            })

            setCheckboxStatus(obj)
            detail.operation = { type: "operatingHour", content: [`一般：${detail.normal_start_time}~${detail.normal_end_time}`, `週末：${detail.weekend_start_time}~${detail.weekend_end_time}`, `假日：${detail.holiday_start_time}~${detail.holiday_end_time}`] }
            setPayType(detail.pay_type)
            setFetchData(detail)
        }
    }
    const onSubmitEdit = async (value) => {
        let new_data = { ...fetchData }
        for (let key in value) {
            new_data[key] = value[key]
        }
        let check = new_data.name === value.name ? true : await CheckStation(new_data.name)
        if (editDrawer) check = true
        if (check) {
            delete new_data.update_uid
            if (editDrawer === 1) {
                let operating_key = { ...checkboxStatus }
                Object.keys(operating_key).map(o => {
                    if (value[o]) {
                        let open_key = `${o}_open`
                        let start_key = `${o}_start_time`
                        let end_key = `${o}_end_time`
                        new_data[open_key] = operating_key[o] ? '0' : '1'
                        new_data[start_key] = value[o][0].format(format)
                        new_data[end_key] = value[o][1].format(format)
                    }

                })
                let no_accept_car_text = ""
                if (new_data.no_accept_cars) {
                    new_data.no_accept_cars.map((car, i) => no_accept_car_text += i === 0 ? car : `,${car}`)
                }

                new_data.no_accept_car = no_accept_car_text
                new_data.county = value.address_county[0]
                new_data.town = value.address_county[1]
            }
            let receipt_address = value.receipt_address_county ? value.receipt_address_county : new_data.receipt_address_county
            let obj = {
                row_id: new_data.row_id,
                name: new_data.name,
                county: new_data.county,
                town: new_data.town,
                address: new_data.address,
                tel: new_data.tel,
                service_item: new_data.service_item,
                dealer: new_data.dealer,
                no_accept_car: new_data.no_accept_car,
                normal_start_time: new_data.normal_start_time,
                normal_end_time: new_data.normal_end_time,
                normal_open: new_data.normal_open ? new_data.normal_open : "0",
                weekend_open: new_data.weekend_open ? new_data.weekend_open : "0",
                weekend_start_time: new_data.weekend_start_time,
                weekend_end_time: new_data.weekend_end_time,
                holiday_start_time: new_data.holiday_start_time,
                holiday_end_time: new_data.holiday_end_time,
                holiday_open: new_data.holiday_open ? new_data.holiday_open : "0",
                receipt_no: new_data.receipt_no,
                receipt_title: new_data.receipt_title,
                receipt_address_county: receipt_address[0],
                receipt_address_town: receipt_address[1],
                receipt_address: new_data.receipt_address,
                receive_mode: new_data.receive_mode,
                receive_day: new_data.receive_day,
                pay_day: new_data.pay_day,
                pay_type: new_data.pay_type,
                note: new_data.note,
                receipt_email: new_data.receipt_email,
                assign_driver_row_id:new_data.assign_driver_row_id
            }
            if (editDrawer === 1) {
                // let param = ''
                // Object.keys(obj).map((key, i) => {
                //     param += i === 0 ? `${key}=${obj[key]}` : `&${key}=${obj[key]}`
                // })

                UpdateCustomerStation(obj).then(res => {
                    if (res && !res.Error) {
                        fetchApi()
                        setEditDrawer(false)
                        setInvalidation(null)
                    } else {
                        setInvalidation(res.Error.Message)
                    }
                })
            } else {
                UpdateStationReceiptInfo(obj).then(res => {
                    console.log(res)
                    if (res && !res.Error) {
                        fetchApi()
                        setEditDrawer(false)
                        setInvalidation(null)

                    }
                })
            }

        }
    }
    const ChangeCoperationStatus = async () => {
        let status = coperationStatus === 0 ? 1 : 0
        let param = `row_id=${fetchData.row_id}&status=${status}`
        let res = await UpdateCustomerStationStatus(param)
        if (res.Result.Success) {
            setPopStatus(true)
            message.success("配合狀態已變更")
            fetchApi()
        }
    }

    useEffect(() => {
        GetDriver('').then(res => {
            console.log(res)
            if (res && !res.Error) setDrivers(res.Result)

        })
        fetchApi()
    }, [])
    useEffect(() => {
        if (!CarType) {
            GetCarTypeList().then(res => {
                if (res && !res.Error) {
                    dispatch(fetchCarType(res.Result))
                }
            })
        }
    }, [CarType])
    return (
        <div className='container' style={ContainerWidth}>
            <div style={{ height: "calc(100%)" }}>
                <PageHeader
                    style={{ background: "white", width: "100%" }}
                    title={fetchData !== null && `${fetchData.user_id} - ${fetchData.name}`}
                    onBack={() => propState ? navigate(`/customer/lists/${state.customerId}/`, { state: propState }) : navigate(`/customer/lists/${state.customerId}/details`, { state: state })}
                    className="site-page-header"
                    breadcrumb={<BreadCrumb
                        Info={state.BreadcrumbInfo}
                        DetailText={state && state.DetailText ? state.DetailText : fetchData !== null ? fetchData.name : ""}
                    />}
                    extra={permission !== 2 ? [] : currentTab === "1" && coperationStatus === 1 ? [
                        <Button key={"變更密碼"} icon={<KeyOutlined />} onClick={() => setModalStatus(true)}>變更密碼</Button>,
                        <Popconfirm
                            placement="bottom"
                            title={"取消配合後，將無法上單"}
                            onConfirm={() => ChangeCoperationStatus()}
                            style={{ marginRight: 12 }}
                            okText="好，取消配合"
                            showCancel={false}
                        >
                            <Button key={"配合狀態變更"} icon={<PauseCircleOutlined />}>配合狀態變更</Button>
                        </Popconfirm>
                    ] : currentTab === "1" && coperationStatus === 0 ? [
                        <Button key={"變更密碼"} icon={<KeyOutlined />} onClick={() => setModalStatus(true)}>變更密碼</Button>,
                        <Popconfirm
                            placement="bottom"
                            title={"復原後將可再次作為運送點"}
                            onConfirm={() => ChangeCoperationStatus()}
                            style={{ marginRight: 12 }}
                            okText="重啟配合"
                            // cancelText="請假"
                            showCancel={false}
                        >
                            <Button key={"重新啟用"} icon={<PlayCircleOutlined />} type="primary">重新啟用</Button>
                        </Popconfirm>
                    ] : [<Button key={"新增人員"} type="primary" icon={<PlusOutlined />}
                        onClick={() => {

                            navigate(`/customer/lists/${customerId}/add`, {
                                state: {
                                    ...state,
                                    tab: state.tab,
                                    page: pagination.current,
                                    customerId: customerId,
                                    stationId: fetchData.row_id,
                                    subTab: currentTab,
                                }
                            })
                        }}>
                        新增人員</Button>]}
                    footer={
                        <Tabs defaultActiveKey={currentTab} onTabClick={(value) => setCurrentTab(value)} >
                            <TabPane tab={"基本資訊"} key="1" />
                            <TabPane tab={"人員管理"} key="2" />
                        </Tabs>
                    }
                >
                </PageHeader>

                {
                    currentTab === "1" ? (<>
                        {fetchData !== null ? (
                            <div className='split_block' style={{ display: 'grid', gridTemplateColumns: "repeat(2, 1fr)", height: "calc(100% - 102px)" }}>
                                <div style={{ padding: 24 }}>
                                    <Card
                                        style={{ width: "100%", marginBottom: 24 }}
                                        title="基本資訊"
                                        extra={
                                            permission !== 2 ? false :
                                                <Button
                                                    type="text"
                                                    style={{ paddingRight: 0 }}
                                                    onClick={() => {

                                                        setEditDrawer(1)
                                                        setTimeout(() => {
                                                            editRef.current.setFieldsValue(fetchData)
                                                        }, 100)
                                                    }}
                                                >
                                                    修改
                                                    <EditOutlined style={{ color: "rgba(24, 144, 255, 1)" }} />
                                                </Button>
                                        }
                                    >
                                        <CustomTable
                                            remarkStatus={(val) => setEditRemark(val)}
                                            header={[
                                                { key: "user_id", name: "代號" },
                                                { key: "name", name: "名稱" },
                                                { key: "full_add", name: "地址" },
                                                { key: "tel", name: "聯絡電話" },
                                                // { key: "service_item_text", name: "服務項目" },
                                                { key: "work_status", name: "配合狀態" },
                                                { key: "dealer", name: "經銷商" },
                                                { key: "no_accept_car_arr", name: "不接受車種" },
                                                { key: "operation", name: "營業時間" },
                                                { key: permission !== 2 ? 'note' : "remark", name: "備註" },
                                                { key: "assign_driver_name", name: "指定技師" },

                                            ]} data={fetchData} />
                                    </Card>
                                    {

                                    }
                                    <Card
                                        style={{ width: "100%", marginBottom: 24 }}
                                        title="運送點請款資料"
                                        extra={
                                            permission !== 2 ? false :
                                                <Button
                                                    type="text"
                                                    style={{ paddingRight: 0 }}
                                                    onClick={() => {
                                                        setEditDrawer(2)
                                                        setTimeout(() => {
                                                            editRef.current.setFieldsValue(fetchData)
                                                        }, 100)
                                                    }}

                                                >
                                                    修改
                                                    <EditOutlined style={{ color: "rgba(24, 144, 255, 1)" }} />
                                                </Button>
                                        }
                                    >
                                        <CustomTable
                                            header={[
                                                {
                                                    key: "receipt_no",
                                                    name: "發票統編"
                                                },
                                                {
                                                    key: "receipt_title",
                                                    name: "發票抬頭"
                                                },
                                                {
                                                    key: "receipt_full_address",
                                                    name: "紙本發票收件地址"
                                                },
                                                {
                                                    key: "receipt_email",
                                                    name: "發票收件E-mail"
                                                },
                                                {
                                                    key: "receive_text",
                                                    name: "請款日期"
                                                },
                                                {
                                                    key: "payment_text",
                                                    name: "付款方式"
                                                }
                                            ]} data={fetchData} />
                                    </Card>
                                    <Card
                                        style={{ width: "100%", marginBottom: 24 }}
                                        title="帳號資訊"
                                    >
                                        <CustomTable
                                            header={[
                                                { key: "user_id", name: "帳號" },
                                                { key: "password", name: "密碼" },
                                            ]} data={fetchData} />
                                    </Card>
                                </div>
                                <div style={{ padding: 32, background: "rgba(250, 250, 250, 1)" }}>
                                    <div
                                        style={{
                                            fontWeight: 500,
                                            padding: "16px",
                                            borderBottom: "1px solid rgba(240, 240, 240, 1)",
                                        }}
                                    >歷程</div>
                                    <ul style={{ padding: 32, margin: 0 }}>
                                        {
                                            historyRecord && historyRecord.map(h => (<li
                                                key={h.log_text}
                                                style={{
                                                    fontSize: 14,
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    color: "rgba(0, 0, 0, 0.25)",
                                                    marginBottom: 18,
                                                }}
                                            >
                                                <p>
                                                    {
                                                        h.text.map(text => (<span style={text.style}>{text.title}</span>))
                                                    }
                                                </p>
                                                <p style={{ color: "rgba(0, 0, 0, 0.25)" }}> {h.date}</p>
                                            </li>))
                                        }
                                    </ul>
                                </div>
                            </div>
                        ) : (<div style={{ margin: 24, background: "white", padding: 16 }}>
                            <Skeleton active style={{ marginBottom: 8 }} />
                            <Skeleton active style={{ marginBottom: 8 }} />
                            <Skeleton active style={{ marginBottom: 8 }} />
                            <Skeleton active style={{ marginBottom: 8 }} />
                        </div>)}
                    </>


                    ) : (<>
                        <div className='table_block' style={{ margin: 24 }}>
                            {
                                EmployeeList && (<Table
                                    columns={[
                                        { title: '中文姓名', dataIndex: 'name_cht' },
                                        { title: '職稱', dataIndex: 'job_title' },
                                        { title: '聯絡電話', dataIndex: 'tel' },
                                        { title: 'E-mail', dataIndex: 'email' },
                                        {
                                            title: '任職狀態', dataIndex: 'status', width: '10%',
                                            render: status => (<div style={{ display: "flex", alignItems: "center" }}>
                                                <div style={{ width: 6, height: 6, borderRadius: "50%", background: status === 1 ? "rgba(82, 196, 26, 1)" : "rgba(217, 217, 217, 1)", marginRight: 8 }}></div>
                                                <p> {status === 1 ? "在職" : "離職"}</p></div>)
                                        },
                                        { title: '身份標籤', dataIndex: 'Roles', render: label => label.length === 0 ? '-' : label.map(l => (<Tag>{l}</Tag>)) }]}
                                    rowKey={record => record.row_id}
                                    dataSource={EmployeeList.Data}
                                    pagination={false}
                                    // pagination={dataLists[currentTab].length > 10 ? pagination : false}
                                    // loading={loading}
                                    // style={{minHeight: 400}}
                                    onRow={(record, rowIndex) => {
                                        return {
                                            onClick: event => {
                                                navigate(`/customer/lists/${customerId}/details/${record.row_id}`,
                                                    {
                                                        state: {
                                                            ...state,
                                                            subTab: currentTab,
                                                            subPage: pagination.current,
                                                            stationId: record.station_row_id,
                                                            id: record.row_id,
                                                            DetailText: fetchData !== null ? fetchData.name : ""
                                                        }
                                                    }
                                                )
                                            },
                                        };
                                    }}

                                />)
                            }

                        </div>
                    </>)
                }
            </div>
            <Modal title="變更密碼"
                visible={modalStatus}
                width={416}
                onOk={() => resetPasswordForm.current.submit()}
                onCancel={() => setModalStatus(false)}
            >
                <Form
                    ref={resetPasswordForm}
                    name="basic"
                    layout='vertical'
                    onFinish={(value) => {
                        if (value.password !== value.comfirm_password) {
                            setPasswordError(true)
                        } else {
                            value.user_id = fetchData.user_id
                            UpdatePassowrdByUserId(value).then(res => {
                                if (!res.Error) {
                                    fetchApi()
                                    setPasswordError(null)
                                    setModalStatus(false)
                                    resetPasswordForm.current.resetFields()
                                }
                            })
                        }
                    }}
                >
                    <Form.Item
                        label=""
                        name="password"
                        validateStatus={passwordError ? "error" : ""}
                        rules={[{ required: true, message: "請輸入新密碼!" }]}
                    >
                        <Input.Password
                            allowClear
                            placeholder="請輸入新密碼"
                        />
                    </Form.Item>
                    <Form.Item
                        label=""
                        name="comfirm_password"
                        rules={[{ required: true, message: "再次輸入新密碼!" }]}
                        validateStatus={passwordError ? "error" : ""}
                        help={passwordError ? "新密碼與再次輸入新密碼不一致" : ""}
                    >
                        <Input.Password
                            allowClear
                            placeholder="再次輸入新密碼"
                        />
                    </Form.Item>
                </Form>

            </Modal>
            <Drawer
                width={672}
                title={`編輯${editDrawer === 1 ? "基本資訊" : "運送點請款資料"}`}
                placement="right"
                closable={false}
                extra={<CloseOutlined
                    style={{ cursor: "pointer", color: "rgba(0, 0, 0, 0.45)" }}
                    onClick={() => onClose()}
                />}
                visible={editDrawer}
                footer={
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button type="text" style={{ marginRight: 16 }} onClick={() => onClose()}>取消</Button>
                        <Button type="primary"
                            onClick={() => editRef.current.submit()}>保存</Button>
                    </div>
                }
            >

                {
                    fetchData !== null && (
                        <Form
                            ref={editRef}
                            name="basic"
                            layout='vertical'
                            initialValues={fetchData}
                            onFinish={onSubmitEdit}
                            autoComplete="off"
                        >
                            {
                                editDrawer === 1 && (<>
                                    <div style={{ display: "flex" }}>
                                        <Form.Item
                                            name="name"
                                            label="運送點名稱"
                                            style={{ marginBottom: 0 }}
                                            rules={[{ required: true, message: '請輸入運送點名稱!' }]}
                                            hasFeedback
                                            validateStatus={StationValidation == null ? "" : StationValidation ? "success" : "error"}
                                            help={StationValidation == null || StationValidation ? '輸入名稱後點擊右側檢核紐' : '此運送點名稱已存在'}
                                        >
                                            <Input
                                                onKeyUp={() => setStationValidation(null)}
                                                placeholder='請輸入運送點名稱'
                                                addonAfter={<EnterOutlined
                                                    tyle={{ cursor: "pointer" }}
                                                    onClick={() => CheckStation(editRef.current.getFieldValue("name"))} />} style={{ width: "304px" }} />
                                        </Form.Item>
                                        <Form.Item
                                            label="聯絡電話"
                                            name="tel"
                                            style={{ margin: '0 16px' }}
                                            rules={[{ required: true, message: '請輸入聯絡電話!' }]}
                                        >
                                            <Input allowClear placeholder='example' style={{ width: "304px" }} />
                                        </Form.Item>
                                    </div>

                                    <div style={{ display: "flex" }}>
                                        <Form.Item
                                            label="運送點地址"
                                            name="address_county"
                                            rules={[{ required: true, message: '請輸入運送點地址!' }]}
                                            style={{ marginRight: 16 }}
                                            hasFeedback={Invalidation ? true : false}
                                            help={Invalidation ? Invalidation : ""}
                                            validateStatus={Invalidation === null ? "" : !Invalidation ? 'success' : 'error'}
                                        >
                                            <CustomCascader
                                                style={{ width: "304px" }}
                                                defaultValue={fetchData.address_county}
                                                OnChange={(val) => editRef.current.setFieldsValue({ address_county: val })}
                                            />
                                        </Form.Item>
                                        <div >
                                            <div style={{ height: 34 }}></div>
                                            <Form.Item
                                                label=""
                                                name="address"
                                                rules={[{ required: true, message: '請輸入完整地址!' }]}
                                                validateStatus={Invalidation === null ? "" : !Invalidation ? 'success' : 'error'}

                                            >
                                                <Input allowClear placeholder='點擊輸入地址' style={{ width: "304px" }} />
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <Form.Item
                                        label="經銷商"
                                        name="dealer"
                                    >
                                        <Input allowClear placeholder='例：台澳北區' style={{ width: "304px" }} />
                                    </Form.Item>
                                    <div style={{ display: "flex", gridGap: 16 }}>
                                        <Form.Item
                                            label={<><span>不可接受之載運車</span><span style={{ paddingLeft: 4, color: "rgba(0, 0, 0, 0.45)", fontSize: 14 }}>(可複選)</span></>}
                                            name="no_accept_cars"
                                        >
                                            <Select
                                                mode="multiple"
                                                placeholder="請選擇不可接受之載運車款"
                                                style={{ width: "304px" }}
                                            >
                                                {
                                                    CarType && CarType.map(car => (<Option key={`${car.Code}_${car.Name}`} value={car.Code}>{car.Name}</Option>))
                                                }
                                            </Select>
                                        </Form.Item>
                                        <Form.Item
                                            label={<><span>指定技師</span><span style={{ paddingLeft: 4, color: "rgba(0, 0, 0, 0.45)", fontSize: 14 }}>(系統會優先媒合給該技師)</span></>}
                                            name="assign_driver_row_id"
                                        >
                                            <Select

                                                placeholder="請選擇指定技師"
                                                style={{ width: "304px" }}
                                                showSearch
                                                allowClear
                                                filterOption={(input, option) => {
                                                    return (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
                                                }
                                                }
                                            >
                                                {
                                                    Drivers && Drivers.map(d => (<Option key={d.driver_row_id} value={d.driver_row_id}>{d.driver_name}</Option>))
                                                }
                                            </Select>
                                        </Form.Item>
                                    </div>
                                    <div style={{ fontSize: 16, fontWeight: 700 }}>營業時間</div>
                                    <div style={{ background: "rgba(250, 250, 250, 1)", padding: " 0 16px" }}>
                                        <div style={{ display: "flex", paddingTop: 16, alignItems: "center" }}>
                                            <Form.Item
                                                label="一般日"
                                                name="normal"
                                                rules={[{ required: true, message: '請選擇一般日之營業時間！' }]}
                                            >
                                                <TimePicker.RangePicker
                                                    format={"HH:mm"}
                                                    disabled={checkboxStatus["normal"]}
                                                    style={{ width: 400 }} />
                                            </Form.Item>
                                            <Checkbox
                                                style={{ marginLeft: 8 }}
                                                onChange={(e) => checkOperatingHour(e.target.checked, "normal")}
                                                checked={checkboxStatus["normal"]}
                                            >不營業</Checkbox>
                                        </div>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <Form.Item
                                                label="週末"
                                                name="weekend"
                                                rules={[{ required: true, message: '請選擇週末之營業時間！' }]}
                                            >
                                                <TimePicker.RangePicker
                                                    format={"HH:mm"}
                                                    disabled={checkboxStatus["weekend"]}
                                                    style={{ width: 400 }} />
                                            </Form.Item>
                                            <Checkbox
                                                style={{ marginLeft: 8 }}
                                                onChange={(e) => checkOperatingHour(e.target.checked, "weekend")}
                                                checked={checkboxStatus["weekend"]}

                                            >不營業</Checkbox>
                                        </div>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <Form.Item
                                                label="假日"
                                                name="holiday"
                                                rules={[{ required: true, message: '請選擇假日之營業時間！' }]}

                                            >
                                                <TimePicker.RangePicker
                                                    format={"HH:mm"}
                                                    disabled={checkboxStatus["holiday"]}
                                                    style={{ width: 400 }} />
                                            </Form.Item>
                                            <Checkbox
                                                checked={checkboxStatus["holiday"]}
                                                style={{ marginLeft: 8 }}
                                                onChange={(e) => checkOperatingHour(e.target.checked, "holiday")}
                                            >不營業</Checkbox>
                                        </div>
                                    </div>
                                </>)
                            }
                            {
                                editDrawer === 2 && (<>
                                    <div style={{ display: "flex" }}>
                                        <Form.Item
                                            label="發票統編"
                                            name="receipt_no"
                                            rules={[{ required: true, message: '請輸入公司統編！' },
                                            {
                                                pattern: /^[\d]{8}$/,
                                                message: "請輸入8位數之公司統編",
                                            }]}
                                        >
                                            <Input placeholder='example' style={{ width: 304, marginRight: 16 }} />
                                        </Form.Item>
                                        <Form.Item
                                            label="發票抬頭"
                                            name="receipt_title"
                                            rules={[{ required: true, }]}
                                        >
                                            <Input placeholder='example' style={{ width: 304 }} />
                                        </Form.Item>
                                    </div>
                                    <div style={{ display: 'flex' }}>
                                        <Form.Item
                                            label="紙本發票收件地址"
                                            name="receipt_address_county"
                                        // rules={[{ required: true, message: "請輸入聯絡地址！!" }]}
                                        >
                                            <CustomCascader
                                                defaultValue={fetchData.receipt_address_county}
                                                style={{ width: "304px" }}
                                                OnChange={(val) => {
                                                    editRef.current.setFieldsValue({ receipt_address_county: val })
                                                }}
                                            />
                                        </Form.Item>
                                        <div>
                                            <div style={{ height: 34 }}></div>
                                            <Form.Item
                                                label=""
                                                name="receipt_address"
                                                style={{ margin: "0 16px" }}

                                            // rules={[{ required: true, message: '請輸入地址！' }]}
                                            >
                                                <Input allowClear placeholder='請直接輸入地址' style={{ width: "304px" }} />
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <Form.Item
                                        label="發票收件E-mail"
                                        name="receipt_email"
                                    >
                                        <Input allowClear placeholder='example' style={{ width: "304px" }} />
                                    </Form.Item>
                                    <div style={{ display: "flex" }}>
                                        <Form.Item
                                            label="請款日期"
                                            name="receive_day"
                                            rules={[{ required: true, message: '請選擇請款日期!' }]}
                                        >
                                            <Input
                                                placeholder='請輸入數字，例：15'
                                                style={{ width: "304px" }}
                                                addonAfter="號"
                                                addonBefore='每月'
                                            // addonBefore={<Form.Item name="receive_mode" noStyle>
                                            //     <Select>
                                            //         <Option value="01">每月</Option>
                                            //     </Select>
                                            // </Form.Item>}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            label="付款方式"
                                            name="pay_day"
                                            style={{ margin: '0 16px' }}
                                            rules={[{ required: true, message: '請輸入付款方式!' }]}
                                        >
                                            <Input allowClear placeholder={editRef.current && editRef.current.getFieldValue("pay_type") === 'remit' ? '請輸入匯款備註' : '請輸入數字，例：15'} style={{ width: "304px" }}
                                                addonBefore={<Form.Item name="pay_type" noStyle

                                                >
                                                    <Select onChange={(val) => setPayType(val)}>
                                                        <Option value="check">支票</Option>
                                                        <Option value="remit">匯款</Option>
                                                    </Select>
                                                </Form.Item>} addonAfter={PayType === "check" ? "個月支票" : false} />
                                        </Form.Item>

                                    </div>
                                </>)
                            }
                        </Form>
                    )
                }

            </Drawer>
            {
                fetchData && (<Modal title="請輸入備註"
                    visible={editRemark === 1 ? true : false}
                    width={416}
                    onOk={() => {
                        let param = `row_id=${fetchData.row_id}&note=${remarkContent}`
                        UpdateCustomerStationRemark(param)
                            .then(res => {
                                if (res.Result.Success) {
                                    setEditRemark(null)
                                    fetchApi()
                                }
                            })
                    }}
                    onCancel={() => setEditRemark(null)}
                >
                    <TextArea
                        defaultValue={fetchData.note}
                        placeholder="額外的備註請寫在此......"
                        style={{ width: 368 }}
                        value={remarkContent}
                        onKeyUp={(e) => setRemarkContent(e.target.value)}
                    />
                </Modal>)
            }
        </div>
    );
}

export default Detail;
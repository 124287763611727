import React, { useState, useEffect, useRef } from 'react'
import BreadCrumb from '../../components/shared/BreadCrumb'
import CustomCard from '../../components/shared/component/Card'
import LoadingBlock from '../../components/shared/LoadingBlock'
import HistoryRecord from '../../components/shared/component/HistoryRecord'
import { PageHeader, Card, Button, Tag, Popconfirm, Drawer, Input, Form,Select,Modal ,DatePicker} from 'antd'
import { EditOutlined, MailOutlined, RightOutlined, CheckCircleOutlined, CloseCircleOutlined, PauseCircleOutlined, DeleteOutlined, DownloadOutlined, PlayCircleOutlined, CloseOutlined } from '@ant-design/icons'
import { UserDetail, UserHistory, DelAccount, DisabledAccount, EnableAccount,AllPrivilegeTag,EditAccount } from '../../api/Member'
import { useParams, useNavigate,useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RouteName } from '../../util/route'
import { DownloadFile } from '../../util/download'
const { TextArea } = Input
const { RangePicker } = DatePicker
const AccountDetail = ({ ContainerWidth, pageTitle }) => {
  let { accountId } = useParams()
  const navigate = useNavigate()
  const { state,pathname } = useLocation()
  const routeId = RouteName(pathname)
  let UserInfo = useSelector(state => state.UserInfo) 
  let UserPermission = UserInfo? UserInfo.permission.route: null
  const [data, setData] = useState(null)
  const [history, setHistory] = useState(null)
  const [editDrawer, setEditDrawer] = useState(false)
  const [downloadModal, setDownloadModal] = useState(false)
  const submitRef = useRef(),downloadRange=useRef()
  const [tagOption, setTagOption] = useState([])
  
  const FetchApi = () => {
    UserDetail(accountId).then(res => {
      if (res && !res.Error) {
        let result = res.Result
        result.Status = { type: "status", color: result.status === 1 ? '#52C41A' : 'rgba(217, 217, 217, 1)', content: result.status === 1 ? ['使用中'] : ['停用'] }
        result.Email = { type: "icons", title: '', label: result.mail, link: "mailto", icon: <MailOutlined /> }
        result.Tag = { type: "icons", title: result.permission_tag_name, 
        navigate: `/privilege/tags/${result.permission_tag_id}`, icon: <RightOutlined /> }
        result.remark =result.note
        setData(result)
      }
    })
    UserHistory(accountId).then(res => {
      setHistory(res.Result)
    })
  }
  const changeStatus = async (type) => {
    if (type === "delete") {
      DelAccount(data.id).then(res => {
        if (!res.Error) navigate("/privilege/account/")
      })
    } else {
      let res
      if (type === "disabled") {
        res = await DisabledAccount(data.id)
      } else {
        res = await EnableAccount(data.id)
      }
      if (!res.Error) {
        FetchApi()
      }
    }
  }

  useEffect(() => {
    AllPrivilegeTag().then(res => {
      if (res && !res.Error) {
          let result = res.Result
              .map(r => {
                  r.value = r.tag_id
                  r.label = r.tag_name
                  return r
              })
          // result.unshift({ value: 'add', label: "+  新增權限標籤" })
          setTagOption(result)
      }
  })
    FetchApi()
  }, [])
  return (
    <div className="container" style={ContainerWidth}>
      <div style={{ height: "calc(100%)" }}>
        {data ? (
          <>
            <PageHeader
              style={{ background: "white", width: "100%" }}
              title={`${data.account} / ${data.name}`}
              onBack={() => navigate(-1)}
              className="site-page-header"
              tags={
                <Tag
                  icon={
                    data.status === 1 ? (
                      <CheckCircleOutlined />
                    ) : (
                      <CloseCircleOutlined />
                    )
                  }
                  color={data.status === 1 ? "success" : "error"}
                >
                  {data["Status"]["content"][0]}
                </Tag>
              }
              breadcrumb={<BreadCrumb Info={{ [accountId]: data.name }} />}
              extra={
                UserPermission && UserPermission[routeId] !== 2
                  ? []
                  : data.status === 1
                  ? [
                      <Popconfirm
                        placement="bottom"
                        title="停用後將無法使用該帳號登入，日後可再次啟用"
                        onConfirm={() => changeStatus("disabled")}
                        style={{ marginRight: 12 }}
                        okText="確定停用"
                        showCancel={false}
                      >
                        <Button key="帳號停用" icon={<PauseCircleOutlined />}>
                          帳號停用
                        </Button>
                      </Popconfirm>,
                      <Popconfirm
                        placement="bottom"
                        title="刪除後資料將無法復原，確定刪除嗎？"
                        onConfirm={() => changeStatus("delete")}
                        style={{ marginRight: 12 }}
                        okText="確定刪除"
                        showCancel={false}
                      >
                        <Button key="帳號刪除" icon={<DeleteOutlined />}>
                          帳號刪除
                        </Button>
                      </Popconfirm>,
                      <Button
                        key="下載使用紀錄"
                        icon={<DownloadOutlined />}
                        onClick={() => setDownloadModal(true)}
                      >
                        下載使用紀錄
                      </Button>,
                    ]
                  : [
                      <Popconfirm
                        placement="bottom"
                        title="啟用後可以使用該帳號登入，日後可再次停用"
                        onConfirm={() => changeStatus("enable")}
                        style={{ marginRight: 12 }}
                        okText="確定停用"
                        showCancel={false}
                      >
                        <Button key="帳號啟用" icon={<PlayCircleOutlined />}>
                          帳號啟用
                        </Button>
                      </Popconfirm>,
                      <Popconfirm
                        placement="bottom"
                        title="刪除後資料將無法復原，確定刪除嗎？"
                        onConfirm={() => changeStatus("delete")}
                        style={{ marginRight: 12 }}
                        okText="確定刪除"
                        showCancel={false}
                      >
                        <Button key="帳號刪除" icon={<DeleteOutlined />}>
                          帳號刪除
                        </Button>
                      </Popconfirm>,
                      <Button
                        key="下載使用紀錄"
                        icon={<DownloadOutlined />}
                        onClick={() => {
                          // setEditDrawer(true)
                          // setTimeout(() => {
                          //     submitForm.current.setFieldsValue(fetchData)
                          // }, 200)
                        }}
                      >
                        下載使用紀錄
                      </Button>,
                    ]
              }
            ></PageHeader>
            <div
              className="split_block"
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)",
                height: "calc(100% - 102px)",
              }}
            >
              <div className="content_block" style={{ padding: 24 }}>
                <Card
                  style={{ width: "100%", marginBottom: 24 }}
                  title="帳號資訊"
                  extra={
                    UserPermission && UserPermission[routeId] !== 2 ? (
                      false
                    ) : (
                      <Button
                        type="text"
                        style={{ paddingRight: 0 }}
                        onClick={() => {
                          setEditDrawer(true);
                          setTimeout(
                            () => submitRef.current.setFieldsValue(data),
                            200
                          );
                        }}
                      >
                        修改
                        <EditOutlined
                          style={{ color: "rgba(24, 144, 255, 1)" }}
                        />
                      </Button>
                    )
                  }
                >
                  {data !== null && (
                    <CustomCard
                      header={[
                        { key: "account", name: "帳號" },
                        { key: "password_un", name: "密碼" },
                        { key: "name", name: "姓名" },
                        { key: "Email", name: "E-mail" },
                        { key: "Tag", name: "權限標籤" },
                        { key: "Status", name: "帳號狀態" },
                        { key: "note", name: "備註" },
                      ]}
                      data={data}
                    />
                  )}
                </Card>
              </div>
              <HistoryRecord detail={data} History={history} />
            </div>
          </>
        ) : (
          <div style={{ padding: 24, height: "100%" }}>
            <LoadingBlock />
          </div>
        )}
      </div>
      <Drawer
        title="編輯 - 基本資訊"
        placement="right"
        closable={false}
        width={400}
        extra={
          <CloseOutlined
            style={{ cursor: "pointer", color: "rgba(0, 0, 0, 0.45)" }}
            onClick={() => setEditDrawer(false)}
          />
        }
        visible={editDrawer}
        footer={
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              type="text"
              style={{ marginRight: 16 }}
              onClick={() => {
                setEditDrawer(false);
              }}
            >
              取消
            </Button>
            <Button type="primary" onClick={() => submitRef.current.submit()}>
              保存
            </Button>
          </div>
        }
      >
        {data && (
          <Form
            ref={submitRef}
            name="basic"
            layout="vertical"
            initialValues={data}
            onFinish={(val) => {
              val.password = val.password_un;
              val.tag_id = val.permission_tag_id;
              val.email = val.mail;
              val.id = data.id;
              val.note = val.note?val.note:null
              EditAccount(val).then((res) => {
                if (!res.Error) {
                  FetchApi();
                  setEditDrawer(false);
                }
              });
            }}
            autoComplete="off"
          >
            <Form.Item
              label="姓名"
              name="name"
              rules={[{ required: true, message: "請輸入姓名!" }]}
            >
              <Input placeholder="請輸入姓名" />
            </Form.Item>

            <Form.Item
              label="密碼"
              name="password_un"
              rules={[
                // { pattern: new RegExp(/^(?=.*?[A-Za-z])(?=.*?[0-9]).{0,10}$/), message: '密碼格式錯誤!' },
                { required: true, message: "請輸入密碼!" },
              ]}
            >
              <Input placeholder="請輸入密碼" />
            </Form.Item>

            <Form.Item
              label="E-mail"
              name="mail"
              rules={[
                {
                  pattern: new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g),
                  message: "E-mail格式錯誤!",
                },
                { required: true, message: "請輸入電子信箱!" },
              ]}
            >
              <Input placeholder="請輸入E-mail" />
            </Form.Item>
            <Form.Item
              label="權限標籤"
              name="permission_tag_id"
              rules={[{ required: true, message: "請選擇權限標籤 !" }]}
            >
              <Select
                showSearch
                placeholder="僅可單選"
                optionFilterProp="children"
                onChange={(val) => {
                  if (val === "add") {
                    submitRef.current.setFieldsValue({ permission_tag_id: "" });
                    // showPromiseConfirm()
                  }
                }}
                filterOption={(input, option) => {
                  if (option.value !== "add") {
                    return (option?.label ?? "").includes(input);
                  } else {
                    return option;
                  }
                }}
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                options={tagOption}
              />
            </Form.Item>
            <Form.Item label="備註" name="note">
              <TextArea
                autoSize={{
                  minRows: 3,
                  maxRows: 6,
                }}
                placeholder=""
              />
            </Form.Item>
          </Form>
        )}
      </Drawer>
      <Modal
        title="請輸入起迄時間"
        visible={downloadModal}
        width={416}
        okText="下載"
        cancelText="關閉"
        onOk={() => {
          downloadRange.current.submit()
        }}
        onCancel={() => {
          setDownloadModal(false);
          // deleteTagForm.current.resetFields()
        }}
      >
        <Form
          ref={downloadRange}
          name="basic"
          layout="vertical"
          onFinish={(val) => {
            DownloadFile(`User/GetUserLogInOutExcel?user_row_id=${accountId}&start_time=${val.range[0].format("YYYY-MM-DD")}&end_time=${val.range[1].format("YYYY-MM-DD")}`)
            downloadRange.current.resetFields()
            setDownloadModal(false)
          }}
        >
          <Form.Item
            label=""
            name="range"
            rules={[{ required: true, message: "請輸入起迄時間!" }]}
          >
             <RangePicker style={{ width: "100%" }} />
          </Form.Item>
        </Form>
       
      </Modal>
    </div>
  );
}

export default AccountDetail;
import React, { useState, useEffect } from 'react'
import { Table, Tag, Button, Drawer } from 'antd'
import { UserOutlined, FileAddOutlined, CloseOutlined,CloseCircleTwoTone, FullscreenOutlined } from '@ant-design/icons'
import { StatusObj } from '../../../util/status'
import { GetOfficialDriverList, GetDriverExecutionList, DispatchCase, ChangeDispatchCase,DispatchCaseFullManual,ManualChangeDispatchCase } from '../../../api/Case'
import Driver from '../../shared/Dispatch/Driver'
import FullRoute from '../../shared/Dispatch/FullRoute'
import Route from '../../shared/Dispatch/Route'

// import Ful from '../../shared/Dispatch/Route'

const Manualdispatch = ({ carData, caseId, visible, SetVisible, CaseStatus, reasonOption }) => {
  let [selectedTechnician, setSelectedTechnician] = useState(null)
  let [selectedRoute, setSelectedRoute] = useState(null)
  let [selectedCar, setSelectedCar] = useState(carData)
  const [btnDisabled, setBtnDisabled] = useState(true)
  const [Reason, setReason] = useState({})
  const [manualCarList, setManualCarList]= useState(null)
  const [loading, setLoading] = useState(null)
  const columnsCar = [
    { title: '廠牌 / 車型', dataIndex: 'vehicle_model_name', key: 'vehicle_model_name' },
    {
      title: '動力狀況', dataIndex: 'power_status', key: 'mobility',
      render: (mobility, index) => (<Tag color={mobility === 1 ? "green" : "red"}> {mobility === 1 ? "正常" : "不可行駛"}</Tag>)
    },
    { title: '起運點', dataIndex: 'origin', key: 'startingPoint' },
    { title: '迄運點', dataIndex: 'destination', key: 'endingPoint' },
    {
      title: '案件狀態', dataIndex: 'case_status', key: 'case_status',
      render: (r) => (<Tag key={r} color={StatusObj(r).tagColor}>{StatusObj(r).text}</Tag>)
    }

  ];
  
  // useEffect(() => {
  //   let param = ''
  //   selectedCar.forEach((s, i) => {
  //     param += i === 0 ? `wait_match_case_list[${i}]=${s.case_row_id}` : `&wait_match_case_list[${i}]=${s.case_row_id}`
  //   })
  //   GetOfficialDriverList(param).then(res => {
  //     if (res && !res.Error) {
  //       setTechnicianList({ ...technicianList, official: res.Result })
  //     }
  //   })
  // }, [selectedCar]);
  // useEffect(() => {
  //   if (selectedRoute) {
  //     if (visible === 1) {
  //       setBtnDisabled(false)
  //     } else {
  //       setBtnDisabled(true)
  //     }
  //   } else {
  //     setBtnDisabled(true)
  //   }
  // }, [selectedRoute])
  const onDispatch = async () => {
    // visible 1:手動派遣、7:改派
    let filter = selectedCar.filter(car => car.case_row_id !== carData[0]['case_row_id'])
    setLoading(true)
    setBtnDisabled(true)
    let caseSorts = []
    let obj = {
      current_case_info_row_id: carData[0]['case_row_id'],
      wait_match_case_list: filter.map(d => d.case_row_id),
      driver_row_id: selectedTechnician[0]["driver_row_id"],
    }
    if(selectedTechnician[0]['working_type'] === 1){
      //正式
      obj={...obj, ...selectedRoute[0]}
    }else{
      obj={...obj, action_type:'add',is_add_last:true,car_info_row_id:selectedTechnician[0]['car_info_row_id']}

    }
    
    if(manualCarList){
        manualCarList.forEach(route=>{
          route.car.forEach(c=>{
            caseSorts.push({
              case_info_row_id:c.case_info_row_id,
              case_action:c.case_action,
              case_sort:route.key
            })
          })
        })
        obj.caseSorts=caseSorts
    }else{
      delete obj.caseSorts
    }
    // console.log(visible)
    // console.log(obj)
    // return false
    // DispatchCase
    if (visible === 1) {
      let res = manualCarList? await DispatchCaseFullManual(obj): await DispatchCase(obj)
      if (res && !res.Error) {
        if (res.Result.is_success) {
          SetVisible('success')
        }
      }
    } else {
      // 改派
      let success = []
      let value = {
        driver_row_id: selectedTechnician[0]['driver_row_id'],
        ...Reason
      }
      if(selectedTechnician[0]['working_type'] === 1){
        //正式
        obj={...obj, ...selectedRoute[0]}
      }else{
        obj={...obj, action_type:'add',is_add_last:true,car_info_row_id:selectedTechnician[0]['car_info_row_id']}
      }
      if(manualCarList){
        value.current_case_info_row_id = carData[0]['case_row_id']
        value.caseSorts = caseSorts
      }else{
        value.wait_match_case_list = selectedCar.map(car => car.case_row_id)
      }
      // console.log(value)
      let fetch = manualCarList? await ManualChangeDispatchCase(value) :await ChangeDispatchCase(value)
      if (fetch && !fetch.Error) {
        success.push(1)
      } else {
        success.push(0)
      }
     
      let filter = success.filter(s => s === 0)
      if (filter.length === 0) {
        SetVisible('success')
      }
    }
  }
  return (
    <>
      <Drawer
        title={visible === 1 ? "手動派遣" : "改派他人"}
        placement="right"
        width={"1280px"}
        closable={false}
        visible={visible}
        extra={<CloseOutlined
          style={{ cursor: "pointer", color: "rgba(0, 0, 0, 0.45)" }}
          onClick={() => SetVisible(null)} />
        }
        footer={
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button type="text" style={{ marginRight: 16 }} onClick={() => {
              SetVisible(null)
              setSelectedRoute(null)
              setSelectedTechnician(null)
            }
            }>取消</Button>
            <Button type="primary"
              disabled={btnDisabled}
              onClick={() => {
                onDispatch()
              }}>{visible === 1 ? "派遣" : '改派'}{loading?"中...":""}</Button>
          </div>
        }
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <p style={titleStyle}>運送車輛</p>
          <Button onClick={() => SetVisible(6)} icon={<FileAddOutlined />}>新增車輛</Button>
        </div>
        <Table
          rowKey={record => record.key}
          dataSource={selectedCar}
          columns={columnsCar}
          pagination={false}
          style={{ margin: "8px 0 24px" }}
        />
        <Driver 
        type="official"
          SetVisible={val=> SetVisible(val)}
          visible={visible}
          selectedCar={selectedCar}
          selectedTechnician={selectedTechnician}
          SetSelectedTechnician={(val,type) => {
            setSelectedTechnician(type?[val]:val)
            setManualCarList(type?type:null)
          }} 

          />
          {
            console.log(manualCarList)
          }
        {
          selectedTechnician  && (
            <>
              {
                manualCarList?(
                  <FullRoute
                    type="official"
                    visible={visible}
                    reasonOption={reasonOption}
                    selectedCar={selectedCar}
                    selectedTechnician={selectedTechnician}
                    SetselectedTechnician={(val)=>{
                      setSelectedTechnician(val)
                    }}
                    selectedDriver={(val) => setSelectedTechnician(val)}
                    setBtnDisabled={(val)=>{
                      setBtnDisabled(val)
                    }}
                    setReason={(val)=>{
                      setReason(val)
                      // console.log(val)
                    }}
                    manualCarList={manualCarList}
                    selectedRoute={selectedRoute}
                    setSelectedRoute={(val)=> setSelectedRoute(val)}
                  />
                ):(
                  <Route
                    type="official"
                    visible={visible}
                    reasonOption={reasonOption}
                    selectedCar={selectedCar}
                    selectedTechnician={selectedTechnician}
                    SetselectedTechnician={(val)=>{
                      setSelectedTechnician(val)
                    }}
                    selectedDriver={(val) => setSelectedTechnician(val)}
                    setBtnDisabled={(val)=>{
                      setBtnDisabled(val)
                    }}
                    setReason={(val)=>{
                      setReason(val)}}
                    manualCarList={manualCarList}
                    selectedRoute={selectedRoute}
                    setSelectedRoute={(val)=> setSelectedRoute(val)}
                  />
                )
              }
            </>
           
          )
        }
      </Drawer>
    </>
  );
}

const titleStyle = {
  fontSize: 16,
  fontWeight: 500,
}
export default Manualdispatch;

import React, { useState, useEffect } from 'react';
import { Route, Routes, BrowserRouter as Router, Navigate } from 'react-router-dom'
import Header from './components/shared/Header'
import Login from './pages/member/login'
import ForgetPW from './pages/member/forget_pw'
import ResetPW from './pages/member/reset_pw'
import Cases from './pages/cases/index'
import AddCases from './pages/cases/add'
import VerifyCase from './pages/cases/verify'
import CasesDetail from './pages/cases/case'
import Execution from './pages/execution/index'
import Execute from './pages/execution/execute'
import Points from './pages/points/index'
import Customers from './pages/customers/customerList/index'
import CustomersAdd from './pages/customers/customerList/customerAdd'
import CustomerDetail from './pages/customers/customerList/customerDetail'
import EmployeeDetail from './components/customers/customer/employeeDetail'
import CustomerAllDetails from './pages/customers/customerList/Details'
import CustomerRelatedAdd from './pages/customers/customerList/add'
import CustomerServicePoints from './pages/customers/servicePoints/index'
import CustomerServicePointsAdd from './pages/customers/servicePoints/servicePointsAdd'
import CustomerServicePointDetail from './pages/customers/servicePoints/detail'
import PromotionList from "./pages/customers/promotion/index"
import PromotionAdd from "./pages/customers/promotion/Add"
import PromotionDetail from './pages/customers/promotion/PromotionDetail'
import AdvertisementList from "./pages/customers/advertisement/index"
import AdvertisementDetail from "./pages/customers/advertisement/AdvertisementDetail"
import AdvertisementAdd from "./pages/customers/advertisement/Add"
import AddPoint from './pages/points/add'
import PointDetail from './pages/points/point'
import TechnicianDetail from './pages/points/technicianDetail'
import TechnicianHealth from './pages/points/technicianHealth';
import CarDetail from './pages/points/carDetail'
import TechnicianAdd from './pages/points/technicianAdd'
import CarAdd from './pages/points/carAdd'
import OilSearch from './pages/fuel/search'
import OilSearchDetail from "./pages/fuel/searchDetail"
import OilEvaluation from "./pages/fuel/evaluation"
import OilRecord from "./pages/fuel/record"
import OilRecordDetail from "./pages/fuel/recordDetail"
import Statement from './pages/statement/Index'
import Notifications from "./pages/notifications/index"
import Account from './pages/privilege/Account'
import AccountDetail from './pages/privilege/AccountDetail'
import PrivilegeTags from './pages/privilege/Tag'
import PrivilegeTagsAdd from './pages/privilege/TagAdd'
import PrivilegeTagDetails from './pages/privilege/TagDetail'
import AccountAdd from './pages/privilege/AccountAdd'
import Error from './pages/ErrorPage'
import MemberInfo from './pages/member/Info'
import Lease from './pages/rent/index'
import LeaseInfo from './pages/rent/Info'
import LeaseAdd from './pages/rent/Add'
import "antd/dist/antd.css"
// import { notifyChanges } from "./actions/notification"
import { fetchCounty, fetchCountyCode, fetchCarBrand, fetchCaseType, fetchAllCustomerList, fetchCommonStationType, fetchExtraPriceItem } from './actions/iniFetch'
import { useDispatch, useSelector } from 'react-redux'
import { GetCountyList, GetSelectedTownList, GetCarBrandList, GetSelectedCarModelList, GetCaseType, GetAllCustomerList, GetCommonStationType, GetAllPriceItem } from './api/Code'
import { GetUserInfo,PrivilegeTagDetail } from './api/Member';
import { fetchUserInfo } from './actions/iniFetch';

function App({ history }) {
  const dispatch = useDispatch()
  // const nagivate = useNavigate()
  const [token, setToken] = useState(false)
  const [containerWidth, setContainerWidth] = useState("208px")
  const [pageLoading, setPageLoading] = useState(false)
  const [header, setHeader] = useState(false)
  const location = window.location.pathname
  const Container = {
    boxSizing: 'border-box',
    marginLeft: containerWidth,
    marginTop: '48px',
    background: '#F0F2F5',
    height: '100%',
    overflow: 'scroll',
  }
  let user = localStorage.getItem("User")? JSON.parse(localStorage.getItem("User")) : localStorage.getItem("User");
  const ProtectedRoute = ({ token, children, config }) => {
    let user = localStorage.getItem("User")? JSON.parse(localStorage.getItem("User")) : null;
    if(config.restricted){
      if(!user){
        window.location.href='/tms/user/login'
      }
    }
    return children
  }
  const fetchAPI = async () => {
    if(user && !user.permission){
      localStorage.removeItem("User")
      window.location.reload()
    }else{
      let userInfo = await GetUserInfo()
      if(userInfo && !userInfo.Error){
        let Result = userInfo.Result
        // console.log(Result)
        let tag =  await PrivilegeTagDetail(Result.permission_tag_id)
        if(tag && !tag.Error){
          let obj={route:{},notification:{}}
          let permission_list = tag.Result.permission_info.forEach(p=>{
            let type = p.p_type ===1?'route':'notification'
            obj[type][p.p_code] = p.p_status
          })
          dispatch(fetchUserInfo({...Result, permission:obj, permission_list}))
        }
       
      }
    }
    //取得縣市清單
    const countyResponse = await GetCountyList()
    let county = []
    let reformCounty = []
    if (countyResponse.Result !== null) {
      county = countyResponse.Result
    }
    for (let i = 0; i < county.length; i++) {
      let townFetch = await GetSelectedTownList(county[i]["Code"])
      let Town = townFetch.Result
      Town = Town.map(t => {
        t.label = t.Name
        t.value = t.Code
        return t
      })
      let obj = {
        label: county[i]["Name"],
        value: county[i]["Code"],
        children: Town
      }
      reformCounty.push(obj)
    }
    const options = [
      {
        value: '1',
        label: '指定區域',
        children: reformCounty,
      }
    ]
    dispatch(fetchCounty(options))
    dispatch(fetchCountyCode(options))
    
    //取得所有客戶清單
    // let CustomerList = await GetCustomerList(0,-1)
    // console.log(CustomerList)


    //取得所有車輛廠牌清單
    const FetchCarBrand = await GetCarBrandList()
    if (!FetchCarBrand.Result.Error) {
      let reformCarBrand = []
      let carBrand = FetchCarBrand.Result
      let Cars = []
      for (let i = 0; i < carBrand.length; i++) {
        let carBrandId = carBrand[i]["Code"]
        let carBrandName = carBrand[i]["Name"]
        const FetchCarBrandModel = await GetSelectedCarModelList(carBrandId)
        if (!FetchCarBrandModel.Result.Error) {
          let carModel = FetchCarBrandModel.Result
          // carModel.parentName = carBrandName;
          carModel.forEach(c => {
            c.parentName = carBrandName;
            c.label = `${c.Name}`
            c.value = c.Code
            reformCarBrand.push(c)
          })
        carBrand[i]['children'] = carModel
        Cars.push(carBrand[i])
        }
      }
      // console.log(Cars)
      dispatch(fetchCarBrand(Cars))
    }
    //取得案件類型（服務案件）清單
    const FetchCaseType = await GetCaseType()
    if (!FetchCaseType.Error) {
      dispatch(fetchCaseType(FetchCaseType.Result))
    }
    // 取得所有客戶別清單
    const customerListResult = await GetAllCustomerList()
    if (customerListResult&&!customerListResult.Error) {
      let Data = customerListResult.Result.Data
      Data = Data.map(d => {
        d.value = d.row_id
        d.label = `Q${d.id} - ${d.name}  `
        return d
      })
      // console.log(Data)
      dispatch(fetchAllCustomerList(Data))
    }

    // 取得共同運輸站類別
    const commonStationTypeResult = await GetCommonStationType()
    if (!commonStationTypeResult.Error) {
      dispatch(fetchCommonStationType(commonStationTypeResult.Result))
    }
    // GetAllCustomerList


    //
    let type = ["special", "time", "floor", "far", "other"]
    let extraPricingItem = []
    for (let i = 0; i < type.length; i++) {
      const res = await GetAllPriceItem(type[i])
      if (!res.Error) {
        res.Result.map(r => {
          r.type = type[i]
          extraPricingItem.push(r)
          return r
        })
        // fetchExtraPriceItem
      }
      dispatch(fetchExtraPriceItem(extraPricingItem))



    }
  }

  useEffect(() => {
    let user = localStorage.getItem("User") ? JSON.parse(localStorage.getItem("User")) : localStorage.getItem("User");
    setToken(user)
    fetchAPI()
 
  }, [])

  useEffect(() => {
    // header
    let user = localStorage.getItem("User")? JSON.parse(localStorage.getItem("User")) : localStorage.getItem("User");
    setToken(user)
    let locationArr = location.split("/")
    let Location = locationArr[locationArr.length - 1]
    if (Location === "login" || Location === "forget_pw" || Location === 'forgotpassword') {
      setHeader(false)
    } else {
      setHeader(true)
    }
  }, [location])


  const RouterPath = [
    {
      title: '案件列表',
      component: <Cases pageTitle={"「案件列表」全鋒事業客服管理中心 "} ContainerWidth={Container} />,
      path: 'cases', restricted: true,
    },
    {
      title: '案件詳細',
      component: <CasesDetail pageTitle={"「案件詳細」全鋒事業客服管理中心 "} ContainerWidth={Container} setLoading={(value) => setPageLoading(value)} 
      />,
      path: 'cases/:caseId', restricted: true
    },
    {
      title: '新增案列',
      component: <AddCases pageTitle={"「新增案列」全鋒事業客服管理中心"} ContainerWidth={Container} />,
      path: 'cases/add', restricted: true
    },
    {
      title: '審核案件',
      component: <VerifyCase pageTitle={"「審核案件」全鋒事業客服管理中心"} ContainerWidth={Container} />,
      path: "cases/verify", restricted: true
    },
    {
      title: '執行列表',
      component: <Execution pageTitle={"「執行列表」全鋒事業客服管理中心"} ContainerWidth={Container} />,
      path: "execution", restricted: true
    },
    {
      title: '執行詳細',
      component: <Execute pageTitle={"「執行詳細」全鋒事業客服管理中心"} ContainerWidth={Container} />,
      path: "execution/:executeId", restricted: true
    },
    {
      title: '據點管理',
      component: <Points pageTitle={"「據點管理」全鋒事業客服管理中心"} ContainerWidth={Container} />,
      path: "points", restricted: true
    },
    {
      title: '新增據點',
      component: <AddPoint pageTitle={"「新增據點」全鋒事業客服管理中心"} ContainerWidth={Container} />,
      path: "points/add", restricted: true
    },
    {
      title: '據點詳細',
      component: <PointDetail ContainerWidth={Container} pageTitle={"「據點詳細」全鋒事業客服管理中心"} />,
      path: "points/:pointId", restricted: true
    },
    {
      title: '據點技師',
      component: <TechnicianDetail ContainerWidth={Container} pageTitle={"「據點技師」全鋒事業客服管理中心"} />,
      path: "points/:pointId/:technicianId", restricted: true
    },
    {
      title: '生理監控',
      component: <TechnicianHealth ContainerWidth={Container} pageTitle={"「生理監控」全鋒事業客服管理中心"} />,
      path: "points/:pointId/:technicianId/health", restricted: true
    },
    {
      title: '據點車籍',
      component: <CarDetail ContainerWidth={Container} pageTitle={"「據點車籍」全鋒事業客服管理中心"} />,
      path: "points/:pointId/car:carId", restricted: true
    },
    {
      title: '新增技師',
      component: <TechnicianAdd ContainerWidth={Container} pageTitle={"「新增技師」全鋒事業客服管理中心"} />,
      path: "points/:pointId/add_technician", restricted: true
    },
    {
      title: '新增車籍',
      component: <CarAdd ContainerWidth={Container} pageTitle={"「新增車籍」全鋒事業客服管理中心"} />,
      path: "points/:pointId/add_car", restricted: true
    },
    {
      title: '客戶管理',
      component: <Navigate to="/customer/lists" replace />,
      path: "customer", restricted: true

    },
    {
      title: '新增客戶',
      component: <CustomersAdd pageTitle={"「新增客戶」全鋒事業客服管理中心"} ContainerWidth={Container} />,
      path: "/customer/lists/add", restricted: true
    },
    {
      title: '客戶管理',
      component: <Customers pageTitle={"「客戶管理」全鋒事業客服管理中心"} ContainerWidth={Container} />,
      path: "/customer/lists", restricted: true
    },
    {
      title: '客戶詳細',
      component: <CustomerDetail pageTitle={"「客戶詳細」全鋒事業客服管理中心"} ContainerWidth={Container} />,
      path: "/customer/lists/:customerId", restricted: true
    },
    {
      title: '客戶新增相關',
      component: <CustomerRelatedAdd pageTitle={"「客戶管理」全鋒事業客服管理中心"} ContainerWidth={Container} />,
      path: "/customer/lists/:customerId/add", restricted: true
    },
    {
      title: '新增人員',
      component: <CustomerRelatedAdd pageTitle={"「新增人員」全鋒事業客服管理中心"} ContainerWidth={Container} />,
      path: "/customer/lists/:customerId/:employeeId/add", restricted: true
    },
    {
      title: '客戶詳細',
      component: <CustomerAllDetails pageTitle={"「客戶詳細」全鋒事業客服管理中心"} ContainerWidth={Container} />,
      path: "/customer/lists/:customerId/details", restricted: true
    },
    {
      title: '客戶人員詳細',
      component: <EmployeeDetail pageTitle={"「客戶人員詳細」全鋒事業客服管理中心"} ContainerWidth={Container} />,
      path: "/customer/lists/:customerId/details/:employeeId", restricted: true
    },
    {
      title: '共用運送點',
      component: <CustomerServicePoints pageTitle={"「共用運送點」全鋒事業客服管理中心"} ContainerWidth={Container} />,
      path: "/customer/servicePoints", restricted: true
    },
    {
      title: '新增共用運送點',
      component: <CustomerServicePointsAdd pageTitle={"「新增共用運送點」全鋒事業客服管理中心"} ContainerWidth={Container} />,
      path: "/customer/servicePoints/add", restricted: true
    },
    {
      title: '新增共用運送點',
      component: <CustomerServicePointsAdd pageTitle={"「新增共用運送點」全鋒事業客服管理中心"} ContainerWidth={Container} />,
      path: "/customer/servicePoints/add", restricted: true
    },
    {
      title: '共用運送點詳細',
      component: <CustomerServicePointDetail pageTitle={"「共用運送點詳細」全鋒事業客服管理中心"} ContainerWidth={Container} />,
      path: "/customer/servicePoints/:pointId", restricted: true
    },
    {
      title: '行銷活動',
      component: <PromotionList pageTitle={"「行銷活動」全鋒事業客服管理中心"} ContainerWidth={Container} />,
      path: "/customer/promotions", restricted: true
    },
    {
      title: '行銷活動詳細',
      component: <PromotionDetail pageTitle={"「行銷活動詳細」全鋒事業客服管理中心"} ContainerWidth={Container} />,
      path: "/customer/promotions/:promotionId", restricted: true
    },
    {
      title: '新增行銷活動',
      component: <PromotionAdd pageTitle={"「新增行銷活動」全鋒事業客服管理中心"} ContainerWidth={Container} />,
      path: "/customer/promotions/add", restricted: true
    },
    {
      title: '廣告版位',
      component: <AdvertisementList pageTitle={"「廣告版位」全鋒事業客服管理中心"} ContainerWidth={Container} />,
      path: "/customer/advertisement", restricted: true
    },
    {
      title: '廣告詳細',
      component: <AdvertisementDetail  pageTitle={"「廣告詳細」全鋒事業客服管理中心"} ContainerWidth={Container} />,
      path: "/customer/advertisement/:advertisementId", restricted: true
    },
    {
      title: '新增廣告',
      component: <AdvertisementAdd  pageTitle={"「新增廣告」全鋒事業客服管理中心"} ContainerWidth={Container} />,
      path: "/customer/advertisement/add", restricted: true
    },
    {
      title: '通路查詢',
      component: <Navigate to="/fuel/search" replace />,
      path: "/fuel", restricted: true
    },
    {
      title: '通路查詢',
      component: <OilSearch pageTitle={"「通路查詢」全鋒事業客服管理中心"} ContainerWidth={Container} />,
      path: "/fuel/search", restricted: true
    },
    {
      title: '通路詳細',
      component: <OilSearchDetail pageTitle={"「通路詳細」全鋒事業客服管理中心"} ContainerWidth={Container} />,
      path: "fuel/search/:stationId", restricted: true
    },
    {
      title: '即時油價及預估',
      component: <OilEvaluation pageTitle={"「即時油價及預估」全鋒事業客服管理中心  "} ContainerWidth={Container} />,
      path: "fuel/evaluation", restricted: true
    },
    {
      title: '加油紀錄',
      component: <OilRecord pageTitle={"「加油紀錄」全鋒事業客服管理中心"} ContainerWidth={Container} />,
      path: "fuel/record", restricted: true
    },
    {
      title: '加油卡客戶',
      component: <OilRecordDetail pageTitle={"「加油卡客戶」全鋒事業客服管理中心"} ContainerWidth={Container} />,
      path: "fuel/record/:recordId", restricted: true
    },
    {
      title: '請款報表',
      component: <Statement pageTitle={"「請款報表」全鋒事業客服管理中心"} ContainerWidth={Container} />,
      path: "Statement", restricted: true
    },
    {
      title: '帳號管理',
      component: <Navigate to="/privilege/account" replace />,
      path: "privilege", restricted: true
    },
    {
      title: '帳號管理',
      component: <Account pageTitle={"「帳號管理」全鋒事業客服管理中心"} ContainerWidth={Container} />,
      path: "/privilege/account", restricted: true
    },
    {
      title: '帳號管理',
      component: <AccountDetail pageTitle={"「帳號管理」全鋒事業客服管理中心"} ContainerWidth={Container} />,
      path: "/privilege/account/:accountId", restricted: true
    },
    {
      title: '新增帳號',
      component: <AccountAdd pageTitle={"「新增帳號」全鋒事業客服管理中心"} ContainerWidth={Container} />,
      path: "/privilege/account/add", restricted: true
    },
    {
      title: '權限標籤管理',
      component: <PrivilegeTags pageTitle={"「權限標籤管理」全鋒事業客服管理中心"} ContainerWidth={Container} />,
      path: "/privilege/tags", restricted: true
    },
    {
      title: '新增權限標籤',
      component: <PrivilegeTagsAdd pageTitle={"「新增權限標籤」全鋒事業客服管理中心"} ContainerWidth={Container} />,
      path: "/privilege/tags/add", restricted: true
    },
    {
      title: '權限標籤',
      component: <PrivilegeTagDetails pageTitle={"「權限標籤」全鋒事業客服管理中心"} ContainerWidth={Container} />,
      path: "/privilege/tags/:tagId", restricted: true
    },
    {
      title: '租賃管理',
      component: <Lease pageTitle={"「租賃管理」全鋒事業客服管理中心"} ContainerWidth={Container} />,
      path: "/rent", restricted: true
    },
    {
      title: '租賃資訊',
      component: <LeaseInfo pageTitle={"「租賃資訊」全鋒事業客服管理中心"} ContainerWidth={Container} />,
      path: "/rent/:leaseId", restricted: true
    },
    {
      title: '新增租賃',
      component: <LeaseAdd pageTitle={"「新增租賃」全鋒事業客服管理中心"} ContainerWidth={Container} />,
      path: "/rent/add", restricted: true
    },
    {
      title: '通知管理',
      component: <Notifications pageTitle={"「通知管理」全鋒事業客服管理中心"} ContainerWidth={Container} />,
      path: "notifications", restricted: true
    },
    {
      title: '404頁面',
      component: <Error ContainerWidth={Container} pageTitle={"「404頁面」全鋒事業客服管理中心"} />,
      path: "*", restricted: false
    },
    {
      title: '登入',
      component: <Login pageTitle={"「登入」全鋒事業客服管理中心"} />,
      path: "user/login", restricted: false
    },
    {
      title: '忘記密碼',
      component: <ForgetPW pageTitle={"「忘記密碼」全鋒事業客服管理中心"} />,
      path: "user/forget_pw", restricted: false
    },
    {
      title: '重設密碼',
      component: <ResetPW pageTitle={"「重設密碼」全鋒事業客服管理中心"} />,
      path: "user/forgotpassword", restricted: false
    },
    {
      title: '帳號資料',
      component: <MemberInfo pageTitle={"帳號資料"} ContainerWidth={Container}  />,
      path: "user/info", restricted: true
    }
  ]

  return (
    <div className={header ? 'with_header' : 'without_header'}>
  
      <Router basename={process.env.REACT_APP_BASE_URL}>
        {/* <Router > */}
        {
          header && (<Header show={true} history={history} setContainer={(width) => {
            let actual_width = !width ? "208px" : "60px"
            setContainerWidth(actual_width)
          }} />)
        }
  
        <Routes>

          {/* 權限管理 */}
          {/* <Route path="privilege/account" element={<Account pageTitle={"全鋒事業客服管理中心 | 帳號管理"} ContainerWidth={Container} />} ></Route> */}
          {/* 案件列表 */}
          
          {/* {
            RouterPath.map(r => <Route
              element={token? r.component : <Navigate to={"user/login"}  replace/>} 
            />)
          } */}
          {/* <Route key="points" path="points" element={<ProtectedRoute token={token}>
            <Points pageTitle={"全鋒事業客服管理中心 | 據點管理"} ContainerWidth={Container} />
          </ProtectedRoute> } /> */}

          {/*
          <Route path="cases/:caseId" element={<CasesDetail ContainerWidth={Container} setLoading={(value) => setPageLoading(value)} />} />
          <Route path="cases/add" element={<AddCases pageTitle={"全鋒事業客服管理中心 | 新增案列"} ContainerWidth={Container} />}></Route>
          <Route path="cases/verify" element={<VerifyCase pageTitle={"全鋒事業客服管理中心 | 審核案件"} ContainerWidth={Container} />}></Route> */}

          {/* 登入會員 */}
          {
            !user ? (<Route path="/" element={<Navigate to="user/login" replace />} />):(<Route path="/" element={<Navigate to="cases" replace />} />)
          }
          <Route path="/" element={<Navigate to="cases" replace />} />
           {/* 404頁面 */}
           <Route path="*" element={<Error ContainerWidth={Container} pageTitle={"全鋒事業客服管理中心 | 404頁面"} />}></Route>
           {
            RouterPath.map(r=>(<Route key={r.path} path={r.path} element={<ProtectedRoute token={token} config={r}>
               { (r.component)}
            </ProtectedRoute> } />))
           }
           {/* {
            :(<>
                <Route path="user/login" element={<Login pageTitle={"全鋒事業客服管理中心 | 登入"} />}></Route>
                <Route path="user/forget_pw" element={<ForgetPW pageTitle={"全鋒事業客服管理中心 | 忘記密碼"} />}></Route>
                <Navigate to="user/login" replace />
            </>)
          } */}
          {/* 執行列表 */}
          {/* <Route path="execution" element={<Execution pageTitle={"全鋒事業客服管理中心 | 執行列表"} ContainerWidth={Container} />}></Route>
          <Route path="execution/:executeId" element={<Execute ContainerWidth={Container} />} /> */}

          {/* 據點管理 */}
          {/* <Route path="points" element={<Points pageTitle={"全鋒事業客服管理中心 | 據點管理"} ContainerWidth={Container} />} ></Route> */}
          {/* <Route path="points/addpoint" element={<Points pageTitle={"全鋒事業客服管理中心 | 新增案列"} ContainerWidth={Container} />} ></Route> */}
          {/* <Route path="points/add" element={<AddPoint pageTitle={"全鋒事業客服管理中心 | 新增據點"} ContainerWidth={Container} />} ></Route>
          <Route path="points/:pointId" element={<PointDetail ContainerWidth={Container} />} > </Route>
          <Route path="points/:pointId/:technicianId" element={<TechnicianDetail ContainerWidth={Container} />} > </Route>
          <Route path="points/:pointId/c:carId" element={<CarDetail ContainerWidth={Container} />} > </Route>
          <Route path="points/:pointId/add_technician" element={<TechnicianAdd ContainerWidth={Container} />} > </Route>
          <Route path="points/:pointId/add_car" element={<CarAdd ContainerWidth={Container} />} > </Route> */}

          {/* 客戶管理 */}
          {/* <Route path="/customer" element={<Navigate to="/customer/lists" replace />} />
          <Route path="/customer/lists" element={<Customers pageTitle={"全鋒事業客服管理中心 | 客戶管理"} ContainerWidth={Container} />} ></Route>
          <Route path="/customer/lists/:customerId" element={<CustomerDetail pageTitle={"全鋒事業客服管理中心 | 客戶管理"} ContainerWidth={Container} />} ></Route>
          <Route path="/customer/lists/:customerId/add" element={<CustomerRelatedAdd pageTitle={"全鋒事業客服管理中心 | 客戶管理"} ContainerWidth={Container} />} ></Route>
          <Route path="/customer/lists/:customerId/:employeeId/add" element={<CustomerRelatedAdd pageTitle={"全鋒事業客服管理中心 | 新增人員"} ContainerWidth={Container} />} ></Route> */}
          {/* <Route path="/customer/lists/:customerId/:employeeId" element={<EmployeeDetail pageTitle={"全鋒事業客服管理中心 | 客戶管理"} ContainerWidth={Container} />} ></Route> */}
          {/* <Route path="/customer/lists/:customerId/details" element={<CustomerAllDetails pageTitle={"全鋒事業客服管理中心 | 客戶管理"} ContainerWidth={Container} />} ></Route>
          <Route path="/customer/lists/:customerId/details/:employeeId" element={<EmployeeDetail pageTitle={"全鋒事業客服管理中心 | 客戶管理"} ContainerWidth={Container} />} ></Route>
          <Route path="/customer/lists/add" element={<CustomersAdd pageTitle={"全鋒事業客服管理中心 | 心中客戶"} ContainerWidth={Container} />} ></Route>

          <Route path="/customer/servicePoints" element={<CustomerServicePoints pageTitle={"全鋒事業客服管理中心 | 客戶管理"} ContainerWidth={Container} />} ></Route>
          <Route path="/customer/servicePoints/add" element={<CustomerServicePointsAdd pageTitle={"全鋒事業客服管理中心 | 客戶管理"} ContainerWidth={Container} />} ></Route>
          <Route path="/customer/servicePoints/:pointId" element={<CustomerServicePointDetail pageTitle={"全鋒事業客服管理中心 | 客戶管理"} ContainerWidth={Container} />} ></Route>
          <Route path="/customer/promotions" element={<PromotionList pageTitle={"全鋒事業客服管理中心 | 客戶管理"} ContainerWidth={Container} />} ></Route>
          <Route path="/customer/promotions/add" element={<PromotionAdd pageTitle={"全鋒事業客服管理中心 | 客戶管理"} ContainerWidth={Container} />} ></Route>

          <Route path="/customer/promotions/:promotionId" element={<PromotionDetail pageTitle={"全鋒事業客服管理中心 | 客戶管理"} ContainerWidth={Container} />} ></Route> */}

          {/* 加油管理 */}
          {/* <Route path="/fuel" element={<Navigate to="/fuel/search" replace />} />
          <Route path="/fuel/search" element={<OilSearch pageTitle={"全鋒事業客服管理中心 | 通路查詢"} ContainerWidth={Container} />} ></Route>
          <Route path="fuel/search/:stationId" element={<OilSearchDetail pageTitle={"全鋒事業客服管理中心 | 油站詳細"} ContainerWidth={Container} />} ></Route>
          <Route path="fuel/evaluation" element={<OilEvaluation pageTitle={"全鋒事業客服管理中心 | 即時油價及預估"} ContainerWidth={Container} />} ></Route>
          <Route path="fuel/record" element={<OilRecord pageTitle={"全鋒事業客服管理中心 | 加油紀錄"} ContainerWidth={Container} />} ></Route>
          <Route path="fuel/record/:recordId" element={<OilRecordDetail pageTitle={"全鋒事業客服管理中心 | 加油卡客戶"} ContainerWidth={Container} />} ></Route> */}

          {/* 請款報表 */}
          {/* <Route path="statement" element={<Statement pageTitle={"全鋒事業客服管理中心 | 請款報表"} ContainerWidth={Container} />} ></Route> */}


          {/* 通知管理 */}
          {/* <Route path="notifications" element={<Notifications pageTitle={"全鋒事業客服管理中心 | 通知"} ContainerWidth={Container} notification={notification} />} ></Route> */}
         
        </Routes>
      </Router>

      {/* <Footer show={token} /> */}

    </div>
  )

}

export default App

import service from './Setting'


export const LoginApi = (obj) => service.post(`/User/Login`, obj);
export const ForgetPassword  = (obj) => service.post(`/User/ForgotPassword`, obj);
export const ResetPassword = (obj) => service.post(`/User/ResetPassword`, obj);

//取得帳號資訊
export const GetUserInfo = () => service.get(`/User/InfoBySelf`);
//編輯帳號資訊
export const EditUserInfo = (obj) => service.post(`/User/EditBySelf`,obj);
//UpdatePassowrd
export const UpdatePassowrdByUserId = (obj) => service.post(`/User/UpdatePasswordByUserId`, obj);



// 取得使用者清單
export const UserList = (user_type) => service.get(`/User/List?page_index=0&page_size=-1&user_type=${user_type}` );
// 取得使用者資訊
export const UserDetail = (id) => service.get(`/User/Info?id=${id}`);
// 取得使用者歷程
export const UserHistory = (id) => service.get(`/User/History?id=${id}`);
// 驗證使用者帳號是否重複
export const UserCheckAccount = (id) => service.post(`/User/CheckAccount`,{user_id:id});
// 新增帳號
export const AddAccount = (obj) => service.post(`/User/Add`,obj);
// 新增帳號備註
export const AddAccountNote = (obj) => service.post(`/User/AddNote`,obj);
// 更新帳號
export const EditAccount = (obj) => service.post(`/User/Edit`,obj);
// 刪除帳號
export const DelAccount = (id) => service.post(`/User/Del`,{user_row_id:id});
// 停用帳號
export const DisabledAccount = (id) => service.post(`/User/Disabled`,{user_row_id:id});
// 啟用帳號
export const EnableAccount = (id) => service.post(`/User/Enable`,{user_row_id:id});
// 取得權限標籤清單
export const AllPrivilegeTag = () => service.get(`/UserPermission/TagAllList`);


// 取得權限清單
export const PrivilegeList = () => service.get(`/UserPermission/List`);

// 查核權限標籤名稱是否重複
export const CheckPrivilegeTagName = (name) => service.get(`/UserPermission/CheckTag?tag_name=${name}`);
// 新增權限標籤
export const AddPrivilegeTag = (formData) => service.post(`/UserPermission/TagAdd`,formData);
// 新增權限標籤備註
export const AddPrivilegeTagNote = (formData) => service.post(`/UserPermission/TagNoteAdd`,formData);
// 取得權限標籤內容
export const PrivilegeTagDetail = (id) => service.get(`/UserPermission/TagBindInfo?tag_id=${id}`);

// 更新權限標籤資訊
export const UpdatePrivilegeTag = (formData) => service.post(`/UserPermission/TagEdit`,formData);
// 更新權限標籤狀態
export const EditPrivilegeTagStatus = (formData) => service.post(`/UserPermission/ChangeStatus`,formData);
//刪除權限標籤
export const DeletePrivilegeTag = (formData) => service.post(`/UserPermission/TagDel`,formData);
//取得標籤歷程
export const GetPrivilegeTagHistory = (id) => service.get(`/UserPermission/TagHistory?tag_id=${id}`);

//取得標籤使用者清單
export const GetPrivilegeTagUserList = (id) => service.get(`/UserPermission/ReplaceTagUserList?tag_id=${id}`);
import React, { useEffect, useState, useRef } from 'react'
import { PageHeader, Form, Input, Button, Select, Upload, Checkbox, Row, Col, message, Popconfirm } from 'antd'
import BreadCrumb from '../../shared/BreadCrumb'
import { PlusOutlined, RightOutlined, StarTwoTone, UnorderedListOutlined } from '@ant-design/icons'
import { AddCustomerEmployee, AddStationEmployee, AddCustomerEmployeeNote, UpdateStationEmployeeNote } from '../../../api/Customer'
import CustomAutoComplete from '../../shared/component/CustomAutoComplete'
import { useNavigate, useLocation,useParams } from 'react-router-dom'
import CustomCascader from '../../shared/component/CustomCascader'
import { GetCarBrandList } from '../../../api/Code'

const { TextArea } = Input

const Add = ({ pageTitle, ContainerWidth }) => {
    const { state } = useLocation()

    const [currentStep, setCurrentStep] = useState(0)
    const [remark, setRemark] = useState("")
    let navigate = useNavigate()
    const submitForm = useRef()
    //state.subTab ? ‘運送點新增人員’ ： ‘客戶新增人員’
    let historyHref = state.subTab ? `/customer/lists/${state.customerId}/details` : `/customer/lists/${state.customerId}`
    const [successSubmit, setSuccessSubmit] = useState(null)
    const [FileList, setFileList] = useState([])
    const fileInput = useRef()
    const [previewPhoto, setPreviewPhoto] = useState(null)
    const [BrandList, setBrandList] = useState([])
    useEffect(() => {
        // document.title = pageTitle
        GetCarBrandList().then(res=>{
            if(res && !res.Error){
                let Data = res.Result.map(r=>{
                    return({value:r.Code, label:r.Name})
                })
                setBrandList(Data)
            }
        })
       
    }, [])
    const AddRemark = async () => {
        let param = `row_id=${successSubmit.row_id}&note=${remark}`
        let res
        // console.log(param)

        if (state.subTab) {
            res = await UpdateStationEmployeeNote(param)
        } else {
            res = await AddCustomerEmployeeNote(param)
        }
        if (res && !res.Error) {
            setRemark("")
            submitForm.current.resetFields()
            message.success('已新增備註')
        }
    }
    const SubmitForm = async (value) => {
        const formdata = new FormData()
        let roles = state.subTab ? ["role_keyin", "role_payment", "role_handover"] : ["role_keyin", "role_payment"]
        if (value.role) {
            value.role.forEach(r => {
                value[r] = 1
            })
        }
        roles.forEach(r => {
            if (!value[r]) value[r] = 0
        })
        if (state.subTab) {
            value.station_row_id = state.id
        } else {
            let transport_code = ""
            if (value.transport_code) {
                value.transport_code.forEach((code, i) => { transport_code += i === 0 ? code : `,${code}` })
            }
            value.customer_row_id = state.customerId
            value.transport_code = transport_code
            value.county =  value.address_county[0]
            value.town =  value.address_county[1]
        }

        let param = ''
        Object.keys(value).forEach((key, i) => {
            if (!value[key]) {
                if (key === "role_payment" || key === 'role_keyin') {
                    formdata.append(key, value[key])
                } else {
                    formdata.append(key, "")
                }
            } else {
                if (key === "card_photo") {
                    formdata.append(key, value[key]["file"]["originFileObj"])
                } else {
                    formdata.append(key, value[key])
                }
            }
            if (state.subTab) {
                param += i === 0 ? `${key}=${value[key]}` : `&${key}=${value[key]}`
            }
        })
        let fetch
        if (state.subTab) {
            fetch = await AddStationEmployee(param)
        } else {
            fetch = await AddCustomerEmployee(formdata)
        }
        if (fetch.Result.Success) {
            let data = { ...fetch.Result.Data, name: value.name_cht }
            submitForm.current.resetFields()
            setSuccessSubmit(data)
            setCurrentStep(1)
        }
    }
    const getBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        })
    return (
        <div className='container' style={ContainerWidth}>
            <PageHeader
                title="新增人員"
                onBack={() => currentStep === 0 ? navigate(historyHref, { state }) : setCurrentStep(currentStep - 1)}
                className="site-page-header"
                breadcrumb={<BreadCrumb Info={state.BreadcrumbInfo} addText='新增人員' />}
            >
            </PageHeader>
            <div className='step_block'>
                <div >
                    <Form
                        ref={submitForm}
                        name="basic"
                        layout="vertical"
                        onFinish={SubmitForm}
                    // initialValues={{customerLevel:"一般客戶"}}
                    >
                        <div style={{ minHeight: "calc(100vh - 240px)" }}>
                            <div className='step_content'>
                                {
                                    currentStep === 0 ? (<>
                                        <Form.Item
                                            name="role"
                                            label={<><span>身份標籤</span><span style={{ paddingLeft: 4, color: "rgba(0, 0, 0, 0.45)", fontSize: 14 }}>(可複選)</span></>}
                                        >
                                            <Checkbox.Group>
                                                <Row style={{ display: "flex" }}>
                                                    <Col style={{ marginRight: 8 }}>
                                                        <Checkbox
                                                            value="role_keyin"
                                                            style={{
                                                                lineHeight: '32px',
                                                            }}
                                                        >可上單</Checkbox>
                                                    </Col>
                                                    <Col >
                                                        <Checkbox
                                                            value="role_payment"
                                                            style={{
                                                                lineHeight: '32px',
                                                            }}
                                                        >請款窗口</Checkbox>
                                                    </Col>
                                                    {
                                                        state.subTab && (<Col >
                                                            <Checkbox
                                                                value="role_handover"
                                                                style={{
                                                                    lineHeight: '32px',
                                                                }}
                                                            >交接車人</Checkbox>
                                                        </Col>)
                                                    }
                                                </Row>
                                            </Checkbox.Group>
                                        </Form.Item>
                                        <div style={{ display: "flex" }}>
                                            <Form.Item
                                                label="中文姓名"
                                                name="name_cht"
                                                rules={[{ required: true, message: '請輸入中文姓名!' }]}
                                            >
                                                <Input allowClear placeholder='example' style={{ width: "304px" }} />
                                            </Form.Item>
                                            {
                                                !state.subTab && (<Form.Item
                                                    label="英文姓名"
                                                    name="name_en"
                                                    style={{ margin: '0 16px' }}
                                                >
                                                    <Input allowClear placeholder='example' style={{ width: "304px" }} />
                                                </Form.Item>)
                                            }

                                            <Form.Item
                                                label="職稱"
                                                name="job_title"
                                                rules={[{ required: true, message: '請輸入職稱!' }]}
                                                style={{ marginLeft: state.subTab ? '16px' : '' }}
                                            >
                                                <Input allowClear placeholder='例：廠長' style={{ width: "304px" }} />
                                            </Form.Item>
                                        </div>
                                        <div style={{ display: "flex" }}>
                                            <Form.Item
                                                label="聯絡電話"
                                                name="tel"
                                                rules={[{ required: true, message: '請輸入聯絡電話！' }]}
                                            >
                                                <Input allowClear placeholder='市話、手機皆可，不需加分隔號' style={{ width: "304px" }} />
                                            </Form.Item>
                                            <Form.Item
                                                label="E-mail"
                                                name="email"
                                                style={{ margin: '0 16px' }}
                                                rules={[{ required: true, message: '請輸入E-mail！' }]}
                                            >
                                                <Input allowClear placeholder='example@abc.com' style={{ width: "304px" }} />
                                            </Form.Item>
                                           
                                            {
                                                !state.subTab && (<Form.Item
                                                    label="傳真號碼"
                                                    name="fax"
                                                >
                                                    <Input allowClear placeholder='不需加分隔號' style={{ width: "304px" }} />
                                                </Form.Item>)
                                            }


                                        </div>
                                        {
                                            !state.subTab ? (<>
                                                <div style={{ display: 'flex' }}>
                                                    <Form.Item
                                                        label="聯絡地址"
                                                        name="address_county"
                                                        rules={[{ required: true, message: "請輸入聯絡地址！!" }]}
                                                    >
                                                        <CustomCascader
                                                            style={{ width: "304px" }}
                                                            OnChange={(val) => {
                                                                submitForm.current.setFieldsValue({ address_county: val })
                                                            }}
                                                        />
                                                    </Form.Item>
                                                    <div style={{margin:"0 16px"}}>
                                                        <div style={{ height: 34 }}></div>
                                                        <Form.Item
                                                            label=""
                                                            name="address"
                                                            rules={[{ required: true, message: '請輸入地址！' }]}
                                                        >
                                                            <Input allowClear placeholder='請直接輸入地址' style={{ width: "304px" }} />
                                                        </Form.Item>
                                                    </div>
                                                    <Form.Item
                                                label="常用廠牌"
                                                name="brand"
                                                // rules={[{ required: true, message: '請選擇常用車！' }]}
                                            >
                                                <Select
                                                    placeholder='點擊選擇'
                                                    style={{ width: 304 }}
                                                    onChange={(val) => console.log(val)}
                                                    options={BrandList}

                                                />
                                            </Form.Item>
                                                </div>
                                               
                                                <Form.Item
                                                    label="名片"
                                                    name="card_photo"
                                                    // rules={[{ required: true, message: '請選擇名片圖!' }]}
                                                    extra="僅接受 jpg, png 並且小於 5mb 之檔案格式類型"
                                                >
                                                    <Upload
                                                        maxCount={1}
                                                        listType="picture-card"
                                                        className="avatar-uploader"
                                                        showUploadList={false}
                                                        accept="image/png, image/jpeg, image/jpg"
                                                        customRequest={({ onSuccess }) => onSuccess("ok")}
                                                        onChange={async (file) => {

                                                            let url = await getBase64(file.file.originFileObj)
                                                            setPreviewPhoto(url);
                                                        }}
                                                    >
                                                        {previewPhoto ? <img src={previewPhoto} alt="avatar" style={{ width: '100%' }} /> : (<div>
                                                            <PlusOutlined />
                                                            <div style={{ marginTop: 8 }}>上傳</div>
                                                        </div>)}
                                                    </Upload>
                                                </Form.Item>
                                               
                                            </>):(
                                                <Form.Item
                                                label="常用車牌"
                                                name="brand"
                                                rules={[{ required: true, message: '請選擇常用車！' }]}
                                            >
                                                <Select
                                                    placeholder='點擊選擇'
                                                    style={{ width: 304 }}
                                                    onChange={(val) =>{}}
                                                    options={BrandList}

                                                />
                                            </Form.Item>
                                            )
                                        }

                                    </>) :
                                        (<>
                                            <div style={{ fontSize: 30 }}>
                                                人員
                                                <span style={{ color: "#1890FF", fontSize: 30 }}> #{successSubmit.id} / {successSubmit.name}
                                                </span>
                                                已新增
                                            </div>
                                            <TextArea
                                                placeholder="額外的人員內容說明請寫在此......"
                                                autoSize={{ minRows: 2, maxRows: 4 }}
                                                style={{ width: 395, marginTop: 8 }}
                                                value={remark}
                                                onChange={(e) => setRemark(e.target.value)}
                                            />
                                            <Button
                                                style={{ display: "block", marginTop: 8 }}
                                                disabled={remark !== "" ? "" : "disabled"}
                                                onClick={() => AddRemark()}
                                            >新增備註</Button>
                                            <div style={{ marginTop: 32 }}>
                                                <Button
                                                    type="primary"
                                                    icon={<PlusOutlined />}
                                                    onClick={() => setCurrentStep(0)}
                                                >新增其他人員</Button>
                                                <Button
                                                    icon={<RightOutlined />}
                                                    style={{ margin: "0 8px" }}
                                                    onClick={() => {
                                                        let href = state.subTab ? `/customer/lists/${state.customerId}/details/${successSubmit.row_id}` : `/customer/lists/${state.customerId}/details`
                                                        let new_state = state.subTab ? { ...state, id: successSubmit.row_id } : { ...state, employeeId: successSubmit.row_id }
                                                        navigate(href, { state: new_state })
                                                    }}
                                                >人員內容</Button>
                                                <Button
                                                    icon={<UnorderedListOutlined />}
                                                    onClick={() => {
                                                        let href = state.subTab ? `/customer/lists/${state.customerId}/details` : `/customer/lists/${state.customerId}`
                                                        navigate(href, { state })
                                                    }}
                                                >回列表</Button>
                                            </div>
                                        </>)
                                }
                            </div>
                        </div>
                        {
                            currentStep === 0 && (<div style={{ background: "white", marginTop: 16, width: "100%", padding: "20px", display: "flex", justifyContent: "flex-end" }}>
                                <Popconfirm
                                    style={{ width: 410 }}
                                    placement="top"
                                    title={
                                        <div>
                                            <div>
                                                <strong>新增還沒完成喔</strong>
                                            </div>
                                            <div style={{ fontSize: 14 }}>
                                                剛剛輸入的所有資訊將不被保存。
                                            </div>
                                        </div>
                                    }
                                    onCancel={() => {
                                        let href = state.subTab ? `/customer/lists/${state.customerId}/details` : `/customer/lists/${state.customerId}`
                                        navigate(href, { state: state })
                                    }
                                    }
                                    okText="不，我要留下"
                                    cancelText="對，我要離開"
                                >
                                    <Button type="text" style={{ marginRight: 24 }} >
                                        關閉
                                    </Button>
                                </Popconfirm>
                                <Button type="primary" icon={<PlusOutlined />}
                                    htmlType="submit"
                                > 新增 </Button>
                            </div>)
                        }
                    </Form>
                </div>
            </div>
        </div>
    );
}

export default Add;

import React, { useEffect, useState, useRef } from 'react'
import { PageHeader, Form, Input, Button, Select, Checkbox, TimePicker, Steps, message, Popconfirm, InputNumber } from 'antd'
import BreadCrumb from '../../shared/BreadCrumb'
import { PlusOutlined, RightOutlined, UnorderedListOutlined, EnterOutlined } from '@ant-design/icons'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { AddCustomerStation, UpdateCustomerStationRemark, CheckStationName,GetDriver } from '../../../api/Customer'
import CustomCascader from '../../../components/shared/component/CustomCascader'
import { fetchCarType } from '../../../actions/iniFetch'
import { GetCarTypeList, CheckValidAddr } from '../../../api/Code'
import moment from "moment"
import { useSelector, useDispatch } from 'react-redux'
// import { FetchData } from '../../../api/Fetch'

const { Option } = Select
const { TextArea } = Input
const { Step } = Steps

const Add = ({ pageTitle, ContainerWidth }) => {
  const format = 'HH:mm'
  const [currentStep, setCurrentStep] = useState(0)
  const [remark, setRemark] = useState("")
  const [weekDayOperatingHours, setWeekDayOperatingHours] = useState(null)
  const [weekendOperatingHours, setWeekendOperatingHours] = useState(null)
  const [holidayOperatingHours, setHolidayOperatingHours] = useState(null)
  const [Invalidation, setInvalidation] = useState({ station: null, address: null })
  const [submitData, setSubmitData] = useState(null)
  const [successSubmit, setSuccessSubmit] = useState(null)
  const [Drivers, setDrivers] = useState([])
  const [PayType, setPayType] = useState("check")
  let navigate = useNavigate(), dispatch = useDispatch()
  const CarType = useSelector(state => state.CarType)
  const formRef = useRef()
  const { state } = useLocation()
  let { customerId } = useParams()

  let historyHref = state.subTab ? `/customer/lists/${state.customerId}/details` : `/customer/lists/${state.customerId}`
  const steps = [
    { title: '基本資訊', description: '請輸入運送點的基本資訊', content: 'First-content', width: "30%" },
    { title: '請款資訊', description: '並確認該運送點的請款資訊', content: 'Second-content', width: "100%" },
    { title: '完成', description: '完成後即可派遣', content: 'Last-content', width: "30%" },
  ];
  const checkOperatingHour = (e, type) => {
    let isChecked = e
    let value = isChecked ? "0" : null
    type === "weekday" ? setWeekDayOperatingHours(value) : type === "weekend" ? setWeekendOperatingHours(value) : setHolidayOperatingHours(value)
    if (isChecked) {
      formRef.current?.setFieldsValue({ [type]: [moment("0"), moment("0")] })
    } else {
      formRef.current?.setFieldsValue({ [type]: null })
    }
  }
  const CheckStation = async (name) => {
    let res = await CheckStationName(name, customerId)
    let invalid = { ...Invalidation }
    invalid.station = !res.Result
    setInvalidation(invalid)
    return res.Result
  }
  const onFinish = async (value) => {
    for (let key in value) {
      if (!value[key]) value[key] = ''
    }
    if (currentStep === 0) {
      let address = `${value.address_county[0].split(",")[1]}${value.address_county[1].split(",")[1]}${value.address}`
      value.county = value.address_county[0].split(",")[0]
      value.town = value.address_county[1].split(",")[0]
      let invalid = { ...Invalidation }
      let check_name = await CheckStationName(value.name, customerId)
      let check_add = await CheckValidAddr({ addr_list: [address] })
      invalid.station = !check_name.Result
      invalid.address = !check_add.Result[0]['is_check']

      setInvalidation(invalid)
      let valid = Object.keys(invalid).filter(d => invalid[d])
      if (valid.length > 0) return false
      value.customer_row_id = state.customerId
      value.normal_open = weekDayOperatingHours ? weekDayOperatingHours : '1'
      value.weekend_open = weekendOperatingHours ? weekendOperatingHours : '1'
      value.holiday_open = holidayOperatingHours ? holidayOperatingHours : '1'
      value.normal_start_time = value.weekday[0].format(format)
      value.normal_end_time = value.weekday[1].format(format)
      value.weekend_start_time = value.weekend[0].format(format)
      value.weekend_end_time = value.weekend[1].format(format)
      value.holiday_start_time = value.holiday[0].format(format)
      value.holiday_end_time = value.holiday[1].format(format)
      let no_accept_car = ''
      if (value.no_accept_car_arr) {
        value.no_accept_car_arr.map((car, i) => no_accept_car += i === 0 ? car : `,${car}`)
      }

      value.no_accept_car = no_accept_car
      setSubmitData(value)
      setCurrentStep(1)
    } else {
      let final = { ...submitData, ...value }
      final.receive_mode = '01'
      if(final.receipt_address_county_arr){
        final.receipt_address_county = final.receipt_address_county_arr[0]
        final.receipt_address_town = final.receipt_address_county_arr[1]
      }
     
      // let param = ''
      // Object.keys(final).forEach((key, i) => {
      //   param += i === 0 ? `${key}=${final[key]}` : `&${key}=${final[key]}`
      // })
      // const user = JSON.parse(window.localStorage.getItem('User'))
      // fetch(`${process.env.REACT_APP__API_HOST}/Station/AddStation?${param}`,{
      //   headers:{
      //     'Authorization': user?  `Bearer ${user.Token}`:''
      //   }
      // }).then(res=>res.json())
      // .then(d=>console.log(d))
     
      AddCustomerStation(final).then(res => {
        if (res.Result.Success) {
          formRef.current.resetFields()
          let Data = { ...res.Result.Data, name: final.name }
          setSuccessSubmit(Data)
          setCurrentStep(2)
        }
      })
    }
  }
  const DriverList = (val)=>{
    GetDriver(val).then(res=>{
      if(res && !res.Error) setDrivers(res.Result)
      
    })
  }
  useEffect(() => {
    DriverList('')
    // document.title = pageTitle
   
  }, [])
  useEffect(() => {
    if (!CarType) {
      GetCarTypeList().then(res => {
        if (res && !res.Error) {
          dispatch(fetchCarType(res.Result))
        }
      })
    }
  }, [CarType])
  return (
    <div className='container' style={ContainerWidth}>
      <PageHeader
        title="新增運送點"
        onBack={() => currentStep === 0 ? navigate(historyHref, { state }) : setCurrentStep(currentStep - 1)}
        className="site-page-header"
        breadcrumb={<BreadCrumb Info={state.BreadcrumbInfo} addText='新增運送點' />}
      >
      </PageHeader>
      <div className='step_block'>
        <Steps current={currentStep} style={{ padding: "16px 24px", borderBottom: "1px solid rgba(0, 0, 0, 0.06)", margin: "0 24px", background: "white", width: "calc(100% - 48px)" }}>
          {steps.map(item => (
            <Step key={item.title} title={item.title} description={item.description} style={{ width: item.width }} />
          ))}
        </Steps>
        <div >

          <Form
            name="basic"
            layout="vertical"
            initialValues={{ pay_type: "check" }}
            ref={formRef}
            onFinish={onFinish}
          >
            <div style={{ minHeight: "calc(100vh - 327px)" }}>
              <div className='step_content'>
                {
                  currentStep === 0 ? (<>
                    <div style={{ display: "flex" }}>
                      <Form.Item
                        name="name"
                        label="運送點名稱"
                        style={{ marginBottom: 0 }}
                        rules={[{ required: true, message: '請輸入運送點名稱!' }]}
                        hasFeedback={Invalidation.station ? false : true}
                        help={Invalidation.station ? "此運送點名稱已存在!" : "輸入名稱後點擊右側檢核紐"}
                        validateStatus={Invalidation.station === null ? "" : !Invalidation.station ? 'success' : 'error'}
                      >
                        <Input
                          placeholder='請輸入運送點名稱'
                          onKeyUp={() => {
                            setInvalidation({ ...Invalidation, station: null })
                          }}
                          addonAfter={<EnterOutlined
                            style={{ cursor: "pointer" }}
                            onClick={(e) => CheckStation(formRef.current.getFieldValue("name"))} />}
                          style={{ width: "304px" }} />
                      </Form.Item>
                      <Form.Item
                        label="聯絡電話"
                        name="tel"
                        rules={[{ required: true, message: '請輸入聯絡電話!' }]}
                        style={{ marginLeft: 16 }}
                      >
                        <Input allowClear placeholder='example' style={{ width: "304px" }} />
                      </Form.Item>
                    </div>
                    <div style={{ display: "flex" }}>
                      <Form.Item
                        label="運送點地址"
                        name="address_county"
                        rules={[{ required: true, message: '請選擇縣市!' }]}
                      // 
                      >
                        <CustomCascader
                          NameCode
                          style={{ width: "304px" }}
                          OnChange={(val) => {
                            formRef.current.setFieldsValue({ address_county: val })
                          }}
                        />
                      </Form.Item>
                      <div>
                        <div style={{ height: 34 }}></div>
                        <Form.Item
                          label=""
                          name="address"
                          rules={[{ required: true, message: '請輸入完整地址!' }]}
                          style={{ margin: "0 16px" }}
                          hasFeedback={Invalidation.address ? false : true}
                          help={Invalidation.address ? "查無此地址，請輸入有效地址" : ""}
                          validateStatus={Invalidation.address === null ? "" : !Invalidation.address ? 'success' : 'error'}
                        >
                          <Input allowClear placeholder='請輸入完整地址' style={{ width: "304px" }} />
                        </Form.Item>
                      </div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <Form.Item
                        label="經銷商"
                        name="dealer"
                      >
                        <Input allowClear placeholder='例：台澳北區' style={{ width: "304px" }} />
                      </Form.Item>
                    </div>
                    <div style={{ display: "flex", gridGap: 16 }}>
                      <Form.Item
                        label={<><span>不可接受之載運車</span><span style={{ paddingLeft: 4, color: "rgba(0, 0, 0, 0.45)", fontSize: 14 }}>(可複選)</span></>}
                        name="no_accept_car_arr"
                      >
                        <Select
                          mode="multiple"
                          placeholder="請選擇不可接受之載運車款"
                          style={{ width: "304px" }}
                        >
                          {
                            CarType && CarType.map(car => (<Option key={`${car.Code}_${car.Name}`} value={car.Code}>{car.Name}</Option>))
                          }
                        </Select>
                      </Form.Item>
                      <Form.Item
                        label={<><span>指定技師</span><span style={{ paddingLeft: 4, color: "rgba(0, 0, 0, 0.45)", fontSize: 14 }}>(系統會優先媒合給該技師)</span></>}
                        name="assign_driver_row_id"
                      >
                        <Select
                          placeholder="請選擇指定技師"
                          style={{ width: "304px" }}
                          showSearch
                          allowClear
                          filterOption={(input, option) =>{
                            return (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
                          }
                          }
                        >
                          {
                            Drivers && Drivers.map(d => (<Option key={d.driver_row_id} value={d.driver_row_id}>{d.driver_name}</Option>))
                          }
                        </Select>
                      </Form.Item>
                     
                    </div>
                    <div style={{ fontSize: 16, fontWeight: 700 }}>營業時間</div>
                    <div style={{ background: "rgba(250, 250, 250, 1)", padding: " 0 16px" }}>
                      <div style={{ display: "flex", paddingTop: 16, alignItems: "center" }}>
                        <Form.Item
                          label="一般日"
                          name="weekday"
                          rules={[{ required: true, message: '請選擇一般日之營業時間！' }]}
                        >
                          <TimePicker.RangePicker format={"HH:mm"} disabled={weekDayOperatingHours && weekDayOperatingHours === "0" ? "disabled" : ""} />
                        </Form.Item>
                        <Checkbox style={{ marginLeft: 8 }} onChange={(e) => checkOperatingHour(e.target.checked, "weekday")} >不營業</Checkbox>
                      </div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Form.Item
                          label="週末"
                          name="weekend"
                          rules={[{ required: true, message: '請選擇週末之營業時間！' }]}
                        >
                          <TimePicker.RangePicker format={"HH:mm"} disabled={weekendOperatingHours && weekendOperatingHours === "0" ? "disabled" : ""} />
                        </Form.Item>
                        <Checkbox style={{ marginLeft: 8 }} onChange={(e) => checkOperatingHour(e.target.checked, "weekend")}>不營業</Checkbox>
                      </div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Form.Item
                          label="假日"
                          name="holiday"
                          rules={[{ required: true, message: '請選擇假日之營業時間！' }]}
                        >
                          <TimePicker.RangePicker format={"HH:mm"} disabled={holidayOperatingHours && holidayOperatingHours === "0" ? "disabled" : ""} />
                        </Form.Item>
                        <Checkbox style={{ marginLeft: 8 }} onChange={(e) => checkOperatingHour(e.target.checked, "holiday")}>不營業</Checkbox>
                      </div>
                    </div>
                  </>) :
                    currentStep === 1 ? (<>
                      <div style={{ display: "flex" }}>
                        <Form.Item
                          label="發票統編"
                          name="receipt_no"
                          rules={[{ required: true, message: '請輸入發票統編' },
                          {
                            pattern: /^[\d]{8}$/,
                            message: "請輸入8位數之公司統編",
                          }]}
                        >
                          <Input allowClear placeholder='example' style={{ width: "304px" }} />
                        </Form.Item>
                        <Form.Item
                          label="發票抬頭"
                          name="receipt_title"
                          style={{ margin: '0 16px' }}
                          rules={[{ required: true, message: '請輸入發票抬頭!' }]}
                        >
                          <Input allowClear placeholder='example' style={{ width: "304px" }} />
                        </Form.Item>
                      </div>
                      <div style={{ display: 'flex' }}>
                        <Form.Item
                          label="紙本發票收件地址"
                          name="receipt_address_county_arr"
                          // rules={[{ required: true, message: "請輸入聯絡地址！!" }]}
                        >
                          <CustomCascader
                            style={{ width: "304px" }}
                            OnChange={(val) => {
                              formRef.current.setFieldsValue({ receipt_address_county_arr: val })
                            }}
                          />
                        </Form.Item>
                        <div>
                          <div style={{ height: 34 }}></div>
                          <Form.Item
                            label=""
                            name="receipt_address"
                            // rules={[{ required: true, message: '請輸入地址！' }]}
                            style={{ margin: "0 16px" }}
                          >
                            <Input allowClear placeholder='請直接輸入地址' style={{ width: "304px" }} />
                          </Form.Item>
                        </div>
                        <Form.Item
                            label="發票收件E-mail"
                            name="receipt_email"
                            // rules={[{ required: true, message: '請輸入地址！' }]}
                          >
                            <Input allowClear placeholder='example' style={{ width: "304px" }} />
                          </Form.Item>
                      </div>
                      {/* <Form.Item
                        label="紙本發票收件地址"
                        name="receipt_address"
                        rules={[{ required: true, message: '請輸入紙本發票收件地址!' }]}
                      >
                        <CustomAutoComplete
                          placeholder="點擊輸入地址"
                          style={{ width: "304px" }}
                          ONChange={(val) => formRef.current.setFieldsValue({ receipt_address: val })}
                        />
                      </Form.Item> */}
                      <div style={{ display: "flex" }}>
                        <Form.Item
                          label="請款日期"
                          name="receive_day"
                          rules={[{ required: true, message: '請輸入請款日期!' }]}
                        >
                          <InputNumber
                            min={1} max={31}
                            allowClear placeholder='請輸入數字，例：15' style={{ width: "304px" }}
                            addonAfter="號"
                            // addonBefore={<Form.Item name="receive_mode" noStyle>
                            //   <Select >
                            //     <Option value="01">當月</Option>
                            //   </Select>
                            // </Form.Item>} 
                            addonBefore='當月' />
                        </Form.Item>
                        <Form.Item
                          label="付款方式"
                          name="pay_day"
                          style={{ margin: '0 16px' }}
                          rules={[{ required: true, message: '請輸入付款方式!' }]}
                        >
                          <Input 
                            allowClear 
                            placeholder={formRef.current.getFieldValue("pay_type")==='remit'?'請輸入匯款備註':'請輸入數字，例：15'} style={{ width: "304px" }}
                            addonBefore={<Form.Item name="pay_type" noStyle
                            >
                              <Select onChange={(val) => setPayType(val)}>
                                <Option value="check">支票</Option>
                                <Option value="remit">匯款</Option>
                              </Select>
                            </Form.Item>} addonAfter={PayType === "check" ? "個月支票" : false} />
                        </Form.Item>
                        {/* <Form.Item
                          label="付款方式"
                          name="pay_day"
                          style={{ margin: '0 16px' }}
                          rules={[{ required: true, message: '請輸入付款方式!' }]}
                        >
                          <Input allowClear placeholder='請輸入數字，例：15' style={{ width: "304px" }}
                            addonBefore={<Form.Item name="pay_type" noStyle>
                              <Select>
                                <Option value="check">支票</Option>
                                <Option value="remit">匯款</Option>
                              </Select>
                            </Form.Item>} addonAfter="個月支票" />
                        </Form.Item> */}
                      </div>
                    </>) :
                      (<>
                        <div style={{ fontSize: 30 }}>運送點
                          <span style={{ color: "#1890FF", fontSize: 30 }}> #{successSubmit.id} / {successSubmit.name}  </span> 已新增</div>
                        <div style={{ color: "rgba(0, 0, 0, 0.45)", marginTop: 8 }}>運送點已建立完成，還可以替運送點建置不同職位之人員</div>
                        <TextArea
                          placeholder="額外的運送點說明請寫在此......"
                          autoSize={{ minRows: 2, maxRows: 4 }}
                          style={{ width: 395, marginTop: 8 }}
                          value={remark}
                          onChange={(e) => setRemark(e.target.value)}
                        />
                        <Button
                          style={{ display: "block", marginTop: 8 }}
                          disabled={remark !== "" ? "" : "disabled"}
                          onClick={() => {
                            // let updated_uid = '00001'
                            let param = `row_id=${successSubmit.row_id}&note=${remark}`
                            UpdateCustomerStationRemark(param).then(data => {
                              if (data.Result.Success) {
                                setRemark("")
                                formRef.current.resetFields()
                                message.success('已新增備註')
                              }
                            })
                          }}
                        >新增備註</Button>
                        <div style={{ marginTop: 32 }}>
                          <Button
                            type="primary"
                            icon={<PlusOutlined />}
                            onClick={() => setCurrentStep(0)}
                          >新增其他運送點</Button>
                          <Button
                            icon={<RightOutlined />}
                            style={{ margin: "0 8px" }}
                            onClick={() => navigate(`/customer/lists/${state.customerId}/details`, { state: { ...state, id: successSubmit.row_id } })}
                          >運送點內容</Button>
                          <Button
                            icon={<UnorderedListOutlined />}
                            onClick={() => navigate(`/customer/lists/${state.customerId}`, { state })}
                          >回列表</Button>
                        </div>
                      </>)
                }
              </div>
            </div>

            {
              currentStep === 0 && (<div style={{ background: "white", marginTop: 16, width: "100%", padding: "20px", display: "flex", justifyContent: "flex-end" }}>
                <Popconfirm
                  style={{ width: 410 }}
                  placement="top"
                  title={
                    <div>
                      <div>
                        <strong>新增還沒完成喔</strong>
                      </div>
                      <div style={{ fontSize: 14 }}>
                        剛剛輸入的所有資訊將不被保存。
                      </div>
                    </div>
                  }
                  onCancel={() => navigate(`/customer/lists/${state.customerId}`, { state })
                  }
                  okText="不，我要留下"
                  cancelText="對，我要離開"
                >
                  <Button type="text" style={{ marginRight: 24 }} >
                    關閉
                  </Button>
                </Popconfirm>
                <Button type="primary"
                  htmlType="submit"
                >
                  下一步
                </Button>
              </div>)
            }
            {
              currentStep === 1 && (<div style={{ background: "white", marginTop: 16, width: "100%", padding: "20px", display: "flex", justifyContent: "flex-end" }}>
                <Button type="text" style={{ marginRight: 24 }} >關閉 </Button>
                <Button type="primary" icon={<PlusOutlined />} htmlType="submit">新增</Button>
              </div>)
            }
          </Form>
        </div>
      </div>
    </div>
  );
}

export default Add;

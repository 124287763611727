// 

const cars = (state = null , action) => {
    switch(action.type) {
        case 'FETCH_CAR_BRAND_SUCCESS':
            return action.data
        default:
            return state
    }
}

export default cars
import service from './Setting'
import {FetchData} from './Fetch'



//檢核客戶代號
export const CheckCustomerId = (id) => service.get(`/Customer/CheckCustomerId?id=${id}`);
//取得客戶清單
export const GetCustomerList = (param) => service.get(`/Customer/ListCustomer?${param}`);
//取得客戶歷程
export const GetCustomerHistory = (id,page,page_size) => service.get(`/Customer/CustomerHistory?page_size=${page_size}&page_index=${page}&row_id=${id}`);
//取得客戶資料
export const GetCustomerDetail = (id) => service.get(`/Customer/GetCustomer?row_id=${id}`);
//新增客戶
export const AddCustomer = (param) => service.get(`/Customer/AddCustomer?${param}`);
//更新客戶備註
export const AddCustomerRemark = (param) => service.get(`/Customer/UpdateCustomerNote?${param}`);
//更新客戶狀態(配合狀態)
export const UpdateCustomerStatus = (param) => service.get(`/Customer/UpdateCustomerStatus?${param}`);
//更新客戶資料
export const UpdateCustomerDetail = (param) => service.get(`/Customer/UpdateCustomer?${param}`);
//取得客戶員工列表
export const GetCustomerEmployeeList = (id,page,page_size) => service.get(`/Customer/ListCustomerEmplyeer?customer_row_id=${id}&page_size=${page_size}&page_index=${page}`);
//取得客戶員工詳細
export const GetCustomerEmployeeDetail = (id) => service.get(`/Customer/GetCustomerEmployee?row_id=${id}`);
//新增客戶人員
export const AddCustomerEmployee = (formData) => service.post(`/Customer/AddCustomerEmplyee`,formData);
//新增客戶人員備註
export const AddCustomerEmployeeNote = (param) => service.get(`/Customer/UpdateEmployeerNote?${param}`);
//更新客戶員工狀態（是否在職）
export const UpdateCustomerEmployeeStatus = (param) => service.get(`/Customer/UpdateEmployeerStatus?${param}`);
//更新客戶員工資訊
export const UpdateCustomerEmployee= (formData) => service.post(`/Customer/UpdateCustomerEmplyee`,formData);
//取得客戶員工歷程
export const GetCustomerEmployeeHistory = (param) => service.get(`/Customer/CustomerEmployeeHistory?${param}`);


//--------------------- 客戶運送點 ------------------------
//技師清單（模糊搜尋）
export const GetDriver = (search_txt) => service.get(`/Driver/List?search_txt=${search_txt}`);

//取得運送點清單
export const GetCommonPointList = (page) => service.get(`/CommonStation/ListStation?page=${page}`);
//檢核運送點名稱是否有重複
export const CheckStationName = (name,customer_row_id) => service.get(`/Station/CheckStationName?name=${name}&customer_row_id=${customer_row_id}`);
//取得運送點資料
export const GetStationDetail = (id,page) => service.get(`/Station/GetStation?row_id=${id}&page=${page}`);
//新增運送點
export const AddCustomerStation = (data) => service.post('/Station/AddStation',data);
// export const AddCustomerStation = (param) => FetchData(`/Station/AddStation`,'POST',param);
//更新運送點備註
export const UpdateCustomerStationRemark = (param) => service.get(`/Station/UpdateStationNote?${param}`);
//更新運送點資料
export const UpdateCustomerStation = (data) => service.post(`/Station/UpdateStation`,data);
//更新運送點狀態
export const UpdateStationReceiptInfo = (data) => service.post(`/Station/UpdateStationReceiptInfo`,data);
//更新運送點狀態
export const UpdateCustomerStationStatus = (param) => service.get(`/Station/UpdateStationStatus?${param}`);
//更新運送點歷程
export const GetStationHistory = (param) => service.get(`/Station/StationHistory?${param}`);
//取得運送點員工列表
export const GetStationEmployee = (id,page,page_size) => service.get(`/Station/ListStationEmplyeer?station_row_id=${id}&page_size=${page_size}&page_index=${page}`);
export const GetStationEmployeeDetail = (id) => service.get(`/Station/GetStationEmplyee?row_id=${id}`);
//新增運送點員工
export const AddStationEmployee = (param) => service.get(`/Station/AddStationEmplyee?${param}`);
//更新運送點員工備註
export const UpdateStationEmployeeNote = (param) => service.get(`/Station/UpdateEmployeerNote?${param}`);
//更新運送點員工狀態（在職/離職）
export const UpdateStationEmployeeStatus = (param) => service.get(`/Station/UpdateEmployeerStatus?${param}`);
//更新運送點員工資料
export const UpdateCustomerStationEmployee = (param) => service.get(`/Station/UpdateStationEmplyee?${param}`);
//更新運送點員工歷程
export const GetStationEmployeeHistory = (param) => service.get(`/Station/StationEmplyeeHistory?${param}`);



//--------------------- 客戶計價模組 ------------------------




//新增計價模組
export const AddCustomerPricingModule = (formData) => service.post(`/Price/AddPrice`,formData);
//複製計價模組
export const CopyCustomerPricingModule = (source_price_row_id,target_price_row_id) => service.get(`/Price/CopyPrice?source_price_row_id=${source_price_row_id}&target_price_row_id=${target_price_row_id}`);
//更新計價模組備註
export const UpdateStationPriceNote = (param) => service.get(`/Price/UpdatePriceNote?${param}`);
//取得計價模組
export const CustomerPricingModuleDetail = (id) => service.get(`/Price/GetPrice?row_id=${id}`);
//取得計價模組- 合約檔案路徑
export const CustomerPricingModuleDoc= (id) => service.get(`/Price/GetPriceDoc?row_id=${id}`);
//更新計價模組
export const UpdateCustomerPricingModuleDetail = (formData) => service.post(`/Price/UpdatePrice`,formData);
//終止計價模組方案
export const ClosePriceModule = (param) => service.get(`/Price/ClosePrice?${param}`);




export const GetCustomerPricingModule = (id,status,page,page_size) => service.get(`/Price/ListPrice?customer_row_id=${id}&status=${status}&page_size=${page_size}&page_index=${page}`);
//取得計價模組清單
//1.起迄點計價
    //a.取得起迄點計價
    export const GetStartEndPointPrice= (id,page_size,page_index) => service.get(`/Price/StartEndPoint/List?price_row_id=${id}&page_size=${page_size}&page_index=${page_index}`);
    //新增起迄點計價
    export const AddStartEndPointPrice= (param) => service.get(`/Price/StartEndPoint/Add?${param}`);
     //新增起迄點計價
     export const UpdateStartEndPointPrice= (param) => service.get(`/Price/StartEndPoint/Update?${param}`);
 //更新里程計價
//2.里程計價
    //a.取得里程計價列表
    export const GetKMPrice= (id,page_size,page_index) => service.get(`/Price/KM/List?price_row_id=${id}&page_size=${page_size}&page_index=${page_index}`);
      //b.取得里程計價
    //   export const GetKMPrice= (id) => service.get(`/Price/KM/List?price_row_id=${id}&page_size=${page_size}&page_index=${page_index}`);
    //新增里程計價
    export const AddKMPrice= (param) => service.get(`/Price/KM/Add?${param}`);
    //更新里程計價
    export const UpdateKMPrice= (param) => service.get(`/Price/KM/Update?${param}`);
//3.區域階梯式計價
    //a.取得區域階梯式計價
    export const GetMatrixPrice= (id,page_size,page_index) => service.get(`/Price/Matrix/List?price_row_id=${id}&page_size=${page_size}&page_index=${page_index}`);
     //b.取得區域階梯式縣市
     export const GetMatrixPriceCityList= (id) => service.get(`/Price/Matrix/ListTown?price_row_id=${id}`);
    //b.新增區域階梯式縣市
    export const AddMatrixPriceCity= (param) => service.get(`/Price/Matrix/AddCity?${param}`);
    //c.刪除區域階梯式縣市
    export const DeleteMatrixPriceCity= (param) => service.get(`/Price/Matrix/DeleteCity?${param}`);
    //d.更新區域階梯式
    export const UpdateMatrixPrice= (param) => service.get(`/Price/Matrix/Update?${param}`);
//4.包月計價
    //a.取得包月計價   
    export const GetPackagePrice= (id,page_size,page_index) => service.get(`/Price/Package/List?price_row_id=${id}&page_size=${page_size}&page_index=${page_index}`);
    //新增里程計價
    export const AddPackagePrice= (param) => service.get(`/Price/Package/Add?${param}`);
    //更新里程計價
    export const UpdatePackagePrice= (param) => service.get(`/Price/Package/Update?${param}`);

//取得個別加價欄位
//1. 特殊作業
    //a.取得明細
    export const GetPriceAddOnSpecial= (id) => service.get(`/PriceSingle/Special/List?price_row_id=${id}`);
    //b.更新特殊作業加價
    export const UpdatePriceAddOnSpecial= (param) => service.get(`/PriceSingle/Special/Update?${param}`);
    //c.刪除特殊作業加價
    export const DeletePriceAddOnSpecial= (param) => service.get(`/PriceSingle/Special/Delete?${param}`);
//2.時間相關
    //a.取得明細
    export const GetPriceAddOnTime= (id) => service.get(`/PriceSingle/Time/List?price_row_id=${id}`);
    //b.更新時間相關加價
    export const UpdatePriceAddOnTime= (param) => service.get(`/PriceSingle/Time/Update?${param}`);
    //c.刪除時間相關加價
    export const DeletePriceAddOnTime= (param) => service.get(`/PriceSingle/Time/Delete?${param}`);

//3.樓層運送
    //a.取得明細
    export const GetPriceAddOnFloor= (id) => service.get(`/PriceSingle/Floor/List?price_row_id=${id}`);
    //b.更新樓層運送加價
    export const UpdatePriceAddOnFloor= (param) => service.get(`/PriceSingle/Floor/Update?${param}`);
    //c.刪除樓層運送加價
    export const DeletePriceAddOnFloor= (param) => service.get(`/PriceSingle/Floor/Delete?${param}`);

//4.偏遠地區
    //a.取得明細
    export const GetPriceAddOnArea= (id) => service.get(`/PriceSingle/Area/List?price_row_id=${id}`);
        //i.更新區域計價 - 區域計價
        export const UpdatePriceAddOnArea= (param) => service.get(`/PriceSingle/Area/UpdateArea?${param}`);
         //ii.更新區域計價 -刪除區域計價
         export const DeletePriceAddOnArea= (param) => service.get(`/PriceSingle/Area/DeleteArea?${param}`);

        //ii.更新區域計價 -里程計價
        export const UpdatePriceAddOnKM= (param) => service.get(`/PriceSingle/Area/UpdateKM?${param}`);
         //ii.新增區域計價 -里程計價鄉鎮
         export const AddPriceAddOnKMTown= (param) => service.get(`/PriceSingle/Area/UpdateKMTown?${param}`);
         //ii.刪除區域計價 -里程計價鄉鎮
         export const DeletePriceAddOnKMTown= (param) => service.get(`/PriceSingle/Area/DeleteKMTown?${param}`);

    //b.更改模式 (area/km)    
    export const ChangePriceAddOnAreaMode= (param) => service.get(`/PriceSingle/Area/ChangeMode?${param}`);


//5.車款加價
    //a.取得明細
    export const GetPriceAddOnCar= (id) => service.get(`/PriceSingle/Car/List?price_row_id=${id}`);
    //b.更新車款加價
    export const UpdatePriceAddOnCar= (param) => service.get(`/PriceSingle/Car/Update?${param}`);
    //c.刪除車款加價
    export const DeletePriceAddOnCar= (param) => service.get(`/PriceSingle/Car/Delete?${param}`);
//6.其他
    //a.取得明細
    export const GetPriceAddOnOther= (id) => service.get(`/PriceSingle/Other/List?price_row_id=${id}`);
    //b.更新其他加價
    export const UpdatePriceAddOnOther= (param) => service.get(`/PriceSingle/Other/Update?${param}`);
    //c.刪除其他加價
    export const DeletePriceAddOnOther= (param) => service.get(`/PriceSingle/Other/Delete?${param}`);




//--------------------- 共同運送管理 ------------------------

//取得共同運送清單
export const GetCommonPoint = (id) => service.get(`/CommonStation/GetStation?row_id=${id}`);
//新增共同運送
export const AddCommonStation = (param) => service.post(`/CommonStation/AddStation`,param);
//更新共同運送資料
export const UpdateCommonStationDetail = (param) => service.get(`/CommonStation/UpdateStation?${param}`);
//共同運送歷程
export const GetCommonStationHistory = (param) => service.get(`/CommonStation/StationHistory?${param}`);

//更新共同運送備註
export const UpdateCommonStationRemark= (param) => service.get(`/CommonStation/UpdateStationNote?${param}`);


//更新共同運送狀態
export const UpdateCommonStationStatus= (param) => service.get(`/CommonStation/UpdateStationStatus?${param}`);




import React,{useState,useEffect} from 'react';
import { PageHeader, Steps} from 'antd' 
import BreadCrumb from '../../components/shared/BreadCrumb'
import Step1 from '../../components/cases/add/step1'
import Step2 from '../../components/cases/add/step2'
import Step3 from '../../components/cases/add/step3'
import {useNavigate,useLocation} from 'react-router-dom'
// import {GetCheckCaseInfo} from '../../api/Case'
// import { useSelector,useDispatch } from 'react-redux'
const { Step } = Steps

const steps = [
    { title: '案件內容', description: '請輸入案件的基本資訊', content: 'First-content', width: "30%" },
    { title: '最後確認', description: '覆核剛輸入的內容及確認非必要欄位', content: 'Second-content',
      width: "100%" },
    { title: '完成', description: '完成後即可手動派單', content: 'Last-content', width: "30%"},
  ];
const Add = ({pageTitle, ContainerWidth}) => {
    const [currentStep, setCurrentStep] = useState(0)
    const [caseId, setCaseId] = useState(null)
    const [caseData, setCaseData] = useState(null)
    const [draft, setDraft] = useState(false)
    
    const navigate = useNavigate()
    // let CarInfoData = useSelector(state => state.Cases)
    // const dispatch = useDispatch()
    // state && state.step ? state.step:
    // state.caseStatus? parseInt(state.caseStatus):
    useEffect(() => {
      document.title = pageTitle
    }, []);
    useEffect(() => {
        let container = document.querySelector(".container")
        container.scrollTo(0,0)
    }, [currentStep])
    return (
        <div className='container' style={ContainerWidth}>
             <PageHeader
            title="案件新增"
            onBack={() => currentStep === 0 ? navigate(-1) : setCurrentStep(currentStep-1)}
            className="site-page-header"
            breadcrumb={<BreadCrumb/>}
        >
        </PageHeader>
       
        <div className='step_block' >
            <Steps current={currentStep} style={{padding: "16px 24px", borderBottom: "1px solid rgba(0, 0, 0, 0.06)", margin: "0 24px", background: "white", width: "calc(100% - 48px)"}}>
                {steps.map(item => (
                <Step key={item.title} title={item.title} description={item.description} style={{width : item.width}}/>
                ))}
            </Steps>
            <div >
                {
                    currentStep === 0? (<Step1 currentStep={currentStep} setCurrentStep={(step)=> setCurrentStep(step)}/>) : ""
                }
                {
                    currentStep === 1? (<Step2 setCurrentStep={(data)=> {
                        setCaseId(data.caseId)
                        setCurrentStep(data.currentStep)
                        setDraft(data.draft)
                        setCaseData(data.caseData)
                    }} />) : ""
                }
                {
                    currentStep === 2 && caseData !== null? (<Step3  
                    caseId={caseId}
                    caseData={caseData}
                    draft={draft}
                    setCurrentStep={(step)=> setCurrentStep(step)}
                    />) : ""
                }
            </div>
        </div>
        {/* {
            currentStep === 0 || currentStep === 1 ? (<div style={{background: "white", marginTop: 16, width: "100%", padding: "20px", display: "flex", justifyContent: "flex-end"}}>
                <Button type="text" style={{marginRight: 24}} >
                    關閉
                </Button>   
                <Button type="primary" icon={<ArrowRightOutlined />} 
                >
                下一步
                </Button>
            </div>): ""
        } */}
        </div>
    );
}

export default Add;

import React,{useState,useEffect} from 'react';
import { Table, Input, Radio, Button,Form,Select,Cascader } from 'antd';
import {RightOutlined,PlusOutlined} from '@ant-design/icons';
import ExpandRow from './ExpandRow' 
import { useSelector, useDispatch } from 'react-redux'
import { addCase } from '../../../actions/cases'
const { Option } = Select

const insert = (arr, index, newItem) => [
  // part of the array before the specified index
  ...arr.slice(0, index),
  // inserted item
  newItem,
  // part of the array after the specified index
  ...arr.slice(index)
]
  
const Carinfo = ({title,carInfos,error,state}) => {
    let intialData = useSelector(state => state.Cases.cases?state.Cases.cases : state.Cases)
    const [expandKey, setExpandKey] = useState([])
    let DispatchCarBrand = useSelector(state => state.CarBrand) 
    
    const [data, setData] = useState([])
    const [CarBrand, setCarBrand] = useState([])
    const [carModel, setCarModel] = useState([])
    const [currentExpandRowKey , setCurrentExpandRowKey] = useState([]);
    const dispatch = useDispatch()
    useEffect(()=>{
      if(intialData){
        if(!intialData.length ){
          if(intialData.cases){
            intialData = intialData.cases.cases?intialData.cases.cases:intialData.cases
          }
        }
        if(intialData && intialData.length>1){
          let filter = intialData.filter(d=>d.no ==='不可行駛原因')
          setCurrentExpandRowKey(filter.map(d=>d.key))
          setExpandKey(filter.map(d=>d.key))
        }
        setData(intialData)
      }
    },[intialData])
    useEffect(() => {
      if(DispatchCarBrand!==null){
        setCarBrand(DispatchCarBrand)
        let obj={}
        DispatchCarBrand.forEach(brand=>{
          obj[brand.Name] = brand.children
        })
        setCarModel(obj)
      }
    }, [DispatchCarBrand]);
    const radioGroup = (e,record) => {
      let value = e.target.value;
      let key = record.key;
      let index;
      let initial_index;
      let ini_data = [...data];
      let new_expandKey = [...expandKey]
      let new_currentExpandRowKey = [...currentExpandRowKey];

      ini_data.forEach( (d,i) => {
        if(d.key === key){
          index = i+1;
          initial_index= i===0? 1 :index
          d.mobility = value;
        }
      })
      if(value === "0"){
        new_expandKey.push(`no${initial_index}`);
        ini_data =  insert(ini_data, index, { key: `no${initial_index}`, no: '不可行駛原因', recordMethod: "0"})
        new_currentExpandRowKey.push(`no${initial_index}`)
        
      }else{
        ini_data.splice(index,1)
        new_expandKey.filter( i => i !== `no${initial_index}`);
        new_currentExpandRowKey =new_currentExpandRowKey.filter(i => i !== `no${initial_index}`)

      }

      setExpandKey(new_expandKey)
      setData(ini_data);
      dispatch(addCase(ini_data))
      carInfos(ini_data)

    };
    const onInputChange = (e, key, record, index)=>{
      let change_val = key === "CarType" || key === "CarBrand"? e : e.target.value
      let new_data = [...data];
      new_data.map( d => {
        if( d.key === record.key){
          d[key]= change_val;
          return d;
        }
      })
  
      dispatch(addCase(new_data))
      carInfos(new_data)
    }
   
    const onDelete = (record ) =>{
      let new_data = [...data];
      if(new_data.length>1){
        //check mobility
        let mobility = (new_data.filter(d => d.key === record.key))[0]["mobility"]
        new_data = new_data.filter(d => d.key !== record.key)
        if(mobility === "0"){
          let filter_index; 
          data.forEach((d,i)=>{
            if(d.key === record.key){
              filter_index = i+1
            }
          })
          let filter_name = data[filter_index]["key"];
          new_data = new_data.filter(d => d.key !== filter_name)
        }
        let final_data = new_data.map(n => {
          if(n.key > record.key){
              n.no -=1;
          }
          return n
        })
        dispatch(addCase(final_data))
        setData(new_data)
      }
     
    }
    const onCopy = (record) =>{
      let new_data = [...data];
      let index_arr = []
      new_data.forEach(d => {
        if(d.no !== "不可行駛原因"){
            index_arr.push(d.no);
        }
      })
      let max_index = Math.max(...index_arr)
      let obj = {
        key: max_index + 1,
        no: max_index + 1,
        mobility: "1",
        CarBrand: record.CarBrand
      }
      if(record.CarType){
        obj["CarType"] = record.CarType
      }
      new_data.push(obj)
      setData(new_data)
      dispatch(addCase(new_data))
      carInfos(new_data)

    }
      const columns = [
        {
          title: '#',
          dataIndex: 'no',
          width: '4%',
          editable: true,
          required: false,
          render: (_,index) => {
            if( _ === "不可行駛原因"){
              return {
                children: <><span style={{paddingRight: 8}}>{_}</span><RightOutlined /> </>,
                props: {
                  colSpan:6,
                },
              };
            }else{
              return( <span>{_}</span>)
            }
          },
        },
        {
          title: <><span style={{color: "#ff4d4f"}}>*</span><span>廠牌</span></>,
          dataIndex: 'type',
          key: 'CarBrand',
          require: true,
          width: '15%',
          editable: true,
          required: true,
          val: (_,record)=> (record),
          render: (_,record, index) =>{
            if(record.no !== "不可行駛原因"){
              let name = `carBrand${record.key}`
              return (
                <>
                  <Form.Item
                    label=""
                    name={name}
                    rules={[{ required: true, message: '請選擇或輸入廠牌!' }]}
                    style={{marginBottom: "0"}}
                  >
                  {
                    error && error.length>0 && (!_ || _==="") ?(<div style={{height: 20}}></div>):(<div></div>)
                  }
                  <Select 
                  placeholder="點擊選擇" 
                  showSearch
                  value={record.CarBrand}
                  onChange={(val)=>{
                    // console.log(val)
                      // let model = CarBrand.filter(c=>c.Name === val)
                      // if(model.length>0) {
                      //   setCarModel(model[0]["children"])
                      // }else{
                      //   setCarBrand([])
                      // }
                      onInputChange(val,"CarBrand",record)
                      onInputChange('',"CarType",record)
                  }}>
                  
                    {
                      CarBrand.map(c=>(<Option value={c.Name}>{c.Name}</Option>))
                    }
                  </Select>
                   
                  {/* <Cascader
                    options={CarBrand}
                    allowClear
                    onChange={(val) => onInputChange(val[0],"CarType",record)}
                    defaultValue={record.CarType}
                    placeholder="點擊選擇"
                    showSearch={(inputValue, path) => {
                      return path.some(
                        (option) =>
                          option.label
                            .toLowerCase()
                            .indexOf(inputValue.toLowerCase()) > -1
                      );
                    }}
                  /> */}

                  </Form.Item>
                </>
                )
              }else{
                return  {
                  children: <></>,
                  props: {
                    colSpan:0,
                  },
                }
              }
            }
          },
          {
            title: <><span style={{color: "#ff4d4f"}}>*</span><span>車型</span></>,
            dataIndex: 'type',
            key: 'CarType',
            require: true,
            width: '20%',
            editable: true,
            required: true,
            val: (_,record)=> (record),
            render: (_,record, index) =>{
              if(record.no !== "不可行駛原因"){
                let name = `carType${record.key}`
                return (
                  <>
                    <Form.Item
                      label=""
                      name={name}
                      rules={[{ required: true, message: '請選擇或輸入車型!' }]}
                      style={{marginBottom: "0"}}
                    >
                    {
                      error && error.length>0 && (!_ || _==="") ?(<div style={{height: 20}}></div>):(<div></div>)
                    }
                    <Cascader
                      changeOnSelect
                      options={record.CarBrand?carModel[record.CarBrand]:[]}
                      allowClear
                      onChange={(val) => onInputChange(val[0],"CarType",record)}
                      value={record.CarType}
                      placeholder="點擊選擇"
                      showSearch={(inputValue, path) => {
                        return path.some(
                          (option) =>
                            option.label
                              .toLowerCase()
                              .indexOf(inputValue.toLowerCase()) > -1
                        );
                      }}
                    />
  
                    </Form.Item>
                  </>
                  )
                }else{
                  return  {
                    children: <></>,
                    props: {
                      colSpan:0,
                    },
                  }
                }
              }
            },
        {
          title: '車牌 / 引擎 / 車身號碼',
          dataIndex: 'plate',
          key: 'VIN',
          width: '20%',
          editable: true,
          required: false,
          val: "",
          render: (_,record) =>{
            if(record.no !== "不可行駛原因"){
             return <Input value={record && record.VIN ? record.VIN:""} placeholder="點擊輸入" onChange={(e)=> onInputChange(e,"VIN",record)} />
            }else{
              return  {
                children: <></>,
                props: {
                  colSpan:0,
                },
              }
            }
          }
        },
        {
            title: '車身顏色',
            dataIndex: 'color',
            key: 'color',
            width: '15%',
            editable: true,
            required: false,
            val: "",
            render: (_,record) =>{
              if(record.no !== "不可行駛原因"){
               return <Input value={record && record.color ? record.color:""} placeholder='點擊輸入'  onChange={(e)=> onInputChange(e,"color",record)} />
              }else{
                return  {
                  children: <></>,
                  props: {
                    colSpan:0,
                  },
                }
              }
            }
        },
        {
          title: '動力狀況',
          dataIndex: 'mobility',
          width: '204px',
          required: false,
          render: (_,record) => {
            if(record.no !== "不可行駛原因"){
              // let name = `unworked${record.key}`
              return  (
                <>
                  <Radio.Group value={record.mobility} buttonStyle="solid" 
                  onChange={
                    (e)=> {
                      radioGroup(e,record);
                  }}
                  >
                    <Radio.Button value="1">正常</Radio.Button>
                    <Radio.Button value="0">無動力</Radio.Button>
                  </Radio.Group> 
                {/* </Form.Item> */}
                </>
              )
            }else{
              return  {
                children: <></>,
                props: {
                  colSpan:0,
                },
              }
            }
          },

        },
        {
            title: '',
            dataIndex: 'function',
            render: (_ ,record) => {
              if(!state || (state&&state.case_status!==99)){
                if(record.no !== "不可行駛原因"){
                  return  (
                  
                    <>
                      <span 
                        style={{color: "#1890FF", cursor:"pointer"}}
                        onClick={()=> onCopy(record)}
                      >複製</span>
                      <span 
                        style={{ marginLeft: "8px", paddingLeft: "8px", borderLeft: "1px solid rgba(0,0,0,0.05)", color:"rgba(0,0,0,0.25)", cursor:"pointer" }} 
                        onClick={()=> onDelete(record)}
                        >刪除</span> 
                   </>
                  )
                }else{
                  return  {
                    children: <></>,
                    props: {
                      colSpan:0,
                    },
                  }
                }
              }
             
            }
             
        }
      ];  
    
    return (
        <>
            <div style={{marginBottom: "8px"}}>
            <span style={{color: "#ff4d4f", fontSize: "14px", marginRight: "4px"}}>*</span>
            <span >{title}</span>
            </div>
      
            <Table 
            rowKey={record => record.no === "不可行駛原因" ?  record.key : record.no  }
            columns={columns} 
            dataSource={data} 
            pagination={false} 
            expandRowByClick={true}
            expandIcon={false}
            expandIconColumnIndex={-1}
            expandable ={{
              expandedRowRender : record => record.no === "不可行駛原因" ? (<ExpandRow record={record} setRecord={(record)=> {
                let index_ = record.key.split("no");
                let index = parseInt(index_[1])
                let new_data = [...data].map(d => {
                  if(d.key === index){
                    d.unpower = record;
                  }
                  return d
                })
                setData(new_data)
              }}/>): "",
              rowExpandable : record => record.no === "不可行駛原因",
              expandedRowKeys : currentExpandRowKey,
              onExpand: (_,record) => {
                let key = record.key
                let new_currentExpandRowKey_ = [...currentExpandRowKey]
                if(currentExpandRowKey.includes(key)){
                  new_currentExpandRowKey_= new_currentExpandRowKey_.filter( r => r !== record.key);
                }else{
                  new_currentExpandRowKey_.push(key)
                }
                setCurrentExpandRowKey(new_currentExpandRowKey_)
                }
            }}
            onRow = {
              ({key}) => expandKey.includes(key) && {className: "expand-parent"} 
              }  
            />
            {
              !state || (state&&state.case_status!==99) ? (
                <Button 
              style={{marginTop: "16px", marginBottom: 24}} 
              type="dashed" 
              block
              onClick={()=>{
                let new_data = [...data];
                let index_arr = []
                new_data.forEach(d => {
                  if(d.no !== "不可行駛原因"){
                    index_arr.push(d.no);
                  }
                })
                let max_index = Math.max(...index_arr)
                let obj = {
                      key: max_index + 1,
                      no: max_index + 1,
                      mobility: "1",
                    }
                  new_data.push(obj);
                  setData(new_data)
                  dispatch(addCase(new_data))
              }}
            ><PlusOutlined /> 新增車輛</Button>
              ):(<></>)
            }
            
        </>
    );
}

export default Carinfo;



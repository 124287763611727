import React, { useEffect, useState } from 'react'
import { PageHeader, Steps } from 'antd'
import BreadCrumb from '../../components/shared/BreadCrumb'
import Step1 from '../../components/points/add/step1'
import Step2 from '../../components/points/add/step2'
import Step3 from '../../components/points/add/step3'

const { Step } = Steps;
const steps = [
  { title: '基本資訊', description: '請輸入據點的基本資訊', content: 'First-content', width: "30%" },
  { title: '派遣規則', description: '並確認該據點的派遣條件', content: 'Second-content', width: "100%" },
  { title: '完成', description: '完成後即可派遣', content: 'Last-content', width: "30%" },
];
const Add = ({ pageTitle, ContainerWidth }) => {
  const [currentStep, setCurrentStep] = useState(0)
  const [FirstStepData, setFirstStepData] = useState(null)
  const [data, setData] = useState(null)
  
  useEffect(() => {
    document.title = pageTitle
  }, [])
  return (
    <div className='container' style={ContainerWidth}>
      <PageHeader
        title="新增據點"
        onBack={() => currentStep === 0 ? window.history.go(-1) : setCurrentStep(currentStep - 1)}
        className="site-page-header"
        breadcrumb={<BreadCrumb />}
      >
      </PageHeader>
      <div className='step_block'>
        <Steps current={currentStep} style={{ padding: "16px 24px", borderBottom: "1px solid rgba(0, 0, 0, 0.06)", margin: "24px 24px 0 24px", background: "white", width: "calc(100% - 48px)" }}>
          {steps.map(item => (
            <Step key={item.title} title={item.title} description={item.description} style={{ width: item.width }} />
          ))}
        </Steps>
        <div >
          {
            currentStep === 0 ? (<Step1 
              setCurrentStep={(step) => setCurrentStep(step)} 
              setData={(value)=>setFirstStepData(value)}
              data={FirstStepData}
            />) : ""
          }
          {
            currentStep === 1 ? (<Step2 
            setCurrentStep={(step) => setCurrentStep(step)} 
            data={FirstStepData}
            setSubmitData={(val)=> {
              setData(val)
              setFirstStepData(null)
            }}/>) : ""
            
          }
          {
            currentStep === 2 ? (<Step3 
              data={data} 
              setCurrentStep={(step) => setCurrentStep(step)} 
            />) : ""
          }
        </div>
      </div>
    </div>
  );
}

export default Add;

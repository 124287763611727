import React, { useState } from 'react'
import { Input, Button,message } from 'antd'
import { UnorderedListOutlined, PlusOutlined, RightOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import { UpdteStationNote } from '../../../api/ServicePoint'
const { TextArea } = Input

const Step3 = ({ data,setCurrentStep }) => {
    const [remark, setRemark] = useState("")
    // const [fetchResult, setFetchResult] = useState(null)
    let navigate = useNavigate()
    return (
        <div>
            {
                data && (
                    <div className='step_content'>
                        <div style={{ fontSize: 30 }}>
                            據點
                            <span style={{ color: "rgba(24, 144, 255, 1)", fontSize: 30 }}>
                                #{data.id} / {data.name}</span>
                            已新增</div>
                        <div style={{ color: "rgba(0, 0, 0, 0.45)", margin: "8px 0" }}>據點已建立完成，請接著建立技師、出勤、車籍資料等才能開始派遣。</div>
                        <TextArea
                            placeholder="額外的據點內容說明請寫在此......"
                            autoSize={{ minRows: 2, maxRows: 4 }}
                            style={{ width: 395 }}
                            value={remark}
                            onChange={(e) => setRemark(e.target.value)}
                        />
                        <Button
                            style={{ display: "block", marginTop: 8 }}
                            disabled={remark !== "" ? "" : "disabled"}
                            onClick={() => {
                                // let updated_uid = '00001'
                                let param = `row_id=${data.row_id}&note=${remark}`
                                UpdteStationNote(param).then(d => {
                                if (d.Result.Success) {
                                    setRemark("")
                                    message.success('已新增備註')
                                }
                                })
                            }}
                        >
                            新增備註
                        </Button>
                        <div style={{ marginTop: 32 }}>
                            <Button
                                type="primary"
                                icon={<PlusOutlined />}
                                onClick={() =>  setCurrentStep(0)}
                            >新增據點</Button>
                            <Button 
                            icon={<RightOutlined />} 
                            onClick={() => navigate(`/points/${data.row_id}`)}
                            style={{ margin: "0 8px" }}
                            >據點內容</Button>
                            <Button
                                icon={<UnorderedListOutlined />}
                                onClick={() => navigate("/points")}
                            >回列表</Button>
                        </div>
                    </div>
                )
            }
        </div>
    )
}

export default Step3;

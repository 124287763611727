import React, { useState } from "react";
import { Collapse, Tag, Modal,Carousel } from "antd";
import { EditOutlined, DownloadOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
const { Panel } = Collapse;
const Card = ({ header, data, remarkStatus }) => {
  const [openModal, setOpenModal] = useState(null);
  let navigate = useNavigate();
  if (data !== null) {
    for (let key in data) {
      if (!data[key]) {
        data[key] = "";
      }
    }

    return (
      <>
        <div>
          <ul className="case_table">
            {header.map((h, i) => {
              let key = h.key;
              //若 data[key] 為字串或數字，直接顯示值
              // data[key]["type"] === 1. accordian; 2. tel; 3. tag, 4. secondaryTag
              if (key !== "") {
                return (
                  <li
                    style={{
                      display: "flex",
                      borderBottom: "1px solid rgba(240, 240, 240, 1)",
                    }}
                    key={key}
                  >
                    <div
                      style={{
                        width: "160px",
                        background: "#FAFAFA",
                        padding: 8,
                      }}
                    >
                      {h.name}
                    </div>
                    {(typeof data[key] === "string" ||
                      typeof data[key] === "number") && (
                      <div style={{ padding: 8, width: "calc(100% - 160px)" }}>
                        {data[key] === "" ? "-" : data[key]}
                      </div>
                    )}

                    {data[key]["type"] === "accordian" && (
                      <div
                        style={{
                          width: "calc(100% - 160px)",
                          height: "100%",
                          padding:
                            data[key]["text"] === "" ? "8px 0" : "8px 0 0 0",
                        }}
                      >
                        {data[key]["text"] === "" ||
                        data[key]["text"] === null ? (
                          <div style={{ padding: "0 8px" }}>-</div>
                        ) : (
                          <Collapse expandIconPosition={"right"}>
                            <Panel header={`$${data[key]["text"]}`} key={i}>
                              {data[key]["subdata"].map((sub, i) => (
                                <p>{`${sub.type} / $${sub.amount}`}</p>
                              ))}
                            </Panel>
                          </Collapse>
                        )}
                      </div>
                    )}
                    {data[key]["type"] === "icons" && (
                      <div
                        style={{
                          padding: 8,
                          display: "flex",
                          alignItems: "center",
                          width: "calc(100% - 160px)",
                        }}
                      >
                        {data[key]["navigate"] ? (
                          <div style={{ position: "relative", width: "100%" }}>
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              {data[key]["color"] && (
                                <div
                                  style={{
                                    width: 6,
                                    height: 6,
                                    borderRadius: "50%",
                                    background: data[key]["color"],
                                    marginRight: 8,
                                  }}
                                ></div>
                              )}

                              <p>{data[key]["title"]}</p>
                            </div>

                            <p
                              onClick={() => navigate(data[key]["navigate"])}
                              style={{
                                color: "rgba(0,0,0,0.25)",
                                paddingLeft: 8,
                                position: "absolute",
                                right: 0,
                                top: "50%",
                                transform: "translateY(-50%)",
                                cursor: "pointer",
                              }}
                            >
                              {data[key]["icon"]}
                            </p>
                          </div>
                        ) : (
                          <>
                            {data[key]["title"] && data[key]["label"] !== "" ? (
                              <p>
                                {data[key]["title"]} - {data[key]["label"]}
                              </p>
                            ) : data[key]["title"] ? (
                              <p>{data[key]["title"]}</p>
                            ) : data[key]["label"] ? (
                              <p>{data[key]["label"]}</p>
                            ) : (
                              <p>-</p>
                            )}
                            {data[key]["label"] === "" &&
                            data[key]["title"] === "" ? (
                              <p></p>
                            ) : data[key]["link"] &&
                              data[key]["icon"] !== "" ? (
                              <a
                                href={`${data[key]["link"]}:${
                                  data[key]["label"] ? data[key]["label"] : "-"
                                }`}
                                style={{
                                  color: "rgba(0,0,0,0.25)",
                                  paddingLeft: 8,
                                }}
                              >
                                {data[key]["icon"]}
                              </a>
                            ) : (
                              <a
                                href="javascript: void(0)"
                                style={{
                                  color: "rgba(0,0,0,0.25)",
                                  paddingLeft: 8,
                                }}
                                onClick={() => {
                                  let d = data[key]
                                  if(data[key]){
                                    setOpenModal({title:"", sliderImage:true,data:data[key]["photoArr"], url:data[key]["url"]})
                                    // console.log(data[key]["photoArr"])
                                   

                                  }
                                }}
                              >
                                
                                {data[key]["icon"]}
                              </a>
                            )}
                          </>
                        )}
                      </div>
                    )}
                    {data[key]["type"] === "operatingHour" && (
                      <div style={{ padding: 8, width: "calc(100% - 160px)" }}>
                        {data[key]["content"].map((d) => (
                          <p>{d}</p>
                        ))}
                      </div>
                    )}

                    {data[key]["type"] === "tag" && (
                      <div
                        style={{
                          padding: 8,
                          display: "flex",
                          alignItems: "center",
                          flexWrap: "wrap",
                          width: "calc(100% - 160px)",
                          gridGap: 8,
                        }}
                      >
                        {data[key]["content"].length === 0
                          ? "-"
                          : data[key]["content"].map((tag) => (
                              <Tag
                                color={data[key]["color"]}
                                icon={
                                  data[key]["icon"] ? data[key]["icon"] : ""
                                }
                                style={{
                                  cursor: data[key]["type"]
                                    ? "pointer"
                                    : "default",
                                }}
                                onClick={() => {
                                  if (data[key]["download"])
                                    window.open(data[key]["download"]);
                                }}
                              >
                                {tag}
                              </Tag>
                            ))}
                      </div>
                    )}
                    {data[key]["type"] === "files" && (
                      <div
                        style={{
                          padding: 8,
                          display: "flex",
                          alignItems: "center",
                          width: "calc(100% - 160px)",
                        }}
                      >
                        {data[key]["title"] ? (
                          <Tag
                            style={{
                              cursor: data[key]["download"]
                                ? "pointer"
                                : "default",
                            }}
                            onClick={() => {
                              if (data[key]["download"])
                                window.open(data[key]["download"]);
                            }}
                            icon={
                              data[key]["download"] ? (
                                <DownloadOutlined />
                              ) : (
                                data[key]["icon"]
                              )
                            }
                            color="default"
                          >
                            {" "}
                            {typeof data[key]["title"] === "string"
                              ? data[key]["title"]
                              : `${data[key]["title"][0]} / ${data[key]["title"][1]}`}{" "}
                          </Tag>
                        ) : (
                          data[key]["data"].map((d) => (
                            <Tag
                              key={d.id}
                              icon={data[key]["icon"]}
                              color="default"
                            >
                              {" "}
                              {d.fileName}{" "}
                            </Tag>
                          ))
                        )}
                      </div>
                    )}
                    {data[key]["type"] === "status" && (
                      <div
                        style={{
                          padding: 8,
                          display: "flex",
                          alignItems: "center",
                          width: "calc(100% - 160px)",
                        }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div
                            style={{
                              width: 6,
                              height: 6,
                              borderRadius: "50%",
                              background: data[key]["color"],
                              marginRight: 8,
                            }}
                          ></div>
                          <p>
                            {data[key]["content"].length > 1
                              ? data[key]["content"].map((c, index) => {
                                  let html = "";
                                  if (
                                    index ===
                                    data[key]["content"].length - 1
                                  ) {
                                    html += c;
                                  } else {
                                    html += `${c} / `;
                                  }
                                  return html;
                                })
                              : data[key]["content"][0]}
                          </p>
                          {/* <p>{data[key]["status"] === 1 ? "執行中" : "已完成"}{data[key]["place"] ? ` / ${data[key]["place"]}` : ""}</p> */}
                        </div>
                      </div>
                    )}
                    {data[key]["type"] === "remark" && (
                      <div
                        style={{
                          padding: 8,
                          display: "flex",
                          alignItems: "center",
                          width: "calc(100% - 160px)",
                          
                        }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <p>
                            {data[key]["content"] === ""
                              ? "-"
                              : data[key]["content"]}
                          </p>
                          <EditOutlined
                            onClick={() => remarkStatus(1)}
                            style={{
                              cursor: "pointer",
                              color: " rgba(0, 0, 0, 0.25)",
                              marginLeft: 8,
                            }}
                          />
                        </div>
                      </div>
                    )}
                    {data[key]["type"] === "photo" && (
                      <div
                        style={{
                          padding: 8,
                          display: "flex",
                          alignItems: "center",
                          width: "calc(100% - 160px)",
                          flexWrap:"wrap"
                        }}
                      >
                        {data[key]["data"].map((d, i) => (
                          <Tag
                            key={d.row_id}
                            icon={data[key]["icon"]}
                            color="default"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setOpenModal({
                                ...d,
                                title: `${
                                  data[key]["prefixText"]
                                    ? data[key]["prefixText"]
                                    : ""
                                }${i + 1}`,
                              });
                            }}
                          >
                            {data[key]["prefixText"]
                              ? data[key]["prefixText"]
                              : ""}
                            {i + 1}
                          </Tag>
                        ))}
                      </div>
                    )}
                  </li>
                );
              }
            })}
          </ul>
        </div>
        {openModal && (
          <Modal
            visible={true}
            title={openModal.title}
            footer={null}
            onCancel={() => setOpenModal(null)}
          >
          {
            openModal.video? (
                <video width="100%" controls>
                    <source src={openModal.url} type="video/mp4"/>
                </video>
            ):openModal.sliderImage?(
              <Carousel afterChange={()=>{

              }}>
              {
                openModal.data.map(d=>(<figure style={{width:500, height:500}}>
                  <img
                  alt="example"
                  src={`${process.env.REACT_APP__API_HOST}/${openModal.url}&type=${d}&download=false`}
                  style={{
                    width: "100%", height:"100%", objectFit:"contain"
                  }} />
                </figure>))
              }
                </Carousel>
            ):(
            <img
              alt="example"
              style={{
                width: "100%",
              }}
              src={openModal.url}
            />
            )
          }
            
          </Modal>
        )}
      </>
    );
  } else {
    return <div></div>;
  }
};

export default Card;

import service from './Setting'
//取得租賃公司列表
export const GetAllLease = () => service.get('/Lease/List');
//取得租賃公司資訊
export const GetLeaseDetail = (company_base_row_id) => service.get(`/Lease/Detail?company_base_row_id=${company_base_row_id}`);
//取得租賃公司方案歷程(續訂記錄)
export const GetLeasePayHistory= (company_base_row_id) => service.get(`/Lease/PayHistory?company_base_row_id=${company_base_row_id}`);
//新增租賃公司
export const AddLease= (obj) => service.post(`/Lease/Add`,obj);
//編輯租賃公司資訊
export const EditLeaseDetail= (obj) => service.post(`/Lease/Edit`,obj);
//新增/編輯租賃方案
export const EditLeasePlan= (obj) => service.post(`/Lease/AddPayPlan`,obj);
//檢查統編是否重複(續訂記錄)
export const CheckTax= (tax_no) => service.get(`/Lease/CheckTaxRepeat?tax_no=${tax_no}`);

//檢查統編是否重複(續訂記錄)
export const RentHistory= (company_base_row_id) => service.get(`/Lease/CompanyHistory?company_base_row_id=${company_base_row_id}`);

//停用租賃
export const DisabledRent= (obj) => service.post(`/Lease/Disable`,obj);
//修改方案歷程已讀狀態
export const EditPaymentReadStatus= (obj) => service.post(`/Lease/PayHistroyRead`,obj);
import service from './Setting'
//取得廣告列表
export const GetAdvertisementList = (page,page_size) => service.get(`/Dm/ListDm?page_size=${page_size}&page_index=${page}`);
//取得廣告資訊
export const GetAdvertisementDetail = (id) => service.get(`/Dm/GetDm?row_id=${id}`);
//取得廣告圖片
export const GetAdvertisementPhoto = (id) => service.get(`/Dm/GetDmPhoto?row_id=${id}`);
//新增廣告資訊
export const AddAdvertisementDetail = (formData) => service.post(`/Dm/AddDm`,formData);
//更新廣告資訊
export const UpdateAdvertisementDetail = (formData) => service.post(`/Dm/UpdateDm`,formData);

//取得廣告歷程
export const GetAdvertisementHistory = (id,page,page_size) => service.get(`/Dm/DmHistory?row_id=${id}&page_size=${page_size}&page_index=${page}`);

import React, { useState, useRef, useEffect } from 'react'
import { Row, Card, Statistic, Button, Progress, Drawer, Form, Input, Select, DatePicker, Radio, Checkbox, Modal, Upload } from 'antd'
import CustomTable from '../../shared/component/Card'
import CustomCascader from '../../shared/component/CustomCascader'
import { UpdateStation } from '../../../api/ServicePoint'
import { EditOutlined, UserOutlined, PhoneOutlined, UploadOutlined } from '@ant-design/icons'
import moment from 'moment'
const { TextArea } = Input
const { RangePicker } = DatePicker
const Basicinfo = ({ fetchDetail, onSubmit, permission }) => {
    const [fileList, setFileList] = useState([])
    let [modalStatus, setModatStatus] = useState(null)
    const [remarkModal, setRemarkModal] = useState(null)
    const serviceOptions = ['運送車', '道路救援']
    const submitForm = useRef()
    const onFinish = (value) => {
        const formdata = new FormData();
        for (var key in value) {
            if (fetchDetail[key]) {
                if (key === 'contractPeriod') {
                    fetchDetail.contract_period_start = moment(value[key][0]).format("YYYY-MM-DD 00:00:00")
                    fetchDetail.contract_period_end = moment(value[key][1]).format("YYYY-MM-DD 12:59:59")
                } else if (key === 'upload') {
                    if (value[key].length > 0) {
                        fetchDetail.contract_file = value[key][0]
                    }
                } else if (key === 'county_town') {
                    fetchDetail.county_code = value["county_town"][0]
                    fetchDetail.town_code = value["county_town"][1]
                } else {
                    fetchDetail[key] = value[key]
                }
            }
            if (key === 'Service') {
                let ser = ''
                value[key].map((v, i) => {
                    let text = v === '運送車' ? "1" : "2"
                    ser += i === 0 ? text : `,${text}`

                })
                fetchDetail.service_type = ser
            }

        }
        let area_arr = ["social_cycle", "responsible_area1", "responsible_area2"]
        area_arr.forEach((a) => {
            let key = `_${a}`
            let param = ''
            fetchDetail[key]
            .filter(
                (obj, index) =>
                fetchDetail[key].findIndex((item) => item[item.length-1] === obj[obj.length-1]) === index
              )
            .forEach((area, i) => {
                let value = area.length > 1 ? area[1] : `${area[0]}000`
                param += i === 0 ? `${value}` : `,${value}`
            })
            fetchDetail[a] = param
        })
        for (var key in fetchDetail) {
            formdata.append(key, fetchDetail[key])
        }
        UpdateStation(formdata).then(res => {
            if (res && !res.Error) {
                setModatStatus(null)
                onSubmit(true)
            }
        })
    }
    const onClose = () => {
        setModatStatus(null)
    }

    return (
        <div>
            <Card title="派量數據" bordered={false} >
                <Row style={{ paddingTop: 6, display: "flex", justifyContent: "space-around" }}>
                    <Statistic title="平均日派量" value={fetchDetail.case_day_avg ? fetchDetail.case_day_avg : ""} />
                    <Statistic
                        title="平均週派量"
                        value={fetchDetail.case_week_avg ? fetchDetail.case_week_avg : ""}
                        style={{
                            paddingLeft: 24,
                            borderLeft: '1px solid rgba(0, 0, 0, 0.03)'
                        }}
                    />
                    <Statistic title="平均月派量" value={fetchDetail.case_month_avg ? fetchDetail.case_month_avg : ""} style={{ borderLeft: '1px solid rgba(0, 0, 0, 0.03)', paddingLeft: 24 }} />
                </Row>
            </Card>
            <Card
                style={{ width: "100%", margin: "24px 0" }}
                title="基本資料"
                extra={permission !== 2 ? false :
                    <Button
                        type="text"
                        style={{ paddingRight: 0 }}
                        onClick={() => {
                            setModatStatus("1a")
                            setTimeout(() => {
                                submitForm.current.setFieldsValue(fetchDetail)
                            }, 100)
                        }}
                    >
                        修改
                        <EditOutlined style={{ color: "rgba(24, 144, 255, 1)" }} />
                    </Button>
                }
            >
                <CustomTable
                    remarkStatus={(status) => setRemarkModal(status)}
                    header={[
                        { key: "id", name: "據點編號" },
                        { key: "contract_company", name: "簽約公司" },
                        { key: "industry_text", name: "產業別" },
                        { key: "contact_name", name: "合約名稱" },
                        { key: "contract_period", name: "合約期間" },
                        { key: "responsible_person", name: "負責人" },
                        { key: "responsible_person_id", name: "統編 / 身份證" },
                        { key: "contact", name: "聯絡人 - 電話" },
                        { key: "fax", name: "傳真電話" },
                        { key: "ful_add", name: "聯絡地址" },
                        { key: "email", name: "E-MAIL" },
                        { key: "remark", name: "備註" },
                    ]} data={fetchDetail} />
            </Card>
            <Card
                style={{ width: "100%", margin: "24px 0" }}
                title="派遣資料"
                extra={permission !== 2 ? false :
                    <Button
                        type="text"
                        style={{ paddingRight: 0 }}
                        onClick={() => {
                            setModatStatus("1b")
                            setTimeout(() => {
                                submitForm.current.setFieldsValue(fetchDetail)
                            }, 100)
                        }}
                    >
                        修改
                        <EditOutlined style={{ color: "rgba(24, 144, 255, 1)" }} />
                    </Button>
                }
            >
                <CustomTable
                    remarkStatus={(status) => setModatStatus(status)}
                    header={[
                        { key: "category_text", name: "錄用分類" },
                        { key: "service_text", name: "可服務類別" },
                        { key: "new_social_cycle", name: "負責生活圈" },
                        { key: "new_responsible_area1", name: "主要區域 1" },
                        { key: "new_responsible_area2", name: "主要區域 2" },
                        { key: "supervisor", name: "負責督導" }
                    ]} data={fetchDetail}
                />
            </Card>
            <Card title="車輛統計" bordered={false} >
                <ul>
                    {
                        fetchDetail.CarStatistics.map(d => (
                            <li key={`${d.code}${d.name}`} style={{ display: "flex", borderBottom: "1px solid rgba(240, 240, 240, 1)" }}>
                                <div style={{ width: "160px", background: "#FAFAFA", padding: 8 }}>{d.name}</div>
                                <Progress style={{ padding: "0px 8px", display: "flex", alignItems: "center" }} percent={d.percentage} format={() => d.value} showInfo={true} />
                            </li>))
                    }
                </ul>

            </Card>

            <Drawer
                title={modalStatus === "1a" ? "編輯 - 基本資料" : '編輯 - 派遣規則'}
                placement="right"
                width={modalStatus === "1a" ? 680 : 400}
                closable={false}
                visible={modalStatus}
                footer={
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button type="text"
                            style={{ marginRight: 16 }}
                            onClick={() => {
                                submitForm.current.setFieldsValue(fetchDetail)
                                setModatStatus(null)
                            }}
                        > 取消 </Button>
                        <Button type="primary"
                            htmlType="submit"
                            onClick={() => submitForm.current.submit()}
                        >編輯</Button>
                    </div>
                }
            >
                <Form
                    name="basic"
                    ref={submitForm}
                    layout="vertical"
                    onFinish={onFinish}
                    autoComplete="off"
                >
                    {
                        modalStatus === "1a" ? (
                            <div style={{ height: "100%" }}>
                            <Form.Item
                                        label="產業別"
                                        name="industry"
                                        rules={[{ required: true, message: '請選擇產業別!' }]}
                                    >
                                        <Radio.Group >
                                            <Radio.Button value="1">物流倉儲業</Radio.Button>
                                            <Radio.Button value="2">大眾運輸業</Radio.Button>
                                            <Radio.Button value="3">道路救援業</Radio.Button>
                                            <Radio.Button value="4">汽機車買賣業</Radio.Button>
                                        </Radio.Group>
                                    </Form.Item>
                                <div style={{ display: "flex" }}>
                                    <Form.Item
                                        label="據點名稱"
                                        name="name"
                                        rules={[{ required: true, message: '請選擇或輸入據點名稱!' }]}
                                        style={{ marginRight: '16px' }}
                                    >
                                        <Input style={{ width: "304px" }} />
                                    </Form.Item>
                                    <Form.Item
                                        label="簽約公司"
                                        name="contract_company"
                                        rules={[{ required: true, message: '請輸入簽約公司！' }]}
                                    >
                                        <Input style={{ width: "304px" }} />

                                    </Form.Item>
                                </div>
                                <div style={{ display: "flex" }}>
                                    <Form.Item
                                        label="合約名稱"
                                        name="contract_name"
                                        // rules={[{ required: true, message: '請輸入合約名稱!' }]}
                                        style={{ marginRight: '16px' }}
                                    >
                                        <Input placeholder='' style={{ width: "304px" }} />
                                    </Form.Item>
                                    <div>
                                        <div style={{ height: 34 }}></div>
                                        <Form.Item
                                            label=""
                                            name="upload"

                                        // rules={[{ required: true, message: '請選擇簽約公司！' }]}
                                        >
                                            <Upload
                                                customRequest={({ onSuccess }) => onSuccess("ok")}
                                                // defaultFileList={fetchDetail.upload}
                                                fileList={fileList}
                                                onChange={({ file, fileList }) => {
                                                    setFileList(fileList)
                                                    submitForm.current.setFieldsValue({ upload: fileList })
                                                }}
                                            >
                                                <Button icon={<UploadOutlined />}>上傳電子檔</Button>
                                            </Upload>
                                        </Form.Item>
                                    </div>

                                </div>
                                <Form.Item
                                    label="合約期間"
                                    name="contractPeriod"
                                    rules={[{ required: true, message: '請選擇合約期間!' }]}
                                    style={{ marginRight: '16px' }}
                                >
                                    <RangePicker style={{ width: "304px" }} />
                                </Form.Item>
                                <div style={{ display: "flex" }}>
                                    <Form.Item
                                        label="負責人姓名"
                                        name="responsible_person"
                                        rules={[{ required: true, message: '請輸入負責人姓名!' }]}
                                        style={{ marginRight: '16px' }}
                                    >
                                        <Input placeholder='example' style={{ width: "304px" }} />
                                    </Form.Item>
                                    <Form.Item
                                        label="統編"
                                        name="responsible_person_id"
                                        rules={[{ required: true, message: '請輸入統編！' }]}
                                    >
                                        <Input placeholder='example' style={{ width: "304px" }} />
                                    </Form.Item>
                                </div>
                                <div style={{ display: "flex", alignItems: "end" }}>
                                    <Form.Item
                                        label="聯絡人資訊"
                                        name="contact_name"
                                        rules={[{ required: true, message: '請輸入聯絡人資訊!' }]}
                                        style={{ marginRight: '16px' }}
                                    >
                                        <Input prefix={< UserOutlined />} placeholder='點擊選擇' style={{ width: "304px" }} />
                                    </Form.Item>
                                    <Form.Item
                                        label=""
                                        name="tel"
                                        rules={[{ required: true, message: '請輸入聯絡人資訊！' }]}
                                    >
                                        <Input prefix={<PhoneOutlined />} placeholder='點擊選擇' style={{ width: "304px" }} />
                                    </Form.Item>
                                </div>
                                <div style={{ display: "flex" }}>
                                    <Form.Item
                                        label="傳真號碼"
                                        name="fax"
                                        style={{ marginRight: '16px' }}
                                    >
                                        <Input placeholder='example' style={{ width: "304px" }} />
                                    </Form.Item>
                                    <Form.Item
                                        label="電子郵件"
                                        name="email"
                                    >
                                        <Input placeholder='example' style={{ width: "304px" }} />
                                    </Form.Item>
                                </div>
                                <div style={{ display: "flex" }}>
                                    <Form.Item
                                        label="聯絡地址"
                                        name="county_town"
                                        rules={[{ required: true, message: "請選擇地址!" }]}
                                        style={{ marginRight: "16px" }}
                                    >
                                        <CustomCascader
                                            defaultValue={fetchDetail.county_town}
                                            style={{ width: "304px" }}
                                            OnChange={(val) => {
                                                submitForm.current.setFieldsValue({ county_town: val })
                                            }}
                                        />
                                    </Form.Item>
                                    <div>
                                        <div style={{ height: 34 }}></div>
                                        <Form.Item
                                            label=""
                                            name="address"
                                            rules={[{ required: true, message: "請輸入完整地址!" }]}
                                            style={{ marginRight: "16px" }}
                                        >
                                            <Input allowClear placeholder='請輸入完整地址' style={{ width: "304px" }} />
                                        </Form.Item>
                                    </div>

                                </div>

                            </div>
                        ) : (
                            <div style={{ height: "100%" }}>
                                <div style={{ display: "flex" }}>
                                    <Form.Item
                                        label="錄用分類"
                                        name="category"
                                        rules={[{ required: true, message: '請選擇錄用分類!' }]}
                                        style={{ marginRight: 16 }}
                                    >
                                        <Radio.Group buttonStyle="solid">
                                            <Radio.Button value="1">正式</Radio.Button>
                                            <Radio.Button value="2">支援</Radio.Button>
                                        </Radio.Group>
                                    </Form.Item>
                                    <Form.Item
                                        label="可服務類別"
                                        name="Service"
                                        rules={[{ required: true, message: '請選擇系統派遣層級!' }]}
                                    >
                                        <Checkbox.Group options={serviceOptions}
                                        // onChange={onChange} 
                                        />
                                    </Form.Item>
                                </div>
                                <Form.Item
                                    label="主要負責生活圈（可複選）"
                                    name="_social_cycle"
                                    rules={[{ required: true, message: '請選擇派遣時段!' }]}
                                >
                                    <CustomCascader
                                        OnChange={(val) => {
                                            submitForm.current.setFieldsValue({ _social_cycle: val })
                                        }}
                                        defaultValue={fetchDetail._social_cycle}
                                        multiple
                                        style={{ width: 360 }} />
                                </Form.Item>
                                <Form.Item
                                    label="負責區域 - 1（可複選）"
                                    name="_responsible_area1"
                                    rules={[{ required: true, message: '請選擇派遣時段!' }]}
                                >
                                    <CustomCascader
                                        multiple
                                        defaultValue={fetchDetail._responsible_area1}
                                        OnChange={(val) => {
                                            submitForm.current.setFieldsValue({ _responsible_area1: val })
                                        }}
                                        style={{ width: 360 }} />
                                </Form.Item>
                                <Form.Item
                                    label="負責區域 - 2（可複選）"
                                    name="_responsible_area2"
                                // rules={[{ required: true, message: '請選擇派遣時段!' }]}
                                >
                                    <CustomCascader
                                        OnChange={(val) => {
                                            submitForm.current.setFieldsValue({ _responsible_area2: val })
                                        }}
                                        multiple
                                        defaultValue={fetchDetail._responsible_area2}
                                        style={{ width: 360 }} />
                                </Form.Item>
                                <Form.Item
                                    label="負責督導 "
                                    name="supervisor"
                                    rules={[{ required: true, message: '請輸入負責督導!' }]}
                                >
                                    <Input />
                                </Form.Item>
                            </div>
                        )
                    }
                </Form>
            </Drawer>

            <Modal title="請輸入備註"
                visible={remarkModal === 1 ? true : false}
                width={416}
                // onOk={()=> setIsModalVisible("1a")} 
                onCancel={() => setRemarkModal(null)}
            >
                <TextArea placeholder="額外的據點內容說明請寫在此......" style={{ width: 368 }} />
            </Modal>

        </div>
    );
}

export default Basicinfo;

import React, { useEffect, useState } from 'react'
import { Button, Drawer, Tag, Table, Dropdown } from 'antd'
import { InfoCircleOutlined, CloseOutlined, UpOutlined, DownOutlined, PlusOutlined, ArrowRightOutlined, Loading3QuartersOutlined } from '@ant-design/icons'
import _ from 'lodash'
const ManualRoute = ({ visible, SetVisible, selectedCar,fullManualCases,onConfirm }) => {
    const [btnDisabled, setBtnDisabled] = useState(true)
    const [defaultCarList, setDefaultCarList] = useState(null)
    const [routes, setRoutes] = useState(null)
    const [cars, setCars] = useState(null)
    const [history, setHistory] = useState([])
    const [unavailableCar, setUnavailableCar] = useState([])
    const [upCase, setUpCase] = useState([])
    const [space, setSpace] = useState({ available: 0, default: 0 })
    const SetRoutes = (val, type) => {
        let reform_history = [...history]
        let cases = [...upCase]
        let selected_car_route = _.cloneDeep(unavailableCar)
        selected_car_route.push(val.no.toString())
        setUnavailableCar(selected_car_route);
        
        if (val.case_action === 'UP') {
            cases.push(val.case_info_row_id)
        }
        setUpCase(cases)

        let reform_route = routes ? [...routes] : []
        let route_no = type ? reform_route.length : reform_route.length + 1
        let num = { ...space }.available
        let max = { ...space }.default
        num = val.case_action === "UP" ? num - 1 : num + 1
        setSpace({ ...space, available: num })

        let reform_cars = []
        _.cloneDeep(cars).forEach(c => {
            let available, dropdown
            if (cases.indexOf(c.case_info_row_id) > -1) {
                // let available = unavailableCar.indexOf(c.no.toString())>-1? 0:1
                available = c.case_action === "DOWN" ? 1 : 0
                dropdown = val.lat === c.lat && val.lng === c.lng? 1 : 0
                if (val.no === c.no) c.route = route_no
            } else {
                if (val.lat === c.lat && val.lng === c.lng) {
                    //同樣地址
                    available = val.no === c.no || c.case_action === "DOWN" ? 0 : 1
                    dropdown = val.no === c.no || c.case_action === "DOWN" ? 0 : 1

                } else {
                    available = c.case_action === "UP" ? 1 : 0
                    dropdown = 0
                }


            }
            if (selected_car_route.indexOf(c.no.toString()) > -1 || (num === 0 && c.case_action === "UP")) {
                available = 0
                dropdown = 0
            }

            c.status = available
            c.dropdown = dropdown
            // if (val.name === c.name) {
            //     if (val.no === c.no) {
            //         c.status = 0
            //         c.route = route_no
            //         c.dropdown = 0
            //     } else {
            //         console.log(c)
            //         let available = unavailableCar.indexOf(c.no.toString())>-1? 0:1
            //         if(c.case_action==="DOWN"){
            //             available =upCase.indexOf(c.case_info_row_id)>-1? 1 : 0
            //         }else{
            //             available =upCase.indexOf(c.case_info_row_id)>-1? 0:1
            //         }
            //         c.status = available
            //         c.dropdown= available
            //     }
            // } else {
            //     let available
            //     if(c.case_action==="DOWN"){
            //         available = cases.indexOf(c.case_info_row_id)>-1? 1 : 0
            //     }else{
            //         available =upCase.indexOf(c.case_info_row_id)>-1? 0:1
            //     }
            //     c.status = available
            // }
            reform_cars.push(c)
        })
        // let car = 4;
        if (type) {
            let up = val.case_action === 'UP' ? reform_route[route_no - 1]['up'] + 1 : reform_route[route_no - 1]['up']
            let down = val.case_action === 'DOWN' ? reform_route[route_no - 1]['down'] + 1 : reform_route[route_no - 1]['down']
            // let available = car-up+down
            reform_route[route_no - 1]['available'] = num
            reform_route[route_no - 1]['up'] = up
            reform_route[route_no - 1]['down'] = down
            reform_route[route_no - 1]['car'].push(val)
        } else {
            let up = val.case_action === 'UP' ? 1 : 0;
            let down = val.case_action === 'DOWN' ? 1 : 0
            let route = {
                key: reform_route.length + 1,
                name: val.name,
                up,
                down,
                available: num,
                car: [val]
            }
            reform_route.push(route)
        }
        setCars(reform_cars)
        setRoutes(reform_route)
        reform_history.push({ cars: reform_cars, routes: reform_route, upCase: cases, space: { ...space, available: num }, unavailableCar: selected_car_route })
        setHistory(reform_history)
    }
    const rollBack = (type) => {
        let history_list = type?[]:[...history].filter((h, i) => i !== history.length - 1)
        let last = history_list.length - 1
        setCars(history_list.length>0?history_list[last]['cars']:defaultCarList)
        setRoutes(history_list.length>0?history_list[last]['routes']:null)
        setUpCase(history_list.length>0?history_list[last]['upCase']:[])
        setSpace(history_list.length>0?history_list[last]['space']:{ available: parseInt(visible.trailer_type_code), default: parseInt(visible.trailer_type_code)})
        setUnavailableCar(history_list.length>0?history_list[last]['unavailableCar']:[])
        setHistory(history_list)
    }
    const close = ()=>{
        rollBack('close')
        SetVisible(null)
    }
    useEffect(() => {
        if(visible){
            setSpace({ available: parseInt(visible.trailer_type_code), default: parseInt(visible.trailer_type_code) })
        }
        if (fullManualCases) {
            let cars = _.cloneDeep(fullManualCases).map((c,i)=>{
                    c.no = i+1
                    c.status = c.case_action==='UP'?1:0
                    c.name = c.service_point_name?c.service_point_name:c.full_address
                    return c
            })
            setCars(cars)
            setDefaultCarList(cars)
        }
    }, [fullManualCases,visible])
    useEffect(() => {
        if(defaultCarList && defaultCarList.length === unavailableCar.length){
            setBtnDisabled(false)
        }else{
            setBtnDisabled(true)

        }
    }, [unavailableCar])

    return (
        <Drawer
            title="全手動派遣"
            placement="right"
            width={"1400px"}
            closable={false}
            visible={visible}
            extra={<CloseOutlined
                style={{ cursor: "pointer", color: "rgba(0, 0, 0, 0.45)" }}
                onClick={() => {
                    close()
                   
                }} />
            }
            footer={
                <div style={{ display: 'flex', justifyContent: routes ? 'space-between' : 'flex-end' }}>
                    {
                        routes && (
                            <Button
                                type="secondary"
                                icon={<Loading3QuartersOutlined />}
                                onClick={() => rollBack()}>復原</Button>)
                    }

                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button type="text" style={{ marginRight: 16 }} onClick={() => {
                            close()
                            //   setSelectedRoute(null)
                            //   setSelectedTechnician(null)
                        }
                        }>取消</Button>
                        <Button type="primary"
                            disabled={btnDisabled}
                            onClick={() => {
                                // console.log(routes)
                                // console.log(unavailableCar)
                                onConfirm(routes,visible)
                                SetVisible(null)
                                rollBack('close')
                            }}>確定</Button>
                    </div>
                </div>
            }
        >
            <p style={{ fontSize: 30, paddingBottom: 16 }}>請從上架卡片挑一個作為開始</p>
            <div>
                <Tag icon={<InfoCircleOutlined />} >上架後才可下架</Tag>
                <Tag icon={<InfoCircleOutlined />} >同個順序內無法對同車上下架</Tag>
                <Tag icon={<InfoCircleOutlined />} >系統判定是否可在同地點加車</Tag>
            </div>
           
            {
                cars && (<div className='route_list'>
                    {
                        cars.map(car => {
                            let items = [
                                {
                                    key: '1', label: (<div
                                        onClick={() => { if (car.status) SetRoutes(car) }}> <PlusOutlined /> 新增目的地</div>)
                                },
                                {
                                    key: '2', label: (<div
                                        onClick={() => { if (car.status) SetRoutes(car, 'combine') }}> <ArrowRightOutlined /> 併入順序「{routes&&routes.length}」</div>)
                                }]
                            return (
                                <>
                                    {car.dropdown ? (
                                        <Dropdown 
                                        key={`${car.case_info_row_id}_${car.no}`}
                                        menu={{ items }} placement="bottomLeft">
                                            <div className={`route_block ${car.status ? '' : 'disabled'}`}>
                                                <div>
                                                    <Tag icon={car.case_action === "UP"?(<UpOutlined />):(<DownOutlined/>)} color={car.case_action === "UP" ? "rgba(64, 169, 255, 1)" : "rgba(115, 209, 61, 1)"} style={{ fontSize: 14 }}>{car.case_action === "UP" ? "上架" : "下架"}</Tag>
                                                </div>
                                                <div className='title'>
                                                    <p>{car.name}</p>
                                                    <p className='info'>{car.vehicle_brand_name} / {car.vehicle_model_name}</p>
                                                </div>
                                                {car.route && (<div className='route_tab'>{car.route}</div>)}
                                            </div>
                                        </Dropdown>
                                    ) : (
                                        <div 
                                        key={`${car.case_info_row_id}_${car.no}`}
                                        className={`route_block ${car.status ? '' : 'disabled'}`}
                                            onClick={() => { if (car.status) SetRoutes(car) }}
                                        >
                                            <div>
                                                <Tag icon={<UpOutlined />} color={car.case_action === "UP" ? "rgba(64, 169, 255, 1)" : "rgba(115, 209, 61, 1)"} style={{ fontSize: 14 }}>{car.case_action === "UP" ? "上架" : "下架"}</Tag>
                                            </div>
                                            <div className='title'>
                                                <p>{car.name}</p>
                                                <p className='info'>{car.vehicle_brand_name} / {car.vehicle_model_name}</p>
                                            </div>
                                            {car.route && (<div className='route_tab'>{car.route}</div>)}

                                        </div>
                                    )}
                                </>
                            )


                        })
                    }
                </div>)
            }
            {
                routes && (
                    <div style={{ marginTop: 32 }}>
                        <p style={{ paddingBottom: 16 }}>車輛及趟次安排</p>
                        <Table
                            rowKey={record => record.name}
                            dataSource={routes}
                            columns={[
                                { title: '順序', dataIndex: 'key', key: 'key', width: '8rem' },
                                { title: '地點', dataIndex: 'name', key: 'name' },
                                { title: '上架', dataIndex: 'up', key: 'up', width: '8rem', render: val => (<span>{val}</span>) },
                                { title: '下架', dataIndex: 'down', key: 'down', width: '8rem', render: val => (<span>{val}</span>) },
                                { title: '空位', dataIndex: 'available', key: 'available', width: '8rem', render: val => (<span>{val}</span>) },
                            ]}
                            pagination={false}
                        />
                    </div>
                )
            }
        </Drawer>

    )
}

export default ManualRoute
// 
let certain_case ={
    VIN: "ABC-123",
    calling_date: "2022-04-02T00:00:00",
    calling_person_email: "",
    calling_person_name: "",
    calling_person_phone: "",
    calling_source: "LINE",
    calling_time: "14:20:00",
    carStatus: {type: 'tag', status: 1},
    case_type_id: "001-新車運送",
    coupon: "",
    customer_id: "080-台灣馬自達汽車股份有限公司",
    deliveryTime: "2022/05/09 (儘快)",
    delivery_person: "",
    delivery_person_phone: "",
    destination: ['1', '63000', '63000010'],
    destination_add: "臺北市內湖區新湖三路125號",
    destination_address: "00633310",
    destination_county: "63000",
    destination_lat: "",
    destination_lng: "",
    destination_service_point: "",
    destination_town: "63000010",
    expense_attribution_unit: "",
    harbor_area_pass: "無需辦理",
    line_group_name: "標達新車運送(098-001)全鋒調度",
    line_id: "test",
    note: "",
    origin: ['002'],
    origin_add: "全鋒事業",
    origin_address: "",
    origin_county: "",
    origin_lat: "",
    origin_lng: "",
    origin_service_point: "002",
    origin_town: "",
    payout: {type: 'accordian', text: '', subdata: Array(0)},
    payoutAmount: {type: 'accordian', text: '', subdata: Array(0)},
    pick_up_person: "",
    pick_up_person_phone: "",
    power_status: "",
    project_type: "087 - 建復新車運送",
    receiver: {type: 'tel', subject: '', tel: ''},
    report_content: "",
    report_picture: [],
    report_way: "",
    
    request_for_payment: "",
    selfPaying: "",
    sender: {type: 'tel', subject: '', tel: ''},
    serviceFee: "",
    source: "LINE-標達新車運送(098-001)全鋒調度-test",
    transport_date: "2022-05-09T00:00:00",
    transport_time_from: "10:29:00",
    transport_time_to: "10:59:00",
    transport_type: "儘快",
    vehicle_color: "",
    vehicle_model_id: "05101",
    car_model_text: "Toyota/ Yaris",
    vehicle_sn: 1,
  }
const fetchCase = (state = certain_case , action) => {
    switch(action.type) {
        case 'FETCH_CERTAIN_CASE':
            return action.data
        default:
            return state
    }
}

export default fetchCase
import service from './Setting'

export const GetExecutionLists = (param) => service.get(`/Execution/List?${param}`);
export const GetExecutionHistory = (executionId) => service.get(`/Execution/History/${executionId}`);
export const GetExecutionInfo = (executionId) => service.get(`/Execution/${executionId}`);



//取消趟次
export const CancelExecution = (execution_info_row_id,obj) => service.post(`/Execution/${execution_info_row_id}/Cancel`, obj);


//紙本結案
export const ManualCloseExecution = (execution_info_row_id,obj) => service.post(`/Execution/${execution_info_row_id}/CloseOnPaper`, obj);


//繼續執行異況趟次
export const ContinueExecution = (execution_info_row_id) => service.post(`/Execution/${execution_info_row_id}/Continue`);

import React, { useState, useEffect,useRef } from 'react';
import BreadCrumb from '../../components/shared/BreadCrumb'
import { PlusOutlined,CloseOutlined,FilterOutlined } from '@ant-design/icons'
import { PageHeader, Button, Tabs, Badge, Table, Card,Select,Form,Drawer,AutoComplete } from 'antd'
import { useNavigate, useLocation } from 'react-router-dom'
import moment from 'moment'
import { GetAllLease } from '../../api/Lease';
import { useSelector } from 'react-redux';
import { RouteName } from '../../util/route';
import FilterTag from '../../components/shared/component/FilterTag';

const { TabPane } = Tabs
const { Option } = Select
export function multiFilter(array, filters) {
    const filterKeys = Object.keys(filters)
    // console.log(filters)
    // filters all elements passing the criteria
    return array.filter((item, i) => {
        // dynamically validate all filter criteria
        return filterKeys.every(key => {
            if (filters[key] && item[key]) {
                if (!filters[key].length) return true
                let filter = filters[key].filter(f => item[key].toLowerCase().indexOf(f.toLowerCase()) > -1)
                return filter.length > 0 ? true : false
            }
        })
    })
}
const Index = ({ pageTitle, ContainerWidth }) => {
    let navigate = useNavigate();
    const { state, pathname } = useLocation()
    const routeId = RouteName(pathname)
    const [currentTab, setCurrentTab] = useState("1")
    const [allData, setAllData] = useState(null)
    const [DataOption, setDataOption] = useState(null)
    const [FetchAllData, setFetchAllData] = useState(null)
    const [loading, setLoading] = useState(true)
    const [pagination, setPagination] = useState(state && state.pagination ? state.pagination : { current: 1, pageSize: 10 })
    const [Count, setCount] = useState(null)
    const [filterObjectLabel, setFilterObjectLabel] = useState(null)
    const [filterObject, setFilterObject] = useState(null)
    const [filterVisible, setFilterVisible] = useState(false)
    let UserInfo = useSelector(state => state.UserInfo)
    let UserPermission = UserInfo ? UserInfo.permission.route : null
    const submitRef = useRef()
    const [tabs, setTabs] = useState([
        { id: 'all', name: '全部', count: null, status: "all" },
        { id: '2', name: '逾期', count: null, status: 3 },
        { id: '3', name: '停用', count: null, status: 2 },
        { id: '1', name: '使用中', count: null, status: 1 },
    ])

    const fectApi = async () => {
        let res = await GetAllLease()
        if (res && !res.Error) {
            let count = { 物流倉儲業: 0, 大眾運輸業: 0, 道路救援業: 0, 汽機車買賣業: 0, 零件運送業: 0 }
            let data = res.Result.map(d => {
                d.status = d.is_over_time ? 3 : d.company_status
                count[d.company_industry_name] += 1

                return d
            })
            setAllData(data)
            setDataOption(data)
            setCount(count)
            
        }
    }
    useEffect(() => {
        document.title = pageTitle
        fectApi()
    }, [])
    useEffect(()=>{
        if(allData){
            let obj = filterObject? filterObject: {}
            let FinallyFilteredData = multiFilter(allData, obj)
            let new_data = {
                all: FinallyFilteredData,
                1: [],//使用中
                2: [],//停用
                3: []//逾期
            }
            FinallyFilteredData.map(d => {
                new_data[d.status.toString()].push(d)
            })
            tabs.map(tab => {
                tab.count = new_data[tab.status].length
                return tab
            })
            setFetchAllData(new_data)
            setLoading(false)
        }
    },[filterObject,allData])

    const columns = [
        { title: '公司名稱', dataIndex: 'company_name', },
        { title: '產業別', dataIndex: 'company_industry_name', },
        { title: '方案類型', dataIndex: 'pay_plan' },
        { title: '付款方式', dataIndex: 'pay_method' },
        {
            title: '續訂日期', dataIndex: 'to_renew_date',
            render: date => (moment(date).format("YYYY/MM/DD"))
        },
        {
            title: '狀態狀態', dataIndex: 'status',
            render: status => (<div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ width: 6, height: 6, borderRadius: "50%", background: status === 1 ? "#52C41A" : "#D9D9D9", marginRight: 8 }}></div>
                <p>{status === 1 ? "使用中" : status === 2 ? "停用" : "逾期"}</p>
            </div>)
        }
    ]

    return (
        <div className="container" style={ContainerWidth}>
            <PageHeader
                style={{ background: "white", width: "100%" }}
                title="租賃管理"
                className="site-page-header"
                extra={UserPermission && UserPermission[routeId] === 2 ? [
                    <Button
                        icon={<FilterOutlined />}
                        onClick={() => setFilterVisible(true)}
                    >篩選</Button>,
                    <Button
                        key="案件新增"
                        type="primary"
                        icon={<PlusOutlined />}
                        onClick={() => {
                            navigate("add")
                        }}
                    > 帳號新增 </Button>
                ] : [
                    <Button
                        icon={<FilterOutlined />}
                        onClick={() => setFilterVisible(true)}
                    >篩選</Button>
                ]}
                breadcrumb={<BreadCrumb />}
            >
            </PageHeader>
            <FilterTag 
                columns={columns} 
                filterObject={filterObjectLabel}   
                Filter={filterObject}
                onDelete={(value,label)=> {
                    submitRef.current.resetFields()
                    submitRef.current.setFieldsValue(value)
                    let obj = Object.keys(value).length===0?null: value
                    let Label = Object.keys(label).length===0?null: label
                    setFilterObject(obj)
                    setFilterObjectLabel(Label)
                    
                }} 
            />
            {
                Count && (
                    <Card title="產業別統計" style={{ margin: 24 }}>
                        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(5,1fr)' }}>
                            {
                                Object.keys(Count).map((key, i) => (
                                    <>
                                        <div style={{ borderLeft: i === 0 ? '' : '1px solid rgba(0, 0, 0, 0.03)', paddingLeft: i === 0 ? "" : 24 }}>
                                            <label style={{ color: "rgba(0,0,0,0.45)" }}>{key}</label>
                                            <div style={{ fontSize: 24, marginTop: 6 }}>{Count[key]}</div>
                                        </div>

                                    </>
                                ))
                            }

                        </div>
                    </Card>
                )
            }

            <div className='table_block'>
                <Tabs defaultActiveKey={currentTab}>
                    {
                        tabs.map(t => (
                            <TabPane key={t.id}
                                tab={
                                    <div onClick={(e) => {
                                        setPagination({ current: 1, pageSize: 10 })
                                        setCurrentTab(t.id)
                                    }}>
                                        <span>{t.name}</span>
                                        <Badge
                                            className="site-badge-count-109"
                                            count={t.count >= 999 ? `999+` : t.count}
                                            style={{ backgroundColor: currentTab === t.id ? "#E6F7FF" : "#F0F0F0", color: currentTab === t.id ? "#1890FF" : "rgba(0,0,0,0.45)", marginLeft: "4px" }}
                                        />
                                    </div>
                                } />
                        ))
                    }
                </Tabs>
                <Table
                    columns={columns}
                    rowKey={record => record.company_base_row_id}
                    dataSource={FetchAllData ? FetchAllData[currentTab] : []}
                    pagination={FetchAllData && FetchAllData[currentTab].length > 10 ? {
                        current: pagination.current,
                        total: FetchAllData[currentTab].length,
                        showTotal: (total, range) =>
                            `${range[0]}-${range[1]} 列（共${total}  列）`,
                    } : false}
                    loading={loading}
                    style={{ padding: "12px 0", minHeight: 400 }}
                    onRow={(record, rowIndex) => {
                        return {
                            onClick: event => { navigate(`/rent/${record.company_base_row_id}`, { state: { pagination } }) },
                        };
                    }}
                    onChange={(pagination) => setPagination(pagination)}
                />
            </div>
             {/* 篩選視窗 */}
             <Drawer
                title="篩選"
                placement="right"
                closable={false}
                extra={
                    (<CloseOutlined
                        style={{ cursor: "pointer", color: "rgba(0, 0, 0, 0.45)" }}
                        onClick={() => {
                            submitRef.current.resetFields()
                            submitRef.current.setFieldsValue(filterObject)
                            setFilterVisible(false)
                        }}
                    />)
                }
                footer={
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button type="text" style={{ marginRight: 16 }} onClick={() => {
                            submitRef.current.resetFields()
                            submitRef.current.setFieldsValue(filterObject)
                            setFilterVisible(null)

                        }
                        }>取消</Button>
                        <Button type="primary"
                            htmlType="submit"
                            // disabled={btnDisabled}
                            onClick={() => {
                                submitRef.current.submit();
                                // setSubmitForm(true)
                            }}
                        >篩選</Button>
                    </div>
                }
                visible={filterVisible}>
                <Form
                    ref={submitRef}
                    name="basic"
                    layout="vertical"
                    onFinish={(value) => {
                        let dataObj = {}
                        let labelObj = {}
                        for (let key in value) {
                            if (value[key]) {
                                labelObj[key] = [value[key]]
                                   dataObj[key] = [value[key]]
                            }
                        }
                        setFilterObject(dataObj)
                        setFilterObjectLabel(labelObj)
                        setFilterVisible(false)
                    }}
                    autoComplete="off"
                >
                    <Form.Item
                        label="產業別"
                        name="company_industry_name"
                    >
                        <Select placeholder='請選擇'>
                            <Option value='物流倉儲業'>物流倉儲業</Option>
                            <Option value='大眾運輸業'>大眾運輸業</Option>
                            <Option value='道路救援業'>道路救援業</Option>
                            <Option value='汽機車買賣業'>汽機車買賣業</Option>
                            <Option value='零件運送業'>零件運送業</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="方案類型"
                        name="pay_plan"
                    >
                        <Select placeholder='請選擇'>
                            <Option value='標準版'>標準版</Option>
                            <Option value='專業版'>專業版</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="公司名稱"
                        name="company_name"
                    >
                     <AutoComplete
                            options={(DataOption || []).map(d => ({
                                value: d.company_name,
                                label: d.company_name,
                            }))}
                            onSearch={(searchText)=>{
                                let search = searchText? [...allData].filter(d=> d.company_name.toLowerCase().indexOf(searchText.toLowerCase())>-1):[...allData]
                                setDataOption(search)
                            }}
                            allowClear
                            placeholder='請選擇'
                        />
                        {/* <Select
                            allowClear
                            showSearch
                            placeholder="請選擇"
                        >
                            {
                                allData&& allData.map(o => (<Option
                                    key={`${o.company_base_row_id}`}
                                    value={`${o.company_name}`}
                                >{`${o.company_name}`}</Option>))
                            }
                        </Select> */}
                    </Form.Item>
                </Form>
            </Drawer>
        </div>
    );
}

export default Index;

// 

const cases = (state = null , action) => {
    switch(action.type) {
        case 'FETCH_CASES_SUCCESS':
            return action.data
        default:
            return state
    }
}

export default cases
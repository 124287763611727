import React, { useState, useEffect, useRef } from 'react'
import BreadCrumb from '../../../components/shared/BreadCrumb'
import CustomTable from '../../../components/shared/component/Card'
import { PageHeader, Button, Card, Modal, Input, Popconfirm, message, Drawer, Form, TimePicker, Checkbox,Tag } from 'antd'
import { PauseCircleOutlined, CloseOutlined, EditOutlined } from '@ant-design/icons'
import { useParams, useNavigate,useLocation } from 'react-router-dom'
import { GetCommonPoint } from '../../../api/Customer'
import CustomCascader from '../../../components/shared/component/CustomCascader'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { RouteName } from '../../../util/route'

import LoadingBlock from '../../../components/shared/LoadingBlock'
import { UpdateCommonStationDetail, UpdateCommonStationRemark, GetCommonStationHistory,UpdateCommonStationStatus } from '../../../api/Customer'
import HistoryRecord from '../../../components/shared/component/HistoryRecord'
const { TextArea } = Input
// const { RangePicker } = DatePicker

const Detail = ({ ContainerWidth, pageTitle }) => {
    let { pointId } = useParams()
    const { state,pathname } = useLocation()
    const routeId = RouteName(pathname)
    let UserInfo = useSelector(state => state.UserInfo) 
    let UserPermission = UserInfo? UserInfo.permission.route: null
    let Town = useSelector(state => state.county)
    const navigate = useNavigate()
    const submitRef = useRef()
    const [StationTypeOptions, setStationTypeOptions] = useState([])
    const [editDrawer, setEditDrawer] = useState(false)
    const [data, setData] = useState(null)
    const [modalStatus, setModalStatus] = useState(false)
    const [popStatus, setPopStatus] = useState(false)
    let CommonStationType = useSelector(state => state.CommonStationType)
    const [editRemark, setEditRemark] = useState(null)
    const [remarkValue, setRemarkValue] = useState(null)
    const [history, setHistory] = useState(null)
    const [checkboxStatus, setCheckboxStatus] = useState(null)

    const format = 'HH:mm'
    const UpdateRemark = () => {
        let param = `row_id=${data.row_id}&note=${remarkValue}`
        UpdateCommonStationRemark(param).then(res => {
            if (res.Result.Success) {
                FetchApi()
                setEditRemark(null)
            }
        })
    }
    const checkOperatingHour = (e, type) => {
        let isChecked = e
        let val  = isChecked ? [moment("0"),moment("0")]:[]
        let new_checkboxStatus = {...checkboxStatus}
        new_checkboxStatus[type] = e
        setCheckboxStatus(new_checkboxStatus)
        submitRef.current.setFieldsValue({[type]:val})
    }
    const FetchApi = async () => {
        let history = await GetCommonStationHistory(`page_size=-1&page_index=0&row_id=${pointId}`)
        if (!history.Error) {
            setHistory(history.Result.Data.reverse())
        }
        GetCommonPoint(pointId).then(result => {
            if (!result.Error) {
                let Result = result.Result
                let operation = ["normal", "weekend", "holiday"]
                let obj = {}
                Result.hour = { type: "operatingHour", content: [`一般：${Result.normal_start_time}~${Result.normal_end_time}`, `週末：${Result.weekend_start_time}~${Result.weekend_end_time}`, `假日：${Result.holiday_start_time}~${Result.holiday_end_time}`] }
                operation.forEach(operate => {
                    let start = `${operate}_start_time`
                    let end = `${operate}_end_time`
                    Result[operate] = [moment(Result[start], format), moment(Result[end], format)]
                    if (Result[start] === "00:00" && Result[end] === "00:00") {
                        obj[operate] = true
                    } else {
                        obj[operate] = false
                    }
                })
                setCheckboxStatus(obj)
                Result.address_county = [Result.county, Result.town]
                Result.full_address = ''
                Result.remark = { type: 'remark', content: Result.note ? Result.note : '-' }
                let County = Town[0]['children'].filter(d => d.value === Result.county)
                if (County.length > 0) {
                    let filter_town = County[0]['children'].filter(t => t.Code === Result.town)
                    if (filter_town.length > 0) {
                        Result.full_address = `${County[0]['label']}${filter_town[0]['Name']}${Result.address}`
                    }
                }
                setRemarkValue(Result.note)

                if (CommonStationType) {
                    setStationTypeOptions(CommonStationType)
                    let filter = CommonStationType.filter(c => c.Code === Result.station_type_code)
                    if (filter.length > 0) {
                        Result.station_type_text = filter[0]['Name']
                    } else {
                        Result.station_type_text = '-'
                    }
                    setData(Result)
                }
            }
        })
    }
    useEffect(() => {
        if (CommonStationType && Town) {
            FetchApi()
        }
    }, [CommonStationType, Town])
    const SubmitForm = (value) => {
        value.row_id = data.row_id
        value.county = value.address_county[0]
        value.town = value.address_county[1]
        let arr = ["normal", "weekend", "holiday"]
        arr.map(a => {
            let start = `${a}_start_time`
            let end = `${a}_end_time`
            let open_key = `${a}_open`
            value[start] = value[a][0].format(format)
            value[end] = value[a][1].format(format)
            //checkboxStatus[a]=== true 為勾選不營業
            value[open_key] = checkboxStatus[a]? "0":"1"
        })
        let param = ''
        Object.keys(value).map((key,i)=>{
            param+= i===0? `${key}=${value[key]}` : `&${key}=${value[key]}`

        })
        value.note = data.note
        UpdateCommonStationDetail(param).then(result => {
            if (result.Result.Success) {
                FetchApi()
                setEditDrawer(false)
            }
        })
    }
    return (
        <div className='container' style={ContainerWidth}>
            <div style={{ height: "calc(100%)" }}>
            {
                console.log(data)
            }
                {
                    data ? (<>
                        <PageHeader
                            style={{ background: "white", width: "100%" }}
                            title={data ? `${data.id} - ${data.name}` : '-'}
                            onBack={() => navigate("/customer/servicePoints")}
                            className="site-page-header"
                            breadcrumb={<BreadCrumb
                                Info={{ [pointId]: data.name }}
                            />}
                             tags={<Tag color={data.status>0?'green':''}>{data.status>0? '配合中':'取消'}</Tag>}
                            extra={
                                UserPermission && UserPermission[routeId] ===2?[
                                    <Popconfirm
                                        placement="bottom"
                                        title={data.status===1?"取消配合後，將無法上單":"復原後將可再次作為運送點"}
                                        // onCancel={ ()=> setPopStatus(false)} 
                                        onConfirm={() => {
                                            let status =data.status===1?"0":"1"
                                            let update_uid = '00050'
                                            console.log(data)
                                            let param=`row_id=${pointId}&status=${status}&update_uid=${update_uid}`
                                            UpdateCommonStationStatus(param).then(res=>{
                                                if(res.Result.Success){
                                                    setPopStatus(true)
                                                    message.success("配合狀態已變更")
                                                    FetchApi()
                                                }

                                            })
                                            
                                        }}
                                        style={{ marginRight: 12 }}
                                        okText={data.status===1?"好，取消配合":"重啟配合"}
                                        // cancelText="請假"
                                        showCancel={false}
                                    >
                                        <Button icon={<PauseCircleOutlined />}>配合狀態變更</Button>
                                    </Popconfirm>
                                ]:[]}
                        >
                        </PageHeader>
                        <div className='split_block' style={{ display: 'grid', gridTemplateColumns: "repeat(2, 1fr)", height: "calc(100% - 102px)" }}>

                            <div className='content_block' style={{ padding: 24 }}>
                                {
                                    data !== null ? (
                                        <Card
                                            style={{ width: "100%", marginBottom: 24 }}
                                            title="運送點資訊"
                                            extra={
                                                UserPermission && UserPermission[routeId] !==2?false:
                                                <Button
                                                    type="text"
                                                    style={{ paddingRight: 0 }}
                                                    onClick={() => setEditDrawer(true)}
                                                >
                                                    修改
                                                    <EditOutlined style={{ color: "rgba(24, 144, 255, 1)" }} />
                                                </Button>
                                            }
                                        >

                                            <CustomTable
                                                remarkStatus={(val) => setEditRemark(val)}
                                                header={[
                                                    { key: "id", name: "代號" },
                                                    { key: "name", name: "名稱" },
                                                    { key: "station_type_text", name: "運送點類型" },
                                                    { key: "full_address", name: "地址" },
                                                    { key: "hour", name: "營業時間" },
                                                    { key:  UserPermission && UserPermission[routeId] !==2?"note":"remark", name: "備註" }
                                                ]} data={data} />

                                        </Card>) : (<div style={{ background: 'white' }}>
                                            <LoadingBlock />
                                        </div>)
                                }
                            </div>
                            <HistoryRecord History={history} detail={data} />
                        </div>
                    </>) : (<div style={{ padding: 24, height: '100%' }}>
                        <LoadingBlock />
                    </div>)
                }



                <Modal title="變更密碼"
                    visible={modalStatus}
                    width={416}
                    // onOk={()=> setIsModalVisible("1a")} 
                    onCancel={() => setModalStatus(false)}
                >
                    <Input.Password placeholder='請輸入新密碼' />
                    <Input.Password placeholder='再次輸入新密碼' style={{ marginTop: 24 }} />
                </Modal>

                <Drawer
                    title="編輯 - 基本資訊"
                    placement="right"
                    closable={false}
                    width={400}
                    extra={<CloseOutlined
                        style={{ cursor: "pointer", color: "rgba(0, 0, 0, 0.45)" }}
                        onClick={() => setEditDrawer(false)}
                    />}
                    visible={editDrawer}
                    footer={
                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                            <Button type="text" style={{ marginRight: 16 }} onClick={() => {
                                setEditDrawer(false)

                            }
                            }>取消</Button>
                            <Button type="primary"
                                // disabled={btnDisabled}
                                onClick={() => {
                                    submitRef.current.submit()
                                    // setSubmitForm(true)
                                }}>保存</Button>
                        </div>
                    }
                >
                    {
                        data && (<Form
                            ref={submitRef}
                            name="basic"
                            layout='vertical'
                            initialValues={data}
                            onFinish={SubmitForm}
                            // onFinishFailed={onFinishFailed}
                            autoComplete="off"
                        >
                            {/* <Form.Item
                            label={"共用運送點類型"}
                            name="station_type_code"
                            rules={[{ required: true, message: '請選擇客戶等級！' }]}
                        >
                            <Radio.Group
                                buttonStyle="solid"
                                onChange={(e) => { }}
                            >
                                {
                                    StationTypeOptions.map(station => (<Radio.Button value={station.Code}>{station.Name}</Radio.Button>))
                                }
                            </Radio.Group>
                        </Form.Item> */}
                            <Form.Item
                                label="名稱"
                                name="name"
                                rules={[{ required: true, message: "請輸入名稱!" }]}
                            >
                                <Input placeholder="請輸入名稱" />
                            </Form.Item>
                            <Form.Item
                                label="地址"
                                name="address_county"
                                rules={[{ required: true, message: "請選擇!" }]}
                                style={{ marginBottom: 8 }}
                            >
                                <CustomCascader
                                    defaultValue={data.address_county}
                                    OnChange={(value) => submitRef.current.setFieldsValue({ address_county: value })}
                                />
                            </Form.Item>
                            <Form.Item
                                label=""
                                name="address"
                                rules={[{ required: true, message: "請輸入地址!" }]}
                            >
                                <Input placeholder="請輸入地址" />
                            </Form.Item>
                            <div style={{ display: "flex", paddingTop: 16, alignItems: "center" }}>
                                <Form.Item
                                    label="營業時間（一般）"
                                    name="normal"
                                    rules={[{ required: true, message: "營業時間（一般）!" }]}
                                >
                                    <TimePicker.RangePicker
                                        style={{ width: '100%' }}
                                        format={format}
                                        disabled={checkboxStatus["normal"]}
                                    />
                                </Form.Item>
                                <Checkbox style={{ marginLeft: 8 }}
                                    onChange={(e) => checkOperatingHour(e.target.checked, "normal")} 
                                    checked={checkboxStatus["normal"]}
                                > 不營業</Checkbox>
                            </div>
                            <div style={{ display: "flex", paddingTop: 16, alignItems: "center" }}>
                                <Form.Item
                                    label="營業時間（週末）"
                                    name="weekend"
                                    rules={[{ required: true, message: "營業時間（週末）!" }]}
                                >
                                    <TimePicker.RangePicker style={{ width: '100%' }}
                                        format={format}
                                        disabled={checkboxStatus["weekend"]}
                                    />

                                </Form.Item>
                                <Checkbox style={{ marginLeft: 8 }}
                                    onChange={(e) => checkOperatingHour(e.target.checked, "weekend")} 
                                    checked={checkboxStatus["weekend"]}
                                > 不營業</Checkbox>
                            </div>
                            <div style={{ display: "flex", paddingTop: 16, alignItems: "center" }}>
                                <Form.Item
                                    label="營業時間（假日）"
                                    name="holiday"
                                    rules={[{ required: true, message: "營業時間（假日）!" }]}
                                >
                                    <TimePicker.RangePicker format={format} disabled={checkboxStatus["holiday"]} />
                                </Form.Item>
                                <Checkbox style={{ marginLeft: 8 }}
                                    onChange={(e) => checkOperatingHour(e.target.checked, "holiday")} 
                                    checked={checkboxStatus["holiday"]}
                                > 不營業</Checkbox>
                            </div>

                        </Form>)
                    }

                </Drawer>
                <Modal title="請輸入備註"
                    visible={editRemark === 1 ? true : false}
                    width={416}
                    onOk={() => UpdateRemark()}
                    onCancel={() => setEditRemark(null)}
                >
                    <TextArea
                        placeholder="額外的運輸點內容說明請寫在此......"
                        style={{ width: 368 }}
                        defaultValue={remarkValue}
                        onKeyUp={(e => e.target.value === "" ? setRemarkValue('') : setRemarkValue(e.target.value))}
                    />
                </Modal>
            </div>
        </div>
    );
}

export default Detail;
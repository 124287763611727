import React, { useState, useEffect, useRef } from "react"
import { Button, Tabs, Table, Drawer, Form, Input, InputNumber, notification, Radio, Card, Tag, Statistic, Select } from "antd"
import { PlusOutlined, CloseOutlined, EditOutlined, ReloadOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'
import {useLocation } from 'react-router-dom'
import AddOnPricing from "./AddOnPricing"
import CustomCascader from "../../shared/component/CustomCascader"
import { GetStartEndPointPrice, AddStartEndPointPrice, UpdateStartEndPointPrice, GetKMPrice, AddKMPrice, UpdateKMPrice, GetMatrixPrice, AddMatrixPriceCity, DeleteMatrixPriceCity, GetMatrixPriceCityList,UpdateMatrixPrice, GetPackagePrice, AddPackagePrice, UpdatePackagePrice } from '../../../api/Customer'
import { RouteName } from "../../../util/route"
const { TabPane } = Tabs
const { Option } = Select

const PricingMethod = ({ mode, ParentData }) => {
  const [method, setMethod] = useState(mode)
  const [data, setData] = useState([])
  const [allData, setAllData] = useState([])
  const [currentTab, setCurrentTab] = useState("01");
  const submitRef = useRef()
  const submitEditRef = useRef()
  const addCityRef = useRef()
  const { state,pathname } = useLocation()
  const routeId = RouteName(pathname)
  let UserInfo = useSelector(state => state.UserInfo) 
  let UserPermission = UserInfo? UserInfo.permission.route: null
  const County = useSelector(state => state.county)
  const [drawerStatus, setDrawerStatus] = useState(null)
  const [editDrawerStatus, setEditDrawerStatus] = useState(false)
  const [AddCityDrawer, setAddCityDrawer] = useState(null)
  const [CityList, setCityList] = useState(null)
  const [drawerData, setDrawerData] = useState(null)
  const methods = {
    "001": {
      title: "起訖點計價組合", type: "table",
      tab: [{ id: '01', name: "向總公司請款" }, { id: '02', name: "向起點請款" }, { id: '03', name: "向迄點請款" }, { id: '04', name: "個案請款" }],
      column: UserPermission && UserPermission[routeId] ===2 ?[
        { title: "運送點 1", dataIndex: "start_point_name" },
        { title: "運送點 2", dataIndex: "end_point_name" },
        { title: "請款金額", dataIndex: "receive_money" },
        { title: "服務費用", dataIndex: "service_cost" },
        { title: "自費金額", dataIndex: "self_pay" },
        {
          title: "備註", dataIndex: "note",
          render: (remark) => remark === "" || !remark ? ("-") : (<span>{remark}</span>)
        },
        {
          title: "操作", dataIndex: "setting", width: "15%",
          render: (status, obj) => (
            <>
              <Button
                type="link"
                primary
                style={{ padding: 0, marginRight: 16 }}
                onClick={() => editFetch("edit", obj)}
              >編輯</Button>
              <Button type="link" onClick={() => editFetch("delete", obj)} primary style={{ padding: 0 }}>刪除</Button>
            </>
          )
        },
      ]:[   { title: "運送點 1", dataIndex: "start_point_name" },
      { title: "運送點 2", dataIndex: "end_point_name" },
      { title: "請款金額", dataIndex: "receive_money" },
      { title: "服務費用", dataIndex: "service_cost" },
      { title: "自費金額", dataIndex: "self_pay" },
      {
        title: "備註", dataIndex: "note",
        render: (remark) => remark === "" || !remark ? ("-") : (<span>{remark}</span>)
      }]
    },
    "002": { title: "里程計價", type: "card", },
    "003": {
      title: "區域階梯計價", type: "table", tag: [],
      column:UserPermission && UserPermission[routeId] ===2 ?[
        { title: "往返點 1", dataIndex: "start_point_name" },
        { title: "往返點 2", dataIndex: "end_point_name" },
        { title: "請款金額", dataIndex: "receive_money", 
        render:val => val !== null ?(val) :(<span style={{color:"rgba(0, 0, 0, 0.45)"}}>尚未新增</span>)
        },
        { title: "服務費用", dataIndex: "service_cost",
        render:val => val !== null ?(val) :(<span style={{color:"rgba(0, 0, 0, 0.45)"}}>尚未新增</span>) },
        { title: "自費金額", dataIndex: "self_pay",
        render:val => val !== null ?(val) :(<span style={{color:"rgba(0, 0, 0, 0.45)"}}>尚未新增</span>) },
        {
          title: "備註", dataIndex: "note",
          render: (remark,obj) => obj.receive_money !== null?  !remark || remark === "" ? ("-") : (<span>{remark}</span>):(<span style={{color:"rgba(0, 0, 0, 0.45)"}}>尚未新增</span>),
        },
        {
          title: "操作", dataIndex: "setting", width: "15%",
          render: (status, obj) => (
            <>
              <Button type="link"
                primary
                style={{ padding: 0, marginRight: 16 }}
                onClick={() => editFetch("edit", obj)}
              >{obj.receive_money?"新增":"編輯"}</Button>
              {/* <Button type="link" primary style={{ padding: 0 }}>刪除</Button> */}
            </>
          )
        },
      ]:
      [
        { title: "往返點 1", dataIndex: "start_point_name" },
        { title: "往返點 2", dataIndex: "end_point_name" },
        { title: "請款金額", dataIndex: "receive_money",render:val => val !== null ?(val) :(<span style={{color:"rgba(0, 0, 0, 0.45)"}}>尚未新增</span>) },
        { title: "服務費用", dataIndex: "service_cost",render:val => val !== null ?(val) :(<span style={{color:"rgba(0, 0, 0, 0.45)"}}>尚未新增</span>) },
        { title: "自費金額", dataIndex: "self_pay",render:val => val !== null ?(val) :(<span style={{color:"rgba(0, 0, 0, 0.45)"}}>尚未新增</span>) },
        {
          title: "備註", dataIndex: "note",
          render: (remark,obj) => obj.receive_money !== null?  !remark || remark === "" ? ("-") : (<span>{remark}</span>):(<span style={{color:"rgba(0, 0, 0, 0.45)"}}>尚未新增</span>),
        }
      ]
    },
    "004": { title: "包月計價", type: "card" },
  }
  const editFetch = (type, val) => {
    let obj = {}
    if (method === '002' || method === "004") {
      data.map(d => {
        if (d.type_code === "002") {
          obj[`receive_money/2`] = d.receive_money
          obj[`self_pay/2`] = d.self_pay
          obj[`service_cost/2`] = d.service_cost
        } else {
          for (let key in d) {
            obj[key] = d[key]
          }
        }
      })
    } else {

      if (val) {
        obj = val
      }
    }
    if (type === "edit") {
      // 編輯
      if (method === '002' || method === "004") {
        setEditDrawerStatus(true)
        setDrawerData(val)
        setTimeout(() => {
          if (submitEditRef.current) {
            submitEditRef.current.setFieldsValue(val)
          }
        }, 200)
      } else {
        if(method === '001'){
          let arr = ["start_point","end_point"]
          arr.forEach(a=>{
            let place = []
            if(obj[a].length > 5){
              place = [obj[a].substring(0, 5),obj[a]]

            }else{
              place = [obj[a]]
            }
            obj[`${a}_arr`]=place
          })
          // obj.start_point = 
        }
        setDrawerStatus(method)
        setDrawerData(obj)
        setTimeout(() => {
          if (submitRef.current) {
            submitRef.current.setFieldsValue(obj)
          }
        }, 200)
      }
    } else if (type === "add") {
      // 新增
      setDrawerStatus(method)
      setDrawerData(null)
      setTimeout(() => {
        if (submitRef.current) {
          submitRef.current.resetFields()
          submitRef.current.setFieldsValue({ target: currentTab })
        }
      }, 200)
    } else if (type === "delete") {
      let key = method === "001" ? "StartEndPoint" : method === "002" ? "KM" : method === "003" ? "Matrix" : "Package"
      let user = JSON.parse(window.localStorage.getItem('User'))
      fetch(`${process.env.REACT_APP__API_HOST}/Price/${key}/Delete?row_id=${obj.row_id}`, {
        headers: {
          'Authorization': `Bearer ${user.Token}`
        }
      })
        .then(res => res.json)
        .then(result => {
          if (!result.Error) {
            fetchApi()
            notification.success({
              message: `${methods[method]["title"]}已刪除`,
              style: { background: "#F6FFED", border: "1px solid #B7EB8F" },
              placement: "top",
              closeIcon: (
                <div style={{ color: "rgba(0,0,0,0.85)" }}
                  onClick={() => {
                    let new_obj = { ...obj }
                    if (method === "001") {
                      let arr = ["start_point", "end_point"]
                      arr.map(a => {
                        let key = `${a}_arr`
                        let text = ''
                        new_obj[key].forEach((o, i) => text += i === 0 ? o : `/${o}`)
                        new_obj[a] = text
                      })
                    }
                    new_obj.price_row_id = ParentData.row_id
                    let param = ''
                    Object.keys(new_obj).forEach((Key, i) => {
                      let value = new_obj[Key] ? new_obj[Key] : ''
                      param += i === 0 ? `${Key}=${value}` : `&${Key}=${value}`
                    })
                    fetch(`${process.env.REACT_APP__API_HOST}/Price/${key}/Add?${param}`, {
                      headers: {
                        'Authorization': `Bearer ${user.Token}`
                      }
                    })
                      .then(res => res.json)
                      .then(result => {
                        if (!result.Error) {
                          fetchApi()
                        }
                      })
                  }}
                >
                  <ReloadOutlined /> <span>復原</span>
                </div>
              )
            })
          }
        })
    }
  }
  const fetchApi = async () => {
    let res
    if (method === '001') {
      //起訖點計價組合
      res = await GetStartEndPointPrice(ParentData.row_id, -1, 0)
    } else if (method === '002') {
      //里程計價
      res = await GetKMPrice(ParentData.row_id, -1, 0)
    } else if (method === '003') {
      //區域階梯式計價
      res = await GetMatrixPrice(ParentData.row_id, -1, 0)
      let city_list = await GetMatrixPriceCityList(ParentData.row_id)
      if (!city_list.Error) {
        setCityList(city_list.Result)
      }
    } else if (method === '004') {
      //包月計價
      res = await GetPackagePrice(ParentData.row_id, -1, 0)
    }
    if (res && !res.Error) {
      let Data = res.Result.Data
      let allData = res.Result.Data
      if (method === '001') {
        Data = Data.filter(d => d.target === currentTab)
        if (County) {
          let county = County[0]["children"]
          let arr = ["start_point", "end_point"]
          allData = allData.map((d, i) => {
            arr.forEach(a => {
              if (d[a]) {
                let check = d[a].indexOf("/") > -1 ? d[a].split("/") : [d[a]]
                d[`${a}_arr`] = check
                let point = []
                let point_text = ""
                let filter = county.filter(C => C.value === check[0])
                if (filter.length > 0) {
                  point.push(filter[0])
                }
                if (check.length > 1 && filter.length > 0) {
                  let town = filter[0]["children"].filter(C => C.Code === check[1])
                  if (town.length > 0) point.push(town[0])
                }

                if (point.length === 1) {
                  point_text = `${point[0]["label"]}全區`
                } else {
                  point.forEach((p, i) => {
                    point_text += i === 0 ? `${p.label}` : ` ${p.label}`
                  })
                }
                d[a] = point_text === "" ? d[a] : point_text
                if(!d[`${a}_name`]){
                  d[`${a}_name`] = point_text === "" ? d[a] : point_text
                }
              }
            })
            return d
          })
        }
      } else if (method === '002' || method === '004') {
        Data = Data.sort(function (a, b) {
          return a.type_code - b.type_code;
        })
      }
      setData(Data)
      setAllData(allData)
    }
  }
  useEffect(() => {
    let AllData = [...allData]
    AllData = AllData.filter(a => a.target === currentTab)
    setData(AllData)
  }, [currentTab])
  useEffect(() => {
    fetchApi()
  }, [County])

  const onFinish = async (val) => {
    val.note = val.note ? val.note : ""
    if (method === "002" || method === "004") {
      let obj = [{}, {}]
      for (let key in val) {

        let i = key.indexOf("/2") > -1 ? 1 : 0
        if (key === "receive_money/2" || key === "receive_money") {
          obj[i]["receive_money"] = val[key]
        } else if (key === "service_cost/2" || key === "service_cost") {
          obj[i]["service_cost"] = val[key]
        } else if (key === "self_pay/2" || key === "self_pay") {
          obj[i]["self_pay"] = val[key]
        }
      }
      obj = obj.map((o, i) => {
        let type_code = i === 0 ? "001" : "002"
        o.type_code = type_code
        if (method === "002") {
          o.base_km = val.base_km
        } else {
          o.package_type = val.package_type
          o.car_num = val.car_num
        }
        o.price_row_id = ParentData.row_id
        if (data.length > 0) {
          let filter = data.filter(d => d.type_code === type_code)
          o.row_id = filter[0]["row_id"]
        }
        return o
      })
      let success_arr = []
      for (let i = 0; i < obj.length; i++) {
        let param = ""
        let obj_data = obj[i]
        Object.keys(obj_data).forEach((key, i) => {
          param += i === 0 ? `${key}=${obj_data[key]}` : `&${key}=${obj_data[key]}`
        })
        let res

        if (data.length === 0) {
          res = method === "002" ? await AddKMPrice(param) : await AddPackagePrice(param)
        } else {
          res = method === "002" ? await UpdateKMPrice(param) : await UpdatePackagePrice(param)
        }
        if (res && !res.Error) {
          success_arr.push(1)
        } else {
          success_arr.push(0)
        }
      }

      if (success_arr.length === 2) {
        let filter = success_arr.filter(s => s === 0)

        if (filter.length === 0) {
          fetchApi()
          setDrawerStatus(null)
          submitRef.current.resetFields()
        }
      }
    } else if (method === "001") {
      //起訖點計價
      let param = ""
      let point = ["start_point", "end_point"]
      point.forEach(p => {

        let point_text = ''
        let selected
        if (drawerData) {
          selected = typeof (val[p]) !== "string" ? [val[p]] : drawerData[`${p}_arr`] ? [drawerData[`${p}_arr`]] : []
        } else {
          selected = typeof (val[p]) !== "string" ? val[p] : drawerData[`${p}_arr`] ? [drawerData[`${p}_arr`]] : []
        }
        selected.forEach((v, index) => {
          let text = ''
          if (typeof (v) !== "string") {

            v = v.map((value, i) => {
              if (i === 1 && value.indexOf(",") > -1) {
                let a = value.split(",")
                value = a[0]
              }
              return value
              text += i === 0 ? value : `/${value}`
            })
            if(v.length === 0){
              text = ''
            }

            text = v.length === 1? `${v[0]}000`:v[v.length-1]
            // point_text = v.length === 1? `${v[0]}000`:v[v.length-1]
            point_text += index === 0 ? text : `,${text}`
          }
        })
        val[p] = point_text



      })
      if (drawerData) {
        val.row_id = drawerData.row_id
      } else {
        val.price_row_id = ParentData.row_id
      }
      Object.keys(val).forEach((key, i) => {
        param += i === 0 ? `${key}=${val[key]}` : `&${key}=${val[key]}`
      })
      let res = drawerData ? await UpdateStartEndPointPrice(param) : await AddStartEndPointPrice(param)
      if (res && !res.Error) {
        fetchApi()
        setDrawerStatus(null)
        submitRef.current.resetFields()
      }
    }else{
      //區域階梯計價
      let param=''
      val.row_id = drawerData.row_id
      Object.keys(val).forEach((key, i) => {
        param += i === 0 ? `${key}=${val[key]}` : `&${key}=${val[key]}`
      })
      UpdateMatrixPrice(param).then(res =>{
        if (res && !res.Error) {
          fetchApi()
          setDrawerStatus(null)
          submitRef.current.resetFields()
        }
      })
    }
  }
  const AddCity = async (val) => {
    let city_code = ''
    if (val["area"]) {
      val["area"].forEach((a, i) => {
        let area_val = a.length === 1 ? `${a[a.length - 1]}000` : a[a.length - 1]
        city_code += i === 0 ? area_val : `,${area_val}`
      })
      // val.update_uid = update_uid
      let param = `price_row_id=${ParentData.row_id}&city_code=${city_code}`
      let AddCity = await AddMatrixPriceCity(param)
      if (!AddCity.Error) {
        fetchApi()
        setAddCityDrawer(null)
        addCityRef.current.resetFields()
      }
    }
  }
  return (
    <>
      {methods[method]["type"] === "table" && (
        <div className={methods[method]["tab"] ? "table_block" : "card_table"} >
          <div style={{ position: "relative" }}>
            <div style={{ fontSize: 16, padding: methods[method]["tab"] ? "16px 0 0 0" : 16, borderBottom: methods[method]["tab"] ? "" : "1px solid #f0f0f0" }}>
              {methods[method]["title"]}
            </div>
            {methods[method]["tab"] && methods[method]["tab"].length > 0 && (
              <Tabs defaultActiveKey={currentTab}>
                {methods[method]["tab"].map((t, i) => (
                  <TabPane
                    key={`${t.id}${t.name}`}
                    tab={
                      <div onClick={(e) => setCurrentTab(t.id)}>
                        <span>{t.name}</span>
                      </div>
                    }
                  />
                ))}
              </Tabs>
            )}
            <div></div>
          </div>
          {
            methods[method]["tag"] && (<div style={{ margin: "24px 24px 0 " }}>
              {
                CityList && CityList.map(city => (<Tag key={`${city.Code}-${city.Name}`} closable onClose={() => {
                  let param = `price_row_id=${ParentData.row_id}&city_code=${city.Code}`
                  DeleteMatrixPriceCity(param)
                    .then(res => {
                      if (!res.Error) {
                        fetchApi()
                      }
                    })
                }}> {city.Name}</Tag>))
              }
              {
                UserPermission && UserPermission[routeId] ===2  &&(<Tag style={{ borderStyle: "dashed", cursor: "pointer", background: "white" }} icon={<PlusOutlined />} onClick={() => setAddCityDrawer(true)} >新增往返點</Tag>)
              }
              
            </div>)
          }
          <Table
            columns={methods[method]["column"]}
            rowKey={(record) => (record.row_id)}
            // rowSelection={selectedRowKeys}
            dataSource={data}
            pagination={false}
            // loading={loading}
            style={{ padding: "12px 0", margin: methods[method]["tab"] ? 0 : "0 24px" }}
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  // navigate(`/cases/${record.id}`, {
                  //   state: {
                  //     caseStatus: record.case_status,
                  //     vehicle_sn: record.vehicle_sn,
                  //     dataDate
                  //   },
                  // });
                },
              };
            }}
          // onChange={(pagination) => setPagination(pagination)}
          />
          {method === "001"&& UserPermission && UserPermission[routeId] ===2  && (
            <Button
              type="dashed"
              onClick={() => editFetch("add")}
              icon={<PlusOutlined />}
              block
              style={{ marginBottom: 40 }}
            > 新增起訖點組合 </Button>
          )}
        </div>
      )}
      {
        methods[method]["type"] === "card" && (<div style={{ margin: "0 24px" }}>
          <Card
            style={{ width: "100%", marginBottom: 24, color: 'black', fontWeight: 'bold' }}
            title={methods[method]["title"]}
          >
            {
              data.length === 0 ? (<div style={{ display: "flex", justifyContent: "space-between", background: "rgba(245, 245, 245, 1)", alignItems: "center", padding: 24, cursor: UserPermission && UserPermission[routeId] !==2?'default':"pointer" }} onClick={() =>{
                if(UserPermission && UserPermission[routeId] ===2 )setDrawerStatus(method)
              }} >
                <div>
                  <div style={{ fontSize: 20 }}>{UserPermission && UserPermission[routeId] !==2?'未':''}新增{methods[method]["title"]}</div>
                  <div style={{ color: "rgba(0, 0, 0, 0.45)", paddingTop: 8 }}>設定{methods[method]["title"]}的基本費用，以及超出基本{method === "2" ? '里程' : '台數'}的額外加價。</div>
                </div>
                {
                  UserPermission && UserPermission[routeId] ===2  &&(<PlusOutlined block style={{ fontSize: 27 }} />)
                }
                
              </div>) : (<div style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gridGap: 8 }}>
                {
                  data.map(d => (<div style={{ background: "#FAFAFA", padding: 24 }} key={d.row_id}>
                    <div style={{ display: "flex", justifyContent: "space-between", marginBottom: 8 }}>
                      <div style={{ fontSize: 20 }}>{d.type_code === "001" ? "基本費用" : `超出基本${method === "002" ? '里程' : '台數'}額外加價`}</div>
                      {
                        UserPermission && UserPermission[routeId] ===2  &&( 
                          <Button type="link" style={{ padding: 0 }} onClick={() => editFetch("edit", d)}>
                        <EditOutlined /> 編輯
                      </Button>)
                      }
                     
                    </div>
                    <div style={{ display: "grid", gridTemplateColumns: `repeat(${d.type_code === "001" ? 4 : 3}, 1fr)` }}>
                      {
                        d.type_code === "001" && (<Statistic title={method === "002" ? '基本里程' : `${d.package_type === "01" ? '每月' : '每日'}台數`} value={method === "002" ? `${d.base_km} km` : `${d.car_num}`} />)
                      }
                      <Statistic title="請款金額" value={d.receive_money} style={{ borderLeft: d.type_code === "001" ? '1px solid rgba(0, 0, 0, 0.03)' : '', paddingLeft: d.type_code === "001" ? 20 : 0 }} />
                      <Statistic title="服務費用" value={d.service_cost} style={{ borderLeft: '1px solid rgba(0, 0, 0, 0.03)', paddingLeft: 20 }} />
                      <Statistic title="自費金額" value={d.self_pay} style={{ borderLeft: '1px solid rgba(0, 0, 0, 0.03)', paddingLeft: 20 }} />
                    </div>
                  </div>))
                }
              </div>)
            }
          </Card>
        </div>)
      }
      <AddOnPricing ParentData={ParentData} />
      <Drawer
        title="新增往返點"
        placement="right"
        closable={false}
        extra={
          <CloseOutlined
            style={{ cursor: "pointer", color: "rgba(0, 0, 0, 0.45)" }}
            onClick={() => {
              setAddCityDrawer(null)
              addCityRef.current.resetFields()
            }}
          />
        }
        visible={AddCityDrawer}
        footer={
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              type="text"
              style={{ marginRight: 16 }}
              onClick={() => {
                setAddCityDrawer(null)
                addCityRef.current.resetFields()
              }}
            > 取消</Button>
            <Button
              type="primary"
              onClick={() => addCityRef.current.submit()}
            > 新增 </Button>
          </div>
        }
      >
        <Form
          ref={addCityRef}
          name="basic"
          layout="vertical"
          initialValues={{ package_type: "01" }}
          onFinish={(val) => AddCity(val)}
          autoComplete="off"
        >
          <Form.Item
            name="area"
            label={
              <>
                <span>區域名稱</span>
                <span style={{ paddingLeft: 4, color: "rgba(0, 0, 0, 0.45)", fontSize: 14 }}
                >(可複選)</span>
              </>}
            style={{ display: "block" }}
            rules={[{ required: true, message: "請選擇區域!" }]}
          >
            <CustomCascader
              SeperatedName
              multiple
              OnChange={val => addCityRef.current.setFieldsValue({ area: val })} />
          </Form.Item>
        </Form>
      </Drawer>
      <Drawer
        title={drawerData ? `編輯${methods[method].title}` : `新增${methods[method].title}`}
        placement="right"
        closable={false}
        extra={
          <CloseOutlined
            style={{ cursor: "pointer", color: "rgba(0, 0, 0, 0.45)" }}
            onClick={() => setDrawerStatus(null)}
          />
        }
        visible={drawerStatus}
        footer={
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              type="text"
              style={{ marginRight: 16 }}
              onClick={() => {
                setDrawerStatus(null)
              }}
            > 取消 </Button>
            <Button
              type="primary"
              onClick={() => {
                submitRef.current.submit()
              }}
            >
              {drawerData ? "編輯" : "新增"}
            </Button>
          </div>
        }
      >
        <Form
          ref={submitRef}
          name="basic"
          layout="vertical"
          initialValues={{ package_type: "01" }}
          onFinish={onFinish}
          // onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
       
          {
            method === "001" && (<>
              <Form.Item
                name="start_point"
                label={drawerData ? "運送點 1" : (
                  <>
                    <span>運送點 1</span>
                    <span
                      style={{
                        paddingLeft: 4,
                        color: "rgba(0, 0, 0, 0.45)",
                        fontSize: 14,
                      }}
                    > (可複選) </span>
                  </>)
                }
                style={{ display: "block" }}
              >
                <CustomCascader
                  defaultValue={drawerData ? drawerData.start_point_arr : ""}
                  // SeperatedName
                  multiple={drawerData ? false : true}
                  OnChange={val => {
                    submitRef.current.setFieldsValue({ start_point: val })
                  }}
                />
              </Form.Item>
              <Form.Item
                name="end_point"
                label={drawerData ? "運送點 2" : (
                  <>
                    <span>運送點 2</span>
                    <span
                      style={{
                        paddingLeft: 4,
                        color: "rgba(0, 0, 0, 0.45)",
                        fontSize: 14,
                      }}
                    > (可複選) </span>
                  </>)
                }
                style={{ display: "block" }}
              >
                <CustomCascader
                  defaultValue={drawerData ? drawerData.end_point_arr : ""}
                  // SeperatedName
                  multiple={drawerData ? false : true}
                  OnChange={val => submitRef.current.setFieldsValue({ end_point: val })} />
              </Form.Item>
            </>)
          }
          {
            (method === "002" || method === "004") && (<p style={{ paddingBottom: 16, fontWeight: 'bold', color: 'black', fontSize: 16 }}>基本費用</p>)
          }
          {
            method === "002" && (<Form.Item
              label="基本里程"
              name="base_km"
              rules={[
                { required: true, message: "請輸入基本里程!" },
              ]}
              style={{ display: "block" }}
            >
              <InputNumber min={1} addonAfter="公里" allowClear placeholder="請輸入基本里程" />
            </Form.Item>)
          }
          {/* {
            method === "003" && (
              <Form.Item
                name="area"
                label={<>
                  <span>區域名稱</span>
                  <span style={{ paddingLeft: 4, color: "rgba(0, 0, 0, 0.45)", fontSize: 14 }}
                  >(可複選)</span>
                </>}
                style={{ display: "block" }}
              >
                <CustomCascader
                  defaultValue={drawerData ? drawerData.end_point_arr : ""}
                  SeperatedName
                  multiple={drawerData ? false : true}
                  OnChange={val => submitRef.current.setFieldsValue({ area: val })} />
              </Form.Item>)
          } */}
          {
            method === "004" && (<Form.Item
              label="基本台數"
              name="car_num"
              rules={[
                { required: true, message: "請輸入基本台數!" },
                { pattern: /^\d+$/, message: "請輸入數字" }
              ]}
            >
              <Input addonAfter="台" addonBefore={<Form.Item noStyle name="package_type">
                <Select>
                  <Option value='01'>包月</Option>
                  <Option value='02'>包日</Option>
                </Select>
              </Form.Item>} allowClear placeholder="請輸入數量" />
            </Form.Item>)
          }
          <Form.Item
            label="請款金額"
            name="receive_money"
            rules={[
              { required: true, message: "請輸入請款金額!" },
              { pattern: /^\d+$/, message: "請輸入數字" }
            ]}
          >
            <Input addonBefore="NT$" allowClear placeholder="請輸入金額" />
          </Form.Item>
          <Form.Item
            label="服務費用"
            name="service_cost"
            rules={[
              { required: true, message: "請輸入服務費用!" },
              { pattern: /^\d+$/, message: "請輸入數字" }
            ]}
          >
            <Input addonBefore="NT$" allowClear placeholder="請輸入金額" />
          </Form.Item>
          <Form.Item
            label="自費金額"
            name="self_pay"
            rules={[
              { required: true, message: "請輸入自費金額!" },
              { pattern: /^\d+$/, message: "請輸入數字" }
            ]}
          >
            <Input addonBefore="NT$" allowClear placeholder="請輸入金額" />
          </Form.Item>

          {method === "001" && (<Form.Item
            label="收費對象"
            name="target"
            rules={[{ required: true, message: "請選擇收費對象!" }]}
          >
            <Radio.Group buttonStyle="solid">
              <Radio.Button value="01">總公司</Radio.Button>
              <Radio.Button value="02">起點</Radio.Button>
              <Radio.Button value="03">迄點</Radio.Button>
              <Radio.Button value="04">個案</Radio.Button>
            </Radio.Group>
          </Form.Item>)}
          {
            (method === "002" || method === "004") && (<>
              <p style={{ padding: '16px 0', fontWeight: 'bold', color: 'black', fontSize: 16 }}>超出基本{method === "002" ? '里程每公里' : '台數每台'}額外加價</p>
              <Form.Item
                label="請款金額"
                name="receive_money/2"
                rules={[
                  { required: true, message: "請輸入請款金額!" },
                  { pattern: /^\d+$/, message: "請輸入數字" }
                ]}
              >
                <Input addonBefore="NT$" allowClear placeholder="請輸入金額" />
              </Form.Item>
              <Form.Item
                label="服務費用"
                name="service_cost/2"
                rules={[
                  { required: true, message: "請輸入服務費用!" },
                  { pattern: /^\d+$/, message: "請輸入數字" }
                ]}
              >
                <Input addonBefore="NT$" allowClear placeholder="請輸入金額" />
              </Form.Item>
              <Form.Item
                label="自費金額"
                name="self_pay/2"
                rules={[
                  { required: true, message: "請輸入自費金額!" },
                  { pattern: /^\d+$/, message: "請輸入數字" }
                ]}
              >
                <Input addonBefore="NT$" allowClear placeholder="請輸入金額" />
              </Form.Item>

            </>)
          }
          {
            (method === "001" || method === "003") && (<Form.Item
              name="note"
              label={
                <>
                  <span>備註</span>
                  <span
                    style={{
                      paddingLeft: 4,
                      color: "rgba(0, 0, 0, 0.45)",
                      fontSize: 14,
                    }}
                  > (非必填) </span>
                </>
              }
              style={{ display: "block" }}
            >
              <Input allowClear placeholder="你也可以留下備註說明..." />
            </Form.Item>)
          }
        </Form>
      </Drawer>
      {
        editDrawerStatus && (<Drawer
          title={`編輯${methods[method].title}`}
          placement="right"
          closable={false}
          extra={
            <CloseOutlined
              style={{ cursor: "pointer", color: "rgba(0, 0, 0, 0.45)" }}
              onClick={() => setEditDrawerStatus(false)}
            />
          }
          visible={editDrawerStatus}
          footer={
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                type="text"
                style={{ marginRight: 16 }}
                onClick={() => setEditDrawerStatus(false)}
              > 取消 </Button>
              <Button
                type="primary"
                onClick={() => submitEditRef.current.submit()}
              > 編輯 </Button>
            </div>
          }
        >
          <Form
            ref={submitEditRef}
            name="basic"
            layout="vertical"
            // initialValues={{ package_type: "01" }}
            onFinish={async (val) => {
              let param = ''
              val.row_id = drawerData.row_id
              val.type_code = drawerData.type_code
              val.update_uid = '00050'
              for (let key in val) {
                param += key === "update_uid" ? `${key}=${val[key]}` : `${key}=${val[key]}&`
              }
              let res
              if (method === "002") {
                res = UpdateKMPrice(param)
              } else {
                res = UpdatePackagePrice(param)
              }
              if (!res.Error) {
                let Fetch
                if (method === "002") {
                  Fetch = await GetKMPrice(ParentData.row_id, -1, 0)
                } else {
                  Fetch = await GetPackagePrice(ParentData.row_id, -1, 0)
                }
                if (!Fetch.Error) {

                  let Data = Fetch.Result.Data.sort(function (a, b) {
                    return a.type_code - b.type_code;
                  })
                  setData(Data)
                  setAllData(Data)
                  fetchApi()
                  setEditDrawerStatus(false)
                }
              }
            }}
            autoComplete="off"
          >
            {
              drawerData.type_code === "001" ? (<p style={{ paddingBottom: 16, fontWeight: 'bold', color: 'black', fontSize: 16 }}>基本費用</p>) : (<p style={{ paddingBottom: 16, fontWeight: 'bold', color: 'black', fontSize: 16 }}> 超出基本{method === "002" ? '里程每公里' : '台數每台'}額外加價 </p>)
            }
            {
              drawerData.type_code === "001" && method === "002" && (<Form.Item
                label="基本里程"
                name="base_km"
                rules={[{ required: true, message: "請輸入數字!" }]}
                style={{ display: "block" }}
              >
                <InputNumber min={1} addonAfter="公里" placeholder="請輸入基本里程" />
              </Form.Item>)
            }
            {
              drawerData.type_code === "001" && method === "004" && (<Form.Item
                label="基本台數"
                name="car_num"
                rules={[
                  { required: true, message: "請輸入基本台數!" },
                  { pattern: /^\d+$/, message: "請輸入數字" }
                ]}
                style={{ display: "block" }}
              >
                <Input addonAfter="台" addonBefore={<Form.Item noStyle name="package_type">
                  <Select>
                    <Option value='01'>包月</Option>
                    <Option value='02'>包日</Option>
                  </Select>
                </Form.Item>} placeholder="請輸入數量" />
              </Form.Item>)
            }
            <Form.Item
              label="請款金額"
              name="receive_money"
              rules={[
                { required: true, message: "請輸入請款金額!" },
                { pattern: /^\d+$/, message: "請輸入數字" }
              ]}
              style={{ display: "block" }}
            >
              <Input addonBefore="NT$" placeholder="請輸入金額" />
            </Form.Item>
            <Form.Item
              label="服務費用"
              name="service_cost"
              rules={[
                { required: true, message: "請輸入服務費用!" },
                { pattern: /^\d+$/, message: "請輸入數字" }
              ]}
              style={{ display: "block" }}
            >
              <Input addonBefore="NT$" placeholder="請輸入金額" />
            </Form.Item>
            <Form.Item
              label="自費金額"
              name="self_pay"
              rules={[
                { required: true, message: "請輸入自費金額!" },
                { pattern: /^\d+$/, message: "請輸入數字" }
              ]}
              style={{ display: "block" }}
            >
              <Input addonBefore="NT$" placeholder="請輸入金額" />
            </Form.Item>
          </Form>
        </Drawer>)
      }
    </>
  );
}
export default PricingMethod;


import { connectRouter } from 'connected-react-router'
import Cases from './cases' 
import {Notification,UnreadNotification} from './notification' 
import {county,CountyCode,caseType,CustomerList,CommonStationType,PriceModule,ExtraPriceItem,CarType,UserInfo,TrailerType} from './fetchApi' 
import CasesList from './fetchCases' 
import CertainCase from './fetchCase'
import CarBrand from './fetchCar'

import { combineReducers } from 'redux'

const allReducers = (history) => combineReducers({
    router: connectRouter(history),
    UserInfo,
    CertainCase,
    Cases,
    CarBrand,
    Notification,
    UnreadNotification,
    county,
    CountyCode,
    CasesList,
    CustomerList,
    CommonStationType,
    caseType,
    PriceModule,
    ExtraPriceItem,
    CarType,
    TrailerType
})

export default allReducers

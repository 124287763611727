import React, { useState, useEffect } from 'react'
import { DatePicker,  Tag, Table, Drawer, Button } from 'antd'
import { LeftOutlined, RightOutlined, CalendarOutlined, CloseOutlined } from '@ant-design/icons'
import moment from 'moment'
import { GetAttendanceList,GetDriverExecutedList,SetDriverAttendStatus } from '../../../api/ServicePoint'
function Status(val) {
    switch (val) {
        case "0":
            return ({ val: '休息日', color: "#F5222D" })
        case "1a":
            return ({ val: '尚未打卡', color: "#52C41A" })
        case "1b":
            return ({ val: '上班日', color: "#52C41A" })
        case "2":
            return ({ val: '執勤中', color: "#52C41A" })
        case "3":
            return ({ val: '暫離', color: "#1890FF" })
        case "99":
            return ({ val: '支援', color: "#FA8C16" })
        default:
            return ({ val: '-', color: "" })
    }
}

const CustomCalendar = ({ type, pointData, setLeave, changeWorkDate,permission}) => {
    const [calendarOpen, setCalendarOpen] = useState(false)
    const [selectDate, setSelectDate] = useState(moment())
    const [selectDateText, setSelectDateText] = useState(moment().format("YYYY 年 MM 月 DD 日"))
    const [modalStatus, setModalStatus] = useState(null)
    const [modalVal, setModalVal] = useState(null)
    const [fetchData, setFetchData] = useState([])
    const [caseMonthAmount, setCaseMonthAmount] = useState(0)
    const [executeList, setExecuteList] = useState([])
    const [drawerOpen, setDrawerOpen] = useState(false)
    const [btnDisabled, setBtnDisabled] = useState(true)

    const formRef = React.createRef()
    let periodrange = []

    for (let i = 7; i < 22; i++) {
        let tostring = i.toString().length === 1 ? `0${i}` : i
        periodrange.push(tostring)
    }
    const fetchApi = async () => {
        let res = await GetAttendanceList(pointData.row_id,selectDate.format("YYYY-MM-DD"))
        if(res && !res.Error){
            let Data = res.Result.Data.map(d=>{
                d.end = null
                return d
            })
            setFetchData(Data)
        }
    }
    const columns = [
        { title: '技師名稱', dataIndex: 'driver_name' },
        { title: '車牌號碼', dataIndex: 'plate_number' },
        { title: '車種', dataIndex: 'car_type_name',
        render: (v) => (v ? v : '-') },
        {
            title: '開始時間', dataIndex: 'check_in',
            render: (v) => (v ? v : '-')
        },
        {
            title: '結束時間', dataIndex: 'end',
            render: (v) => (v ? v : '-')
        },
        {
            title: '狀態', dataIndex: 'status', width: "40%",
            render: (v) => {
                let now = moment().format("YYYY 年 MM 月 DD 日")
                let status = v
                if(v === "1"){
                    status = selectDateText === now?'1a':'1b'
                }else{
                    status = v
                }
                return(
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ width: 6, height: 6, borderRadius: "50%", background: Status(status).color, marginRight: 8 }}></div>
                        <p>{Status(status).val}</p>
                    </div>
                )
            }
        },
    ]
    const ExecutedColumns = [
        { title: '當日執行', dataIndex: 'id' },
        { title: '運送訖點', dataIndex: 'ori_place' },
        { title: '車種', dataIndex: 'dec_place',},
        {
            title: '執行狀態', dataIndex: 'status', width: "40%",
            render: (v) => (
                <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ width: 6, height: 6, borderRadius: "50%", background: v==="1"?'#52C41A': '#D9D9D9'}}></div>
                    <p>{ v==="1"?'執行中':'待執行'}</p>
                </div>
            )
        },
    ]
    useEffect(() => {
        if (modalVal !== null) {
            formRef.current.setFieldsValue({
                id: modalVal.id,
                carPlate: modalVal.defaultCar ? modalVal.defaultCar.carPlate : "",
                carFrequency: modalVal.defaultCar ? "1" : "0"
            })
        }
    }, [modalVal]);
    useEffect(()=>{
        fetchApi()
    },[selectDateText,pointData])
    useEffect(()=>{
        if(drawerOpen){
            GetDriverExecutedList(drawerOpen.driver_row_id,selectDate.format("YYYY-MM-DD") ).then(res=>{
                if(res && !res.Error){
                    setExecuteList(res.Result.Info)
                    setCaseMonthAmount(res.Result.CaseMonthCount)
                }
            })
        }else{
            setExecuteList([])
        }
    },[drawerOpen])
    return (
        <div>
            <div style={{ padding: "16px 24px", boxShadow: "inset 0px -1px 0px #F0F0F0", display: "flex", justifyContent: "space-between", alignItems: 'center' }}>
                <div>
                    {selectDateText}
                </div>
                <div style={{ position: "relative" }}>
                    <LeftOutlined style={{ cursor: 'pointer' }}
                        onClick={() => {
                            let date = selectDate.subtract(1, "d")
                            setSelectDate(date)
                            setSelectDateText(date.format("YYYY 年 MM 月 DD 日"))
                        }}
                    />
                    <RightOutlined style={{ margin: '0 16px', cursor: 'pointer' }}
                        onClick={() => {
                            let date = selectDate.add(1, "d")
                            setSelectDate(date)
                            setSelectDateText(date.format("YYYY 年 MM 月 DD 日"))
                        }}
                    />
                    {
                        selectDate.format("YYYYMMDD") === moment().format("YYYYMMDD") && (<Tag style={{ background: "white" }}>今日</Tag>)
                    }

                    <DatePicker
                        value={selectDate}
                        open={calendarOpen}
                        style={{ visibility: "hidden", widtd: 0, position: 'absolute', right: 0 }}
                        onOpenChange={open => setCalendarOpen(!calendarOpen)}
                        onChange={val => {
                            setSelectDate(val)
                            setSelectDateText(val.format("YYYY 年 MM 月 DD 日"))
                        }}
                    />
                    <Tag style={{ background: "white", cursor: 'pointer' }}>
                        <CalendarOutlined onClick={() => setCalendarOpen(!calendarOpen)} />
                    </Tag>
                </div>
            </div>
            <div style={{ borderTop: '8px solid rgb(240, 242, 245)', padding: 24 }}>
                <Table
                    columns={columns}
                    rowKey={(record) => record.row_id}
                    dataSource={fetchData}
                    pagination={false}
                    style={{ minHeight: 400 }}
                    onRow={(record, rowIndex) => {
                        return {
                            onClick: (event) => {
                                setDrawerOpen(record)
                                if (record.status === "1") {
                                    setBtnDisabled(false)
                                } else {
                                    setBtnDisabled(true)
                                }
                                if (moment().isBefore(selectDate.format("YYYY-MM-DD"))) {
                                    setBtnDisabled(false)
                                }

                            }, // click row
                        };
                    }}
                // onChange={(pagination) => setPagination(pagination)}
                />
            </div>

            {
                drawerOpen && (
                    <Drawer
                        title={drawerOpen.driver}
                        placement="right"
                        width="960px"
                        closable={false}
                        extra={
                            (<CloseOutlined
                                style={{ cursor: "pointer", color: "rgba(0, 0, 0, 0.45)" }}
                                onClick={() => setDrawerOpen(false)}
                            />)
                        }

                        footer={
                            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                <Button type="text" style={{ marginRight: 16 }} onClick={()=> setDrawerOpen(false) }>關閉</Button>
                                {
                                    permission ===2 && selectDate.format("YYYYMMDD") === moment().format("YYYYMMDD") && drawerOpen.status !=="99" ? (
                                        <Button type="primary"
                                            htmlType="submit"
                                            disabled={btnDisabled}
                                            onClick={() => {
                                                let param=`driver_row_id=${drawerOpen.driver_row_id}&work_date=${selectDate.format("YYYY-MM-DD")}&status=0`
                                                SetDriverAttendStatus(param).then(res=>{
                                                    if(res&&!res.Error){
                                                        fetchApi()
                                                        setDrawerOpen(false)
                                                    }
                                                })
                                            }}
                                        >結束出勤</Button>
                                    ) :  permission ===2 && moment().isBefore(selectDate.format("YYYY-MM-DD")) ? (
                                        <Button type="primary"
                                            htmlType="submit"
                                            disabled={btnDisabled}
                                            onClick={() => {
                                                changeWorkDate({...drawerOpen,period:[selectDate,selectDate]})
                                                setDrawerOpen(false)
                                                //   submitRef.current.submit();
                                                // setSubmitForm(true)
                                            }}
                                        >出勤設定</Button>
                                    ) : (<></>)
                                }

                            </div>
                        }
                        visible={drawerOpen}>
                        <div className='work_status_block'>
                            <div >
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <div style={{ width: 6, height: 6, borderRadius: "50%", background: Status(drawerOpen.status).color, marginRight: 8 }}></div>
                                    <p style={{ color: "rgba(0, 0, 0, 0.45)", fontSize: 14 }}>當日狀態</p></div>
                                <p className='title'>{Status(drawerOpen.status).val}</p>
                            </div>
                            <div>
                                <p style={{ color: "rgba(0, 0, 0, 0.45)", fontSize: 14 }}>{selectDate.format("M")} 月份累積運載</p>
                                <p className='title'>{caseMonthAmount}</p>
                            </div>
                        </div>
                        <div style={{ marginTop: 24 }}>
                            <h6 style={{ fontWeight: 500 }}>當日執行</h6>
                            <Table
                                columns={ExecutedColumns}
                                rowKey={(record) => record.id}
                                dataSource={executeList}
                                pagination={false}
                                style={{ minHeight: 400, marginTop: 8 }}
                            // onChange={(pagination) => setPagination(pagination)}
                            />
                        </div>
                    </Drawer>
                )
            }

        </div>
    );
}


export default CustomCalendar;

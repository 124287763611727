import React, {useState} from 'react'
import { Button, Form, Input,Modal  } from 'antd'
import { useNavigate } from 'react-router-dom'
import {ForgetPassword} from '../../api/Member'


const ForgetPW = () => {
    let navigate = useNavigate()
    const [modal, contextHolder] = Modal.useModal()
    const [error, setError] = useState(null)
    const config = {
        title: '發送成功',
        content: "更改密碼之驗證信已發送至您的信箱，請前往信箱查看後重新登入。",
        okText:'確認',
        onOk: ()=>{
            navigate("/user/login")
        }
      };
    const onFinish = async (val)=>{
        let res = await ForgetPassword(val)
        if(!res.Error){
            modal.info(config)
        }else{
            setError(res.Error.Message)
        }
    }
   
    return (
        <div style={{ background: '#F0F2F5', height: "100%" }}>
            <div style={{ background: `url(${process.env.PUBLIC_URL}/assets/images/bg.svg)`, height: "100%", width: "100%", backgroundRepeat: 'no-repeat', position: "relative" }}>
                <div style={{ width: 540, position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%,-50%)' }}>
                    <div style={{ width: 226, margin: '0 auto' }}>
                        <figure style={{ width: '100%' }}>
                            <img src={`${process.env.PUBLIC_URL}/assets/images/logo.png`} />
                        </figure>
                    </div>
                    <p style={{paddingTop:56, fontSize:16, fontWeight:"bold", color:'black'}}>忘記密碼</p>
                    <Form
                        name="basic"
                        style={{marginTop:20}}
                        onFinish={onFinish}
                        autoComplete="off"
                    >
                        <Form.Item
                            label=""
                            name="user_id"
                            rules={[{ required: true, message: '請輸入帳號'}]}
                            validateStatus={error? "error":""}
                        >
                            <Input placeholder='帳號' style={{padding:8, fontSize:16}} onKeyUp={()=>setError(null)}/>
                        </Form.Item>

                        <Form.Item
                            label=""
                            name="email"
                            rules={[
                                    { type: 'email', message: 'email格式錯誤'},
                                    { required: true, message: '請輸入email'},
                                ]}
                            validateStatus={error? "error":""}
                            help={error}
                        >
                            <Input placeholder='Email' style={{padding:8, fontSize:16}} onKeyUp={()=>setError(null)} />
                        </Form.Item>
                        <div style={{display:'grid', gridTemplateColumns:'repeat(2,1fr)'}}>
                            <Form.Item
                            >
                                <Button type="primary" htmlType="submit" block style={{fontSize:16}}>
                                確認
                                </Button>
                            </Form.Item>

                            <Button 
                                style={{fontSize:16, padding:0, textAlign:'right'}} 
                                type="link"
                                onClick={()=>navigate('/user/login')}
                            >回到登入</Button>
                        </div>
                       

                       
                    </Form>
                    {contextHolder}
                    {/* <Modal title="Basic Modal" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                        <p>Some contents...</p>
                        <p>Some contents...</p>
                        <p>Some contents...</p>
                    </Modal> */}
                </div>
            </div>
        </div>
    )
}

export default ForgetPW;

import React, { useState, useRef, useEffect } from 'react';
import { Form, Select, Input, Popconfirm, Radio, DatePicker, TimePicker, Button, Cascader, AutoComplete } from 'antd'
import CarInfo from './CarInfo'
import { useNavigate, useLocation } from 'react-router-dom'
import { ArrowRightOutlined, PhoneOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux'
import { addCase, } from '../../../actions/cases'
import { GetCarModelList, GetCaseType } from '../../../api/Code'
import { CasePriceId, GetCustomerStation, GetOrderCustomerList, } from '../../../api/Case'
import { GetHandOverList, CheckValidAddr } from '../../../api/Code';
import CustomCascader from '../../shared/component/CustomCascader';
import { GetCustomerDetail } from '../../../api/Customer';
import moment from "moment"
const { Option } = Select

const Step1 = ({ currentStep, setCurrentStep }) => {
  const { state } = useLocation()
  let CarInfoData = useSelector(state => state.Cases)
  let case_type = useSelector(state => state.caseType)
  let DispatchCarBrand = useSelector(state => state.CarBrand) 
  //客戶別選項
  let CustomerLists = useSelector(state => state.CustomerList) 
  let County = useSelector(state => state.county) 
  const [sourceState, setSourceState] = useState(()=>{
    return state?state.calling_source:"LINE"
  })
  const [iniData, setIntData] = useState({ calling_date: moment(), calling_time: moment() })
  const [carInfo, setCarInfo] = useState(null)
  const [error, setError] = useState(null)
  //交接車人選單
  const [deliveryPersonList, setDeliveryPersonList] = useState([])
  const [pickerList, setPickerList] = useState([])
  const [customerOptions, setCutomerOptions] = useState([])
  const [caseTypeList, setCaseTypeList] = useState([])
  const [orderCustomerList, setOrderCustomerList] = useState([])
  const [CarNeed, setCarNeed] = useState(null)
  const [originType, setOriginType,] = useState(1)
  const [destinationType, setDestinationType] = useState(1)
  const [unvalidAdd, setUnvalidAdd] = useState({ origin: null, destination: null })
  const [CaseOption, setCaseOption] = useState({customer_row_id:'',case_type_id:'', origin: { type: 1, data: [] }, destination: { type: 1, data: [] }})
  const [priceModuleData, setPriceModuleData] = useState(null)
  const customerInput = useRef(null)
  const caseTypeInput = useRef(null)
  const startingPointInput = useRef(null)
  const endingPointInput = useRef(null)
  const callingDateInput = useRef(null)
  const callingTimeInput = useRef(null)
  const carInfoInput = useRef(null)
  const callingGroupInput = useRef(null)
  const lineIDInput = useRef(null)
  const dispatch = useDispatch()
  const formRef = useRef()
  let navigate = useNavigate()

  const FetchInitalValues = (data)=>{
    data.orderCustomer = data.orderCustomer?data.orderCustomer.toLowerCase():""
    if(data.car_hood_type) setCarNeed(data.car_hood_type)
    let origin_type = data.origin.length >1 ?2:1
    let destination_type = data.destination.length >1 ?2:1
    setOriginType(origin_type)
    setDestinationType(destination_type)
    setCaseOption({...CaseOption,customer_row_id: data.customer_row_id ? data.customer_row_id : "",
    case_type_id: data.case_type_id ? data.case_type_id : "",})
    formRef.current?.setFieldsValue(data)
  }

  
  const setState = async ()=>{
    if (state) {
      if(state.transport_type ==="暫不配送"){
        let add_array = ['origin','destination']
        for(let i=0; i<add_array.length; i++){
            let a = add_array[i]
            if(state[`${a}_service_point`]){
              let point = await GetCustomerStation(state.customer_row_id)
             if (!point.Error) {
               let filter = point.Result.filter((t) => t.code === state[`${a}_service_point`]);
               if(filter.length>0) 
               state[a] = filter.length>0?[`${state[`${a}_service_point`]},${filter[0]['row_id']}`]:[]
             }
           }else{
             state[a] = ['1',`${state[`${a}_county`]}`,`${state[`${a}_town`]}`]
           }
        }
        state.calling_date = moment(state.calling_date)
        state.calling_time = moment(state.calling_date,"HH:mm")
        state.carType1 = state.vehicle_model_id
        let brand = await GetCarModelList()
        if(brand && !brand.Error){
          let car_brand = brand.Result.filter(b=>b.Code===state.vehicle_model_id)
          let car_status = state.power_status.toString();

          let car=[
            {
                "key": 1,
                "no": 1,
                "mobility": car_status,
                "CarBrand": car_brand.length>0?car_brand[0]["ParentName"]:'',
                "CarType": state.vehicle_model_id,
                "color":state.vehicle_color,
                VIN:state.VIN
            }
        ]
          if(car_status ==="0"){
            let unpower = {
              "key": "no1",
              "no": "不可行駛原因",
              "uploadFile": state.report_picture,
              "description": state.report_content,
              recordMethod:state.report_way.toString()
          }
            car[0]['unpower'] = unpower;
            car.push(unpower)
            state.reportMethodno1= state.report_way.toString()
            state.descriptionno1= unpower.description
            if(state.report_way !== 2){
              state.reportPhotono1 = state.report_picture
            }
          }
          
        state.carBrand1 = car_brand.length>0?car_brand[0]["ParentName"]:''
        state.carType1 = state.vehicle_model_id
        dispatch(addCase(car));
        }
      //CarInfoData.customerInfo = state
      }
      FetchInitalValues(state)
      //查詢上單客戶
      GetOrderCustomerList(state.customer_row_id).then(res => {
        if (!res.Error) setOrderCustomerList(res.Result)
      })
      // if(state.transport_type ==="暫不配送"){ setCurrentStep(1) }
      setSourceState(state.calling_source)

    }
  }
  useEffect(() => {
    if(state)  setState()
   
  }, [state])
  useEffect(()=>{
    if(CarInfoData.customerInfo)  FetchInitalValues(CarInfoData.customerInfo)
  },[CarInfoData])
  useEffect(() => {
    if (case_type) setCaseTypeList(case_type)
  }, [case_type])
  useEffect(() => {
    if (CustomerLists) {
      let isUnmount = false;
      setCutomerOptions(CustomerLists)
      return () => isUnmount = true
    }
  }, [CustomerLists])
  // useEffect(() => {
  //   if (CaseOption.customer_row_id !== "" && CaseOption.case_type_id !== "") {
  //     CasePriceId(CaseOption.customer_row_id, CaseOption.case_type_id).then(res => {
  //       if (res && !res.Error) {
  //         setPriceModuleData(res.Result)
  //       }
  //     })
  //   }
  // }, [CaseOption])

  useEffect(() => {
    if (carInfo !== null) {
      let valObj = {};
      let keys=["CarBrand","CarType"]
      carInfo.forEach(c => {
        keys.forEach(key=>{
          let name = `${key.charAt(0).toLowerCase()
          + key.slice(1)}${c.key}`
          valObj[name] = c[key]

        })
        if (c.no === "不可行駛原因") {
          let reportMethod = `reportMethod${c.key}`
          valObj[reportMethod] = c.recordMethod
        }
      })
      if (currentStep === 0) {
        setTimeout(()=>formRef.current.setFieldsValue(valObj), 100)
      }
    }
  }, [carInfo])

  const onFinish = async (values) => {
    let caseType = await GetCaseType()
    caseType = caseType.Result
    let filter_customer = caseType.filter(c => c.Code === values.case_type_id)
    if (filter_customer.length > 0) {
      values.case_type = `${values.case_type_id} - ${filter_customer[0]["Name"]}運送`
    } else {
      values.case_type = ''
    }
    let customer_filter = await GetCustomerDetail(values.customer_row_id)
    if (customer_filter && !customer_filter.Error) {
      values.customer = `${customer_filter.Result.id}-${customer_filter.Result.name}`
      values.is_VIP = customer_filter.Result.is_VIP
    }
    values.calling_source = sourceState
    let address_key = ["origin","destination"]
    let param =''
    address_key.forEach((key,i)=>{
      param += i===0?'':'&'
      if(values[key].length ===1){
        let point = values[key][0].split(",")
        param+= `${key}_service_point=${point[0]}` 
      }else{
        param+= `${key}_county=${values[key][1]}&${key}_town=${values[key][2]}`
      }
    })
    let customer_row_id = typeof(values.customer_row_id)==='string'?values.customer_row_id:values.customer_row_id[0]
    let price = await CasePriceId(customer_row_id,values.case_type_id,param)
    // console.log(price)
    if(price && !price.Error){
        if(price.Result){
          let priceModuleData = price.Result
          values.project_type = priceModuleData.name
          values.request_for_payment = priceModuleData.target
          // console.log(price.Result)
        }
    }
    let sources = {
      "LINE": ["line_group_name", "line_id"],
      "Email": ["calling_person_email", "calling_person_name", "calling_person_phone"],
      "080": ["calling_person_name", "calling_person_phone"],
      "傳真": ["calling_person_name", "calling_person_phone"],
      "現場加派": ["calling_person_name", "calling_person_phone"],
    }
    let source_arr = []
    if (sources[values.calling_source]) {
      sources[values.calling_source].forEach(s => {
        if (values[s] && values[s] !== "") {
          source_arr.push(values[s])
        }
      })
    }
    values.calling_arr = source_arr
    //驗證地址
    let add = ["origin", "destination"]
    let town = ''
    let unvalid = { ...unvalidAdd }
    for (let i = 0; i < add.length; i++) {
      let key = add[i]
      if (values[key].length === 3) {
        if(town ===''){
          let city = County[0]["children"].filter(c=>c.value === values[key][1])
          if(city.length>0){
            town+=city[0]["label"]
            let State = city[0]["children"].filter(t=>t.Code === values[key][2])
            town+=State?State[0]["label"]:""
          }

        }
        let address = `${town}${values[`${key}_address`]}`
        let valid = await CheckValidAddr({ addr_list: [address] })
        if (valid && !valid.Error) {
          unvalid[key] = !valid.Result[0]["is_check"]
          if (!valid.Result[0]["is_check"]) {
            let container = document.querySelector(".container");
            container.scrollTo(0, 0);
          }
        } else {
          unvalid[key] = null
        }
      }
      if (values.orderCustomer) {
      let customer_arr = orderCustomerList.filter(o=> o.c_row_id === values.orderCustomer)
      if(customer_arr.length>0){
        values.order_customer = values.orderCustomer
        values.order_type = customer_arr[0]["type"]
      }
    }
      setUnvalidAdd(unvalid)
      let filter = Object.keys(unvalid).filter(u => unvalid[u])
      if (filter.length === 0) {
        if (currentStep === 0) {
          // console.log(state)
          if(state && state.transport_type === "暫不配送"){
            // values.transport_type = ""
            // values.is_draft = false
            values.case_status = state.case_status
            values.vehicle_sn = state.vehicle_sn
            // values.case_info_row_id = state.case_info_row_id
            values.caseId = state.caseId
            // console.log(state)
            // Object.keys(state).forEach(key=>{
            //   if(key.indexOf("vehicle") ===-1){
            //     values[key] = values[key]? values[key]:state[key]
            //   }
            // })

          }
          dispatch(addCase(CarInfoData, values))
          setCurrentStep(1)
          setError(null)
        }
      }
    }
  };

  const onFinishFailed = (values) => {
    if (values.errorFields.length > 0) {
      let errorFields = values.errorFields[0];
      let first = errorFields.name[0]
      if (first === "customer_row_id") {
        customerInput.current?.focus();
      } else if (first === "case_type_id") {
        caseTypeInput?.current.focus();
      } else if (first === "origin_address") {
        if (startingPointInput.current) startingPointInput.current?.focus()
      } else if (first.indexOf("carInfo") > -1) {
        carInfoInput.current?.focus()
      } else if (first === "calling_date") {
        callingDateInput?.current.focus()
      } else if (first === "calling_time") {
        callingTimeInput.current?.focus()
      } else if (first === "line_group_name") {
        callingGroupInput.curren?.focus()
      } else if (first === "line_ID") {
        lineIDInput.current?.focus()
      } else if (first === "destination_address") {
        if (endingPointInput.current) endingPointInput.current?.focus()
      }
      if (currentStep === 0) {
        setError(values.errorFields)
      }
    }
  }
  const changeOption = (type, val) => {
    let optionObj = { ...CaseOption }
    if (type === "origin" || type === "destination") {
      if (val.length === 1) {
        let arr = val[0].split(",")
        optionObj[`${type}_service_point`] = arr[0]
        GetHandOverList(arr[1]).then(res => {
          let Result = res.Result.map(r => {
            r.label = `${r.name}`
            r.value = `${r.name},${r.tel}`
            return r
          })
          if (!res.Error) {
            if (type === 'origin') {
              setDeliveryPersonList(Result)
            } else {
              setPickerList(Result)
            }
          }
        })
      } else {
        optionObj[`${type}_county`] = val[1]
        optionObj[`${type}_town`] = val[2]
      }
    } else {
      if (type === 'case_type_id') {
        if (val === '012') {
          formRef.current.setFieldsValue({ harbor_area_pass: '委託方辦理' })
        } else if (val === '013') {
          formRef.current.setFieldsValue({ car_hood_type: '0' })
        } else if (val === '014') {
          CarInfoData.cases = CarInfoData.cases && []
          setCarInfo(null)
        }
      } else if (type === 'customer_row_id') {
        //查詢上單客戶
        GetOrderCustomerList(val).then(res => {
          if (!res.Error) setOrderCustomerList(res.Result)


        })
      }
      optionObj[type] = val
    }
    setCaseOption(optionObj)

  }
  return (
    <div>
      <Form
        name="basic"
        layout="vertical"
        ref={formRef}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        initialValues={iniData}
      >
        <div className="step_content">
          <div style={{ display: "flex" }}>
            <Form.Item
              label="客戶別"
              name="customer_row_id"
              rules={[{ required: true, message: "請選擇或輸入客戶別!" }]}
            >
              <Cascader
                allowClear
                showSearch
                placeholder="請選擇客戶別"
                options={customerOptions}
                style={{ width: "304px" }}
                onChange={(val) => {
                  if (CarInfoData.customerInfo) {
                    let obj = { ...CarInfoData.customerInfo }
                    dispatch(addCase(CarInfoData, obj))
                  }
                  formRef.current.setFieldsValue({ customer_row_id: val[0] })
                  changeOption("customer_row_id", val[0])

                }}
              />
            </Form.Item>
            <Form.Item
              label="案件類型"
              name="case_type_id"
              rules={[{ required: true, message: "請選擇案件類型！" }]}
              style={{ margin: "0 16px" }}
            >
              <Select
                allowClear
                style={{ width: "304px" }}
                placeholder="點擊選擇"
                ref={caseTypeInput}
                onChange={(val) => {
                  changeOption("case_type_id", val)

                }}
              >
                {
                  caseTypeList.map(c => (<Option key={`${c.Code} - ${c.Name}運送`} value={c.Code}>{c.Name}運送</Option>))
                }
              </Select>
            </Form.Item>
            <Form.Item
              label="上單客戶"
              name="orderCustomer"
            >
              <Select
                placeholder="點擊選擇"
                allowClear
                style={{ width: "304px" }}
              >
                {
                  orderCustomerList.map(c => (<Option key={c.code} value={`${c.c_row_id}`}>{c.code}/{c.name}</Option>))
                }
              </Select>
            </Form.Item>
          </div>

          <div style={{ display: "flex" }}>
            <div>
              <Form.Item
                label="起運點"
                name="origin"
                rules={[{ required: true, message: "請選擇或輸入起運點!" }]}
                style={{ marginRight: "16px" }}
                hasFeedback={unvalidAdd.origin}
                validateStatus={!unvalidAdd.origin ? 'success' : 'error'}
              >
                <CustomCascader
                  row_id
                  withServicePoint
                  ref={startingPointInput}
                  defaultValue={CarInfoData.customerInfo ? CarInfoData.customerInfo.origin : state ? state.origin : ""}
                  style={{ width: "304px" }}
                  selectedCustomer={CaseOption.customer_row_id}
                  OnChange={(val) => {
                    if (val.length > 1) {
                      setOriginType(2)
                    } else {
                      let arr = val[0].split(",")
                      setOriginType(arr[1])
                    }
                    changeOption("origin", val)
                    formRef.current.setFieldsValue({ origin: val })
                  }}
                />

              </Form.Item>
              {originType === 2 && (
                <>
                  <Form.Item
                    label=""
                    name="origin_address"
                    rules={[{ required: true, message: "請輸入完整地址!" }]}
                    style={{
                      marginRight: "16px",
                      marginTop: -12,
                      marginBottom: 24,
                    }}
                    hasFeedback={unvalidAdd.origin}
                    validateStatus={!unvalidAdd.origin ? 'success' : 'error'}
                  >
                    <Input
                      allowClear
                      placeholder="請輸入完整地址"
                      style={{ width: "304px" }}
                    />
                  </Form.Item>
                  <div style={{ width: 304, marginTop: -12, display: "grid", gridTemplateColumns: "repeat(2,1fr)", gridGap: 16 }}>
                    <Form.Item
                      label=""
                      name="origin_floor"
                      hasFeedback={unvalidAdd.origin}
                      validateStatus={!unvalidAdd.origin ? 'success' : 'error'}
                      
                    >
                      <Input
                        allowClear
                        placeholder=""
                        addonAfter="樓"
                      />
                    </Form.Item>
                    <Form.Item
                      label=""
                      name="origin_address_room"
                      hasFeedback={unvalidAdd.origin}
                      validateStatus={!unvalidAdd.origin ? 'success' : 'error'}
                      // help={unvalidAdd.origin?"查無此地址，請輸入有效地址!":""}
                    >
                      <Input
                        allowClear
                        placeholder=""
                        addonAfter="室"

                      />
                    </Form.Item>
                  </div>
                  {
                      unvalidAdd.origin&& (<div className='ant-form-item-explain-error input-error'>查無此地址，請輸入有效地址!</div>)
                  }
                </>
              )}
            </div>
            <div>
              <Form.Item
                label="訖運點"
                name="destination"
                rules={[{ required: true, message: "請選擇或輸入訖運點!" }]}
              >
                <CustomCascader
                  row_id
                  // NameCode
                  ref={endingPointInput}
                  defaultValue={CarInfoData && CarInfoData.customerInfo ? CarInfoData.customerInfo.destination : state ? state.destination : ""}
                  withServicePoint
                  style={{ width: "304px" }}
                  selectedCustomer={CaseOption.customer_row_id}
                  OnChange={(val) => {
                    // let destination = val
                    // let destination_town = ''
                    if (val.length > 1) {
                      setDestinationType(2)
                      // destination = val.map(v=>{
                      //   let code = v
                      //   if(v.indexOf(",")>-1){
                      //     code = v.split(",")[0]
                      //     destination_town+= v.split(",")[1]
                      //   }
                      //   return code
                      // })
                    } else {
                      let arr = val[0].split(",")
                      // destination_town =''
                      // destination=val
                      setDestinationType(arr[1])
                    }
                    changeOption("destination", val)
                    formRef.current.setFieldsValue({ destination: val })
                  }}
                />
              </Form.Item>
              {destinationType === 2 && (
                <>
                  <Form.Item
                    label=""
                    name="destination_address"
                    rules={[{ required: true, message: "請輸入完整地址!" }]}
                    style={{
                      marginRight: "16px",
                      marginTop: -12,
                      marginBottom: 24,
                    }}
                    hasFeedback={unvalidAdd.destination}
                    validateStatus={!unvalidAdd.destination ? 'success' : 'error'}
                  >
                    <Input
                      allowClear
                      placeholder="請輸入完整地址"
                      style={{ width: "304px" }}
                    />
                  </Form.Item>
                  <div style={{ width: 304, marginTop: -12, display: "grid", gridTemplateColumns: "repeat(2,1fr)", gridGap: 16 }}>
                    <Form.Item
                      label=""
                      name="destination_floor"
                      hasFeedback={unvalidAdd.destination}
                      validateStatus={!unvalidAdd.destination ? 'success' : 'error'}
                      help={unvalidAdd.destination ? "查無此地址，請輸入有效地址!" : ""}
                    >
                      <Input
                        allowClear
                        placeholder=""
                        addonAfter="樓"
                      />
                    </Form.Item>
                    <Form.Item
                      label=""
                      name="destination_address_room"
                      hasFeedback={unvalidAdd.destination}
                      validateStatus={!unvalidAdd.destination ? 'success' : 'error'}
                    >
                      <Input
                        allowClear
                        placeholder=""
                        addonAfter="室"
                      />
                    </Form.Item>
                  </div>
                </>
              )}
            </div>
          </div>
          <div style={{ display: "flex" }}>
            {/* 拍賣車 */}
            {
              (CaseOption.case_type_id === "010" || CaseOption.case_type_id === "011") && (
                <Form.Item
                  label="拍賣單號"
                  name="auction_no"
                  rules={[{ required: true, message: "請輸入拍賣單號!" }]}
                >
                  <Input
                    style={{ width: 304 }}
                    allowClear
                    placeholder="例：B0025"
                  />
                </Form.Item>)
            }

            {/* 港口/外匯車 */}
            {
              CaseOption.case_type_id === "012" && (
                <Form.Item
                  label="港務通行證辦理"
                  name="harbor_area_pass"
                  rules={[{ required: true, message: "請選擇港務通行證辦理!" }]}
                >
                  <Radio.Group>
                    <Radio.Button value="委託方辦理">委託方辦理</Radio.Button>
                    <Radio.Button value="司機辦理">司機辦理</Radio.Button>
                  </Radio.Group>
                </Form.Item>)
            }
            {/* 保密車 */}
            {
              CaseOption.case_type_id === "013" && (
                <div style={{ display: "flex" }}>
                  <Form.Item
                    label="車罩需求"
                    name="car_hood_type"
                    rules={[{ required: true, message: "請選擇車罩需求!" }]}

                  >
                    <Radio.Group
                      onChange={(e) => e.target.value === "2" ? setCarNeed("2") : setCarNeed(null)}
                    >
                      <Radio.Button value="0" >委託方自備</Radio.Button>
                      <Radio.Button value="1" >全鋒代準備</Radio.Button>
                      <Radio.Button value="2" >其他</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                  {
                    CarNeed === "2" && (<Form.Item
                      label="車罩說明"
                      name="car_hood_note_txt"
                      rules={[{ required: true, message: "請輸入車罩說明!" }]}
                      style={{ width: 304, marginLeft: 16 }}

                    >
                      <Input
                        allowClear
                        placeholder="請簡述車罩"
                      />
                    </Form.Item>)
                  }

                </div>)
            }
            {/* 零鈑件運送 */}
            {
              CaseOption.case_type_id === "014" && (
                <Form.Item
                  label="運送品項、件數"
                  name="components_note"
                  rules={[{ required: true, message: "請輸入您欲運送內容" }]}
                >
                  <Input
                    style={{ width: 304 }}
                    allowClear
                    placeholder="請輸入您欲運送內容，例：鈑件5件"
                  />
                </Form.Item>)
            }
            {/* 案件計酬 */}
            {
              CaseOption.case_type_id === "015" && (
                <Form.Item
                  label="案件說明"
                  name="components_note"
                  rules={[{ required: true, message: "請輸入案件說明!" }]}
                >
                  <Input
                    style={{ width: 624 }}
                    allowClear
                    placeholder="請簡述這個案件"
                  />
                </Form.Item>)
            }
          </div>
          {
            (CaseOption.case_type_id !== "014" && CaseOption.case_type_id !== "015") && (<>
              <div>
                <CarInfo
                  state={state}
                  style={{ width: "100%" }}
                  title={"車輛資訊"}
                  formRef={formRef}
                  carInfos={(value) => {
                    if (currentStep === 0) {
                      setCarInfo(value);
                    }
                  }}
                  error={
                    error &&
                    error.filter((e) => e.name[0].indexOf("carType") > -1)
                  }
                />
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Form.Item label="交車人資訊" name="delivery_person">
                  <AutoComplete
                    options={deliveryPersonList}
                    onChange={(val) => {
                      if (val.indexOf(",") > -1) {
                        let arr = val.split(",")
                        setTimeout(() => {
                          formRef.current.setFieldsValue({ delivery_person: arr[0], delivery_person_phone: arr[1] })
                        }, 10)

                      }
                    }}
                    placeholder={"請輸入名字"}
                    style={{ width: "304px" }}
                  />
                </Form.Item>
                <Form.Item
                  label=""
                  name="delivery_person_phone"
                  style={{ margin: "0 16px 24px" }}
                >
                  <Input
                    prefix={<PhoneOutlined />}
                    allowClear
                    placeholder="請輸入電話"
                    style={{ width: "304px", marginTop: 30 }}
                  // disabled={deliveryPersonChecked}
                  />
                </Form.Item>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Form.Item label="接車人資訊" name="pick_up_person">
                  <AutoComplete
                    // prefix={<UserOutlined />}
                    options={pickerList}
                    onChange={(val) => {
                      if (val.indexOf(",") > -1) {
                        let arr = val.split(",")
                        setTimeout(() => {
                          formRef.current.setFieldsValue({ pick_up_person: arr[0], pick_up_person_phone: arr[1] })
                        }, 10)

                      }
                    }}
                    placeholder={"請輸入名字"}
                    style={{ width: "304px" }}
                  />
                  {/* <Input
                prefix={<UserOutlined />}
                allowClear
                placeholder="請輸入名字"
                style={{ width: "304px" }}
                disabled={pickerChecked}
              /> */}
                </Form.Item>
                <Form.Item
                  label=""
                  name="pick_up_person_phone"
                  style={{ margin: "0 16px 24px" }}
                >
                  <Input
                    prefix={<PhoneOutlined />}
                    allowClear
                    placeholder="請輸入電話"
                    style={{ width: "304px", marginTop: 30 }}
                  // disabled={pickerChecked}
                  />
                </Form.Item>
                {/* <Checkbox
                      onChange={(value) =>{
                          let check = value.target.checked
                          setPickerChecked(check)
                          let obj={
                            pick_up_person:"",
                            pick_up_person_phone:""
                          }
                          if(check){
                            obj.pick_up_person =loginUser.name
                            obj.pick_up_person_phone =loginUser.tel
                                
                          }else{
                            obj.pick_up_person = ""
                            obj.pick_up_person_phone = ""
                          }
                          formRef.current.setFieldsValue(obj)
                      }}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                        <div style={{paddingTop: 5}}> 同上單者</div>
                    </Checkbox> */}
              </div>
            </>)
          }

          <div>
            <p style={{ fontSize: "16px", fontWeight: 500, paddingTop: "8px" }} >
              來源資訊
            </p>
            <div
              style={{
                width: "100%",
                background: "#FAFAFA",
                marginTop: "8px",
                padding: "16px",
              }}
            >
              <p>叫車來源</p>
              <Radio.Group
                defaultValue={sourceState}
                buttonStyle="solid"
                style={{ margin: "8px 0 24px 0" }}
                onChange={(e) => {
                  if (currentStep === 0) {
                    setSourceState(e.target.value);
                  }
                }}
              >
                <Radio.Button value="LINE">LINE</Radio.Button>
                <Radio.Button value="Email">Email</Radio.Button>
                <Radio.Button value="080">080</Radio.Button>
                <Radio.Button value="傳真">傳真</Radio.Button>
                <Radio.Button value="現場加派">現場加派</Radio.Button>
              </Radio.Group>
              <div style={{ display: "flex" }}>
                {sourceState === "LINE" ? (
                  <>
                    <Form.Item
                      label="群組名稱"
                      name="line_group_name"
                      style={{ marginRight: "16px" }}
                    // rules={[
                    //   { required: true, message: "請輸入群組名稱！" },
                    // ]}
                    >
                      <Input
                        allowClear
                        placeholder="example"
                        style={{ width: "304px" }}
                        ref={callingGroupInput}
                      />
                    </Form.Item>
                    <Form.Item
                      label="LINE ID"
                      name="line_id"
                    // rules={[{ required: true, message: "請輸入LINE ID！" }]}
                    >
                      <Input
                        allowClear
                        placeholder="example"
                        style={{ width: "304px" }}
                        ref={lineIDInput}
                      />
                    </Form.Item>
                  </>
                ) : (
                  ""
                )}
                {sourceState === "Email" ? (
                  <>
                    <Form.Item
                      label="信箱"
                      name="calling_person_email"
                      style={{ marginRight: "16px" }}
                    >
                      <Input
                        allowClear
                        placeholder="example"
                        style={{ width: "304px" }}
                      />
                    </Form.Item>
                  </>
                ) : (
                  ""
                )}
                {sourceState !== "LINE" && (
                  <>
                    <Form.Item
                      label="姓名"
                      name="calling_person_name"
                      style={{ marginRight: "16px" }}
                    // rules={
                    //   sourceState === "080"
                    //     ? [{ required: true, message: "請輸入姓名！" }]
                    //     : []
                    // }
                    >
                      <Input
                        allowClear
                        placeholder="example"
                        style={{ width: "304px" }}
                      />
                    </Form.Item>
                    <Form.Item
                      label="電話"
                      name="calling_person_phone"
                    // rules={
                    //   sourceState === "080"
                    //     ? [{ required: true, message: "請輸入電話！" }]
                    //     : []
                    // }
                    >
                      <Input
                        allowClear
                        placeholder="example"
                        style={{ width: "304px" }}
                      />
                    </Form.Item>
                  </>
                )}
              </div>
              <div style={{ display: "flex" }}>
                <Form.Item
                  label="叫車日期"
                  name="calling_date"
                  style={{ marginRight: "16px" }}
                  rules={[{ required: true, message: "請選擇叫車日期!" }]}
                >
                  <DatePicker
                    ref={callingDateInput}
                    format={"YYYY/MM/DD"}
                    style={{ width: 258 }}
                  />
                </Form.Item>

                <Form.Item
                  label="叫車時間"
                  name="calling_time"
                  rules={[{ required: true, message: "請選擇叫車時間!" }]}
                >
                  <TimePicker
                    ref={callingTimeInput}
                    style={{ width: 128 }}
                    format={"HH:mm"}
                  />
                </Form.Item>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            background: "white",
            marginTop: 16,
            width: "100%",
            padding: "20px",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Popconfirm
            style={{ width: 410 }}
            placement="top"
            title={
              <div>
                <div>
                  <strong>新增還沒完成喔</strong>
                </div>
                <div style={{ fontSize: 14 }}>
                  剛剛輸入的所有資訊將不被保存。
                </div>
              </div>
            }
            onConfirm={(v) => { }}
            onCancel={(v) => {
              dispatch(addCase([{ key: 1, mobility: "1", no: 1 }]));
              navigate("/cases");
            }}
            okText="不，我要留下"
            cancelText="對，我要離開"
          >
            <Button type="text" style={{ marginRight: 24 }}>
              關閉
            </Button>
          </Popconfirm>

          <Button
            type="primary"
            icon={<ArrowRightOutlined />}
            htmlType="submit"
          >
            下一步
          </Button>
        </div>
      </Form>
    </div>
  );
}
export default Step1;

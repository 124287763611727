import React ,{useState,useEffect } from 'react'
import BreadCrumb from '../../components/shared/BreadCrumb'
// import {PushpinOutlined, PlusOutlined} from '@ant-design/icons';
import { PageHeader, Tabs,Table,Select } from 'antd' 
import {  useNavigate } from 'react-router-dom'
// import axios from 'axios'
import 'moment/locale/zh-cn'
import {GetGasRecords} from '../../api/Gas'
// const { RangePicker } = DatePicker
// const { TabPane } = Tabs
const { Option } = Select

const Index = ({pageTitle, ContainerWidth}) => {
    let navigate = useNavigate()
    // const [currentTab, setCurrentTab] = useState("tab1")
    const [filterVisible, setFilterVisible] = useState(false)
    const [selectedRowKeys, setSelectedRowKeys]= useState([])
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState([])
    const [filter, setFilter] = useState({page_index:0, page_size:10})
    useEffect(() => {
        let container = document.querySelector(".container");
        container.scrollTo(0, 0);
        document.title = pageTitle
        GetGasRecords(`page_index=${filter.page_index}&page_size=${filter.page_size}`).then(res => {
            // let data = res.Result === null ? [] : res.Result 
            res.Result.Data.map( (d,index) =>{
                d.date = d.transaction_time.replaceAll(";",":")
                d.totalPrice = parseInt(d.quantity* d.price)
                d.key = index +1
                return d
            })
            setData(res.Result)
            setLoading(false) 
        })
      }, [filter])
      const columns = [
        {
          title: '交易時間',
          dataIndex: 'date',
          width: '15%',
        },
        {
            title: '客戶編號',
            dataIndex: 'company_no',
            sorter: true,
        },
        {
            title: '使用單位',
            dataIndex: 'department',
        },
        {
            title: '車號',
            dataIndex: 'plate_number',
        },
        {
            title: '平台',
            dataIndex: 'supplier_name',
          },
        {
          title: '站名',
          dataIndex: 'gas_station',
          width: '25%',
        },
        {
            title: '金額',
            dataIndex: 'totalPrice',
          }
       
        
      ];

    return (
        <div className="container" style={ContainerWidth}>
        <PageHeader
            style={{background: "white", width: "100%"}}
            title="加油紀錄"
            className="site-page-header"
            breadcrumb={<BreadCrumb/>}
        >
    
        </PageHeader>
        
        <div className='table_block'>
            <Table
                columns={columns}
                rowKey={record => record.key}
                // rowSelection={selectedRowKeys}
                dataSource={data? data.Data: []}
                pagination={ data  && data.PageCount > 1 ? {
                    total: data? data.RowsCount: 0,
                    showTotal: (total, range) =>
                    `${range[0]}-${range[1]} 列（共${total}  列）`,
                }: false}
                loading={loading}
                style={{padding: "12px 0", minHeight: 400}}
                onRow={(record, index) => {
                    return {
                    onClick: event => {navigate(`/fuel/record/${record.key}`, {state:record})}, // click row
                    };
                }}
                onChange={(pagination)=>{
                    setFilter({...filter, page_index:pagination.current-1})
                }}
            />
        </div>
        </div>
    );
}

export default Index;

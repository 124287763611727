import React ,{useState,useEffect,useRef } from 'react'
import BreadCrumb from '../../components/shared/BreadCrumb'
import {CaretUpOutlined, CaretDownOutlined} from '@ant-design/icons';
import { PageHeader,Statistic,DatePicker, Row, Tabs, Card,Col,Typography,Select } from 'antd'  
import {  useNavigate } from 'react-router-dom'
import { GetLatestGasPrice,GetGasPriceHistory } from '../../api/Gas';
// import { Line } from '@ant-design/charts'

import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend} from 'chart.js'
import { Line } from 'react-chartjs-2'

import 'moment/locale/zh-cn'
import { P } from '@antv/g2plot';
const { Text } = Typography;
const { RangePicker } = DatePicker
const { TabPane } = Tabs
const { Option } = Select
const Index = ({pageTitle, ContainerWidth}) => {
    let navigate = useNavigate()
    const [currentTab, setCurrentTab] = useState("中油")
    const [currentSubTab, setCurrentSubTab] = useState("01")
    const [loading, setLoading] = useState(false)
    const [allData, setAllData] = useState(null)
    const [selectedData, setSelectedData] = useState(null)
    const [selectedSubData, setSelectedSubData] = useState(null)
    const [updateDate, setUpdateDate] = useState(null)
    const [HistoryDate, setHistoryDate] = useState(null)
    let  subTab = 
    [
      { key: "01", name:"92 無鉛" },
      { key: "02", name:"95 無鉛" },
      { key: "03", name:"98 無鉛" },
      { key: "04", name:"超級柴油" }
    ]

    const FetchApi = async()=>{
      let latest_price =  await GetLatestGasPrice()
      let history_price = await GetGasPriceHistory(7)
      let Result_latest = latest_price.Result.sort(function(a, b) {
        return a.type - b.type;
      });
      setUpdateDate(latest_price.Result[0]["price"][0]["monday"])
      let obj={
        "中油":{
          currentEval:[],
          estimatedEval:[],
          history:{
            "01":[],"02":[],"03":[],"04":[],
          }
        },
        "台塑":{
          currentEval:[],
          estimatedEval:[],
          history:{
            "01":[],"02":[],"03":[],"04":[],
          }
        }
      }
      
      Result_latest.forEach(r=>{
        obj[r.source]["currentEval"].push(r.price[0]['price'])
        obj[r.source]["estimatedEval"].push(r.price[0]['next_week_price'])
      })
      let history_data=[]
      history_price.Result.forEach((h,i)=>{
        obj[h.source]["history"][h.type] = h.price.map(p=> p.price?p.price:0)
        if(i===0){
          history_data = h.price.map(p=>{
            let arr = p.monday.split("/")
            return `${arr[1]}/${arr[2]}`
          })
        }
      })
      setHistoryDate(history_data)

      setAllData(obj)
      setSelectedData(obj[currentTab])
      let selected_sub_data=[]
      obj[currentTab]["history"][currentSubTab].forEach((data,i) =>{
        selected_sub_data.push({
          date: history_data[i],
          value:data
        })
      })
      setSelectedSubData(obj[currentTab]["history"][currentSubTab])
      ChartJS.register( CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend )
    }
    useEffect(() => {
      FetchApi()
        document.title = pageTitle
      
      }, [])
      useEffect(()=>{
        if(allData !==null && HistoryDate) {
          setSelectedData(allData[currentTab])
          let selected_sub_data=[]
          allData[currentTab]["history"][currentSubTab].forEach((data,i) =>{
            selected_sub_data.push({
              date: HistoryDate[i],
              value:data
            })
          })

          setSelectedSubData(allData[currentTab]["history"][currentSubTab])
        }
      
      },[currentTab])
      useEffect(()=>{
       
        if(allData !==null) {
          let selected_sub_data=[]
          allData[currentTab]["history"][currentSubTab].forEach((data,i) =>{
            selected_sub_data.push({
              date: HistoryDate[i],
              value:data
            })
          })
          setSelectedSubData(allData[currentTab]["history"][currentSubTab])
          // setSelectedSubData(selected_sub_data)
        }
      },[currentSubTab])
    return (
        <div className="container" style={ContainerWidth}>
            <PageHeader
                style={{background: "white", width: "100%"}}
                title="即時油價及預估"
                className="site-page-header"
                footer={
                <Tabs defaultActiveKey={currentTab} onChange={(e)=>setCurrentTab(e)}>
                    <TabPane tab="中油" key="中油" />
                    <TabPane tab="台塑" key="台塑" />
                </Tabs>
                }
                breadcrumb={<BreadCrumb/>}
            >
        
            </PageHeader>
            <div style={{margin: "24px 24px 0 24px"}}>
                <Card title="即時油價" bordered={false}  extra={<div style={{display:"flex", alignItems:"center"}}>
                    <div style={{width:6, height: 6, border:"1px solid rgba(0, 0, 0, 1)", borderRadius:"50%",marginRight:8}}></div>
                    <div style={{color:"rgba(0, 0, 0, 0.45)"}}>更新時間：{updateDate?updateDate:'-'}</div>
                </div>}>
                    <Row style={{paddingTop: 6, display: "grid", gridTemplateColumns:"repeat(4,1fr)", gridGap: 24}}>
                      {
                        selectedData !==null && subTab.map((tab,i)=>( <Statistic title={tab.name} value={selectedData["currentEval"][i]?.toString()} style={{borderRight: '1px solid rgba(0, 0, 0, 0.03)', paddingRight: 24}}/>)
                        )
                      }
                    </Row>
                </Card>
            </div>
            <div style={{margin: "24px 24px 0 24px"}}>
                <Card title="下週預估" bordered={false}  extra={<div style={{display:"flex", alignItems:"center"}}>
                    <div style={{width:6, height: 6, border:"1px solid rgba(0, 0, 0, 1)", borderRadius:"50%",marginRight:8}}></div>
                    <div style={{color:"rgba(0, 0, 0, 0.45)"}}>更新時間： {updateDate?updateDate:'-'}</div>
                </div>}>
                    <Row style={{paddingTop: 6, display: "grid", gridTemplateColumns:"repeat(4,1fr)", gridGap: 24}}>
                    {
                        selectedData !==null && subTab.map((tab,i)=>{
                          let diff = (selectedData["currentEval"][i] - selectedData["estimatedEval"][i]).toFixed(1)
                          return(<Col>
                            <Statistic title={tab.name} value={selectedData["estimatedEval"][i]} style={{borderRight: '1px solid rgba(0, 0, 0, 0.03)', paddingRight: 24}}/>
                            {
                              diff > 0 ? (<div><CaretDownOutlined style={{color:"rgba(82, 196, 26, 1)"}}/> <Text type="success">{`${diff.replace('-','')}元`}</Text></div>): diff < 0? ( <div><CaretUpOutlined style={{color:"rgba(245, 34, 45, 1)"}}/> <Text type="danger">{`${diff.replace('-','')}元`}</Text></div>):(<></>)
                                
                              }
                          
                            
                        </Col>)
                        }
                        )
                      }
                    </Row>
                </Card>
            </div>
            <div style={{margin: 24, background:"white"}}>
                <div style={{padding: "24px 24px 0 24px"}}>
                    <div style={{fontSize: 16, fontWeight: 700}}>歷史油價趨勢</div>
                    <Tabs defaultActiveKey={currentSubTab} onChange={(e)=>setCurrentSubTab(e)}>
                      {
                        subTab.map(tab => (<TabPane tab={tab.name} key={tab.key} />))
                      }
                    </Tabs>
                </div>

                {
                  selectedSubData !== null && HistoryDate && ( 
                    <Line style={{padding: 24}} 
                    height={80}
                    options={{
                      plugins: {
                        legend: false,
                        title: {
                          display: false,
                        },
                        tooltip: {
                          callbacks: {
                              label: tooltipItem =>  "" ,
                              footer: function(tooltipItem) {
                                  return `${tooltipItem[0]["raw"]}元`;
                              }
                          }
                      },
                      }
                    }} 
                    data={{
                      labels : HistoryDate,
                      datasets: [
                        {
                          data: selectedSubData,
                          borderColor: '#1890FF',
                          backgroundColor: 'white',
                        }
                      ],
                    } 
                    }
                     />
                    )
                }
          
            </div>
            
        </div>
    );
}

export default Index;


export const ExecutedStatusObj = (status) => {
    let obj;
    // 待接案: 0 / 待執行: 1 / 執行中: 2 / 完成: 3 / 取消: 4 / 改派: 5 / 部分完成: 6 / 考慮中: 7 / 媒合確認: 8 / 抵達: 9 
    switch (status) {
        case 0:
            obj = { color: "", text: "待接案", category: "1",background:"rgba(217,217,217,1)" }
            break;
        case 1:
            obj = { color: "", text: "待執行", category: "1" ,background:"rgba(217,217,217,1)"}
            break;
        case 2:
            obj = { color: "", text: "執行中", category: "2" ,background:"rgba(82, 196, 26,1)"}
            break;
        case 3:
            obj = { color: "", text: "完成", category: "3" ,background:"rgba(47, 84, 235, 1)"}
            break;
        case 4:
            obj = { color: "", text: "取消", category: "4", background: "rgba(245, 34, 45, 1)" }
            break;
        case 5:
            obj = { color: "", text: "異況確認", category: "5", background: "rgba(250, 140, 22, 1)" }
            break;
        case 6:
            obj = { color: "", text: "部分完成", category: "2" ,background:"rgba(82, 196, 26,1)"}
            break;
        case 7:
            obj = { color: "", text: "考慮中", category: "1",background:"rgba(217,217,217,1)" }
            break;
        case 8:
            obj = { color: "", text: "媒合確認", category: "1",background:"rgba(217,217,217,1)" }
            break;
        case 9:
            obj = { color: "", text: "抵達", category: "2" ,background:"rgba(82, 196, 26,1)"}
            break;
        default:
            obj = {}

    }

    return obj
}
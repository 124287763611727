import React, { useEffect, useState } from 'react'
import { PageHeader, Button, Table } from 'antd'
import BreadCrumb from '../../components/shared/BreadCrumb'
import { useNavigate,useLocation } from 'react-router-dom'
import { PlusOutlined } from '@ant-design/icons'
import { AllPrivilegeTag } from '../../api/Member'
import { useSelector } from 'react-redux'
import { RouteName } from '../../util/route'
const Tag = ({ ContainerWidth }) => {
    let navigate = useNavigate()
    const { state,pathname } = useLocation()
    const routeId = RouteName(pathname)
    let UserInfo = useSelector(state => state.UserInfo) 
    let UserPermission = UserInfo? UserInfo.permission.route: null
    const [pagination, setPagination] = useState({ current: 1, pageSize: 10 })
    useEffect(() => {
        let container = document.querySelector(".container")
        container.scrollTo(0, 0)
    }, [pagination])
    const [data, setData] = useState([]);
    const columns = UserPermission && UserPermission[routeId] !==2?[{ title: '標籤名稱', dataIndex: 'tag_name', }]:[
        { title: '標籤名稱', dataIndex: 'tag_name', },
        {
            title: '操作', dataIndex: '', width: '15%',
            render: (record, _) => (
                <div onClick={(e) => e.stopPropagation()}>
                    <Button type='link'
                    // onClick={() => showPromiseConfirm(_.status === 1 ? "disabled" : "enable", record)}
                    >刪除</Button>
                </div>)
        }]
    useEffect(() => {
        AllPrivilegeTag().then(res => {
            if (!res.Error) {
                setData(res.Result)
            }
        })
    }, []);
    return (
        <div className="container" style={ContainerWidth}>
            <PageHeader
                style={{ background: "white", width: "100%" }}
                title="權限標籤管理"
                className="site-page-header"
                extra={UserPermission && UserPermission[routeId] !==2?[]:[
                    <Button
                        type="primary"
                        icon={<PlusOutlined />}
                        onClick={() => navigate('/privilege/tags/add')}
                    >
                        權限標籤新增
                    </Button>,
                ]}
                breadcrumb={<BreadCrumb />}
            >
            </PageHeader>
            <div className='table_block'>
                <Table
                    columns={columns}
                    rowKey={record => record.tag_id}
                    dataSource={data}
                    pagination={data.length > 10 ? {
                        total: data.length,
                        showTotal: (total, range) =>
                            `${range[0]}-${range[1]} 列（共${total}  列）`,
                    } : false}
                    onChange={(pagination) => setPagination(pagination)}
                    // loading={loading}
                    style={{ padding: "12px 0", minHeight: 400 }}
                    onRow={(record, rowIndex) => {
                        return {
                            onClick: event => { navigate(`/privilege/tags/${record.tag_id}`) },
                        };
                    }}
                />
            </div>
        </div>
    )
}

export default Tag
import { GetCountyList,GetSelectedTownList } from '../../../api/Code'

export const FilterConty = async (val, slash) => {
    // if (Town !== null) {
        let getCounty = await GetCountyList()
        if(!getCounty.Error){
            let allOptions = []
            let County = getCounty.Result
            for(let c=0; c<County.length; c++){
                let Code = County[c]["Code"]
                let CountyName = County[c]["Name"]
                let getTown = await GetSelectedTownList(Code)
                if(!getTown.Error){
                    let Town = getTown.Result
                    Town.forEach(t=>allOptions.push({...t, parentName: CountyName,parentCode: Code, label: `${CountyName}${t.Name}` }))
                }
            }
            let filter = allOptions.filter(option => option.Code === val)
        
            if (filter.length > 0) {
                return slash ? `${filter[0]["parentName"]} / ${filter[0]["Name"]}` :filter[0]["label"]
            } else {
                return ""
            }
        }else{
            return ''
        }
}
import React,{useState, useEffect} from 'react';
import { Button,Dropdown,Menu} from 'antd'
import {useNavigate } from 'react-router-dom'
import {UnorderedListOutlined,PlusOutlined, RightOutlined} from '@ant-design/icons'
// import { useSelector,useDispatch } from 'react-redux'
// import { fetchCounty,fetchCases } from '../../../actions/iniFetch'
import moment from 'moment'
// import {GetAllCases,GetCaseInfo} from '../../../api/Case'

// import { set } from 'vue/types/umd';

const Step3 = ({caseId,caseData,setCurrentStep,draft}) => {
    let navigate = useNavigate()
    // let caseInfo = useSelector((state) => state)
    let [subCases, setSubCases] = useState([])
    // const dispatch = useDispatch()
    // const fetchAPI = async () => {
    //     let cases = await GetAllCases()
    //     if (!cases.Error) {
    //     let casesList = cases.Result
    //     casesList = 
    //     casesList.map((r, i) => {
    //         let year = moment(r.transport_date).year() - 1911
    //         let date = `${year}${moment(r.transport_date).format("-MM-DD")}`
    //         r.transport_timeStamp = parseInt(moment(r.transport_date).format("x"))
    //         r.transport_date = date
    //         return r
    //       })
    //      dispatch(fetchCases(casesList))
    //     }
    // }
    useEffect(()=>{
        if(caseData !== null){
            let filter_subCases = caseData.vehicles.filter(c => !c.is_cancel )
            setSubCases(filter_subCases)
            // fetchAPI()
        }
    },[])
    
    let menu = (cases)=>{
        let returnMenu =  <Menu>
            {
                cases.map(c =>(
                    <Menu.Item>
                    <div onClick={()=> navigate(`/cases/${caseId}`,{state:{vehicle_sn: c.vehicle_sn}})}>{`${caseId}-${c.vehicle_sn}`}</div>
                    </Menu.Item>
                ))
            }
        </Menu>
        return returnMenu
    }
    return (
        <div >
            <div className='step_content'>
                <div style={{fontSize: 30}}>案件 <span style={{ color: "#1890FF", fontSize: 30 }}>#{caseId} </span>已成立</div>
                <div style={{color: "rgba(0, 0, 0, 0.45)", marginTop: 8}}>如同筆案件新增中有多台車輛，系統會自動分單（編號後加上”-1”, “-2”），下次自動媒合時間為 {moment().add(1,"hour").format("HH")}:00，如客戶不願等候可前往案件內容頁進行手動派遣。</div>
                <div style={{marginTop: 32}}>
                    <Button 
                        type="primary" 
                        icon={<PlusOutlined />} 
                        onClick={()=> setCurrentStep(0)}
                        style={{
                            marginRight:draft?8:0
                        }}
                        >新增案件</Button>
                    {
                        subCases.length>1 && (<Dropdown overlay={menu(subCases)} placement="bottomLeft">
                            <Button icon={<RightOutlined />} style={{margin: "0 8px"}}>案件內容</Button>
                        </Dropdown>)
                    }
                    {
                        subCases.length === 1 && !draft &&(<Button icon={<RightOutlined />} style={{margin: "0 8px"}} onClick={()=> navigate(`/cases/${caseId}`,{state:{caseStatus: "0",vehicle_sn: subCases[0]["vehicle_sn"]}})}> 案件內容</Button>)
                    }
                    {/* <Button icon={<RightOutlined />} style={{margin: "0 8px"}}>案件內容</Button> */}
                    <Button 
                    icon={<UnorderedListOutlined />} 
                    onClick={()=> navigate("/cases")}
                    >回列表</Button>
                    {/* <Link to="/cases">回列表</Link> */}
                </div>
            </div>
        </div>
    );
}

export default Step3;


export const addCase = (state,payload) => {
// console.log(state)
// console.log(payload)
  if (payload) {
    return {
        type: 'ADD_CASE',
        cases: state.cases,
        customer: payload,
      }
  } else {
    return {
      type: 'ADD_CASE',
      cases: state.cases? state.cases : state,
    }
  } 
}


import React, { useState, useEffect } from 'react'
import BreadCrumb from '../../components/shared/BreadCrumb'
// import ModalCard from '../../components/cases/case/ModalCard'
import CustomCard from '../../components/shared/component/Card'
import { PageHeader, Statistic, Row, Card } from 'antd'
import { PushpinTwoTone, PhoneOutlined, PushpinOutlined } from '@ant-design/icons'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import GoogleMapReact from 'google-map-react'
import { GetLatestGasPrice } from '../../api/Gas'
import moment from 'moment'

const Case = ({ ContainerWidth, pageTitle }) => {
    let { stationId } = useParams()
    const { state } = useLocation()
    const [data, setData] = useState(null)
    const [fuelPrice, setFuelPrice] = useState(null)
    const [latlng, setLatln] = useState([state.lat, state.lng])
    const navigate = useNavigate()
    const [mapConfig, setMapConfig] = useState({
        config: {
            apiKey: "AIzaSyDidd-7pD-ozOcsDQGO2wibmHgI7mkBTYE",
            language: 'tw',
            region: 'tw',
        },
        center: { lat: latlng[0], lng: latlng[1]},
        zoom: 16
    })

    useEffect(() => {

        let new_data = { ...state }
        let open_time, open_status
        if (new_data.opentime === "24H") {
            open_time = "00:00 - 24:00"
            open_status = "營業中"
        } else {
            open_time = new_data.opentime
            let time_arr = new_data.opentime.split("-")
            let format = "hh:mm"
            let current = moment().format("HH:mm");
            let start = moment(time_arr[0], format)
            let end = moment(time_arr[1], format)
            let isBetween = moment(current, format).isBetween(start, end)
            open_status = isBetween ? "營業中 " : "未開放"
        }
        new_data.address = { type: "icons", label: new_data.address, icon: <PushpinOutlined /> }
        new_data.phoneNo = { type: "icons", label: new_data.phone, icon: <PhoneOutlined /> }
        new_data.oilType = { type: "tag", content: new_data.kinds_of_fuel, color: "" }

        new_data.operatingDuration = { type: "status", color: "rgba(82, 196, 26, 1)", status: 1, content: [open_status, open_time] }
        // {type: "status", status: open_status, data:open_time}
        GetLatestGasPrice().then(res => {
            let Result = res.Result
            let oil_price = []
            let oil_type = { '01': "92", '02': "95", '03': "98", '04': '超級柴油' }
            Result.forEach(r => {
                if (r.source === new_data["belong_group"]) {
                    oil_price.push({ key: oil_type[r.type], price: r.price[0]["price"] })
                }
            })
            setFuelPrice(oil_price)
        })
        setData(new_data)
    }, [])
    const Marker = ({ text }) => (
        // <div style={{width: 26, height: 26, borderRadius:"50%", background:"blue"}}>
        <PushpinTwoTone fill={""} style={{ fontSize: 26 }} />
        // </div>
    )
    return (
        <div className='container' style={ContainerWidth}>
            <div style={{}}>
                <PageHeader
                    style={{ background: "white", width: "100%" }}
                    title={`${state.belong_group} - ${state.station_name}`}
                    onBack={() => navigate(-1)}
                    className="site-page-header"
                    breadcrumb={<BreadCrumb FuelId={{ [stationId]: state.station_name }} />}
                >
                </PageHeader>
                <div className='split_block' style={{ display: 'grid', gridTemplateColumns: "repeat(2, 1fr)" }}>
                    <div style={{ padding: 24 }}>
                        <Card title="即時油價" bordered={false} >
                            <Row style={{ paddingTop: 6, display: "flex", justifyContent: "space-around" }}>
                                {
                                    fuelPrice !== null && fuelPrice.map((fuel, i) => (<Statistic key={fuel.key} title={i === fuelPrice.length - 1 ? fuel.key : `${fuel.key} 無鉛`} value={fuel.price} style={{ borderRight: i === fuelPrice.length - 1 ? '' : '1px solid rgba(0, 0, 0, 0.03)', paddingRight: 24, margin: "0 auto" }} />))
                                }
                            </Row>
                        </Card>
                        <Card
                            style={{ width: "100%", margin: "24px 0" }}
                            title="油站明細"
                        >
                            {
                                data !== null && (<CustomCard
                                    header={[
                                        { key: "city", name: "縣市別" },
                                        { key: "station_name", name: "站名" },
                                        { key: "belong_group", name: "平台" },
                                        { key: "area_code", name: "區" },
                                        { key: "address", name: "地址" },
                                        { key: "phoneNo", name: "電話" },
                                        { key: "oilType", name: "配合油品" },
                                        { key: "operatingDuration", name: "營業時間" },
                                    ]} data={data} />)
                            }
                        </Card>
                    </div>
                    <div style={{ background: "rgba(250, 250, 250, 1)" }}>
                        <GoogleMapReact
                            bootstrapURLKeys={mapConfig.config}
                            defaultCenter={mapConfig.center}
                            defaultZoom={mapConfig.zoom}
                        >
                            <Marker
                                lat={latlng[0]}
                                lng={latlng[1]}
                            />
                        </GoogleMapReact>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Case;
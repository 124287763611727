import { HashRouter, Route, Routes, Link, useLocation, useNavigate } from 'react-router-dom'
import { Breadcrumb } from 'antd'


const BreadCrumb = ({AddTechinician,AddCar, FuelId, DetailText, Info,addText,extraInfo}) => {
  const location = useLocation()
  const {state} = useLocation()
  const navigate = useNavigate()
  const breadcrumbNameMap = {
    '/cases': '案件列表',
    '/cases/add': '案件新增',
    '/cases/verify': '案件審核',
    '/execution': '執行列表',
    '/points': '據點管理',
    '/points/add': '新增據點',
    '/customer': '客戶管理',
    '/customer/lists/add': '新增客戶',
    '/customer/servicePoints': '共用運送點',
    '/customer/servicePoints/add': '新增共用運送點',
    '/customer/promotions': '行銷活動',
    '/customer/promotions/add': '新增行銷活動',
    '/customer/advertisement': '廣告版位',
    '/customer/advertisement/add': '新增廣告',
    '/oil': '加油管理',
    '/monitor': '即時監控',
    '/statement': '請款報表',
    '/search': '通路查詢',
    '/privilege':'權限管理',
    '/privilege/account':'帳號管理',
    '/privilege/account/add':'帳號新增',
    '/privilege/tags':'權限標籤管理',
    '/privilege/tags/add':'權限標籤新增',
    '/rent': '租賃管理',
    '/rent/add': '帳號新增',

  }
  breadcrumbNameMap[AddTechinician] = "新增技師"
  breadcrumbNameMap[AddCar] = "新增車籍"
  const pathname ={
    fuel: "加油管理",
    search: "通路查詢",
    evaluation: "即時油價及預估",
    evaluation: "即時油價及預估",
    record: "加油紀錄",
    lists: "客戶清單",
    add_technician: "新增技師",
    add_car: "新增車籍",
    add: addText? addText : '',
    details: DetailText? DetailText:''
  }

  if(FuelId){
    let key = Object.keys(FuelId)[0]
    pathname[key] = Object.values(FuelId)[0]
  }

  //ID帶入資料
  if(Info){
    Object.keys(Info).forEach(key=>{
      pathname[key] = Info[key]
    })
  }
  if(extraInfo){
    let key = Object.keys(extraInfo)[0]
    pathname[key] = Object.values(extraInfo)[0]
  }

  const pathSnippets = location.pathname.split('/').filter(i => i);
  const extraBreadcrumbItems = pathSnippets.map((_, index) => {
  const url = `/${pathSnippets.slice(0, index + 1).join('/')}`
  let urlName = ""
  if(breadcrumbNameMap[url]){
    urlName = breadcrumbNameMap[url]
  }else{
    if(url.indexOf("/") > -1){
      let url_ = url.split("/");
      urlName = url_[url_.length -1]
      if(pathname[urlName]){
        urlName = pathname[urlName]
      }
    }
  }
  return (
        <Breadcrumb.Item key={url}>
        <Link to={url} onClick={(e)=>{
          if(index=== pathSnippets.length-1){
              e.preventDefault()
              navigate(0)
          }
        }}>{urlName}</Link>
        </Breadcrumb.Item>
      )
    })
    
    const breadcrumbItems = [
      <Breadcrumb.Item key="home">
        全鋒事業
      </Breadcrumb.Item>,
    ].concat(extraBreadcrumbItems);
    return (
     <div>
        <Breadcrumb style={{marginBottom: "12px"}} >{breadcrumbItems}</Breadcrumb>
      </div>
    );
  
}

export default BreadCrumb;

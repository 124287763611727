import React, { useEffect, useState, useRef } from 'react'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { PageHeader, Tag, Button, Card, Modal, Drawer, Form, Input, DatePicker, Cascader, Menu, Upload, Select, Radio, Dropdown } from 'antd'
import { ToolOutlined, CalendarOutlined, EditOutlined, CloseOutlined, QrcodeOutlined, UploadOutlined, MoreOutlined, PaperClipOutlined, EnterOutlined } from '@ant-design/icons'
import BreadCrumb from '../../components/shared/BreadCrumb'
import Checkbox from 'antd/lib/checkbox/Checkbox'
import { GetServicePoint, GetServicePointCar, GetServicePointCarInsurance, GetServicePointCarHistory, UpdateServicePointCar, AddServicePointCarInsurance, UpdateServicePointCarInsurance, CheckServicePointCarNo,UpdateCarStatus } from '../../api/ServicePoint'
import { GetCarBrandList, GetCarTypeList,GetUnoccupiedCarList } from '../../api/Code'
import CustomCard from '../../components/shared/component/Card'
import LoadingBlock from '../../components/shared/LoadingBlock'
import HistoryRecord from '../../components/shared/component/HistoryRecord'
import { carStatus } from '../../util/status'
import moment from 'moment'
const { TextArea } = Input
const { RangePicker } = DatePicker
const listHeader = [
    { key: "no", name: "車牌號碼" },
    { key: "kind_text", name: "車種" },
    { key: "chassis_no", name: "板架號碼" },
    { key: "engine_no", name: "引擎號碼" },
    { key: "body_no", name: "車身號碼" },
    { key: "company", name: "掛牌公司" },
    { key: "brand_name", name: "廠牌" },
    { key: "type_name", name: "車別" },
    { key: "color", name: "顏色" },
    { key: "engine_displacement_text", name: "排氣量" },
    { key: "car_load_text", name: "載重" },
    { key: "weight_text", name: "總重" },
    { key: "leave_yymm", name: "出廠年月" },
    { key: "license", name: "行照原發照日" },
    { key: "next_check_date", name: "下次驗車日" },
    { key: "fleet_vehicles_text", name: "靠行單位" },
    { key: "gas_kind_text", name: "加油油品" },
    { key: "note", name: "備註" }
]
const listHeaderInsurance = [
    { key: "company", name: "保險公司" },
    { key: "no", name: "保單號碼" },
    { key: "file", name: "保單文件" },
    { key: "period_status", name: "保險期間" },
    { key: "money_text", name: "保費金額" },
    { key: "prevInsurance", name: "前期保單" }
]
const Cardetail = ({ ContainerWidth }) => {
    let { carId, pointId } = useParams()
    let { state } = useLocation()
    const editForm = useRef(), editInsurance = useRef(), statusForm = useRef()
    const navigate = useNavigate()
    const [currentInsuranceTab, setCurrentInsuranceTab] = useState("01")
    const [editRemark, setEditRemark] = useState(null)
    //1: 編輯基本資料, 2a: 編輯保險資料, 2b: 更新保單, 3:更新驗車時間, 4: 變更使用狀態, 5:更新行照時間
    const [modalStatus, setModalStatus] = useState(null)
    const [changeCarModal, setChangeCarModal] = useState(null)
    const [infoData, setInfoData] = useState(null)
    const [carData, setCarData] = useState(null)
    const [insuranceData, setInsuranceData] = useState(null)
    const [check, setCheck] = useState(false)
    const [CarBrand, setCarBrand] = useState(null)
    const [history, setHistory] = useState(null)
    const [CheckDate, setCheckDate] = useState(null)
    const [DrivingLicenseDate, setDrivingLicenseDate] = useState(null)
    const [LicenseFile, setLicenseFile] = useState([])
    const [insuranceObj, setInsuranceObj] = useState(null)
    const [fileObj, setFileObj] = useState(null)
    const [carTypeList, setCarTypeList] = useState(null)
    const [isValid, setIsValid] = useState(null)
    const [notOccupiedCarList, setNotOccupiedCarList] = useState([])
    // GetCarTypeList
    // let tagColor, tagText

    // if (status === 1) {
    //     tagColor = "green"
    //     tagText = "正常"
    // } else if (status === 2) {
    //     tagColor = "red"
    //     tagText = "停派"
    // } else if (status === 3) {
    //     tagColor = "orange"
    //     tagText = "修繕"
    // } else if (status === 4) {
    //     tagColor = "default"
    //     tagText = "撤銷"
    // } else if (status === 5) {
    //     tagColor = "default"
    //     tagText = "報廢"
    // }
    const checkCarPlate = async () => {
        let no = editForm.current.getFieldValue().no
        if (no !== carData.no) {
            let res = await CheckServicePointCarNo(pointId, no)
            setIsValid(res.Result)
            return res.Result
        } else {
            setIsValid(true)
            return true
        }
    }
    const onClose = () => {
        setModalStatus(null)
        setIsValid(null)
    }
    const submitForm = async (val) => {
        let new_car_data = { ...carData }
        let check_no = await checkCarPlate()
        if (!check_no) return false
        let date = { leave: "leave_yymm", licenseDate: "license_date", checkDate: "next_check_date", licenseExpiryDate: "license_expiry_date" }
        for (let key in val) {
            if (val[key]) {
                if (date[key]) {
                    new_car_data[date[key]] = val[key].format("YYYY-MM-DD")
                }
                new_car_data[key] = val[key]
            }
        }
        let formData = new FormData();
        for (let key in new_car_data) {
            if (key.indexOf('file') > -1) {
                if (new_car_data[key] && new_car_data[key] !== '') {
                    if (new_car_data[key]['file']) {
                        formData.append(key, new_car_data[key]['file']['originFileObj'])
                    }
                }
            }
            if (new_car_data[key]) {
                formData.append(key, new_car_data[key])
            }
        }
        UpdateServicePointCar(formData).then(res => {
            if (res && !res.Error) {
                setModalStatus(null)
                fetchAPI()
            }
        })
        if (check_no) {


        }
    }
    const updateCarStatus = async(val)=>{
        let obj={row_id:carData.row_id,...val}
        if(val.new_car_no){
            obj.driver_row_id = carData.driver_row_id
        }
        let param = ''
        Object.keys(obj).forEach((key,i)=>{
            param+= i ===0? `${key}=${obj[key]}`:`&${key}=${obj[key]}`
        })
        let res = await UpdateCarStatus(param)
        if(res && !res.Error){
            if(res.Result.Success) {
                setChangeCarModal(null)
                setModalStatus(null)
                fetchAPI()
            }
        }
    }
    const editInsuranceForm = async (val) => {
        // if(currentInsuranceTab )
        let res
        let formData = new FormData();
        val.car_row_id = carId
        if (modalStatus === "2b") {
            val.date_start = moment(val["period"][0]).format("YYYY/MM/DD 00:00:00")
            val.date_end = moment(val["period"][1]).format("YYYY/MM/DD 23:59:59")
            val.doc_file = val.doc ? val.doc.file.originFileObj : ''
        } else {
            // console.log(val)
        }

        for (let key in val) {
            if (val[key]) {
                formData.append(key, val[key])
            } else {
                formData.append(key, '')

            }
            if (modalStatus === "2a" && key.indexOf("period") > -1) {
                let name = key.split("_")
                formData.append(`${name[0]}_start`, moment(val[key][0]).format("YYYY-MM-DD 00:00:00"))
                formData.append(`${name[0]}_end`, moment(val[key][1]).format("YYYY-MM-DD 23:59:59"))

            }
            if (key.indexOf("doc_file") > -1 && val[key]) {
                let name = key.split("_")
                if (val[key]["file"]) {
                    formData.append(`${name[0]}_file`, val[key]["file"]["originFileObj"])
                }
            }
        }
        res = modalStatus === "2b" ? await AddServicePointCarInsurance(formData) : UpdateServicePointCarInsurance(formData)
        setInsuranceObj(null)
        if (res && !res.Error) {
            fetchAPI()
            setModalStatus(null)
        }

    }
    const fetchAPI = async () => {
        let kind_list
        if (!carTypeList) {
            let res = await GetCarTypeList()
            if (res && !res.Error) {
                kind_list = res.Result
            }
            setCarTypeList(kind_list)
        } else {
            kind_list = [...carTypeList]
        }
        GetServicePoint(pointId).then(res => {
            if (!res.Error) setInfoData(res.Result)
        })
        GetServicePointCar(carId).then(res => {
            if (!res.Error) {
                let Result = res.Result
                Result.engine_displacement_text = `${Result.engine_displacement} C.C`
                Result.car_load_text = `${Result.car_load} 公噸`
                Result.weight_text = `${Result.weight} 公噸`
                Result.fleet_vehicles_text = Result.fleet_vehicles === "1" ? "是" : "否"
                Result.leave = Result.leave_yymm ? moment(Result.leave_yymm) : ""
                Result.license = { type: 'files', icon: <PaperClipOutlined />, data: [{ fileName: moment(Result.license_date).format("YYYY/MM/DD") }] }
                Result.licenseDate = moment(Result.license_date)
                Result.checkDate = moment(Result.next_check_date)
                Result.licenseExpiryDate = moment(Result.license_expiry_date)
                if (Result.license_file && Result.license_file !== '') {
                    setLicenseFile([{ uid: '1', name: Result.license_file, tatus: 'done' }])
                }
                Result.gas_kind_text =
                    Result.gas_kind === "01" ? '92 無鉛' :
                        Result.gas_kind === "02" ? "95 無鉛" :
                            Result.gas_kind === "03" ? "98 無鉛" : "超級柴油"
                let filter_kind = kind_list.filter(k => k.Code === Result.kind)
                Result.kind_text = filter_kind.length > 0 ? filter_kind[0]["Name"] : ""
                // console.log(Result)
                setCarData(Result)
            }
        })
        GetServicePointCarInsurance(carId).then(res => {
            if (!res.Error) {
                let Result = res.Result
                if (Result.length > 0) {
                    let obj = {}
                    let insurance_type = { "01": "voluntary", "02": "compulsory", "03": "cargo" }
                    let all_insurance = {}
                    let doc_file = {}
                    Result.map(r => {
                        // let arr = ["start", "end"].map(a => r[`date_${a}`] !== null ? r[`date_${a}`].split(" ")[0] : r[`date_${a}`])
                        r.no = r.no === 'undefined' ? '-' : r.no
                        // r.period_status = `${arr[0]} - ${arr[1]}`
                        r.period_status = { type: "status", content: [r.stauts === 1 ? '有效' : '無效', `${moment(r.date_start).format("YYYY.MM.DD")}-${moment(r.date_end).format("YYYY.MM.DD")}`], color: r.stauts === 1 ? '#52C41A' : '#BFBFBF' }
                        r.money_text = `$${r.money}`
                        r.file = r.doc_file ? r.doc_file : '-'
                        r.prevInsurance = '-'
                        obj[r.type] = r
                        for (let key in r) {
                            let name = `${insurance_type[r.type]}_${key}`
                            if (key.indexOf("date") > -1) {
                                all_insurance[`${insurance_type[r.type]}_period`] = r.date_start && r.date_end ? [moment(r.date_start), moment(r.date_end)] : []
                            } else if (key === 'doc_file') {
                                all_insurance[name] = r[key] === null || r[key] === "" ? null :
                                    [
                                        { uid: r.row_id, name: r[key], status: 'done', }
                                    ]
                                doc_file[name] = r[key] === null || r[key] === "" ? null :
                                    [
                                        { uid: r.row_id, name: r[key], status: 'done', }
                                    ]
                            } else {
                                all_insurance[name] = r[key]
                            }
                        }
                    })
                    setInsuranceData(obj)
                    setFileObj(doc_file)
                    setInsuranceObj(all_insurance)
                }
                GetServicePointCarHistory(carId, -1, 0).then(res => {
                    if (res && !res.Error) {
                        setHistory(res.Result.Data.reverse())
                    }
                })
            }
        })
    }
    useEffect(() => {
        fetchAPI()
        GetUnoccupiedCarList(pointId).then(res=>{
            if(res && !res.Error){
                // console.log(res.Result.Data)
                setNotOccupiedCarList(res.Result.Data)
            }
        })
        GetCarBrandList().then(res => {
            if (!res.Error) {
                let Result = res.Result.map(r => {
                    r.value = r.Code
                    r.label = r.Name
                    return r
                })
                setCarBrand(Result)
            }
        })
    }, [])
    return (
        <div className='container' style={ContainerWidth}>
            {
                infoData && carData ? (<>
                    <PageHeader
                        style={{ background: "white", width: "100%" }}
                        title={`${carData.no}/ ${carData.kind_name}`}
                        onBack={() => navigate(`/points/${pointId}`, { state: state })}
                        className="site-page-header"
                        tags={<Tag color={carStatus(carData.status).tagColor}>{carStatus(carData.status).text}</Tag>}
                        extra={[
                            <Button key="更新驗車時間" onClick={() => setModalStatus("3")} icon={<CalendarOutlined />}>更新驗車時間</Button>,
                            <Button key="變更使用狀態" onClick={() => {
                                setModalStatus("4")
                                setTimeout(()=> statusForm.current.setFieldsValue(carData),100)
                            }} icon={<ToolOutlined />}>變更使用狀態</Button>,
                            <Button key="更新行照時間" onClick={() => setModalStatus("5")} icon={<CalendarOutlined />}>更新行照時間</Button>,
                            <Button key="車輛 QR Code 製作" onClick={() => {
                                let file = `${process.env.REACT_APP__API_HOST}/Trailer/${carData.no}/QRCode`
                                fetch(file).then(response => {
                                    // console.log(response)
                                    response.arrayBuffer().then(function (buffer) {
                                        const url = window.URL.createObjectURL(new Blob([buffer]));
                                        const link = document.createElement("a");
                                        link.href = url;
                                        link.setAttribute("download", `${carData.no}_QRCODE.png`);
                                        //or any other extension
                                        document.body.appendChild(link);
                                        link.click();
                                    });
                                })
                            }} icon={<QrcodeOutlined />}>車輛 QR Code 製作</Button>
                        ]}
                        breadcrumb={<BreadCrumb Info={{ [pointId]: infoData.name, [`car${carId}`]: carData.no }} />}>
                    </PageHeader>
                    <div className='split_block' style={{ display: 'grid', gridTemplateColumns: "repeat(2, 1fr)", }}>
                        <div className='content_block' style={{ padding: 24 }}>
                            <Card
                                style={{ width: "100%" }}
                                title="基本資料"
                                extra={
                                    <Button
                                        type="text"
                                        style={{ paddingRight: 0 }}
                                        onClick={() => {
                                            setModalStatus("1")
                                            setTimeout(() => {
                                                editForm.current.setFieldsValue(carData)
                                            }, 200)
                                        }}
                                    > 修改
                                        <EditOutlined style={{ color: "rgba(24, 144, 255, 1)" }} />
                                    </Button>
                                }
                            >
                                <CustomCard header={listHeader} data={carData} />
                            </Card>
                            <Card
                                style={{ width: "100%", marginTop: 24 }}
                                title="保險資料"
                                tabList={[
                                    { key: '01', tab: '任意險' },
                                    { key: '02', tab: '強制險' },
                                    { key: '03', tab: '貨物運送險' }
                                ]}
                                onTabChange={(key) => setCurrentInsuranceTab(key)}
                                extra={
                                    <Dropdown
                                        overlay={(
                                            <Menu style={{ width: 118 }}
                                                onClick={(value, index) => {
                                                    if (value.key === "a") {
                                                        setTimeout(() => editInsurance.current.setFieldsValue(insuranceObj), 100)
                                                    }
                                                    setModalStatus(`2${value.key}`)
                                                }}>
                                                <Menu.Item key="a">
                                                    <div style={{ cursor: "pointer" }}>資料編輯</div>
                                                </Menu.Item>
                                                <Menu.Item key="b">
                                                    <div>更新保單</div>
                                                </Menu.Item>
                                            </Menu>
                                        )}
                                        placement="bottomLeft" arrow >
                                        <Button
                                            type="text"
                                            style={{ paddingRight: 0 }}
                                        > 更多  <MoreOutlined style={{ color: "rgba(24, 144, 255, 1)" }} />
                                        </Button>
                                    </Dropdown>
                                }
                            >
                                <CustomCard header={listHeaderInsurance} data={insuranceData && insuranceData[currentInsuranceTab]} />
                            </Card>

                        </div>
                        <HistoryRecord detail={carData} History={history} />
                    </div>
                </>) : (<div style={{ padding: 24, height: '100%' }}>
                    <LoadingBlock />
                </div>)
            }

            <Modal title="請輸入備註"
                visible={editRemark === 1 ? true : false}
                width={416}
                // onOk={()=> setIsModalVisible("1a")} 
                onCancel={() => setEditRemark(null)}
            >
                <TextArea placeholder="額外的據點內容說明請寫在此......" style={{ width: 368 }} />
            </Modal>
            <Drawer
                title="編輯 - 基本資料"
                placement="right"
                size={"large"}
                closable={false}
                width={993}
                extra={
                    <CloseOutlined style={{ cursor: "pointer", color: "rgba(0, 0, 0, 0.45)" }} onClick={() => onClose()} />
                }
                visible={modalStatus === "1" ? true : false}>
                <Form
                    name="basic"
                    layout="vertical"
                    ref={editForm}
                    onFinish={submitForm}
                    // onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    style={{ width: "100%" }}
                >
                    <div style={{ height: "100%", paddingBottom: 32 }}>
                        <div style={{ display: "flex" }}>
                            <Form.Item
                                label="車牌號碼"
                                name="no"
                                rules={[{ required: true, message: '請輸入車牌號碼!' }]}
                                hasFeedback={isValid !== null ? true : false}
                                validateStatus={isValid === null ? '' : isValid ? 'success' : 'error'}
                                help={isValid !== null && !isValid ? '此車牌號碼已存在!' : ''}
                            >
                                <Input placeholder='example'
                                    style={{ width: "304px" }}

                                    addonAfter={<EnterOutlined style={{ cursor: "pointer" }} onClick={checkCarPlate} />}
                                    onKeyUp={(e) => editForm.current.setFieldsValue({ no: e.target.value.toUpperCase() })}
                                />

                            </Form.Item>
                            <Form.Item
                                label="車種"
                                name="kind"
                                rules={[{ required: true, message: '請輸入車身樣式!' }]}
                                style={{ margin: "0 16px" }}
                            >
                                <Select style={{ width: 304 }}>
                                    {
                                        carTypeList && carTypeList.map(type => (<option value={type.Code} key={`${type.Code}_${type.Name}`}>{type.Name}</option>))
                                    }
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label="板架號碼"
                                name="chassis_no"
                            >
                                <Input placeholder='example' style={{ width: "304px", marginTop: 2 }} />
                            </Form.Item>
                        </div>

                        <div style={{ display: "flex" }}>
                            <Form.Item
                                label="引擎號碼"
                                name="engine_no"
                                rules={[{ required: true, message: '請輸入引擎號碼!' }]}
                            >
                                <Input placeholder='example' style={{ width: "304px" }} />
                            </Form.Item>
                            <Form.Item
                                label="車身號碼"
                                name="body_no"
                                rules={[{ required: true, message: '請輸入車身號碼' }]}
                                style={{ marginLeft: 16 }}
                            >
                                <Input placeholder='example' style={{ width: "304px" }} />

                            </Form.Item>
                        </div>
                        <div style={{ display: "flex" }}>
                            <Form.Item
                                label="掛牌公司"
                                name="company"
                                rules={[{ required: true, message: '請選擇或輸入掛牌公司!' }]}
                            >
                                <Input placeholder='example' style={{ width: "304px" }} />
                            </Form.Item>
                            <Form.Item
                                label="掛牌公司統一編號"
                                name="company_no"
                                rules={[{ required: true, message: '請選擇或輸入掛牌公司統一編號!' }]}
                                style={{ marginLeft: 16 }}
                            >
                                <Input placeholder='example' style={{ width: "304px" }} />
                            </Form.Item>
                        </div>

                        <div style={{ display: "flex" }}>
                            <Form.Item
                                label="廠牌"
                                name="brand"
                                rules={[{ required: true, message: '請輸入廠牌!' }]}
                            >
                                <Cascader
                                    style={{ width: 304 }}
                                    options={CarBrand}
                                    allowClear
                                    // onChange={(val) => onInputChange(val[0],"CarType",record)}
                                    // defaultValue={record.CarType}
                                    placeholder="點擊選擇"
                                    showSearch={(inputValue, path) => {
                                        return path.some(
                                            (option) =>
                                                option.label
                                                    .toLowerCase()
                                                    .indexOf(inputValue.toLowerCase()) > -1
                                        );
                                    }}
                                />
                            </Form.Item>
                            <Form.Item
                                label="車別"
                                name="type"
                                rules={[{ required: true, message: '請選擇常車別！' }]}
                                style={{ marginLeft: 16, marginRight: 16 }}
                            >
                                <Select
                                    allowClear
                                    style={{ width: 304 }}
                                    placeholder="點擊選擇"
                                // onChange={handleChange}
                                >
                                    <option value='營業大貨車'>營業大貨車</option>
                                    <option value='自用大貨車'>自用大貨車</option>
                                    <option value='自用大貨車-特種'>自用大貨車-特種</option>
                                    <option value='曳引車'>曳引車</option>
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label="車色"
                                name="color"
                                rules={[{ required: true, message: '請輸入車色!' }]}
                            >
                                <Input allowClear placeholder='example' style={{ width: "304px" }} />
                            </Form.Item>
                        </div>
                        <div style={{ display: "flex" }}>
                            <Form.Item
                                label="排氣量"
                                name="engine_displacement"
                                rules={[{ required: true, message: '請輸入排氣量!' }]}
                                style={{ position: "relative" }}
                            >
                                <Input addonAfter="C.C" placeholder='example' style={{ width: "304px" }} />
                            </Form.Item>
                            <Form.Item
                                label="載重"
                                name="car_load"
                                rules={[{ required: true, message: '請輸入排氣量!' }]}
                                style={{ position: "relative", margin: "0 16px 24px" }}
                            >
                                <Input addonAfter="公噸" placeholder='example' style={{ width: "304px" }} />
                            </Form.Item>
                            <Form.Item
                                label="總重"
                                name="weight"
                                rules={[{ required: true, message: '請輸入總重!' }]}
                                style={{ position: "relative" }}
                            >
                                <Input addonAfter="公噸" placeholder='example' style={{ width: "304px" }} />
                            </Form.Item>
                        </div>
                        <div style={{ display: "flex" }}>
                            <Form.Item
                                label="出廠年月"
                                name="leave"
                                rules={[{ required: true, message: '請選擇出廠年月!' }]}
                                style={{ position: "relative" }}
                            >
                                <DatePicker style={{ width: "304px" }} />

                            </Form.Item>
                            <Form.Item
                                label="行照發照日"
                                name="licenseDate"
                                rules={[{ required: true, message: '請選擇行照發照日!' }]}
                                style={{ position: "relative", margin: "0 16px 24px" }}
                            >
                                <DatePicker style={{ width: "304px", marginBottom: 24 }} />
                            </Form.Item>
                            <div>
                                <div style={{ height: 34 }}></div>
                                <Form.Item
                                    label=""
                                    name="license_file"
                                >
                                    <Upload
                                        defaultFileList={LicenseFile}
                                        maxCount={1}
                                        customRequest={({ onSuccess }) => onSuccess("ok")}
                                    // onChange={(val, files) => {
                                    //     console.log(val)
                                    // }}
                                    >
                                        <Button icon={<UploadOutlined />}>上傳電子檔</Button>
                                    </Upload>
                                </Form.Item>
                            </div>
                        </div>
                        <div style={{ display: "flex", alignItems: "" }}>
                            <Form.Item
                                label="下次驗車日"
                                name="checkDate"
                                rules={[{ required: true, message: '請選擇下次驗車日!' }]}
                            >
                                <DatePicker style={{ width: "304px" }} />
                            </Form.Item>
                            <Form.Item
                                label="行照有效日期"
                                name="licenseExpiryDate"
                                rules={[{ required: !check, message: '請行照有效日期!' }]}
                                style={{ margin: "0 16px 24px" }}
                            >
                                <DatePicker style={{ width: "304px" }} disabled={check ? "disabled" : ""} />
                            </Form.Item>

                            <div>
                                <div style={{ height: 32 }}></div>
                                <Form.Item
                                    label=""
                                    name="license_no_change"
                                >
                                    <Checkbox onChange={() => setCheck(!check)}>免訂換</Checkbox>
                                </Form.Item>
                            </div>
                        </div>
                        <div style={{ display: "flex" }}>
                            <Form.Item
                                label="車機廠牌"
                                name="machine_brand"
                                rules={[{ required: true, message: '請選擇車機廠牌!' }]}
                                style={{ position: "relative" }}
                            >
                                <Select style={{ width: 304 }} placeholder='點擊選擇' >
                                    <option value="長輝">長輝</option>
                                    <option value="衛星犬">衛星犬</option>
                                    <option value="卡好路">卡好路</option>
                                    <option value="無">無</option>
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label="使用油品"
                                name="gas_kind"
                                rules={[{ required: true, message: '請選擇使用油品!' }]}
                                style={{ position: "relative", marginLeft: 16 }}
                            >
                                <Radio.Group buttonStyle="solid">
                                    <Radio.Button value="04">超級柴油</Radio.Button>
                                    <Radio.Button value="01">92 無鉛</Radio.Button>
                                    <Radio.Button value="02">95 無鉛</Radio.Button>
                                    <Radio.Button value="03">98 無鉛</Radio.Button>
                                </Radio.Group>
                            </Form.Item>
                        </div>
                        <div style={{ display: "flex" }}>
                            <Form.Item
                                label="是否靠行"
                                name="fleet_vehicles"
                                rules={[{ required: true, message: '請選擇是否靠行!' }]}
                                style={{ position: "relative", marginRight: 16 }}
                            >
                                <Radio.Group buttonStyle="solid">
                                    <Radio.Button value="1">靠行</Radio.Button>
                                    <Radio.Button value="0">非靠行</Radio.Button>
                                </Radio.Group>
                            </Form.Item>
                        </div>
                        <Form.Item
                            label="備註"
                            name="note"
                        >
                            <TextArea placeholder="" style={{ width: 304 }} />
                        </Form.Item>

                    </div>
                    <div style={{ width: 993, padding: "10px 6px", display: "flex", justifyContent: "flex-end", position: "fixed", bottom: 0, right: 0, borderTop: "1px solid rgba(240, 240, 240, 1)", background: "white", zIndex: 3 }}>
                        <Button type='text' onClick={() => onClose()}>取消</Button>
                        <Button style={{ marginLeft: 8 }} htmlType="submit">確定</Button>
                    </div>
                </Form>
            </Drawer>

            <Drawer
                title={modalStatus === "2a" ? "編輯 - 保險資料" : "更新保單"}
                placement="right"
                size={"large"}
                closable={false}
                width={1024}
                extra={
                    <CloseOutlined style={{ cursor: "pointer", color: "rgba(0, 0, 0, 0.45)" }} onClick={() => onClose()} />
                }
                visible={modalStatus && modalStatus.indexOf("2") > -1 ? true : false}>
                <Form
                    name="basic"
                    layout="vertical"
                    onFinish={editInsuranceForm}
                    ref={editInsurance}
                    // onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    style={{ width: "100%" }}
                >

                    {
                        modalStatus === "2a" && (
                            <> <div>
                                <div style={{ fontSize: 16, fontWeight: 500 }}>任意險</div>
                                <div style={{ background: "rgba(250, 250, 250, 1)", padding: 16, marginTop: 8 }}>
                                    <div style={{ display: "flex" }}>
                                        <Form.Item
                                            label="保險公司"
                                            name="voluntary_company"
                                            rules={[{ required: true, message: '請輸入保險公司!' }]}
                                        >
                                            <Input placeholder='example' style={{ width: "304px" }} />
                                        </Form.Item>
                                        <Form.Item
                                            label="保單號碼"
                                            name="voluntary_no"
                                            // rules={[{ required: true, message: '請輸入保單號碼!' }]}
                                            style={{ position: "relative", margin: "0 16px 24px" }}
                                        >
                                            <Input placeholder='example' style={{ width: "304px" }} />

                                        </Form.Item>
                                        <div>
                                            <div style={{ height: 32 }}></div>
                                            <Form.Item
                                                label=""
                                                name="voluntary_doc_file"
                                            >

                                                <Upload
                                                    defaultFileList={fileObj["voluntary_doc_file"]}
                                                    maxCount={1}
                                                    customRequest={({ onSuccess }) => onSuccess("ok")}
                                                >
                                                    <Button icon={<UploadOutlined />}>上傳電子檔</Button>
                                                </Upload>
                                            </Form.Item>

                                        </div>

                                    </div>
                                    <div style={{ display: "flex" }}>
                                        <Form.Item
                                            label="保險期間"
                                            name="voluntary_period"
                                            rules={[{ required: true, message: '請選擇保險期間!' }]}
                                        >
                                            <RangePicker style={{ width: "304px" }} />
                                        </Form.Item>
                                        <Form.Item
                                            label="保單金額"
                                            name="voluntary_money"
                                            rules={[{ required: true, message: '請輸入保單金額!' }]}
                                            style={{ position: "relative", marginLeft: 16 }}
                                        >
                                            <Input placeholder='example' style={{ width: "304px" }} />

                                        </Form.Item>
                                    </div>
                                </div>
                            </div>
                                <div style={{ margin: "8px 0" }}>
                                    <div style={{ fontSize: 16, fontWeight: 500 }}>強制險</div>
                                    <div style={{ background: "rgba(250, 250, 250, 1)", padding: 16, marginTop: 8 }}>
                                        <div style={{ display: "flex" }}>
                                            <Form.Item
                                                label="保險公司"
                                                name="compulsory_company"
                                                rules={[{ required: true, message: '請輸入保險公司!' }]}
                                            >
                                                <Input placeholder='example' style={{ width: "304px" }} />
                                            </Form.Item>
                                            <Form.Item
                                                label="保單號碼"
                                                name="compulsory_no"
                                                // rules={[{ required: true, message: '請輸入保單號碼!' }]}
                                                style={{ position: "relative", margin: "0 16px 24px" }}
                                            >
                                                <Input placeholder='example' style={{ width: "304px" }} />

                                            </Form.Item>
                                            <div>
                                                <div style={{ height: 32 }}></div>
                                                <Form.Item
                                                    label=""
                                                    name="compulsory_doc_file"
                                                >
                                                    <Upload
                                                        defaultFileList={fileObj["compulsory_doc_file"]}

                                                        maxCount={1}
                                                        customRequest={({ onSuccess }) => onSuccess("ok")}
                                                    >
                                                        <Button icon={<UploadOutlined />}>上傳電子檔</Button>
                                                    </Upload>
                                                </Form.Item>

                                            </div>
                                        </div>
                                        <div style={{ display: "flex" }}>
                                            <Form.Item
                                                label="保險期間"
                                                name="compulsory_period"
                                                rules={[{ required: true, message: '請選擇保險期間!' }]}
                                            >
                                                <RangePicker style={{ width: "304px" }} />
                                            </Form.Item>
                                            <Form.Item
                                                label="保單金額"
                                                name="compulsory_money"
                                                rules={[{ required: true, message: '請輸入保單金額!' }]}
                                                style={{ position: "relative", marginLeft: 16 }}
                                            >
                                                <Input placeholder='example' style={{ width: "304px" }} />

                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ marginBottom: 72 }}>
                                    <div style={{ fontSize: 16, fontWeight: 500 }}>貨物運送險</div>
                                    <div style={{ background: "rgba(250, 250, 250, 1)", padding: 16, marginTop: 8 }}>
                                        <div style={{ display: "flex" }}>
                                            <Form.Item
                                                label="保險公司"
                                                name="cargo_company"
                                                rules={[{ required: true, message: '請輸入保險公司!' }]}
                                            >
                                                <Input placeholder='example' style={{ width: "304px" }} />
                                            </Form.Item>
                                            <Form.Item
                                                label="保單號碼"
                                                name="cargo_no"
                                                // rules={[{ required: true, message: '請輸入保單號碼!' }]}
                                                style={{ position: "relative", margin: "0 16px 24px" }}
                                            >
                                                <Input placeholder='example' style={{ width: "304px" }} />

                                            </Form.Item>
                                            <div>
                                                <div style={{ height: 32 }}></div>
                                                <Form.Item
                                                    label=""
                                                    name="cargo_doc_file"
                                                >
                                                    <Upload
                                                        defaultFileList={fileObj["cargo_doc_file"]}
                                                        maxCount={1}
                                                        customRequest={({ onSuccess }) => onSuccess("ok")}
                                                    >
                                                        <Button icon={<UploadOutlined />}>上傳電子檔</Button>
                                                    </Upload>
                                                </Form.Item>

                                            </div>
                                        </div>
                                        <div style={{ display: "flex" }}>
                                            <Form.Item
                                                label="保險期間"
                                                name="cargo_period"
                                                rules={[{ required: true, message: '請選擇保險期間!' }]}
                                            >
                                                <RangePicker style={{ width: "304px" }} />
                                            </Form.Item>
                                            <Form.Item
                                                label="保單金額"
                                                name="cargo_money"
                                                rules={[{ required: true, message: '請輸入保單金額!' }]}
                                                style={{ position: "relative", marginLeft: 16 }}
                                            >
                                                <Input placeholder='example' style={{ width: "304px" }} />

                                            </Form.Item>
                                        </div>
                                    </div>
                                </div></>
                        )
                    }
                    {
                        modalStatus === "2b" && (
                            <>
                                <Form.Item
                                    label="保單類型"
                                    name="type"
                                    rules={[{ required: true, message: '請選擇車機廠牌!' }]}
                                >
                                    <Radio.Group buttonStyle="solid">
                                        <Radio.Button value="01">任意險</Radio.Button>
                                        <Radio.Button value="02">強制險</Radio.Button>
                                        <Radio.Button value="03">貨物運送險</Radio.Button>
                                    </Radio.Group>
                                </Form.Item>
                                <div style={{ display: "flex" }}>
                                    <Form.Item
                                        label="保險公司"
                                        name="company"
                                        rules={[{ required: true, message: '請輸入保險公司!' }]}
                                    >
                                        <Input placeholder='example' style={{ width: "304px" }} />
                                    </Form.Item>
                                    <Form.Item
                                        label="保單號碼"
                                        name="no"
                                        // rules={[{ required: true, message: '請輸入保單號碼!' }]}
                                        style={{ position: "relative", margin: "0 16px" }}
                                    >
                                        <Input placeholder='example' style={{ width: "304px" }} />

                                    </Form.Item>
                                    <div>
                                        <div style={{ height: 32 }}></div>
                                        <Form.Item
                                            label=""
                                            name="doc"
                                        >
                                            <Upload
                                                maxCount={1}
                                                customRequest={({ onSuccess }) => onSuccess("ok")}
                                            >
                                                <Button icon={<UploadOutlined />}>上傳電子檔</Button>
                                            </Upload>
                                        </Form.Item>

                                    </div>
                                </div>
                                <div style={{ display: "flex" }}>
                                    <Form.Item
                                        label="保險期間"
                                        name="period"
                                        rules={[{ required: true, message: '請選擇保險期間!' }]}
                                    >
                                        <RangePicker style={{ width: "304px" }} />
                                    </Form.Item>
                                    <Form.Item
                                        label="保單金額"
                                        name="money"
                                        rules={[{ required: true, message: '請輸入保單金額!' }]}
                                        style={{ position: "relative", marginLeft: 16 }}
                                    >
                                        <Input placeholder='example' style={{ width: "304px" }} />

                                    </Form.Item>
                                </div>
                            </>

                        )
                    }

                    <div style={{ width: 1024, padding: "10px 6px", display: "flex", justifyContent: "flex-end", position: "fixed", bottom: 0, right: 0, borderTop: "1px solid rgba(240, 240, 240, 1)", background: "white", zIndex: 3 }}>
                        <Button type='text' onClick={() => onClose()}>取消</Button>
                        <Button style={{ marginLeft: 8 }} htmlType="submit">
                            {modalStatus === "2a" ? '編輯' : '更新'}
                        </Button>
                    </div>
                </Form>
            </Drawer>

            <Modal title="更新驗車時間"
                visible={modalStatus === "3" ? true : false}
                width={416}
                onOk={() => {
                    submitForm({ next_check_date: CheckDate })
                }}
                onCancel={() => setModalStatus(null)}
            >
                <DatePicker onChange={(val) => setCheckDate(val.format("YYYY-MM-DD"))} style={{ width: "368px" }} />
            </Modal>
            <Modal title="調整使用狀況"
                visible={modalStatus === "4" ? true : false}
                width={416}
                onCancel={() => setModalStatus(null)}
                onOk={(val) => {
                    statusForm.current.submit()
                }}
            >
                <Form
                    name="basic"
                    layout="vertical"
                    ref={statusForm}
                    onFinish={(value) => {
                        if(value.status !=="1" && carData.driver_row_id){
                            setChangeCarModal(value.status)
                            setModalStatus(null)
                        }else{
                            updateCarStatus({...value})
                        }
                    }}
                    autoComplete="off"
                    style={{ width: "100%" }}
                >
                    <Form.Item
                        label=""
                        name="status"
                        rules={[{ required: true, message: '請選擇!' }]}
                    >
                        <Radio.Group buttonStyle="solid">
                            <Radio.Button value="1">正常</Radio.Button>
                            <Radio.Button value="0">停派</Radio.Button>
                            <Radio.Button value="2">修繕</Radio.Button>
                            <Radio.Button value="3">撤銷</Radio.Button>
                            <Radio.Button value="4">報廢</Radio.Button>
                        </Radio.Group>
                    </Form.Item>
                </Form>
            </Modal>

        
            <Modal title="請設定新的常用車"
                visible={changeCarModal}
                width={416}
                onCancel={() => setChangeCarModal(null)}
                onOk={(val) => {
                    statusForm.current.submit()
                }}
            >
                <Form
                    name="basic"
                    layout="vertical"
                    ref={statusForm}
                    onFinish={(value) => {
                        updateCarStatus({status:changeCarModal,...value})
                    }}
                    autoComplete="off"
                    style={{ width: "100%" }}
                >
                <p>請替 <span style={{color:"rgba(24, 144, 255, 1)"}}>{carData?carData.driver_name:''}</span> 設定一台新的常用車輛</p>
                    <Form.Item
                        label="車輛"
                        name="new_car_no"
                        rules={[{ required: true, message: '請選擇!' }]}
                        style={{ marginTop: 16 }}
                    >
                        <Select placeholder='請選擇'>
                            {
                                notOccupiedCarList.map(type => (<option value={type.no} key={type.row_id}>{type.no}</option>))
                            }
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
            <Modal title="更新行照時間"
                visible={modalStatus === "5" ? true : false}
                width={416}
                onOk={() => {
                    submitForm({ license_date: DrivingLicenseDate })
                }}
                onCancel={() => setModalStatus(null)}
            >
                <DatePicker onChange={(val) => setDrivingLicenseDate(val.format("YYYY-MM-DD"))} style={{ width: "368px" }} />
            </Modal>
        </div>
    );
}

export default Cardetail;

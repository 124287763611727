// 
export const county = (state = null , action) => {
    switch(action.type) {
        case 'FETCH_COUNTY_TOWN_SUCCESS':
            return action.data
        default:
            return state
    }
}
export const CountyCode = (state = null , action) => {
    switch(action.type) {
        case 'FETCH_COUNTY_TOWN':
            return action.data
        default:
            return state
    }
}
export const caseType = (state = null , action) => {
    switch(action.type) {
        case 'FETCH_CASE_TYPE':
            return action.data
        default:
            return state
    }
}

export const CustomerList = (state = null , action) => {
    switch(action.type) {
        case 'FETCH_CUSTOMER_LIST':
            return action.data
        default:
            return state
    }
}

export const CommonStationType = (state = null , action) => {
    switch(action.type) {
        case 'FETCH_COMMON_STATION_TYPE':
            return action.data
        default:
            return state
    }
}

let priceModules={
    "001":"起迄點", "002":"里程","003":"區域階梯式", "004":"包月", "005":"按件計酬"
}
export const PriceModule = (state = priceModules , action) => {
    switch(action.type) {
        case 'FETCH_PRICE_MODULES':
            return action.data
        default:
            return state
    }
}
export const ExtraPriceItem = (state = null , action) => {
    switch(action.type) {
        case 'FETCH_EXTRA_PRICE_ITEM':
            return action.data
        default:
            return state
    }
}


export const CarType = (state = null , action) => {
    switch(action.type) {
        case 'FETCH_CAR_TYPE':
            return action.data
        default:
            return state
    }
}
export const TrailerType = (state = null , action) => {
    switch(action.type) {
        case 'FETCH_TRAILER_TYPE':
            return action.data
        default:
            return state
    }
}

export const UserInfo = (state = null , action) => {
    switch(action.type) {
        case 'FETCH_USER_INFO':
            return action.data
        default:
            return state
    }
}


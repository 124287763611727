import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import BreadCrumb from '../../components/shared/BreadCrumb'
import BasicInfo from '../../components/points/point/basicInfo'
import CustomCalendar from '../../components/points/point/CustomCalendar'
import { GetServicePoint, GetServicePointCarStatistics, GetServicePointHistory, GetServicePointDriverList, GetServicePointCarList, UpdateServicePointStatus,UpdateDriverAttend } from '../../api/ServicePoint'
import { GetUnoccupiedCarList } from '../../api/Code'
// import { FilterConty } from '../../components/shared/util/FilterFunc'
import LoadingBlock from '../../components/shared/LoadingBlock'
import HistoryRecord from '../../components/shared/component/HistoryRecord'
import { RouteName } from '../../util/route'
import { PageHeader, Button, Tag, Tabs, Table, Select, DatePicker, Popconfirm, Modal, Form, Radio } from 'antd'
import { PauseCircleOutlined, FileTextOutlined, UserOutlined, CalendarOutlined, CarOutlined, UserAddOutlined, PlusOutlined, PaperClipOutlined, PhoneOutlined, QrcodeOutlined } from '@ant-design/icons'
import TextArea from 'antd/lib/input/TextArea'
import moment from 'moment'
import { useSelector } from 'react-redux'
const { RangePicker } = DatePicker;
const { TabPane } = Tabs
const { Option } = Select
function Status(val) {
    switch (val) {
        case '1':
            return ({ val: '正常', color: "#52C41A" })
        case '0':
            return ({ val: '停派', color: "#FF4D4F" })
        case '2':
            return ({ val: '修繕', color: "#FAAD14" })
        case '3':
            return ({ val: '撤銷', color: "#D9D9D9" })
        case '4':
            return ({ val: '報廢', color: "#D9D9D9" })
        default:
            return ({ val: '正常', color: "#52C41A" })
    }
}
const Point = ({ ContainerWidth }) => {
    let navigate = useNavigate()
    let { state,pathname } = useLocation()
    let { pointId } = useParams()
    let County = useSelector(state => state.county)
    const routeId = RouteName(pathname)
    let UserInfo = useSelector(state => state.UserInfo) 
    let UserPermission = UserInfo? UserInfo.permission.route: null
    const [currentTab, setCurrentTab] = useState(state && state.tab ? state.tab : "1")
    //派遣狀態 1. 可派遣; 2. 請假 ; 3. 停派
    const [pointStatus, setPointStatus] = useState(1)
    const [modalVal, setModalVal] = useState(null)
    const [fetchDetail, setFetchDetail] = useState(null)
    const [extraData, setExtraData] = useState(null)
    const formRef = React.createRef()
    const [history, setHistory] = useState(null)
    const [pagination, setPagination] = useState(state && state.pagination ? state.pagination : { current: 1, pageSize: 10 })
    const [driverList, setDriverList] = useState([])
    const [carList, setCarList] = useState([])
    const [selectedDriver, setSelectedDriver] = useState(null)
    useEffect(() => {
        if (modalVal !== null) {
            formRef.current.setFieldsValue({
                technicianName: modalVal.id
            })
        } else {
            if (formRef.current !== null) {
                formRef.current.resetFields();
            }
        }
    }, [modalVal]);
    useEffect(()=>{
        if(selectedDriver){
            // console.log(selectedDriver)
            formRef.current.setFieldsValue(selectedDriver)
        }
    },[selectedDriver])
    //彈跳視窗設定
    //1)派遣狀態 : 1a. 請假 ; 1b. 停派
    const [isModalVisible, setIsModalVisible] = useState(null)
    const dispatchStatus = async (val) => {
        let obj = {}
        if (val) {
            let totalLength = Object.keys(val).length
            let length = 0
            Object.keys(val).map(v => {
                if (!val[v]) length += 1
            })
            //若 totalLength === length， 沒有填寫任何資料
            if (length !== totalLength) {
                for (let key in val) {
                    if (val[key]) {
                        if (key === 'period') {
                            obj.start_date = val[key][0].format("YYYY-MM-DD")
                            obj.end_date = val[key][1].format("YYYY-MM-DD")
                        } else {
                            obj[key] = val[key]
                        }
                    }
                }
            }
        }
    
        if(isModalVisible!=="1c"){
            obj.row_id = pointId
            obj.status = !isModalVisible ? "1" : isModalVisible === "1a" ? "2" : "0"
        }
        let param = ''
            Object.keys(obj).map((o, index) => {
                param += index === 0 ? `${o}=${obj[o]}` : `&${o}=${obj[o]}`
            })
        let res
        if(isModalVisible === "1c"){
            res = await UpdateDriverAttend(param)
        }else{
            res = await UpdateServicePointStatus(param)
        }
        if (res && !res.Error) {
            fetchAPI()
            setIsModalVisible(null)
        }
       
    }
    const fetchAPI = async () => {
        let county = County[0]["children"]
        let res = await GetServicePoint(pointId)
        if (!res.Error) {
            let detail = res.Result
            detail.industry = detail.industry.toString()
            let CarStatistics = await GetServicePointCarStatistics(pointId)
            if (!CarStatistics.Error) {
                let total = 0
                CarStatistics.Result.Data.forEach(c => total += parseInt(c.value))
                detail.CarStatistics = CarStatistics.Result.Data.map(c => {
                    c.percentage = (parseInt(c.value) / total) * 100
                    return c
                })
            }
            let status = moment().isBetween(moment(detail.contract_period_start).format("YYYY/MM/DD"), moment(detail.contract_period_end).format("YYYY/MM/DD"))
            detail.contract = detail.contract_file ? {
                type: "files", icon: <PaperClipOutlined />,
                data: [{ id: 1, fileName: detail.contact_name, path: "" }],
            } : detail.contract_name
            detail.contract_period = { type: "status", color: status ? "rgba(82, 196, 26, 1)" : "rgba(255, 77, 79, 1)", status: 1, content: [status ? "有效" : "過期", `${moment(detail.contract_period_start).format("YYYY/MM/DD")}~${moment(detail.contract_period_end).format("YYYY/MM/DD")}`] }
            detail.contractPeriod = [moment(detail.contract_period_start), moment(detail.contract_period_end)]
            detail.contact = { type: "icons", link: "tel", label: detail.tel, title: detail.contact_name, icon: <PhoneOutlined /> }
            detail.remark = { type: "remark", content: detail.note }
            let industry = detail.industry
            detail.industry_text = industry === "1" ? "物流倉儲業" : industry === "2" ? "大眾運輸業" : industry === "3" ? "道路救援業" : "汽機車買賣業"
            detail.category_text = detail.category === "1" ? "正式" : "支援"
            detail.service_type = detail.service_type ? detail.service_type : "-"
            let split_arr = ["responsible_area1", "responsible_area2", "social_cycle"]
            split_arr.forEach(a => {
                let key = `new_${a}`
                let key_ = `_${a}`
                let arr = []
                if (detail[a]) {
                    if (detail[a].indexOf(",") > -1) {
                        let text = ''
                        arr = detail[a]
                            .split(",")
                            .map((a, i) => {
                                let county_code = a.length > 5 ? a.slice(0, 5) : a
                                let town_code = a.length > 5 &&a.slice(-3)!=='000' ? a : ''
                                text += i === 0 ? filterCounty(county, county_code, town_code) : `、${filterCounty(county, county_code, town_code)}`
                                let array = town_code === "" ? [county_code] : [county_code, town_code]
                                return array
                            })
                        detail[key] = text
                    } else {
                        let county_code = detail[a].length > 5 ? detail[a].slice(0, 5) : detail[a]
                        let town_code = detail[a].length > 5 ? detail[a] : ''
                        detail[key] = filterCounty(county, county_code, town_code)
                        arr = [town_code === "" ? [county_code] : [county_code, town_code]]
                    }
                } else {
                    detail[key] = ""
                }
                
                detail[key_] = arr
            })
            let upload = []
            if (detail.contract_file && detail.contract_file !== "") {
                upload = [{
                    uid: '1',
                    name: detail.contract_file,
                    status: 'done',
                }]
            }
            detail.county_town = [detail.county_code, detail.town_code]
            let county_arr = county.filter(c=>c.value ===detail.county_code)
            let full_add = ''
            if(county_arr.length>0){
                full_add += county_arr[0]["label"]
                let town_arr=county_arr[0]["children"].filter(c=>c.value ===detail.town_code)
                if(town_arr.length > 0) full_add += town_arr[0]["label"]
            }
            detail.ful_add = `${full_add}${detail.address}`
            detail.upload = upload
            let service_type = detail.service_type
            let service_type_text = ""
            let service_type_arr = service_type.indexOf(",")>-1? service_type.split(","):[service_type]
            service_type_arr.forEach((t,i)=>{
                let service = t === "1" ? "運送車" : "道路救援"
                service_type_text +=  i===0? `${service}`:`、${service}`
            })
            detail.Service = service_type_arr.map(d=> d === "1" ? "運送車" : "道路救援" )
            detail.service_text = service_type_text
            setFetchDetail(detail)

        }
        let history = await GetServicePointHistory(pointId, -1, 0)
        if (history && !history.Error) {
            setHistory(history.Result.Data.reverse())

        }
        if (currentTab) {

        }
    }
    const filterCounty = (county, county_code, town_code) => {
        let text = ''
        let county_filter = county.filter(c => c.value === county_code)
        if (county_filter.length > 0) {
            text = county_filter[0]["label"]
            if (town_code !== "") {
                let town_filter = county_filter[0]["children"].filter(c => c.value === town_code)
                if (town_filter.length > 0) text += town_filter[0]["label"]

            }
        }
        return text
    }
    const ExtraFetch = async () => {
        setExtraData(null)
        let res
        if (currentTab === "2") {
            res = await GetServicePointDriverList(-1, 0, pointId)
        } else if (currentTab === '3') {
            // res = await GetAttendanceList(-1, 0, pointId)
        }
        else if (currentTab === "4") {
            res = await GetServicePointCarList(-1, 0, pointId)
        }
        if (res) {
            if (!res.Error) {
                let result = res.Result
                let Data = result.Data
                let day = moment("2022-12-19").day().toString()
                let obj = { 1: "mon", 2: "tue", 3: "wed", 4: "thu", 5: "fri", 6: "sat", 0: "sun" }
                let key = `${day}_no_work`
                if (currentTab === "2") {
                    console.log(Data)
                   
                    Data.map(d => {
                        let status =  moment().isBetween(moment(d.take_office_date).format("YYYY/MM/DD"), moment(d.leave_office_date).format("YYYY/MM/DD"))
                        let after_take_office = moment(moment().format("YYYY-MM-DD")).isAfter(moment(d.take_office_date).format("YYYY/MM/DD")); // true
                        d.workStatus = !after_take_office?-1:status?1:0
                        return d
                    })
                }
                setExtraData(Data)
            }
        }
    }
    useEffect(() => {
        if (County && !fetchDetail) {
            fetchAPI()
        }
        GetServicePointDriverList(-1, 0, pointId).then(res => {
            if (res && !res.Error) {
                let Data = res.Result.Data.filter(d=>d.working_type==="1")
                setDriverList(Data)
            }
        })
        
        // GetServicePointCarList(-1, 0, pointId).then(res => {
        //     console.log(res.Result)
        //     if (res && !res.Error) {
        //         setCarList(res.Result.Data)
        //     }
        // })
        setPagination({ current: 1, pageSize: 10 })
        ExtraFetch()
    }, [currentTab, County])

    const pageHeaderButton = UserPermission&&UserPermission[routeId]!==2 ? []:currentTab === "1" && pointStatus === 1 ?
        [<Popconfirm placement="bottom" title={fetchDetail && fetchDetail.status === "1" ? "停派或請假後該據點將無法被派遣。" : "啟用據點後就可以再次派遣"}
            onCancel={() => setIsModalVisible("1a")}
            onConfirm={() => {
                if (fetchDetail && fetchDetail.status === "1") {
                    setIsModalVisible("1b")
                } else {
                    dispatchStatus()
                }
            }}
            style={{ marginRight: 12 }}
            showCancel={fetchDetail && fetchDetail.status === "1" ? true : false}
            okText={fetchDetail && fetchDetail.status === "1" ? "停派" : "啟用"}
            cancelText="請假"
            okButtonProps={{ style: { background: "white", color: "rgba(0, 0, 0, 0.85)", border: "1px solid rgba(217, 217, 217, 1)" } }}
        >
            <Button key={currentTab} icon={<PauseCircleOutlined />}>派遣狀態設定</Button>
        </Popconfirm>] :
        (currentTab === "1" && pointStatus === 2) || (currentTab === "1" && pointStatus === 3) ?
            [<Popconfirm placement="bottom" title={"啟用後該據點將從回派遣列表。"}
                style={{ marginRight: 12 }}
                okText="啟用"
                cancelButtonProps={{ style: { display: "none" } }}
            >
                <Button key={currentTab} icon={<PauseCircleOutlined />}>派遣狀態設定</Button>
            </Popconfirm>] :
            currentTab === "2" ? [
                <Button type="primary" key={currentTab} icon={<UserAddOutlined />} onClick={() => navigate("add_technician",{state:{pagination,point:fetchDetail.name,tab:currentTab}})}>新增技師</Button>
            ] :
                currentTab === "3" ? [
                    <Button onClick={() => {
                        setModalVal(null)
                        setIsModalVisible("1c")
                    }} key={currentTab} >出勤設定</Button>
                ] :
                    [<Button
                        icon={<QrcodeOutlined />}
                        onClick={() => {
                            let url = `${process.env.REACT_APP__API_HOST}/ServicePoint/Car/GetQRCode?service_point_row_id=${pointId}`
                            const link = document.createElement("a");
                            link.href = url;
                            link.setAttribute("download", `${fetchDetail?`${fetchDetail.name}_`:''}車輛 QR Code`);
                            //or any other extension
                            document.body.appendChild(link);
                            link.click();
                        }}
                    >
                        車輛 QR Code 製作
                    </Button>, <Button type="primary" key={currentTab} icon={<PlusOutlined />} onClick={() => navigate("add_car",{state:{pagination,point:fetchDetail.name,tab:currentTab}})}>新增車籍</Button>]

  
    const TechinicianColumn = [
        { title: '姓名', dataIndex: 'name' },
        { title: '身分證字號', dataIndex: 'id_no' },
        { title: '生日', dataIndex: 'birthday', render: (date) => (<p>{moment(date).format("YYYY-MM-DD")}</p>) },
        {
            title: '任職狀態', dataIndex: 'workStatus',
            render: (record) => {
                return (<div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ width: 6, height: 6, borderRadius: "50%", background: record === -1?"#FA8C16":record === 0 ? "rgba(217,217,217,1)" : "rgba(82, 196, 26, 1)", marginRight: 8 }}></div>
                    <p>{record === -1?"尚未到職":record === 0 ? "離職" : "在職"}</p>
                </div>)
            }
        },
        { title: '駕照效期', dataIndex: 'driving_license_expiration_date', render: (date) => (<p>{moment(date).format("YYYY-MM-DD")}</p>) },
        { title: '手機號碼', dataIndex: 'mobile_phone_number' }
    ]
    const CarColumn = [
        { title: '車牌號碼', dataIndex: 'no' },
        { title: '掛牌公司', dataIndex: 'company' },
        { title: '廠牌', dataIndex: 'brand_name' },
        { title: '車別', dataIndex: 'type_name' },
        { title: '車身樣式', dataIndex: 'kind_name' },
        {
            title: '使用狀況', dataIndex: 'status',
            render: (record) => {
                let status = Status(record)
                return (<div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ width: 6, height: 6, borderRadius: "50%", background: status.color, marginRight: 8 }}></div>
                    <p>{status.val}</p>
                </div>)
            }
        },
        {
            title: '下次驗車日', dataIndex: 'next_check_date',
            render: (record) => {
                let year = parseInt(moment(record).format("YYYY")) - 1911
                return (`${year}/${moment(record).format("MM/DD")}`)
            }
        }]
    return (
        <div className='container' style={ContainerWidth}>
            {
                fetchDetail ? (<>
                    <PageHeader
                        style={{ background: "white", width: "100%" }}
                        title={fetchDetail.name}
                        onBack={() => navigate('/points', { state: state })}
                        className="site-page-header"
                        tags={<Tag color={fetchDetail.status === "1" ? "green" : "red"}>{fetchDetail.status === "1" ? "可派遣" : "不可派遣"}</Tag>}
                        extra={pageHeaderButton}
                        breadcrumb={<BreadCrumb Info={{ [fetchDetail.row_id]: fetchDetail.name }} />}
                        footer={
                            <Tabs defaultActiveKey={currentTab} onTabClick={(value) => setCurrentTab(value)} >
                                <TabPane tab={<><FileTextOutlined /><span>基本資訊</span></>} key="1" />
                                <TabPane tab={<><UserOutlined /><span>技師資訊</span></>} key="2" />
                                <TabPane tab={<><CalendarOutlined /><span>出勤管理</span></>} key="3" />
                                <TabPane tab={<><CarOutlined /><span>車籍資料</span></>} key="4" />
                            </Tabs>
                        }
                    >
                    </PageHeader>


                    {
                        fetchDetail && currentTab === "1" && (
                            <div className='split_block' style={{ display: 'grid', gridTemplateColumns: "repeat(2, 1fr)", }}>
                                <div className='content_block' style={{ padding: 24 }}>
                                    <BasicInfo
                                        permission={UserPermission[routeId]}
                                        fetchDetail={fetchDetail}
                                        onSubmit={(val) => {
                                            if (val) fetchAPI()
                                        }}
                                    />
                                </div>
                                <HistoryRecord detail={fetchDetail} History={history} />

                            </div>)
                    }

                    {
                        currentTab === "2" && (
                            <div className='table_block'>
                                <Table
                                    columns={TechinicianColumn}
                                    rowKey={record => record.row_id}
                                    dataSource={extraData}
                                    pagination={extraData && extraData.length > 10 ? {
                                        current: pagination.current,
                                        total: extraData.length,
                                        showTotal: (total, range) =>
                                            `${range[0]}-${range[1]} 列（共${total}  列）`,
                                    } : false}
                                    onChange={(pagination) => setPagination(pagination)}
                                    style={{ padding: "12px 0", minHeight: 400 }}
                                    onRow={(record, rowIndex) => {
                                        return {
                                            onClick: event => { 
                                                navigate(`${record.row_id}`, { state: { point: fetchDetail.name, tab: currentTab, pagination } })
                                             },
                                        };
                                    }}

                                />
                            </div>
                        )
                    }

                    {
                        currentTab === "3" && (
                            <div style={{ margin: 24, background: "white" }}>
                                <CustomCalendar
                                    permission={UserPermission[routeId]}
                                    pointData={fetchDetail}
                                    data={extraData}
                                   
                                    changeWorkDate={(val) => {
                                        setIsModalVisible("1c")
                                        setTimeout(()=>setSelectedDriver(val),100)                      
                                    }}
                                    setLeave={(leave) => {
                                        setModalVal(leave);
                                        setIsModalVisible("1c")
                                    }} />
                            </div>
                        )
                    }
                    {
                        currentTab === "4" && extraData && (
                            <div className='table_block'>
                                <Table
                                    columns={CarColumn}
                                    rowKey={record => record.row_id}
                                    dataSource={extraData}
                                    pagination={extraData && extraData.length > 10 ? {
                                        current: pagination.current,
                                        total: extraData.length,
                                        showTotal: (total, range) =>
                                            `${range[0]}-${range[1]} 列（共${total}  列）`,
                                    } : false}
                                    onChange={(pagination) => setPagination(pagination)}
                                    // loading={loading}
                                    style={{ padding: "12px 0", minHeight: 400 }}
                                    onRow={(record, rowIndex) => {
                                        return {
                                            onClick: event => { navigate(`/points/${pointId}/car${record.row_id}`, { state: { tab: currentTab,pagination } }) },
                                        };
                                    }}

                                />
                            </div>
                        )
                    }



                    <Modal title={isModalVisible === "1a" ? "請輸入請假時間及原因" : isModalVisible === "1b" ? "請輸入停派原因" : "請輸入請假資訊"}
                        visible={isModalVisible}
                        width={416}
                        onOk={() => formRef.current.submit()}
                        onCancel={() => {
                            formRef.current.resetFields()
                            setIsModalVisible(null)
                        }}
                    >
                        <Form
                            ref={formRef}
                            name="basic"
                            layout="vertical"
                            onFinish={dispatchStatus}
                            autoComplete="off"
                            initialValues={{
                                attend_type :"1"
                            }}
                        >
                            {
                                isModalVisible === "1a" && (
                                    <Form.Item
                                        label=""
                                        name="period"
                                    >
                                        <RangePicker style={{ width: 368 }} />
                                    </Form.Item>
                                )
                            }
                            {
                                (isModalVisible === "1a" || isModalVisible === "1b") && (
                                    <Form.Item
                                        label=""
                                        name="reason"
                                    >
                                        <TextArea placeholder="請輸入請假原因" style={{ width: 368 }} />
                                    </Form.Item>

                                )
                            }

                            {
                                isModalVisible === "1c" && (
                                    <>
                                        <Form.Item
                                            label="姓名"
                                            name="driver_row_id"
                                            rules={[{ required: true, message: '請選擇技師!' }]}
                                        >
                                            <Select placeholder="請選擇技師"
                                            onChange={(e)=>{
                                                let filter = driverList.filter(d=>d.row_id===e)
                                                setSelectedDriver(filter[0])
                                                GetUnoccupiedCarList(pointId).then(res=>{
                                                    let data=res.Result.Data
                                                    if(filter[0]['plate_number']){
                                                    data.push({no:filter[0]['plate_number']})

                                                    }
                                                    setCarList(data)
                                                })
                                            }}
                                            >
                                                {
                                                    driverList.map(t => {
                                                        return (
                                                            <Option value={t.row_id}>{t.name}</Option>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        </Form.Item>

                                        <Form.Item
                                            label="日期"
                                            name="period"
                                            rules={[{ required: true, message: '請選擇日期!' }]}
                                        >
                                            <RangePicker 
                                            disabledDate={(current)=>{
                                                let customDate = moment().format("YYYY-MM-DD");
                                                return current && current < moment(customDate, "YYYY-MM-DD");
                                            }}
                                            style={{ width: "100%" }} />
                                        </Form.Item>
                                        <Form.Item
                                            label="駕駛車輛"
                                            name="plate_number"
                                            rules={[{ required: true, message: '請選擇車輛!' }]}
                                        >
                                            <Select 
                                            placeholder="請選擇車輛" 
                                            >
                                                {
                                                    carList.map(t => {
                                                        return (
                                                            <Option value={t.no}>{t.no}</Option>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        </Form.Item>
                                        <Form.Item
                                            label="上班"
                                            name="attend_type"
                                            rules={[{ required: true, message: '請選擇請假時間!' }]}
                                        >
                                            <Radio.Group defaultValue="" buttonStyle="solid">
                                                <Radio.Button value="1">上班</Radio.Button>
                                                <Radio.Button value="0">休息</Radio.Button>
                                            </Radio.Group>
                                        </Form.Item>
                                    </>
                                )
                            }
                        </Form>
                    </Modal>
                </>) : (<div style={{ padding: 24, height: '100%' }}>
                    <LoadingBlock />
                </div>)
            }

        </div>

    );
}

export default Point;

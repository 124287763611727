import React, { useState, useEffect } from 'react'
import BreadCrumb from '../../../components/shared/BreadCrumb'
import { PageHeader, Button, Table } from 'antd'
import { FilterOutlined, PlusOutlined } from '@ant-design/icons'
import { useNavigate,useLocation} from 'react-router-dom'
import { GetCommonPointList } from '../../../api/Customer'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { RouteName } from '../../../util/route'


const ServicePoints = ({ ContainerWidth }) => {
  let navigate = useNavigate()
  const [data, setData] = useState(null)
  const { state,pathname } = useLocation()
  const routeId = RouteName(pathname)
  let UserInfo = useSelector(state => state.UserInfo) 
  let UserPermission = UserInfo? UserInfo.permission.route: null
  let CommonStationType = useSelector(state => state.CommonStationType)
  const columns = [
    { title: '代號', dataIndex: 'id',  width: '100px'},
    { title: '名稱', dataIndex: 'name' },
    { title: '地址', dataIndex: 'address'},
    { title: '運送點類型', dataIndex: 'station_type_text', },
    { title: '配合狀態', dataIndex: 'status', width: '10%',
      render: status => status > 0 ? (<div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ width: 6, height: 6, borderRadius: "50%", background: "rgba(82, 196, 26, 1)", marginRight: 8 }}></div>
        <p>配合中</p>
      </div>) : (<div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ width: 6, height: 6, borderRadius: "50%", background: "rgba(217, 217, 217, 1)", marginRight: 8 }}></div>
        <p>取消</p>
      </div>)
    },
    { title: '營業狀態', dataIndex: 'status', width: '10%',
    render: status => status === 0 ?"-": status === 1 ?(<div style={{ display: "flex", alignItems: "center" }}>
      <div style={{ width: 6, height: 6, borderRadius: "50%", background: "rgba(82, 196, 26, 1)", marginRight: 8 }}></div>
      <p>營業中</p>
    </div>) : (<div style={{ display: "flex", alignItems: "center" }}>
      <div style={{ width: 6, height: 6, borderRadius: "50%", background: "rgba(217, 217, 217, 1)", marginRight: 8 }}></div>
      <p>{status === 2 ? "公休":"已打烊"}</p>
    </div>)
  }]
  useEffect(() => {
    GetCommonPointList().then(result => {
      if (!result.Error) {
        let Result = result.Result.Data
        Result = Result.map((r, i) => {
          if (CommonStationType) {
            r.station_type_text = '-'
            let filter = CommonStationType.filter(c => c.Code === r.station_type_code)
            if (filter.length > 0) {
              r.station_type_text = filter[0]["Name"]
            }
          }
          return r
        })
        setData(Result)
      }
    })
  }, [CommonStationType])
  return (
    <div className='container' style={ContainerWidth}>
      <PageHeader
        style={{ background: "white", width: "100%" }}
        title="共用運送點"
        className="site-page-header"
        extra={UserPermission && UserPermission[routeId] ===2?[
          <Button
            key="新增共用運送點"
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => navigate('/customer/servicePoints/add')}
          >
            新增共用運送點
          </Button>,
        ]:[]}
        breadcrumb={<BreadCrumb />}
      >
      </PageHeader>
      <div className='table_block'>
        {
          data && (<Table
            columns={columns}
            rowKey={record => record.id}
            dataSource={data}
            pagination={data.length > 10 ? {
                        total: data.length,
                        showTotal: (total, range) =>
                            `${range[0]}-${range[1]} 列（共${total}  列）`,
                    } : false}
            // loading={loading}
            style={{ padding: "12px 0", minHeight: 400 }}
            onRow={(record, rowIndex) => {
              return {
                onClick: event => { navigate(`/customer/servicePoints/${record.row_id}`) },
              };
            }}
          />)
        }
      </div>
    </div>
  )
}


export default ServicePoints;
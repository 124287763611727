import service from './Setting'
export const GetGasStations = () => service.get('/Gas/Station');
export const GetGasRecords = (param) => service.get(`/Gas/History?${param}`);


//取得最新油價
export const GetLatestGasPrice = () => service.get('/GasPrice/LatestPrice');
export const GetGasPriceHistory = (week_count) => service.get(`/GasPrice/PriceHistory?week_count=${week_count} `);






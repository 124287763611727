import service from './Setting'

export const GetAllCases = (param) => service.get(`/Case/List?${param}`);
//取得指定案件明細
export const GetCaseInfo = (caseId,vehicleSn) => service.get(`/Case/${caseId}/${vehicleSn}`);
//取得指定案件歷程
export const GetCaseHistory = (rowId) => service.get(`/Case/History/${rowId}`);
export const GetCheckCaseInfo = (caseId) => service.get(`/Case/${caseId}`);
export const GetCustomerCaseType = (id) => service.get(`/Customer/CaseTypeList?customer_row_id=${id}`);
//取得上單客戶清單
export const GetOrderCustomerList = (id) => service.get(`/Customer/OrderCustomerList?customer_row_id=${id}`);
export const GetCustomerStation = (id) => service.get(`/Customer/StationList?customer_row_id=${id}`);
export const PostAddCase = (formData) => service.post(`/Case`,formData );
export const PutDraftCase = (id,vehicle_sn,formData) => service.put(`/Case/${id}/${vehicle_sn}/Draft`,formData );
export const PutAddCase = (id,formData) => service.put(`/Case/${id}/Review`,formData );
export const PutEditCase = (caseId,vehicleSn,formData) => service.put(`/Case/${caseId}/${vehicleSn}`,formData );
export const CancelAllCases = (caseId) => service.post(`/Case/${caseId}/Cancel` );
export const CancelCases = (caseId,vehicleSn) => service.post(`/Case/${caseId}/${vehicleSn}/Cancel` );

//取得案件計價模組 row_id
export const CasePriceId = (customer_row_id,case_type_id,param) => service.get(`/Price/${customer_row_id}/${case_type_id}?${param}` );
//編輯客服備註
export const CustomerServiceNote= (obj) => service.post(`/Case/CustomerServiceNote`,obj );
//結案計價模組費用清單
export const CloseCasePriceList = (caseId,vehicle_sn,param) => service.get(`/Case/${caseId}/${vehicle_sn}/Price/FeeList?${param}` );
//案計價模組費用清單：回傳結案項目row id
export const CloseCasePriceLists = (case_info_row_id) => service.get(`/Case/CaseBindFee/${case_info_row_id}` );
//結案
export const CloseCase = (caseId,vehicleSn,obj) => service.post(`/Case/${caseId}/${vehicleSn}/Close`,obj );
//結案後修改
export const CloseModifyFee = (obj) => service.post(`/Case/CloseModifyFee`,obj);
//紙本結案
export const ManualCloseCase = (caseId,vehicleSn,obj) => service.post(`/Case/${caseId}/${vehicleSn}/CloseOnPaper`,obj );
//客訴註記
export const complaintCase = (caseId,vehicleSn,obj) => service.post(`/Case/${caseId}/${vehicleSn}/Complain`,obj );
//取消案件再次派遣
//編輯客服備註
export const dispatchCancelCase= (obj) => service.post(`/Case/CancelCaseTurnBack`,obj );
//確認派遣
export const ConfirmDispatch = (id,obj) => service.post(`/Execution/${id}/Assign`,obj );

//取得所有待媒合案件清單
export const GetUnmatchedCases = (page_index,page_size) => service.get(`/Case/WaitMatchList?page_index=${page_index}&page_size=${page_size}`, );

//取得案件可派遣載車資訊
export const GetTrailerType = (obj) => service.post(`/Case/Trailer`,obj);

//取得待媒合技師名單（正式）
export const GetOfficialDriverList = (param) => service.get(`/Case/MatchOfficialDriverList?${param}`);
//取得待媒合技師名單（支援）
export const GetNonOfficialDriverList = (param) => service.get(`/Case/MatchSupportDriverList?${param}`, );

//取得待媒合技師名單（支援）
export const GetDriverExecutionList = (param) => service.get(`/Case/DriverExecutionList?${param}`, );

//臨時技師 執行改派/手動派遣
export const DispatchCaseByTemporaryDriver = (obj) => service.post(`/Case/DispatchCaseByTemporary`,obj );

//正式/支援技師 執行改派/手動派遣
export const DispatchCase = (obj) => service.post(`/Case/DispatchCase`,obj );

//全手動- 取得案件排序清單
export const CaseSortListFullManual = (obj) => service.post(`/Case/CaseSortListFullManual`,obj );
//全手動- 取得案件排序清單
export const DriverExecutionListFullManual = (param) => service.get(`/Case/DriverExecutionListFullManual?${param}`, );

//全手動派遣
export const DispatchCaseFullManual = (obj) => service.post(`/Case/DispatchCaseFullManual`,obj );
 

//手動改派 -進入系統
export const ChangeDispatchCase = (obj) => service.post(`/Case/ChangeDispatchCase`,obj );
//全手動改派
export const ManualChangeDispatchCase = (obj) => service.post(`/Case/ChangeDispatchCaseFullManual`,obj );
//取得支援技師車輛資訊
export const SupportDriverCar = (id) => service.get(`/Case/SupportDriverCar?driver_row_id=${id}` );

//繼續執行異況案件
export const Continue = (id,obj) => service.post(`/Case/${id}/Continue`,obj );
// import React, { useState } from 'react';
import { Button } from 'antd'
import { PhoneFilled } from '@ant-design/icons';
import moment from 'moment'
import { useNavigate } from 'react-router-dom';
import { ConfirmDispatch } from '../../../api/Case';

const Modalcard = ({ status, setVisible,data, Success, continueExecute }) => {
    let navigate = useNavigate()
    if (status === 0) {
        //取消
      return(
        <div style={{ background: "white", marginTop: 24 }}>
        <div style={{ padding: "16px 24px", borderBottom: "1px solid rgba(240, 240, 240, 1)", fontSize: 16, fontWeight: 500 }}>案件已被取消</div>
        <div style={{ padding: 24 }}>您可以隨時透過再次派遣替案件設定新的運送時間，並進入案件媒合階段。</div>
        <div
            style={{ borderTop: "1px solid rgba(240, 240, 240, 1)", padding: "10px 24px", display: "flex", justifyContent: "flex-end" }}
            onClick={() => setVisible("3")}
        >
            <Button>再次派遣</Button>
        </div>
    </div>
      )

    }else if (status === 1) {
        //待收單
      return(
        <div style={{ background: "white", marginTop: 24 }}>
        <div style={{ padding: "16px 24px", borderBottom: "1px solid rgba(240, 240, 240, 1)", fontSize: 16, fontWeight: 500 }}>等待審核</div>
        <div style={{ padding: 24 }}>客服在收到客戶上單後後請盡快審核，內容如有疑問請與客戶聯繫後於審核階段修改，案件一經成立將不可修改。</div>
        <div
            style={{ borderTop: "1px solid rgba(240, 240, 240, 1)", padding: "10px 24px", display: "flex", justifyContent: "flex-end" }}
            onClick={() => setVisible("navigate")}
        >
            <Button type='primary'>案件審核</Button>
        </div>
    </div>
      )
    }else if (status === 2) {
        return (
            <div style={{ background: "white", marginTop: 24 }}>
                <div style={{ padding: "16px 24px", borderBottom: "1px solid rgba(240, 240, 240, 1)", fontSize: 16, fontWeight: 500 }}>等待媒合</div>
                <div style={{ padding: 24 }}>系統將於 <span style={{ color: "rgba(24, 144, 255, 1)" }}>今天 {moment().add(0, "minute").format("HH")*1+1}:00</span> 自動媒合所有的待派遣案件，您也可以自行替該案件指定服務技師。</div>
                <div
                    style={{ borderTop: "1px solid rgba(240, 240, 240, 1)", padding: "10px 24px", display: "flex", justifyContent: "flex-end" }}
                    onClick={() => setVisible(1)}
                >
                    <Button>手動派遣</Button>
                </div>
            </div>
        )
    } else if (status === 3) {
        return (
            <div style={{ background: "white", marginTop: 24 }}>
                <div style={{ padding: "16px 24px", borderBottom: "1px solid rgba(240, 240, 240, 1)", fontSize: 16, fontWeight: 500 }}>媒合成功請確認派遣結果</div>
                <div style={{ padding: 24 }}>此案件將交予 <span style={{ color: "rgba(24, 144, 255, 1)" }}>{data.service_point}</span> 的 <span style={{ color: "rgba(24, 144, 255, 1)" }}>{data.driver}</span>，駕駛 <span style={{ color: "rgba(24, 144, 255, 1)" }}>{data.trailer}</span>，請確認是否派遣給該技師，此案件將於 <span style={{ color: "rgba(24, 144, 255, 1)" }}>今天  {moment().add(0, "minute").format("HH")*1+1}:00</span> 再次媒合。</div>
                <div style={{ borderTop: "1px solid rgba(240, 240, 240, 1)", padding: "10px 24px", display: "flex", justifyContent: "flex-end" }}>
                    <Button style={{ marginRight: 8 }} onClick={()=>{
                        navigate(`/execution/${data.execution_info_row_id}`)
                    }}>執行內容</Button>
                    <Button type="primary" onClick={()=>{
                        ConfirmDispatch(data.execution_info_row_id, {execution_info_row_id:data.execution_info_row_id}).then(res=>{
                            if(res && !res.Error){
                                Success(true)
                            }
                        })
                    }}>同意派遣</Button>
                </div>
            </div>
        )
    } else if (status === 4) {
        return (
            <div style={{ background: "white", marginTop: 24 }}>
                <div style={{ padding: "16px 24px", borderBottom: "1px solid rgba(240, 240, 240, 1)", fontSize: 16, fontWeight: 500 }}>等待技師確認中</div>
                <div style={{ padding: 24 }}>
                案件於 
                <span style={{ color: "rgba(24, 144, 255, 1)" }}> {moment(data.has_dispatch_time).format("HH:mm")} </span>
                派給 
                <span style={{ color: "rgba(24, 144, 255, 1)" }}> {data.service_point} </span>
                的
                <span style={{ color: "rgba(24, 144, 255, 1)" }}> {data.driver} </span>
                ，駕駛
                <span style={{ color: "rgba(24, 144, 255, 1)" }}> {data.trailer} </span>
                ，執行單號為
                <span style={{ color: "rgba(24, 144, 255, 1)" }}> {data.execute_id}</span>
                等待技師確認中，如技師超過十分鐘未承接此案件，請與技師聯絡確認狀況</div>
                <div
                    style={{ borderTop: "1px solid rgba(240, 240, 240, 1)", padding: "10px 24px", display: "flex", justifyContent: "flex-end" }}
                >
                    <Button style={{marginRight:8}} onClick={()=>{
                        navigate(`/execution/${data.execution_info_row_id}`)
                    }}>執行內容</Button>
                    <Button type="primary" icon={<PhoneFilled />}>聯絡技師</Button>
                </div>
            </div>
        )
    } else if(status ===5){
        return (
            <div style={{ background: "white", marginTop: 24 }}>
                <div style={{ padding: "16px 24px", borderBottom: "1px solid rgba(240, 240, 240, 1)", fontSize: 16, fontWeight: 500 }}>等待技師開始執行</div>
                <div style={{ padding: 24 }}>
                案件於 
                <span style={{ color: "rgba(24, 144, 255, 1)" }}> {moment(data.has_dispatch_time).format("HH:mm")} </span>
                由 
                <span style={{ color: "rgba(24, 144, 255, 1)" }}> {data.service_point} </span>
                的
                <span style={{ color: "rgba(24, 144, 255, 1)" }}> {data.driver} </span>
                承接，駕駛
                <span style={{ color: "rgba(24, 144, 255, 1)" }}> {data.trailer} </span>
                ，執行單號
                <span style={{ color: "rgba(24, 144, 255, 1)" }}> {data.execute_id} </span>
                預計於 {moment().add(1, "h").format("HH:mm")} 開始執行</div>
                <div
                    style={{ borderTop: "1px solid rgba(240, 240, 240, 1)", padding: "10px 24px", display: "flex", justifyContent: "flex-end" }}
                >
                    <Button style={{marginRight:8}} onClick={()=>{
                        navigate(`/execution/${data.execution_info_row_id}`)
                    }}>執行內容</Button>
                    <Button type="primary" icon={<PhoneFilled />}>聯絡技師</Button>
                </div>
            </div>
        )
    }else if(status === 11){
        return (
            <div style={{ background: "white", marginTop: 24 }}>
                <div style={{ padding: "16px 24px", borderBottom: "1px solid rgba(240, 240, 240, 1)", fontSize: 16, fontWeight: 500 }}>異況確認</div>
                <div style={{ padding: 24 }}>
                技師回報運送案件 
                <span style={{ color: "rgba(24, 144, 255, 1)" }}>異況項目  </span>
                ，請與技師電話確認現場狀況後更改案件狀態 
                </div>
                <div
                    style={{ borderTop: "1px solid rgba(240, 240, 240, 1)", padding: "10px 24px", display: "flex", justifyContent: "flex-end" }}
                >
                    <Button style={{marginRight:8}} onClick={()=>continueExecute(true)}>繼續執行</Button>
                    <Button type="primary" icon={<PhoneFilled />}>聯絡技師</Button>
                </div>
            </div>
        )
    }else
    {
        return (<div></div>)
    }
}

export default Modalcard;

import React, { useState, useEffect, useRef } from 'react'
import { Table, Tag, Button, Input, Form, AutoComplete, Radio, Spin } from 'antd'
import { UserOutlined, BookOutlined, Loading3QuartersOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { GetOfficialDriverList, GetNonOfficialDriverList, SupportDriverCar, GetTrailerType, CaseSortListFullManual } from '../../../api/Case'
import ManualRoute from './ManualRoute'
import { GetServicePointList } from '../../../api/ServicePoint'
import { AllDrivers } from '../../../api/Code'

const Driver = ({ type, selectedCar, visible, selectedTechnician, SetSelectedTechnician, SetVisible, availableCarType, active, width }) => {
  const [technicianList, setTechnicianList] = useState(null)
  // const [AllTechnicianList, setAllTechnicianList] = useState([])
  // const [AllTrailer, setAllTrailer] = useState(null)
  // const [selectedTrailer, setSelectedTrailer] = useState(null)
  // const [inputValue, setInputValue] = useState("")
  // const AllTrailer = ["全載車", "三載車", "四載車", "六載車", "八載車"]
  const [loading, setLoading] = useState(null)
  const [manualRouteDrawer, setManualRouteDrawer] = useState(false)
  const [fullManualCases, setFullManualCases] = useState(null)
  const [stations, setStations] = useState(null)
  const [drivers, setDrivers] = useState(null)
  const [filterOpt, setFilterOpt] = useState({ working_type: 0 })
  const form = useRef()
  const columnsTechnician = type === "official" ? [
    { title: '據點', dataIndex: 'service_point_name', key: 'service_point_name' },
    { title: '技師', dataIndex: 'driver_name', key: 'driver_name',
      render: (record) => (<Tag icon={<UserOutlined />}>{record}</Tag>)
    },
    { title: '載車', dataIndex: 'plate_number', key: 'plate_number' },
    { title: '車種', dataIndex: 'trailer_type_name', key: 'trailer_type_name' },
    { title: '出勤型態', dataIndex: 'working_type', key: 'working_type',
      render: (record) => (<p>{record===1?'正式':'支援'}</p>)
    },
    { title: '狀態', dataIndex: 'status_tag', key: 'status_tag', width: "30%",
      render: (record) => record && record.length > 0 ? (<div>
        {
          record.map(r => <Tag >{r}</Tag>)
        }
      </div>) : ("-")
    },
    { title: '', dataIndex: '', key: '', width: '10%',
      render: (_, record) => !selectedTechnician ? (<div style={{ display: 'flex', gridGap: 8 }}>
      {
        record.working_type===1 && (
          <Button type='link'
          onClick={() => {
            //visible: 1-手動派遣； 7: 改派他人
            CaseSortListFullManual({
              wait_match_case_list: [...selectedCar].map(car => car.case_row_id),
              query_type: visible === 1 ? 1 : 2
            }).then(res => {
              if (res && !res.Error) {
                setFullManualCases(res.Result)
                setManualRouteDrawer(record)
              }
            })
            // setManualRouteDrawer(record)
            // SetSelectedTechnician([record],'manual')
          }}
        > {selectedTechnician ? "取消" : ""}客服手動</Button>
        )
      }
        
        <Button type='link'
          onClick={() => {
            SetSelectedTechnician([record])
          }}
        >{selectedTechnician ? "取消" : ""}系統自動</Button>
      </div>) : (<Button type='link'
        onClick={() => {
          SetSelectedTechnician(null)
        }}
      >取消</Button>)
    },
  ] : [
  { title: '據點', dataIndex: 'service_point_name', key: 'service_point_name'},
  { title: '技師', dataIndex: 'driver_name', key: 'driver_name',
    render: (record) => (<Tag icon={<UserOutlined />}>{record}</Tag>)
  },
  { title: '技能', dataIndex: 'skill_list', key: 'skill_list',
    render: (record, data) => record?.map(r => <Tag key={`${data.driver_row_id}_${r.skill_code}`}>{r.skill_name}</Tag>)
  },
  { title: '', dataIndex: 'mobile_phone_number', key: 'mobile_phone_number',
    render: (record) => <a href={`tel:${record}`}><Button type='link'>撥打電話</Button></a>
  },
  { title: '', dataIndex: 'action', key: 'action', width: "10%",
    render: (mobility, record) => (
      <div onClick={async () => {
        let Result = { ...record }
        if (!selectedTechnician) {
          let res = await SupportDriverCar(record.driver_row_id)
          if (res && !res.Error) {
            let data = res.Result
            // if (data.plate_number) {
            //   setSelectedDriverCar(data.car_row_id)
            // } else {
            //   setSelectedDriverCar(null)
            // }
            Result = { ...record, ...data }
            // console.log(data)
          }
        }
        SetSelectedTechnician(!selectedTechnician ? [Result] : null)
      }}
      >
        <Button type="link">{selectedTechnician ? "取消" : ""}選擇</Button>
      </div>)
  }]
  const FecthApi = async () => {
    if (!filterOpt.service_point_name && !filterOpt.driver_name) return false
    // if (!selectedTrailer) {
    //   GetTrailerType({ wait_match_case_list: selectedCar.map(car => car.case_row_id ? car.case_row_id : car.row_id) }).then(Trailer => {
    //     if (Trailer && !Trailer.Error) {
    //       let obj = {}
    //       Trailer.Result.forEach(trailer => obj[trailer.trailer_type_name] = true)
    //       if (availableCarType) availableCarType(obj)
    //       setSelectedTrailer(obj)
    //     }
    //   })
    // }
    setLoading(true)
    let param = ''
    selectedCar.forEach((s, i) => {
      param += i === 0 ? `wait_match_case_list[${i}]=${s.case_row_id ? s.case_row_id : s.row_id}` : `&wait_match_case_list[${i}]=${s.case_row_id ? s.case_row_id : s.row_id}`
    })

    Object.keys(filterOpt).forEach((key, i) => {
      param += `&${key}=${filterOpt[key]}`
    })

    let res = await GetOfficialDriverList(param)
    // if (type === 'official') {
    //   res = await GetOfficialDriverList(param)
    // } else {
    //   res = await GetNonOfficialDriverList(param)
    // }
    if (res && !res.Error) {
      let Result = res.Result.map(obj => {
        let status = []
        let tag = ["area1_list", "driver_status", "case_match_count"]
          .map(t => {
            let text = ''
            if (t === "case_match_count") {
              if (obj[t] > 0) {
                text = `今日已載 ${obj[t]} 車`
              }
            } else if (t === 'driver_status') {
              text = obj[t] === "0" ? "休息" : obj[t] === "1" ? "尚未打卡" : "執勤中"
            }
            else {
              if (obj.is_match_area_1 > 0 || obj.is_match_area_2 > 0) {
                text = `主要 ${obj.is_match_area_1 > 0 ? 1 : 2}`
              }
            }
            if (text !== '') status.push(text)
          })
        obj.status_tag = status
        obj.filter = `${obj.service_point_name.toLowerCase()},${obj.driver_name.toLowerCase()}`
        return obj
      })
      setTechnicianList(Result)
      setLoading(false)
    }
  }
  // const onSearchDriver = async (val) => {
  //   setInputValue(val)
  //   let filter = AllTechnicianList.filter(a => a.filter.indexOf(val.toLowerCase()) > -1)
  //   setTechnicianList(filter)
  // }
  useEffect(() => {
    // FecthApi()
    GetServicePointList(`page_index=0&page_size=-1`).then(res => {
      setStations(res?.Result.Data || [])
    })
    AllDrivers().then(res => {
      setDrivers(res?.Result || [])
    })
  }, []);

  return (
    <>
      <div>
        {/* {
          !active && (
            <>
              <div >可配合載運之載車
                <Tooltip placement="topLeft" title="部分車型有限制車種，請選擇可配合載運之載車技師">
                  <QuestionCircleOutlined style={{ cursor: "pointer", paddingLeft: 4 }} />
                </Tooltip>
              </div>
              {
                AllTrailer && selectedTrailer && (
                  <ul className='tab_block'>
                    {
                      AllTrailer.map(t => (<li className={selectedTrailer[t] ? 'active' : ''}>{t}</li>))
                    }
                  </ul>
                )
              }
            </>
          )
        } */}


      </div>
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", gridGap: 24 }}>
        <div>{!active ? "媒合名單" : "技師名單"}</div>
        <Button key="臨時技師" icon={<BookOutlined />} onClick={() => {
          visible === 1 ? SetVisible("5a") : SetVisible("5b")
        }} >臨時技師</Button>
      </div>

      {
        !selectedTechnician && (
          <div style={{ display: "flex", alignItems: "center", padding: 24, paddingBottom: 0, background: 'rgba(250, 250, 250, 1)', margin: '8px 0' }}>
            <Form
              name="basic"
              layout="vertical"
              ref={form}
              initialValues={filterOpt}
              onFinish={(value) => {
                FecthApi()
              }}
              // onFinishFailed={onFinishFailed}
              autoComplete="off"
              style={{ display: "flex", justifyContent: "space-between", alignItems: "center", gridGap: 24, width: "100%" }}
            >
              <Form.Item
                label={<p>
                  據點
                  <span style={{ color: 'rgba(0, 0, 0, 0.45)', fontSize: 14 }}> (與技師擇一輸入)</span>
                </p>}
                name="service_point_name"
                style={{ flexGrow: 1 }}

              // rules={[{ required: true, message: 'Please input your username!' }]}
              >
                <AutoComplete
                  disabled={filterOpt.driver_name}
                  options={(stations || []).map(d => ({
                    value: d.name,
                    label: d.name,
                  }))}
                  onChange={(data) => setFilterOpt({ ...filterOpt, service_point_name: data })}
                  filterOption={(inputValue, option) => (option.value?.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1)}
                  // onSearch={(searchText) => {
                  //  return searchText ? stations.filter(d => d.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1) : []
                  //   // setOptionList(search)
                  // }}
                  allowClear
                  placeholder='請搜尋據點'
                />
              </Form.Item>
              <Form.Item
                label={<p>
                  技師
                  <span style={{ color: 'rgba(0, 0, 0, 0.45)', fontSize: 14 }}> (與據點擇一輸入)</span>
                </p>}
                name="driver_name"
                style={{ flexGrow: 1 }}
              // rules={[{ required: true, message: 'Please input your username!' }]}
              >
                <AutoComplete
                  options={(drivers || []).map(d => ({
                    value: d.Name,
                    label: d.Name,
                  }))}
                  disabled={filterOpt.service_point_name}
                  onChange={(data) => setFilterOpt({ ...filterOpt, driver_name: data })}
                  filterOption={(inputValue, option) => (option.value?.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1)}
                  // onSearch={(searchText) => {
                  //  return searchText ? stations.filter(d => d.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1) : []
                  //   // setOptionList(search)
                  // }}
                  allowClear
                  placeholder='請搜尋技師'
                />
              </Form.Item>
              <Form.Item
                label="出勤型態"
                name="working_type"
              // rules={[{ required: true, message: 'Please input your username!' }]}
              >
                <Radio.Group
                  options={[
                    { label: '全部', value: 0 },
                    { label: '正式', value: 1 },
                    { label: '支援', value: 2 },
                  ]}
                  onChange={(e) => setFilterOpt({ ...filterOpt, working_type: e.target.value })}
                  optionType="button"
                // buttonStyle="solid"
                />
              </Form.Item>
              <Button type="primary" htmlType="submit" style={{ marginTop: 4 }}>
                搜尋
              </Button>
            </Form>
          </div>
        )
      }

      {
        loading ? (<div style={{ width: '100%', display: 'flex', justifyContent: 'center', margin: "62px 0" }}>
          <Spin tip="搜尋中..."></Spin>
        </div>) : selectedTechnician || technicianList ? (
          <>
            {
              !selectedTechnician && (<p style={{ margin: "24px 0", fontSize: 20, fontWeight: 500 }}>找到 {technicianList.length} 個結果</p>)
            }
            {
              technicianList.length > 0 || selectedTechnician ? (
                <Table
                  rowKey={record => record.name}
                  dataSource={!selectedTechnician ? technicianList : selectedTechnician}
                  columns={columnsTechnician}
                  onRow={(record, rowIndex) => {
                    return {
                      onClick: (event) => { }, // click row
                    };
                  }}
                  pagination={false}
                  style={{ margin: "8px 0 24px" }}
                />
              ) : (
                <p style={{ color: "rgba(0, 0, 0, 0.45)", fontSize: 14 }}>你可以試著變更搜尋條件再試一次</p>
              )
            }

          </>
        ) : (<></>)
      }
      {/*  */}

      <ManualRoute width='1280px'
        SetVisible={(val) => setManualRouteDrawer(val)}
        fullManualCases={fullManualCases}
        visible={manualRouteDrawer}
        selectedCar={selectedCar}
        onConfirm={(car, driver) => {
          // console.log(driver)
          // console.log(car)
          SetSelectedTechnician(driver, car)
        }}
      />
    </>
  )
}

export default Driver
import React, { useState, useRef, useEffect } from 'react'
import { PageHeader, Form, Input, Button, Select, Modal, Table, DatePicker, Cascader, message, Steps, Radio } from 'antd'
import { EnterOutlined, PlusOutlined, RightOutlined, ArrowRightOutlined, UnorderedListOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import BreadCrumb from '../../components/shared/BreadCrumb'
import { PrivilegeList, CheckPrivilegeTagName,AddPrivilegeTag,AddPrivilegeTagNote } from '../../api/Member'
import LoadingBlock from '../../components/shared/LoadingBlock'
const { Step } = Steps
const { TextArea } = Input

const steps = [
    { title: '權限設定', description: '', content: 'First-content', width: "30%" },
    { title: '通知設定', description: '', content: 'Second-content', width: "100%" },
    { title: '完成', description: '', content: 'Last-content', width: "30%" },
];
const TagAdd = ({ ContainerWidth }) => {
    const [currentStep, setCurrentStep] = useState(0)
    const [option, setOption] = useState(null)
    const [successSubmit, setSuccessSubmit] = useState(null)
    const [DefaultValue, setDefaultValue] = useState(null)
    const [nameValidation, setNameValidation] = useState(null)
    const [submitData, setSubmitData] = useState(null)
    const [remark, setRemark] = useState("")
    const form = useRef(), inputName = useRef()
    const navigate = useNavigate()
    const onSubmitForm = async (val) => {
        let name_check = true
        if (currentStep === 0) {
            let nameCheck = await CheckPrivilegeTagName(val.tag_name)
            if (nameCheck.Result === 'true') {
                inputName.current.focus()
                setNameValidation(false)
                name_check = false
            } else {
                setNameValidation(true)
                name_check = true
            }
        }
        if (name_check) {
            let data = { ...submitData }

            if (submitData === null) {
                setSubmitData(val)
            } else {
                for (let key in val) {
                    data[key] = val[key]
                }
                setSubmitData(val)
            }
            if (currentStep === 1) {
                let permission_list = []
                for (let key in data) {
                    if (key !== "tag_name") {
                        permission_list.push({
                            permission_id: key, status: data[key]
                        })
                    }
                }
                let reform_obj = { tag_name: data.tag_name, permission_list }
                AddPrivilegeTag(reform_obj).then(res=>{
                console.log(res)

                    if(!res.Error){
                        setSuccessSubmit({...res.Result,name:reform_obj.tag_name})
                        form.current.resetFields()
                        setCurrentStep(currentStep + 1)
                    }
                })
                // form
            } else {
                setCurrentStep(currentStep + 1)

            }
        }
    }
    useEffect(() => {
        let container = document.querySelector(".container")
        container.scrollTo(0, 0)
    }, [currentStep])
    useEffect(() => {
        PrivilegeList().then(res => {
            if (!res.Error) {
                let obj = { "權限選單": [], "通知": [] }
                let default_value = {}
                let Result = res.Result.forEach(r => {
                    obj[r.permission_type_name].push(r)
                    default_value[r.id] = r.permission_type_name === "權限選單" ? "1" : "2"
                })
                setTimeout(() => {
                    if (form.current) form.current.setFieldsValue(default_value)
                }, 100)
                setDefaultValue(default_value)
                setOption(obj)
            }
        })
    }, [])
    return (
        <div className="container" style={ContainerWidth}>
            {
                option ? (<>
                    <PageHeader
                        title="權限標籤新增"
                        onBack={() => currentStep === 0 ? window.history.go(-1) : setCurrentStep(currentStep - 1)}
                        className="site-page-header"
                        breadcrumb={<BreadCrumb />}
                    >
                    </PageHeader>
                    <div className='step_block'>
                        <div >
                            <Form
                                ref={form}
                                name="basic"
                                layout="vertical"
                                onFinish={onSubmitForm}
                                onFinishFailed={(value) => console.log(value)}
                                defaultValue={DefaultValue}
                            >
                                <div className='step_block'>

                                    <Steps current={currentStep} style={{ padding: "24px", borderBottom: "1px solid rgba(0, 0, 0, 0.06)", margin: "24px 24px 0 24px", background: "white", width: "calc(100% - 48px)" }}>
                                        {steps.map(item => (
                                            <Step key={item.title} title={item.title} description={item.description} style={{ width: item.width }} />
                                        ))}
                                    </Steps>
                                    <div className='step_content'>
                                        {
                                            currentStep === 0 && (
                                                <div>
                                                    <Form.Item
                                                        label="標籤名稱"
                                                        name="tag_name"
                                                        hasFeedback
                                                        validateStatus={nameValidation == null ? "" : nameValidation ? "success" : "error"}
                                                        help={nameValidation == null || nameValidation ? '' : '此標籤名稱已存在'}
                                                        rules={[{ required: true, message: '請輸入中文字名稱!' }]}
                                                    >
                                                        <Input
                                                            onKeyUp={() => setNameValidation(null)}
                                                            ref={inputName}
                                                            placeholder='請輸入中文字名稱' addonAfter={<EnterOutlined style={{ cursor: "pointer" }} onClick={() => {
                                                                let val = form.current.getFieldValue().tag_name
                                                                CheckPrivilegeTagName(val).then(res => {
                                                                    if (res.Result === 'true') {
                                                                        setNameValidation(false)
                                                                    } else {
                                                                        setNameValidation(true)
                                                                    }
                                                                })
                                                            }} />} style={{ width: "304px" }} />
                                                    </Form.Item>
                                                    {
                                                        console.log(option["權限選單"])
                                                    }
                                                    {
                                                        option["權限選單"].map(o => (<>
                                                            <Form.Item
                                                                key={o.id}
                                                                label={o.permission_name}
                                                                name={o.id}
                                                                rules={[{ required: true, message: '請輸入中文字名稱!' }]}
                                                            >
                                                                <Radio.Group>
                                                                    <Radio.Button value="1">檢視
                                                                    
                                                                    </Radio.Button>
                                                                    {
                                                                         o.permission_name !=="加油管理"&&(
                                                                            <Radio.Button value="2">編輯</Radio.Button> 
                                                                         )
                                                                    }
                                                                   
                                                                    <Radio.Button value="3">隱藏</Radio.Button>
                                                                </Radio.Group>
                                                            </Form.Item>
                                                        </>))
                                                    }
                                                </div>)
                                        }
                                        {
                                            currentStep === 1 && (<>
                                                {
                                                    option["通知"].map(o => (<>
                                                        <Form.Item
                                                            key={o.id}
                                                            label={o.permission_name}
                                                            name={o.id}
                                                            rules={[{ required: true, message: '請輸入中文字名稱!' }]}
                                                        >
                                                            <Radio.Group>
                                                                <Radio.Button value="2">允許</Radio.Button>
                                                                <Radio.Button value="3">拒絕</Radio.Button>
                                                            </Radio.Group>
                                                        </Form.Item>
                                                    </>))
                                                }
                                            </>)
                                        }
                                        {
                                            currentStep === 2 && successSubmit && (<>
                                                <div style={{ fontSize: 30 }}>
                                                    權限標籤 #{successSubmit.name} 已成立
                                                </div>
                                                <div style={{ color: "rgba(0, 0, 0, 0.45)", marginTop: 8 }}>該標籤已成立，即刻就可以讓別的帳號使用。</div>
                                                <TextArea
                                                    placeholder="額外的權限標籤說明請寫在此......"
                                                    autoSize={{ minRows: 2, maxRows: 4 }}
                                                    style={{ width: 395, marginTop: 8 }}
                                                    onChange={(e) => setRemark(e.target.value)}
                                                    value={remark}
                                                />
                                                <Button
                                                    style={{ display: "block", marginTop: 8 }}
                                                    disabled={remark !== "" ? "" : "disabled"}
                                                    onClick={() => {
                                                        let obj = {
                                                            tag_id: successSubmit.id,
                                                            tag_note: remark
                                                        }
                                                        AddPrivilegeTagNote(obj).then(res => {
                                                            if (!res.Error) {
                                                                console.log(res)
                                                                setRemark("")
                                                                form.resetFields()
                                                                message.success('已新增備註')
                                                            }
                                                        })
                                                    }}
                                                > 新增備註 </Button>
                                                <div style={{ marginTop: 32 }}>
                                                    <Button
                                                        type="primary"
                                                        icon={<PlusOutlined />}
                                                        onClick={() => setCurrentStep(0)}
                                                    >新增其他標籤</Button>
                                                    <Button
                                                        icon={<RightOutlined />}
                                                        style={{ margin: "0 8px" }}
                                                        onClick={() => navigate(`/privilege/tags/${successSubmit.id}`)}
                                                    >權限標籤內容</Button>
                                                    <Button
                                                        icon={<UnorderedListOutlined />}
                                                        onClick={() => navigate("/privilege/tags")}
                                                    >回列表</Button>
                                                </div>
                                            </>)
                                        }
                                    
                            
                                </div>
                        </div>
                        {
                            currentStep !== 2 && (<div style={{ background: "white", marginTop: 16, width: "100%", padding: "20px", display: "flex", justifyContent: "flex-end" }}>
                                <Button type="text" style={{ marginRight: 24 }} >
                                    關閉
                                </Button>
                                <Button type="primary" icon={<ArrowRightOutlined />}
                                    htmlType="submit"
                                >
                                    下一步
                                </Button>

                            </div>)
                        }
                    </Form>
                </div>
                    </div>
                </>) : (
    <div style={{ padding: 24, height: '100%' }}>
        <LoadingBlock />
    </div>


)
            }

        </div >
    )
}

export default TagAdd
import React from 'react'

function ErrorPage({ContainerWidth}) {
    return (
        <div className="container" style={ContainerWidth}>
        <h2 style={{padding: 16}}>頁面建置中...</h2>
            {/* Error! Page Not Found */}
        </div>
    )
}

export default ErrorPage

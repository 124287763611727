
export const StatusObj = (status) => {
    let obj;
    switch (status) {
        case 0:
            obj = { color: "#FF4D4F", text: "取消", tagColor: "red" }
            break;
        case 99:
            obj = { color: "#D9D9D9", text: "草稿", tagColor: "" }
            break;
        case 1:
            obj = { color: "#D9D9D9", text: "待收單", tagColor: "" }
            break;
        case 2:
            obj = { color: "#D9D9D9", text: "待媒合", tagColor: "" }
            break;
        case 3:
            obj = { color: "#D9D9D9", text: "待派遣", tagColor: "" }
            break;
        case 4:
            obj = { color: "#FAAD14", text: "已派遣", tagColor: "orange" }
            break;   
        case 5:
            obj = { color: "#FAAD14", text: "已接案", tagColor: "orange" }
            break;
        case 6:
            obj = { color: "#52C41A", text: "執行中", tagColor: "green" }
            break;
        case 7: case 10:
            obj = { color: "#1890FF", text: "待結案", tagColor: "blue" }
            break;
        case 8:
            obj = { color: "#2F54EB", text: "已結案", tagColor: "geekblue" }
            break;
        case 11:
            obj = { color: "#FF4D4F", text: "異況確認", tagColor: "magenta" }
            break;
        default:
            obj = {}

    }

    return obj
}


export const carStatus = (status) => {
    let obj;
    switch (status) {
        case "0":
            obj = { color: "#FF4D4F", text: "停派", tagColor: "red" }
            break;
        case "1":
            obj = { color: "#D9D9D9", text: "正常", tagColor: "green" }
            break;
        case "2":
            obj = { color: "#D9D9D9", text: "修繕", tagColor: "orange" }
            break;
        case "3":
            obj = { color: "#D9D9D9", text: "撤銷", tagColor: "default" }
            break;
        case "4":
            obj = { color: "#FAAD14", text: "報廢", tagColor: "default" }
            break;   
        default:
            obj = {}

    }

    return obj
}
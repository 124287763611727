import React, { useState, useEffect, useRef } from 'react'
import BreadCrumb from '../../components/shared/BreadCrumb'
import { PageHeader, Button, Select, Drawer, Form, Table, Input, Tag, Cascader, Modal, Checkbox,AutoComplete } from 'antd'
import { FilterOutlined, PlusOutlined, CloseOutlined, FileAddOutlined } from '@ant-design/icons'
import { useNavigate, useLocation } from 'react-router-dom'
import { GetServicePointList } from '../../api/ServicePoint'
import { RouteName } from '../../util/route'
import { useSelector } from 'react-redux'
import { DownloadFile } from '../../util/download'
const { Option } = Select

const Index = ({ pageTitle, ContainerWidth }) => {
    const [filterVisible, setFilterVisible] = useState(false)
    let navigate = useNavigate()
    const { state, pathname } = useLocation()
    const routeId = RouteName(pathname)
    let UserInfo = useSelector(state => state.UserInfo)
    let UserPermission = UserInfo ? UserInfo.permission.route : null
    const [data, setData] = useState([])
    const [filterObjectLabel, setFilterObjectLabel] = useState(null)
    const [filterObject, setFilterObject] = useState(null)
    const [modal, setModal] = useState(null)
    const [modalBtn, setModalBtn] = useState(false)
    const [optionList, setOptionList] = useState([])
    const [ServicePoint, setServicePoint] = useState([])
    const submitRef = useRef(), statementForm = useRef()
    const filterName = { service_point_name: "據點名稱", responsible_person: "負責人", contact_person: "聯絡人", contact_phone: "聯絡電話", category: "錄用分類", service_type: '服務類別' };

    const columns = [
        { title: '據點名稱', dataIndex: 'name', },
        { title: '負責人', dataIndex: 'responsible_person' },
        { title: '聯絡人', dataIndex: 'contact_name' },
        { title: '聯絡電話', dataIndex: 'tel', width: '15%' },
        { title: '錄用分類', dataIndex: 'category_text', },
        {
            title: '派遣狀態', dataIndex: 'status',
            render: status => status === "0" ? (<div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ width: 6, height: 6, borderRadius: "50%", background: "#FF4D4F", marginRight: 8 }}></div>
                <p>停派</p>
            </div>) :
                (<div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ width: 6, height: 6, borderRadius: "50%", background: "#52C41A", marginRight: 8 }}></div>
                    <p>可派遣</p>
                </div>)
        },
        {
            title: '服務類別', dataIndex: 'services',
            render: record => record ? record.map(r => <Tag>{r === "1" ? "運送車" : "道路救援"}</Tag>) : '-'
        }]
    const onChangeFilter = (obj) => {
        let filter = { ...filterObject }
        Object.keys(obj).forEach(key => {
            filter[key] = obj[key]
        })
        setFilterObject(filter)
    }
    const fetchList = async () => {
        let container = document.querySelector(".container")
        container.scrollTo(0, 0)
        let param = ''
        Object.keys(filterObject).forEach((key, i) => {
            let value = filterObject[key]
            param += i === 0 ? `${key}=${value}` : `&${key}=${value}`

        })
        let res = await GetServicePointList(param)
        if (!res.Error) {
            let result = res.Result
            result.Data.map(r => {
                let services = []
                r.category_text = r.category === "1" ? "正式" : "支援"
                if (r.service_type) {
                    if (r.service_type.indexOf(",") > -1) {
                        services = r.service_type.split(",")
                    } else {
                        services.push(r.service_type)
                    }
                } else {
                    services = ''
                }
                r.services = services
                return r
            })
            setData(result)
        }
    }
    const exportReport = async (val) => {
        setModalBtn(true)
        let is_all = false
        let service_point_row_id_list = []
        if (val.service_point_row_id) {
            val.service_point_row_id.forEach(s => {
                if (s.length === 1) {
                    is_all = true
                    return;

                } else {
                    is_all = false
                    service_point_row_id_list.push(s[1])
                }
            })
        }
        let export_type = val.export_type
        let success =[]
        for (let i = 0; i < export_type.length; i++) {
            let res = await DownloadFile(`ServicePoint/ExcelExport?${is_all ? `is_all=${is_all}` : `service_point_row_id_list=${service_point_row_id_list}`}&export_type=${export_type[i]}`)
            if (res) {
                success.push(1)
            }else{
                success.push(0)
            }
        }
        let unsucess = success.filter(s=>!s)
        if(unsucess.length ===0 && success.length ===export_type.length){
            setModalBtn(false)
            setModal(null)
        }
    }
    useEffect(() => {
        if (state) {
            onChangeFilter(state)
        } else {
            onChangeFilter({ page_index: 0, page_size: 10 })
        }
    }, [state])
    useEffect(() => {
        if (filterObject) fetchList()
    }, [filterObject])
    useEffect(() => {
        GetServicePointList(`page_index=0&page_size=-1`).then(res => {
            if (res && !res.Error) {
                setOptionList(res.Result.Data)
                setServicePoint([{
                    value: "all",
                    label: "全部據點",
                    children: res.Result.Data.map(d => ({ ...d,label: d.name, value: d.row_id }))

                }])
            }
        })
    }, [])

    return (
        <div className="container" style={ContainerWidth}>
            <PageHeader
                style={{ background: "white", width: "100%" }}
                title="據點管理"
                className="site-page-header"
                extra={UserPermission && UserPermission[routeId] === 2 ? [
                    <Button
                        icon={<FilterOutlined />}
                        onClick={() => {
                            setFilterVisible(true)
                            setTimeout(() => {
                                submitRef.current.resetFields()
                                let filter = { ...filterObject }
                                Object.keys(filter).forEach((key) => {
                                    if ( key === 'category') {
                                        // key === 'service_point_row_id' ||
                                        filter[key] = `${filter[key][0]},${filterObjectLabel[key][0]}`
                                    } else if (key === 'service_type') {
                                        filter[key] = filter[key].map(v => {
                                            return v === "1" ? '1,運送車' : '2,道路救援'
                                        })
                                    }
                                })
                                submitRef.current.setFieldsValue(filter)
                            }, 100)
                        }}
                    >篩選</Button>,
                    <Button
                        icon={<FileAddOutlined />}
                        onClick={() => setModal(1)}
                    >
                        報表製作
                    </Button>,
                    <Button
                        type="primary"
                        icon={<PlusOutlined />}
                        onClick={() => navigate('/points/add')}
                    >
                        新增據點
                    </Button>
                ] : [
                    <Button
                        icon={<FilterOutlined />}
                        onClick={() => setFilterVisible(true)}
                    >篩選</Button>
                ]}
                breadcrumb={<BreadCrumb />}
            >
            </PageHeader>
            {
                filterObjectLabel && (
                    <div className='filter_tabs_block' style={{ padding: 24, paddingBottom: 0 }}>
                        {
                            Object.keys(filterObjectLabel).map(label => (
                                <>
                                    {
                                        filterObjectLabel[label].map(l =>
                                        (<p className='tab' onClick={() => {
                                            let filter = { ...filterObject }, filterLabel = { ...filterObjectLabel }
                                            let index = filterLabel[label].indexOf(l)
                                            let value = filter[label].filter((Label, i) => i !== index)
                                            filterLabel[label] = filterLabel[label].filter(Label => Label !== l)
                                            if (value.length === 0) {
                                                delete filter[label]
                                            } else {
                                                filter[label] = value
                                            }
                                            if(!filter.service_point_name) setOptionList(ServicePoint[0]['children'])
                                            setFilterObject(filter)
                                            setFilterObjectLabel(filterLabel)
                                        }}>
                                            {filterName[label]}：{l}
                                            <CloseOutlined style={{ color: "rgba(0, 0, 0, 0.45)", fontSize: 14, paddingLeft: 4 }} />
                                        </p>))
                                    }
                                </>
                            ))
                        }
                    </div>
                )
            }

            <div className='table_block'>
                <Table
                    columns={columns}
                    rowKey={record => record.row_id}
                    dataSource={data?.Data}
                    pagination={data && data.PageCount > 1 ? {
                        current: filterObject.page_index + 1,
                        total: data.RowsCount,
                        showTotal: (total, range) =>
                            `${range[0]}-${range[1]} 列（共${total}  列）`,
                    } : false}
                    // loading={loading}
                    style={{ padding: "12px 0", minHeight: 400 }}
                    onRow={(record, rowIndex) => {
                        return {
                            onClick: event => { navigate(`/points/${record.row_id}`, { state: filterObject }) },
                        };
                    }}
                    onChange={(pagination) => onChangeFilter({ page_index: pagination.current - 1 })}
                />
            </div>
            <Modal
                title='報表製作'
                visible={modal}
                onOk={() => statementForm.current.submit()}
                okText={`製作${modalBtn ? "中..." : ""}`}
                okButtonProps={{ disabled: modalBtn }}
                onCancel={() => {
                    statementForm.current.resetFields()
                    setModal(null)
                }}
                width={416}
            >
                <Form
                    name="basic"
                    layout="vertical"
                    ref={statementForm}
                    onFinish={(val) => { exportReport(val) }}
                >

                    <Form.Item
                        label={<><span>服務據點</span><span style={{ paddingLeft: 4, color: "rgba(0, 0, 0, 0.45)", fontSize: 14 }}>(可複選)</span></>}
                        name="service_point_row_id"
                        rules={[{ required: true, message: "請選擇服務據點！" }]}
                    >
                        <Cascader
                            placeholder='選擇服務據點'
                            multiple
                            options={ServicePoint} showSearch={(inputValue, path) => {
                                path.some((option) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);

                            }} />
                    </Form.Item>
                    <Form.Item
                        name="export_type"
                        label={<><span>匯出內容</span><span style={{ paddingLeft: 4, color: "rgba(0, 0, 0, 0.45)", fontSize: 14 }}>(可複選)</span></>}
                        rules={[{ required: true, message: "請選擇匯出內容！" }]}
                    >
                        <Checkbox.Group onChange={(val) => {
                            // statementForm.current.setFieldsValue({export_type:val})
                        }}>
                            <Checkbox value='0'>基本資料</Checkbox>
                            <Checkbox value='1'>技師</Checkbox>
                            <Checkbox value='2'>車籍</Checkbox>
                        </Checkbox.Group>

                    </Form.Item>
                </Form>
            </Modal>
            {/* 篩選視窗 */}
            <Drawer
                title="篩選"
                placement="right"
                closable={false}
                extra={
                    (<CloseOutlined
                        style={{ cursor: "pointer", color: "rgba(0, 0, 0, 0.45)" }}
                        onClick={() => {
                            submitRef.current.resetFields()
                            submitRef.current.setFieldsValue(filterObject)
                            setFilterVisible(false)
                        }}
                    />)
                }
                footer={
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button type="text" style={{ marginRight: 16 }} onClick={() => {
                            submitRef.current.resetFields()
                            submitRef.current.setFieldsValue(filterObject)
                            setFilterVisible(null)
                        }
                        }>取消</Button>
                        <Button type="primary"
                            htmlType="submit"
                            // disabled={btnDisabled}
                            onClick={() => {
                                submitRef.current.submit();
                            }}
                        >篩選</Button>
                    </div>
                }
                visible={filterVisible}>
                <Form
                    ref={submitRef}
                    name="basic"
                    layout="vertical"
                    onFinish={(value) => {
                        let dataObj = {}
                        let labelObj = {}
                        for (let key in value) {
                            if (value[key]) {
                                if (key === "service_point_row_id" || key === 'category') {
                                    let arr = value[key].split(",")
                                    labelObj[key] = [arr[1]]
                                    dataObj[key] = [arr[0]]
                                } else if (key === 'service_type') {
                                    let val = [], text = []
                                    value[key].forEach(v => {
                                        let arr = v.split(",")
                                        val.push(arr[0])
                                        text.push(arr[1])
                                    })
                                    labelObj[key] = text
                                    dataObj[key] = val
                                }
                                else {
                                    labelObj[key] = [value[key]]
                                    dataObj[key] = [value[key]]
                                }
                            }
                        }
                        onChangeFilter(dataObj)
                        setFilterObjectLabel(labelObj)
                        setFilterVisible(false)
                    }}
                    autoComplete="off"
                >
                    <Form.Item
                        label="據點名稱"
                        name="service_point_name"
                    >
                    <AutoComplete
                            options={(optionList || []).map(d => ({
                                value: d.name,
                                label: d.name,
                            }))}
                            onSearch={(searchText)=>{
                                // console.log(ServicePoint)
                                let search = searchText? [...ServicePoint[0]['children']].filter(d=> d.name.toLowerCase().indexOf(searchText.toLowerCase())>-1):[...ServicePoint[0]['children']]
                                setOptionList(search)
                            }}
                            allowClear
                            placeholder='請選擇'
                        />
                    
                        {/* <Select
                            allowClear
                            showSearch
                            placeholder="請選擇"
                        >
                            {
                                optionList.map(o => (<Option
                                    key={`${o.row_id}`}
                                    value={`${o.row_id},${o.name}`}
                                >{`${o.name}`}</Option>))
                            }
                        </Select> */}
                    </Form.Item>
                    <Form.Item
                        label="負責人"
                        name="responsible_person"
                    >
                        <Input placeholder='請輸入' />
                    </Form.Item>
                    <Form.Item
                        label="聯絡人"
                        name="contact_person"
                    >
                        <Input placeholder='請輸入' />
                    </Form.Item>
                    <Form.Item
                        label="聯絡電話"
                        name="contact_phone"
                    >
                        <Input placeholder='請輸入' />
                    </Form.Item>
                    <Form.Item
                        label="錄用分類"
                        name="category"
                    >
                        <Select placeholder='請選擇'>
                            <Option value='1,正式'>正式</Option>
                            <Option value='0,支援'>支援</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="服務類別"
                        name="service_type"
                    >
                        <Select mode="multiple" placeholder='請選擇'>
                            <Option value='1,運送車'>運送車</Option>
                            <Option value='2,道路救援'>道路救援</Option>
                        </Select>
                    </Form.Item>
                </Form>
            </Drawer>
        </div>
    );
}

export default Index;

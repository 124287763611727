import React, { useEffect, useState, useRef } from 'react'
import { PageHeader, Form, Input, InputNumber, Button, Select, Upload, DatePicker, message, Radio, Popconfirm } from 'antd'
import BreadCrumb from '../../shared/BreadCrumb'
import { UploadOutlined, PlusOutlined, RightOutlined, UnorderedListOutlined } from '@ant-design/icons'
import { useNavigate, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { AddCustomerPricingModule, UpdateStationPriceNote, CopyCustomerPricingModule } from '../../../api/Customer'
import CustomAutoComplete from '../../shared/component/CustomAutoComplete'
import CustomCascader from '../../shared/component/CustomCascader'
import moment from 'moment'
const { Option } = Select
const { TextArea } = Input
const { RangePicker } = DatePicker;
const Add = ({ pageTitle, ContainerWidth }) => {
  const submitForm = useRef()

  // const [pointOptions, setPointOptions] = useState([])
  const [caseTypeOptions, setCaseTypeOptions] = useState([])
  const [durationValidation, setDurationValidation] = useState(null)
  const [currentStep, setCurrentStep] = useState(0)
  const [remark, setRemark] = useState("")
  const [successSubmit, setSuccessSubmit] = useState(null)
  const [PayType, setPayType] = useState("check")
  let navigate = useNavigate()
  const { state } = useLocation()
  let CaseType = useSelector(state => state.caseType)
  useEffect(() => {
    if (CaseType !== null) {
      setCaseTypeOptions(CaseType)
    }
  }, [CaseType])
  let historyHref = state.subTab ? `/customer/lists/${state.customerId}/details` : `/customer/lists/${state.customerId}`
  useEffect(() => {
    if (state && state.data) {
      submitForm.current.setFieldsValue(state.data)
    } else {
      submitForm.current.setFieldsValue({ mode: "001", receive_mode: "01", pay_type: "check", tax_month: "01", tax_included: "1" })

    }
    // document.title = pageTitle
  }, [])

  const OnFinish = async (value) => {
    value.customer_row_id = state.customerId
    value.doc = value.contractFile.file
    value.start_date = moment(value.contractPeriod[0]).format("YYYY-MM-DD")
    value.end_date = moment(value.contractPeriod[1]).format("YYYY-MM-DD")
    value.status = 1
    value.doc = value.contractFile.file.originFileObj
    if (value.receipt_county && value.receipt_county.length > 0) {
      value.receipt_address_county = value.receipt_county[0]
      value.receipt_address_town = value.receipt_county[1]
    }

    const formdata = new FormData()
    for (let key in value) {
      formdata.append(key, value[key] ? value[key] : '');
    }
    let result = await AddCustomerPricingModule(formdata)
    if (result.Result.Success) {
      let data
      let status = false
      if (state && state.data) {
        let copy = await CopyCustomerPricingModule(state.data.row_id, result.Result.Data.row_id)
        if (copy && !copy.Error) {
          status = true
        } else {
          status = false
        }
      } else {
        status = true
      }
      if (status) {
        data = { ...result.Result.Data, name: value.name }
        setSuccessSubmit(data)
        setCurrentStep(1)
        submitForm.current.resetFields()
      }
    } else {
      setDurationValidation(result.Result.ErrorMsg)
    }

  }
  return (
    <div className='container' style={ContainerWidth}>
      <PageHeader
        title="新增計價模組"
        onBack={() => currentStep === 0 ? navigate(historyHref, { state }) : setCurrentStep(currentStep - 1)}
        className="site-page-header"
        breadcrumb={<BreadCrumb Info={state.BreadcrumbInfo} addText='新增計價模組' />}
      >
      </PageHeader>
      <div className='step_block'>
        <div >
          <Form
            ref={submitForm}
            name="basic"
            layout="vertical"
            onFinish={OnFinish}
          >
            <div style={{ minHeight: "calc(100vh - 240px)" }}>
              <div className='step_content'>
                {
                  currentStep === 0 ? (<>
                    <Form.Item
                      label="計價方式"
                      name="mode"
                      rules={[{ required: true, message: '請選擇計價方式!' }]}
                    >
                      <Radio.Group
                        buttonStyle="solid"
                      // onChange={(e) => setTransportType(e.target.value)}
                      >
                        <Radio.Button value="001">起迄點</Radio.Button>
                        <Radio.Button value="002">里程</Radio.Button>
                        <Radio.Button value="003">區域階梯式</Radio.Button>
                        <Radio.Button value="004">包月</Radio.Button>
                      </Radio.Group>
                    </Form.Item>
                    <div style={{ display: "flex" }}>
                      <Form.Item
                        label="合約名稱"
                        name="name"
                        rules={[{ required: true, message: '請輸入合約名稱!' }]}
                      >
                        <Input allowClear placeholder='請輸入合約名稱' style={{ width: "304px" }} />
                      </Form.Item>
                      <Form.Item
                        name="contractFile"
                        label="合約電子檔"
                        className='pricingModule_contractFile'
                        style={{ marginLeft: 16, marginBottom: 0 }}
                        extra="僅接受 jpg, png, pdf 之檔案格式類型"
                        rules={[{ required: true, message: '請上傳合約電子檔！' }]}
                      // help={durationValidation == null ? '' : durationValidation !== '服務案型期間重複' ? durationValidation : ''}
                      >
                        <Upload
                          name="logo"
                          accept="image/png, image/jpeg, image/jpg, application/pdf"
                          customRequest={({ onSuccess }) => onSuccess("ok")}
                          onChange={({ file, fileList }) => {
                            submitForm.current.value = file
                          }}
                        >
                          <Button icon={<UploadOutlined />}>點擊上傳合約</Button>
                        </Upload>
                      </Form.Item>
                    </div>
                    <div style={{ display: "flex" }}>
                      <Form.Item
                        label="合約期間"
                        name="contractPeriod"
                        rules={[{ required: true, message: '請選擇合約期間！' }]}
                        style={{ marginRight: 16 }}
                        validateStatus={durationValidation === null ? "" : durationValidation === '服務案型期間重複' ? "error" : "success"}
                        help={durationValidation == null ? '' : durationValidation === '服務案型期間重複' ? '服務案型期間重複' : ''}

                      >
                        <RangePicker style={{ width: "304px" }} />
                      </Form.Item>
                      <Form.Item
                        label="服務案型"
                        name="case_type"
                        rules={[{ required: true, message: '請選擇服務案型！' }]}
                      >
                        <Select
                          // mode="multiple"
                          allowClear
                          style={{ width: "304px" }}
                          placeholder="請選擇該計價方案適用的服務類型"
                          defaultValue={[]}
                        // onChange={handleChange}
                        >
                          {
                            caseTypeOptions.map(c => (<Option key={`${c.Code}_${c.Name}`} value={c.Code}>{c.Name}</Option>))
                          }
                        </Select>
                      </Form.Item>
                    </div>
                    <div style={{ display: "flex" }}>
                      <Form.Item
                        label="發票統編"
                        name="receipt_no"
                        rules={[{ required: true, message: '請輸入公司統編！' },
                        {
                          pattern: /^[\d]{8}$/,
                          message: "請輸入8位數之公司統編",
                        }]}
                      >
                        <Input allowClear placeholder='例：12345678' style={{ width: "304px" }} />
                      </Form.Item>
                      <Form.Item
                        label="發票抬頭"
                        name="receipt_title"
                        rules={[{ required: true, message: '請輸入發票抬頭！' }]}
                      >
                        <Input allowClear placeholder='例：〇〇股份有限公司' style={{ width: "304px", margin: "0 16px" }} />
                      </Form.Item>
                      <Form.Item
                        label="公司負責人姓名"
                        name="company_head"
                        rules={[{ required: true, message: '請輸入公司負責人姓名！' }]}
                      >
                        <Input allowClear placeholder='例：〇〇〇' style={{ width: "304px" }} />
                      </Form.Item>
                    </div>
                    <div style={{ display: 'flex' }}>
                      <Form.Item
                        label="紙本發票收件地址"
                        name="receipt_county"
                      // rules={[{ required: true, message: "請選擇紙本發票收件地址!" }]}
                      >
                        <CustomCascader
                          defaultValue={state.data ? state.data.receipt_county : ""}
                          style={{ width: "304px" }}
                          OnChange={(val) => {
                            submitForm.current.setFieldsValue({ receipt_county: val })
                          }}
                        />
                      </Form.Item>
                      <div>
                        <div style={{ height: 34 }}></div>
                        <Form.Item
                          label=""
                          name="receipt_address"
                          style={{ margin: "0 16px" }}
                        // rules={[{ required: true, message: '請輸入公司地址！' }]}
                        >
                          <Input allowClear placeholder='請直接輸入地址' style={{ width: "304px" }} />
                        </Form.Item>
                      </div>
                      <Form.Item
                        label="發票收件E-mail"
                        name="receipt_email"
                      >
                        <Input allowClear placeholder='example' style={{ width: "304px" }} />
                      </Form.Item>
                    </div>
                    <div style={{ display: "flex" }}>
                      <Form.Item
                        label="請款日期"
                        name="receive_day"
                        rules={[{ required: true, message: '請輸入請款日期!' }]}
                      >
                        <InputNumber
                          min={1} max={31}
                          allowClear placeholder='請輸入數字，例：15' style={{ width: "304px" }}
                          addonAfter="號"
                          addonBefore='當月'
                        // addonBefore={<Form.Item name="receive_mode" noStyle>
                        //   <Select >
                        //     <Option value="01">當月</Option>
                        //   </Select>
                        // </Form.Item>} 

                        />
                      </Form.Item>
                      <Form.Item
                        label="結算日期"
                        name="settlement_day"
                        rules={[{ required: true, message: '請輸入結算日期!' }]}
                        style={{ margin: '0 16px' }}

                      >
                        <InputNumber min={1} max={31} allowClear placeholder='請輸入數字，例：15' style={{ width: "304px" }}
                          addonAfter="號" addonBefore='每月' />
                      </Form.Item>

                    </div>
                    <div style={{ display: "flex" }}>
                      <Form.Item
                        label="付款方式"
                        name="ticket_month"
                        rules={[{ required: true, message: '請輸入付款方式!' }]}
                      >
                        <Input
                          allowClear
                          placeholder={PayType === 'remit' ? '請輸入匯款備註' : '請輸入數字，例：15'}
                          style={{ width: "304px" }}
                          addonBefore={<Form.Item name="pay_type" noStyle

                          >
                            <Select onChange={(val) => setPayType(val)}>
                              <Option value="check">支票</Option>
                              <Option value="remit">匯款</Option>
                            </Select>
                          </Form.Item>} addonAfter={PayType === "check" ? "個月支票" : false} />
                      </Form.Item>
                      <Form.Item
                        label="發票設定"
                        name="tax_month"
                        rules={[{ required: true, message: '請選擇發票設定!' }]}
                        style={{ margin: '0 16px' }}
                      >
                        <Radio.Group
                          buttonStyle="solid"
                        // onChange={(e) => setTransportType(e.target.value)}
                        >
                          <Radio.Button value="01">帳款發生月份</Radio.Button>
                          <Radio.Button value="02">請款月份</Radio.Button>
                        </Radio.Group>
                      </Form.Item>
                      <Form.Item
                        label=""
                        name="tax_included"
                      >
                        <Radio.Group
                          buttonStyle="solid"
                        // onChange={(e) => setTransportType(e.target.value)}
                        >
                          <div style={{ height: 34 }}></div>
                          <Radio.Button value="1">含稅</Radio.Button>
                          <Radio.Button value="0">未稅</Radio.Button>
                        </Radio.Group>
                      </Form.Item>
                    </div>
                  </>) :
                    (<>
                      <div style={{ fontSize: 30 }}>
                        計價模組
                        <span style={{ color: "#1890FF", fontSize: 30 }}>  #{successSubmit.id} / {successSubmit.name} </span>
                        已新增
                      </div>
                      <div style={{ color: "rgba(0, 0, 0, 0.45)", marginTop: 8 }}>模組基本資料已建立，請注意尚未設定金額的模組將無法使用，請先點擊查看模組資料並確認金額設定</div>
                      <TextArea
                        placeholder="額外的模組說明請寫在此......"
                        autoSize={{ minRows: 2, maxRows: 4 }}
                        style={{ width: 395, marginTop: 8 }}
                        onChange={(e) => setRemark(e.target.value)}
                      />
                      <Button
                        style={{ display: "block", marginTop: 8 }}
                        disabled={remark !== "" ? "" : "disabled"}
                        onClick={() => {
                          let param = `row_id=${successSubmit.row_id}&note=${remark}`
                          UpdateStationPriceNote(param).then(data => {
                            if (data.Result.Success) {
                              setRemark("")
                              submitForm.current.resetFields()
                              message.success('已新增備註')
                            }
                          })
                        }}
                      >新增備註</Button>
                      <div style={{ marginTop: 32 }}>
                        <Button
                          type="primary"
                          icon={<PlusOutlined />}
                          onClick={() => setCurrentStep(0)}
                        >新增其他模組</Button>
                        <Button
                          icon={<RightOutlined />}
                          onClick={() => navigate(`/customer/lists/${state.customerId}/details`,
                            {
                              state: {
                                tab: state.tab,
                                page: state.current,
                                customerId: state.customerId,
                                id: successSubmit.row_id,
                                BreadcrumbInfo: state.BreadcrumbInfo,
                                customer_id: state.id,
                                customer_name: state.name,
                              }
                            })}
                          style={{ margin: "0 8px" }}>模組內容</Button>
                        <Button
                          icon={<UnorderedListOutlined />}
                          onClick={() => navigate(`/customer/lists/${state.customerId}`, { state })}
                        > 回列表 </Button>
                      </div>
                    </>)
                }
              </div>
            </div>
            {
              currentStep === 0 && (<div style={{ background: "white", marginTop: 16, width: "100%", padding: "20px", display: "flex", justifyContent: "flex-end" }}>
                <Popconfirm
                  style={{ width: 410 }}
                  placement="top"
                  title={
                    <div>
                      <div>
                        <strong>新增還沒完成喔</strong>
                      </div>
                      <div style={{ fontSize: 14 }}>
                        剛剛輸入的所有資訊將不被保存。
                      </div>
                    </div>
                  }
                  onCancel={() => navigate(`/customer/lists/${state.customerId}`, { state })}
                  okText="不，我要留下"
                  cancelText="對，我要離開"
                >
                  <Button type="text" style={{ marginRight: 24 }} >
                    關閉
                  </Button>
                </Popconfirm>
                <Button type="primary" icon={<PlusOutlined />}
                  htmlType="submit">新增</Button>
              </div>)
            }
          </Form>
        </div>
      </div>
    </div>
  );
}
export default Add;

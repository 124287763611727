import React, { useState, useEffect } from "react";
import { Card, Tag, Space, Row, Col, Divider } from "antd";
import { LeftOutlined, PhoneOutlined } from "@ant-design/icons";
import CustomTable from "../../../components/shared/component/Card"

const Status = ({ selectedData, SetCurrentTab }) => {
    const [driverList, setDriverList] = useState([])
    useEffect(() => {
        let list = selectedData.not_yet_driver_list.map(l => {
            let phone = ["phone_number", "emergency_contact1", "emergency_contact2"]
            phone.forEach(p => {
                let phone_key = p === "phone_number" ? p : `${p}_tel`
                let key = `${p}_text`
                if (p === "phone_number") {
                    l[key] = { type: "icons", link: 'tel', icon: <PhoneOutlined />, label: l[p] }
                } else {
                    if (!l[p] && !l[phone_key]) {
                        l[key] = ''
                    } else if (l[p] && !l[phone_key]) {
                        l[key] = l[p]
                    } else if (l[p] && l[phone_key]) {
                        l[key] = { type: "icons", link: 'tel', icon: <PhoneOutlined />, title: l[p], label: l[phone_key] }
                    } else {
                        l[key] = { type: "icons", link: 'tel', icon: <PhoneOutlined />, label: l[phone_key] }
                    }
                }
            })
            return l
        })
        setDriverList(list)

    }, [selectedData])
    return (
        <div>
            <Space>
                <LeftOutlined
                    style={{ display: "block", marginRight: 24 }}
                    onClick={() => SetCurrentTab("1")}
                />
                <p style={{ fontSize: 38, fontWeight: "bold" }}>{selectedData.type}</p>
            </Space>
            <Card
                title="出勤統計"
                bordered={false}
                style={{ margin: "24px 0" }}
            >
                <Row>
                    <Col className="gutter-row" span={8}>
                        <div style={{ color: "rgba(0,0,0,0.4)" }}>應上班人數</div>
                        <div style={{ fontSize: "24px" }}>{selectedData.need_check_in_count}</div>
                    </Col>
                    <Col className="gutter-row" span={8}>
                        <Space>
                            <Divider type="vertical" style={{ height: 32 }} />
                            <div>
                                <div style={{ color: "rgba(0,0,0,0.4)" }}>已打卡</div>
                                <div style={{ fontSize: 24 }}>{selectedData.has_check_in_count}</div>
                            </div>
                        </Space>
                    </Col>
                    <Col className="gutter-row" span={8}>
                        <Space>
                            <Divider type="vertical" style={{ height: 32 }} />
                            <div>
                                <div style={{ color: "rgba(0,0,0,0.4)" }}>尚未打卡</div>
                                <div style={{ fontSize: 24 }}>{selectedData.not_yet_check_in_count}</div>
                            </div>
                        </Space>
                    </Col>
                </Row>
            </Card>
            {
                driverList.map((d, i) => (
                    <Card
                        style={{ marginTop: i === 0 ? 0 : 16 }}
                        title={d.driver_name}
                    >
                        <CustomTable header={[
                            { key: "service_point_name", name: "據點名稱" },
                            { key: "driver_id", name: "技師編號" },
                            { key: "id", name: "身分證號" },
                            { key: "birthday", name: "生日" },
                            { key: "phone_number_text", name: "手機號碼" },
                            { key: "emergency_contact1_text", name: "緊急聯絡人 - 1" },
                            { key: "emergency_contact2_text", name: "緊急聯絡人 - 2" },
                        ]}
                            data={d}
                        >
                        </CustomTable>
                    </Card>
                ))
            }
        </div>
    );
};

export default Status;

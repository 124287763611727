import React, { useState, useEffect } from 'react'
import BreadCrumb from '../../components/shared/BreadCrumb'
import { PageHeader, Button, Select, Modal, DatePicker, Table, Tabs, Badge } from 'antd'
import { PlusOutlined,ExclamationCircleOutlined } from '@ant-design/icons'
import { useNavigate,useLocation } from 'react-router-dom'
import { UserList,DelAccount,DisabledAccount,EnableAccount } from '../../api/Member'
import { useSelector } from 'react-redux'
import { RouteName } from '../../util/route'

const { TabPane } = Tabs
const { confirm } = Modal
const { RangePicker } = DatePicker
const Account = ({ pageTitle, ContainerWidth }) => {
  const [filterVisible, setFilterVisible] = useState(false)
  const { state,pathname } = useLocation()
  const routeId = RouteName(pathname)
  let UserInfo = useSelector(state => state.UserInfo) 
  let UserPermission = UserInfo? UserInfo.permission.route: null
  let navigate = useNavigate()
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 })
  const [currentTab, setCurrentTab] = useState("1")
  const [allData, setAllData] = useState(null)
  const [data, setData] = useState([])
  const [filterData, setFilterData] = useState([])
  const [tabs, setTabs] = useState([
    { id: "tab1", name: "使用中", count: null, status: "1" },
    { id: "tab2", name: "停用", count: null, status: "2" },
  ]);
  const columns = UserPermission && UserPermission[routeId] ===2?[
    { title: '帳號', dataIndex: 'account', },
    { title: '姓名', dataIndex: 'name' },
    { title: 'E-mail', dataIndex: 'mail' },
    { title: '權限標籤', dataIndex: 'permission_tag_name' },
    {
      title: '狀態', dataIndex: 'status', width: '10%',
      render: status => status === 2 ? (<div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ width: 6, height: 6, borderRadius: "50%", background: "rgba(217, 217, 217, 1)", marginRight: 8 }}></div>
        <p>停用</p>
      </div>) :
        (<div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ width: 6, height: 6, borderRadius: "50%", background: "#52C41A", marginRight: 8 }}></div>
          <p>使用中</p>
        </div>)
    },
    {
      title: '操作', dataIndex: '', width: '15%',
      render: (record, _) => (
        <div onClick={(e) => e.stopPropagation()}>
          <Button type='link' onClick={()=>showPromiseConfirm(_.status===1?"disabled":"enable", record)}>{_.status === 1 ? "停用" : "啟用"}</Button>
          <Button type='link' onClick={()=>showPromiseConfirm("delete", record)}>刪除</Button>
        </div>)
    }]:[
      { title: '帳號', dataIndex: 'account', },
      { title: '姓名', dataIndex: 'name' },
      { title: 'E-mail', dataIndex: 'mail' },
      { title: '權限標籤', dataIndex: 'permission_tag_name' },
      {
        title: '狀態', dataIndex: 'status', width: '10%',
        render: status => status === 2 ? (<div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ width: 6, height: 6, borderRadius: "50%", background: "rgba(217, 217, 217, 1)", marginRight: 8 }}></div>
          <p>停用</p>
        </div>) :
          (<div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ width: 6, height: 6, borderRadius: "50%", background: "#52C41A", marginRight: 8 }}></div>
            <p>使用中</p>
          </div>)
      }
  ]

  const showPromiseConfirm = (type,obj) => {
    let status = obj.status
    confirm({
      icon: <ExclamationCircleOutlined />,
      title: `${type==='delete'? '刪除後資料將無法復原，確定刪除嗎？' :status===1?'停用後將無法使用該帳號登入，日後可再次啟用':'啟用後可以使用該帳號登入，日後可再次停用'}`,
      onOk() { 
        if(type === 'delete'){
          DelAccount(obj.id).then(res=> {
            if(!res.Error) fetchAPI()
          })
        }else{
          if(type==='disabled'){
            DisabledAccount(obj.id).then(res=> {
              if(!res.Error) fetchAPI()
            })
          }else{
            EnableAccount(obj.id).then(res=> {
              if(!res.Error) fetchAPI()
            })
          }
        }
      },
      onCancel() {},
      okText:`${type==='delete'? '確定刪除':status===1? '確定停用':'確定啟用'}`,
      cancelText:'取消'
    });
  };
  const fetchAPI = ()=>{
    UserList(0).then(res => {
      if (!res.Error) {
        let Result = res.Result.Data.reverse()
        let filter = Result.filter(r=>r.status == currentTab)
        tabs.map(t=>{
          t.count= Result.filter(r=>r.status == t.status).length
          return t
        })
        setAllData(Result)
        setData(filter)
      }
    })
  }
  useEffect(() => {
    fetchAPI()
   
  }, [])
  useEffect(() => {
    let container = document.querySelector(".container")
    container.scrollTo(0, 0)
  }, [pagination])
  useEffect(()=>{
    if(allData){
      let filter = [...allData].filter(r=>r.status == currentTab)
      setData(filter)
    }
  },[currentTab])
  return (
    <div className="container" style={ContainerWidth}>
      <PageHeader
        style={{ background: "white", width: "100%" }}
        title="帳號管理"
        className="site-page-header"
        extra={UserPermission && UserPermission[routeId] ===2?
        [
          <Button type="primary" icon={<PlusOutlined />}
            onClick={() => navigate('/privilege/account/add')}
          > 帳號新增 </Button>
        ]:[]}
        breadcrumb={<BreadCrumb />}
      >
      </PageHeader>
      <div className='table_block'>
      <Tabs defaultActiveKey={currentTab}>
            {tabs.map((t) => (
              <TabPane
                key={t.name}
                tab={
                  <div onClick={(e) => setCurrentTab(t.status)}>
                    <span>{t.name}</span>
                    <Badge
                      className="site-badge-count-109"
                      overflowCount={999}
                      count={t.count}
                      style={{
                        backgroundColor:
                          currentTab === t.id ? "#E6F7FF" : "#F0F0F0",
                        color:
                          currentTab === t.id ? "#1890FF" : "rgba(0,0,0,0.45)",
                        marginLeft: "4px",
                      }}
                    />
                  </div>
                }
              />
            ))}
          </Tabs>
        <Table
          columns={columns}
          rowKey={record => record.row_id}
          // rowSelection={selectedRowKeys}
          dataSource={data}
          pagination={data.length > 10 ? {
            total: data.length,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} 列（共${total}  列）`,
          } : false}
          onChange={(pagination) => setPagination(pagination)}
          // pagination={data.length > 10 ? pagination : false}
          // loading={loading}
          style={{ padding: "12px 0", minHeight: 400 }}
          onRow={(record, rowIndex) => {
            return {
              onClick: event => { navigate(`/privilege/account/${record.id}`) },
            };
          }}
        />
      </div>
      {/* 篩選視窗 */}
      <Modal
        title={"篩選"}
        transitionName="ant-slide-right"
        style={{ left: "unset", top: 0, right: "-100%", paddingBottom: 0, margin: 0 }}
        bodyStyle={{ height: "calc(100vh - 108px)", overflow: "scroll" }}
        visible={filterVisible}
        onOk={() => setFilterVisible(false)}
        okText={"篩選"}
        onCancel={() => setFilterVisible(false)}
        width={284}
      >
        <div style={{ marginBottom: 24 }}>
          <div>運送日期</div>
          <RangePicker style={{ width: "100%", marginTop: 8 }} />
        </div>
        {/* <div style={{ marginBottom: 24 }}>
                    <div>客戶別</div>
                    <Select
                        mode="multiple"
                        allowClear
                        style={{ width: '100%', marginTop: 8 }}
                        placeholder="請選擇客戶別"
                        defaultValue={[]}
                    // onChange={handleChange}
                    >
                        {children}
                    </Select>
                </div> */}
        <div style={{ marginBottom: 24 }}>
          <div>執行技師</div>
          {/* <Select
                        mode="multiple"
                        allowClear
                        style={{ width: '100%', marginTop: 8 }}
                        placeholder="請選擇執行技師"
                        defaultValue={[]}
                    // onChange={handleChange}
                    >
                        {children}
                    </Select> */}
        </div>
        <div style={{ marginBottom: 24 }}>
          <div>車牌</div>
          {/* <Select
                        mode="multiple"
                        allowClear
                        style={{ width: '100%', marginTop: 8 }}
                        placeholder="請選擇車牌"
                        defaultValue={[]}
                    // onChange={handleChange}
                    >
                        {children}
                    </Select> */}
        </div>
        <div style={{ marginBottom: 24 }}>
          <div>運送起點</div>
          {/* <Select
                        mode="multiple"
                        allowClear
                        style={{ width: '100%', marginTop: 8 }}
                        placeholder="請選擇運送起點"
                        defaultValue={[]}
                    // onChange={handleChange}
                    >
                        {children}
                    </Select> */}
        </div>
        <div style={{ marginBottom: 24 }}>
          <div>運送迄點</div>
          {/* <Select
                        mode="multiple"
                        allowClear
                        style={{ width: '100%', marginTop: 8 }}
                        placeholder="請選擇運送迄點"
                        defaultValue={[]}
                    // onChange={handleChange}
                    >
                        {children}
                    </Select> */}
        </div>
      </Modal>
    </div>
  );
}

export default Account;

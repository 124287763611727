import React, { useState, useRef, useEffect } from 'react';
import {  Form, Input, Table, Select, Button, Drawer, AutoComplete } from 'antd'
import { PlusOutlined, CloseOutlined } from '@ant-design/icons';
// import { useSelector } from 'react-redux'
import { CloseCase, CloseModifyFee } from '../../../api/Case'
import { GetFeeList } from '../../../api/Code'

const { Option } = Select

const Caseclosure = ({ SetVisible, visible, caseData, onConfirm }) => {
    const submitRef = useRef()
    // const ExtraPriceItem = useSelector((state) => console.log(state))
    const [dataSource, setDataSource] = useState(null)
    const [priceItemOption, setPriceItemOption] = useState(null)
    useEffect(() => {
        GetFeeList().then(res => {
            if (!res.Error) setPriceItemOption(res.Result)
        })
        let ini = [{ key: 1, item: "", receive_money: 0, self_pay: 0, service_cost: 0 }]
        // console.log(caseData.project_type)
        // if (caseData.project_type !== "") {
                let obj={}
                let count ={item:"費用小計",receive_money:0, self_pay: 0, service_cost: 0 }
                let items =  caseData.pricing_items&& caseData.pricing_items.length>0? [...caseData.pricing_items]:ini
                items.map((item,i)=>{
                    Object.keys(item).forEach((d,index)=>{
                        if(d!=="key"){
                            if(d.indexOf('item') === -1 ){
                                obj[`${d}_${ i + 1}`] = item[d]?item[d]:0
                                count[d]+= item[d]
                            }else{
                                //收費項目
                                obj[`${d}_${ i + 1}`] = item[d]? item[d] : null
                            }
                        }
                    })
                    item.key =  i + 1
                    return item
                })
                items.push({...count})
                setDataSource(items)
                setTimeout(()=>{submitRef.current.setFieldsValue(obj)},500)
            //     }
            // })
        // }

    }, [])
    const onInputChange = (e, key, record, index) => {
        let change_val = e.target.value ? parseFloat( e.target.value ):0;
        let new_data = [...dataSource].filter((d,i)=> i !== dataSource.length-1)
        let total ={item:"費用小計",receive_money:0, self_pay: 0, service_cost: 0 }
        new_data.map(d => {
            if (d.key === record.key) {
                d[key] = change_val;
            }
            Object.keys(d).forEach(k=>{
                if(k !=="item" && k !=="key" ){
                    total[k]+=d[k]

                }
            })
            return d;
        })
        new_data.push(total)
        setDataSource(new_data)
    }
    
    const columns = [
        { title: "#", dataIndex: 'key', key: 'key', width: '5%' },
        
        {
            title: "收費項目",
            dataIndex: 'item',
            key: 'item',
            width: '30%',
            render: (type, record) => (
                <>
                {
                    record.key && !record.case_fee_row_id? (
                        <Form.Item
                        label=""
                        name={`item_${record.key}`}
                        rules={[{ required: true, message: '請選擇收費項目!' }]}
                        style={{ marginBottom: 0 }}
                    >
                        <Select
                            placeholder="請選擇"
                            onChange={(e) => {
                                let new_dataSource = dataSource?dataSource:[]
                                new_dataSource.map(data => {
                                    if (data.key === record.key) {
                                        data.item = e
                                    }
                                    return data
                                })
                                setDataSource(new_dataSource)
                            }}
                            allowClear
                        >
                            {
                                priceItemOption&&priceItemOption.map(p => (<Option key={`${p.Code}_${p.Name}`} value={p.Name}>{p.Name}</Option>))
                            }
                        </Select>
                    </Form.Item>
                    ):(<span>{type}</span>)
                }
                    
                </>)
        },
        {
            title: '請款費用',
            dataIndex: 'receive_money',
            key: 'receive_money',
            render: (amount, record) => (<>
            {
                record.key? (<Form.Item
                    label=""
                    name={`receive_money_${record.key}`}
                    rules={[{ required: true, message: '請填寫請款費用!' }]}
                    style={{ marginBottom: 0 }}
                >
                    <Input prefix="$" onChange={(e) => onInputChange(e, "receive_money", record)} defaultValue={0} />

                </Form.Item>):(`$ ${amount}`)
            }
                
            </>)
        },
        {
            title: '自費費用',
            dataIndex: 'self_pay',
            key: 'self_pay',
            render: (amount, record) => (<>
            {
                record.key? (<Form.Item
                    label=""
                    name={`self_pay_${record.key}`}
                    rules={[{ required: true, message: '請填寫自費費用!' }]}
                    style={{ marginBottom: 0 }}
                >
                    <Input prefix="$" defaultValue={0} onChange={(e) => onInputChange(e, "self_pay", record)} />

                </Form.Item>):(`$ ${amount}`)
            }
            </>)
        },
        {
            title: '服務費用',
            dataIndex: 'service_cost',
            key: 'service_cost',
            render: (amount, record) => (<>
                {
                record.key? ( <Form.Item
                    label=""
                    name={`service_cost_${record.key}`}
                    rules={[{ required: true, message: '請填寫服務費用!' }]}
                    style={{ marginBottom: 0 }}
                >
                    <Input prefix="$" defaultValue={0}  onChange={(e) => onInputChange(e, "service_cost", record)} />

                </Form.Item>):(`$ ${amount}`)
                }
            </>)
        },
        {
            title: '',
            dataIndex: 'action',
            width: "5%",
            key: 'action',
            render: (mobility, record) => record.key && !record.case_fee_row_id ?(
                <Button type="link" onClick={() => {
                let delete_key = record.key
                submitRef.current.setFieldsValue({ [`item_${delete_key}`]: '', [`receive_money_${delete_key}`]: "", [`self_pay_${delete_key}`]: "", [`service_cost_${delete_key}`]: "" })
                let set_obj = {}
                let old_obj = {}
                dataSource.forEach((t, i) => {
                    old_obj[`item_${i + 1}`] = []
                    old_obj[`receive_money_${i + 1}`] = ""
                    old_obj[`self_pay_${i + 1}`] = ""
                    old_obj[`service_cost_${i + 1}`] = ""
                })
                let count = dataSource[dataSource.length-1]
                let new_dataSource = [...dataSource].filter(t => t.key !== delete_key && t.key)
                    .map((t, i) => {
                        if(i !== dataSource.length-1){
                            t.key = i + 1
                            set_obj[`item_${i + 1}`] = [t.item]
                            set_obj[`receive_money_${i + 1}`] = t.receive_money
                            set_obj[`self_pay_${i + 1}`] = t.self_pay
                            set_obj[`service_cost_${i + 1}`] = t.service_cost
                        }
                        return t
                    })
                    new_dataSource.push(count)
                submitRef.current.setFieldsValue(set_obj)
                setDataSource(new_dataSource)
            }}>刪除</Button>
            ): ("")
        }
    ]
    const onFinish = async (value) => {
        let data = [...dataSource].filter(d=> d.item!=='費用小計')
        let obj={ fee_list:data }
        let res
        console.log(value)
        obj.request_for_payment = value.request_for_payment
        obj.expense_attribution_unit = value.expense_attribution_unit
    
        if(visible === 2){
        
            res = await CloseCase(caseData.caseId, caseData.vehicle_sn, obj)

        }else{
            obj.case_info_row_id =caseData.case_info_row_id
            console.log(obj)
            // return false
            
            res = await CloseModifyFee(obj)
        }
        if (res && !res.Error) {
            onConfirm(true)
        }
    }
    return (
        <div>
            <Drawer
                // key={v.key}
                title={"結案"}
                placement="right"
                width={"1088px"}
                closable={false}
                visible={visible}
                extra={<CloseOutlined
                    style={{ cursor: "pointer", color: "rgba(0, 0, 0, 0.45)" }}
                    onClick={() => SetVisible(null)} />
                }
                footer={
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button type="text" style={{ marginRight: 16 }} onClick={() => {
                            SetVisible(null)
                        }
                        }>取消</Button>
                        <Button type="primary"
                            htmlType="submit"
                            onClick={() => {
                                submitRef.current.submit();
                                // setSubmitForm(true)
                            }}
                        >保存</Button>
                    </div>
                }
            >
                {/* <Alert
                    message="專案備註"
                    description="針對專案之費用說明備註。"
                    type="warning"
                    showIcon
                /> */}
                <Form
                    name="basic"
                    ref={submitRef}
                    layout="vertical"
                    initialValues={{
                        ...caseData,
                        priceScale: caseData.project_type,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={(val)=>{}}
                    autoComplete="off"
                    style={{ margin: "24px 0 32px" }}
                >
                    <Form.Item
                        label="計價專案"
                        name="priceScale"
                        style={inputStyle}
                    >
                        <Input style={inputStyle} disabled />
                    </Form.Item>
                    <Form.Item
                        label="請款對象"
                        name="request_for_payment"
                        style={{ width: 328, display: "block", margin: "24px 0" }}
                        // rules={[{ required: true, message: '請填寫請款對象！' }]}
                    >
                        <AutoComplete/>
                    </Form.Item>
                    <Form.Item
                        label="費用歸屬單位"
                        name="expense_attribution_unit"
                        style={inputStyle}
                    >
                        <AutoComplete/>
                        {/* <Search /> */}
                    </Form.Item>

                    <div style={titleStyle}> 費用清單</div>
                    {
                        dataSource &&  (<>
                            <Table
                                rowKey={record => record.key}
                                dataSource={dataSource}
                                columns={columns}
                                pagination={false}
                                style={{ marginTop: 8 }}
                            />
                            <Button
                                type="dashed"
                                style={{ width: "100%", marginTop: 24 }}
                                onClick={() => {
                                    let new_dataSource = [...dataSource]
                                    let count=new_dataSource[new_dataSource.length-1]
                                    let obj = {
                                        key: new_dataSource.length ,
                                        // item: "",
                                        receive_money: 0,
                                        self_pay: 0,
                                        service_cost: 0
                                    } 
                                    new_dataSource=new_dataSource.filter((d,i)=> i!==dataSource.length-1)
                                    new_dataSource.push(obj)
                                    let form_obj={}
                                    new_dataSource.map((item,i)=>{
                                        Object.keys(item).forEach((d,index)=>{
                                            if(d!=="key"){
                                                form_obj[`${d}_${item.key}`] = item[d] ? item[d]:0
                                            }
                                        })
                                        item.key =  i + 1
                                    })
                                    new_dataSource.push(count)
                                    setDataSource(new_dataSource)
                                    submitRef.current.setFieldsValue(form_obj)

                                }}
                            >
                                <PlusOutlined /> 新增
                            </Button>
                        </>)
                    }
                </Form>
            </Drawer>
        </div>
    );
}

const inputStyle = {
    width: 328,
    display: "block"
}
const titleStyle = {
    fontSize: 16,
    fontWeight: 500,
}
export default Caseclosure;

import React,{useState} from 'react'
import { Tabs,Button, Checkbox, Form, Input  } from 'antd'
import { useNavigate } from 'react-router-dom'
import { UserOutlined,LockOutlined,FolderOpenOutlined } from '@ant-design/icons'
import {LoginApi} from '../../api/Member'

const Login = () => {
    let navigate = useNavigate()
    const [error, setError] = useState(null)
    const onFinish = async(val)=>{
        let res = await LoginApi(val)
        if(!res.Error){
            let permission_list = res.Result.permission_list
            let permission = {route:{},notification:{}}
            if(permission_list.length > 0){
                permission_list.forEach(p=>{
                    let type = p.p_type ===1?'route':'notification'
                    permission[type][p.p_code] = p.p_enable_status
                })
            }
            let obj ={
                UserName: res.Result.UserName,
                Token: res.Result.Token,
                RefreshToken: res.Result.RefreshToken,
                permission,
                permission_list
            }
            setError(null)
            localStorage.setItem("User",JSON.stringify(obj))
            window.location.href=`${process.env.PUBLIC_URL}/cases`
        }else{
            setError(res.Error.Message)
        }
    }
    return (
        <div style={{ background: '#F0F2F5', height: "100%" }}>
            <div style={{ background: `url(${process.env.PUBLIC_URL}/assets/images/bg.svg)`, height: "100%", width: "100%", backgroundRepeat: 'no-repeat', position: "relative" }}>
                <div style={{ width: 540, position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%,-50%)' }}>
                    <div style={{ width: 226, margin: '0 auto' }}>
                        <figure style={{ width: '100%' }}>
                            <img src={`${process.env.PUBLIC_URL}/assets/images/logo.png`} />
                        </figure>
                    </div>
                    <div style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.03)', marginTop: 60 }}>
                        <Tabs defaultActiveKey="1">
                            <Tabs.TabPane tab="Login" key="1" />
                        </Tabs>
                    </div>
                    <Form
                        name="basic"
                        style={{marginTop:20}}
                        onFinish={onFinish}
                        autoComplete="off"
                    >
                     <Form.Item
                            label=""
                            name="company_id"
                            validateStatus={error? "error":""}
                        >
                            <Input  prefix={<FolderOpenOutlined style={{ fontSize: 16,color: '#1890ff' }} />} placeholder='公司代號' style={{padding:8, fontSize:16}}/>
                        </Form.Item>
                        <Form.Item
                            label=""
                            name="user_id"
                            validateStatus={error? "error":""}
                        >
                            <Input  prefix={<UserOutlined style={{ fontSize: 16,color: '#1890ff' }} />} placeholder='帳號' style={{padding:8, fontSize:16}}/>
                        </Form.Item>

                        <Form.Item
                            label=""
                            name="password"
                            help={error}
                            validateStatus={error? "error":""}
                        >
                            <Input.Password prefix={<LockOutlined style={{ fontSize: 16,color: '#1890ff' }} />}  placeholder='密碼' style={{padding:8, fontSize:16}} />
                        </Form.Item>
                        <div style={{display:'flex', justifyContent:'space-between'}}>
                            <Form.Item
                                name="remember"
                                valuePropName="checked"
                            >
                                <Checkbox style={{fontSize:16}}>記住我</Checkbox>
                            </Form.Item>

                            <Button 
                            style={{fontSize:16, padding:0}} 
                            type="link"
                            onClick={()=>navigate('/user/forget_pw')}
                            >忘記密碼？</Button>
                        </div>
                       

                        <Form.Item>
                            <Button type="primary" htmlType="submit" block style={{fontSize:16}}>
                            登入
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </div>
    )
}

export default Login;

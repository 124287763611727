import React, { useState, useEffect, useRef } from 'react'
import { Input, Button, Drawer, Form, Select, Radio } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'
import { GetServicePointList } from '../../api/ServicePoint'
import { DispatchCaseByTemporaryDriver } from '../../api/Case'
import { ManualCloseExecution } from '../../api/Execution'

const { TextArea } = Input
const { Option } = Select

const TemporaryDriver = ({ SetVisible, carData, reasonOption, active, executeId }) => {
    const submitRef = useRef()
    const state = useSelector(state => state)
    const [stationList, setStationList] = useState([])
    const [filterObj, setFilterObj] = useState({})
    const [btnDisabled, setBtnDisabled] = useState(true)
    useEffect(() => {
        GetServicePointList(-1, 0).then(res => {
            if (res && !res.Error) {
                let data = res.Result.Data.map(d => {
                    return { value: `${d.row_id},${d.name}`, label: d.name }
                })
                setStationList(data)
            }
        })
    }, [])

    const KeyUp = (key, val) => {
        let obj = { ...filterObj }
        if (val === "") {
            delete obj[key]
        } else {
            obj[key] = val
        }
        setFilterObj(obj)
        let maxLength = active === '紙本結案' ? 3 : 4
        if (Object.keys(obj).length === maxLength) {
            setBtnDisabled(false)
        } else {
            setBtnDisabled(true)
        }
    }
    const onFinish = async (val) => {
        let success = []
        Object.keys(val).forEach(key => {
            if (!val[key]) val[key] = ''
        })
        if (active === "紙本結案") {
            if(val.service_point.indexOf(",")>-1){
                val.service_point = val.service_point.split(",")[0]
            }
            val.driver=val.driver_name
            val.vehicle_row_id_list = carData.map(c => c.row_id)
            let res = await ManualCloseExecution(executeId, val)
            if (res && !res.Error) {
                success.push(1)
            } else {
                success.push(0)
            }

        } else {
            val.service_point = val.service_point.split(",")[1]
            for (let i = 0; i < carData.length; i++) {
                val.current_case_info_row_id = carData[i]["row_id"]
                let res = await DispatchCaseByTemporaryDriver(val)
                if(res&& !res.Error){
                    success.push(1)
                }else{
                    success.push(0)
                }
            }
        }
        let filter = success.filter(s=>s ===0)
        if(filter.length === 0){
            SetVisible(null)
        }
    }
    return (
        <Drawer
            title={<div>
                <ArrowLeftOutlined
                    style={{ cursor: "pointer" }}
                    onClick={() => SetVisible(7)}
                />
                <span style={{ fontWeight: 700, paddingLeft: 16 }}>臨時技師</span>
            </div>}
            placement="right"
            closable={false}
            visible={true}
            width="1280px"
            footer={
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button type="text" style={{ marginRight: 16 }} onClick={() => SetVisible(7)}>取消</Button>
                    <Button type="primary"
                        disabled={btnDisabled}
                        onClick={() => {
                            submitRef.current.submit()
                        }}>派遣</Button>
                </div>
            }
        >
            <Form
                ref={submitRef}
                name="basic"
                layout='vertical'
                onFinish={onFinish}
                autoComplete="off"
            >
                <Form.Item
                    label="姓名"
                    name="driver_name"
                    rules={[{ required: true, message: "請輸入姓名!" }]}
                >
                    <Input
                        onKeyUp={(e) => KeyUp("driver_name", e.target.value)}
                        allowClear
                        placeholder="example"
                        style={{ width: 304 }}

                    />
                </Form.Item>
                <Form.Item
                    label="電話"
                    name="driver_phone"
                    rules={[{ required: true, message: "請輸入電話!" }]}
                >
                    <Input
                        onKeyUp={(e) => KeyUp("driver_phone", e.target.value)}
                        allowClear
                        placeholder="example"
                        style={{ width: 304 }}
                    />
                </Form.Item>
                <Form.Item
                    label="據點"
                    name="service_point"
                >
                    {/* <Select
                        allowClear
                        showSearch
                        placeholder='點擊選擇'
                        options={stationList}
                        style={{ width: 304 }}
                    /> */}
                    <Select style={{ width: 304 }}
                        showSearch
                        placeholder='點擊選擇'
                    >
                        {
                            stationList.map(s => (<Option key={s.value} value={`${s.label},${s.value}`}>{s.label}</Option>))
                        }
                    </Select>
                </Form.Item>

                {
                    active === "紙本結案" ? (
                        <Form.Item
                            label="紙本結案原因"
                            name="reason"
                        >
                            <TextArea
                                onKeyUp={(e) => KeyUp("reason", e.target.value)}
                                placeholder="如果可以，請簡述原因"
                                autoSize={{ minRows: 1, maxRows: 2 }}
                            />
                        </Form.Item>
                    ) : (
                        <>
                            <Form.Item
                                // label={option[1]["active"]?"取消原因":"改派原因"}
                                name="change_dispatch_reason_name"
                                style={{ marginBottom: 8 }}
                            >
                                <Radio.Group
                                      onChange={(e) => KeyUp("change_dispatch_reason_name", e.target.value)}
                                    buttonStyle="solid" >
                                    {
                                        reasonOption.map(o => (<Radio.Button value={`${o.label}`} key={o.value}>{o.label}</Radio.Button>))
                                    }
                                </Radio.Group>
                            </Form.Item>
                            <Form.Item
                                label=""
                                name="change_dispatch_reason_description"
                            >
                                <TextArea
                                    onKeyUp={(e) => KeyUp("change_dispatch_reason_description",e.target.value)}
                                    placeholder="請在此輸入補充說明..."
                                    autoSize={{ minRows: 2, maxRows: 3 }}
                                />
                            </Form.Item>
                        </>
                    )
                }
            </Form>
        </Drawer>
    )
}

export default TemporaryDriver
import React, { useState, useEffect, useRef } from 'react'
import BreadCrumb from '../../../components/shared/BreadCrumb'
// import ModalCard from '../../components/cases/case/ModalCard'
import CustomTable from '../../../components/shared/component/Card'
import { PageHeader, Button, Card, Modal, Input, Popconfirm, message, Tag, Drawer, Select, Form, Checkbox, Row, Col, Upload } from 'antd'
import { KeyOutlined, PauseCircleOutlined, EditOutlined, CloseOutlined, PlusOutlined } from '@ant-design/icons'
import { useNavigate, useLocation } from 'react-router-dom'
import { GetStationEmployeeDetail, GetCustomerEmployeeDetail, UpdateCustomerStationEmployee, AddCustomerEmployeeNote, UpdateCustomerEmployee, UpdateCustomerEmployeeStatus, GetCustomerEmployeeHistory, UpdateStationEmployeeNote, UpdateStationEmployeeStatus, GetStationEmployeeHistory } from '../../../api/Customer'
import { UpdatePassowrdByUserId } from '../../../api/Member'
import { GetCarBrandList } from '../../../api/Code'
import LoadingBlock from '../../shared/LoadingBlock'
import HistoryRecord from '../../shared/component/HistoryRecord'
import CustomCascader from '../../shared/component/CustomCascader'
import { RouteName } from '../../../util/route'
import { useSelector } from 'react-redux'
const { TextArea } = Input

const Detail = ({ ContainerWidth, pageTitle, propState }) => {
    const { state,pathname } = useLocation()
    const routeId = RouteName(pathname)
    let UserInfo = useSelector(state => state.UserInfo) 
    let UserPermission = UserInfo? UserInfo.permission.route: null
    let permission = UserPermission?UserPermission[routeId]:UserPermission
    const submitRef = useRef(), resetPasswordForm = useRef()
    const finalState = propState ? propState : state
    const navigate = useNavigate()
    const [editDrawer, setEditDrawer] = useState(false)
    const [modalStatus, setModalStatus] = useState(false)
    const [popStatus, setPopStatus] = useState(false)
    const [fetchData, setFetchData] = useState(null)
    const [editRemark, setEditRemark] = useState(null)
    const [remarkContent, setRemarkContent] = useState(null)
    const [history, setHistory] = useState(null)
    const [passwordError, setPasswordError] = useState(null)
    const [BrandList, setBrandList] = useState([])
    const [previewPhoto, setPreviewPhoto] = useState(null)
    const roles = !state.stationId ? [
        { key: "role_keyin", name: "可上單" },
        { key: "role_payment", name: "請款窗口" }
    ] : [
        { key: "role_keyin", name: "可上單" },
        { key: "role_payment", name: "請款窗口" },
        { key: "role_handover", name: "交接車人" }
    ]
    const getBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        })
    const updateRemark = async () => {
        let param = `row_id=${fetchData.row_id}&note=${remarkContent}`
        let res
        if (state.stationId) {
            res = await UpdateStationEmployeeNote(param)
        } else {
            res = await AddCustomerEmployeeNote(param)
        }
        if (res && !res.Error) {
            setEditRemark(null)
            setRemarkContent(null)
            fetchAPI()
        }
    }
    const fetchAPI = async () => {
        let historyRes, Fetch

        if (state.stationId) {
            Fetch = await GetStationEmployeeDetail(state.id)
        } else {
            let id = state.id ? state.id : state.employeeId
            Fetch = await GetCustomerEmployeeDetail(id)
        }

        if (Fetch && !Fetch.Error) {
            let obj = Fetch.Result
            if (state.stationId) {
                historyRes = await GetStationEmployeeHistory(`page_size=-1&page_index=0&row_id=${obj.row_id} `)
            } else {
                historyRes = await GetCustomerEmployeeHistory(`page_size=-1&page_index=0&row_id=${obj.row_id} `)
            }
            if (!historyRes.Error) {
                let History = (historyRes.Result.Data).reverse()
                setHistory(History)
            }
            let role_content = []
            roles.forEach(r => {
                if (obj[r.key] === 1) {
                    role_content.push(r.name)
                }
            })
            obj.Roles = role_content
            obj.Status = { type: "status", color: obj.status === 1 ? '#52C41A' : '#D9D9D9', content: [obj.status === 1 ? '在職' : '離職'] }
            obj.remark = { type: "remark", content: [obj.note] }
            obj.nameCard = obj.card_photo ? { type: "tag", content: [obj.name_cht], title: "", download: `${process.env.REACT_APP__API_HOST}/Customer/GetEmployeeCard?row_id=${obj.row_id}&download=true` } : '-'
            if (obj.county_name) {
                obj.full_address = obj.county_name !== "" ? `${obj.county_name}${obj.town_name}${obj.address}` : obj.address
                obj.address_county = obj.county_name !== "" ? [obj.county, obj.town] : ""
            } else {
                obj.full_address = obj.address
                obj.address_county = ''
            }
            if(obj.card_photo){
                setPreviewPhoto(`${process.env.REACT_APP__API_HOST}/Customer/GetEmployeeCard?row_id=${obj.row_id}`)
            }
            obj.brand_name = obj.brand_name !== undefined ? obj.brand_name : ''
            setFetchData(obj)
        }
    }
    const onSubmitForm = async (value) => {
        const formdata = new FormData();
        roles.map(r => {
            let filter = value.Roles.filter(role => role === r.name)
            value[r.key] = filter.length > 0 ? 1 : 0
        })
        value.status = fetchData.status
        value.row_id = fetchData.row_id
        if (value.address_county) {
            value.county = value.address_county[0]
            value.town = value.address_county[1]
        }
        let param = ''
        Object.keys(value).map((key, i) => {
            param += i === 0 ? `${key}=${value[key]}` : `&${key}=${value[key]}`
            if(key === 'card_photo' && value[key]){
                console.log(value[key])
                formdata.append(key, value[key]["file"]["originFileObj"])
            }else{
                formdata.append(key, value[key])
            }
        })
        let res
        if (state.stationId) {
            res = await UpdateCustomerStationEmployee(param)
        } else {
            res = await UpdateCustomerEmployee(formdata)
        }
        if (res.Result.Success) {
            fetchAPI()
            setEditDrawer(false)
        }
    }
    const changeStatus = async () => {
        let data = {
            row_id: fetchData.row_id,
            status: fetchData.status === 1 ? 0 : 1,
        }
        let param = ''
        Object.keys(data).map((key, index) => {
            param += index === 0 ? `${key}=${data[key]}` : `&${key}=${data[key]}`
        })
        let res
        if (state.stationId) {
            res = await UpdateStationEmployeeStatus(param)
        } else {
            res = await UpdateCustomerEmployeeStatus(param)
        }
        if (res.Result.Success) {
            fetchAPI()
            setPopStatus(true)
            message.success("任職狀態已變更")
            setEditDrawer(false)
        }
    }
    useEffect(() => {
        fetchAPI()
        GetCarBrandList().then(res => {
            if (res && !res.Error) {
                let Data = res.Result.map(r => {
                    return ({ value: r.Code, label: r.Name })
                })
                setBrandList(Data)
            }
        })
    }, [])
    return (
        <div className='container' style={ContainerWidth}>
            <div style={{ height: "calc(100%)" }}>
                {
                    fetchData ? (<>
                        <PageHeader
                            style={{ background: "white", width: "100%" }}
                            title={fetchData !== null ? (
                                <div style={{ display: "flex" }}>
                                    <p>{fetchData.name_cht}</p>
                                    <div style={{ marginLeft: 12 }}>
                                        {
                                            fetchData.Roles.map(role => (<Tag>{role}</Tag>))
                                        }
                                    </div>
                                </div>
                            ) : ''}

                            onBack={() => propState ? navigate(`/customer/lists/${propState.customerId}/`, { state }) : navigate(`/customer/lists/${finalState.customerId}/details`, { state })}
                            className="site-page-header"
                            breadcrumb={<BreadCrumb
                                Info={state.BreadcrumbInfo}
                                DetailText={state.DetailText ? state.DetailText : fetchData !== null ? fetchData.name_cht : ''}
                                extraInfo={state.stationId && fetchData ? { [state.id]: fetchData.name_cht } : ''}
                            />}

                            extra={permission !== 2 ? [] : !state.stationId ? [
                                <Button key={"1"} icon={<KeyOutlined />} onClick={() => setModalStatus(true)}>變更密碼</Button>,
                                <Popconfirm
                                    placement="bottom"
                                    title={fetchData && fetchData.status === 1 ? "離職後將無法上單" : "恢復為在職狀態？"}
                                    onConfirm={() => changeStatus()}
                                    style={{ marginRight: 12 }}
                                    okText={fetchData && fetchData.status === 1 ? "確定離職" : "復職"}
                                    showCancel={false}
                                >
                                    <Button icon={<PauseCircleOutlined />}>任職狀態變更</Button>
                                </Popconfirm>
                            ] : [<Popconfirm
                                placement="bottom"
                                title={fetchData && fetchData.status === 1 ? "離職後將無法上單" : "恢復為在職狀態？"}
                                onConfirm={() => changeStatus()}
                                style={{ marginRight: 12 }}
                                okText={fetchData && fetchData.status === 1 ? "確定離職" : "復職"}
                                showCancel={false}
                            >
                                <Button icon={<PauseCircleOutlined />}>任職狀態變更</Button>
                            </Popconfirm>]}
                        >

                        </PageHeader>
                        <div className='split_block' style={{ display: 'grid', gridTemplateColumns: "repeat(2, 1fr)", height: "calc(100% - 102px)" }}>
                            <div className='content_block' style={{ padding: 24 }}>
                                <Card
                                    style={{ width: "100%", marginBottom: 24 }}
                                    title="基本資訊"
                                    extra={
                                        permission !== 2 ? false :
                                            <Button
                                                type="text"
                                                style={{ paddingRight: 0 }}
                                                onClick={() => setEditDrawer(true)}
                                            >
                                                修改
                                                <EditOutlined style={{ color: "rgba(24, 144, 255, 1)" }} />
                                            </Button>
                                    }
                                >

                                    {
                                        fetchData !== null && (
                                            <CustomTable
                                                remarkStatus={(val) => {
                                                    setEditRemark(val)
                                                }}
                                                header={!state.stationId ? [
                                                    { key: "name_cht", name: "中文姓名" },
                                                    { key: "name_en", name: "英文姓名" },
                                                    { key: "job_title", name: "職稱" },
                                                    { key: "tel", name: "聯絡電話" },
                                                    { key: "email", name: "E-mail" },
                                                    { key: "fax", name: "傳真號碼" },
                                                    { key: "full_address", name: "聯絡地址" },
                                                    { key: "Status", name: "任職狀態" },
                                                    { key: "nameCard", name: "名片" },
                                                    { key: "brand_name", name: "常用廠牌" },
                                                    { key: permission === 2 ? "remark" : 'note', name: "備註" }
                                                ] : [
                                                    { key: "user_id", name: "代號" },
                                                    { key: "name_cht", name: "中文姓名" },
                                                    { key: "job_title", name: "職稱" },
                                                    { key: "tel", name: "聯絡電話" },
                                                    { key: "email", name: "E-mail" },
                                                    { key: "Status", name: "任職狀態" },
                                                    { key: "brand_name", name: "常用廠牌" },
                                                    { key: permission === 2 ? "remark" : 'note', name: "備註" }
                                                ]} data={fetchData} />
                                        )}
                                </Card>
                                <Card style={{ width: "100%", marginBottom: 24 }} title="帳號資訊" >
                                    <CustomTable
                                        header={[
                                            { key: "account", name: "帳號" },
                                            { key: "password", name: "密碼" },

                                        ]} data={{
                                            account: fetchData.user_id,
                                            password: fetchData.password
                                        }} />
                                </Card>
                            </div>
                            <HistoryRecord History={history} detail={fetchData} />
                        </div>
                    </>) : (<div style={{ background: 'white', width: '100%', margin: 24 }}>
                        <LoadingBlock />
                    </div>)
                }

            </div>
            <Modal title="變更密碼"
                visible={modalStatus}
                width={416}
                onOk={() => resetPasswordForm.current.submit()}
                onCancel={() => setModalStatus(false)}
            >
                <Form
                    ref={resetPasswordForm}
                    name="basic"
                    layout='vertical'
                    onFinish={(value) => {
                        if (value.password !== value.comfirm_password) {
                            setPasswordError(true)
                        } else {
                            value.user_id = fetchData.user_id
                            UpdatePassowrdByUserId(value).then(res => {
                                if (!res.Error) {
                                    fetchAPI()
                                    setPasswordError(null)
                                    setModalStatus(false)
                                    resetPasswordForm.current.resetFields()
                                }
                            })
                        }
                    }}
                >
                    <Form.Item
                        label=""
                        name="password"
                        validateStatus={passwordError ? "error" : ""}
                        rules={[{ required: true, message: "請輸入新密碼!" }]}
                    >
                        <Input.Password
                            allowClear
                            placeholder="請輸入新密碼"
                        />
                    </Form.Item>
                    <Form.Item
                        label=""
                        name="comfirm_password"
                        rules={[{ required: true, message: "再次輸入新密碼!" }]}
                        validateStatus={passwordError ? "error" : ""}
                        help={passwordError ? "新密碼與再次輸入新密碼不一致" : ""}
                    >
                        <Input.Password
                            allowClear
                            placeholder="再次輸入新密碼"
                        />
                    </Form.Item>
                </Form>
            </Modal>
            {
                fetchData !== null && (<Drawer
                    width={361}
                    title="編輯 - 基本資訊"
                    placement="right"
                    closable={false}
                    extra={<CloseOutlined
                        style={{ cursor: "pointer", color: "rgba(0, 0, 0, 0.45)" }}
                        onClick={() => setEditDrawer(false)}
                    />}
                    visible={editDrawer}
                    footer={
                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                            <Button type="text" style={{ marginRight: 16 }} onClick={() => {
                                setEditDrawer(false)
                            }
                            }>取消</Button>
                            <Button type="primary"
                                // disabled={btnDisabled}
                                onClick={() => {
                                    submitRef.current.submit()
                                    // setSubmitForm(true)
                                }}>保存</Button>
                        </div>
                    }
                >
                    <Form
                        ref={submitRef}
                        name="basic"
                        layout='vertical'
                        initialValues={fetchData}
                        onFinish={onSubmitForm}
                        autoComplete="off"
                    >
                        <Form.Item
                            name="Roles"
                            label={<><span>身份標籤</span><span style={{ paddingLeft: 4, color: "rgba(0, 0, 0, 0.45)", fontSize: 14 }}>(可複選)</span></>}
                        >
                            <Checkbox.Group>
                                <Row style={{ display: "flex" }}>
                                    <Col style={{ marginRight: 8 }}>
                                        <Checkbox
                                            value="可上單"
                                            style={{
                                                lineHeight: '32px',
                                            }}
                                        >
                                            可上單
                                        </Checkbox>
                                    </Col>
                                    <Col >
                                        <Checkbox
                                            value="請款窗口"
                                            style={{
                                                lineHeight: '32px',
                                            }}
                                        >
                                            請款窗口
                                        </Checkbox>
                                    </Col>
                                    {
                                        state.stationId && (<Col >
                                            <Checkbox
                                                value="交接車人"
                                                style={{
                                                    lineHeight: '32px',
                                                }}
                                            >
                                                交接車人
                                            </Checkbox>
                                        </Col>)
                                    }
                                </Row>
                            </Checkbox.Group>
                        </Form.Item>
                        <Form.Item
                            label="中文姓名"
                            name="name_cht"
                            rules={[{ required: true, message: '請輸入中文姓名!' }]}
                        >
                            <Input placeholder='example' />
                        </Form.Item>
                        {
                            !state.stationId && (<Form.Item
                                label="英文姓名"
                                name="name_en"
                            >
                                <Input allowClear placeholder='example' />
                            </Form.Item>)
                        }

                        <Form.Item
                            label="職稱"
                            name="job_title"
                            rules={[{ required: true, message: '請輸入職稱!' }]}
                        >
                            <Input placeholder='例：廠長' />
                        </Form.Item>
                        <Form.Item
                            label="聯絡電話"
                            name="tel"
                            style={{ display: "block" }}
                            rules={[{ required: true, message: '請輸入聯絡電話！' }]}

                        >
                            <Input placeholder='市話、手機皆可，不需加分隔號' />
                        </Form.Item>
                        <Form.Item
                            label="E-mail"
                            name="email"
                            rules={[{ required: true, message: '請輸入E-mail！' }]}
                        >
                            <Input placeholder='example@abc.com' />
                        </Form.Item>
                        {
                            !state.stationId && (<>
                                <Form.Item
                                    label="傳真號碼"
                                    name="fax"
                                >
                                    <Input allowClear placeholder='不需加分隔號' />
                                </Form.Item>
                                <Form.Item
                                    label="聯絡地址"
                                    name="address_county"
                                    rules={[{ required: true, message: "請輸入聯絡地址！!" }]}
                                    style={{ marginRight: "16px", marginBottom: 8 }}
                                >
                                    <CustomCascader
                                        defaultValue={fetchData.address_county}
                                        style={{ width: "304px" }}
                                        OnChange={(val) => {
                                            submitRef.current.setFieldsValue({ address_county: val })
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label=""
                                    name="address"
                                >
                                    <Input allowClear placeholder='請直接輸入地址' style={{ width: "304px" }} />
                                </Form.Item>
                                <Form.Item
                                    label="名片"
                                    name="card_photo"
                                    // rules={[{ required: true, message: '請選擇名片圖!' }]}
                                    extra="僅接受 jpg, png 並且小於 5mb 之檔案格式類型"
                                >
                                    <Upload
                                        maxCount={1}
                                        listType="picture-card"
                                        className="avatar-uploader"
                                        showUploadList={false}
                                        accept="image/png, image/jpeg, image/jpg"
                                        customRequest={({ onSuccess }) => onSuccess("ok")}
                                        onChange={async (file) => {
                                            let url = await getBase64(file.file.originFileObj)
                                            setPreviewPhoto(url);
                                        }}
                                    >
                                        {previewPhoto ? <img src={previewPhoto} alt="avatar" style={{ width: '100%' }} /> : (<div>
                                            <PlusOutlined />
                                            <div style={{ marginTop: 8 }}>上傳</div>
                                        </div>)}
                                    </Upload>
                                </Form.Item>

                            </>)
                        }
                        <Form.Item
                            label="常用車牌"
                            name="brand"
                        // rules={[{ required: true, message: '請選擇常用車牌！' }]}
                        >
                            <Select
                                placeholder='點擊選擇'
                                style={{ width: 304 }}
                                // onChange={(val) => console.log(val)}
                                options={BrandList}
                            />
                        </Form.Item>
                    </Form>
                </Drawer>)

            }
            {
                fetchData && (<Modal title="請輸入備註"
                    visible={editRemark === 1 ? true : false}
                    width={416}
                    onOk={() => updateRemark()}
                    onCancel={() => {
                        setEditRemark(null)
                        setRemarkContent(null)
                    }}
                >
                    <TextArea
                        defaultValue={fetchData.note}
                        placeholder="額外的備註請寫在此......"
                        style={{ width: 368 }}
                        onKeyUp={(e) => setRemarkContent(e.target.value)}
                    />
                </Modal>)
            }


        </div>

    );
}

export default Detail;
import React, { useEffect, useState } from 'react'
import { Button, Card, Tag, Space, Row, Col, Divider,Modal } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { useNavigate } from 'react-router-dom'
import CustomTable from "../../../components/cases/case/Table"
import { ConfirmDispatch } from '../../../api/Case'
import { GetExecutionInfo } from '../../../api/Execution'
import { NotifyGetExecutionInfo } from '../../../api/Notification';

const DispatchCase = ({ SetCurrentTab, selectedData, dispatchData }) => {
  const navigate = useNavigate()
  const [data, setData] = useState(selectedData)
  const [success, setSuccess] = useState({})
  const info = async (s, dispatch)=>{
    let res
    if(dispatch){
      res = await ConfirmDispatch(s.execution_info_row_id, {
        execution_info_row_id: s.execution_info_row_id,
      })
    }else{
       res = await NotifyGetExecutionInfo(s.execution_info_row_id)
    }
    if(res && !res.Error){
      if(dispatch){
        let obj = { ...success }
        obj[s.execution_info_row_id] = 1
        setSuccess(obj)
      }else{
        navigate(`/execution/${s.execution_info_row_id}`)
      }
    }else{
      console.log(res)
      let errorCode =  res.Error.StatusCode
      let error =''
      if(errorCode === 404){
        error ='此趟次已超過確認派遣時間，已加入下個時段重新媒合，請前往執行列表或通知確認'
      }else{
        error= `${errorCode}錯誤：${res.Error.Message}`
      }
      Modal.error({
        title: error
      });
    }             
  }
  useEffect(() => {
    setData(selectedData)
    let obj = {}
    data.success.forEach(s => {
      obj[s.execution_info_row_id] = s.status
    })
    setSuccess(obj)
  }, [selectedData])

  return (
    <div>
      <Space>
        <LeftOutlined
          style={{ display: "block", marginRight: 24 }}
          onClick={() => SetCurrentTab("1")}
        />
        <p style={{ fontSize: 38, fontWeight: "bold" }}>媒合結果</p>
      </Space>
      <Card
        title={
          <div>
            <span>媒合統計</span>
            <span style={{ color: "rgba(0,0,0,0.45)", paddingLeft: 4 }}>
              請確認此批派遣結果，未確認之結果將於下個小時再次媒合
            </span>
          </div>
        }
        bordered={false}
        style={{ margin: "24px 0" }}
      >
        <Row>
          <Col className="gutter-row" span={8}>
            <div style={{ color: "rgba(0,0,0,0.4)" }}>媒合筆數</div>
            <div style={{ fontSize: "24px" }}>{selectedData.total}</div>
          </Col>
          <Col className="gutter-row" span={8}>
            <Space>
              <Divider type="vertical" style={{ height: 32 }} />
              <div>
                <div style={{ color: "rgba(0,0,0,0.4)" }}>成功筆數</div>
                <div style={{ fontSize: 24 }}>{selectedData.success_count}</div>
              </div>
            </Space>
          </Col>
          <Col className="gutter-row" span={8}>
            <Space>
              <Divider type="vertical" style={{ height: 32 }} />
              <div>
                <div style={{ color: "rgba(0,0,0,0.4)" }}>失敗筆數</div>
                <div style={{ fontSize: 24 }}>{selectedData.failure_count}</div>
              </div>
            </Space>
          </Col>
        </Row>
      </Card>
      {
        data && data.success.map((s, i) => {
          let arr = s.result ? s.result.split("|") : ""
          if (typeof (arr) === "object") {
            arr = arr.map((a, i) => {
              let obj = {}
              let index = i + 1
              obj.text = a
              if (index % 2 == 0) {
                obj.color = "#1890FF"
              } else {
                obj.color = 'rgba(0,0,0,0.8)'
              }
              return obj
            })
          }
          return ((<>
            <Card
              style={{ marginTop: i === 0 ? 0 : 16 }}
              title={s.execution_info_id}
              extra={<Tag color="green">配對成功</Tag>}>
              <CustomTable header={[
                { key: "origin", name: "起運點" },
                { key: "destination", name: "迄運點" },
                { key: "vehicle_count", name: "車輛台數" },
                { key: "stop_count", name: "停靠點" },
                { key: "duration", name: "預計耗時" },
              ]}
                data={s}
              >
              </CustomTable>
              <div style={{ marginTop: 24 }}>
                <p style={{ fontWeight: "bold", marginBottom: 8, fontSize: 16 }}>派遣結果</p>
                <p>
                  {
                    typeof (arr) === "object" && arr.map(a => (<span style={{ color: a.color }} key={a.text}>{a.text}</span>))
                  }
                </p>
              </div>
            </Card>
            <Space style={{ background: "white", width: "100%", padding: "10px 16px", justifyContent: "flex-end" }}>
              {
                success[s.execution_info_row_id] ? (<>
                  <Button onClick={() => info(s)}>查看內容</Button>
                  <Button type="primary" disabled>已派遣</Button>
                </>) : (<>
                  <Button onClick={() => info(s)}>查看內容</Button>
                  <Button
                  onClick={()=>{
                    navigate(`/execution/${s.execution_info_row_id}`,{state:{isChange:true}})
                  }}
                  >改派他人</Button>
                  <Button type="primary" onClick={() => {
                    info(s, true)
                  }}>確認派遣</Button>
                </>)
              }

            </Space>
          </>))
        })
      }

      {
        selectedData.failure && selectedData.failure.map((s, i) => {
          let arr = s.result ? s.result.split("|") : ""
          if (typeof (arr) === "object") {
            arr = arr.map((a, i) => {
              let obj = {}
              let index = i + 1
              obj.text = a
              if (index % 2 == 0) {
                obj.color = "#1890FF"
              } else {
                obj.color = 'rgba(0,0,0,0.8)'
              }
              return obj
            })
          }
          return ((<>
            <Card
              style={{ marginTop: i === 0  && data.success.length===0 ? 0 : 16 }}
              title={`${s.case_no}-${s.vehicle_sn?s.vehicle_sn:1}`}
              extra={<Tag color="red">配對失敗</Tag>}>
              <CustomTable header={[
                {
                  key: "customer",
                  name: "客戶別"
                },
                {
                  key: "origin",
                  name: "起運點"
                },
                {
                  key: "destination",
                  name: "迄運點"
                },

              ]}
                data={s}
              >
              </CustomTable>
              <div style={{ marginTop: 24 }}>
                <p style={{ fontWeight: "bold", marginBottom: 8, fontSize: 16 }}>派遣結果</p>
                <p>
                  {
                    typeof (arr) === "object" && arr.map(a => (<span style={{ color: a.color }} key={a.text}>{a.text}</span>))
                  }
                </p>
              </div>
            </Card>
            <Space style={{ background: "white", width: "100%", padding: "10px 16px", justifyContent: "flex-end" }}>
              <Button onClick={() => navigate(`/cases/${s.case_no}`, {
                state: {
                  vehicle_sn: s.vehicle_sn?s.vehicle_sn:1,
                  dataDate: "111-10,111-11"
                }
              })}>查看內容</Button>
            </Space>
          </>))
        })
      }
    </div>
  )
}

export default DispatchCase
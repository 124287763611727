import React, { useState, useEffect,useRef } from "react"
import { Alert, DatePicker, Form, Radio, TimePicker, Collapse, Button, Input, Popconfirm,Modal } from "antd";
import { useSelector, useDispatch } from "react-redux";
import {  addCase} from "../../../actions/cases";
import { CalendarOutlined, PlusOutlined, ArrowRightOutlined, UserOutlined, PhoneOutlined, } from "@ant-design/icons";
import CarExpandRow from "./CarExpandRow";
import { clone } from "lodash"
import { useNavigate } from 'react-router-dom'
import moment from "moment"
import { CancelAllCases,PostAddCase,PutAddCase,PutDraftCase} from '../../../api/Case'
const { confirm } = Modal;
const { Panel } = Collapse
function WeekDay(day) {
  switch (day) {
    case 1:
      return "週一";
    case 2:
      return "週二";
    case 3:
      return "週三";
    case 4:
      return "週四";
    case 5:
      return "週五";
    case 6:
      return "週六";
    case 0:
      return "週日";
    default:
  }
}
function DateRange(iniDate) {
  if (iniDate === null) {
    iniDate = moment().format("YYYY-MM-DD");
  } else {
    iniDate = moment(iniDate).format("YYYY-MM-DD");
  }
  let timeArr = [];
  for (let i = 0; i < 7; i++) {
    let obj = {};
    if (i === 0) {
      obj.moment = moment(iniDate);
      obj.date = moment(iniDate).format("M/D");
      obj.day = WeekDay(moment(iniDate).day());
    } else {
      obj.moment = moment(iniDate).add(i, "day");
      obj.date = moment(iniDate).add(i, "day").format("M/D");
      obj.day = WeekDay(moment(iniDate).add(i, "day").day());
    }
    timeArr.push(obj);
  }
  return timeArr;
}
const Step2 = ({ setCurrentStep }) => {
  let caseInfo = useSelector((state) => state);
  let DispatchCarBrand = useSelector(state => state.CarBrand)
  const dispatch = useDispatch();
  let customerInfo = caseInfo.Cases.customerInfo;
  const [pickerOpen, setPickerOpen] = useState(false);
  const [selectDate, setSelectDate] = useState(customerInfo &&customerInfo.transport_date!==""?moment(customerInfo.transport_date) :moment());
  const [selectTime, setSelectTime] = useState(customerInfo.transport_type==='指定時間'?[moment(customerInfo.transport_time_from,"HH:00"),moment(customerInfo.transport_time_to,"HH:00")]:null);
  const [iniDate, setIniDate] = useState(null);
  const [sourceState, setSourceState] = useState(customerInfo.calling_source);
  const [dateRange, setDateRange] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deliveryType, setDeliveryType] = useState(customerInfo.transport_type?customerInfo.transport_type:"儘快");
  const [cars, setCars] = useState([])
  const [collapseActiveKey, setCollapseActiveKey] = useState([])
  const [harborPass, setHarborPass] = useState(customerInfo.harbor_area_pass)
  const [remark, setRemark] = useState(customerInfo.to_customer_service_note?customerInfo.to_customer_service_note:null)
  const [selectedDateType, setSelectedDateType] = useState(false)
  const formRef = React.createRef()
  let navigate = useNavigate()
  const [CarBrand, setCarBrand] = useState(null)
  const [CustomerInfo, setCustomerInfo] = useState(customerInfo)
  const [disabledTime, setDisabledTime]=useState([])
  const selectTimeForm = useRef(), selectedTimeInput= useRef()
  useEffect(() => {
    if (DispatchCarBrand !== null) {
      setCarBrand(DispatchCarBrand)
    }
  }, [DispatchCarBrand]);
  useEffect(() => {
    let carInfos = caseInfo.Cases.cases;
    if (carInfos) {
      let cars = clone(carInfos).filter((c) => c.no !== "不可行駛原因");
      cars.map((car) => {
        car.vehicle_model_id = car.CarType;
        car.status = parseInt(car.mobility);
        car.vehicle_sn = car.key - 1;
        return car
      });
      setCars(cars)
    }
    for (let key in customerInfo) {
      if (!customerInfo[key]) {
        customerInfo[key] = "";
      }
    }
  }, []);
  useEffect(() => {
    let range = DateRange(iniDate);
    setDateRange(range);
  }, [iniDate]);
  useEffect(()=>{
    // 一般客戶：指定時間只能選擇下一個整點＋3小時後的時間 
    // VIP客戶：指定時間選擇下一個整點＋1小時後的時間、無加價特急件
    let invalidTime =[], time_picker
    if(selectDate){
      let isToday = moment().format("YYYYMMDD") === selectDate.format("YYYYMMDD")?true:false
      if(isToday){
        let defaultTime = customerInfo.is_VIP ? moment().add(2, "hours") : moment().add(4, "hours")
        let time = moment(defaultTime.format("YYYY-MM-DD HH:00"))
        time_picker = time
        let hours = parseInt(defaultTime.format("H"))
        if(deliveryType ==='指定時間'){
          hours = parseInt(defaultTime.format("H"))
          for(let i=0; i< hours; i++){
            invalidTime.push(i)
          }
        }else{
          hours = parseInt(moment().add(1, "hours").format("H"))
          for(let i=0; i< hours; i++){
            invalidTime.push(i)
          }
          for(let i=hours+2; i< 24; i++){
            invalidTime.push(i)
          }
          time_picker = moment(`${moment().add(1, "hours").format("H")}:00`,"HH:mm")
        }
        
      }else{

        if(selectedDateType && deliveryType ==='指定時間'){
          time_picker = moment(`09:00`,"HH:mm")

        }else{
          if(deliveryType==='儘快') setSelectDate(moment())
          time_picker=null
        }
        invalidTime=[]

      }
      selectTimeForm.current?.setFieldsValue({selectTime: selectedDateType?'':time_picker})
      setSelectTime(time_picker)
      setDisabledTime(invalidTime)
  
    }
  },[selectDate,deliveryType])
  const editCarData = (value) => {
    let cars_ = cars;
    if (value.type === 1) {
      let new_cars = cars_.map((car) => {
        if (car.key === value.carData.key) {
          // car.previousData = car
          for (let key in value.carData) {
            car[key] = value.carData[key];
          }
        }
        return car;
      });
      setCars(new_cars);
    } else {
      let new_cars = cars_.map((car) => {
        if (car.key === value.key) {
          for (let key in value.iniCarData) {
            car[key] = value.iniCarData[key];
          }
        }
        return car;
      });
      setCars(new_cars);
    }
  }
  const submitData = async () => {
    selectTimeForm.current?.submit()
    if(deliveryType ==='指定時間' && !selectedDateType && !selectTime) return false
    let carUnfilled = cars.filter(c=>c.vehicle_model_id==="" && c.no !== "無法運送")
    if(CustomerInfo.case_type_id!=="014" && CustomerInfo.case_type_id!=="015" &&carUnfilled.length >0){
      let collapseKey = []
        let new_car = [...cars].map(c=> {
          let trigger=''
          if(c.vehicle_model_id === "" ){
            trigger = true
            collapseKey.push(c.key)
          }else{
            trigger= false
          }
          c.trigger_form_click =trigger
          return c
        })
        setCollapseActiveKey(collapseKey)
        setCars(new_car)
        return false
      } else{
      setLoading(true)
    }

    let place_arr =["origin","destination"]
    if (!customerInfo.case_status || customerInfo.case_status  === 99) {
      place_arr.forEach(key=>{
        if(!customerInfo[`${key}_lat`]) customerInfo[`${key}_lat`] = 0
        if(!customerInfo[`${key}_lng`]) customerInfo[`${key}_lng`] = 0
        let address = `${key}_address`
        if(!customerInfo[address]){
            let service_point = customerInfo[key][0].indexOf(",") > -1?customerInfo[key][0].split(",")[0]: customerInfo[key][0]
            customerInfo[`${key}_service_point`]=service_point
        }else{
          customerInfo[`${key}_county`] = customerInfo[key][1].split(",")[0]
          customerInfo[`${key}_town`] = customerInfo[key][2].split(",")[0]
          if (customerInfo[`${key}address_room`]) {
            customerInfo[address] = `${customerInfo[address]}${customerInfo[`${key}_address_room`]}室`
          }
        }
      })
      
    }
    //上單客戶
    if (customerInfo.orderCustomer) {
      let customer_arr = customerInfo.orderCustomer.split("/")
      customerInfo.order_customer = customer_arr[0]
      customerInfo.order_type = customer_arr[1]
    }
    customerInfo.transport_type = deliveryType;
    customerInfo.calling_source = sourceState;
    customerInfo.harbor_area_pass = harborPass?harborPass:'';
    customerInfo.note = CustomerInfo.note?CustomerInfo.note:''
    customerInfo.coupon = CustomerInfo.coupon?CustomerInfo.coupon:""
    customerInfo.customer_service_self_note = CustomerInfo.customer_service_self_note?CustomerInfo.customer_service_self_note:""
    let info_arr=['pick_up_person','pick_up_person_phone','delivery_person','delivery_person_phone','expense_attribution_unit']
    info_arr.forEach(key=> {
      customerInfo[key] = CustomerInfo[key]?CustomerInfo[key]:''
    })
    if (customerInfo.calling_time !== "") {
      customerInfo.calling_time = customerInfo.calling_time.format("HH:mm");
    }

    if (customerInfo.calling_date !== "") {
      customerInfo.calling_date = customerInfo.calling_date.format();
    }
    customerInfo.transport_date = moment(selectDate).format();
    if (customerInfo.transport_type === "指定時間") {
      if(!selectedDateType){
        customerInfo.transport_time_from = selectTime.format("HH:mm");
        customerInfo.transport_time_to = selectTime.add(1,"h").format("HH:mm");
      }else{
        //指定時間 →不指定時段 transport_type 帶入儘快 
        customerInfo.transport_type = '儘快'
      }
     
    } else {
      delete customerInfo.transport_time_from
      delete customerInfo.transport_time_to
      if(customerInfo.transport_type === '暫不配送'){
        customerInfo.transport_date = ''
      }
    }
    if(customerInfo.transport_type === '暫不配送'){
      customerInfo.is_draft = true
    }else{
      customerInfo.is_draft = false
    }

    delete customerInfo.carType1;
    let clone_cars = cars.map((c) => {
      let Obj = {}
      if (customerInfo.case_status) {
        //審核案件
        if (c.no === "無法運送") {
          Obj.is_cancel = true
        } else {
          Obj.is_cancel = false
        }
      }
      Obj.vehicle_model_id = c.CarType?c.CarType:c.vehicle_model_id;
      Obj.vehicle_sn = c.key;
      Obj.status = parseInt(c.mobility)
      Obj.VIN = !c.VIN ? "" : c.VIN
      // let draft = customerInfo.transport_type==="暫不配送"? false:true
      // if(customerInfo.case_status){
      //   Obj.is_draft = draft
      // }
      Obj.color = !c.color ? "" : c.color
      if (c.mobility === "0") {
        let unpower = c.unpower[0] ? c.unpower[0] : c.unpower;
        if (unpower) {
          Obj.report_content = unpower["description"] ? unpower["description"] : "";

          if (unpower["recordMethod"]) {
            Obj.report_way = parseInt(unpower["recordMethod"]);
          } else {
            let method;
            if (unpower["description"] !== "" && unpower.uploadFile.length > 0) {
              method = 0;
            } else if (unpower.uploadFile.length > 0 && unpower["description"] === "") {
              method = 1;
            } else if (unpower["description"] !== "" && unpower.uploadFile.length === 0) {
              method = 2;
            }
            c.report_way = method;
          }
          let report_picture = [];
          if (unpower["uploadFile"]) {
            unpower.uploadFile.forEach((file) => {
              let obj = {
                FileName: file.name,
                MediaType: file.type,
                Buffer: file.originFileObj,
              };
             
              if(file.originFileObj) report_picture.push(obj)
            });
            Obj.report_picture = report_picture;
          }
          Obj.delete_report_picture_row_id = unpower["removeFile"] ?unpower["removeFile"].map(c=>c.row_id):[]
          Obj.report_picture = unpower["uploadFile"] ? report_picture : []

        }
      }
      if(c.is_new_add){
        Obj.is_new_add = c.is_new_add
      }
      return Obj
    })
    customerInfo.vehicles = clone_cars
    let obj = {vehicles:clone_cars}
    if(customerInfo.power_status){
      delete customerInfo.report_way
      delete customerInfo.report_picture
    }else{
      if(customerInfo.report_picture){
        
        let report_picture = customerInfo.report_picture.filter(p=> !p.row_id)
        if(report_picture.length>0){
          customerInfo.report_picture = report_picture
        }else{
          delete customerInfo.report_picture
        }
      
      }
    }
  
    const formdata = new FormData();
    for (var key in customerInfo) {
      if (customerInfo[key] === null ) {
        formdata.append(key, '');
        obj[key] =''
      }
      if (key !== "vehicles") {
        obj[key] =customerInfo[key]
        formdata.append(key, customerInfo[key]);
      } else {
        
        if(CustomerInfo.case_type_id !== "014" && CustomerInfo.case_type_id !== "015"){
          customerInfo[key].forEach((c, i) => {
            if(customerInfo.case_status === 99){
              formdata.append(`vehicle_model_id`,c.vehicle_model_id?c.vehicle_model_id:"")
              formdata.append(`VIN`, c.VIN);
              formdata.append(`color`, c.color);
              formdata.append(`power_status`, c.status);
            }else{
              formdata.append(`vehicles[${i}].vehicle_sn`, c.vehicle_sn);
              formdata.append(`vehicles[${i}].vehicle_model_id`,c.vehicle_model_id?c.vehicle_model_id:"")
              formdata.append(`vehicles[${i}].VIN`, c.VIN);
              formdata.append(`vehicles[${i}].color`, c.color);
              formdata.append(`vehicles[${i}].power_status`, c.status);
            }
            if (!c.status) {
              if(customerInfo.case_status === 99){
                formdata.append('report_way',  c.report_way);

              }else{
                formdata.append(`vehicles[${i}].report_way`, c.report_way);
              }
              if(customerInfo.report_picture){
                customerInfo.report_picture.forEach((r,i)=>{
                  formdata.append(`report_picture[${i}]`, r);
        
                })
              }
              if (c.report_content) {
                formdata.append(customerInfo.case_status === 99?'report_content':`vehicles[${i}].report_content`, c.report_content);
              }
              if (c.report_picture && c.report_picture.length>0) {
                c.report_picture.forEach((p, index) => {
                  formdata.append(`vehicles[${i}].report_picture`, p.Buffer?p.Buffer:p);
                })
              }
              if(c.delete_report_picture_row_id.length>0){
                formdata.append(customerInfo.case_status === 99?'delete_report_picture_row_id':`vehicles[${i}].delete_report_picture_row_id`, c.delete_report_picture_row_id);
              }
     
            }
            if(customerInfo.calling_source === '客戶上單'){
            formdata.append(`vehicles[${i}].is_cancel`, c.is_cancel);
            if(c.is_new_add){
              formdata.append(`vehicles[${i}].is_new_add`, c.is_new_add);
            }
            }
          })
        }
      }
    }
    // console.log(customerInfo)
    // return false
    let fetch
    if(!customerInfo.case_status) {
      fetch = await PostAddCase(formdata)
    }else{
      if(customerInfo.case_status===99){
        fetch = await PutDraftCase(customerInfo.caseId,customerInfo.vehicle_sn, formdata)
      }else{
        fetch = await PutAddCase(customerInfo.caseId, formdata)

      }

    }
    
    if (fetch && !fetch.Error) {
      if(customerInfo.case_type_id === "014" || customerInfo.case_type_id === "015"){
        setLoading(false)
        navigate(`/cases/${fetch.Result}`,{state:{vehicle_sn: 1}})
      }else{
        setCurrentStep({
          currentStep: 2,
          caseId: !customerInfo.case_status ? fetch.Result : customerInfo.caseId,
          caseData: customerInfo,
          draft:customerInfo.is_draft
        });
        setLoading(false)
        dispatch(addCase([{ key: 1, mobility: "1", no: 1 }]))
      }

    }else{
      if(fetch && fetch.Error.StatusCode===500){
        confirm({
          icon: false,
          content: <p>{fetch.Error.Message}</p>,
          okText:'返回案件列表',
          cancelButtonProps:{ style: { display: 'none' } },
          // cancelText:'前往案件列表',
          onOk() {
            // navigate(`/cases/${customerInfo.caseId}`,{state:{vehicle_sn: customerInfo.vehicle_sn}})
            navigate(`/cases`)
          },
        });
      }
      // console.log(fetch)
    }
  };
  const formOnFinish = (value) => {
    let info = { ...CustomerInfo }
    let sources = {
      "LINE": ["line_group_name", "line_id"],
      "Email": ["calling_person_email", "calling_person_name", "calling_person_phone"],
      "080": ["calling_person_name", "calling_person_phone"],
      "傳真": ["calling_person_name", "calling_person_phone"],
      "現場加派": ["calling_person_name", "calling_person_phone"],
    }
    let arr = []
    for (let key in value) {
      info[key] = value[key];
      if (value[key] && moment.isMoment(value[key])) {
        let utc = moment(value[key]).utc().format();
        if (key === "calling_time") {
          info[key] = moment(value[key]).format("HH:mm");
        } else {
          info[key] = utc;
        }
      }
    }

    sources[sourceState].forEach(state=>{
      if(value[state]){
        arr.push(value[state])
      }
    })
    info.calling_arr = arr
    setCustomerInfo(info)
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", minHeight: "calc(100vh - 152px)" }}>
      <div className="step_content">
        {
          remark && remark!==""  ?(
          <div style={{marginBottom:24}}>
          <Alert message={<>
            <p>客戶上單備註</p>
            <p>{remark}</p>
          </>} type="info" showIcon />
          </div>):(<></>)
        }
        <div
          style={{
            maxWidth: "720px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <p style={{ fontWeight: 500 }}>
            <span style={{ color: "#FF4D4F", paddingRight: 4 }}>*</span>運送時間
          </p>
          {
            deliveryType !== "暫不配送" ?(
              <p>
              {deliveryType !== "儘快" ? `${selectDate.format("M")} 月 ${selectDate.format("D")} 日  ${selectTime
                ? `${moment(selectTime).format("HH:00")} - ${moment(selectTime).add(1,"h").format("HH:00")}` : ""
                }` : `${selectDate.format("M")} 月 ${selectDate.format("D")} 日`}
            </p>
            ):(<p>暫不配送</p>)
          }
        </div>
        <Radio.Group
          defaultValue={deliveryType}
          buttonStyle="solid"
          onChange={(e) => {
            setDeliveryType(e.target.value)
            setSelectedDateType(false)
          }}
          style={{ marginBottom: 8 }}
        >
          <Radio.Button value="儘快">儘快</Radio.Button>
          <Radio.Button value="指定時間">指定時間</Radio.Button>
          {
            !customerInfo.is_VIP && (<Radio.Button value="加價特急件">加價特急件</Radio.Button>)
          }
          {
            customerInfo.case_status !== 99&& (
              <Radio.Button value="暫不配送">暫不配送存為草稿</Radio.Button>
            )
          }
        </Radio.Group>
          {
            deliveryType ==="暫不配送" && (
              <Alert showIcon={true} message=" 案件將會以草稿的形式被新增，並不會進入媒合階段，直至案件被指定運送時間。" banner style={{boxShadow: "0px 2px 8px 0px #00000026", background:'white', marginTop:8}}/>
            )
          }
        { deliveryType === "指定時間" ? (
          <>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(7, 1fr)",
                  gridColumnGap: 8,
                  maxWidth: "720px",
                  textAlign: "center",
                }}
              >
                {dateRange.map((d, i) => {
                  let select_date = moment(selectDate).format("M/D");
                  return (
                    <div
                      key={i}
                      style={{
                        boxSizing: "border-box",
                        border: select_date === d.date ? "1px solid transparent" : "1px solid #D9D9D9",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        height: 108,
                        width: 96,
                        cursor: "pointer",
                        background:
                          select_date === d.date ? "#1890FF" : "white",
                        color: select_date === d.date ? "white" : "black",
                      }}
                      onClick={() => {

                        setSelectDate(d.moment);
                        setSelectedDateType(false)
                        // let now = moment().format("YYYY-MM-DD")
                        // if(selectedDateType && now !== d.moment.format("YYYY-MM-DD")){
                        //   setSelectTime(moment("09:00","HH:mm"))
                        // }
                        
                        setPickerOpen(false);
                      }}
                    >
                      <p style={{ paddingBottom: 8, fontWeight: 500 }}>
                        {d.day}
                      </p>
                      <p>{d.date}</p>
                      {moment().format("M/DD") === d.date ? <p>今天</p> : ""}
                    </div>
                  );
                })}
              </div>
              <div style={{ position: "relative" }}>
                <DatePicker
                  open={pickerOpen}
                  defaultValue={selectDate}
                  value={selectDate}
                  style={{ opacity: 0, position: "absolute", zIndex: -1 }}
                  onChange={(date) => {
                    setPickerOpen(!pickerOpen);
                    let click_date = date.format("M/D");
                    let inner = dateRange.filter((d) => d.date === click_date);
                    if (inner.length === 0) {
                      setIniDate(date);
                    }
                    setSelectDate(date);
                  }}
                  disabledDate={(current) => {
                    let now = moment().format("YYYY-MM-DD");
                    return current < moment(`${now} 00:00:00`);
                  }}
                />
                <CalendarOutlined
                  style={{
                    marginLeft: 24,
                    borderRadius: "50%",
                    width: 32,
                    height: 32,
                    border: "1px solid #D9D9D9",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => setPickerOpen(!pickerOpen)}
                />
              </div>
            </div>
            <div style={{display:"flex", marginTop:12}}>
            
            <Form
              ref={selectTimeForm}
              initialValues={{}}
              name="basic"
              onFinish={(val)=>{
              }}
              onFinishFailed={()=>{
                selectedTimeInput.current.focus()
              }}
              autoComplete="off"
            >
              <Form.Item
                  label=""
                  name="selectTime"
                  rules={[{ required: !selectedDateType?true:false, message: '請選擇時間！' }]}
                >
                  <TimePicker
                    ref={selectedTimeInput}
                    showNow={false}
                    hideDisabledOptions={true}
                    disabledHours={()=>disabledTime}
                    value={selectTime ? selectTime : ''}
                    style={{ marginTop: 8 }}
                    format={"HH:mm"}
                    minuteStep={60}
                    disabled={selectedDateType?true:false}
                    onChange={(time) => setSelectTime(time)}
                  />
              </Form.Item> 
            </Form>
                <div style={{}}>
                  <Button 
                  style={{marginLeft:8, marginTop:9}}
                  type={selectedDateType?'primary':''}
                  onClick={(e)=>{
                  
                    setSelectedDateType(!selectedDateType)
                    // let today =moment().format("YYYY-MM-DD")
                    // selectTimeForm.current.setFieldsValue({selectTime: !selectedDateType?selectTime:''})
                    // if(!selectedDateType && today!==moment(selectDate).format("YYYY-MM-DD")){
                    //   setSelectTime(null)

                    // }else{
                    //   // setSelectTime(null)
                    // }
                    selectTimeForm.current?.setFieldsValue({selectTime: ''})
                    setSelectTime(null)
                  }}
                  >不指定時段</Button>
                </div>
              </div>
          </>
        ) : (
          <></>
        )}
        {
          customerInfo.case_type_id !== "014" && customerInfo.case_type_id !== "015"&&cars.length > 0 && (
            <div>
              <p style={{ fontWeight: 500, padding: "24px 0 8px" }}> 運送車輛</p>
            </div>
          )
        }
        <div style={{ maxWidth: 934 }}>
          <div className="collapseCar">
            {customerInfo.case_type_id !== "014" && customerInfo.case_type_id !== "015" && cars.map((c) => {
              let car_type = "-";
              if (CarBrand && CarBrand.length > 0) {
                let filter_model = CarBrand.filter(car => car.Name === c.CarBrand)
                if (filter_model.length > 0) {
                  let model = filter_model[0]["children"].filter(m=>m.value === c.CarType)
                  if(model.length>0){
                    car_type =`${filter_model[0]["Name"]} / ${model[0]["label"]}` 
                  }
                }
              }
              if(c.client_case_photos&&c.client_case_photos.length > 0) car_type='拍照上單'
              return (
                <Collapse
                  accordion
                  activeKey={collapseActiveKey}
                  onChange={(e) => setCollapseActiveKey([e])}
                >
                  <Panel
                    collapsible={c.no === '無法運送' ? "disabled" : ''}
                    forceRender={true}
                    key={c.key}
                    header={
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                          padding: "16px  0",
                        }}
                      >
                        <div
                          style={{
                            background: "rgba(250, 250, 250, 1)",
                            border: "1px solid rgba(217, 217, 217, 1)",
                            padding: "4px 8px",
                          }}
                        >
                          {c.no}
                        </div>
                        <div
                          style={{
                            borderRight: "1px solid rgba(0, 0, 0, 0.03)",
                            paddingLeft: 24,
                            width: "30%",
                          }}
                        >
                          <p
                            style={{
                              fontSize: 14,
                              color: "rgba(0, 0, 0, 0.45)",
                            }}
                          >
                            廠牌 / 車型
                          </p>
                          <p style={{ fontSize: 24, fontWeight: 400 }}>
                            {car_type}
                          </p>
                        </div>
                        <div
                          style={{
                            borderRight: "1px solid rgba(0, 0, 0, 0.03)",
                            paddingLeft: 24,
                            width: "30%",
                          }}
                        >
                          <p
                            style={{
                              fontSize: 14,
                              color: "rgba(0, 0, 0, 0.45)",
                            }}
                          >
                            車牌 / 車身 / 引擎號碼
                          </p>
                          <p style={{ fontSize: 24, fontWeight: 400 }}>
                            {c.VIN ? c.VIN : "-"}
                          </p>
                        </div>
                        <div
                          style={{
                            borderRight: "1px solid rgba(0, 0, 0, 0.03)",
                            paddingLeft: 24,
                            width: "20%",
                          }}
                        >
                          <p
                            style={{
                              fontSize: 14,
                              color: "rgba(0, 0, 0, 0.45)",
                            }}
                          >
                            動力狀況
                          </p>
                          <p style={{ fontSize: 24, fontWeight: 400 }}>
                            {c.mobility === "1" ? "正常" : "無動力"}
                          </p>
                        </div>
                        {
                          customerInfo.case_status!==99&&(
                            <div style={{ paddingLeft: 24, display: "grid", gridTemplateColumns: "repeat(2, 1fr)" }}>
                          {
                            c.no === '無法運送' ? (<>
                              <Button
                                style={{ padding: 0, marginRight: 8 }}
                                type="link"
                                disabled
                              >
                                複製
                              </Button>
                              <Button
                                style={{ padding: 0, }}
                                type="link"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  let new_cars = [...cars];
                                  new_cars = new_cars.map(car => {
                                    if (c.vehicle_sn === car.vehicle_sn) car.no = car.key
                                    return car
                                  })
                                  setCars(new_cars);
                                }}
                              >
                                回復
                              </Button>
                            </>) : (<>
                              <Button
                                style={{ padding: 0 }}
                                type="link"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  let new_cars = [...cars];
                                  let obj = {
                                    key: new_cars.length + 1,
                                    no: new_cars.length + 1,
                                    CarPlate: "",
                                    CarType: c.CarType,
                                    CarBrand: c.CarBrand,
                                    mobility: c.mobility,
                                    is_new_add:true
                                  };
                                  setCollapseActiveKey([new_cars.length + 1]);
                                  new_cars.push(obj);
                                  // new_cars = new_cars.filter(n=> n.key !== c.key)
                                  setCars(new_cars);
                                }}
                              >
                                複製
                              </Button>
                              <Button
                                style={{ padding: c.power_status ?0:"4px 15px" }}
                                type="link"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  let new_cars = [...cars];
                                  if (c.case_status) {
                                    new_cars = new_cars.map(car => {
                                      if (c.vehicle_sn === car.vehicle_sn) car.no = '無法運送'
                                      return car
                                    })
                                  } else {
                                    new_cars = new_cars.filter(
                                      (n) => n.key !== c.key
                                    );
                                  }
                                  setCars(new_cars);
                                }}
                              >
                                {c.power_status ? "取消運送" : '刪除'}
                              </Button>
                            </>)
                          }
                        </div>
                          )
                        }
                       
                      </div>
                    }
                  >
                    <CarExpandRow
                      data={c}
                      submitForm={c.trigger_form_click}
                      setData={(value) => { editCarData(value) }}
                      style={{ padding: "16px 0" }}
                      CarBrand={CarBrand}
                    />
                  </Panel>
                </Collapse>
              );
            })}
          </div>
          {
            customerInfo.case_type_id !== "014" && customerInfo.case_type_id !== "015" && cars.length > 0 && customerInfo.case_status!==99 && (<Button
              style={{ marginTop: "16px" }}
              type="dashed"
              block
              onClick={() => {
                let new_cars = [...cars];
                let obj = {
                  key: new_cars.length + 1,
                  no: new_cars.length + 1,
                  CarPlate: "",
                  CarType: "",
                  mobility: "1",
                };
                if(customerInfo.calling_source === '客戶上單'){
                  obj.is_new_add=true
                }
                new_cars.push(obj);
                setCollapseActiveKey([new_cars.length]);
                setCars(new_cars);
              }}
            >
              <PlusOutlined /> 新增車輛
            </Button>)
          }
          <div style={{ marginTop: 24 }} className="info_block">
            <div style={{ fontSize: 24, paddingBottom: 16 }}>案件資訊</div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingBottom: 16,
              }}
            >
           
              <p style={{ fontWeight: 500, fontSize: 16 }}>客戶別</p>
              <p style={{ color: "rgba(0, 0, 0, 0.45)", paddingRight: 8 }}>
                {customerInfo.customer}
              </p>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingBottom: 8,
              }}
            >
              <p style={{ fontWeight: 500, fontSize: 16 }}>服務類型</p>
              <p style={{ color: "rgba(0, 0, 0, 0.45)", paddingRight: 8 }}>
                {customerInfo.case_type}
              </p>
            </div>
            <Collapse accordion>
              {
                harborPass && (<Panel
                  header={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        alignItems: "center",
                      }}
                    >
                      <p style={{ fontWeight: 500, fontSize: 16 }}>
                        港務通行證辦理
                      </p>
                      <p style={{ color: "rgba(0, 0, 0, 0.45)" }}>{harborPass}</p>
                    </div>
                  }
                >
                  <Form
                    name="basic"
                    layout="vertical"
                    initialValues={{ harbor_area_pass: CustomerInfo.harbor_area_pass }}
                    onFinish={(formOnFinish)}
                    autoComplete="off"
                  >
                    <Form.Item
                      label=""
                      name="harbor_area_pass"
                    >
                      <Radio.Group
                        buttonStyle="solid"
                        onChange={(value) => setHarborPass(value.target.value)}
                      >
                        <Radio.Button value="委託方辦理">
                          委託方辦理
                        </Radio.Button>
                        <Radio.Button value="司機辦理">司機辦理</Radio.Button>
                      </Radio.Group>
                    </Form.Item>
                    <div style={{ display: "flex" }}>
                      <Button
                        type="primary"
                        htmlType="submit"
                        style={{ marginRight: 8 }}
                      > 保存 </Button>
                      <Button>回復至修改前</Button>
                    </div>
                  </Form>
                </Panel>)
              }
              {
                CustomerInfo.car_hood_type && (<Panel
                  header={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        alignItems: "center",
                      }}
                    >
                      <p style={{ fontWeight: 500, fontSize: 16 }}> 車罩需求 </p>
                      <p style={{ color: "rgba(0, 0, 0, 0.45)" }}>{CustomerInfo.car_hood_type ==="0"?"委託方自備":CustomerInfo.car_hood_type ==="1"?"全鋒代準備":"其他"}</p>
                    </div>
                  }
                >

                  <Form
                    name="basic"
                    layout="vertical"
                    initialValues={{
                      car_hood_type: CustomerInfo.car_hood_type,
                      car_hood_note_txt:CustomerInfo.car_hood_note_txt
                    }}
                    onFinish={(formOnFinish)}
                    autoComplete="off"
                  >
                   <Form.Item
                      label=""
                      name="car_hood_type"
                      rules={[{ required: true, message: "請輸入拍賣單號!" }]}
                    >
                      <Radio.Group
                      buttonStyle="solid"
                      // defaultValue={CustomerInfo.car_hood_type}
                      onChange={(e) => {
                        formOnFinish({ car_hood_type: e.target.value })
                      }}
                    >
                      <Radio.Button value="0"> 委託方自備 </Radio.Button>
                      <Radio.Button value="1">全鋒代準備</Radio.Button>
                      <Radio.Button value="2">其他</Radio.Button>
                    </Radio.Group>

                    </Form.Item>
                    
                  {
                    CustomerInfo.car_hood_type === "2" && (
                      <Form.Item
                      label="車罩說明"
                      name="car_hood_note_txt"
                      rules={[{ required: true, message: "請簡述車罩!" }]}
                    >
                       <Input
                        allowClear
                        placeholder="請簡述車罩"
                        style={{ width: 304, display: "flex" }}
                      />
                    </Form.Item>
                     
                    )
                  }
                    <div style={{ display: "flex" }}>
                      <Button
                        type="primary"
                        htmlType="submit"
                        style={{ marginRight: 8 }}
                      > 保存 </Button>
                      <Button>回復至修改前</Button>
                    </div>
                  </Form>
                 
                
                </Panel>)
              }
              {
                CustomerInfo.auction_no && (<Panel
                  header={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        alignItems: "center",
                      }}
                    >
                      <p style={{ fontWeight: 500, fontSize: 16 }}>
                        拍賣單號
                      </p>
                      <p style={{ color: "rgba(0, 0, 0, 0.45)" }}>{CustomerInfo.auction_no}</p>
                    </div>
                  }
                >
                  <Form
                    name="basic"
                    layout="vertical"
                    initialValues={{
                      auction_no: CustomerInfo.auction_no
                    }}
                    onFinish={(formOnFinish)}
                    autoComplete="off"
                  >
                    <Form.Item
                      label="拍賣單號"
                      name="auction_no"
                      rules={[{ required: true, message: "請輸入拍賣單號!" }]}
                    >
                      <Input
                        style={{ width: 304 }}
                      />
                    </Form.Item>
                    <div style={{ display: "flex" }}>
                      <Button
                        type="primary"
                        htmlType="submit"
                        style={{ marginRight: 8 }}
                      > 保存 </Button>
                      <Button>回復至修改前</Button>
                    </div>
                  </Form>
                </Panel>)
              }
              {
                CustomerInfo.components_note && (
                  <Panel
                    header={
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                          alignItems: "center",
                        }}
                      >
                        <p style={{ fontWeight: 500, fontSize: 16 }}> 運送品項件數 </p>
                        <p style={{ color: "rgba(0, 0, 0, 0.45)" }}>{CustomerInfo.components_note}</p>
                      </div>
                    }
                  >
                    <Form
                      name="basic"
                      layout="vertical"
                      initialValues={{
                        components_note: CustomerInfo.components_note
                      }}
                      onFinish={(formOnFinish)}
                      autoComplete="off"
                    >
                      <Form.Item
                        label="運送品項、件數"
                        name="components_note"
                        rules={[{ required: true, message: "請輸入運送品項、件數!" }]}
                      >
                        <Input
                          style={{ width: 304 }}
                        />
                      </Form.Item>
                      <div style={{ display: "flex" }}>
                        <Button
                          type="primary"
                          htmlType="submit"
                          style={{ marginRight: 8 }}
                        > 保存 </Button>
                        <Button>回復至修改前</Button>
                      </div>
                    </Form>
                  </Panel>
                )
              }
              <Panel
                header={
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      alignItems: "center",
                    }}
                  >
                    <p style={{ fontWeight: 500, fontSize: 16 }}>費用相關</p>
                    <p style={{ color: "rgba(0, 0, 0, 0.45)" }}>
                      {CustomerInfo ? CustomerInfo.project_type : ''}
                    </p>
                  </div>
                }
              >
                <Form
                  name="basic"
                  layout="vertical"
                  initialValues={CustomerInfo ? CustomerInfo : {}}
                  onFinish={formOnFinish}
                  autoComplete="off"
                >
                  <Form.Item
                    label="專案別"
                    name="project_type"
                    style={{ display: "block" }}
                  >
                    <Input disabled style={{ width: 304 }} />
                  </Form.Item>
                  <Form.Item label="請款對象" name="request_for_payment">
                    <Input disabled style={{ width: 304 }} />
                  </Form.Item>
                  <Form.Item
                    label="費用歸屬單位"
                    name="expense_attribution_unit"
                  >
                    <Input style={{ width: 304 }} />
                  </Form.Item>
                  <div style={{ display: "flex" }}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{ marginRight: 8 }}
                    > 保存 </Button>
                    <Button>回復至修改前</Button>
                  </div>
                </Form>
              </Panel>
              {
                cars.length > 0 && (
                  <>
                    <Panel
                      header={
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                            alignItems: "center",
                          }}
                        >
                          <p style={{ fontWeight: 500, fontSize: 16 }}>交車人</p>
                          <p style={{ color: "rgba(0, 0, 0, 0.45)" }}>
                            {CustomerInfo.delivery_person ? CustomerInfo.delivery_person : '-'}
                            {CustomerInfo.delivery_person_phone ? ` / ${CustomerInfo.delivery_person_phone}` : ''}
                          </p>
                        </div>
                      }
                    >
                      <Form
                        name="basic"
                        layout="vertical"
                        initialValues={customerInfo ? customerInfo : {}}
                        onFinish={formOnFinish}
                        autoComplete="off"
                      >
                        <div style={{ display: "flex" }}>
                          <Form.Item
                            label="交車人資訊"
                            name="delivery_person"
                            style={{ display: "block" }}
                          >
                            <Input prefix={<UserOutlined />} style={{ width: 304 }} />
                          </Form.Item>
                          <div>
                            <div style={{ height: 30 }}></div>
                            <Form.Item
                              label=""
                              name="delivery_person_phone"
                              style={{ display: "block", margin: "0 16px" }}
                            >
                              <Input
                                prefix={<PhoneOutlined />}
                                style={{ width: 304 }}
                              />
                            </Form.Item>
                          </div>
                        </div>
                        <div style={{ display: "flex" }}>
                          <Button
                            type="primary"
                            htmlType="submit"
                            style={{ marginRight: 8 }}
                          >
                            保存
                          </Button>
                          <Button>回復至修改前</Button>
                        </div>
                      </Form>
                    </Panel>
                    <Panel
                      header={
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                            alignItems: "center",
                          }}
                        >
                          <p style={{ fontWeight: 500, fontSize: 16 }}>接車人</p>
                          <p style={{ color: "rgba(0, 0, 0, 0.45)" }}>
                            {CustomerInfo.pick_up_person ? CustomerInfo.pick_up_person : '-'}
                            {CustomerInfo.pick_up_person_phone ? ` / ${CustomerInfo.pick_up_person_phone}` : ''}
                          </p>
                        </div>
                      }
                    >
                      <Form
                        name="basic"
                        layout="vertical"
                        initialValues={customerInfo ? customerInfo : {}}
                        onFinish={formOnFinish}
                        autoComplete="off"
                      >
                        <div style={{ display: "flex" }}>
                          <Form.Item
                            label="接車人資訊"
                            name="pick_up_person"
                            style={{ display: "block" }}
                          >
                            <Input prefix={<UserOutlined />} style={{ width: 304 }} />
                          </Form.Item>
                          <div>
                            <div style={{ height: 30 }}></div>
                            <Form.Item
                              label=""
                              name="pick_up_person_phone"
                              style={{ display: "block", margin: "0 16px" }}
                            >
                              <Input
                                prefix={<PhoneOutlined />}
                                style={{ width: 304 }}
                              />
                            </Form.Item>
                          </div>
                        </div>
                        <div style={{ display: "flex" }}>
                          <Button
                            type="primary"
                            htmlType="submit"
                            style={{ marginRight: 8 }}
                          >
                            保存
                          </Button>
                          <Button>回復至修改前</Button>
                        </div>
                      </Form>
                    </Panel>
                  </>
                )
              }
              {
                customerInfo.calling_source === '客戶上單' ? (<>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      padding: 8,
                      alignItems: "center",
                    }}
                  >
                    <p style={{ fontWeight: 500, fontSize: 16 }}>案件來源</p>
                    <p style={{ color: "rgba(0, 0, 0, 0.45)" }}>
                      {sourceState}
                      {
                        CustomerInfo.calling_arr && CustomerInfo.calling_arr.map((c, i) => {
                          let text = ''
                          if (c !== "") {
                            {/* text += i === 0 ? c : ` / ${c}` */}
                            text += ` / ${c}`
                          }
                          return text
                        })
                      }
                    </p>
                  </div>
                </>) : (<>
                  <Panel
                    showArrow={customerInfo.calling_source === '客戶上單' ? false : true}

                    header={
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                          alignItems: "center",
                        }}
                      >
                        <p style={{ fontWeight: 500, fontSize: 16 }}>案件來源</p>
                        <p style={{ color: "rgba(0, 0, 0, 0.45)" }}>
                        {sourceState}
                          {
                            CustomerInfo.calling_arr && CustomerInfo.calling_arr.map((c, i) => {
                              let text = ''
                              if (c !== "") {
                                {/* text += i === 0 ? c : ` / ${c}` */}
                                text += ` / ${c}`
                              }
                              return text
                            })
                          }
                        </p>
                      </div>
                    }
                  >
                    <Form
                      name="basic"
                      layout="vertical"
                      initialValues={customerInfo ? customerInfo : { calling_source: sourceState }}
                      onFinish={formOnFinish}
                      autoComplete="off"
                    >
                      <Form.Item
                        label="叫車來源"
                        name="calling_source"
                        rules={[{ required: true, message: "" }]}
                      >
                        <Radio.Group
                          buttonStyle="solid"
                          onChange={(e) => setSourceState(e.target.value)}
                        >
                          <Radio.Button value="LINE">LINE</Radio.Button>
                          <Radio.Button value="Email">Email</Radio.Button>
                          <Radio.Button value="080">080</Radio.Button>
                          <Radio.Button value="傳真">傳真</Radio.Button>
                          <Radio.Button value="現場加派">現場加派</Radio.Button>
                        </Radio.Group>
                      </Form.Item>
                      <div style={{ display: "flex" }}>
                        {sourceState === "LINE" ? (
                          <>
                            <Form.Item
                              label="群組名稱"
                              name="line_group_name"
                              style={{ marginRight: "16px" }}
                            >
                              <Input
                                allowClear
                                placeholder="example"
                                style={{ width: "304px" }}
                              />
                            </Form.Item>
                            <Form.Item label="LINE ID" name="line_id">
                              <Input
                                allowClear
                                placeholder="example"
                                style={{ width: "304px" }}
                              />
                            </Form.Item>
                          </>
                        ) : (
                          ""
                        )}
                        {sourceState === "Email" ? (
                          <>
                            <Form.Item
                              label="信箱"
                              name="calling_person_email"
                              style={{ marginRight: "16px" }}
                            >
                              <Input
                                allowClear
                                placeholder="example"
                                style={{ width: "264px" }}
                              />
                            </Form.Item>
                          </>
                        ) : (
                          ""
                        )}
                        {sourceState !== "LINE" ? (
                          <>
                            <Form.Item
                              label="姓名"
                              name="calling_person_name"
                              style={{ marginRight: "16px" }}
                            >
                              <Input
                                allowClear
                                placeholder="example"
                                style={{ width: "304px" }}
                              />
                            </Form.Item>
                            <Form.Item label="電話" name="calling_person_phone">
                              <Input
                                allowClear
                                placeholder="example"
                                style={{ width: "304px" }}
                              />
                            </Form.Item>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                      <div style={{ display: "flex" }}>
                        <Form.Item
                          label="叫車日期"
                          name="calling_date"
                          style={{ marginRight: "16px" }}
                        >
                          <DatePicker
                            format={"YYYY/MM/DD"}
                            style={{ width: 258 }}
                          />
                        </Form.Item>

                        <Form.Item label="叫車時間" name="calling_time">
                          <TimePicker style={{ width: 128 }} format={"HH:mm"} />
                        </Form.Item>
                      </div>

                      <div style={{ display: "flex" }}>
                        <Button
                          type="primary"
                          htmlType="submit"
                          style={{ marginRight: 8 }}
                        >
                          保存
                        </Button>
                        <Button>回復至修改前</Button>
                      </div>
                    </Form>
                  </Panel>
                </>)
              }

              <Panel
                showArrow={CustomerInfo.note ? true : false}
                header={
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      alignItems: "center",
                    }}
                  >
                    <p style={{ fontWeight: 500, fontSize: 16 }}>
                      有想對技師說的話嗎？
                    </p>
                    <Button type="link" style={{ padding: 0 }}>
                        {CustomerInfo.note?(<p style={{ color: "rgba(0, 0, 0, 0.45)" }}>{CustomerInfo.note}</p>):"新增"}
                    </Button>
                  </div>
                }
              >
                <Form
                  // ref={formRef}
                  name="basic"
                  layout="vertical"
                  // initialValues={{ remark: remark ? remark : "" }}
                  onFinish={ formOnFinish}
                  autoComplete="off"
                >
                  <Form.Item
                    label="備註"
                    name="note"
                    // rules={[{ required: true, message: '請輸入接車人姓名!' }]}
                    style={{ display: "block" }}
                  >
                    <Input.TextArea
                      showCount
                      maxLength={100}
                      style={{ width: 400 }}
                    />
                  </Form.Item>
                  <div style={{ display: "flex" }}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{ marginRight: 8 }}
                    >
                      保存
                    </Button>
                    <Button
                      onClick={() => {
                        // formOnFinish({note:customerInfo.note})
                        // console.log(customerInfo)
                        // formRef.current.setFieldsValue({ note:remark });
                      }}
                    >
                      取消
                    </Button>
                  </div>
                </Form>
              </Panel>
              <Panel
                showArrow={CustomerInfo.customer_service_self_note ? true : false}
                header={
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      alignItems: "center",
                    }}
                  >
                    <p style={{ fontWeight: 500, fontSize: 16 }}>
                    有額外的注意事項嗎？
                    </p>
                    <Button type="link" style={{ padding: 0 }}>
                        {CustomerInfo.customer_service_self_note	?(<p style={{ color: "rgba(0, 0, 0, 0.45)" }}>{CustomerInfo.customer_service_self_note	}</p>):"新增"}
                    </Button>
                  </div>
                }
              >
                <Form
                  // ref={formRef}
                  name="basic"
                  layout="vertical"
                  // initialValues={{ remark: remark ? remark : "" }}
                  onFinish={formOnFinish}
                  autoComplete="off"
                >
                  <Form.Item
                    label="備註"
                    name="customer_service_self_note"
                    // rules={[{ required: true, message: '請輸入接車人姓名!' }]}
                    style={{ display: "block" }}
                  >
                    <Input.TextArea
                      showCount
                      maxLength={100}
                      style={{ width: 400 }}
                    />
                  </Form.Item>
                  <div style={{ display: "flex" }}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{ marginRight: 8 }}
                    >
                      保存
                    </Button>
                    <Button
                      onClick={() => {
                        // console.log(customerInfo)
                        // formOnFinish()
                        // formRef.current.setFieldsValue({ remark });
                      }}
                    >
                      取消
                    </Button>
                  </div>
                </Form>
              </Panel>
              <Panel
                showArrow={CustomerInfo.coupon ?true:false}
                header={
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      alignItems: "center",
                    }}
                  >
                    <p style={{ fontWeight: 500, fontSize: 16 }}>
                      您有折價卷要使用嗎？
                    </p>
                    <Button type="link" style={{ padding: 0 }}>
                        {CustomerInfo.coupon?(<p style={{ color: "rgba(0, 0, 0, 0.45)" }}>{CustomerInfo.coupon}</p>):"新增"}
                    </Button>
                  </div>
                }
              >
                <Form
                  name="basic"
                  layout="vertical"
                  onFinish={formOnFinish}
                  // onFinishFailed={onFinishFailed}
                  autoComplete="off"
                >
                  <Form.Item
                    label="折價券"
                    name="coupon"
                    style={{ display: "block" }}
                  >
                    <Input.TextArea
                      showCount
                      maxLength={100}
                      style={{ width: 400 }}
                    />
                  </Form.Item>
                  <div style={{ display: "flex" }}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{ marginRight: 8 }}
                    > 保存 </Button>
                    <Button>取消</Button>
                  </div>
                </Form>
              </Panel>
            </Collapse>
          </div>
        </div>
      </div>
      <div
        style={{
          background: "white",
          marginTop: 16,
          width: "100%",
          padding: "20px",
          display: "flex",
          alignItems:"center",
          justifyContent: customerInfo.client_case_photos?"space-between":"flex-end",
        }}
      >
      {
        customerInfo.client_case_photos ? (<div className='add_step_2_footer' style={{display:"grid", width:"calc(100% - 296px)",gridGap:24, gridTemplateColumns:"repeat(3,1fr)"}}>
            <div className='label_block'>
              <label>上單客戶</label>
              <p>{customerInfo.order_customer_name?customerInfo.order_customer_name:"-"}</p>
            </div>
            <div className='label_block'>
              <label>姓名</label>
              <p>{customerInfo.create_name?customerInfo.create_name:"-"}</p>
            </div>
            <div className='label_block'>
              <label>電話</label>
              <p className="phone">{customerInfo.order_customer_phone?customerInfo.order_customer_phone:"-"}</p>
            </div>
        </div>):(<></>)
      }
        <div style={{display:"grid", gridTemplateColumns:`repeat(${customerInfo.client_case_photos?"3":"2"},1fr)`, gridGap:24}}>
          <Popconfirm
            style={{ width: 410 }}
            placement="top"
            title={
              <div>
                <div>
                  <strong>新增還沒完成喔</strong>
                </div>
                <div style={{ fontSize: 14 }}>剛剛輸入的所有資訊將不被保存。</div>
              </div>
            }
            onConfirm={(v) => { }}
            onCancel={(v) => {
              dispatch(addCase([{ key: 1, mobility: "1", no: 1 }]))
              navigate("/cases");
            }}
            okText="不，我要留下"
            cancelText="對，我要離開"
          >
            <Button type="text">
              關閉
            </Button>
          </Popconfirm>
          {
            customerInfo.client_case_photos ? (
              <Popconfirm
            style={{ width: 410 }}
            placement="top"
            title="確定整單取消"
            onConfirm={(v) => { 
              setLoading(true)
              CancelAllCases(customerInfo.caseId).then(res=>{
                if(res && !res.Error){
                  navigate("/")
                }
              })
            }}
            onCancel={(v) => {
              dispatch(addCase([{ key: 1, mobility: "1", no: 1 }]))
              navigate("/cases");
            }}
            // okText="不，我要留下"
            // cancelText="對，我要離開"
          >
             <Button
              loading={loading}
                type=""
              >整單取消</Button>
          </Popconfirm>
             
            ) : (<></>)
          }
          <Button
            type="primary"
            icon={<ArrowRightOutlined />}
            htmlType="submit"
            onClick={submitData}
            loading={loading}
          >
            {customerInfo.client_case_photos ? "送出" : "新增"}
            {loading ? "中" : ""}

          </Button>
        </div>
      </div>
    </div>
  );
};


export default Step2;

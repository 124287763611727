import React from 'react'
import { Tag, Card, Timeline } from 'antd'
import { EnvironmentOutlined, CaretUpOutlined, CaretDownOutlined, ArrowRightOutlined } from '@ant-design/icons'
import moment from 'moment'
import { Link, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
const TimeLine = ({ fetchData }) => {
    let CasesList = useSelector(state => state.CasesList)
    const navigate = useNavigate()
    return (
        <Card
            style={{ width: "100%", margin: "24px 0" }}
            title="執行內容"
        >
            <Timeline style={{ color: "rgba(0, 0, 0, 0.45)" }}>
                {
                    fetchData.route.map((e, i) => {
                        let dot = e.status === "完成" ? doneDot : e.status === "執行中" ? activeDot : greyDot
                        let line = e.status === "完成" ? "#BAE7FF" : e.status === "執行中" ? "#1890FF" : "gray"
                        return (
                            <Timeline.Item key={e.id} color={line} dot={<div style={dot}></div>}>
                                <div style={{ display: 'flex', justifyContent: "space-between", marginBottom: 8 }}>
                                    <p style={{ fontSize: 16 }}>{e.category}</p>
                                    <p ><EnvironmentOutlined /> {e.place}</p>
                                </div>
                                {
                                    e.vehicles.map(d => {
                                        let tag_color = ""
                                        if (e.status === "完成") {
                                            tag_color = "lime"
                                        } else if (e.status === "執行中") {
                                            tag_color = d.action === "上架" ? "#52C41A" : "#1890FF"
                                        } else {
                                            tag_color = 'rgba(0, 0, 0, 0.25)'
                                        }
                                        return (
                                            <div key={d.row_id} style={{ display: "flex", alignItems: "center", borderBottom: "1px solid rgba(240, 240, 240, 1)", padding: "8px 0" }}>
                                                <Tag icon={d.action === "上架" ? <CaretUpOutlined /> : <CaretDownOutlined />} color={tag_color}>{d.action}</Tag>

                                                <div style={{ width: "40%", margin: "0 16px" }}>
                                                    <p style={{ fontWeight: 700, color: "rgba(0,0,0,0.8)", fontSize: 16, paddingBottom: 6 }}>{d.VIN}</p>
                                                    <p>{d.case_id} / {d.customer} / {d.vehicle_model}</p>
                                                </div>
                                                <div style={{ width: "40%", marginRight: 16 }}>
                                                    <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: 6 }}>
                                                        <Tag >{d.status}</Tag>
                                                    </div>
                                                    <p style={{ textAlign: "end" }}> {d.action_time? moment(d.action_time).format("YYYY/MM/DD HH:mm"):moment(fetchData.time).format("YYYY/MM/DD")}</p>
                                                </div>
                                                <div style={{ color: "rgba(0,0,0,0.8)", cursor:"pointer" }} 
                                                onClick={() => {
                                                    navigate(`/cases/${d.case_id}`, {state:{ caseStatus: "4", vehicle_sn: d.vehicle_sn }})
                                                }}>
                                                    <ArrowRightOutlined />
                                                </div>
                                            </div>
                                        )
                                    })
                                }

                            </Timeline.Item>
                        )
                    })
                }
                <Timeline.Item style={{ display: 'none' }}>
                </Timeline.Item>
            </Timeline>

        </Card>
    )
}
const greyDot = {
    background: "rgba(0, 0, 0, 0.25)",
    width: 8,
    height: 8,
    borderRadius: "50%"
}
const activeDot = {
    background: "#1890FF",
    width: 8,
    height: 8,
    borderRadius: "50%"
}
const doneDot = {
    background: "#BAE7FF",
    width: 8,
    height: 8,
    borderRadius: "50%"
}
export default TimeLine;
import React, { useEffect, useState, useRef } from 'react'
import { PageHeader, Form, Input, Button, Select, Radio, message, Popconfirm } from 'antd'
import BreadCrumb from '../../components/shared/BreadCrumb'
import { EnterOutlined, PlusOutlined, RightOutlined, UnorderedListOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import { GetCustomerType } from '../../api/Code'
import { CheckCustomerId, AddCustomer, AddCustomerRemark } from '../../api/Customer'
// import { useSelector } from 'react-redux'
import { CheckTax, AddLease } from '../../api/Lease'
import CustomCascader from '../../components/shared/component/CustomCascader'
const { TextArea } = Input

const Add = ({ pageTitle, ContainerWidth }) => {
    // const [form] = Form.useForm()
    const form = useRef()
    // let Town = useSelector(state => state.county)
    const [currentStep, setCurrentStep] = useState(0)
    const [customerType, setCustomerType] = useState(null)
    const [successSubmit, setSuccessSubmit] = useState(null)
    const [isValid, setIsValid] = useState(null)
    const [customerId, setCustomerId] = useState("")
    const [remark, setRemark] = useState("")
    let navigate = useNavigate()
    let idRef = useRef()
    useEffect(() => {
        document.title = pageTitle
        GetCustomerType().then(res => {
            if (res !== null) {
                setCustomerType(res.Result)
            }
        })
    }, [])
    const onSubmitForm = (value) => {
        value.c_county = value.address_county[0]
        value.c_town = value.address_county[1]
        value.c_fax_num = value.c_fax_num ? value.c_fax_num : ""
        AddLease(value).then(res => {
            if (res && !res.Error) {
                navigate("/rent")
            } else {
                if (res.Error.ErrorType === "RESOURCE_EXIST") {
                    setIsValid(false)
                    idRef.current.focus()

                }
            }
        })
    }
    return (
        <div className='container' style={ContainerWidth}>
            <PageHeader
                title="帳號新增"
                onBack={() => currentStep === 0 ? window.history.go(-1) : setCurrentStep(currentStep - 1)}
                className="site-page-header"
                breadcrumb={<BreadCrumb />}
            >
            </PageHeader>
            <div className='step_block'>
                <div >
                    <Form
                        ref={form}
                        name="basic"
                        layout="vertical"
                        initialValues={{ pay_plan: "1", pay_frequency: "1", pay_method: "1",industry:"1" }}
                        onFinish={onSubmitForm}
                    // onFinishFailed={(value)=> console.log(value)}
                    >
                        <div style={{ minHeight: "calc(100vh - 240px)" }}>
                            <div className='step_content'>
                                {
                                    currentStep === 0 ? (<>
                                        <Form.Item
                                            label="產業別"
                                            name="c_industry"
                                            rules={[{ required: true, message: '請選擇產業別!' }]}
                                        >
                                            <Radio.Group >
                                                <Radio.Button value="1">物流倉儲業</Radio.Button>
                                                <Radio.Button value="2">大眾運輸業</Radio.Button>
                                                <Radio.Button value="3">道路救援業</Radio.Button>
                                                <Radio.Button value="4">汽機車買賣業</Radio.Button>
                                                <Radio.Button value="5">零件運送業</Radio.Button>
                                            </Radio.Group>
                                        </Form.Item>
                                        <div style={{ display: "flex" }}>
                                            <Form.Item
                                                label="方案"
                                                name="pay_plan"
                                                rules={[{ required: true, message: '請選擇方案！' }]}
                                            >
                                                <Radio.Group
                                                    buttonStyle="solid"
                                                >
                                                    <Radio.Button value="1">標準版</Radio.Button>
                                                    <Radio.Button value="2">專業版</Radio.Button>
                                                </Radio.Group>

                                            </Form.Item>
                                            <Form.Item
                                                label={"付款頻率"}
                                                name="pay_frequency"
                                                rules={[{ required: true, message: '請選擇付款頻率！' }]}
                                                style={{ margin: "0 16px" }}
                                            >
                                                <Radio.Group
                                                    buttonStyle="solid"
                                                >
                                                    <Radio.Button value="1">月付</Radio.Button>
                                                    <Radio.Button value="2">年付</Radio.Button>
                                                </Radio.Group>

                                            </Form.Item>
                                            <Form.Item
                                                label={"付款方式"}
                                                name="pay_method"
                                                rules={[{ required: true, message: '請選擇付款方式！' }]}
                                            >
                                                <Radio.Group
                                                    buttonStyle="solid"
                                                >
                                                    <Radio.Button value="1">匯款</Radio.Button>
                                                    <Radio.Button value="2">信用卡</Radio.Button>
                                                </Radio.Group>

                                            </Form.Item>
                                        </div>
                                        <Form.Item
                                            label="公司名稱"
                                            name="c_name"
                                            rules={[{ required: true, message: '請輸入公司名稱！' }]}
                                        >
                                            <Input allowClear placeholder='example' style={{ width: "304px" }} />
                                        </Form.Item>

                                        <div style={{ display: "flex" }}>
                                            <Form.Item
                                                label="公司電話"
                                                name="c_phone"
                                                rules={[{ required: true, message: '請輸入公司電話' }]}
                                            >
                                                <Input allowClear placeholder='example' style={{ width: "304px" }} />
                                            </Form.Item>
                                            <Form.Item
                                                label="公司統編"
                                                name="c_tax"
                                                rules={[{ required: true, message: '請輸入公司統編！' },
                                                {
                                                    pattern: /^[\d]{8}$/,
                                                    message: "請輸入8位數之公司統編",
                                                }]}
                                                style={{ position: 'relative', margin: '0 16px' }}
                                                hasFeedback={isValid !== null ? true : false}
                                                validateStatus={isValid === null ? '' : isValid ? 'success' : 'error'}
                                            >
                                                <Input
                                                    ref={idRef}
                                                    onChange={(e) => {
                                                        form.current.setFieldsValue("c_tax", e.target.value)

                                                    }}
                                                    placeholder='example'
                                                    addonAfter={<EnterOutlined style={{ cursor: "pointer" }}
                                                        onClick={(e) => {
                                                            let val = form.current.getFieldValue("c_tax")
                                                            CheckTax(val).then(res => {
                                                                if (res && !res.Error) {
                                                                    setIsValid(!res.Result.is_repeat_by_tax)
                                                                }
                                                            })
                                                        }} />} style={{ width: "304px" }} />
                                            </Form.Item>

                                        </div>
                                        <div style={{ display: "flex" }}>

                                            <Form.Item
                                                label="公司地址"
                                                name="address_county"
                                                rules={[{ required: true, message: '請輸入公司地址！' }]}
                                            >
                                                <CustomCascader
                                                    style={{ width: "304px" }}
                                                    OnChange={(val) => form.current.setFieldsValue({ address_county: val })}
                                                />
                                            </Form.Item>
                                            <div>
                                                <div style={{ height: 36 }}></div>
                                                <Form.Item
                                                    label=""
                                                    name="c_address"
                                                    style={{ margin: '0 16px' }}
                                                    rules={[{ required: true, message: '請輸入公司地址！' }]}
                                                >
                                                    <Input allowClear placeholder='請直接輸入地址' style={{ width: "304px" }} />
                                                </Form.Item>
                                            </div>
                                        </div>
                                        <div style={{ display: "flex" }}>
                                            <Form.Item
                                                label="聯絡窗口名稱"
                                                name="c_contact_person"
                                                rules={[{ required: true, message: '請輸入聯絡窗口名稱！' }]}
                                                style={{ marginRight: 16 }}
                                            >
                                                <Input allowClear placeholder='example' style={{ width: "304px" }} />
                                            </Form.Item>
                                            <Form.Item
                                                label="聯絡窗口電話"
                                                name="c_contact_person_phone"
                                                rules={[{ required: true, message: '聯絡窗口電話' }]}
                                            >
                                                <Input allowClear placeholder='example' style={{ width: "304px" }} />
                                            </Form.Item>
                                        </div>
                                        <div style={{ display: "flex" }}>
                                            <Form.Item
                                                label="傳真號碼"
                                                name="c_fax_num"
                                                // rules={[{ required: true, message: '請輸入聯絡窗口名稱！' }]}
                                                style={{ marginRight: 16 }}
                                            >
                                                <Input allowClear placeholder='example' style={{ width: "304px" }} />
                                            </Form.Item>
                                            <Form.Item
                                                label="E-mail"
                                                name="c_mail"
                                                rules={[{ required: true, message: '請輸入E-mail' }]}

                                            >
                                                <Input allowClear placeholder='example' style={{ width: "304px" }} />
                                            </Form.Item>
                                        </div>
                                    </>) : (
                                        <>
                                            {/* {
                                                successSubmit && (<>
                                                    <div style={{ fontSize: 30 }}>
                                                        客戶
                                                        <span style={{ color: "#1890FF", fontSize: 30 }}> {successSubmit.id} / {successSubmit.name} </span>
                                                        已新增
                                                    </div>
                                                    <div style={{ color: "rgba(0, 0, 0, 0.45)", marginTop: 8 }}>客戶已建立完成，請替該客戶建立人員、據點、計價模組後方可新增訂單</div>
                                                    <TextArea
                                                        placeholder="額外的據點內容說明請寫在此......"
                                                        autoSize={{ minRows: 2, maxRows: 4 }}
                                                        style={{ width: 395, marginTop: 8 }}
                                                        onChange={(e) => setRemark(e.target.value)}
                                                        value={remark}
                                                    />
                                                    <Button
                                                        style={{ display: "block", marginTop: 8 }}
                                                        disabled={remark !== "" ? "" : "disabled"}
                                                        onClick={() => {
                                                            let updated_uid = '00001'
                                                            let param = `row_id=${successSubmit.row_id}&note=${remark}&update_uid=${updated_uid}`
                                                            AddCustomerRemark(param).then(data => {
                                                                if (data.Result.Success) {
                                                                    setRemark("")
                                                                    form.resetFields()
                                                                    message.success('已新增備註')
                                                                }
                                                            })
                                                        }}
                                                    > 新增備註 </Button>

                                                    <div style={{ marginTop: 32 }}>
                                                        <Button
                                                            type="primary"
                                                            icon={<PlusOutlined />}
                                                            onClick={() => setCurrentStep(0)}
                                                        >新增其他客戶</Button>
                                                        <Button
                                                            icon={<RightOutlined />}
                                                            style={{ margin: "0 8px" }}
                                                            onClick={() => navigate(`/customer/lists/${successSubmit.row_id}`)}
                                                        >客戶內容</Button>
                                                        <Button
                                                            icon={<UnorderedListOutlined />}
                                                            onClick={() => navigate("/customer/lists/")}
                                                        >回列表</Button>
                                                    </div>
                                                </>)
                                            } */}
                                        </>)
                                }
                            </div>
                        </div>
                        {
                            currentStep === 0 && (<div style={{ background: "white", marginTop: 16, width: "100%", padding: "20px", display: "flex", justifyContent: "flex-end" }}>
                                <Popconfirm
                                    style={{ width: 410 }}
                                    placement="top"
                                    title={
                                        <div>
                                            <div>
                                                <strong>新增還沒完成喔</strong>
                                            </div>
                                            <div style={{ fontSize: 14 }}>
                                                剛剛輸入的所有資訊將不被保存。
                                            </div>
                                        </div>
                                    }
                                    onCancel={() => navigate(-1)}
                                    okText="不，我要留下"
                                    cancelText="對，我要離開"
                                >
                                    <Button type="text" style={{ marginRight: 24 }}>
                                        關閉
                                    </Button>
                                </Popconfirm>
                                <Button type="primary" icon={<PlusOutlined />}
                                    htmlType="submit"
                                > 新增 </Button>
                            </div>)
                        }
                    </Form>
                </div>
            </div>
        </div>
    );
}

export default Add;

import React,{useState} from 'react'
import { Button, Form, Input,Modal  } from 'antd'
import { useNavigate,useParams,useLocation } from 'react-router-dom'
import {ResetPassword} from '../../api/Member'


const ResetPW = () => {
    let navigate = useNavigate()
    let location = window.location.search
    let param = new URLSearchParams(location)
    let token = param.get('token')
    const [error, setError] = useState(null)
    const [modal, contextHolder] = Modal.useModal()
    const config = {
        title: '密碼設定成功',
        content: "請重新登入此帳號",
        okText:'前往登入',
        onOk: ()=>{
            navigate("/user/login")
        }
      };
    const onFinish = async (val)=>{
        if(val.password !== val.comfirm_password){
            setError("密碼不一致")
        }else{
            setError(null)
            let obj ={...val, token}
            let res = await ResetPassword(obj)
            if(!res.Error){
                modal.info(config)
            }else{
                setError(res.Error.Message)
            }
        }
    }
    return (
        <div style={{ background: '#F0F2F5', height: "100%" }}>
            <div style={{ background: `url(${process.env.PUBLIC_URL}/assets/images/bg.svg)`, height: "100%", width: "100%", backgroundRepeat: 'no-repeat', position: "relative" }}>
                <div style={{ width: 540, position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%,-50%)' }}>
                    <div style={{ width: 226, margin: '0 auto' }}>
                        <figure style={{ width: '100%' }}>
                            <img src={`${process.env.PUBLIC_URL}/assets/images/logo.png`} />
                        </figure>
                    </div>
                    <p style={{paddingTop:56, fontSize:16, fontWeight:"bold", color:'black'}}>重設密碼</p>
                    <Form
                        name="basic"
                        style={{marginTop:20}}
                        onFinish={onFinish}
                        autoComplete="off"
                    >
                        <Form.Item
                            label=""
                            name="password"
                            rules={[{ required: true, message: '請輸入密碼'}]}
                            validateStatus={error? "error":""}

                        >
                            <Input.Password placeholder='密碼' style={{padding:8, fontSize:16}} onKeyUp={()=>setError(null)}/>
                        </Form.Item>

                        <Form.Item
                            label=""
                            name="comfirm_password"
                            rules={[{ required: true, message: '請輸入確認密碼'}]}
                            validateStatus={error? "error":""}
                            help={error}
                        >
                            <Input.Password placeholder='確認密碼' style={{padding:8, fontSize:16}} onKeyUp={()=>setError(null)}/>
                        </Form.Item>
                        <div style={{display:'grid', gridTemplateColumns:'repeat(2,1fr)'}}>
                           

                            {/* <Button 
                                style={{fontSize:16, padding:0, textAlign:'right'}} 
                                type="link"
                                onClick={()=>navigate('/user/login')}
                            >回到登入</Button> */}
                        </div>
                        <Form.Item
                            >
                                <Button type="primary" htmlType="submit" block style={{fontSize:16}}>
                                確認
                                </Button>
                            </Form.Item>
                    </Form>
                    {contextHolder}

                </div>
            </div>
        </div>
    )
}

export default ResetPW;

import React ,{useState,useEffect } from 'react'
import BreadCrumb from '../../components/shared/BreadCrumb'
import {PushpinOutlined} from '@ant-design/icons';
import { PageHeader, Tabs, Badge,Table,Tag,Select } from 'antd' 
import {  useNavigate } from 'react-router-dom'
import 'moment/locale/zh-cn'
// import moment from 'moment'
import {GetGasStations,GetLatestGasPrice} from '../../api/Gas'
const { TabPane } = Tabs
const { Option } = Select

const Index = ({pageTitle, ContainerWidth}) => {
    let navigate = useNavigate()
    const [currentTab, setCurrentTab] = useState("all")
    const [filterVisible, setFilterVisible] = useState(false)
    const [selectedRowKeys, setSelectedRowKeys]= useState([])
    const [allData, setAllData]= useState(null)
    const [selectedData, setSelectedData]= useState(null)
    const [loading, setLoading] = useState(true)
    const [currentPage, setCurrentPage]= useState(1)
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
      })
    const [allTabs, setAllTabs] = useState([
        { id: 'all', name: '全部', count: null },
        { id: '92', name: '92 無鉛', count: null },
        { id: '95', name: '95 無鉛', count: null },
        { id: '98', name: '98 無鉛', count: null },
        { id: '柴油', name: '超級柴油', count: null },
        { id: '尿素', name: '尿素', count: null },
    ])
    const fetchApi = async () =>{
        const fetch = await  GetGasStations()
        let gas_stations = [];
        if(fetch.Result !== null){
            gas_stations = fetch.Result
            let new_data ={
                all : gas_stations,
                92:[],
                95:[],
                98:[],
                柴油:[],
                尿素:[],
            }
            gas_stations.forEach((gas,index) =>{
                gas.id =  index+1
                gas.kinds_of_fuel.forEach(type =>{
                    new_data[type].push(gas)
                })
            })
            allTabs.map(tab => {
                tab.count = new_data[tab.id].length
                return tab
            })
            setAllTabs(allTabs)
            setAllData(new_data)
            setSelectedData(gas_stations)
            setLoading(false)
        }
    }
    useEffect(() => {
        document.title = pageTitle
        fetchApi()
      }, [])
      useEffect(() => {
        let container = document.querySelector(".container")
        container.scrollTo(0,0)
      }, [currentPage])
     
      useEffect(()=>{
          if(allData !== null){
            setSelectedData(allData[currentTab])
          }
      },[currentTab])
      const children = [];
    for (let i = 10; i < 36; i++) {
        children.push(<Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>);
    }
  
      
      const columns = [
        {
          title: '縣市別',
          dataIndex: 'city',
          width: '15%',
        },
        {
          title: '站名',
          dataIndex: 'station_name',
          sorter: true,
          width: '25%',
        },
        {
          title: '平台',
          dataIndex: 'belong_group',
        },
        {
            title: '營業時間',
            dataIndex: 'opentime',
            // render: status =>{
            //     if(status === 0){
            //         return(
            //             <div style={{display:"flex", alignItems: "center"}}>
            //                 <div style={{width: 6, height: 6, borderRadius: "50%", background:"rgba(217,217,217,1)", marginRight: 8}}></div>
            //                 <p>未開放</p>
            //             </div> 
            //         )
            //     }else{
            //         return(
            //             <div style={{display:"flex", alignItems: "center"}}>
            //                 <div style={{width: 6, height: 6, borderRadius: "50%", background:"rgba(82, 196, 26,1)", marginRight: 8}}></div>
            //                 <p>開放中</p>
            //             </div> 
            //         )
            //     }
            // }
        },
        {
          title: '電話',
          dataIndex: 'phone',
          render: no=> (<span style={{color:"rgba(24, 144, 255, 1)"}}>{no}</span>)
        },
        {
            title: '配合油品',
            dataIndex: 'kinds_of_fuel',
            render: type =>(<span>
                {
                    type.map(t=>(<Tag>{t}</Tag>))
                }
            </span>)
        },
        {
            title: '定位',
            dataIndex: 'location',
            width: '6%',
            render: status =>{
                return(<PushpinOutlined style={{color:"rgba(0, 0, 0, 0.45)"}}/>)
            }
        }
        
      ];

    return (
        <div className="container" style={ContainerWidth}>
        <PageHeader
            style={{background: "white", width: "100%"}}
            title="通路查詢"
            className="site-page-header"
            // tags={[<Space direction="vertical" size={12}>
            //     {/* <RangePicker picker="year" /> */}
            //     <DatePicker picker="month" />
            // </Space>]}
            // extra={[
            // <Button 
            //     key="1" 
            //     icon={<FilterOutlined  />}
            //     onClick={() => setFilterVisible(true)}
            // >篩選</Button>,
            // <Button 
            //     key="2" 
            //     type="primary" 
            //     icon={<PlusOutlined />}
            //     onClick={()=> navigate('add')}
            // >
            //     新增案例
            // </Button>,
            // ]}
            breadcrumb={<BreadCrumb/>}
        >
    
        </PageHeader>
        <div className='table_block'>
            <Tabs defaultActiveKey={currentTab}>
                {allTabs.map((t) => (
                <TabPane
                    key={t.id}
                    tab={
                    <div onClick={(e) => setCurrentTab(t.id)}>
                        <span>{t.name}</span>
                        <Badge
                        className="site-badge-count-109"
                        count={t.count}
                        overflowCount={999}
                        style={{
                            backgroundColor:
                            currentTab === t.id ? "#E6F7FF" : "#F0F0F0",
                            color:
                            currentTab === t.id
                                ? "#1890FF"
                                : "rgba(0,0,0,0.45)",
                            marginLeft: "4px",
                        }}
                        />
                    </div>
                    }
                />
                ))}
            </Tabs>
            {

            }
            <Table
                columns={columns}
                rowKey={record => record.id}
                // rowSelection={selectedRowKeys}
                pagination={ selectedData !==null && selectedData.length > 10 ? {
                    total: selectedData.length,
                    showTotal: (total, range) =>
                    `${range[0]}-${range[1]} 列（共${total}  列）`,
                }: false}
                dataSource={selectedData}
                // pagination={data.length > 10 ? pagination : false}
                loading={loading}
                style={{padding: "12px 0", minHeight: 400}}
                onChange={(pagination) =>{
                    // console.log(pagination)
                    setCurrentPage(pagination.current)
                   
                }}
                onRow={(record, rowIndex) => {
                    return {
                    onClick: event => { navigate(`/fuel/search/${record.id}`, {state:record})}, // click row
                    };
                }}

            />

            {/* 篩選視窗 */}
             {/* <Modal
                title={"篩選"}
                transitionName="ant-slide-right"
                style={{left: "unset", top: 0, right: "-100%", paddingBottom: 0, margin: 0}}
                bodyStyle={{height: "calc(100vh - 108px)", overflow: "scroll"}}
                visible={filterVisible}
                onOk={() => setFilterVisible(false)}
                okText={"篩選"}
                onCancel={() => setFilterVisible(false)}
                width={284}
                >
                    <div style={{marginBottom: 24}}>
                        <div>運送日期</div>
                        <RangePicker style={{width: "100%", marginTop: 8}} />
                    </div>
                    <div style={{marginBottom: 24}}>
                        <div>客戶別</div>
                        <Select
                            mode="multiple"
                            allowClear
                            style={{ width: '100%', marginTop: 8 }}
                            placeholder="請選擇客戶別"
                            defaultValue={[]}
                            // onChange={handleChange}
                            >
                            {children}
                        </Select>
                    </div>
                    <div style={{marginBottom: 24}}>
                        <div>執行技師</div>
                        <Select
                            mode="multiple"
                            allowClear
                            style={{ width: '100%', marginTop: 8 }}
                            placeholder="請選擇執行技師"
                            defaultValue={[]}
                            // onChange={handleChange}
                            >
                            {children}
                        </Select>
                    </div>
                    <div style={{marginBottom: 24}}>
                        <div>車牌</div>
                        <Select
                            mode="multiple"
                            allowClear
                            style={{ width: '100%', marginTop: 8 }}
                            placeholder="請選擇車牌"
                            defaultValue={[]}
                            // onChange={handleChange}
                            >
                            {children}
                        </Select>
                    </div>
                    <div style={{marginBottom: 24}}>
                        <div>運送起點</div>
                        <Select
                            mode="multiple"
                            allowClear
                            style={{ width: '100%', marginTop: 8 }}
                            placeholder="請選擇運送起點"
                            defaultValue={[]}
                            // onChange={handleChange}
                            >
                            {children}
                        </Select>
                    </div>
                    <div style={{marginBottom: 24}}>
                        <div>運送迄點</div>
                        <Select
                            mode="multiple"
                            allowClear
                            style={{ width: '100%', marginTop: 8 }}
                            placeholder="請選擇運送迄點"
                            defaultValue={[]}
                            // onChange={handleChange}
                            >
                            {children}
                        </Select>
                    </div>
            </Modal> */}
        </div>
        </div>
    );
}

export default Index;

import React,{useState} from 'react'

const Slider = ({data}) => {
    const [active, setActive] = useState(0)
  return (
    <div className='photo_slider'>
        {
            data.map((d,i)=>(
                <figure 
                    className={`${i===active?"active":""}`}
                    style={{width:"100%",height:"100%"}}
                     key={d.row_id}>
                        <img src={d.url} />
                    </figure>
            ))
        }
        <ul className='pagination'>
            {data.map((d,i)=>(<li key={d.url} className={`${i===active?"active":""}`} onClick={()=>setActive(i)}></li>))}
            
        </ul>
    </div>
  )
}

export default Slider
import service from './Setting'

//取得縣市清單
export const GetCountyList = () => service.get('/Code/County');
//取得鄉鎮地區清單
export const GetTownList = () => service.get('/Code/Town');
//取得指定縣市的鄉鎮地區清單
export const GetSelectedTownList = (countyCode) => service.get(`/Code/County/${countyCode}/Town`);
//取得車輛廠牌清單
export const GetCarBrandList = () => service.get('/Code/Vehicle/Brand');
//取得廠牌車型清單
export const GetCarModelList = () => service.get('/Code/Vehicle/Model');
//取得指定車輛廠牌的車型清單
export const GetSelectedCarModelList = (brandCode) => service.get(`/Code/Vehicle/${brandCode}/Model`);
//取得客戶類型清單 （車商-總部(新車、營中古車行等）
export const GetCustomerType = () => service.get(`/Code/CustomerType`);
//取得案件類型清單 （新車、中古車等）
export const GetCaseType = () => service.get(`/Code/CaseType`);
//取得共有運送點清單
export const GetCommonStationType = () => service.get(`/Code/CommonStationType`);
//取得所有客戶清單
export const GetAllCustomerList = () => service.get(`/Customer/List`);

//取得指定類型加價計價項目清單 （特殊作業、時間相關等)
export const GetAllPriceItem = (type) => service.get(`/Code/PriceItem?type=${type}`);

//取得運送點的交接車人清單
export const GetHandOverList = (id) => service.get(`/Station/HandOverList?station_row_id=${id}`);

//取得費用項目清單
export const GetFeeList = (id) => service.get(`/Code/Fee`);


//取得運載車單
export const GetCarTypeList = (id) => service.get(`/Code/TrailerType`);

//取得空車清單
export const GetUnoccupiedCarList = (id) => service.get(`/ServicePoint/Car/NotUse?service_point_row_id=${id}`);

//驗證地址是否有效
export const CheckValidAddr = (obj) => service.post(`/Code/CheckAddr`, obj);


//取得技師清單
export const AllDrivers = () => service.get(`/Monitor/SelectDriverList`);

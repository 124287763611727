import React, { useState, useEffect, useRef } from "react";
import { Button, Tabs, Table, Drawer, Form, Input, InputNumber, Select, TimePicker, Radio, Statistic, Tag } from "antd";
import { PlusOutlined, CloseOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { GetPriceAddOnArea, GetPriceAddOnSpecial, UpdatePriceAddOnSpecial, DeletePriceAddOnSpecial, GetPriceAddOnTime, UpdatePriceAddOnTime, DeletePriceAddOnTime, GetPriceAddOnFloor, UpdatePriceAddOnFloor, DeletePriceAddOnFloor, GetPriceAddOnCar, GetPriceAddOnOther, UpdatePriceAddOnOther, DeletePriceAddOnOther, UpdatePriceAddOnCar, DeletePriceAddOnCar, UpdatePriceAddOnArea, DeletePriceAddOnArea, UpdatePriceAddOnKM, AddPriceAddOnKMTown, DeletePriceAddOnKMTown, ChangePriceAddOnAreaMode } from '../../../api/Customer'
import { GetCarBrandList, GetSelectedCarModelList } from '../../../api/Code'
import moment from 'moment'
import CustomCascader from "../../shared/component/CustomCascader"
import { RouteName } from "../../../util/route"
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
const { TabPane } = Tabs;
const { Option } = Select;

const AddOnPricing = ({ ParentData }) => {
  const format = "HH:mm"
  const [currentTab, setCurrentTab] = useState("1")
  const [mode, setMode] = useState(null)
  const [data, setData] = useState(null)
  const [KMTown, setKMTown] = useState(null)
  const [drawerStatus, setDrawerStatus] = useState(null)
  const [drawerTitle, setDrawerTitle] = useState(null)
  const [carBrandOption, setCarBrandOption] = useState(null)
  const [selectedCarBrand, setSelectedCarBrand] = useState(null)
  const [carModelOption, setCarModelOption] = useState(null)
  const [defaultValue, setDefaultValue] = useState(null)
  const [AreaDrawer, setAreaDrawer] = useState(null)
  const [basicDrawer, setBasicDrawer] = useState(null)
  const submitRef = useRef(), areaSubmit = useRef(), basicSubmit = useRef()
  const { state, pathname } = useLocation()
  const routeId = RouteName(pathname)
  let UserInfo = useSelector(state => state.UserInfo)
  let UserPermission = UserInfo ? UserInfo.permission.route : null
  const fetchApi = async () => {
    let res
    if (currentTab === "1") {
      //特殊作業
      res = await GetPriceAddOnSpecial(ParentData.row_id)
    } else if (currentTab === "2") {
      //時間相關
      res = await GetPriceAddOnTime(ParentData.row_id)
    } else if (currentTab === "3") {
      //樓層運送
      res = await GetPriceAddOnFloor(ParentData.row_id)
    } else if (currentTab === "4") {
      //偏遠地區
      res = await GetPriceAddOnArea(ParentData.row_id)
    } else if (currentTab === "5") {
      //車款加價
      res = await GetPriceAddOnCar(ParentData.row_id)
    } else {
      //其他
      res = await GetPriceAddOnOther(ParentData.row_id)
    }
    if (!res.Error) {
      let AddOnPrice
      if (currentTab === "4") {
        setMode(res.Result.mode)
        if (res.Result.mode === "area") {
          let area = {}
          let data = res.Result.area_data && res.Result.area_data.length === 0 ? null : res.Result.area_data
          AddOnPrice = data
        } else {
          setKMTown(res.Result.km_citytown)
          AddOnPrice = res.Result.km_data.sort(function (a, b) {
            return a.type_code - b.type_code;
          })
        }
      } else {
        AddOnPrice = res.Result.Data
      }
      if (AddOnPrice) {
        AddOnPrice.map(p => {
          if (p.item_name && p.item_name.indexOf("夜間加價") > -1) {
            const re = /\((.*)\)/;
            let date = p.item_name.match(re)[1].split("~")
            p.date_range = [moment(date[0], "HH:mm"), moment(date[1], "HH:mm")]
          }
          if (p.item_name) {
            p.id = `${p.item_code}-${p.item_name}`
          }
          if (p.brand_name) {
            p.id = `${p.brand_name} - ${p.car_name}`
            p.car = `${p.brand_name} - ${p.car_name}`
            if (carBrandOption) {
              let filter = carBrandOption.filter(c => c.Name === p.brand_name)
              if (filter.length > 0) {
                p.brand_code = filter[0]['Code']
              }
            }
          }
          if (p.city_name) {
            p.id = `${p.city_name} / ${p.town_name ? p.town_name : '全區'}`
            p.area = `${p.city_name} / ${p.town_name ? p.town_name : '全區'}`
          }
          return p
        })
      }
      // console.log(AddOnPrice)
      setData(AddOnPrice)
    }
  }
  const UpdateInfo = async (type, data) => {
    if (currentTab.indexOf("4") > -1) {
      if (currentTab === '4b') {
        setDrawerTitle('編輯區域計價')
      }
      setDrawerTitle(`${type}個別加價項目`)
    } else if (currentTab === "2") {
      if (data.item_name.indexOf("夜間加價") > -1) {
        setDrawerTitle('夜間加價')
      } else {
        setDrawerTitle(`${type}個別加價項目`)
      }
    } else if (currentTab === "3") {
      setDrawerTitle('樓層運送')
    }
    else {
      setDrawerTitle(`${type}個別加價項目`)
    }
    setDrawerStatus(`${currentTab}${type === "新增" ? "a" : "b"}`)
    if (type === "新增") {
      if (submitRef.current) {
        submitRef.current.resetFields()
      }
      setTimeout(() => {
        if (submitRef.current) {
          submitRef.current.setFieldsValue({ item_code: data.item_code })
          if (currentTab === "2") {
            submitRef.current.setFieldsValue({ date_range: data.date_range })
          }
        }
      }, 200)
    } else {
      setTimeout(() => {
        if (submitRef.current) {
          if (data.town_code) {
            let town = []
            if (data.town_code.length === 5) {
              town.push(data.town_code)
            } else {
              town = [data.town_code.slice(0, 5), data.town_code]
            }
            data.town = town
            setDefaultValue(town)
          }

          submitRef.current.setFieldsValue(data)
        }
      }, 200)
    }

  }
  const onDelete = async (data, type) => {
    let res
    let param = `price_row_id=${ParentData.row_id}`
    if (type === "delete") {
      if (currentTab === "4") {
        if (mode === "area") {
          param += `&town_code=${data.town_code}`
          res = await DeletePriceAddOnArea(param)
        }
      } else {
        // 刪除 車款加價
        param += `&car_code=${data.car_code}`
        res = await DeletePriceAddOnCar(param)
      }
    } else {
      // 清除 特殊作業、時間相關、樓層、其他
      param += `&item_code=${data.item_code}`
      if (currentTab === "1") {
        res = await DeletePriceAddOnSpecial(param)
      } else if (currentTab === "2") {
        res = await DeletePriceAddOnTime(param)
      } else if (currentTab === "3") {
        res = await DeletePriceAddOnFloor(param)
      } else if (currentTab === "6") {
        res = await DeletePriceAddOnOther(param)
      }
    }
    if (res.Result.Success) {
      setDrawerStatus(null)
      setDrawerTitle(null)
      fetchApi()
    }

    // currentTab === "4"|| currentTab === "5"
  }
  const onFinish = async (val) => {
    let update_uid = '00050'
    val.price_row_id = ParentData.row_id
    if (!val.note) {
      val.note = ''
    }
    if (val.car_code && typeof (val.car_code) !== "string") {
      let car_code = ""
      val.car_code.forEach((c, i) => {
        car_code += i === 0 ? c : `,${c}`
      })
      val.car_code = car_code
    }
    if (val.area) {
      if (typeof (val.area) === "object") {
        let town_code = ''
        if (typeof (val["area"][0]) === "object") {
          val.area.forEach((a, i) => {
            let index = a.length > 1 ? 1 : 0
            town_code += i === 0 ? a[index] : `,${a[index]}`

          })
          val.town_code = town_code
        } else {
          val.town_code = val.area.length > 1 ? val["area"][1] : val["area"][0]
        }
      } else {
        let filter = data.filter(d => d.area === val.area)
        val.town_code = filter[0]["town_code"]
      }


    }
    if (val.date_range) {
      let start = val.date_range[0].format(format)
      let end = val.date_range[1].format(format)
      // "005"
      if (start === "20:00" && end === "23:59") {
        val.item_code = '005'
      } else {
        val.item_code = '006'
      }
    }
    val.update_uid = update_uid
    let param = ""
    let obj = [{}, {}]

    for (let key in val) {
      if (currentTab === "4" && mode === "km") {
        let i = key.indexOf("/2") > -1 ? 1 : 0
        if (key === "receive_money/2" || key === "receive_money") {
          obj[i]["receive_money"] = val[key]
        } else if (key === "service_cost/2" || key === "service_cost") {
          obj[i]["service_cost"] = val[key]
        } else if (key === "self_pay/2" || key === "self_pay") {
          obj[i]["self_pay"] = val[key]
        }
      } else {
        if (typeof (val[key]) === "string" || typeof (val[key]) === "number") {
          param += key === "update_uid" ? `${key}=${val[key]}` : `${key}=${val[key]}&`
        }
      }

    }
    obj = obj.map((o, i) => {
      let type_code = i === 0 ? "001" : "002"
      o.base_km = val.base_km
      o.item_code = type_code
      o.note = val.note ? val.note : ''
      o.price_row_id = val.price_row_id
      return o
    })
    let res
    if (currentTab === "1") {
      res = await UpdatePriceAddOnSpecial(param)
    } else if (currentTab === "2") {
      res = await UpdatePriceAddOnTime(param)
    } else if (currentTab === "3") {
      res = await UpdatePriceAddOnFloor(param)
    } else if (currentTab === "4") {
      if (mode === "area") {
        res = await UpdatePriceAddOnArea(param)
      } else {
        let success_arr = [], fetchRes
        for (let i = 0; i < obj.length; i++) {
          let param = ""
          let obj_data = obj[i]
          for (let key in obj_data) {
            param += key === "update_uid" ? `${key}=${obj_data[key]}` : `${key}=${obj_data[key]}&`
          }
          fetchRes = await UpdatePriceAddOnKM(param)
          if (fetchRes && !fetchRes.Error) {
            success_arr.push(1)
          } else {
            success_arr.push(0)
          }
        }
        if (success_arr.length === 2) {
          let filter = success_arr.filter(s => s === 0)
          if (filter.length === 0) {
            res = {
              Result: {
                Success: true
              },
              Error: null
            }
          }
        }
      }
    } else if (currentTab === "5") {
      res = await UpdatePriceAddOnCar(param)
    } else if (currentTab === "6") {
      res = await UpdatePriceAddOnOther(param)
    }
    if (res && res.Result.Success) {
      setDrawerStatus(null)
      setDrawerTitle(null)
      fetchApi()
    }
  }
  const tabs = [
    { id: "1", title: "特殊作業" },
    { id: "2", title: "時間相關" },
    { id: "3", title: "樓層運送" },
    { id: "4", title: "偏遠地區" },
    { id: "5", title: "車款加價" },
    { id: "6", title: "其他" },
  ];
  const KMColumns = [
    {
      title: "縣市",
      dataIndex: "name",
      width: '15%'
    },
    {
      title: "鄉鎮區",
      dataIndex: "town",
      render: (town, _) => (<>
        {
          town.map(t => (
            <Tag closable onClose={() => {
              let param = `price_row_id=${ParentData.row_id}&town_code=${t.town_code}`
              DeletePriceAddOnKMTown(param).then(res => {
                if (res && !res.Error) {
                  fetchApi()
                }
              })
            }}>
              {t.tonw_name}
            </Tag>))
        }
        {/* {
        town[0]["tonw_name"] !=="全區" &&(   <Tag onClick={()=>console.log("click")} style={{borderStyle:'dashed', background:"white"}}>
          <PlusOutlined /> 新增地區
        </Tag>)
      } */}

      </>)
    },
  ]
  const firstColumns =
    currentTab === "3" ? { title: "樓層", dataIndex: "item_name" } :
      currentTab === "4" ? { title: "區域名稱", dataIndex: "area" } :
        currentTab === "5" ? { title: "車款名稱", dataIndex: "car" } :
          { title: "項目名稱", dataIndex: "item_name" };
  const columns = UserPermission && UserPermission[routeId] !== 2 ? [
    firstColumns,
    {
      title: "請款金額",
      dataIndex: "receive_money",
      render: (value) =>
        value === null ? (
          <p style={{ color: "rgba(0,0,0,0.4)" }}>尚未新增</p>
        ) : (
          value
        ),
    },
    {
      title: "服務費用",
      dataIndex: "service_cost",
      render: (value) =>
        value === null ? (
          <p style={{ color: "rgba(0,0,0,0.4)" }}>尚未新增</p>
        ) : (
          value
        ),
    },
    {
      title: "自費金額",
      dataIndex: "self_pay",
      render: (value) =>
        value === null ? (
          <p style={{ color: "rgba(0,0,0,0.4)" }}>尚未新增</p>
        ) : (
          value
        ),
    },
    {
      title: "備註",
      dataIndex: "note",
      render: (remark, _) =>
        _.receive_money === null ? (
          <p style={{ color: "rgba(0,0,0,0.4)" }}>尚未新增</p>
        ) : remark === "" || !remark ? (
          "-"
        ) : (
          <span>{remark}</span>
        ),
    }
  ] : [
    firstColumns,
    {
      title: "請款金額",
      dataIndex: "receive_money",
      render: (value) =>
        value === null ? (
          <p style={{ color: "rgba(0,0,0,0.4)" }}>尚未新增</p>
        ) : (
          value
        ),
    },
    {
      title: "服務費用",
      dataIndex: "service_cost",
      render: (value) =>
        value === null ? (
          <p style={{ color: "rgba(0,0,0,0.4)" }}>尚未新增</p>
        ) : (
          value
        ),
    },
    {
      title: "自費金額",
      dataIndex: "self_pay",
      render: (value) =>
        value === null ? (
          <p style={{ color: "rgba(0,0,0,0.4)" }}>尚未新增</p>
        ) : (
          value
        ),
    },
    {
      title: "備註",
      dataIndex: "note",
      render: (remark, _) =>
        _.receive_money === null ? (
          <p style={{ color: "rgba(0,0,0,0.4)" }}>尚未新增</p>
        ) : remark === "" || !remark ? (
          "-"
        ) : (
          <span>{remark}</span>
        ),
    },
    {
      title: "操作",
      dataIndex: "setting",
      width: "15%",
      render: (status, _) =>
        _.receive_money === null ? (
          <>
            <Button
              type="link"
              primary
              onClick={() => UpdateInfo("新增", _)}
              style={{ padding: 0 }}>
              新增
            </Button>
          </>
        ) : (
          <>
            <Button type="link"
              primary
              style={{ padding: 0, marginRight: 16 }}
              onClick={() => UpdateInfo("編輯", _)}
            >
              編輯
            </Button>
            <Button type="link" primary style={{ padding: 0 }} onClick={() => onDelete(_, (currentTab === "4" || currentTab === "5" ? "delete" : 'clear'))}>
              {currentTab === "4" || currentTab === "5" ? "刪除" : "清除"}
            </Button>
          </>
        ),
    },
  ];

  useEffect(() => {
    GetCarBrandList().then(res => {
      if (!res.Error) {
        setCarBrandOption(res.Result)
        setSelectedCarBrand(res.Result[0])
      }
    })
  }, [])
  useEffect(() => {
    if (selectedCarBrand) {
      GetSelectedCarModelList(selectedCarBrand.Code).then(res => {
        if (!res.Error) {
          setCarModelOption(res.Result)
        }
      })
    }
  }, [selectedCarBrand])

  useEffect(() => {
    fetchApi()
  }, [currentTab])
  return (
    <div className="table_block">
      <div style={{ position: "relative" }}>
        <div style={{ fontSize: 16, padding: "16px 0 0" }}>個別加價欄位</div>
        <Tabs defaultActiveKey={currentTab}>
          {tabs.map((t, i) => (
            <TabPane
              key={`${t.title}_${t.id}`}
              tab={
                <div onClick={(e) => {
                  if (t.id !== "4") {
                    setMode(null)
                  }
                  setCurrentTab(t.id)
                }}>
                  <span>{t.title}</span>
                </div>
              }
            />
          ))}
        </Tabs>
        {
          <div style={{ paddingBottom: 16 }}>
            {
              data && data.length > 0 ? (
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: `repeat(1, 1fr)`,
                    gridGap: 8,
                  }}
                >

                  {
                    currentTab === "4" && mode === "area" ? (<div
                      style={{
                        background: "#FAFAFA", padding: 24, cursor: UserPermission && UserPermission[routeId] === 2 ? "pointer" : "default", display: "flex", justifyContent: "space-between", alignItems: "center"
                      }}
                    >
                      <div>
                        <div style={{ fontSize: 20 }}>區域計價</div>
                        <p style={{ color: "rgba(0,0,0,0.45)", marginTop: 8 }}>
                          定義偏遠地區並設定加價金額，當起運點為偏遠地區結帳時增加金額
                        </p>
                      </div>
                      {
                        UserPermission && UserPermission[routeId] === 2&&(
                          <div
                        style={{ color: "#1890FF" }}
                        onClick={() => {
                          let param = `price_row_id=${ParentData.row_id}&mode=${mode}`
                            ChangePriceAddOnAreaMode(param).then(res => {
                              if (res.Result.Success) {
                                fetchApi()
                              }
                            })

                        }}
                      >
                        <DeleteOutlined />
                        <span style={{ display: "inline-block", marginLeft: 8 }}>清除設定並變更計價模式</span>
                      </div>
                        )
                      }
                      
                    </div>) : currentTab === "4" && mode === "km" ? (
                      <div
                        style={{
                          background: "#FAFAFA", padding: 24, cursor: UserPermission && UserPermission[routeId] === 2?"pointer":"default", display: "flex", justifyContent: "space-between", alignItems: "center"
                        }}
                        onClick={() => {
                          let param = `price_row_id=${ParentData.row_id}&mode=area`
                            ChangePriceAddOnAreaMode(param).then(res => {
                              if (res.Result.Success) {
                                fetchApi()
                              }
                            })
                        }}
                      >
                        <div>
                          <div style={{ fontSize: 20 }}>里程計價</div>
                          <p style={{ color: "rgba(0,0,0,0.45)", marginTop: 8 }}>
                            定義偏遠地區，並從據點地址開始計算到起運地點里程數進行額外加價
                          </p>
                        </div>
                        {
                          UserPermission && UserPermission[routeId] === 2 &&(
                            <div style={{ color: "#1890FF" }}
                          onClick={() => {
                            if (submitRef.current) submitRef.current.resetFields()
                            let param = `price_row_id=${ParentData.row_id}&mode=area`
                            ChangePriceAddOnAreaMode(param).then(res => {
                              if (res.Result.Success) {
                                fetchApi()
                              }
                            })
                          }}
                        >
                          <DeleteOutlined />
                          <span style={{ display: "inline-block", marginLeft: 8 }}>清除設定並變更計價模式</span>
                        </div>
                          )
                        }
                        
                      </div>) : (<></>)
                  }

                </div>
              ) : currentTab === "4" ? (<div
                style={{
                  display: "grid",
                  gridTemplateColumns: `repeat(2, 1fr)`,
                  gridGap: 8,
                }}
              >
                <div
                  style={{
                    background: "#FAFAFA",
                    padding: 24,
                    cursor: UserPermission && UserPermission[routeId] === 2? "pointer":"default",
                  }}
                  onClick={() => {
                    if(UserPermission && UserPermission[routeId] === 2){
                      setMode("area")
                      setDrawerStatus("5a")
                      setDrawerTitle("新增區域計價")
                      let param = `price_row_id=${ParentData.row_id}&mode=area`
                      ChangePriceAddOnAreaMode(param)
                    }
                   
                    
                  }}
                >
                  <div style={{ fontSize: 20 }}>區域計價</div>
                  <p style={{ color: "rgba(0,0,0,0.45)", marginTop: 8 }}>
                    定義偏遠地區並設定加價金額，當起運點為偏遠地區結帳時增加金額
                  </p>
                </div>
                <div
                  style={{
                    background: "#FAFAFA",
                    padding: 24,
                    cursor: UserPermission && UserPermission[routeId] === 2? "pointer":"default",
                  }}
                  onClick={() => {
                    if(UserPermission && UserPermission[routeId] === 2){
                      setMode("km")
                      setDrawerStatus("5a")
                      setDrawerTitle("新增里程計價")
                      let param = `price_row_id=${ParentData.row_id}&mode=km`
                      ChangePriceAddOnAreaMode(param)
                    }
                  }}
                >
                  <div style={{ fontSize: 20 }}>里程計價</div>
                  <p style={{ color: "rgba(0,0,0,0.45)", marginTop: 8 }}>
                    定義偏遠地區，並從據點地址開始計算到起運地點里程數進行額外加價
                  </p>
                </div>
              </div>) : (<></>)
            }

          </div>
        }
        {
          data && (<>
            <div style={{ paddingBottom: 16 }}>
              {currentTab === "4" && mode === "km" ? (
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: `repeat(${data.length === 0 ? 2 : 1}, 1fr)`,
                    gridGap: 8,
                  }}
                >
                  <div style={{
                    display: "grid",
                    gridTemplateColumns: `repeat(2, 1fr)`,
                    gridGap: 8,
                  }}>
                    {
                      data.map(d => (<div style={{ background: "#FAFAFA", padding: 24 }}>
                        <div style={{ display: "flex", justifyContent: "space-between", marginBottom: 8 }}>
                          <div style={{ fontSize: 20 }}>{d.item_name}</div>
                          {
                            UserPermission && UserPermission[routeId] ===2&&(
                              <Button
                              type="link" style={{ padding: 0 }}
                              onClick={() => {
                                setBasicDrawer(d.item_code)
                                setTimeout(() => basicSubmit.current.setFieldsValue(d), 200)
                              }}
                            > <EditOutlined /> 編輯
                            </Button>
                            )
                          }
                          
                        </div>
                        <div style={{ display: "grid", gridTemplateColumns: `repeat(${d.item_code === "001" ? 4 : 3}, 1fr)` }}>
                          {
                            d.item_code === "001" && (<Statistic title="基本里程" value={`${d.base_km} km`} />)
                          }
                          <Statistic title="請款金額" value={d.receive_money} style={{ borderLeft: d.item_code === "001" ? '1px solid rgba(0, 0, 0, 0.03)' : '', paddingLeft: d.item_code === "001" ? 20 : 0 }} />
                          <Statistic title="服務費用" value={d.service_cost} style={{ borderLeft: '1px solid rgba(0, 0, 0, 0.03)', paddingLeft: 20 }} />
                          <Statistic title="自費金額" value={d.self_pay} style={{ borderLeft: '1px solid rgba(0, 0, 0, 0.03)', paddingLeft: 20 }} />
                        </div>
                      </div>))
                    }
                  </div>
                  <Table
                    columns={KMColumns}
                    rowKey={(record) => record.id}
                    pagination={false}
                    dataSource={KMTown}
                    // loading={loading}
                    style={{ padding: "12px 0" }}
                  />
                  {
                    UserPermission && UserPermission[routeId] === 2 &&(
                      <Button
                        type="dashed"
                        onClick={() => {
                          setAreaDrawer("新增")
                        }}
                        icon={<PlusOutlined />}
                        block
                        style={{ marginBottom: 40 }}
                      >
                        新增區域
                      </Button>
                    )
                  }
                  
                </div>) : (<>
                  <Table
                    columns={columns}
                    rowKey={(record) => record.id}
                    pagination={false}
                    dataSource={data}
                    // loading={loading}
                    style={{ padding: "12px 0" }}
                  />
                  {
                    mode && (mode === "km" || mode === "area") && (UserPermission && UserPermission[routeId] === 2) && (
                      <Button
                        type="dashed"
                        onClick={() => {
                          setMode("area")
                          setDrawerStatus("5a")
                          setDrawerTitle("新增區域計價")
                          setTimeout(() => submitRef.current.resetFields(), 100)

                        }}
                        icon={<PlusOutlined />}
                        block
                        style={{ marginBottom: 40 }}
                      > 新增區域 </Button>)
                  }
                </>
              )
              }
              {currentTab === "5" && (UserPermission && UserPermission[routeId] === 2) && (
                <Button
                  type="dashed"
                  onClick={() => {
                    setDrawerTitle('車款加價')
                    setDrawerStatus(`${currentTab}a`)
                  }}
                  icon={<PlusOutlined />}
                  block
                  style={{ marginBottom: 40 }}
                >
                  新增車款
                </Button>
              )}

            </div>
          </>)
        }
        {
          AreaDrawer && (<Drawer
            title={`${AreaDrawer}區域`}
            placement="right"
            closable={false}
            extra={
              <CloseOutlined
                style={{ cursor: "pointer", color: "rgba(0, 0, 0, 0.45)" }}
                onClick={() => setAreaDrawer(null)}
              />
            }
            visible={AreaDrawer}
            footer={
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  type="text"
                  style={{ marginRight: 16 }}
                  onClick={() => {
                    if (areaSubmit.current) areaSubmit.current.resetFields()
                    setAreaDrawer(null);
                  }}
                > 取消 </Button>
                <Button
                  type="primary"
                  onClick={() => areaSubmit.current.submit()}
                >
                  {AreaDrawer}
                </Button>
              </div>
            }
          >
            <Form
              ref={areaSubmit}
              name="basic"
              layout="vertical"
              // initialValues={drawerData}
              onFinish={(val) => {
                let town_code = '', update_uid = '00050'

                val.area.forEach((area, i) => {
                  let town = area.length > 1 ? area[1] : `${area[0]}000`
                  town_code += i === 0 ? town : `,${town}`
                })

                let param = `price_row_id=${ParentData.row_id}&town_code=${town_code}&update_uid=${update_uid}`
                AddPriceAddOnKMTown(param).then(res => {
                  if (!res.Error) {
                    fetchApi()
                    areaSubmit.current.resetFields()
                    setAreaDrawer(null)
                  }
                })
              }}
              autoComplete="off"
            >
              <Form.Item
                label={<>
                  <span>區域名稱</span>
                  {
                    drawerStatus !== "4b" && (<span
                      style={{
                        paddingLeft: 4,
                        color: "rgba(0, 0, 0, 0.45)",
                        fontSize: 14,
                      }}
                    >
                      (可複選)
                    </span>)
                  }

                </>}
                name="area"
                rules={[{ required: true, message: "請選擇區域!" }]}
              >
                <CustomCascader
                  defaultValue={defaultValue ? defaultValue : ""}
                  multiple={drawerStatus === '4b' ? false : true}
                  SeperatedName
                  OnChange={(val) => {
                    areaSubmit.current.setFieldsValue({ area: val })
                  }}
                />
              </Form.Item>

            </Form>
          </Drawer>)
        }
        {
          basicDrawer && ((<Drawer
            title={basicDrawer === '001' ? "基本費用" : "超出基本里程額外加價"}
            placement="right"
            closable={false}
            extra={
              <CloseOutlined
                style={{ cursor: "pointer", color: "rgba(0, 0, 0, 0.45)" }}
                onClick={() => setBasicDrawer(null)}
              />
            }
            visible={basicDrawer}
            footer={
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  type="text"
                  style={{ marginRight: 16 }}
                  onClick={() => {
                    if (basicSubmit.current) basicSubmit.current.resetFields()
                    setBasicDrawer(null);
                  }}
                > 取消 </Button>
                <Button
                  type="primary"
                  onClick={() => basicSubmit.current.submit()}
                > 修改 </Button>
              </div>
            }
          >
            <Form
              ref={basicSubmit}
              name="basic"
              layout="vertical"
              onFinish={(val) => {
                let item_code = basicSubmit.current.getFieldValue().item_code,
                  update_uid = '00050'
                val.item_code = item_code
                val.price_row_id = ParentData.row_id
                val.update_uid = update_uid
                let param = ''
                for (let key in val) {
                  param += key === 'update_uid' ? `${key}=${val[key]}` : `${key}=${val[key]}&`
                }
                UpdatePriceAddOnKM(param).then(res => {
                  if (res && !res.Error) {
                    fetchApi()
                    setBasicDrawer(null)
                  }
                })
              }}
              autoComplete="off"
            >

              <Form.Item
                label="基本里程"
                name="base_km"
                rules={[{ required: true, message: "請輸入基本里程!" }]}
              >
                <InputNumber min={1} addonAfter="公里" allowClear placeholder="example" />
              </Form.Item>

              <Form.Item
                label="請款金額"
                name="receive_money"
                rules={[
                  { required: true, message: "請輸入請款金額!" },
                  { pattern: /^\d+$/, message: "請輸入數字" }
                ]}
              >
                <Input addonBefore="NT$" allowClear placeholder="請輸入金額" />
              </Form.Item>
              <Form.Item
                label="服務費用"
                name="service_cost"
                rules={[
                  { required: true, message: "請輸入服務費用!" },
                  { pattern: /^\d+$/, message: "請輸入數字" }
                ]}
              >
                <Input addonBefore="NT$" allowClear placeholder="請輸入金額" />
              </Form.Item>
              <Form.Item
                label="自費金額"
                name="self_pay"
                rules={[
                  { required: true, message: "請輸入自費金額!" },
                  { pattern: /^\d+$/, message: "請輸入數字" }
                ]}
              >
                <Input addonBefore="NT$" allowClear placeholder="請輸入金額" />
              </Form.Item>
            </Form>
          </Drawer>))
        }
        {
          drawerStatus && (<Drawer
            title={drawerTitle}
            placement="right"
            closable={false}
            extra={
              <CloseOutlined
                style={{ cursor: "pointer", color: "rgba(0, 0, 0, 0.45)" }}
                onClick={() => setDrawerStatus(null)}
              />
            }
            visible={drawerStatus}
            footer={
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  type="text"
                  style={{ marginRight: 16 }}
                  onClick={() => {
                    // setOnCancel(v.key)
                    // fetchAPI()
                    setDrawerStatus(null);
                  }}
                >
                  取消
                </Button>

                <Button
                  type="primary"
                  // disabled={btnDisabled}
                  onClick={() => {
                    submitRef.current.submit()
                    // setSubmitForm(true)
                  }}
                >
                  {drawerStatus && drawerStatus.indexOf("a") > -1 ? "新增" : "編輯"}
                </Button>
              </div>
            }
          >
            <Form
              ref={submitRef}
              name="basic"
              layout="vertical"
              // initialValues={drawerData}
              onFinish={onFinish}
              // onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              {
                mode && mode === "km" && (<>
                  <div style={{ fontWeight: 700, fontSize: 16, marginBottom: 16 }}>基本費用</div>
                </>)
              }
              {
                drawerTitle === "夜間加價" ? (<Form.Item
                  label="時段設定"
                  name="date_range"
                  rules={[{ required: true, message: '請選擇時段設定!' }]}
                  extra="如加價金額有區分多組時間段，請分段新增"
                >
                  <TimePicker.RangePicker format={"HH:mm"} style={{ width: "100%" }} disabled />
                </Form.Item>) : currentTab === "3" ?
                  (<Form.Item
                    label="上下樓層數設定"
                    name="item_code"
                  >
                    <Radio.Group buttonStyle="solid">
                      <Radio.Button value="001">1 層</Radio.Button>
                      <Radio.Button value="002">2 層</Radio.Button>
                      <Radio.Button value="003">3 層</Radio.Button>
                      <Radio.Button value="004">大於 3 層</Radio.Button>
                    </Radio.Group>
                  </Form.Item>) : currentTab === "5" ? (
                    <>
                      <Form.Item
                        label="廠牌"
                        name="brand_code"
                        rules={[{ required: true, message: "請選擇廠牌!" }]}
                      >
                        <Select
                          block
                          onChange={(val) => {
                            let filter = carBrandOption.filter(c => c.Code === val)
                            if (filter.length > 0) {
                              setSelectedCarBrand(filter[0])

                            }
                          }}
                        >
                          {
                            carBrandOption && carBrandOption.map(d => (<Option value={d.Code}>{d.Name}</Option>))
                          }
                        </Select>
                      </Form.Item>
                      <Form.Item
                        label={
                          <>
                            <span>車型</span>
                            <span
                              style={{
                                paddingLeft: 4,
                                color: "rgba(0, 0, 0, 0.45)",
                                fontSize: 14,
                              }}
                            >
                              (可複選)
                            </span>
                          </>
                        }
                        rules={[{ required: true, message: "請選擇車型!" }]}
                        name="car_code"
                      >
                        <Select
                          block
                          mode="multiple"
                        // onChange={(val) => console.log(val)}
                        >
                          {
                            carModelOption && carModelOption.map(d => (<Option value={d.Code}>{d.Name}</Option>))
                          }
                        </Select>
                      </Form.Item>
                    </>
                  ) : !mode ? (<Form.Item
                    label="項目名稱"
                    name="item_code"
                  >
                    <Select block disabled >
                      {
                        data.map(d => (<Option value={d.item_code}>{d.item_name}</Option>))
                      }
                    </Select>
                  </Form.Item>) : (<></>)
              }

              {
                mode && mode === "km" && (<Form.Item
                  label="基本里程"
                  name="base_km"
                  rules={[{ required: true, message: "請輸入基本里程!" }]}
                >
                  <InputNumber min={1} addonAfter="公里" allowClear placeholder="example" />
                </Form.Item>)
              }
              {
                mode && mode === "area" && (<Form.Item
                  label={<>
                    <span>區域名稱</span>
                    {
                      drawerStatus !== "4b" && (<span
                        style={{
                          paddingLeft: 4,
                          color: "rgba(0, 0, 0, 0.45)",
                          fontSize: 14,
                        }}
                      >
                        (可複選)
                      </span>)
                    }

                  </>}
                  name="area"
                  rules={[{ required: true, message: "請選擇區域!" }]}
                >
                  <CustomCascader
                    defaultValue={defaultValue ? defaultValue : ""}
                    multiple={drawerStatus === '4b' ? false : true}
                    SeperatedName
                    OnChange={(val) => {
                      submitRef.current.setFieldsValue({ area: val })
                    }}
                  />
                </Form.Item>)
              }
              <Form.Item
                label="請款金額"
                name="receive_money"
                rules={[
                  { required: true, message: "請輸入請款金額!" },
                  { pattern: /^\d+$/, message: "請輸入數字" }
                ]}
                style={{ display: "block" }}
              >
                <Input addonBefore="NT$" allowClear placeholder="請輸入金額" />
              </Form.Item>
              <Form.Item
                label="服務費用"
                name="service_cost"
                rules={[
                  { required: true, message: "請輸入服務費用!" },
                  { pattern: /^\d+$/, message: "請輸入數字" }
                ]}
                style={{ display: "block" }}
              >
                <Input addonBefore="NT$" allowClear placeholder="請輸入金額" />
              </Form.Item>
              <Form.Item
                label="自費金額"
                name="self_pay"
                rules={[
                  { required: true, message: "請輸入自費金額!" },
                  { pattern: /^\d+$/, message: "請輸入數字" }
                ]}
                style={{ display: "block" }}
              >
                <Input addonBefore="NT$" allowClear placeholder="請輸入金額" />
              </Form.Item>
              {
                mode && mode === "km" && (<>
                  <div style={{ fontWeight: 700, fontSize: 16, marginBottom: 16 }}>超出基本里程每公里額外加價</div>
                  <Form.Item
                    label="請款金額"
                    name="receive_money/2"
                    rules={[
                      { required: true, message: "請輸入請款金額!" },
                      { pattern: /^\d+$/, message: "請輸入數字" }
                    ]}
                    style={{ display: "block" }}
                  >
                    <Input addonBefore="NT$" allowClear placeholder="請輸入金額" />
                  </Form.Item>
                  <Form.Item
                    label="服務費用"
                    name="service_cost/2"
                    rules={[
                      { required: true, message: "請輸入服務費用!" },
                      { pattern: /^\d+$/, message: "請輸入數字" }
                    ]}
                    style={{ display: "block" }}
                  >
                    <Input addonBefore="NT$" allowClear placeholder="請輸入金額" />
                  </Form.Item>
                  <Form.Item
                    label="自費金額"
                    name="self_pay/2"
                    rules={[
                      { required: true, message: "請輸入自費金額!" },
                      { pattern: /^\d+$/, message: "請輸入數字" }
                    ]}
                    style={{ display: "block" }}
                  >
                    <Input addonBefore="NT$" allowClear placeholder="請輸入金額" />
                  </Form.Item>
                </>)
              }
              <Form.Item
                name="note"
                label={
                  <>
                    <span>備註</span>
                    <span
                      style={{
                        paddingLeft: 4,
                        color: "rgba(0, 0, 0, 0.45)",
                        fontSize: 14,
                      }}
                    >
                      (非必填)
                    </span>
                  </>
                }
                style={{ display: "block" }}
              >
                <Input allowClear placeholder="你也可以留下備註說明..." />
              </Form.Item>

            </Form>
          </Drawer>)
        }

      </div>
    </div>
  );
};

export default AddOnPricing;
